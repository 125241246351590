import { createReducer, on } from '@ngrx/store';
import { Tenant } from '@/app/core/Models/tenant';
import { SignOutAction } from '@/app/State/app/action';
import { Lang, LangModel, TranslationLanguage } from '@/app/core/Models/lang';
import { UpdateTenantBusinessNameSuccessAction, UpdateTenantProfile, fetchSupportedLanguagesSuccessAction, getTranslationPrefeerencesSuccessAction, setTranslationLanguagePreferencesSuccessAction, FetchUserSignatureStatusSuccessAction, UpdateTenantCustomFields, SetUserSignatureStatusAction, SetUserSignatureStatusFailAction, SetUserSignatureValueAction, FetchUserSignatureValueSuccessAction, SetUserSignatureValueFailAction, fetchSynthAutoreplyDefaultSuccessAction } from './tenant.action';
import { BasicCustomFieldDetails } from '@/app/core/Models/contact';
import { BillingTokenLoginAction } from '../Auth/action';

export interface TenantState {
  private_access?: boolean; // when login with tokens
  profile?: Tenant | undefined;
  signature?: {
    status?: boolean,
    value?: string
  };
  translationLanguage?: TranslationLanguage;
  supportedLanguages: LangModel[];
  customFields: BasicCustomFieldDetails[];
  synthAutoreplyDefault?: boolean
  
}

export const initTenantState: TenantState = {
  private_access: false,
  supportedLanguages: [],
  customFields: []
};

export const tenantReducer = createReducer(initTenantState,

  on(UpdateTenantProfile, (state: TenantState, { payload }) => {
    return {
      ...state,
      profile: payload,
    };
  }),

  on(BillingTokenLoginAction, (state: TenantState) => {
    return {
      ...state,
      private_access: true
    };
  }),

  on(UpdateTenantCustomFields, (state: TenantState, { payload }) => {
    return {
      ...state,
      customFields: payload,
    };
  }),

  on(fetchSupportedLanguagesSuccessAction, (state: TenantState, { payload }) => {
    return {
      ...state,
      supportedLanguages: payload.languages
    };
  }),

  /* ---------------------------- signature status ---------------------------- */
  // on(SetUserSignatureStatusAction, (state: TenantState, { payload: { status } }) => {
  //   return {
  //     ...state,
  //     signature: {
  //       ...state.signature,
  //       status
  //     }
  //   };
  // }),

  on(FetchUserSignatureStatusSuccessAction, (state: TenantState, { payload: { status } }) => {
    return {
      ...state,
      signature: {
        ...state.signature,
        status
      }
    };
  }),

  // on(SetUserSignatureStatusFailAction, (state: TenantState, { payload: { } }) => {
  //   return {
  //     ...state,
  //     signature: {
  //       ...state.signature,
  //       status: !state.signature?.status
  //     }
  //   };
  // }),

  /* ----------------------------- signature value ---------------------------- */
  // on(SetUserSignatureValueAction, (state: TenantState, { payload: { value } }) => {
  //   return {
  //     ...state,
  //     signature: {
  //       ...state.signature,
  //       value
  //     }
  //   };
  // }),

  on(FetchUserSignatureValueSuccessAction, (state: TenantState, { payload: { value } }) => {
    return {
      ...state,
      signature: {
        ...state.signature,
        value
      }
    };
  }),

  // on(SetUserSignatureValueFailAction, (state: TenantState, { payload: { } }) => {
  //   return {
  //     ...state,
  //     signature: {
  //       ...state.signature,
  //       value: !state.signature?.value ?? ''
  //     }
  //   };
  // }),

  on(getTranslationPrefeerencesSuccessAction,
    setTranslationLanguagePreferencesSuccessAction,
    (state: TenantState, { payload }
    ) => {
      return {
        ...state,
        translationLanguage: payload
      };
    }),

  on(fetchSynthAutoreplyDefaultSuccessAction, (state: TenantState, { payload: { status } }) => {
    return {
      ...state,
      synthAutoreplyDefault: status
    };
  }),

  on(SignOutAction, (state: TenantState) => {
    return {
      ...initTenantState,
    };
  }),

  on(UpdateTenantBusinessNameSuccessAction, (state: TenantState, { payload: { businessName } }) => {
    return Object.assign({}, state, { ...state, profile: { ...state.profile, businessName: businessName } });
  })
);
