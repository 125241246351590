import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.sass'],
})
export class SuccessDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      duration?: number,
      message: string,
      translateParams: Object
    }
  ) { }

  ngOnInit(): void {
    if (this.data.duration) {
      setTimeout(() => {
        this.dialogRef?.close()
      }, this.data.duration);
    }
  }
}
