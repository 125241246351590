import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../components/Button/button.module';
import { ErrorTextModule } from '../components/errorText/error-text.module';
import { InputWrapperModule } from '../components/new-input-wrapper/input-wrapper.module';
import { PasswordInputModule } from '../components/new-password-input/password-input.module';
import { TextInputModule } from '../components/new-text-input/text-input.module';
import { EmailVerificationGuard } from '../guards/email-verification.guard';
import { GuestGuard } from '../guards/guest.guard';
import { ResetPasswordGuard } from '../guards/reset-password.guard';
import { TranslateCutModule } from '../pipes/translate-cut.pipe';
import { LoginComponent } from './Login/login.component';
import { SignupComponent } from './Signup/signup.component';
import { TermsPrivacyComponent } from './Signup/terms-privacy/terms-privacy.component';
import { AddPasswordComponent } from './add-password/add-password.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { ExpiredTokenComponent } from './expired-token/expired-token.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { RegistrationComponent } from './registration.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthBannerComponent } from './shared/auth-banner/auth-banner.component';
import { A11yModule } from '@angular/cdk/a11y';
import { WaitingListComponent } from './Signup/waiting-list/waiting-list.component';
import { AddCreditCardModule } from '../billing/modals/add-credit-card-dialog/add-credit-card-dialog.module';

const routes: Routes = [
  {
    path: 'auth',
    component: RegistrationComponent,
    // canActivate: [GuestGuard],
    children: [
      { path: 'signup', canActivate: [GuestGuard], component: SignupComponent },
      { path: '', canActivate: [GuestGuard], component: SignupComponent },
      { path: 'invite-user', component: InviteUserComponent },
      { path: 'login', canActivate: [GuestGuard], component: LoginComponent },
      {
        path: 'email-verification',
        component: EmailVerificationComponent,
        canActivate: [EmailVerificationGuard],
        canDeactivate: [EmailVerificationGuard],
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'inv/:token',
    canActivate: [ResetPasswordGuard],
    component: AddPasswordComponent,
    data: {
      reset: true,
    },
  },
  {
    path: 'expired',
    component: ExpiredTokenComponent,
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
];

@NgModule({
  declarations: [
    RegistrationComponent,
    SignupComponent,
    InviteUserComponent,
    TermsPrivacyComponent,
    LoginComponent,
    EmailVerificationComponent,
    AddPasswordComponent,
    AuthBannerComponent,
    ResetPasswordComponent,
    ExpiredTokenComponent,
    WaitingListComponent
  ],
  providers: [EmailVerificationGuard],
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule.forChild({ extend: true }),
    A11yModule,
    TextInputModule,
    ErrorTextModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    FormsModule,
    TranslateCutModule,
    InputWrapperModule,
    PasswordInputModule,
    MatProgressSpinnerModule,
    AddCreditCardModule
  ],
  exports: [RegistrationComponent],
})
export class RegistrationModule { }
