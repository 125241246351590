import { Tag } from '@/app/core/Models/tag';
import { createReducer, on } from '@ngrx/store';
import { FetchTenantTagsSuccessAction } from './tags.action';


export interface TagsState {
  tenantTags: Tag[]
}

export const TagsState: TagsState = {
  tenantTags: []
};

export const tagsReducer = createReducer( TagsState,
  // new message ---------------------------------------------------------------
  on(FetchTenantTagsSuccessAction, (state: TagsState, { payload: { tags } }) => {
    return {
      ...state,
      tenantTags: tags.map(t => ({
        ...t,
        contact_id: 0,
        contact_tag_id: 0,
        unclassified_channel_id: 0,
      }))
    };
  }),
 );
