import { saveAs } from 'file-saver';
import { ElectronService } from './../../Data/services/Electron/electron.service';
import { PlatformType } from '@/app/core/Models/platform';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, Observable, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PlatformService extends ElectronService {
  // I found this link maybe we refer to if any
  // https://github.com/ngneat/hotkeys/blob/master/projects/ngneat/hotkeys/src/lib/utils/platform.ts

  constructor(
    private http: HttpClient
  ) {
    super();
  }
  appleDevices = ['Mac', 'iPhone', 'iPad', 'iPhone'];
  get platform(): PlatformType {
    return navigator.userAgent.indexOf('Win') != -1
      ? PlatformType.windows
      : this.appleDevices.some(d => navigator.userAgent.indexOf(d) != -1)
        ? PlatformType.mac
        : // : (navigator.userAgent.indexOf('X11') != -1)
        // ? 'UNIX'
        PlatformType.linux;
  }

  get isMac(): boolean {
    return this.appleDevices.some(d => navigator.userAgent.indexOf(d) != -1)
  }

  get commandOrCtrl() {
    return this.platform === PlatformType.mac
      ? 'Command'
      : 'Ctrl';
  }

  get device() {
    if (!this.isElectronApp) {
      return 'web';
    } else {
      if (this.isMacOS) {
        return 'mac';
      } else {
        return 'windows';
      }
    }
  }

  openURL(url: string, target: '_self' | '_blank' = '_self') {
    this.isElectronApp
      ? this.shell
        ?.openExternal(url)
        .then((c: any) => {
          console.log(c);
        })
        .catch((er: any) => {
          console.error(er);
        })
      : window.open(url, target);
  }

  saveFromURL(url: string, fileName: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    }).pipe(
      catchError(() => {
        // if the download has been blocked due to CORS policy
        // redirect the user to the url so he can download it.
        // it would be nice if we show a snackbar here
        this.openURL(url, '_blank')
        return of()
      }),
      tap((blob) => this.saveRawFile(blob, fileName)),
    )
  }

  saveRawFile(blob: Blob, fileName: string) {
    saveAs(blob, fileName);
  }
}
