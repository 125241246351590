import { IRoom } from '@/app/Ui/team-chat-center/team-chat-room/team-chat-room.interface';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ITeamInteractionRepository } from '../../IRepositories/ITeamInteractionRepository';
import { InteractionStatus, RecentInteraction } from '../../Models/Interaction';
import { TeamInteraction } from '../../Models/interaction-team';
import { TeamMember } from '../../Models/TeamMember';
import { Tenant } from '../../Models/tenant';
import { Room } from '../../Models/User';
import { UseCase } from '../UseCase';

@Injectable({
  providedIn: 'root',
})
export class HandleIncomingTeamInteractionsUseCase
  implements UseCase<{ interactions: TeamInteraction[], profile: Tenant, users: TeamMember[], room?: IRoom }, Observable<RecentInteraction>> {
  constructor(
    private teamInteractionRepository: ITeamInteractionRepository,
  ) { }

  execute({ interactions, profile, users, room }: { interactions: TeamInteraction[], profile: Tenant, users: TeamMember[], room?: IRoom }): Observable<RecentInteraction> {
    let recentInteraction: RecentInteraction
    for (const i of interactions) {
      let interaction = Object.assign(new TeamInteraction(), {
        ...i,
        users,
        profile,
        room: Object.assign(new Room(), room),
      });
      recentInteraction = interaction.mapToRecentInteraction();
      this.teamInteractionRepository.saveTeamRecentMessage(recentInteraction);
      const threadInteraction = interaction.mapToThreadInteraction();
      this.teamInteractionRepository.saveTeamThreadMessage(threadInteraction);
    }
    return of(recentInteraction!);
  }
}
