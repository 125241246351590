import { createAction, props, union } from '@ngrx/store';

import { RoomDetails, CreateRoomInput, UpdateRoomLogoInput, AddParticipantInput, RemoveParticipantInput, RoomThreadMessagesInput } from '@/app/core/Models/Room';

export const CREATE_ROOM = '[Room] Create Room';
export const CreateRoomAction = createAction(
  CREATE_ROOM,
  props<{ payload: CreateRoomInput }>()
);

export const CREATE_ROOM_SUCCESS = '[Room] Create Room Success';
export const CreateRoomSuccessAction = createAction(
  CREATE_ROOM_SUCCESS,
  props<{ payload: RoomDetails }>()
);

export const CREATE_ROOM_ERROR = '[Room] Create Room Error';
export const CreateRoomErrorAction = createAction(
  CREATE_ROOM_ERROR,
  props<{ payload: { room_id: string, errorMessage: string } }>()
);

export const UPDATE_ROOM = '[Room] Update Room';
export const UpdateRoomAction = createAction(
  UPDATE_ROOM,
  props<{ payload: CreateRoomInput }>()
);

export const UPDATE_ROOM_SUCCESS = '[Room] Update Room Success';
export const UpdateRoomSuccessAction = createAction(
  UPDATE_ROOM_SUCCESS,
  props<{ payload: CreateRoomInput }>()
);

export const UPDATE_ROOM_ERROR = '[Room] Update Room Error';
export const UpdateRoomErrorAction = createAction(
  UPDATE_ROOM_ERROR,
  props<{ payload: { room_id: string, errorMessage: string } }>()
);

export const UPDATE_ROOM_LOGO = '[Room] Update Room Logo';
export const UpdateRoomLogoAction = createAction(
  UPDATE_ROOM_LOGO,
  props<{ payload: UpdateRoomLogoInput }>()
);

export const UPDATE_ROOM_LOGO_SUCCESS = '[Room] Update Room Logo Success';
export const UpdateRoomLogoSuccessAction = createAction(
  UPDATE_ROOM_LOGO_SUCCESS,
  props<{ payload: { avatar: string, room_name: string, room_private: boolean, room_id: string, updated_dt: string } }>()
);

export const UPDATE_ROOM_LOGO_ERROR = '[Room] Update Room Logo Error';
export const UpdateRoomLogoErrorAction = createAction(
  UPDATE_ROOM_LOGO_ERROR,
  props<{ payload: { room_id: string, errorMessage: string } }>()
);

export const ADD_ROOM_PARTICIPANT = '[Room] Add Room Participant';
export const AddRoomParticipantAction = createAction(
  ADD_ROOM_PARTICIPANT,
  props<{ payload: AddParticipantInput }>()
);

export const ADD_ROOM_PARTICIPANT_SUCCESS = '[Room] Add Room Participant Success';
export const AddRoomParticipantSuccessAction = createAction(
  ADD_ROOM_PARTICIPANT_SUCCESS,
  props<{ payload: { user_id: string, is_creator: boolean } }>()
);

export const ADD_ROOM_PARTICIPANT_ERROR = '[Room] Add Room Participant Error';
export const AddRoomParticipantErrorAction = createAction(
  ADD_ROOM_PARTICIPANT_ERROR,
  props<{ payload: { room_id: string, errorMessage: string } }>()
);

export const REMOVE_ROOM_PARTICIPANT = '[Room] Remove Room Participant';
export const RemoveRoomParticipantAction = createAction(
  REMOVE_ROOM_PARTICIPANT,
  props<{ payload: RemoveParticipantInput }>()
);

export const REMOVE_ROOM_PARTICIPANT_SUCCESS = '[Room] Remove Room Participant Success';
export const RemoveRoomParticipantSuccessAction = createAction(
  REMOVE_ROOM_PARTICIPANT_SUCCESS,
  props<{ payload: { user_id: string } }>()
);

export const REMOVE_ROOM_PARTICIPANT_ERROR = '[Room] Remove Room Participant Error';
export const RemoveRoomParticipantErrorAction = createAction(
  REMOVE_ROOM_PARTICIPANT_ERROR,
  props<{ payload: { room_id: string, errorMessage: string } }>()
);

export const FETCH_ROOM_THREAD_MESSAGES = '[Room] Fetch Room Thread Messages';
export const FetchRoomThreadMessagesAction = createAction(
  FETCH_ROOM_THREAD_MESSAGES,
  props<{ payload: RoomThreadMessagesInput }>()
);

export const FETCH_ROOM_PARTICIPANTS = '[Room] Fetch Room Participants';
export const FetchRoomParticipantsAction = createAction(
  FETCH_ROOM_PARTICIPANTS,
  props<{ payload: { room_id: string } }>()
);

export const FETCH_TENANT_ROOMS = '[Room] Fetch Tenant Rooms';
export const FetchTenantRoomsAction = createAction(
  FETCH_TENANT_ROOMS,
  props<{ payload: {} }>()
);

export const FETCH_TENANT_ROOMS_SUCCESS = '[Room] Fetch Tenant Rooms Success';
export const FetchTenantRoomsSuccessAction = createAction(
  FETCH_TENANT_ROOMS_SUCCESS,
  props<{ payload: { tenantRooms: RoomDetails[] } }>()
);

export const FETCH_TENANT_ROOMS_ERROR = '[Room] Fetch Tenant Rooms Error';
export const FetchTenantRoomsErrorAction = createAction(
  FETCH_TENANT_ROOMS_ERROR,
  props<{ payload: { errorMessage: string } }>()
);

export const FETCH_PARTICIPANT_ROOMS = '[Room] Fetch Participant Rooms';
export const FetchParticipantRoomsAction = createAction(
  FETCH_PARTICIPANT_ROOMS,
  props<{ payload: {} }>()
);

export const FETCH_PARTICIPANT_ROOMS_SUCCESS = '[Room] Fetch Participant Rooms Success';
export const FetchParticipantRoomsSuccessAction = createAction(
  FETCH_PARTICIPANT_ROOMS_SUCCESS,
  props<{ payload: { participantRooms: RoomDetails[] } }>()
);

export const FETCH_PARTICIPANT_ROOMS_ERROR = '[Room] Fetch Participant Rooms Error';
export const FetchParticipantRoomsErrorAction = createAction(
  FETCH_PARTICIPANT_ROOMS_ERROR,
  props<{ payload: { errorMessage: string } }>()
);

export const SET_ACTIVE_ROOM = '[Room] Set Active Room';
export const SetActiveRoomAction = createAction(
  SET_ACTIVE_ROOM,
  props<{ payload: RoomDetails }>()
)

export const SET_ACTIVE_ROOM_SUCCESS = '[Room] Set Active Room Success';
export const SetActiveRoomActionSuccess = createAction(
  SET_ACTIVE_ROOM_SUCCESS,
  props<{ payload: RoomDetails }>()
)

const all = union({
  CreateRoomAction,
  CreateRoomSuccessAction,
  CreateRoomErrorAction,
  UpdateRoomAction,
  UpdateRoomSuccessAction,
  UpdateRoomErrorAction,
  UpdateRoomLogoAction,
  UpdateRoomLogoSuccessAction,
  UpdateRoomLogoErrorAction,
  AddRoomParticipantAction,
  AddRoomParticipantSuccessAction,
  AddRoomParticipantErrorAction,
  RemoveRoomParticipantAction,
  RemoveRoomParticipantSuccessAction,
  RemoveRoomParticipantErrorAction,
  FetchRoomThreadMessagesAction,
  FetchRoomParticipantsAction,
  SetActiveRoomAction,
  SetActiveRoomActionSuccess,
  FetchTenantRoomsAction,
  FetchTenantRoomsSuccessAction,
  FetchTenantRoomsErrorAction,
  FetchParticipantRoomsAction,
  FetchParticipantRoomsSuccessAction,
  FetchParticipantRoomsErrorAction
});

export type RoomActions = typeof all;
