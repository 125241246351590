import { EmailFieldDto } from '@/app/Data/DTO/authDto';
import { EmailVerificationCodeDto } from '@/app/Data/DTO/EmailVerificationCodeDto';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { AuthenticationRepository } from '@/app/Data/repositories/AuthenticationRepository';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthenticationRepository } from '../../IRepositories/IAuthenticationRepository';
import { UseCase } from '../UseCase';

@Injectable({
  providedIn: 'root',
})
export class RequestEmailVerificationCodeUseCase
  implements UseCase<EmailVerificationCodeDto, SuccessResponse>
{
  constructor(
    @Inject(AuthenticationRepository)
    private authRepository: IAuthenticationRepository
  ) {}
  execute(request: EmailVerificationCodeDto): Observable<SuccessResponse> {
    return this.authRepository.requestEmailVerificationCode(request);
  }
}
