<ng-container>
  <div>
    <div class="flex gap-4 my-8">
      <mat-icon style="color: #F14336; font-size: 2.5rem; width: 2.5rem; height: 2.5rem;">
        cancel
      </mat-icon>
      <h3 class="text-xl">
        {{ message }} 
        <ng-container *ngIf="isDesktop">
          <br/>
          <div class="text-xl">Please Check our web application for more details.</div>
        </ng-container>
      </h3>
    </div>
    <div
      class="flex justify-end mt-4"
    >
      <app-button 
        *ngIf="!isMacApp"
        color="primary"
        (click)="confirm()">
        <div class="text-lg" [class.px-5]="isDesktop">{{isDesktop ? 'Visit' : 'Upgrade my plan'}}</div>
      </app-button>
    </div>
  </div>
</ng-container>
