import { ButtonModule } from '@/app/Ui/components/Button/button.module';
import { AvatarModule } from '@/app/Ui/components/avatar/avatar.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MentionModule } from 'angular-mentions';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormattedTextareaModule } from '../components/formatted-textarea/formatted-textarea.module';
import { TextInputModule } from '../components/new-text-input/text-input.module';
import { AddTagComponent } from './add-tag/add-tag.component';
import { TagComponent } from './tag/tag.component';
import { TagsComponent } from './tags.component';
@NgModule({
  declarations: [TagsComponent, TagComponent, AddTagComponent],
  exports: [TagsComponent, TagComponent, AddTagComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    ButtonModule,
    MatDialogModule,
    MatTooltipModule,
    AvatarModule,
    AngularSvgIconModule,
    TranslateModule.forChild({extend:true}),
    MentionModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatMenuModule,
    MatAutocompleteModule,
    TextInputModule,
    FormattedTextareaModule
  ],
  providers: [],
})
export class TagsModule {
  constructor() {}
}
