import { ISocketClient, SocketConfig } from '@/app/Data/Adapters/ISocketClient';
import { OnboardingVoiceSynthDto } from '@/app/Data/DTO/OnboardingVoiceSynthDto';
import { TeamMemberOnlineEventDto } from '@/app/Data/DTO/Team/TeamMemberOnlineEventDto';
import { VoiceMailTranscriptionDto } from '@/app/Data/DTO/TranscriptionDto';
import { IsTypingResponseEventDto } from '@/app/Data/DTO/isTypingEventDto';
import { AuthService } from '@/app/Data/services/Auth/auth.service';
import { SocketService } from '@/app/Data/socket/socket.service';
import { VoiceMailSocketService } from '@/app/Data/socket/voiceMail-socket.service';
import { FetchInboxesAction, FetchRecentMessagesAction, IncreaseInboxUnreadCountAction, SetTypingUserAction } from '@/app/State/Inbox/inbox.action';
import { IncomingInteractionAction, SOCKET_CONNECT, SOCKET_INCOMING_INTERACTION, SocketFailedAction } from '@/app/State/Socket/action';
import { SIGN_OUT } from '@/app/State/app/action';
import { TenantViewModel } from '@/app/Ui/ViewModels/tenantViewModel';
import { UsersViewModel } from '@/app/Ui/ViewModels/usersViewModel';
import { PhoneInternalChannel } from '@/app/core/Models/Channel';
import { ChannelType } from '@/app/core/Models/ChannelTypes';
import { InteractionDirection, InteractionStatus, SocketInteraction } from '@/app/core/Models/Interaction';
import { TaskComment, TaskDetail } from '@/app/core/Models/Task';
import { BillingSubscription } from '@/app/core/Models/billing';
import { SocketEmailInteraction } from '@/app/core/Models/email-interaction';
import { TeamInteraction } from '@/app/core/Models/interaction-team';
import { HandleIncomingInteractionUseCase } from '@/app/core/usecases/inbox/HandleIncomingInteractionUseCase';
import { HandleIncomingTeamInteractionsUseCase } from '@/app/core/usecases/inbox/HandleIncomingTeamInteractionUseCase';
import { ConfigurationService } from '@/app/shared/Config/configuration.service';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { auditTime, catchError, combineLatest, filter, first, firstValueFrom, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { AppState } from '../AppState';
import { selectInboxActiveThreadId } from '../Inbox/inbox.selector';
import { GetNotificationsAction, GetUnreadNotificationsCountAction } from '../Notifications/notifications.action';
import { AddIncommingTaskCommentAction } from '../Task/action';
import { GetAllTasksAction } from '../Tasks/action';
import { selectTasksState } from '../Tasks/selector';
import { HandleReactToTeamChatMessageAction, OpenTeamChatThreadAction, ReactToTeamChatMessageSuccessAction } from '../TeamInbox/teamInbox.action';
import { selectTenantProfile } from '../Tenant/tenant.selector';
import { FetchActiveSubscriptionSuccessAction, OpenBillingPromptAction } from '../billing/billing.actions';
import { FetchContactByIdAction, GetUnclassifiedChannelDetailAction } from '../contact/contact.action';
import { CsvContactsImportedAction } from '../contacts/contacts.action';
import { FetchEmailClassificationsAction, FetchEmailInteractionsAction } from '../email-inbox/email-inbox.action';
import { selectActiveConversationId } from '../email-inbox/email-inbox.selector';
import { VoiceSynthOnboardingStatusUpdateAction, VoiceSynthOnboardingTranscriptionAction } from '../onboarding/onboarding.action';
import { FetchMessagesAction, SetThreadMessageSuggestionAction, UpdateLocalInteractionAction } from '../thread/thread.action';
import { selectThreadState } from '../thread/thread.selector';
import { HandleIncomingVoicemailAction, InsertVoicemailLiveTranscriptionAction } from '../voicemails/voicemails.action';
import { VoiceClientConferenceUpdateDto } from './../../Data/DTO/VoiceClient/VoiceClientConferenceUpdateDto';
import { TokenStorgeService } from './../../Data/services/Token/token.service';
import { AudioService } from './../../Utilities/audio/audio.service';
import { TeamMemberOnlineEventAction } from './../users/action';
import { SOCKET_CONNECT_FAIL, SocketConnectedAction } from './action';
import { ConferenceStateMapperService } from './conference-mapper.service';
import { InteractionComment } from '@/app/core/Models/Interaction/InteractionComment';
import { TeamChatViewModel } from "@/app/Ui/ViewModels/TeamChatViewModel";
import { IRoom } from '@/app/Ui/team-chat-center/team-chat-room/team-chat-room.interface';
import { OnReceivedInteractionComment } from '../Interaction/interaction.action';
import { SynthInteraction } from '@/app/core/Models/synth';
import { HandleSynthInteractionAction } from '../synth/synth.action';
import { RightPanelIndexes, SetRightPanelActiveWindowAction } from '../mediaObserver/media.observer.action';
import { getNotesByContactIdAction, getNotesByUnclassifiedChannelIdAction } from '../Notes/action';
import { ITeamInteractionRepository } from '@/app/core/IRepositories/ITeamInteractionRepository';
import { SynthViewModel } from '@/app/Ui/ViewModels/synth-view-model';
import { enableDOM } from '@/app/Utilities/helpers';

enum SocketEventNames {
  CONNECT = 'connect',
  CONNECT_ERROR = 'connect_error',
  DISCONNECT = 'disconnect',
}

enum SocketCustomEventNames {
  ALERT = 'alert_secure',
  TASK = 'task_secure',
  'SYNTH-STATUS' = 'synth-status_secure',
  TASKS = 'tasks_secure',
  'CONTACT-IMPORT' = 'contact-import_secure',
  'UPGRADE-PROMPT' = 'upgrade-prompt_secure',
  LOGOUT = 'logout_secure',
  EMAILS = 'emails_secure',
  CONNECTED_USERS = 'connectedUsers_secure',
  TYPING = 'typing_secure',
  REFRESH = 'refresh_secure',
  INTERNAL_MESSAGE = 'internal-message_secure',
  INTERNAL_COMM_REACTION = 'internal-comm-reaction_secure',
  'SYNTH-SUGGESTION' = 'synth-suggestion_secure',
  INLINE_NOTE = 'inline_note_secure',
  CONFERENCEUPDATE = 'conferenceUpdate_secure',
  INTERNAL_NOTIFICATIONS_V2 = 'internal_notifications_v2_secure',
  TASK_REMINDER = 'task_reminder_secure',
  MESSAGESTATUS = 'messageStatus_secure',
  BILLING_SUBSCRIPTION = 'billing_subscription_secure',
  MESSAGE_TYPING = 'message_typing_secure',
  USER_EVENT = 'user_event_secure',
  VOICE_SYNTH_ONBOARDING = 'voice_synth_onboarding_secure',
  ADD_PARTICIPANT = 'add-participant_secure',
  REMOVE_PARTICIPANT = 'remove-participant_secure',
  MENTION_PARTICIPANT = 'mention-participant_secure',
  SYNTH_INTERACTION = 'synth-interaction_secure',
  SYNTH_CONVERSATION = 'synth-conversation_secure',
  CREATE_SYNTH_ID = 'create-synth-id_secure',
  RETELL_TRANSCRIPT = 'retell-transcript_secure'
}

enum VMSocketCustomEventNames {
  LIVE_TRANSCRIPTION = 'live_transcription',
  ONBOARDING_SYNTH_TRANSCRIPTION = 'onboarding_synth_transcription',
  VOICE_SYNTH_ONBOARDING = 'voice_synth_onboarding',
}


@Injectable()
export class SocketEffect {

  constructor(
    private actions$: Actions,
    private conferenceStateMapperService: ConferenceStateMapperService,
    private authService: AuthService,
    private audioService: AudioService,
    private tokenService: TokenStorgeService,
    private configurationService: ConfigurationService,
    private handleIncomingInteractionUseCase: HandleIncomingInteractionUseCase,
    private handleIncomingTeamInteractionUseCase: HandleIncomingTeamInteractionsUseCase,
    @Inject(ISocketClient) private socketServices: ISocketClient[],
    private store$: Store<AppState>,
    private usersViewModel: UsersViewModel,
    private teamInteractionRepository: ITeamInteractionRepository,
    private tenantViewModel: TenantViewModel,
    private teamChatViewModel: TeamChatViewModel,
    private synthViewModel: SynthViewModel
  ) { }

  isSilentMode$ = combineLatest([
    this.store$.select(selectTenantProfile),
    this.tenantViewModel.internalChannels$
  ]).pipe(
    map(([tenant, internalChannels]) => {
      const phoneChannel = internalChannels.find(c => c.channel_type === ChannelType.Phone) as PhoneInternalChannel | undefined
      if (tenant?.doNotDisturb) return true
      if (phoneChannel?.business_hours?.business_hours === 'ON') {
        const date = new Date(new Date().toLocaleString(undefined, { timeZone: phoneChannel.business_hours.timezone }))
        const day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()];
        const businessHours = phoneChannel.business_hours.hours.find(bh => bh.day === day)
        if (businessHours) {
          const [start_hour, start_minute] = businessHours.start_time.split(':')
          const [end_hour, end_minute] = businessHours.end_time.split(':')
          const start_date = new Date(date)
          start_date.setHours(+start_hour);
          start_date.setMinutes(+start_minute);
          const end_date = new Date(date);
          end_date.setHours(+end_hour);
          end_date.setMinutes(+end_minute);
          return date.getTime() < start_date.getTime() || date.getTime() > end_date.getTime()
        }
      }
      return false
    })
  )


  /* ------------------------------- on connect ------------------------------- */
  onSocketConnection$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive(SocketEventNames.CONNECT).pipe(
      map((event) => SocketConnectedAction({
        payload: event
      })),
      tap((route: any) => {
        // console.log(' ***************** Socket Connected ');
      })
    ),
    { dispatch: true }
  );

  connectSocket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SOCKET_CONNECT),
      map((action: any) => action.payload),
      tap((config: SocketConfig) => {
        this.socketServices.find(x => x instanceof SocketService)!.connectSocket(config);
        this.socketServices.find(x => x instanceof VoiceMailSocketService)!.connectSocket(config);
      })
    ),
    { dispatch: false }
  );

  /* ------------------------------ connect fail ------------------------------ */
  connect_error$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive(SocketEventNames.CONNECT_ERROR).pipe(
      auditTime(1000),
      tap((details: any) => {
        // console.log('************** connect_error', details.message);
      }),
      map(details => SocketFailedAction({ payload: details })),
    ),
    { dispatch: true }
  );

  socketAuthenticationFailHandler$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SOCKET_CONNECT_FAIL),
      map((action: any) => action.payload),
      filter(detail => detail.message != 'io server disconnect' || detail?.message != 'Authentication error'),
      tap(() => {
        const refreshToken = this.tokenService.getRefreshToken()
        if (refreshToken) {
          this.authService.refreshToken(refreshToken).subscribe({
            error: (error: any) => {
              // console.log("error socket refresh ", error, error.statusCode)
            },
            complete: () => {
              // console.log("token updated");
              this.configurationService.connectSocket();
            },
          })
        }
      })
    ),
    { dispatch: false }
  );

  /* ------------------------------- disconnect ------------------------------- */
  onSocketDisconnect$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!
      .onReceive(SocketEventNames.DISCONNECT)
      .pipe(
        tap((detail: any) => {
          if (detail === "io server disconnect") {
            // the disconnection was initiated by the server, you need to reconnect manually
            this.configurationService.connectSocket();
          }
          // console.log('****************** Socket Disconnected', detail);
        })
      ),
    { dispatch: false }
  );

  /* -------------------------------- sign out -------------------------------- */
  onLogoutEvent$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive(SocketCustomEventNames.LOGOUT).pipe(
      tap((data: any) => {
        this.authService?.logout();
        // console.log('Socket forceLogout', data);
      })
    ),
    { dispatch: false }
  );

  onSignOutAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SIGN_OUT),
      map((action: any) => action.payload),
      tap((config: SocketConfig) => {
        this.socketServices.find(x => x instanceof SocketService)!.destroy();
      })
    ),
    { dispatch: false }
  );

  /* ---------------------------- vm transcription ---------------------------- */
  onSynthTrainerInteraction$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!
      .onReceive<SynthInteraction>(SocketCustomEventNames.SYNTH_INTERACTION)
      .pipe(
        map(interaction => HandleSynthInteractionAction({ payload: { interaction } }))
      ),
    { dispatch: true }
  );

  onVMSocketMessage$ = createEffect(() =>
    this.socketServices.find(x => x instanceof VoiceMailSocketService)!
      .onReceive<VoiceMailTranscriptionDto>(VMSocketCustomEventNames.LIVE_TRANSCRIPTION)
      .pipe(
        map(data => InsertVoicemailLiveTranscriptionAction({ payload: data }))
      ),
    { dispatch: true }
  );

  /* ------------------------------ messagestatus ----------------------------- */
  onIntetrnalMessageStatus$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!
      .onReceive<{
        interaction_id: string | number,
        user_id: string | number,
        status: InteractionStatus,
      }>(SocketCustomEventNames.MESSAGESTATUS)
      .pipe(
        tap((data) => {
          this.teamInteractionRepository.updateLocalTeamThreadInteraction({ status: data.status }, data.interaction_id + '')
        })
      ),
    { dispatch: false }
  );

  onInternalCommReaction$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!
      .onReceive<{ interaction_id: string | number; user_id: string | number; reaction: string }>(SocketCustomEventNames.INTERNAL_COMM_REACTION)
      .pipe(
        withLatestFrom(this.store$.select(selectTenantProfile)),
        filter(([{ user_id }, profile]) => user_id !== profile?.userId),
        map(([{ user_id, interaction_id, reaction }]) => HandleReactToTeamChatMessageAction({
          payload: { interaction_id: interaction_id as any, reaction, user_id }
        }))
      ),
    { dispatch: true }
  );

  /* ---------------------------------- alert --------------------------------- */
  onSocketMessage$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!
      .onReceive<{ message_type: string; data: any }>(SocketCustomEventNames.ALERT)
      .pipe(
        tap((data: any) => {
          this.handleSocketAlert(data);
        })
      ),
    { dispatch: false }
  );

  handleSocketAlert({ message_type, data }: {
    message_type: string,
    data: SocketInteraction | { interaction_id: number; status: InteractionStatus } | InteractionComment
  }) {
    switch (message_type) {
      case 'interaction_comment':
        this.store$.dispatch(OnReceivedInteractionComment({ payload: Object.assign(new InteractionComment(), data) }))
        break
      case 'interaction':
        const socketInteraction: SocketInteraction = Object.assign(new SocketInteraction(), data);
        if ((data as SocketInteraction).contact_details) {
          this.store$.dispatch(IncomingInteractionAction({ payload: socketInteraction }));
          (data as SocketInteraction).synth_voicemail_status
            && this.store$.dispatch(HandleIncomingVoicemailAction({ payload: socketInteraction.mapToVoivemailInteraction() }));
        }
        if ((data as SocketInteraction).direction === InteractionDirection.inbound) {
          [InteractionStatus.unread, InteractionStatus.received, InteractionStatus.ringing].includes((data as SocketInteraction).status)
            ? this.store$.dispatch(IncreaseInboxUnreadCountAction({ payload: { inboxId: (data as SocketInteraction).inbox_id } }))
            : undefined // this.store$.dispatch(FetchInboxesAction())
        }
        break;
      case 'interaction-status-update':
        const interaction = data as { interaction_id: number; status: InteractionStatus }
        this.store$.dispatch(UpdateLocalInteractionAction({
          payload: {
            interaction: { interaction_id: interaction.interaction_id, status: interaction.status, updated_dt: new Date().toUTCString() }
          }
        }))
        break;
      case 'user_update':
        break;
      case 'contact_notes':
        if ((data as SocketInteraction).channel_id) {
          this.store$.dispatch(getNotesByUnclassifiedChannelIdAction({ payload: { channel_id: (data as SocketInteraction).channel_id } }));
          break;
        }
        if ((data as SocketInteraction).yobi_contact_id) {
          this.store$.dispatch(getNotesByContactIdAction({ payload: { contact_id: (data as SocketInteraction).yobi_contact_id } }));
          break;
        }
    }
  }

  /* ------------------------------- interaction ------------------------------ */
  onIncomingInteraction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SOCKET_INCOMING_INTERACTION),
      map((action: any) => action.payload as SocketInteraction),
      tap(async ({ direction, status }) => {
        const silent = await firstValueFrom(this.isSilentMode$)
        !silent
          && direction == InteractionDirection.inbound
          && status == InteractionStatus.received
          && this.audioService.playIncomingInteractionNotification();
      }),
      switchMap((interaction: SocketInteraction) =>
        this.handleIncomingInteractionUseCase.execute(interaction)),
      withLatestFrom(this.store$.select(selectInboxActiveThreadId)),
      // TODO reduce fetching first page messages on each socket interaction
      filter(([interaction, activeThreadId]) => (interaction.is_contact ? interaction.yobi_contact_id : interaction.channel_id) == activeThreadId),
      map(([interaction]) =>
        FetchMessagesAction({
          payload: {
            page: 1,
            threadId: (interaction.is_contact ? interaction.yobi_contact_id : interaction.channel_id)!,
            unClassified: !interaction.is_contact
          }
        })
      ),
    ),
    { dispatch: true }
  );

  /* -------------------------- refresh interactions -------------------------- */
  // onRefreshEvent$ = createEffect(() =>
  //   this.socketServices.find(x => x instanceof SocketService)!.onReceive(SocketCustomEventNames.REFRESH).pipe(
  //     filter((item: any) => item?.type == 'refresh_read_status'),
  //     map(() => {
  //       this.store$.dispatch(FetchInboxesAction())
  //       return FetchRecentMessagesAction({
  //         payload: { page: 1, page_size: 30 },
  //       });
  //     })
  //   ),
  //   { dispatch: true }
  // );

  /* ---------------------------- synth onboarding ---------------------------- */
  onSynthOnboardingEstablished$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!
      .onReceive<OnboardingVoiceSynthDto>(SocketCustomEventNames.VOICE_SYNTH_ONBOARDING)
      .pipe(
        map((data) => VoiceSynthOnboardingStatusUpdateAction({ payload: data }))),
    { dispatch: true }
  );

  onSynthOnboardingCompleted$ = createEffect(() =>
    this.socketServices.find(x => x instanceof VoiceMailSocketService)!
      .onReceive<{ data: OnboardingVoiceSynthDto }>(VMSocketCustomEventNames.VOICE_SYNTH_ONBOARDING)
      .pipe(
        map(({ data }) => VoiceSynthOnboardingStatusUpdateAction({ payload: data }))),
    { dispatch: true }
  );

  /* --------------------- synth onboarding transcription --------------------- */
  onSynthOnboardingTranscription$ = createEffect(() =>
    this.socketServices.find(x => x instanceof VoiceMailSocketService)!
      .onReceive<VoiceMailTranscriptionDto>(VMSocketCustomEventNames.ONBOARDING_SYNTH_TRANSCRIPTION)
      .pipe(
        filter(data => !!data.data.transcription.trim()),
        map((data) => VoiceSynthOnboardingTranscriptionAction({ payload: data }))),
    { dispatch: true }
  );

  /* ---------------------------------- synth --------------------------------- */
  onContactUpdate$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!
      .onReceive<{ contact_id: number, channel_id: number }>(SocketCustomEventNames['SYNTH-STATUS'])
      .pipe(
        filter(({ contact_id, channel_id }) => !!contact_id || !!channel_id),
        map(({ contact_id, channel_id }) => contact_id
          ? FetchContactByIdAction({ payload: contact_id })
          : GetUnclassifiedChannelDetailAction({ payload: channel_id })
        )
      ),
    { dispatch: true }
  );

  onSynthSuggest$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!
      .onReceive<{ suggestion: string, contact_id: number | null, channel_id: number | null, silent: boolean }>(SocketCustomEventNames['SYNTH-SUGGESTION'])
      .pipe(
        tap(data => {
          this.store$.dispatch(SetThreadMessageSuggestionAction({
            payload: {
              content: data.suggestion,
              threadId: (data.channel_id ?? data.contact_id)!,
              unclassified: !!data.channel_id
            }
          }))
        })
      ),
    { dispatch: false }
  );

  /* ---------------------------------- task ---------------------------------- */
  reloadTasks$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive(SocketCustomEventNames.TASKS).pipe(
      filter(() => window.location.href.includes('tasks')),
      withLatestFrom(this.store$.select(selectTasksState)),
      map(([data, state]) => GetAllTasksAction({ payload: { filter: state.filter, status: state.activeTasksStatus } }))
    ),
    { dispatch: true }
  );

  onTaskEvent$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive<TaskDetail>(SocketCustomEventNames.TASK).pipe(
      tap(() => this.store$.dispatch(GetUnreadNotificationsCountAction())),
      // removed fetching interaction as the interaction socket is also sent and the fetch is happening there
      // so I removed this to avoid the fetch duplication
      filter(() => window.location.href.includes('tasks')),
      withLatestFrom(this.store$.select(selectTasksState)),
      map(([data, state]) => GetAllTasksAction({ payload: { filter: state.filter, status: state.activeTasksStatus } }))
    ),
    { dispatch: true }
  );

  onTaskCommentEvent$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive<TaskComment>('task-comment_secure').pipe(
      tap((data) => this.store$.dispatch(AddIncommingTaskCommentAction({ payload: data }))),
      map(() => GetUnreadNotificationsCountAction())
    ),
    { dispatch: true }
  );

  onTaskReminderEvent$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive(SocketCustomEventNames.TASK_REMINDER).pipe(
      tap((data) => console.log('task reminder socket', data)),
      map(() => GetUnreadNotificationsCountAction())
    ),
    { dispatch: true }
  );

  /* --------------------------------- contact -------------------------------- */
  csv_import_success$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive(SocketCustomEventNames['CONTACT-IMPORT']).pipe(
      map(data => data as unknown as { total_contacts: number, imported_contacts: number }),
      map((data) => CsvContactsImportedAction({ payload: data })),
    ),
    { dispatch: true }
  );

  /* --------------------------------- billing -------------------------------- */
  onBillingSubscription$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive(SocketCustomEventNames.BILLING_SUBSCRIPTION).pipe(
      map(activeSubscription => Object.assign(new BillingSubscription(), activeSubscription)),
      map((activeSubscription) => FetchActiveSubscriptionSuccessAction({ payload: activeSubscription }))
    ),
    { dispatch: false }
  );

  /* --------------------------------- upgrade -------------------------------- */
  openBillingPrompt$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive(SocketCustomEventNames['UPGRADE-PROMPT']).pipe(
      // tap(() => console.log('billing upgrade prompt opened')),
      map(() => OpenBillingPromptAction()),
    ),
    { dispatch: true }
  );

  /* ---------------------------------- email --------------------------------- */
  onEmailEvent$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive<SocketEmailInteraction>(SocketCustomEventNames.EMAILS).pipe(
      tap(async ({ direction }) => {
        const silent = await firstValueFrom(this.isSilentMode$)
        !silent
          && direction == InteractionDirection.inbound
          && this.audioService.playIncomingInteractionNotification();
      }),
      tap(({ direction, internal_channel_id }) => {
        direction === InteractionDirection.inbound
          && this.store$.dispatch(FetchEmailClassificationsAction({ payload: { channelId: internal_channel_id } }))
      }),
      withLatestFrom(this.store$.select(selectActiveConversationId)),
      filter(([{ conversation_id }, active_conversation_id]) => conversation_id == active_conversation_id),
      map(([{ conversation_id }]) => FetchEmailInteractionsAction({ payload: { query: { conversation_id, page: 1, page_size: 20 } } }))
    ),
    { dispatch: true }
  );

  /* ------------------------------ voice client ------------------------------ */
  onCallConferenceUpdateEvent$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!
      .onReceive<VoiceClientConferenceUpdateDto>(SocketCustomEventNames.CONFERENCEUPDATE)
      .pipe(
        tap((data: VoiceClientConferenceUpdateDto) => {
          // console.log('Conference event :>> ', data);
          this.conferenceStateMapperService.handleConferenceUpdate(data);
        })
      ),
    { dispatch: false }
  );

  /* ---------------------------------- users --------------------------------- */
  onUserOnlineEvent$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive<TeamMemberOnlineEventDto>(SocketCustomEventNames.USER_EVENT).pipe(
      map((data: TeamMemberOnlineEventDto) =>
        TeamMemberOnlineEventAction({ payload: data })
      ),
    ),
    { dispatch: true }
  );

  // onConnectedUsersEvent$ = createEffect(() =>
  //   this.socketServices.find(x => x instanceof SocketService)!.onReceive(SocketCustomEventNames.CONNECTED_USERS).pipe(
  //     tap((data: any) => {
  //       console.log('Socket onConnectedUsersEvent', data);
  //     })
  //   ),
  //   { dispatch: false }
  // );

  onSameTypingevent$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive<IsTypingResponseEventDto>(SocketCustomEventNames.MESSAGE_TYPING).pipe(
      map((data: IsTypingResponseEventDto) => SetTypingUserAction({
        payload: {
          channelId: data.channel_id,
          status: data.is_typing,
          user: data.user
        }
      }))
    ),
    { dispatch: true }
  );

  /* ---------------------------- internal messages --------------------------- */
  onInternalMessageEvent$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive<TeamInteraction>(SocketCustomEventNames.INTERNAL_MESSAGE).pipe(
      withLatestFrom(this.store$.select(selectTenantProfile), (interaction, profile) => ({ interaction, profile })),
      tap(async ({ interaction, profile }) => {
        if (profile?.userId !== interaction.sender_id) {
          const silent = await firstValueFrom(this.isSilentMode$);
          !silent && this.audioService.playIncomingTeamInteractionNotification();
        }
      }),
      withLatestFrom(this.store$.select((state) => state.teamInbox), (data, teamInbox) => ({ ...data, teamInbox })),
      withLatestFrom(this.usersViewModel.teamMembers$, (data, users) => ({ ...data, users })),
      tap(({ interaction, profile, users }) => {
        const isInbound = profile!.userId !== (interaction as TeamInteraction).sender_id
        isInbound && this.handleIncomingTeamInteractionUseCase.execute({
          interactions: [interaction as TeamInteraction],
          profile: profile!,
          users
        });
      }),
      filter(({ interaction }) => !interaction.is_room), // can't auto open when is room cuz of the teamChatViewModel.tempActiveRoom$ should be handled glabally in the state to not conflict with already opened user
      // withLatestFrom(this.store$.select((store: AppState) => store.mediaObserver), (data, { toggleThreadRightSidePanel }) => ({ ...data, toggleThreadRightSidePanel })),
      // map(({ interaction, profile, toggleThreadRightSidePanel, users }) => {
      //   const isInbound = profile!.userId !== (interaction as TeamInteraction).sender_id
      //   return OpenTeamChatThreadAction({
      //     payload: {
      //       user: isInbound
      //           ? users.find(u => u.user_id == (interaction as TeamInteraction).sender_id)!
      //           : users.find(u => u.user_id == (interaction as TeamInteraction).receiver_id)!,
      //       idle: toggleThreadRightSidePanel
      //     }
      //   })
      // }),
    ),
    { dispatch: false }
  );

  /* ------------------------------ inline note ------------------------------- */
  onInlineNoteEvent$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive(SocketCustomEventNames.INLINE_NOTE).pipe(
      map(data => typeof data == 'string' ? JSON.parse(data) : data),
      map((data: SocketInteraction) =>
        Object.assign(new SocketInteraction(), data)
      ),
      map((interaction: SocketInteraction) =>
        IncomingInteractionAction({ payload: interaction })
      )
    ),
    { dispatch: true }
  );

  /* ------------------------------ notification ------------------------------ */
  onInternalNotification$ = createEffect(() =>
    this.socketServices.find(x => x instanceof SocketService)!.onReceive(SocketCustomEventNames.INTERNAL_NOTIFICATIONS_V2).pipe(
      map(() => GetUnreadNotificationsCountAction())
    ),
    { dispatch: true }
  );

  /*----------------------- Add Participant to room ------------------------- */
  onAddRoomParticipant$ = createEffect(() =>
    this.socketServices
      .find(x => x instanceof SocketService)!
      .onReceive<{ user_id: string | number }>(SocketCustomEventNames.ADD_PARTICIPANT)
      .pipe(
        withLatestFrom(this.store$.select(selectTenantProfile)),
        tap(([newParticipant, profile]) => {
          if (profile!.userId === newParticipant.user_id) {
            this.teamChatViewModel.fetchTeamRecentMessages(this.teamChatViewModel.interactionType$.getValue());
            this.teamChatViewModel.fetchProfileRooms();
          }
        })
      ),
    { dispatch: false }
  );

  /*----------------------- Remove Participant to room ------------------------- */
  onRemoveRoomParticipant$ = createEffect(() =>
    this.socketServices
      .find(x => x instanceof SocketService)!
      .onReceive<{ user_id: string | number, room_id: string }>(SocketCustomEventNames.REMOVE_PARTICIPANT)
      .pipe(
        withLatestFrom(this.store$.select(selectTenantProfile)),
        tap(([newParticipant, profile]) => {
          if (profile!.userId === newParticipant.user_id) {
            this.teamChatViewModel.deleteTeamRecentInteraction('interaction.user_id', newParticipant.room_id)
            this.teamChatViewModel.fetchProfileRooms();
          }
        })
      ),
    { dispatch: false }
  );

  /*----------------------- Mentioned Participant to room ------------------------- */
  onMentionRoomParticipant$ = createEffect(() =>
    this.socketServices
      .find(x => x instanceof SocketService)!
      .onReceive<{ user_id: string | number, room_id: string }>(SocketCustomEventNames.MENTION_PARTICIPANT)
      .pipe(
        withLatestFrom(this.store$.select(selectTenantProfile)),
        tap(([mentionedParticipant, profile]) => {
          if (profile!.userId === mentionedParticipant.user_id) {
            this.store$.dispatch(GetNotificationsAction({ payload: { page: 1 } }));
          }
        })
      ),
    { dispatch: false }
  );

  /*----------------------- Create Synth Agent ------------------------- */
  onCreateSynthId$ = createEffect(() =>
    this.socketServices
      .find(x => x instanceof SocketService)!
      .onReceive<{ synth_api: any }>(SocketCustomEventNames.CREATE_SYNTH_ID)
      .pipe(
        tap((results) => {
          this.synthViewModel.newCreatedSynthAPI.next({ synth_id: results.synth_api['synth_id'], tenant_id: results.synth_api['tenant_id'], user_id: results.synth_api['user_id'], channel_value: results.synth_api['channel_value'], endpoint_name: results.synth_api['endpoint_name'] });
        }),
        catchError((error) => {
          enableDOM();
          return of(true);
        })
      ),
    { dispatch: false });

  /*----------------------- Retell Transcript ------------------------- */
  onGetRetellTranscrip$ = createEffect(() =>
    this.socketServices
      .find(x => x instanceof SocketService)!
      .onReceive<{ 'transcript': Array<any>, recording_url: string, synth_conversation_id: number }>(SocketCustomEventNames.RETELL_TRANSCRIPT)
      .pipe(
        tap((results) => {
          this.synthViewModel.showFirstTanscription(results);
        })),
    { dispatch: false });

}