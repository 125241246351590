import { Observable } from 'rxjs';
import {
  Task,
  TaskComment,
  TaskDetail,
} from '@/app/core/Models/Task';
import { TaskStatusDto } from '@/app/Data/DTO/TaskStatusDto';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { PatchTaskDto, TaskDto } from '@/app/Data/DTO/TaskDto';
import { TaskPriorityDto } from '@/app/Data/DTO/TaskPriorityDto';

export abstract class ITaskRepository {
  abstract updateTaskStatus(
    request: TaskStatusDto,
  ): Observable<Task>;
  abstract updateTaskPriority(
    request: TaskPriorityDto,
  ): Observable<Task>;
  abstract saveTaskDetail(request: TaskDetail): void;
  abstract getTaskById(taskId: number): Observable<TaskDetail>;
  abstract getLocalTaskById(taskId: number): Observable<TaskDetail | undefined>;
  abstract addComment(
    comment: { comment: string },
    taskId: number
  ): Observable<TaskComment>;
  abstract editComment(
    comment: { comment: string },
    commentId: number,
    taskId: number
  ): Observable<TaskComment>;
  abstract saveLocalTaskComment(comment: TaskComment, tempCommentId?: number): void;
  abstract deleteLocalTaskComment(commentId: number, taskId: number): void;
  abstract deleteTaskComment(
    commentId: number,
    taskId: number
  ): Observable<SuccessResponse>;
  abstract createTask(request: TaskDto): Observable<TaskDetail>;
  abstract editTask(request: TaskDto, taskId: number): Observable<TaskDetail>;
  abstract isUnread(): Observable<SuccessResponse>;
  abstract patchTask(payload: PatchTaskDto): Observable<TaskDetail>;

}
