import { ConfigurationService } from '@/app/shared/Config/configuration.service';
import { EnvironmentType, IConfiguration } from '@/app/shared/Config/IConfig';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { ILogEntry } from './ILogEntry';
import { Logger } from './Logger';
import { LoggingService } from './LoggingService.service';
import { Severity } from './Severity';
@Injectable()
export class ConsoleLogger extends Logger {
  config: IConfiguration;

  constructor(
    public configService: ConfigurationService,
    private loggingService: LoggingService
  ) {
    super();
  }

  init(): void {
    this.configService.settings$
      .pipe(take(1))
      .subscribe((settings) => (this.config = settings));
    this.loggingService.logs$.subscribe((item) => this.handleLogEntry(item));
  }

  public write(): void {
    switch (this.entry?.severity) {
      case Severity.Error:
        console.error(this.entry);
        break;
      case Severity.Critical:
        console.warn(this.entry);
        break;
      case Severity.Debug:
        console.debug(this.entry);
        break;
      case Severity.Information:
        console.log(this.entry);
        break;
    }
  }

  handleLogEntry(entry: ILogEntry) {
    if (entry && this.config?.environment != EnvironmentType.prod) {
      this.entry = entry;
      this.write();
    }
  }
}
