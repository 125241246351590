export class VoiceClientHoldContactDto {
  name = 'customer';
  constructor(private call_sid: string, private on_hold: boolean) {}
  get body() {
    return {
      call_sid: this.call_sid,
      on_hold: this.on_hold,
      name: this.name,
    };
  }
}
