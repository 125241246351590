import { VoiceClientCallSidDto } from '@/app/Data/DTO/VoiceClient/VoiceClientCallSidDto';
import { VoiceClientTransferToUserDto } from '@/app/Data/DTO/VoiceClient/VoiceClientTransferToUserDto';
import { VoiceClientSetUserOnHoldDto } from '../../Data/DTO/VoiceClient/VoiceClientSetUserOnHoldDto';
import { ConferenceHostParticipant } from '../../core/Models/ConferenceParticipant';
import {
  ConferenceCustomerParticipant,
  ConferenceMemberParticipant,
} from '@/app/core/Models/ConferenceParticipant';
import { createAction, props, union } from '@ngrx/store';
import { ConferenceState } from './voice-client.reducer';
import { VoiceClientCallTransferRequestDto } from '@/app/Data/DTO/VoiceClient/VoiceClientCallTransferRequestDto';
import { VoiceClientDropMemberParticipantDto } from '@/app/Data/DTO/VoiceClient/VoiceClientDropUserDto';

export const VOICE_CLIENT_CONFERENCE_INFO_UPDATE = '[VOICE CLIENT] Conference Info Update';
export const VoiceClientConferenceInfoUpdateAction = createAction(
  VOICE_CLIENT_CONFERENCE_INFO_UPDATE,
  props<{ payload: { conference: Pick<ConferenceState, 'date' | 'merged' | 'particpantsNumber'>, parentCallSid: string } }>()
);

export const VOICE_CLIENT_CONFERENCE_CUSTOMER_UPDATE = '[VOICE CLIENT] Conference customer  update';
export const VoiceClientConferenceCustomerUpdateAction = createAction(
  VOICE_CLIENT_CONFERENCE_CUSTOMER_UPDATE,
  props<{
    payload: {
      customer: ConferenceCustomerParticipant;
      parentCallSid: string;
    };
  }>()
);

export const VOICE_CLIENT_CONFERENCE_HOST_UPDATE = '[VOICE CLIENT] Conference host  update';
export const VoiceClientConferenceHostUpdateAction = createAction(
  VOICE_CLIENT_CONFERENCE_HOST_UPDATE,
  props<{
    payload: {
      host: ConferenceHostParticipant;
      parentCallSid: string;
    };
  }>()
);

export const VOICE_CLIENT_CONFERENCE_MEMBER_UPDATE = '[VOICE CLIENT] Conference member update';
export const VoiceClientConferenceMemberUpdateAction = createAction(
  VOICE_CLIENT_CONFERENCE_MEMBER_UPDATE,
  props<{
    payload: {
      member: ConferenceMemberParticipant;
      parentCallSid: string;
    };
  }>()
);

export const VOICE_CLIENT_CONFERENCE_HOST_REMOVE = '[VOICE CLIENT] Conference Host remove';
export const VoiceClientConferenceRemoveHostAction = createAction(
  VOICE_CLIENT_CONFERENCE_HOST_REMOVE,
  props<{
    payload: {
      parentCallSid: string;
    };
  }>()
);

export const VOICE_CLIENT_CONFERENCE_MEMBER_REMOVE = '[VOICE CLIENT] Conference member remove';
export const VoiceClientConferenceRemoveMemberAction = createAction(
  VOICE_CLIENT_CONFERENCE_MEMBER_REMOVE,
  props<{
    payload: {
      member: ConferenceMemberParticipant;
      parentCallSid: string;
    };
  }>()
);

export const VOICE_CLIENT_REQUEST_CALL_TRANSFER = '[VOICE CLIENT] Request call transfer';
export const VoiceClientRequestCallTransferAction = createAction(
  VOICE_CLIENT_REQUEST_CALL_TRANSFER,
  props<{ payload: VoiceClientCallTransferRequestDto }>()
);

export const VOICE_CLIENT_REQUEST_CALL_TRANSFER_SUCCESS = '[VOICE CLIENT] Request call transfer success';
export const VoiceClientRequestCallTransferSuccessAction = createAction(
  VOICE_CLIENT_REQUEST_CALL_TRANSFER_SUCCESS,
  props<{ payload: { parentCallSid: string, member: Pick<ConferenceMemberParticipant, 'id' | 'user_id' | 'displayName' | 'avatar'> } }>()
);

export const VOICE_CLIENT_CONFERENCE_MEMBER_HANGUP = '[VOICE CLIENT] Conference participant member  hangup';
export const VoiceClientConferenceMemberHangUpAction = createAction(
  VOICE_CLIENT_CONFERENCE_MEMBER_HANGUP,
  props<{ payload: VoiceClientDropMemberParticipantDto }>()
);

export const VOICE_CLIENT_CONFERENCE_MEMBER_ON_HOLD = '[VOICE CLIENT] Conference participant put member on hold';
export const VoiceClientConferenceMemberOnHoldAction = createAction(
  VOICE_CLIENT_CONFERENCE_MEMBER_ON_HOLD,
  props<{ payload: VoiceClientSetUserOnHoldDto }>()
);

export const VOICE_CLIENT_CONFERENCE_CALL_TRANSFER = '[VOICE CLIENT] conference make call transfer';
export const VoiceClientMakeTransferCallAction = createAction(
  VOICE_CLIENT_CONFERENCE_CALL_TRANSFER,
  props<{ payload: VoiceClientTransferToUserDto }>()
);

export const VOICE_CLIENT_CONFERENCE_CALL_MERGE = '[VOICE CLIENT] conference make merge call';
export const VoiceClientMakeMergeCallAction = createAction(
  VOICE_CLIENT_CONFERENCE_CALL_MERGE,
  props<{ payload: VoiceClientCallSidDto }>()
);

export const VOICE_CLIENT_UPDATE_ACTIVE_CALLER = '[VOICE CLIENT] Conference Update Active Caller';
export const VoiceClientUpdateActiveCallerAction = createAction(
  VOICE_CLIENT_UPDATE_ACTIVE_CALLER,
  props<{ payload: { id: string, parentCallSid: string } }>()
);

const all = union({
  VoiceClientConferenceInfoUpdateAction,
  VoiceClientConferenceCustomerUpdateAction,
  VoiceClientConferenceHostUpdateAction,
  VoiceClientConferenceMemberUpdateAction,
  VoiceClientConferenceRemoveMemberAction,
  VoiceClientRequestCallTransferAction,
  VoiceClientRequestCallTransferSuccessAction,
  VoiceClientConferenceMemberHangUpAction,
  VoiceClientConferenceMemberOnHoldAction,
  VoiceClientMakeTransferCallAction,
  VoiceClientMakeMergeCallAction,
  VoiceClientUpdateActiveCallerAction,
});

export type VoiceClientTransferActionsUnion = typeof all;

