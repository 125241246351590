import { RecentEmailsQueryDto, ThreadEmailsQueryDto } from '@/app/Data/DTO/emailMessagingDto';
import { ChannelType } from '@/app/core/Models/ChannelTypes';
import { EmailMessageModel } from '@/app/core/Models/Message';
import { EmailClassification, RecentEmailInteraction, ThreadEmailInteraction } from '@/app/core/Models/email-interaction';
import { EmailInbox, Inbox } from '@/app/core/Models/inbox';
import { createAction, props, union } from '@ngrx/store';

export enum emailInboxActions {
  /* ----------------------------------- --- ---------------------------------- */
  FetchEmailClassifications = '[email inbox] Fetch Email Classifications',
  FetchEmailClassificationsSuccess = '[email inbox] Fetch Email Classifications success',
  FetchEmailClassificationsFail = '[email inbox] Fetch Email Classifications Fail',
  /* ----------------------------------- --- ---------------------------------- */
  FetchRecentEmails = '[email inbox] fetch recent emails',
  FetchRecentEmailsSuccess = '[email inbox] fetch recent emails success',
  FetchRecentEmailsFail = '[email inbox] fetch recent emails Fail',
  /* ----------------------------------- --- ---------------------------------- */
  SelectEmailInbox = '[email inbox] Select email inbox',
  SelectEmailInboxSuccess = '[email inbox] Select email inbox success',
  SelectEmailInboxFail = '[email inbox] Select email inbox Fail',
  /* ----------------------------------- --- ---------------------------------- */
  SelectEmailClassification = '[email inbox] Select email classification',
  SelectEmailClassificationSuccess = '[email inbox] Select email classification success',
  SelectEmailClassificationFail = '[email inbox] Select email classification Fail',
  /* ----------------------------------- --- ---------------------------------- */
  FetchEmailInboxes = '[email inbox] Fetch Email Inboxes',
  FetchEmailInboxesSuccess = '[email inbox] Fetch Email Inboxes success',
  FetchEmailInboxesFail = '[email inbox] Fetch Email Inboxes Fail',
  /* ----------------------------------- --- ---------------------------------- */
  // SetActiveEmailConversation = '[email inbox] Set active email Conversation',
  FetchEmailInteractions = '[email inbox] Fetch email interactions',
  FetchEmailInteractionsSuccess = '[email inbox] Fetch email interactions Success',
  FetchEmailInteractionsFail = '[email inbox] Fetch email interactions Fail',

  HandleEmailInteraction = '[email inbox] Handle socket email interactions',
  /* ----------------------------------- --- ---------------------------------- */
  SendReplyEmail = '[email inbox] send reply Email',
  SendReplyEmailSuccess = '[email inbox] send reply Email success',
  SendReplyEmailFail = '[email inbox] send reply Email fail',
  /* ----------------------------------- --- ---------------------------------- */
  SendEmailNote = '[email inbox] send email note',
  SendEmailNoteSuccess = '[email inbox] send email note success',
  SendEmailNoteFail = '[email inbox] send email note fail',
  /* ----------------------------------- --- ---------------------------------- */
  // updateLocalEmailInteraction = '[email inbox] update local email interaction',
  /* ----------------------------------- --- ---------------------------------- */
  UpdateEmailReadStatus = '[email inbox] update read status',
  UpdateEmailReadStatusSuccess = '[email inbox] update read status success',
  UpdateEmailReadStatusFail = '[email inbox] update read status fail',
}

export const FetchEmailClassificationsAction = createAction(
  emailInboxActions.FetchEmailClassifications,
  props<{ payload: { channelId: number } }>()
)
export const FetchEmailClassificationsSuccessAction = createAction(
  emailInboxActions.FetchEmailClassificationsSuccess,
  props<{ payload: EmailInbox }>()
)
export const FetchEmailClassificationsFailAction = createAction(
  emailInboxActions.FetchEmailClassificationsFail
)
/* ----------------------------------- --- ---------------------------------- */
export const FetchRecentEmailsAction = createAction(
  emailInboxActions.FetchRecentEmails,
  props<{ payload: { query: RecentEmailsQueryDto } }>()
)
export const FetchRecentEmailsSuccessAction = createAction(
  emailInboxActions.FetchRecentEmailsSuccess,
  props<{ payload: { recentEmails: RecentEmailInteraction[], query: RecentEmailsQueryDto } }>()
)
export const FetchRecentEmailsFailAction = createAction(
  emailInboxActions.FetchRecentEmailsFail,
  props<{ payload: string }>()
)
/* ----------------------------------- --- ---------------------------------- */
export const SelectEmailInboxAction = createAction(
  emailInboxActions.SelectEmailInbox,
  props<{ payload: { inbox: Inbox } }>()
)
export const SelectEmailInboxSuccessAction = createAction(
  emailInboxActions.SelectEmailInboxSuccess,
)
/* ----------------------------------- --- ---------------------------------- */
export const SelectEmailClassificationAction = createAction(
  emailInboxActions.SelectEmailClassification,
  props<{ payload: { classificationId: number } }>()
)
export const SelectEmailClassificationSuccessAction = createAction(
  emailInboxActions.SelectEmailClassificationSuccess,
)
/* ----------------------------------- --- ---------------------------------- */

export const FetchEmailInboxesSuccessAction = createAction(
  emailInboxActions.FetchEmailInboxesSuccess,
  props<{ payload: { inboxes: Inbox[] } }>()
)
/* ----------------------------------- --- ---------------------------------- */
export const FetchEmailInteractionsAction = createAction(
  emailInboxActions.FetchEmailInteractions,
  props<{ payload: { query: ThreadEmailsQueryDto } }>()
)
export const FetchEmailInteractionsSuccessAction = createAction(
  emailInboxActions.FetchEmailInteractionsSuccess,
  props<{ payload: { threadEmails: ThreadEmailInteraction[], query: ThreadEmailsQueryDto } }>()
)
export const FetchEmailInteractionsFailAction = createAction(
  emailInboxActions.FetchEmailInteractionsFail,
  props<{ payload: string }>()
)
/* ----------------------------------- --- ---------------------------------- */
export const HandleEmailInteractionAction = createAction(
  emailInboxActions.HandleEmailInteraction,
  props<{ payload: ThreadEmailInteraction }>()
)
/* ----------------------------------- --- ---------------------------------- */
export const SendReplyEmailAction = createAction(
  emailInboxActions.SendReplyEmail,
  props<{ payload: { data: FormData, channelType: ChannelType.Outlook | ChannelType.Google } }>()
)
export const SendReplyEmailSuccessAction = createAction(
  emailInboxActions.SendReplyEmailSuccess,
  props<{ payload: any }>()
)
export const SendReplyEmailFailAction = createAction(
  emailInboxActions.SendReplyEmailFail,
  props<{ payload: any }>()
)
/* ---------------------------- send inline note ---------------------------- */
export const SendEmailNoteAction = createAction(
  emailInboxActions.SendEmailNote,
  props<{ payload: { content: string, conversationId: number } }>()
)
export const SendEmailNoteSuccessAction = createAction(
  emailInboxActions.SendEmailNoteSuccess,
  props<{ payload: ThreadEmailInteraction }>()
)
export const SendEmailNoteFailAction = createAction(
  emailInboxActions.SendEmailNoteFail,
  props<{ payload: any }>()
)
/* ----------------------------------- --- ---------------------------------- */
// export const UpdateLocalEmailInteractionAction = createAction(
//   emailInboxActions.updateLocalEmailInteraction,
//   props<{ payload: ThreadEmailInteraction }>()
// )
/* ----------------------------------- --- ---------------------------------- */
export const UpdateEmailReadStatusAction = createAction(
  emailInboxActions.UpdateEmailReadStatus,
  props<{ payload: { channelId: number, conversation_id: number } }>()
)
export const UpdateEmailReadStatusSuccessAction = createAction(
  emailInboxActions.UpdateEmailReadStatusSuccess,
  props<{ payload: { channelId: number } }>()
)
export const UpdateEmailReadStatusFailAction = createAction(
  emailInboxActions.UpdateEmailReadStatusFail,
  props<{ payload: any }>()
)

const all = union({
  FetchEmailClassificationsAction,
  FetchEmailClassificationsSuccessAction,

  FetchRecentEmailsAction,
  FetchRecentEmailsSuccessAction,

  SelectEmailInboxAction,
  SelectEmailInboxSuccessAction,

  SelectEmailClassificationAction,
  SelectEmailClassificationSuccessAction,

  FetchEmailInboxesSuccessAction,

  FetchEmailInteractionsAction,
  HandleEmailInteractionAction,

  SendReplyEmailAction,
  SendReplyEmailSuccessAction,

  SendEmailNoteAction,
  SendEmailNoteSuccessAction,

  UpdateEmailReadStatusAction,
  UpdateEmailReadStatusSuccessAction,
});

export type EmailInboxActions = typeof all;
