import { BasicCustomFieldDetails, CustomFieldDetails } from '@/app/core/Models/contact';
import { map, Observable, pluck } from 'rxjs';
import { environment } from '@/environments/environment';
import { HttpRequestMethod } from '@/app/Data/services/Networking/HttpRequestMethod';
import { SuccessApiResponse } from '@/app/Data/services/Networking/ApiResponse';
import { HttpService } from '@/app/Data/services/Networking/HttpService';
import { Injectable } from '@angular/core';
import { CustomFieldDeletedResultDto, CustomFieldPinResultDto, renameFieldReuqestDto, UpdateCustomFieldRequestDto } from '../DTO/CustomFieldDto';
import { ICustomFieldsRepository } from '@/app/core/IRepositories/ICustomFieldsRepository';

@Injectable()
export class CustomFieldsRepository implements ICustomFieldsRepository {
  constructor(
    private httpService: HttpService
  ) {}

  updateContactCustomField(request: UpdateCustomFieldRequestDto, contactId: number): Observable<CustomFieldDetails> {
    const requestURL = `${environment.apiURL}contacts/${contactId}/custom`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CustomFieldDetails>;
        return res.results;
      })
    );
  }

  getCustomFields(): Observable<BasicCustomFieldDetails[]> {
    const requestURL = `${environment.apiURL}tenants/custom`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<BasicCustomFieldDetails[]>;
        return res.results;
      })
    );
  }

  deleteCustomField(fieldId: number): Observable<CustomFieldDeletedResultDto> {
    const requestURL = `${environment.apiURL}tenants/custom/${fieldId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CustomFieldDeletedResultDto>;
        return res.results;
      })
    );
  }

  pinField(fieldId: number, status: boolean): Observable<CustomFieldPinResultDto> {
    const requestURL = `${environment.apiURL}tenants/custom/pin`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      {
        tenant_cf_id: fieldId,
        status: status
      },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CustomFieldPinResultDto>;
        return res.results;
      })
    );
  }

  getChannelCustomField(channelId: number): Observable<CustomFieldDetails[] | null> {
    const requestURL = `${environment.apiURL}unclassified-channels/${channelId}/custom`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CustomFieldDetails[] | null>;
        return res.results;
      })
    );
  }

  updateChannelCustomField(request: UpdateCustomFieldRequestDto, channelId: number): Observable<CustomFieldDetails> {
    const requestURL = `${environment.apiURL}unclassified-channels/${channelId}/custom`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CustomFieldDetails>;
        return res.results;
      })
    );
  }

  renameCustomField(request: renameFieldReuqestDto): Observable<CustomFieldDetails> {
    const requestURL = `${environment.apiURL}tenants/custom/rename`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CustomFieldDetails>;
        return res.results;
      })
    );
  }

}
