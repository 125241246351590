import { environment } from '@/environments/environment';
import { Inject, Injectable } from '@angular/core';
import { from, map, NEVER, Observable, of, pluck, switchMap, tap } from 'rxjs';
import { DatabaseService } from '../services/Database/database.service';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';
import { INotesRepository } from '@/app/core/IRepositories/INotesRepository';
import { Note } from '@/app/core/Models/Note';
import { NoteDto } from '@/app/Data/DTO/NoteDto';
import { liveQuery } from 'dexie';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';

@Injectable({
  providedIn: 'root',
})
export class NotesRepository implements INotesRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) {}

  updateNote(
    data: NoteDto,
    noteId: number,
    contactId: number
  ): Observable<Note> {
    const requestURL = `${environment.apiURL}contacts/${contactId}/notes_v2/${noteId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  createNote(data: NoteDto, contactId: number): Observable<Note> {
    const requestURL = `${environment.apiURL}contacts/${contactId}/notes_v2`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  createUnclassifiedChannelNote(
    data: NoteDto,
    channelId: number
  ): Observable<Note> {
    const requestURL = `${environment.apiURL}unclassified-channels/${channelId}/notes_v2`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results.note;
      })
    );
  }

  updateUnclassifiedChannelNote(
    data: NoteDto,
    noteId: number,
    channelId: number
  ): Observable<Note> {
    const requestURL = `${environment.apiURL}unclassified-channels/${channelId}/notes_v2/${noteId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results.note;
      })
    );
  }

  getLocalNotesById(threadId: number, unclassified: boolean): Observable<Note[]> {
    return from(
      liveQuery(() => {
        this.databaseService.notes.mapToClass(Note);
        return this.databaseService.notes
          .where(unclassified ? 'unclassified_channel_id' : 'contact_id')
          .equals(threadId)
          .toArray();
      })
    ).pipe(
      map((notes: Note[]) => {
        return notes.sort((a, b) => {
          const dateA = new Date(a.created_dt);
          const dateB = new Date(b.created_dt);
          return dateB.getTime() - dateA.getTime();
        });
      })
    );
  }

  getNotesByContactId(contactId: number): Observable<Note[]> {
    const requestURL = `${environment.apiURL}contacts/${contactId}/notes`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }
  saveAndDeleteNotes(notes: Note[], forId: number): void {
    this.databaseService
      .transaction('rw!', this.databaseService.notes, async () => {
        const localNotes = await this.databaseService.notes
          .where('contact_id')
          .equals(forId)
          .or('unclassified_channel_id')
          .equals(forId)
          .toArray();
        const newNotesKeys = notes.map((note) => note.note_id);
        const deletedNotes = localNotes
          .filter((item) => newNotesKeys.indexOf(item.note_id) == -1)
          .map((item) => item.note_id as unknown as string);
        await this.databaseService.notes.bulkDelete(deletedNotes);
        await this.databaseService.notes.bulkPut(notes);
      })
      .catch((error) => {
        console.log('Transaction Failed');
      });
  }

  saveNotes(notes: Note[]): void {
    this.databaseService.notes.bulkPut(notes).then().catch();
  }

  deleteContactNote(
    noteId: number,
    contactId: number
  ): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}contacts/${contactId}/notes/${noteId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  deleteUnclassifiedChannelNote(
    noteId: number,
    channelId: number
  ): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}unclassified-channels/${channelId}/notes/${noteId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }
  deleteSavedNote(noteId: number): void {
    this.databaseService.notes.delete(noteId as unknown as string).then().catch();
  }

  getNotesByChannelId(channelId: number): Observable<Note[]> {
    const requestURL = `${environment.apiURL}unclassified-channels/${channelId}/notes`;1
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item: any) => {
        if (item?.results?.notes) {
          const result = { results: item?.results?.notes };
          let res = result as SuccessApiResponse<any>;
          return res.results;
        } else {
          let res = item as SuccessApiResponse<any>;
          return res.results;
        }
      })
    );
  }
}
