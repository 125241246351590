import { Injectable } from '@angular/core';

import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { HttpService } from '@/app/Data/services/Networking/HttpService';

import {
  AddPeopleCompanyDto,
  CreateCompanyDto,
  AssociatedContactToCompanyDto,
  RemoveAssociatedContactToCompanyDto,
  UpdateAssociatedContactRelationshipTypeDto,
  CreateCompanyNoteDto,
  DeleteCompanyNoteDto,
  DeleteCompanyCustomFieldDto,
  AddCompanyCustomFieldsDto,
  AssociateCompanyToCompanyDto,
  DeleteCompanyAssociationDto
} from '../DTO/companyDto';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { SuccessApiResponse } from '@/app/Data/services/Networking/ApiResponse';
import { HttpRequestMethod } from '@/app/Data/services/Networking/HttpRequestMethod';
import { CompanyImageUpdatResponseDto } from '@/app/Data/DTO/companyImageUpdatResponseDto';
import { ICompanyRepository } from '@/app/core/IRepositories/ICompanyRepository';
import {
  AssociatedContact,
  CompaniesQueryParams,
  Company,
  CompanyCustomFields,
  CompanyNotes,
  CompanyTasks,
  AssociatedCompanies,
  AssociatedCompany,
  ImportCompaniesCSV,
  ICompanyAttachments,
  SelectedPipeline
} from '@/app/core/Models/company';
import { getUploadEventProgress } from '@/app/Utilities/functions/getUploadEventProgress';

import { environment } from '@/environments/environment';

import { liveQuery } from 'dexie';
import { catchError, from, last, map, Observable, Observer, pluck, tap, throwError } from 'rxjs';
import { Deal } from '@/app/core/Models/deal';
import { IActivity } from '../services/Activity/activity.service';

@Injectable()
export class CompanyRepository implements ICompanyRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) { }

  getLocalCompany(companyId: string): Observable<Company | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.companies,
          async () => {
            this.databaseService.companies.mapToClass(Company);
            return this.databaseService.companies.get(companyId)
          }
        );
      })
    );
  }

  saveCompanyLocal(company: Company): void {
    this.databaseService.companies.put(company);
  }

  deleteLocalCompany(companyId: string): void {
    this.databaseService.companies.delete(companyId).catch();
  }

  saveCompanies(companies: Company[], params: CompaniesQueryParams): void {
    this.databaseService.transaction('rw!', this.databaseService.companies, async () => {
      Boolean(params.search) && await this.databaseService.companies.clear();
      await this.databaseService.companies.bulkPut(companies);
    }).catch((error) => {
      console.error('Transaction Failed: ', error);
    });
  }

  getLocalCompanies(): Observable<Company[]> {
    return from(
      liveQuery(() => {
        this.databaseService.companies.mapToClass(Company);
        return this.databaseService.companies.orderBy('company_name').toArray();
      })
    );
  }

  updateLocalCompanyDetails(companyId: string, details: any): void {
    this.databaseService.companies.update(companyId, details).then();
  }

  getAssociatedContactsToCompanyLocal(companyId: string): Observable<AssociatedContact[] | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.companies,
          async () => {
            const company = await this.databaseService.companies.get({ 'associated_contacts.yobi_crm_company_id': companyId });
            return company?.associated_contacts?.contacts;
          }
        );
      })
    );
  }

  getAssociatedCompanyToCompanyLocal(companyId: string): Observable<AssociatedCompany[] | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.companies,
          async () => {
            const company = await this.databaseService.companies.get({ 'associated_companies.yobi_crm_company_id': companyId });
            return company?.associated_companies?.associated_companies;
          }
        )
      })
    );
  }

  getAssociatedDealsToCompanyLocal(companyId: string): Observable<Deal[] | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.companies,
          async () => {
            const company = await this.databaseService.companies.get({ 'yobi_crm_company_id': companyId });
            return company?.associated_deals;
          }
        )
      })
    );
  }

  getAssociatedCompanyNotesLocal(companyId: string): Observable<CompanyNotes[] | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.companies,
          async () => {
            const company = await this.databaseService.companies.get({ 'yobi_crm_company_id': companyId });
            return company?.associated_notes;
          }
        );
      })
    );
  }

  getAssociatedCompanyTasksLocal(companyId: string): Observable<CompanyTasks[] | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.companies,
          async () => {
            const company = await this.databaseService.companies.get({ 'yobi_crm_company_id': companyId });
            return company?.associated_tasks;
          }
        );
      })
    );
  }

  getAssociatedCompanyCustomFieldsLocal(companyId: string): Observable<CompanyCustomFields[] | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.companies,
          async () => {
            const company = await this.databaseService.companies.get({ 'yobi_crm_company_id': companyId });
            return company?.associated_custom_fields;
          }
        );
      })
    );
  }

  createCompany(data: CreateCompanyDto): Observable<Company> {
    const requestURL = `${environment.apiURL}company`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Company>;
        return res.results;
      })
    );
  }

  editCompany(companyId: string, data: CreateCompanyDto): Observable<Company> {
    const requestURL = `${environment.apiURL}company/${companyId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Company>;
        return res.results;
      })
    );
  }

  deleteCompany(companyIds: string[]): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}company`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      { "yobi_crm_company_id": companyIds },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  getCompanyById(companyId: string): Observable<Company> {
    const requestURL = `${environment.apiURL}company/${companyId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Company>;
        return res.results;
      })
    );
  }

  uploadCompanyLogo(data: FormData, companyId: string, progress$: Observer<any>): Observable<CompanyImageUpdatResponseDto> {
    const requestURL = `${environment.apiURL}company/${companyId}/avatar`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false,
      null,
      true
    );
    return this.httpService.upload(options).pipe(
      tap((event: any) => getUploadEventProgress(event, progress$)),
      last(),
      map((item) => {
        let response = new SuccessApiResponse<SuccessResponse>();
        response.results = item;
        return response;
      }),
      pluck('results'),
      pluck('body'),
      map((item) => {
        let res = item as SuccessApiResponse<CompanyImageUpdatResponseDto>;
        return res.results;
      })
    );
  }

  deleteCompanyLogo(companyId: string): Observable<CompanyImageUpdatResponseDto> {
    const requestURL = `${environment.apiURL}company/${companyId}/avatar`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CompanyImageUpdatResponseDto>;
        return res.results;
      })
    );
  }

  getCompanies(params: CompaniesQueryParams): Observable<{ companies: Company[], total_count: number }> {
    const requestURL = `${environment.apiURL}company`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      params
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<{ companies: Company[], total_count: number }>;
        return res.results;
      })
    );
  }

  associateContactToCompany(data: AddPeopleCompanyDto[]): Observable<AddPeopleCompanyDto[]> {
    const requestURL = `${environment.apiURL}company-contacts/associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { "company_contact_association": data },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<AddPeopleCompanyDto[]>;
        return res.results;
      })
    );
  }

  getAssociatedContactsToCompany(params: CompaniesQueryParams): Observable<AssociatedContactToCompanyDto> {
    const requestURL = `${environment.apiURL}company/${params.companyId}/contact-associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<AssociatedContactToCompanyDto>;
        return res.results;
      })
    );
  }

  removeAssociatedContactToCompanyDto(data: RemoveAssociatedContactToCompanyDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}company-contacts/associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  updateAssociatedContactRelationshipType(data: UpdateAssociatedContactRelationshipTypeDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}company-contacts/associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  createCompanyNote(data: CreateCompanyNoteDto): Observable<CompanyNotes> {
    const requestURL = `${environment.apiURL}company/${data.yobi_crm_company_id}/notes`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CompanyNotes>;
        return res.results;
      })
    );
  }

  updateCompanyNote(data: CreateCompanyNoteDto): Observable<CompanyNotes> {
    const requestURL = `${environment.apiURL}company/${data.yobi_crm_company_id}/notes/${data.note_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CompanyNotes>;
        return res.results;
      })
    );
  }

  deleteCompanyNote(data: DeleteCompanyNoteDto): Observable<CompanyNotes> {
    const requestURL = `${environment.apiURL}company/${data.yobi_crm_company_id}/notes/${data.note_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CompanyNotes>;
        return res.results;
      })
    );
  }

  getCompanyNotes(params: CompaniesQueryParams): Observable<CompanyNotes[]> {
    const requestURL = `${environment.apiURL}company/${params.companyId}/notes?page=${params.page}&page_size=${params.page_size || 25}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CompanyNotes[]>;
        return res.results;
      })
    );
  }

  getCompanyTasks(params: CompaniesQueryParams): Observable<CompanyTasks[]> {
    const requestURL = `${environment.apiURL}company/${params.companyId}/tasks?page=${params.page}&page_size=${params.page_size || 25}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CompanyTasks[]>;
        return res.results;
      })
    );
  }

  getCompanyCustomFields(params: CompaniesQueryParams): Observable<CompanyCustomFields[]> {
    const requestURL = `${environment.apiURL}company/${params.companyId}/custom_fields`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CompanyCustomFields[]>;
        return res.results;
      })
    );
  }

  deleteCompanyCustomFields(data: DeleteCompanyCustomFieldDto): Observable<CompanyCustomFields> {
    const requestURL = `${environment.apiURL}company/${data.company_custom_field_id}/custom_fields_value`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CompanyCustomFields>;
        return res.results;
      })
    );
  }

  createCompanyCustomFields(data: AddCompanyCustomFieldsDto[]): Observable<CompanyCustomFields> {
    const requestURL = `${environment.apiURL}company/${data[0].yobi_crm_company_id}/custom_fields`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { "company_custom_fields": data },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CompanyCustomFields>;
        return res.results;
      })
    );
  }

  updateCompanyCustomFields(data: AddCompanyCustomFieldsDto[]): Observable<CompanyCustomFields> {
    const requestURL = `${environment.apiURL}company/custom_fields_value`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CompanyCustomFields>;
        return res.results;
      })
    );
  }

  getAssociatedCompanyToCompany(params: CompaniesQueryParams): Observable<AssociatedCompanies> {
    const requestURL = `${environment.apiURL}company/${params.companyId}/company-associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<AssociatedCompanies>;
        return res.results;
      })
    );
  }

  associateCompanyToCompany(data: AssociateCompanyToCompanyDto[]): Observable<AssociateCompanyToCompanyDto[]> {
    const requestURL = `${environment.apiURL}company-company/associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<AssociateCompanyToCompanyDto[]>;
        return res.results;
      })
    );
  }

  updateAssociatedCompanyAssociationType(data: AssociateCompanyToCompanyDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}company-company/associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  removeAssociatedCompanyToCompany(data: DeleteCompanyAssociationDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}company-company/associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  getAssociatedDealsToCompany(params: CompaniesQueryParams): Observable<Deal[]> {
    const requestURL = `${environment.apiURL}company/${params.companyId}/deals`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Deal[]>;
        return res.results;
      })
    );
  }

  importCompaniesViaCSV(data: FormData, redis_id: string): Observable<ImportCompaniesCSV> {
    const requestURL = redis_id ? `${environment.apiURL}company/csv-import?redis_id=${redis_id}` : `${environment.apiURL}company/csv-import`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      catchError((error) => {
        // Handle errors here, e.g., log, display error message, or retry
        console.error('Error importing companies:', error);
        // You can throw a new error or return an empty observable to handle the error
        return throwError(() => new Error('Failed to import companies'));
      }),
      map((item) => {
        let res = item as SuccessApiResponse<ImportCompaniesCSV>;
        return res.results;
      })
    )
  }

  getCompanyActivityLocal(companyId: string): Observable<IActivity[] | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.companies,
          async () => {
            const company = await this.databaseService.companies.get({ 'yobi_crm_company_id': companyId });
            return company?.activities;
          }
        )
      })
    );
  }

  getCompanyAttachments(yobi_crm_company_id: string): Observable<ICompanyAttachments> {
    const requestURL = `${environment.apiURL}company/${yobi_crm_company_id}/attachments`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<ICompanyAttachments>;
        return res.results;
      })
    );
  }

  getCompanyAttachmentsLocal(yobi_crm_company_id: string): Observable<any[] | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.companies,
          async () => {
            const company = await this.databaseService.companies.get({ 'yobi_crm_company_id': yobi_crm_company_id });
            return company?.associated_attachments;
          }
        )
      })
    );
  }

  uploadCompanyAttachments(files: FormData, yobi_crm_company_id: string, progress$: Observer<any>): Observable<ICompanyAttachments> {
    const requestURL = `${environment.apiURL}company/${yobi_crm_company_id}/attachments`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      files,
      false,
      null,
      true
    );
    return this.httpService.upload(options).pipe(
      tap((event: any) => getUploadEventProgress(event, progress$)),
      last(),
      pluck('body'),
      map((item) => {
        let res = item as SuccessApiResponse<ICompanyAttachments>;
        return res.results;
      })
    );
  }

  deleteCompanyAttachments(deleted_files: string[], yobi_crm_company_id: string): Observable<ICompanyAttachments> {
    const requestURL = `${environment.apiURL}company/${yobi_crm_company_id}/attachments`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      { deleted_files: deleted_files },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<ICompanyAttachments>;
        return res.results;
      })
    );
  }

  getCompanyPipelines(company_id: number, pipeline_id: number): Observable<any> {
    const requestURL = `${environment.apiURL}company/${company_id}/pipeline/${pipeline_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  selectCompanyStage(data: Array<{ yobi_crm_company_id: number, yobi_crm_pipeline_id: number, current_stage_id: number, description: string }>): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}company/${data[0].yobi_crm_company_id}/pipeline-associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      { "pipeline_company_associations": data },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  getAllPipelinesCompany(company_id: number): Observable<SelectedPipeline[]> {
    const requestURL = `${environment.apiURL}company/${company_id}/pipelines`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SelectedPipeline[]>;
        return res.results;
      })
    );
  }


}