<div class="mat-dialog-header text-dark gap-4 border-b">
  <h2 class="m-0 text-lg font-semibold">
    {{ localization.labels.task_details | translate }}
  </h2>
  <svg-icon
    class="text-pale hover:text-warning cursor-pointer ml-auto"
    [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
    src="assets/icons/new-set/close.svg"
    (click)="close()"
  >
  </svg-icon>
</div>

<div class="mat-dialog-body text-dark flex flex-col gap-4">
  <ng-container *ngIf="task$ | async as task; else loadingRef">
    <!-- ------------------------------- status -------------------------------- -->
    <div class="surface-bg-color light-shadow flex flex-col py-3 px-4 rounded-md gap-2">
      <div class="flex items-center gap-4 text-strong">
        <ng-container *ngIf="!(updatingStatus$ | async); else loadingSpinnerRef">
          <ng-container [ngSwitch]="task.status">

            <svg-icon
              *ngSwitchCase="TaskStatus.open"
              src="assets/icons/new-set/task-status/upcoming.svg"
              [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
              class="cursor-pointer text-dark hover:text-primary"
              matTooltip="Mark as in progress"
              (click)="updateStatusToInProgress(task.task_id)"
            >
            </svg-icon>
            <svg-icon
              *ngSwitchCase="TaskStatus.inProgress"
              src="assets/icons/new-set/task-status/loading.svg"
              [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
              class="cursor-pointer text-dark hover:text-primary"
              matTooltip="Mark as done"
              (click)="updateStatusToDone(task.task_id)"
            >
            </svg-icon>
            <svg-icon
              *ngSwitchCase="TaskStatus.done"
              src="assets/icons/new-set/task-status/done.svg"
              [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
              class="cursor-pointer text-dark hover:text-primary"
              matTooltip="Mark as open"
              (click)="updateStatusToOpen(task.task_id)"
            >
            </svg-icon>
            <svg-icon
              *ngSwitchCase="TaskStatus.archived"
              src="assets/icons/new-set/task-status/archived.svg"
              [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
              class="cursor-pointer text-dark hover:text-primary"
            >
            </svg-icon>
          </ng-container>
        </ng-container>
        <div class="text-xl font-medium">{{ task.title }}</div>
      </div>
      <div class="flex items-center gap-4 ml-auto">
        <ng-container *ngIf="task.interaction">
          <div
            *ngIf="showEditOption"
            (click)="openThread(task.interaction)"
            class="flex flex-center gap-1 text-primary cursor-pointer whitespace-nowrap"
          >
            <span class="text-base font-medium">{{ localization.labels.see_conversation | translate }}</span>
            <svg-icon
              class="cursor-pointer text-primary"
              src="assets/icons/new-set/open-link.svg"
              matTooltip="Edit Task"
              [svgStyle]="{ 'height.px': 18, 'width.px': 18 }"
            ></svg-icon>
          </div>
          <mat-divider
            [vertical]="true"
            class="secondary-border-color h-6"
          ></mat-divider>
        </ng-container>
        <div
          *ngIf="showEditOption"
          (click)="editTask()"
          class="flex flex-center gap-1 text-primary cursor-pointer whitespace-nowrap"
        >
          <span class="text-base font-medium">{{ localization.labels.edit | translate }}</span>
          <svg-icon
            class="cursor-pointer text-primary"
            src="assets/icons/new-set/edit.svg"
            matTooltip="Edit Task"
            [svgStyle]="{ 'height.px': 18, 'width.px': 18 }"
          ></svg-icon>
        </div>
      </div>
    </div>
    <!-- ----------------------------- assigned to ----------------------------- -->
    <div
      *ngIf="task?.assigned"
      class="surface-bg-color light-shadow flex py-3 px-4 rounded-md gap-4"
    >
      <span class="text-base font-medium">
        {{localization.labels.assign_to | translate }} :
      </span>
      <div class="flex items-center gap-3">
        <app-avatar
          [fontSize]="11"
          class="h-6 w-6 shrink-0"
          [url]="task.assigned!.avatar"
          [name]="task.assigned!.firstName + ' ' + task.assigned!.lastName"
        ></app-avatar>
        <p class="text-base font-medium">
          {{ task.assigned!.firstName }} {{ task.assigned!.lastName }}
        </p>
      </div>
    </div>
    <!-- ------------------------------ reminder ------------------------------- -->
    <div
      *ngIf="task._reminder"
      class="surface-bg-color light-shadow flex justify-between py-3 px-4 rounded-md gap-3"
    >
      <span class="text-base font-medium">{{ 'Reminder' }} :</span>
      <div class="flex items-center gap-1">
        <span>
          {{ task._reminder | date:"MMM dd, yy - hh:mm a" }}
        </span>
        <svg-icon
          src="assets/icons/new-set/bell.svg"
          [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
        ></svg-icon>
      </div>
    </div>
    <!-- ----------------------------- description ----------------------------- -->
    <div class="surface-bg-color light-shadow flex flex-col py-3 px-4 rounded-md gap-3">
      <p class="text-base font-medium">
        {{localization.terms.description | translate}} :
      </p>
      <app-task-description [task]="task"></app-task-description>
    </div>
    <!-- ----------------------------- attachments ----------------------------- -->
    <div class="surface-bg-color light-shadow flex flex-col py-3 px-4 rounded-md gap-3">
      <p class="text-base font-medium">
        {{ localization.labels.task_attachments | translate}} :
      </p>
      <app-attachments-viewer
        *ngIf="task.attachments.length"
        [attachments]="(taskAttachments$ | async)!"
      ></app-attachments-viewer>
    </div>
    <!-- ----------------------------- Activities ------------------------------ -->
    <div
      class="surface-bg-color light-shadow flex flex-col py-3 px-4 rounded-md gap-3 overflow-y-auto"
      style="flex: 1 0 auto"
      #activities_container
      *ngIf="profile$ | async as profile"
    >
      <p class="text-base font-medium">
        {{localization.terms.activities | translate}} :
      </p>
      <app-task-logs
        class="block ml-4"
        [log]="{
          content: 'Task Created by ' + task.creator.firstName + ' ' + task.creator.lastName,
          created_dt: task.created_dt,
          log_id: 0,
          task_id: task.task_id
        }"
      ></app-task-logs>
      <ng-container *ngFor="let activity of activity$ | async">
        <app-task-logs
          class="block ml-4"
          [log]="activity.log"
          *ngIf="activity.log"
        ></app-task-logs>
        <app-task-comment
          class="ml-4"
          *ngIf="activity.comment"
          (onEdit)="commentEdit($event, task.task_id)"
          (onEditEnabled)="enableEditForComment($event)"
          (onCommentDelete)="deleteComment($event, task.task_id)"
          (onCommentResend)="resendComment(activity, task.task_id)"
          [comment]="activity.comment"
          [editableCommentId]="editableCommentId$ | async"
          [userId]="(profile$ | async)?.userId"
          [members]="(mentions$ | async) ?? []"
        ></app-task-comment>
      </ng-container>
    </div>
  </ng-container>
</div>

<div class="mat-dialog-footer text-dark border-t">
  <app-task-comment-input
    *ngIf="task$ | async as task"
    (onComment)="addTaskComment($event, task.task_id)"
    [profile]="profile$ | async"
    [members]="(mentions$ | async) ?? []"
  >
  </app-task-comment-input>
</div>


<ng-template #loadingRef>
  <div class="flex flex-1 w-full h-full">
    <div class="animate-pulse flex flex-1 flex-col gap-6">
      <div class="flex secondary-bg-color rounded-md h-12"></div>
      <div class="flex secondary-bg-color rounded-md h-12"></div>
      <div class="flex secondary-bg-color rounded-md h-28"></div>
      <div class="flex secondary-bg-color rounded-md flex-1"></div>
      <div class="flex secondary-bg-color rounded-md h-16"></div>
    </div>
  </div>
</ng-template>

<ng-template #loadingSpinnerRef>
  <mat-spinner diameter="20"></mat-spinner>
</ng-template>
