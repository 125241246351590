import { Localizations as localization } from '@/app/Utilities/localization/index';
import { ChannelType } from '@/app/core/Models/ChannelTypes';
import { EmailBody, InlineNoteBody, InteractionDirection, RecentInteraction } from '@/app/core/Models/Interaction';
import { changeYobiSupportPreviewText } from '../helpers';

export const interactionPreviewTransformer = (recentInteraction: RecentInteraction) => {
  const value = (recentInteraction.body ?? [])
    .map(value => ({ ...value, content_type: value.content_type ?? (value as any)['content-type'], content: changeYobiSupportPreviewText(value.content) }))
    .filter(value => ['text', 'task', 'incoming-call', 'web_call', 'missed-call', 'outgoing-call', 'image', 'audio', 'video', 'youtube', 'file', 'vimeo', 'note', 'email', 'attachments', 'agent_call']
      .includes(value.content_type))[0]
  switch (value?.content_type) {
    case 'agent_call':
      return 'Outgoing call.'
    case 'text':
      let _sender = recentInteraction.sender as any;
      if (recentInteraction.is_room) {
        return recentInteraction.direction == InteractionDirection.inbound ? `${_sender.firstName}: ${value.content}` : `You: ${value.content}`
      } else {
        return `${recentInteraction.direction == InteractionDirection.outbound ? 'You: ' : ''}${recentInteraction.interaction.translation
          ? recentInteraction.interaction.translation.translated_msg
          : value.content}`
      }
    case 'task':
      return localization.messages.new_task_has_been_created ?? 'A new task has been created.'
    case 'incoming-call':
      return localization.calls.incoming_call ?? 'incoming call.'
    case 'web_call':
      return 'Web call.'
    case 'missed-call':
      return recentInteraction.body.some(item => item.content_type == "audio") ? localization.labels.voicemail : (localization.messages.you_missed_call ?? 'You missed a call.')
    case 'outgoing-call':
      return localization.calls.outgoing_call ?? 'Outgoing call.'
    case 'image':
      return recentInteraction.direction == InteractionDirection.inbound
        ? localization.messages.sent_you_an_image ?? 'sent you an image.'
        : localization.messages.you_sent_an_image ?? 'you sent an image.'
    case 'audio':
      return recentInteraction.direction == InteractionDirection.inbound
        ? localization.messages.sent_you_a_recording ?? 'Sent you an recording.'
        : localization.messages.you_sent_recording ?? 'You sent a recording.'
    case 'file':
      return recentInteraction.direction == InteractionDirection.inbound
        ? localization.messages.sent_you_a_file ?? 'Sent you a file.'
        : localization.messages.you_sent_a_file ?? 'You sent a file.'
    case 'attachments':
      let sender = recentInteraction as any;
      let senderName = sender.firstName ? sender.firstName : sender?.given_name;
      return recentInteraction.direction == InteractionDirection.inbound ? `${senderName}: shared a file` : 'You shared a file';
    case 'vimeo':
    case 'video':
    case 'youtube':
      return recentInteraction.direction == InteractionDirection.inbound
        ? localization.messages.sent_you_a_video ?? 'sent you a video.'
        : localization.messages.you_sent_a_video ?? 'you sent a video.'
    case 'note': {
      try {
        return Array.isArray(value.content)
          ? (value.content as InlineNoteBody)
            .map((s) => s.type == 'text' ? s.text : s.type == 'mention' ? s.user : '')
            .join(' ')
          : value.content;
      } catch {
        return value.content;
      }
    }
    case 'email': {
      try {
        return Array.isArray(value.content)
          ? (value.content as EmailBody).find((s) => s.type == 'subject')!.content
          : value.content;
      } catch {
        return value.content;
      }
    }
    default:
      return localization?.messages.unsupported_content ?? 'Unsuported content';
  }
}
