import { IMessagesRepository } from '@/app/core/IRepositories/IMessagesRepository';
import {
  InteractionStatus,
  RecentInteraction,
  RecentMessagesQuery,
} from '@/app/core/Models/Interaction';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { Observable, from, map, pluck } from 'rxjs';
import { DatabaseService } from '../services/Database/database.service';
import { RecentInteractionsFilter } from '@/app/core/Models/RecentInteractionsFilter';
import { liveQuery } from 'dexie';
import { IsTypingEventDto } from '../DTO/isTypingEventDto';
import { SuccessResponse } from '../DTO/successResponse';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';

@Injectable({
  providedIn: 'root',
})
export class MessagesRepository implements IMessagesRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) { }

  getRecentMessages(
    { filter, params, inbox }: RecentMessagesQuery
  ): Observable<RecentInteraction[]> {
    const requestURL = `${environment.apiURL}recent-channels/${filter}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      { ...params, inboxes: inbox?.is_channel ? inbox.inbox_id : '', custom: inbox?.is_custom ? inbox.inbox_id : '' }
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<RecentInteraction[]>;
        return res.results;
      })
    );
  }

  saveRecentMessages(
    data: RecentInteraction[],
    { params: { page, page_size }, filter, inbox }: RecentMessagesQuery,
  ): void {
    this.databaseService.transaction(
      'rw!',
      this.databaseService.recentInteractions,
      async () => {
        // if (page == 1) {
        //   this.databaseService.recentInteractions.clear();
        // } else {
        // }
        const recentInteractionsByPage =
          await this.databaseService.recentInteractions
            .where('page')
            .equals(Number(page))
            .filter((item) => this.filterRecentInteraction(item, filter))
            .offset(0)
            .limit(page_size)
            .toArray();
        if (recentInteractionsByPage.length) {
          const recentInteractionsIds = recentInteractionsByPage.map((item) => item.id as unknown as string);
          const newRecentInteractionsIds = data.map((item) => item.id as unknown as string);
          const removed = recentInteractionsIds.filter((item) => newRecentInteractionsIds.indexOf(item) == -1);
          await this.databaseService.recentInteractions.bulkDelete(removed);
        }
        this.databaseService.recentInteractions.bulkPut(data);
      }
    );
  }

  getLocalRecentMessages(data: {
    filter: RecentInteractionsFilter,
    inboxId?: number,
    page?: number,
    page_size?: number
  }): Observable<RecentInteraction[]> {
    return from(
      liveQuery(() => {
        this.databaseService.recentInteractions.mapToClass(RecentInteraction);
        return this.databaseService.recentInteractions
          .orderBy('interaction_dt')
          .filter((item) => this.filterRecentInteraction(item, data.filter))
          .and((item) => !data.inboxId || data.inboxId == item.inbox_id)
          .offset(0)
          .limit((data.page_size ?? 29) * (data.page ?? 1))
          .reverse()
          .toArray()
      })
    );
  }

  addRecentMessage(data: RecentInteraction): void {
    this.databaseService.transaction(
      'rw!',
      this.databaseService.recentInteractions,
      async () => {
        let sameContactInteractions = await this.databaseService.recentInteractions
          .where(data.yobi_contact_id ? 'yobi_contact_id' : 'channel_id')
          .equals(data.yobi_contact_id ? data.yobi_contact_id : data.channel_id)
          .toArray();
        if (sameContactInteractions.length) {
          data.is_blocked = sameContactInteractions[0].is_blocked;
          const sameContactInteractionsIds = sameContactInteractions.map((item) => item.id as unknown as string);
          await this.databaseService.recentInteractions.bulkDelete(sameContactInteractionsIds);
        }
        this.databaseService.recentInteractions.put(data).then();
      }
    );
  }

  deleteLocalRecentInteractionByContactId(contactId: number) {
    this.databaseService.recentInteractions.where({ yobi_contact_id: contactId }).delete().catch(console.error)
  }

  deleteLocalRecentInteractionByUnclassifiedChannelId(channelId: number) {
    this.databaseService.recentInteractions.where({ unclassified_channel_id: channelId }).delete().catch(console.error)
  }

  findRecentMessage(id: number | any): Observable<RecentInteraction | undefined> {
    return from(
      liveQuery(() => {
        this.databaseService.recentInteractions.mapToClass(RecentInteraction);
        return this.databaseService.recentInteractions
          .where('yobi_contact_id')
          .equalsIgnoreCase(`${id}` ?? '')
          .or('channel_id')
          .equalsIgnoreCase(`${id}` ?? '')
          .first();
      })
    );
  }

  findRecentInteractionByContactId(
    contactId: number
  ): Observable<RecentInteraction | undefined> {
    return from(
      liveQuery(() => {
        this.databaseService.recentInteractions.mapToClass(RecentInteraction);
        return this.databaseService.recentInteractions
          .where('yobi_contact_id')
          .equals(contactId)
          .first();
      })
    );
  }

  findRecentInteractionByUnclassifiedChannelId(
    unclassifiedChannelId: number
  ): Observable<RecentInteraction | undefined> {
    return from(
      liveQuery(() => {
        this.databaseService.recentInteractions.mapToClass(RecentInteraction);
        return this.databaseService.recentInteractions
          .where('unclassified_channel_id')
          .equals(unclassifiedChannelId)
          .first();
      })
    );
  }

  sendIsTypingEvent(request: IsTypingEventDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}messages/typing`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => res as SuccessResponse)
    )
  }

  filterRecentInteraction = (interaction: RecentInteraction, filter: RecentInteractionsFilter): boolean =>
    filter == RecentInteractionsFilter.inbox ? !interaction.is_archived && !interaction.is_blocked
      : filter == RecentInteractionsFilter.blocked ? interaction.is_blocked && !interaction.is_archived
        : filter == RecentInteractionsFilter.archived ? interaction.is_archived && !interaction.is_blocked
          : filter == RecentInteractionsFilter.unread ? [InteractionStatus.unread, InteractionStatus.received, InteractionStatus.ringing].includes(interaction.interaction.status)
            : true

}
