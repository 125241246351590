import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotificationsCenterComponent } from '@/app/Ui/notifications-center/notifications-center.component';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotificationItemModule } from '@/app/Ui/components/notification-item/notification-item.module';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { TaskDetailsModule } from '../tasks/task-details/task-details.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NotificationsCenterComponent],
  exports: [NotificationsCenterComponent],
  imports: [
    CommonModule,
    MatRippleModule,
    TaskDetailsModule,
    NotificationItemModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    TranslateModule.forChild({extend: true}),
    MatSliderModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
  ],
  providers: [],
})
export class NotificationsCenterModule {
  constructor() {}
}
