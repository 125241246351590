import { createSelector } from '@ngrx/store';
import { AppState } from '../AppState';
import { EmailInboxState } from './email-inbox.reducer';

const emailInboxState = (state: AppState) => state.emailInbox;

export const selectEmailInboxState = createSelector(
  emailInboxState,
  (state: EmailInboxState) => state
);

export const selectIsFetchingRecentEmails = createSelector(
  emailInboxState,
  (state: EmailInboxState) => state.fetching
);

export const selectRecentEmailsState = createSelector(
  emailInboxState,
  (state: EmailInboxState) => state.recentEmailsState
);

export const selectActiveConversationState = createSelector(
  emailInboxState,
  (state: EmailInboxState) => state.activeConversationState
);

export const selectActiveConversationId = createSelector(
  emailInboxState,
  (state: EmailInboxState) => state.activeConversationState?.conversationId
);

export const selectEmailClassifications = createSelector(
  emailInboxState,
  (state: EmailInboxState) => state.classifications
);

export const selectSelectedEmailInbox = createSelector(
  emailInboxState,
  (state: EmailInboxState) => state.selectedInbox
);


