import { Observable } from 'rxjs';
import { Task, TaskFilter, TaskStatus } from '@/app/core/Models/Task';

export abstract class ITasksRepository {
  abstract getTasks(filter: TaskFilter, status: TaskStatus): Observable<Task[]>;
  abstract getTasksByChannelId(
    filter: TaskFilter,
    status: TaskStatus,
    channelId: number
  ): Observable<Task[]>;
  abstract saveTasks(tasks: Task[]): void;
  abstract saveTask(request: Task): void;
  abstract getLocalTasks(filter: TaskFilter): Observable<Task[]>;
}
