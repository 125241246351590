import { Observable, map, pluck } from 'rxjs';
import { FacebookPostDto, InstagramPostDto, LinkedinPostDto, TwitterPostDto } from '@/app/Data/DTO/socialPublish/SocialPostDto';
import { ISocialPublisherRepository } from '@/app/core/IRepositories/ISocialPublisherRepository';
import { environment } from '@/environments/environment';
import { HttpService } from '../services/Networking/HttpService';
import { DatabaseService } from '../services/Database/database.service';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export  class SocialPublisherRepository implements ISocialPublisherRepository {

  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService) {}

  publishToFacebook(request: FacebookPostDto): Observable<any> {
    const requestURL = `${environment.apiURL}fb/feed`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  publishToInstagram(request: InstagramPostDto): Observable<any> {
    const requestURL = `${environment.apiURL}insta/feed`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }
  publishToTwitter(request: TwitterPostDto): Observable<any> {
    const requestURL = `${environment.apiURL}twitter/tweet`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }
  

  publishToLinkedin(request: LinkedinPostDto): Observable<any> {
    const requestURL = `${environment.apiURL}linkedin/feed`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }



}