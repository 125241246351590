import { BillingSubscription, PaymentMethod, PromoCode } from '@/app/core/Models/billing';
import { BillingInvoiceDto } from '@/app/Data/DTO/BillingDto';
import { BillingPlanDto } from '@/app/Data/DTO/BillingPlanDto';
import { createReducer, on } from '@ngrx/store';
import { SignOutAction } from '../app/action';
import { ApplyPromoCodeAction, ApplyPromoCodeFailAction, ApplyPromoCodeSuccessAction, DeleteCurrentPaymentMethodeSuccessAction, DeletePromoCodeSuccessAction, FetchActiveSubscriptionSuccessAction, FetchBillingInvoicesSuccessAction, FetchBillingPlansAction, FetchBillingPlansFailAction, FetchBillingPlansSuccessAction, FetchCurrentPaymentMethodeSuccessAction, GetActivePromotionCodesSuccessAction, InteractWithBillingPromptAction, OpenBillingPromptAction } from './billing.actions';

export interface BillingState {
  billingPromptOpen?: boolean,
  activeSubscription?: BillingSubscription;
  currentPaymentMethod?: PaymentMethod;
  billingInvoices?: BillingInvoiceDto[];
  fetchingBillingPlans: boolean;
  billingPlans: BillingPlanDto[];
  activePromotionCodes: PromoCode[];
  applyingPromoCode: boolean;
}

export const initBillingState: BillingState = {
  billingPlans: [],
  fetchingBillingPlans: false,
  activePromotionCodes: [],
  applyingPromoCode: false
};

export const BillingReducer = createReducer(
  initBillingState,
  on(FetchCurrentPaymentMethodeSuccessAction, (state: BillingState, { payload }) => {
    return {
      ...state,
      currentPaymentMethod: payload,
    };
  }),
  on(FetchBillingPlansAction, (state: BillingState, { }) => {
    return {
      ...state,
      fetchingBillingPlans: true,
    };
  }),
  on(FetchBillingPlansSuccessAction, (state: BillingState, { payload: { plans } }) => {
    return {
      ...state,
      billingPlans: [...plans].sort((p1, p2) => p1.plan_order - p2.plan_order),
      fetchingBillingPlans: false,
    };
  }),
  on(FetchBillingPlansFailAction, (state: BillingState) => {
    return {
      ...state,
      fetchingBillingPlans: false,
    };
  }),
  on(ApplyPromoCodeAction, (state: BillingState) => {
    return {
      ...state,
      applyingPromoCode: true,
    };
  }),
  on(ApplyPromoCodeSuccessAction, (state: BillingState, { payload: { plans } }) => {
    return {
      ...state,
      billingPlans: [...plans].sort((p1, p2) => p1.plan_order - p2.plan_order),
      applyingPromoCode: false,
    };
  }),
  on(DeletePromoCodeSuccessAction, (state: BillingState, { payload: { plans } }) => {
    return {
      ...state,
      billingPlans: [...plans].sort((p1, p2) => p1.plan_order - p2.plan_order),
    };
  }),
  on(ApplyPromoCodeFailAction, (state: BillingState) => {
    return {
      ...state,
      applyingPromoCode: false,
    };
  }),
  on(GetActivePromotionCodesSuccessAction, (state: BillingState, { payload: { promoCodes } }) => {
    return {
      ...state,
      activePromotionCodes: promoCodes,
    };
  }),
  on(FetchActiveSubscriptionSuccessAction, (state: BillingState, { payload }) => {
    return {
      ...state,
      activeSubscription: payload,
    };
  }),
  on(FetchBillingInvoicesSuccessAction, (state: BillingState, { payload }) => {
    return {
      ...state,
      billingInvoices: payload,
    };
  }),
  on(DeleteCurrentPaymentMethodeSuccessAction, (state: BillingState) => {
    return {
      ...state,
      currentPaymentMethod: undefined,
    };
  }),
  on(OpenBillingPromptAction, (state: BillingState) => {
    return {
      ...state,
      billingPromptOpen: true,
    };
  }),
  on(InteractWithBillingPromptAction, (state: BillingState) => {
    return {
      ...state,
      billingPromptOpen: false,
    };
  }),
  on(SignOutAction, (state: BillingState) => {
    return {
      ...initBillingState,
    };
  })
);
