<div [attr.customtooltip]="_name" [ngStyle]="{'background-color': backgroundColor}">
  <p
    *ngIf="text"
    [ngStyle]="{'font-size.px': fontSize, 'color': fontColor }"
    [ngClass]="{'tracking-widest': letterSpacing}"
    class="font-medium rounded-full"
  >
    {{ text }}
  </p>
  <img
    *ngIf="url && !urlError"
    class="absolute object-cover bg-white h-full w-full block rounded-full"
    (error)="urlError = true"
    [src]="url"
  />
  <svg-icon
    *ngIf="!url && !text"
    src="assets/icons/holder.svg"
    class="h-full w-full shadow-sm rounded-full text-white"
    [svgStyle]="{ height: '100%', width: '100%', 'min-width': '100%', 'min-height': '100%' }"
  ></svg-icon>

  <svg-icon
    class="absolute bottom-0 right-0 z-10 text-dark"
    *ngIf="isRoom"
    [svgStyle]="{ 'height.px': 17, 'width.px': 17, 'filter': 'drop-shadow(1px 1px 0 white)' }"
    [src]="isRoomPrivate ? 'assets/icons/new-set/lock.svg': 'assets/icons/new-set/hash.svg'"
  >
  </svg-icon>
</div>