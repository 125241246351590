export class VoiceClientSetUserOnHoldDto {
  constructor(
    private callSid: string,
    private userId: string,
    private isOnHold: boolean,
  ) {}
  body(): Object {
    return {
      call_sid: this.callSid,
      name: `agent-${this.userId}`,
      on_hold: this.isOnHold,
    };
  }
}
