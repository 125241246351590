import { IVoicemailInteractiosRepository } from '@/app/core/IRepositories/IVoicemailInteractionsRepository';
import { VoicemailInteraction } from '@/app/core/Models/Interaction';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { liveQuery } from 'dexie';
import { Observable, from, map, pluck } from 'rxjs';
import { VoicemailInteractionsQueryDto } from '../DTO/voicemailInteractionsQuery.dto';
import { DatabaseService } from '../services/Database/database.service';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';

@Injectable({
  providedIn: 'root',
})
export class VoicemailInteractionsRepository implements IVoicemailInteractiosRepository {

  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) { }

  fetchVoicemails({ params: { pageSize, page, channel_value } }: VoicemailInteractionsQueryDto): Observable<VoicemailInteraction[]> {
    const query = {
      offset: pageSize * (page - 1),
      limit: pageSize,
      // channel_value
    }
    const requestURL = `${environment.apiURL}voicemails`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      query
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => {
        return res as VoicemailInteraction[];
      })
    );
  }

  saveVoicemails(data: VoicemailInteraction[], { params: { page, pageSize } }: VoicemailInteractionsQueryDto,
  ): void {
    this.databaseService.transaction(
      'rw!',
      this.databaseService.voicemailInteractions,
      async () => {
        const voicemailsByPage =
          await this.databaseService.voicemailInteractions
            .where('page')
            .equals(Number(page))
            .offset(0)
            .limit(pageSize)
            .toArray();
        if (voicemailsByPage.length) {
          const voicemailsIds = voicemailsByPage.map((item) => item.interaction_id as string);
          const newVoicemailsIds = data.map((item) => item.interaction_id as string);
          const removed = voicemailsIds.filter((item) => newVoicemailsIds.indexOf(item) == -1);
          await this.databaseService.voicemailInteractions.bulkDelete(removed);
        }
        this.databaseService.voicemailInteractions.bulkPut(data);
      }
    );
  }

  getLocalVoicemails({ params: { pageSize, page, channel_value } }: VoicemailInteractionsQueryDto): Observable<VoicemailInteraction[]> {
    return from(
      liveQuery(() => {
        this.databaseService.voicemailInteractions.mapToClass(VoicemailInteraction);
        return this.databaseService.voicemailInteractions
          .orderBy('interaction_dt')
          .filter(item => !channel_value || item.interaction_to === channel_value)
          .offset(0)
          .limit(pageSize * (page ?? 1))
          .reverse()
          .toArray()
      })
    );
  }

  saveVoicemail(data: VoicemailInteraction): void {
    this.databaseService.transaction(
      'rw!',
      this.databaseService.voicemailInteractions,
      async () => {
        let sameVoicemailInteractions = await this.databaseService.voicemailInteractions
          .where('interaction_id')
          .equals(data.interaction_id)
          .toArray();
        if (sameVoicemailInteractions.length) {
          const sameVoicemailInteractionsIds = sameVoicemailInteractions.map((item) => item.interaction_id as string);
          await this.databaseService.voicemailInteractions.bulkDelete(sameVoicemailInteractionsIds);
        }
        this.databaseService.voicemailInteractions.put(data).then();
      }
    );
  }


  getLocalVoicemailInteraction(interaction_id: string | number): Observable<VoicemailInteraction | undefined> {
    return from(
      liveQuery(() => {
        this.databaseService.voicemailInteractions.mapToClass(VoicemailInteraction);
        let query = this.databaseService.voicemailInteractions
          .where('interaction_id')
          .equals(interaction_id)
        return query.first();
      })
    );
  }


  getLocalVoicemailInteractionByExternalId(external_interaction_id: string | number): Observable<VoicemailInteraction | undefined> {
    return from(
      (async () => {
        this.databaseService.voicemailInteractions.mapToClass(VoicemailInteraction);
        let query = this.databaseService.voicemailInteractions
          .where('external_interaction_id')
          .equals(external_interaction_id)
        return query.first();
      })()
    );
  }


  updateLocalVoicemailInteraction(interaction: Partial<VoicemailInteraction> & Pick<VoicemailInteraction, 'interaction_id'>): void {
    this.databaseService.voicemailInteractions
      .update(interaction.interaction_id as unknown as string, interaction)
  }

}
