<div class="mat-dialog-header">
  <div class="flex items-center gap-2 text-mild">
    <svg-icon
      [svgStyle]="{ 'height.px': 21, 'width.px': 21 }"
      src="assets/icons/notes.svg"
    ></svg-icon>
    <h2 class="m-0 text-lg font-semibold">
      {{(isUpdate ? localization.labels.update_note: localization.labels.add_note ) | translate}}
    </h2>
  </div>
  <svg-icon
    class="text-pale hover:text-warning cursor-pointer ml-auto"
    [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
    src="assets/icons/new-set/close.svg"
    mat-dialog-close
  >
  </svg-icon>
</div>

<div class="mat-dialog-body flex flex-col gap-6">
  <!-- ---------------------------- content input ---------------------------- -->
  <app-formatted-textarea
    class="z-10"
    [id]="'threadInlineNoteInput'"
    [(ngModel)]="content"
    [placeholder]="localization.placeholder.type_to_mention_teammate | translate"
    [members]="mentions$ | async"
    [mentionsDropUp]="false"
    (filesModelChange)="onFiles($event)"
  ></app-formatted-textarea>
  <!-- ---------------------------- attachements ----------------------------- -->
  <app-attachments-viewer
    class="my-1"
    *ngIf="attachments.length"
    [attachments]="attachments"
    [canRemove]="true"
    (remove)="removeAttachment($event)"
  ></app-attachments-viewer>
  <!-- --------------------------- upload progress --------------------------- -->
  <mat-progress-bar
    *ngIf="savingNote && attachments.length"
    [value]="uploadProgress$ | async"
  ></mat-progress-bar>
</div>

<div class="mat-dialog-footer">
  <div class="flex items-center gap-2">
    <button
      class="rounded-md h-9 w-9 flex items-center justify-center hover:light-bg-color shrink-0"
      appFilePicker
      [multiple]="true"
      [processFiles]="true"
      [accept]="'image/*'"
      (filesModelChange)="onFiles($event)"
    >
      <svg-icon
        src="assets/icons/new-set/image.svg"
        class="text-mild"
        [svgStyle]="{ 'height.px': 21, 'width.px': 21 }"
      ></svg-icon>
    </button>
    <app-emoji-button
      class="rounded-md"
      [tooltip]="localization.terms.emoji | translate "
      (onSelect)="addEmoji($event[0])"
    ></app-emoji-button>
  </div>
  <div class="flex items-center gap-4">
    <app-button
      color="light"
      [mat-dialog-close]="false"
      *ngIf="isUpdate"
    >
      {{localization.labels.cancel | translate}}
    </app-button>

    <app-button
      [disabled]="!content.length || savingNote"
      color="primary"
      (click)="save()"
    >
      <span>{{localization.labels.save | translate}}</span>
      <mat-spinner
        *ngIf="savingNote"
        class="ml-2"
        [diameter]="20"
      ></mat-spinner>
    </app-button>
  </div>
</div>