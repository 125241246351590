import { DialerActiveCallComponent } from '@/app/Ui/DIaler/dialer-active-call/dialer-active-call.component';
import { DialerPadComponent } from '@/app/Ui/DIaler/dialer-pad/dialer-pad.component';
import { DialerComponent } from '@/app/Ui/DIaler/dialer/dialer.component';
import { AvatarModule } from '@/app/Ui/components/avatar/avatar.module';
import { ChannelIconModule } from '@/app/Ui/components/channel-icon/channel-icon.module';
import { PhoneMaskDirectiveModule } from '@/app/Ui/directives/phone-mask.directive';
import { PhoneFormatterPipeModule } from '@/app/Ui/pipes/phone-formatter.pipe';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PhoneContactInputModule } from '../components/phone-contact-input/phone-contact-input.module';
import { DropDownPanelDirectiveModule } from '../directives/dropdown-panel.directive';
import { NotificationsCenterModule } from './../notifications-center/notifications-center.module';
import { DialerAudioSettingsModule } from './dialer-audio-settings/dialer-audio-settings.module';
import { DialerCallSessionItemComponent } from './dialer-call-session-item/dialer-call-session-item.component';
import { DialerTransferListComponent } from './dialer-transfer-list/dialer-transfer-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { A11yModule } from '@angular/cdk/a11y';
@NgModule({
  declarations: [
    DialerComponent,
    DialerActiveCallComponent,
    DialerTransferListComponent,
    DialerPadComponent,
    DialerCallSessionItemComponent,
  ],
  exports: [
    DialerComponent,
    DialerActiveCallComponent,
    DialerTransferListComponent,
    DialerPadComponent,
  ],
  imports: [
    A11yModule,
    CommonModule,
    MatDividerModule,
    NotificationsCenterModule,
    DropDownPanelDirectiveModule,
    MatIconModule,
    DialerAudioSettingsModule,
    MatTooltipModule,
    MatAutocompleteModule,
    RouterModule,
    MatAutocompleteModule,
    MatInputModule,
    DragDropModule,
    MatFormFieldModule,
    AngularSvgIconModule,
    AvatarModule,
    ChannelIconModule,
    PhoneMaskDirectiveModule,
    ReactiveFormsModule,
    PhoneFormatterPipeModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatRippleModule,
    MatMenuModule,
    ClipboardModule,
    TranslateModule,
    PhoneContactInputModule
  ],
  providers: [],
})
export class DialerModule {
  constructor() {}
}
