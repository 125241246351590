import { PasswordValidator } from '@/app/Utilities/Validators/pasword-validator';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { firstValueFrom, map, take } from 'rxjs';
import { AuthViewModel } from '../../ViewModels/authViewModel';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';
import { ILocalStorageService } from '@/app/Data/Adapters/ILocalStorageService';
import { DEMO_SESSIONID } from '@/app/Utilities/TokenKeys';

@Component({
  selector: 'app-add-password',
  templateUrl: './add-password.component.html',
  styleUrls: ['./add-password.component.scss'],
})
export class AddPasswordComponent implements OnInit, OnDestroy {
  private preventDestroy = false;
  localization = this.localizationViewModel.localization;
  passwordControl: FormControl = new FormControl('', [
    Validators.required,
    PasswordValidator(),
  ]);
  isResetPassword$ = this.router.data.pipe(map((item) => item['reset']));
  constructor(
    public authViewModel: AuthViewModel,
    private router: ActivatedRoute,
    private localizationViewModel: LocalizationViewModel,
    private localStorageService: ILocalStorageService
  ) {}
  ngOnDestroy(): void {
    this.authViewModel.authorizeAddPassword(false);
  }

  ngOnInit(): void {
   
  }

  submit(): void {
    this.authViewModel.addPasswordState$.pipe(take(1), map( data => data.loading)).subscribe(loading => {
      if(!loading) {
        this.isResetPassword$.pipe(take(1)).subscribe((isReset) => {
          if (isReset) {
            this.resetPassword();
          } else {
            // this.createPassword();
          }
        });
      }
    })

  }
  async resetPassword(): Promise<any> {
    const token = this.router.snapshot.params['token'];
    if (token && this.passwordControl.valid) {
      this.authViewModel.resetPassword({
        new_password: this.passwordControl.value,
        reset_token: token,
      });
    }
  }
}
