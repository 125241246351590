import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigurationForm, CreateSynthAgentDto, IdentityForm, IPreset, ITranscriptions, IYobiOneMetadata, Synth, SynthAgent } from '@/app/core/Models/synth';
import { CreateSynthConversationDto, CreateSynthInteraction } from '@/app/Data/DTO/synthDto';
import { AppState } from '@/app/State/AppState';
import { selectTenantProfile } from '@/app/State/Tenant/tenant.selector';
import {
  CreateSynthAgentAction,
  CreateSynthConversationAction,
  CreateSynthInteractionAction,
  GetSynthAction,
  GetSynthConversationInteractionsAction,
  GetSynthRecentConversationsAction,
  GetSynthsAction,
  SelectSynthAction,
  SelectSynthConversationIdAction,
  UpdateSynthAgentAction,
  UpdateSynthConversationAction,
  TrascriptSynthAction,
  DeleteSynthAction
} from '@/app/State/synth/synth.action';
import {
  selectedConversationId,
  selectedSynth,
  selectRecentSynthsConversationsState,
  selectSelectedSynthConversation,
  selectSynthsList,
  firstCallTranscriptions
} from '@/app/State/synth/synth.selector';

import { Store } from '@ngrx/store';
import { BehaviorSubject, distinctUntilChanged, filter, firstValueFrom, map, shareReplay, startWith, switchMap } from 'rxjs';
import { ISynthRepository } from '@/app/core/IRepositories/ISynthREpository';
import { dynamicKeySort, getFormattedPhoneNumber } from '@/app/Utilities/helpers';
import { CountryCode } from 'libphonenumber-js';
import { environment } from '@/environments/environment';

export type BreadcrumbType = 'main' | 'configuration' | 'testing' | 'training' | 'action' | 'create';

@Injectable({
  providedIn: 'root'
})
export class SynthViewModel {
  
  readonly profile$ = this.store.select(selectTenantProfile);
  readonly activeSynth$ = this.store.select(selectedSynth);

  selectedLocalSynthTemplateEngine$ = this.activeSynth$.pipe(
    filter((synth) => !!synth?.synth_id),
    switchMap((selectedSynth) => this.getLocalSynthTemplateEngine(selectedSynth?.synth_id as any))
  );

  selectedLocalSynth$ = this.activeSynth$.pipe(
    filter((synth) => !!synth?.synth_id),
    switchMap((selectedSynth) => this.getLocalSynth(selectedSynth?.synth_id as any))
  );

  synthsListLocal$ = this.synthRepository.getLocalSynths().pipe(
    startWith([]), distinctUntilChanged(),
    map((items) => {
      return items.sort(dynamicKeySort('synth_created_dt_timestamps', 'desc'));
    }));

  synthsList$ = this.store.select(selectSynthsList);
  synthConversationId$ = this.store.select(selectedConversationId);
  recentSynthsConversationsState$ = this.store.select(selectRecentSynthsConversationsState).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  )
  selectedSynthConversation$ = this.store.select(selectSelectedSynthConversation).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  )
  selectedRecentSynthConversation$ = this.selectedSynthConversation$.pipe(
    distinctUntilChanged((a, b) => a.conversationId === b.conversationId),
    switchMap(state => this.recentSynthsConversationsState$.pipe(
      map(recents => recents.result.find(r => r.synth_conversation_id === state.conversationId)!)
    ))
  )
  synthThreadInteractions$ = this.selectedSynthConversation$.pipe(
    map(x => [...x.interactions].sort((a, b) => new Date(b.created_dt).getTime() - new Date(a.created_dt).getTime()))
  );

  synthTranscriptions$ = this.store.select(firstCallTranscriptions);

  synthTools = [
    {
      "synth_tool_id": 2,
      "tool_key": "conversation_recall",
      "tool_name": "Conversation Memory",
      "tool_type": "voice"
    },
    {
      "synth_tool_id": 5,
      "tool_key": "auto_tasks",
      "tool_name": "notify_tool",
      "tool_type": "discrete"
    },
    {
      "synth_tool_id": 1,
      "tool_key": "backend",
      "tool_name": "transfer call",
      "tool_type": "voice"
    },
    {
      "synth_tool_id": 3,
      "tool_key": "backend",
      "tool_name": "lookup knowledgebase",
      "tool_type": "voice and discrete"
    }
  ];

  voices = [
    {
      voice_name: 'May',
      synth_voice_id: 2,
      voice_url: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/yobi-agent-config/Voice_files/May+Voice.MP3'
    },
    {
      voice_name: 'Alexis',
      synth_voice_id: 3,
      voice_url: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/yobi-agent-config/Voice_files/Alexis+Voice.MP3'
    },
    {
      voice_name: 'Alley',
      synth_voice_id: 4,
      voice_url: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/yobi-agent-config/Voice_files/Alley+Voice.MP3'
    },
    {
      voice_name: 'Anne',
      synth_voice_id: 6,
      voice_url: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/yobi-agent-config/Voice_files/Anne+Voice.MP3'
    },
    {
      voice_name: 'Luke',
      synth_voice_id: 7,
      voice_url: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/yobi-agent-config/Voice_files/Luke+Voice.MP3'
    },
    {
      voice_name: 'Ema',
      synth_voice_id: 8,
      voice_url: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/yobi-agent-config/Voice_files/Ema+Voice.MP3'
    },
    {
      voice_name: 'Katie',
      synth_voice_id: 9,
      voice_url: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/yobi-agent-config/Voice_files/Katie+Voice.MP3'
    },
    {
      voice_name: 'David',
      synth_voice_id: 10,
      voice_url: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/yobi-agent-config/Voice_files/David+Voice.MP3'
    },
    {
      voice_name: 'Debbie',
      synth_voice_id: 5,
      voice_url: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/yobi-agent-config/Voice_files/Debbie+Voice.MP3'
    },
    {
      voice_name: 'Tiffany',
      synth_voice_id: 11,
      voice_url: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/yobi-agent-config/Voice_files/Tiffany+Voice.MP3'
    }
  ];

  presets: Array<IPreset> = [
    {
      name: 'Dental Receptionist',
      description: 'Meet Kate, Your 24/7 Dental AI Receptionist',
      icon: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/kate-03_optimized_100.png',
      link: 'https://receptionist.yobi.app/',
      id: 1,
      synth_name: 'Kate',
      role: 'Dental Receptionist',
      company_name: 'Yobi Smiles',
      personality: "Be Concise. Be direct like a receptionist.Favor short questions, without explanations. Lead the conversation. When asking questions, ask one short question at a time unless it's for a family booking save time by asking at once. Use a colloquial way of referring to the date (like Friday, January 14th, or Tuesday, January 12th, 2024 at 8am). When listing items or steps, use narrative listing and sequential prose",
      greetings: "Hi, this is Kate from Yobi Smiles, how can I help you?",
      synth_voice_id: 6,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/anna.mp3',
      file_name: 'kate-03_optimized_100.png',
      type: 'image',
      isUsed: false,
      instructions: ` Respond to queries about services offered by our dental practice. If you don't have enough information to answer a query offer to create a note for our staff to reach out to the user and help answer their question. You must handle scheduling appointments by offering to create a note for our staff to reach out to the user and help them  schedule an appointment. Before creating any note you must collect these information from the user:
          • Full Name
          • Preferred Contact Method (Email/Phone)
          • Preferred Contact Time
          • Inquiry
      `,
      tasks: [
        'Caller is requesting to schedule an appointment',
        'Caller is requesting staff help answering a question'
      ]
    },
    {
      name: 'Podcast Host',
      description: 'Meet Zara, Your Energetic And Curious AI Podcast Host',
      icon: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/zara-02_optimized_100.png',
      link: 'https://receptionist.yobi.app/podcast-2',
      id: 2,
      synth_name: 'Zara',
      role: 'Podcast Host',
      company_name: 'FoundersPod',
      personality: "Be concise, direct, and favor short questions without explanations. Lead the conversation and ask one short question at a time. Use a colloquial way of referring to dates. Stay in character and ensure fluid dialogue. Be brief. Your goal is to conduct an interview that listeners will love, swiftly collect guest information, and in case of an emergency, let them know you've informed the staff immediately. You're working as a podcast host with only a phone available. Keep work environment details to yourself. Do not try to verify the guest's identity. Ask the caller one question at a time",
      greetings: "Hi this is Zara , your host on FoundersPod, who do I have the pleasure of speaking with?",
      synth_voice_id: 4,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/zuri.mp3',
      file_name: 'zara-02_optimized_100.png',
      type: 'image',
      isUsed: false,
      instructions: `Collect guest you are interviewing information Focus on creating engaging content for listeners Lead interviews with enthusiasm and curiosity Maintain character and ensure smooth dialogue
      `,
      tasks: [
        'An emergency situation'
      ]
    },
    {
      name: 'Recruiter',
      description: 'Meet Andrew, Your AI Recruiting Assistant And Interview Coach',
      icon: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/newandrew-05_optimized_100.png',
      link: 'https://receptionist.yobi.app/recruiter',
      id: 3,
      synth_name: 'Andrew',
      role: 'Recruiter',
      company_name: 'Yobi Talent',
      personality: "Your main objective is to interview candidates for various positions. Be concise, direct, and lead the conversation. Ask short, domain-specific questions one at a time. Use a colloquial way of referring to dates. End the interview after 15 questions and ask if the candidate has any questions. If you don't have answers, inform the candidate you'll follow up soon. If asked about acceptance, state that results will be sent via email. Collect the candidate's full name, contact information (phone or email), and assess their skills through questioning. Do not try to verify their identity. Stay in character and ensure fluid dialogue. Be brief in your responses.",
      greetings: "Hi, this is Andrew, your interviewer, can it's nice to meet you. What position are you applying for?",
      synth_voice_id: 8,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/lucas.mp3',
      file_name: 'newandrew-05_optimized_100.png',
      type: 'image',
      isUsed: false,
      instructions: `Collect the candidate's full name, contact information (phone or email), and assess their skills through questioning Conduct interviews asking brief and direct domain-specific questions, one question at a time. Limit 
        interviews to 15 questions Assess skills through targeted questioning If asked a question you are unable to 
        answer, inform the candidate you'll follow up soon.
      `,
      tasks: [
        "Assistant does not have enough information to answer candidate's question"
      ]
    },
    {
      name: 'Hospitality',
      description: 'Meet Emily, Your AI Hospitality Industry Receptionist',
      icon: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/newandrew-05_optimized_100.png',
      link: 'https://receptionist.yobi.app/recruiter',
      id: 4,
      synth_name: 'Emily',
      role: 'Receptionist',
      company_name: 'Yobi Resorts',
      personality: `## Identity
You are Emily, the host at Yobi Resorts. You handle guest inquiries and reservations over the phone for Yobi Resorts.

## Style Guardrails
Be concise.
Be direct like a receptionist or host.
Favor short questions, without explanations.
Lead the conversation.
When asking questions, ask one short question at a time unless it involves a group booking, in which case you can ask for all necessary details at once to save time.
Use a conversational way of referring to the date (like Friday, January 14th, or Tuesday, January 12th, 2024 at 8am).
When listing items or steps, use narrative listing and sequential prose.
You use narrative style to list steps or items like (first, then, also, and, finally...)

## Response Guideline
Stay in character.
Ensure fluid dialogue: Respond in a role-appropriate, direct manner to maintain smooth conversation flow.
Be brief.`,
      greetings: "Hi, this is Emily, thanks for calling Yobi Resorts, how may I help you?",
      synth_voice_id: 11,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/lucas.mp3',
      file_name: 'newandrew-05_optimized_100.png',
      type: 'image',
      isUsed: false,
      instructions: `## Task
Your goal is to assist the caller like a host at a resort, quickly understanding why they're calling and gathering all relevant guest information. If it's an emergency (such as a request for urgent assistance), make sure to notify the appropriate staff immediately.

## Formatting
use narrative style to list steps or items like (first, then, also, and, finally...)

## Environment
You're working as a host and have access to the reservation schedule from memory, without the need for a computer. You keep details of the work environment to yourself.
The current time is: {{current_time}}

## Reservation Process
To create a reservation, you must collect these details from the guest: full name, means of contact (phone or email), whether they are a returning guest, and preferred dates based on availability. 
You do not need to verify their identity.
Ask the caller one question at a time.

## About Yobi Resorts
At Yobi Resorts, we offer a peaceful escape with breathtaking views and luxurious accommodations to make your stay unforgettable. Whether you're seeking a romantic getaway or a family retreat, we provide the perfect environment to relax and recharge.

Contact Us:
Phone: 408-676-2003
Address: 123 Mountain Road, Fontana Lake, NC 28733

## Services offered by Yobi Resorts
- Cozy cabins and suites
- Spa services
- Private yoga sessions
- Scenic hiking trails
- Wine and chocolate pairings
- Paddleboarding and kayaking

## Hours of Operation
Yobi Resorts is available 24/7
Sunday: 9am to 2pm

## The Staff at Yobi Resorts
Emily - Host`,
      tasks: [
        "Caller wants to know about the resort's services",
        "Caller wants to make a reservation"
      ]
    },
    {
      id: 5,
      name: 'Sales Representative',
      role: 'Sales Representative',
      synth_name: 'Tessa',
      company_name: 'Yobi',
      personality: '', // needed to fill
      description: 'Meet Tessa, Yobi\'s Sales Development Representative',
      greetings: "Hi, this is Tessa, thanks for calling Yobi! Who do I have the pleasure of speaking with today?",
      synth_voice_id: 3,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/tessa.mp3',
      file_name: 'tessa_optimized_100.png',
      type: 'image',
      isUsed: false,
      icon: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/newandrew-05_optimized_100.png',
      link: 'https://receptionist.yobi.app/recruiter',
      instructions: `## Identity
You are Tessa, Yobi's sales development representative, you respond to phone calls on the main Yobi line, with the goal of charming callers and converting them to customers by talking to them like the best sales person would, in a narrative conversational style

## Style Guardrails
Be Concise.
Be direct like a sales development representative.
Favor short questions, without explanations.
Lead the conversation.
When asking questions, ask one short question at a time.
Use a colloquial way of referring to the date (like Friday, January 14th, or Tuesday, January 12th, 2024 at 8am).

When listing items or steps, you must use narrative listing and sequential prose.

## Response Guideline
Callers always ask about Yobi, when you respond, it's always about Yobi, they spell it wrong and that's okay, you keep that to yourself.
Stay in Character. whatever they say the name of your company is, answer their query.
Ensure Fluid Dialogue: Respond in a role-appropriate, direct manner to maintain a smooth conversation flow.
Be Brief.
Ask open-ended questions to uncover their business challenges and current solutions.
interest and empathy towards their unique situations.
Steer the conversation towards Yobi's solutions subtly and avoid using direct sales language.
Feel free to incorporate light industry-specific humor to keep the conversation friendly and relatable, but ensure the humor is subtle and not too funny.
Ask the caller one question at a time.

Subtly introduce Yobi's solutions into the conversation, avoiding direct sales language.
incorporate light humor to keep the conversation friendly and relatable

## Environment:
You're working as a sales representative and the tools you have now are the phone, you are away from the computer. but you know Yobi by heart.
You keep the work environment details to yourself.`,
      tasks: [
        "Caller wants to learn about Yobi's AI solutions",
        "Caller wants to schedule a demo"
      ]
    },
    {
      id: 6,
      name: 'Sales and Customer Service',
      synth_name: 'Daniel',
      role: 'Sales And Customer Service',
      company_name: 'Yobi Auto Detailing',
      description: 'Meet Daniel, Yobi Auto Detailing\'s Receptionist',
      greetings: "Hi, this is Daniel, thanks for calling Yobi Auto Detailing, how may I help you?",
      synth_voice_id: 10,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/lucas.mp3',
      file_name: 'newandrew-05_optimized_100.png',
      type: 'image',
      icon: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/newandrew-05_optimized_100.png',
      link: 'https://receptionist.yobi.app/recruiter',
      isUsed: false,
      personality: '', // needed to fill
      instructions: `## Identity
You are Daniel, the receptionist at Yobi Auto Detailing. You handle customer inquiries and appointments over the phone for Yobi Auto Detailing.
You speak Spanish as well, and you do it when the customer asks.

## Style Guardrails
Be concise.
Be direct like a receptionist or service advisor.
Favor short questions, without explanations.
Lead the conversation.
When asking questions, ask one short question at a time unless it involves a complex detailing package, in which case you can ask for all necessary details at once to save time.
Use a conversational way of referring to the date (like Friday, January 14th, or Tuesday, January 12th, 2024 at 8am).
When listing items or steps, use narrative listing and sequential prose.
You use narrative style to list steps or items like (first, then, also, and, finally...)

## Response Guideline
Stay in character.
Ensure fluid dialogue: Respond in a role-appropriate, direct manner to maintain smooth conversation flow.
Be brief.

## Formatting
use narrative style to list steps or items like (first, then, also, and, finally...)

## Task
Your goal is to assist the caller like a receptionist at an auto detailing shop, quickly understanding why they're calling and gathering all relevant customer information. If it's an emergency (such as a request for urgent assistance), make sure to notify the appropriate staff immediately.

## Environment
You're working as a receptionist and have access to the appointment schedule from memory, without the need for a computer. You keep details of the work environment to yourself.
The current time is: {{current_time}}

## About Yobi Auto Detailing
At Yobi Auto Detailing, we offer premium detailing services to make your vehicle look its absolute best. Whether you need a quick wash or a full detailing package, we provide the perfect service to keep your car in pristine condition.

Contact Us:
Phone: 408-676-2003
Address: 15166 Los Gatos Blvd, Los Gatos, CA 95032

## Services offered by Yobi Auto Detailing
- Basic wash and vacuum
- Full exterior detailing
- Interior deep cleaning
- Paint correction and ceramic coating
- Headlight restoration
- Engine bay cleaning

## Hours of Operation
Monday through Friday: 8am to 6pm
Saturday: 9am to 5pm
Sunday: Closed

## The Staff at Yobi Auto Detailing
Daniel - Receptionist`,
      tasks: [
        "Caller wants to schedule a detailing appointment",
        "Caller wants to know about services and pricing",
        "Caller needs urgent assistance with their vehicle",
        "Caller wants to speak in Spanish",
        "Caller wants to know business hours"
      ]
    }
  ];

  steps: '1' | '2' | '3' = '2';
  stepsDetails = [
    {
      step: 1,
      name: 'Identity',
      isActive: true,
      isDone: false
    },
    {
      step: 2,
      name: 'Configuration',
      isActive: false,
      isDone: false
    },
    {
      step: 3,
      name: 'Test/Link',
      isActive: false,
      isDone: false
    }
  ];

  uploadStashFiles: Array<{
    filename: string,
    raw_filename: string,
    size: number,
    type: string
  }> = [];

  collections: {
    upload: string[],
    cancel: string[]
  } = {
      upload: [],
      cancel: []
    }

  synthPermissions = {
    'create_synth_agent': false,
    'configure_synth_agent': false,
    'train_synth_agent': false,
    'test_synth_agent': false
  }

  mode: 'create' | 'edit';
  selectedSynth$ = this.store.select(selectedSynth);
  identityDetails?: SynthAgent;
  newCreatedSynthAgent: SynthAgent;
  configurationForm?: ConfigurationForm;
  newCreatedSynthAPI = new BehaviorSubject<{ synth_id: number, tenant_id: string, user_id: string, channel_value: any, endpoint_name: string } | null>(null);
  defaultStep = false;
  attachmentChanged = false;
  embedSynthId: number;
  breadcrumb$ = new BehaviorSubject<BreadcrumbType>('main');

  industryOptions = [{ name: 'Dentistry', value: 'Dentistry' }, { name: 'Other', value: 'Other' }];

  public hideConfigurationForm: boolean = false;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private synthRepository: ISynthRepository
  ) {
    this.activeSynth$.subscribe(() => {
      this.resetSteps();
    });
  }

  selectSynth(synth: Synth): void {
    this.store.dispatch(SelectSynthAction({ payload: { synth } }));
  }

  getSynths(search: string) {
    this.store.dispatch(GetSynthsAction({ payload: { page: 1, page_size: 20, search } }));
  }

  async fetchRecentSynthConversations(synth_id: number, train: string, page?: number) {
    const state = await firstValueFrom(this.recentSynthsConversationsState$)
    this.store.dispatch(GetSynthRecentConversationsAction({
      payload: {
        query: {
          page: page ?? state.currentPage + 1,
          page_size: state.pageSize,
          synth_id,
          train
        }
      }
    }))
  }

  setConversationId(conversationId: number) {
    this.store.dispatch(SelectSynthConversationIdAction({ payload: { conversationId } }));
  }

  selectSynthConversation(conversationId: number) {
    this.fetchSynthConversationInteractions(conversationId);
  }

  async fetchSynthConversationInteractions(conversationId: number) {
    const state = await firstValueFrom(this.selectedSynthConversation$);
    this.store.dispatch(GetSynthConversationInteractionsAction({
      payload: {
        query: {
          conversationId: state.conversationId as number,
          params: {
            page: 1,
            page_size: state.pageSize
          }
        }
      }
    }))
  }

  newSynthConversation(synth_id: string | number, type: 'voice' | 'text', train: boolean) {
    this.store.dispatch(CreateSynthConversationAction({ payload: { data: { synth_id, title: 'Unnamed', type, train } } }))
  }

  sendMessage(data: CreateSynthInteraction) {
    this.store.dispatch(CreateSynthInteractionAction({ payload: { data } }))
  }

  setConversationTitle(synth_conversation_id: number, title: string) {
    this.store.dispatch(UpdateSynthConversationAction({
      payload: { data: { title, is_active: false, is_record_disabled: false }, synth_conversation_id: synth_conversation_id }
    }))
  }

  createSynthAgent(): void {
    this.router.navigate(['main/workshop/create']);
  }

  editSynthAgent(id: number) {
    this.router.navigate([`main/synth-trainer/edit/${id}`]);
  }

  getSynthAgentScript(synth_id: number) {
    return this.synthRepository.getSynthAgentScript(synth_id);
  }

  createToken(retell_agent_id: string, conversation_id: number) {
    return this.synthRepository.createCallSynthAgent(retell_agent_id, conversation_id);
  }

  callYobiOne(retell_agent_id: string, metadata: IYobiOneMetadata) {
    return this.synthRepository.callYobiOne(retell_agent_id, metadata);
  }

  createSynthAgentAction(input: CreateSynthAgentDto): void {
    this.store.dispatch(CreateSynthAgentAction({ payload: input }));
  }

  updateSynthAgentAction(input: CreateSynthAgentDto, synth_id: number): void {
    this.store.dispatch(UpdateSynthAgentAction({ payload: { data: input, synth_id } }))
  }

  getSynthDetails(synth_id: number): void {
    this.store.dispatch(GetSynthAction({ payload: synth_id }));
  }

  getSynthDetailsRaw(synth_id: number) {
    return this.synthRepository.getSynthDetails(synth_id);
  }

  createSynthConversation(data: CreateSynthConversationDto) {
    return this.synthRepository.createSynthConversation(data);
  }

  getLocalSynth(synth_id: number) {
    return this.synthRepository.getLocalSynth(synth_id);
  }

  getLocalSynthTemplateEngine(synth_id: number) {
    return this.synthRepository.getLocalSynthTemplateEngine(synth_id);
  }

  getAudioOpt() {
    return [
      'play', // Play/pause playback
      'progress', // The progress bar and scrubber for playback and buffering
      'current-time', // The current time of playback
      'duration', // The full duration of the media
      'mute', // Toggle mute
      // 'volume', // Volume control
      'captions', // Toggle captions
      'pip', // Picture-in-picture (currently Safari only)
      'airplay', // Airplay (currently Safari only)
      'fullscreen', // Toggle fullscreen
    ];
  }

  generateTranscriptsFeedback(synth_id: number, transcripts: string, synth_conversation_id: number) {
    return this.synthRepository.generateTranscriptsFeedback(synth_id, transcripts, synth_conversation_id);
  }

  applyTranscriptsFeedback(synth_conversation_id: number, synth_agent_id: number, transcript: string, changes: Array<{ type: string, description: string }>, feedback_id: number) {
    return this.synthRepository.applyTranscriptsFeedback(synth_conversation_id, synth_agent_id, transcript, changes, feedback_id);
  }

  resetHandler(resetForm: boolean): void {
    this.newCreatedSynthAgent = undefined as any;
    this.presets = this.presets.map((item) => { return { ...item, isUsed: false }; });
    this.newCreatedSynthAPI.next(null);
    this.collections = { upload: [], cancel: [] };
    if (resetForm) {
      this.mode = undefined as any;
      this.uploadStashFiles = [];
      this.configurationForm = undefined;
      this.identityDetails = undefined;
    }
  }

  showFirstTanscription(input: ITranscriptions): void {
    this.store.dispatch(TrascriptSynthAction({ payload: { transcriptions: input } }));
  }

  get yobiOneAgentId() {
    return environment.production ? 'agent_e86275657acd497226315f7d1e' : 'agent_5136d35c689c664a7d437002e9';
  }

  get yobiOneMetadata(): IYobiOneMetadata {
    return {
      "value": "live_form",
      "form_details": {
        "caller_name": this.getFullName(),
        "pages": {
          "Identity": {
            agent_name: {
              description: 'This is the name that your AI Agent will introduce itself with to your customers',
              change_allowed: true
            },
            role: {
              description: 'The role/job position your AI Agent will try to fill for your business. For example a Customer Support Agent, or a Sales Development Representative',
              change_allowed: true
            },
            company_name: {
              description: 'The Company that your AI Agent works for',
              change_allowed: true
            },
            greeting: {
              description: 'This is the exact message your AI Agent will say at the start of an incoming call',
              change_allowed: true
            },
            personality: {
              description: 'This will be how your AI Agent phrases/responds to your customers. You can prioritize traits like Friendliness, Professionalism that will encourage your Agent to act a certain way. Or provide guidelines such as "Speak in short sentences"',
              change_allowed: true
            },
            voice: {
              description: 'Here users can select the voice of your AI Agent. You can choose from a variety of voices that have been pre-trained to sound like real people. Here is the list of voices that are available: May, Alexis, Alley, Anne, Luke, Ema, Katie, David, Debbie, and Tiffany. You can listen to a sample of each voice to help choose the one that best fits your needs.',
              change_allowed: false
            },
            timezone: {
              description: 'This is the timezone of your AI Agent. It will be used to determine the time of day it is when your AI Agent is responding to your customers.',
              change_allowed: false
            }, 
            industry: {
              description: 'This is the industry  your Business Belongs to. It only has Two Options Dentistry and Other',
              change_allowed: false
            }
          },
          "Configuration": {
            instructions: {
              description: 'Instructions can be used to give the AI Agent a workflow/guide to how the conversation should flow. You can also state here which information you want your AI Agent to prioritize collecting, such as Name, or Phone Number.',
              change_allowed: true
            },
            tools: {
              description: `Tools are ways your AI Agent can be enhanced to perform actions/access additional information.

Available tools include:
• Transfer Call: Transfer calls to different phone numbers
• Always UseKnowledge Base: Look up for factual information from pre-uploaded documents before responding to user
• Conversation Memory: Remember information from previous interactions, You can keep memory for 8 hours, 2 days, 1 week.`,
              change_allowed: false
            },
            task_assignments: {
              description: `This allows your AI to assign you or your coworkers tasks. 
You specify a reason, such as, 'Asked about pricing for service', 
then decide who to assign it to, such as your Salesperson to follow up with this potential lead.`,
              change_allowed: false
            },
            Knowledge_base: {
              description: `Upload your documents to give your Agent access to this information. 
Include Frequently Asked Questions, Articles, or nearly any Text Document, 
for the Agent to use.`,
              change_allowed: false
            }
          },
          "link": {
            link_to_channels: {
              description: "This page has no individual fields, This Page allows to Link your AI Agent to communication channels like phone numbers or messaging platforms, It can be linked to a Phone number, Chatwidget, or can be embedded on a Website, If you dont see your channel you can add it using new channel which should be at the bottom right of the page",
              change_allowed: false
            }
          }

        }
      }
    }
  }

  createConfigurationPayload(value: ConfigurationForm, synth_id: number) {
    const payload: any = {
      instructions: value.instructions,
      task_assignments: value.task_assignments,
      synth_agent_tools: [],
      knowledge_base_uuid: { upload: [], cancel: [] },
      always_kb_lookup: value.always_kb_lookup,
      timezone: this.identityDetails?.timezone,
      industry: this.identityDetails?.industry
    }
    if (value.conversation_memory_model) {
      payload['synth_agent_tools'].push({
        synth_tool_id: 2,
        other: {
          enabled: true,
          conversation_memory: value.conversation_memory
        }
      });
    }
    if (value.transfer_call_model && value.phone_number) {
      const formattedPhoneNumber = getFormattedPhoneNumber(value.phone_number, value.country as CountryCode);
      payload['synth_agent_tools'].push({
        synth_tool_id: 1,
        other: {
          transfer_phone_number: formattedPhoneNumber,
          transfer_to: 'phone_number'
        },
        country: value.country
      });
    }
    if (payload.synth_agent_tools.length === 0) delete payload['synth_agent_tools'];
    if (this.collections.upload.length) {
      payload['knowledge_base_uuid']['upload'] = this.collections.upload;
      payload['synth_agent_knowledge_base'] = { synth_tool_id: 3, other: { 'uploaded_files': this.uploadStashFiles } };
    }
    if (this.collections.cancel.length) payload['knowledge_base_uuid']['cancel'] = this.collections.cancel;
    if (!payload.knowledge_base_uuid.upload.length && !payload.knowledge_base_uuid.cancel.length) delete payload['knowledge_base_uuid'];
    if (this.mode === 'edit') payload['synth_id'] = synth_id;
    return payload;
  }

  deleteSynthAgent(ids: number[]): void {
    this.store.dispatch(DeleteSynthAction({ payload: { data: ids } }));
  }

  deleteSynthLocal(ids: number[]): void {
    this.synthRepository.deleteSynthLocal(ids as any);
  }

  get identityFields() {
    return [
      'synth_name',
      'role',
      'company_name',
      'greetings',
      'personality',
      'voice',
      'timezone',
      'industry'
    ]
  }

  getFormControlsIdentityKeys(sampleKey: string): string {
    const mappings: any = {
      'greeting': 'greetings',
      'agent_name': 'synth_name',
      'greetings': 'greetings',
      'personalities': 'personality',
      'role': 'role',
      'roles': 'role',
      'name': 'synth_name',
      'company': 'company_name',
      'company_name': 'company_name',
      'personality': 'personality',
      'synth_name': 'synth_name'
    };
    return mappings[sampleKey.toLowerCase()] || sampleKey.toLowerCase();
  }

  get configurationFields() {
    return [
      'instructions',
      'phone_number',
      'country',
      'task_assignments',
      'always_kb_lookup',
      'conversation_memory',
      'transfer_call_model',
      'conversation_memory_model',
      'industry',
      'timezone'
    ]
  }


  getFormControlsConfigurationKeys(sampleKey: string): string {
    const mappings: any = {
      'instructions': 'instructions',
      'instruction': 'instructions',
      'conversation_memory': 'conversation_memory_model',
      'conversation_memory_model': 'conversation_memory'
    };
    return mappings[sampleKey.toLowerCase()] || sampleKey.toLowerCase();
  }

  initializeDefaultConfiguration() {
    this.configurationForm = {
      instructions: '',
      task_assignments: [],
      always_kb_lookup: false,
      conversation_memory: '',
      transfer_call_model: false,
      conversation_memory_model: false,
      phone_number: '',
      country: 'US'
    };
  }

  handlePresetSelection(preset: any) {
    if (this.hideConfigurationForm) {
      this.configurationForm = {
        instructions: preset.instructions || '',
        task_assignments: preset.tasks?.map((task: string) => ({ reason: task })) || [],
        always_kb_lookup: false,
        conversation_memory: '',
        transfer_call_model: false,
        conversation_memory_model: false,
        phone_number: '',
        country: 'US'
      };
    }
  }

  getFullName(): string {
    let fullName = '';
    this.profile$.subscribe(profile => {
      if (profile) {
        fullName = profile.firstName;
        console.log('Full Name:', fullName);
        // You can perform other actions with fullName here
      }
    });
    return fullName;
  }

  resetSteps(): void {
    this.steps = '1';
  }

}
