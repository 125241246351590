import { ButtonModule } from '@/app/Ui/components/Button/button.module';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatOptionModule, MatRippleModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from "angular-svg-icon";
import { NgxStripeModule } from 'ngx-stripe';
import { AddCreditCardDialogComponent } from './add-credit-card-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

const matModules = [
    MatRippleModule,
    MatIconModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatDialogModule
]
@NgModule({
    declarations: [
        AddCreditCardDialogComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        ButtonModule,
        AngularSvgIconModule,
        ReactiveFormsModule,
        NgxStripeModule,
        TranslateModule.forChild({ extend: true }),
        ...matModules
    ],
    exports: [
        AddCreditCardDialogComponent
    ],
    providers: [],
})
export class AddCreditCardModule {
    constructor() { }
}
