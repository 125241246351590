import { Component, Inject, OnInit } from '@angular/core';

import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
    selector: 'app-waiting-list',
    templateUrl: './waiting-list.component.html',
    styleUrls: ['./waiting-list.component.scss'],
})
export class WaitingListComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private ref: MatDialogRef<WaitingListComponent>
    ) {

    }

    close(): void {
        this.ref.close();
    }
    ngOnInit(): void {
    }

}
