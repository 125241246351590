import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { NotesViewModel } from '@/app/Ui/ViewModels/NotesViewModel';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';

import { NoteFormDialogComponent } from '../Notes/note-form-dialog/note-form-dialog.component';

import { ContactBlockDto } from '@/app/Data/DTO/ContactBlockDto';
import { ContactImageUpdatResponseDto } from '@/app/Data/DTO/contactImageUpdatResponseDto';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { AppState } from '@/app/State/AppState';
import { BlockContactAction, DeleteContactAction, FetchContactByIdAction, FetchContactChannelsByIdAction, GetUnclassifiedChannelDetailAction, ResetContactStateAction } from '@/app/State/contact/contact.action';
import { selectActiveContact, selectActiveContactId } from '@/app/State/contact/contact.selector';
import { SetActiveContactId } from '@/app/State/contacts/contacts.action';

import { Localizations } from '@/app/Utilities/localization';
import { IContactRepository } from '@/app/core/IRepositories/IContactRepository';
import { ContactChannel } from '@/app/core/Models/Channel';
import { Note } from '@/app/core/Models/Note';
import { Contact } from '@/app/core/Models/contact';

import { Store } from '@ngrx/store';
import { Observable, Observer, catchError, of, shareReplay, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactViewModel {
  activeContactId$ = this.store.select(selectActiveContactId).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );
  activeContact$ = this.store.select(selectActiveContact).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );
  contactChannels$ = this.activeContact$.pipe(
    switchMap(contact => contact?.isContact
      ? this.getLocalContactChannelsById(contact.contact_id)
      : of([])
    )
  );

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private contactRepository: IContactRepository,
    private snackBarService: SnackbarService,
    private notesViewModel: NotesViewModel
  ) { }

  loadContact(contactId: number): void {
    this.store.dispatch(FetchContactByIdAction({ payload: contactId }));
  }

  loadUnclassifiedContact(contactId: number): void {
    this.store.dispatch(GetUnclassifiedChannelDetailAction({ payload: contactId }));
  }

  getLocalContactChannelsById(id: number): Observable<ContactChannel[]> {
    return this.contactRepository.getLocalContactChannelsById(id);
  }

  resetContactState(): void {
    this.store.dispatch(ResetContactStateAction());
  }

  setActiveContactIdActive(contactId: number): void {
    this.store.dispatch(SetActiveContactId({ payload: { contactId } }));
  }

  setActiveContactId(contactId: number): void {
    this.store.dispatch(SetActiveContactId({ payload: { contactId } }));
    this.store.dispatch(FetchContactChannelsByIdAction({ payload: contactId }));
  }

  openNoteForm(data: {
    contactId?: number;
    note?: Note;
    unclassifiedChannelId?: number;
  }): void {
    const dialogRef = this.dialog.open(NoteFormDialogComponent, {
      backdropClass: 'templateBackDrop',
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!!result) {
        //  this.getContactInfo(this.contactId);
      }
    });
  }

  deleteContact(contact: Contact): void {
    this.store.dispatch(DeleteContactAction({ payload: contact.contact_id }));
  }

  blockContact(data: ContactBlockDto): void {
    this.store.dispatch(BlockContactAction({ payload: data }));
  }

  updateContactPicture(
    data: FormData,
    userId: number,
    progress$: Observer<any>,
    contact: Contact
  ): Observable<ContactImageUpdatResponseDto> {
    return this.contactRepository
      .updateProfileImage(data, userId, progress$)
      .pipe(
        tap((res) => {
          const payload = { ...contact, avatar: res.avatar };
          this.contactRepository.saveContact(payload as Contact);
          this.snackBarService.openAlert({
            message: Localizations.alert_dialog.contact_picture_updated,
            type: 'success',
          });
        }),
        catchError((error) => {
          this.snackBarService.openAlert({
            message: Localizations.alert_dialog.contact_picture_not_updated,
            type: 'failure',
          });
          throw error;
        })
      );
  }

  deleteContactPicture(contact: Contact): Observable<SuccessResponse> {
    return this.contactRepository.deleteProfileImage(contact.contact_id).pipe(
      tap(res => {
        const payload = { ...contact, avatar: '', updated_dt: new Date().toISOString() };
        this.contactRepository.saveContact(payload as Contact);
        return res;
      }),
      catchError((error) => {
        this.snackBarService.openAlert({
          message: Localizations.alert_dialog.operation_not_possible,
          type: 'failure',
        });
        throw error;
      }));
  }

  getContactById(id: string | number) {
    return this.contactRepository.getContactById(id as any)
  }

  getLocalContactById(id: string | number) {
    return this.contactRepository.getLocalContactById(id as any)
  }

}
