import { Contact } from './contact';
import { SocketInteraction } from './Interaction';
import { Note } from './Note';
import { Task } from './Task';

export namespace fullTextSearch {
  export type interaction = SocketInteraction;
  export type contact = Pick<Contact, 'avatar' | 'contact_id' | 'family_name' | 'given_name' > & { is_contact: boolean, channel_id?: number };
  export type task = Pick<Task, 'assigned_to' | 'created_by' | 'description' | 'interaction_id' | 'status' | 'task_id' | 'tenant_id' | 'title' | 'user_assigned' | 'user_create'>;
  export type note = Note & { contact: (fullTextSearch.contact & { channel_value: string }) };
  export type tag = contact & { tag_name: string; tag_id: number };
  export enum type {
    Interactions = 'interactions',
    Contacts = 'contacts',
    Notes = 'notes',
    Tags = 'tags',
    Tasks = 'tasks',
  }
}

export class FullTextSearch {
  interactions?: {
    count: number;
    pages: {
      page: number;
      data: fullTextSearch.interaction[];
    }[];
  };
  contacts?: {
    count: number;
    pages: {
      page: number;
      data: fullTextSearch.contact[];
    }[];
  };
  tasks?: {
    count: number;
    pages: {
      page: number;
      data: fullTextSearch.task[];
    }[];
  };
  notes?: {
    count: number;
    pages: {
      page: number;
      data: fullTextSearch.note[];
    }[];
  };
  tags?: {
    count: number;
    pages: {
      page: number;
      data: fullTextSearch.tag[];
    }[];
  };
}

export class FullTextSearchResult {
  interactions?: {
    count: number;
    data: fullTextSearch.interaction[];
  };
  contacts?: {
    count: number;
    data: fullTextSearch.contact[];
  };
  tasks?: {
    count: number;
    data: fullTextSearch.task[];
  };
  notes?: {
    count: number;
    data: fullTextSearch.note[];
  };
  tags?: {
    count: number;
    data: fullTextSearch.tag[];
  };
}

export class LocalFullTextSearch {
  value: string;
  date: Date;
  result: FullTextSearchResult;
  type: fullTextSearch.type[];
}
