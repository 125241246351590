<div class="p-2" class="flex flex-col justify-between gap-5">
  <div
        *ngIf="data?.title"
        class="flex flex-row">
        <h2 
            class="text-xl font-semibold text-dark">
            {{data?.title}}
        </h2>
  </div>
  <div class="text-semibold text-base mt-4">{{ data.message | translate : data.translateParams }}</div>
  <div mat-dialog-actions class="flex flex-row w-full justify-between gap-10 items-center">
    <app-button color="light" (click)="dialogRef.close(false)">
      {{ (data.cancel ??  localization.labels.cancel) | translate }}
    </app-button>

    <app-button [color]="data.warning ? 'warning' : 'primary'" (click)="dialogRef.close(true)">
      {{ (data.confirm ??  localization.labels.confirm) | translate }}
    </app-button>
  </div>
</div>
