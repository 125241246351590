import { AppState } from '@/app/State/AppState';
import { createSelector } from '@ngrx/store';
import { TenantState } from './tenant.reducer';
import { Roles } from '@/app/core/Models/Roles';

const tenantState = (state: AppState) => state.tenant;

export const selectTenantProfile = createSelector(
  tenantState,
  (state: TenantState) => state.profile
);

export const selectIsPrivateAccess = createSelector(
  tenantState,
  (state: TenantState) => state.private_access
);

export const selectTenantCustomFields = createSelector(
  tenantState,
  (state: TenantState) => state.customFields
);

export const selectTenantSignature = createSelector(
  tenantState,
  (state: TenantState) => state.signature
);

export const selectTranslationLanguage = createSelector(
  tenantState,
  (state: TenantState) => state.translationLanguage
);

export const selectSupportedLanguages = createSelector(
  tenantState,
  (state: TenantState) => state.supportedLanguages
);

export const selectSynthAutoreplyDefault = createSelector(
  tenantState,
  (state: TenantState) => state.synthAutoreplyDefault
);

export const selectTenantBusinessName = createSelector(
  tenantState,
  (state: TenantState) => state.profile?.businessName
);


export const selectTenantRoles = createSelector(
  selectTenantProfile,
  (profile) => profile?.roles ?? []
);

export const selectTenantScope = createSelector(
  selectTenantRoles,
  (roles: Roles[]) => {
    const scopeMap: Record<Roles, string> = {
      [Roles.admin]: 'tenant',
      [Roles.user]: 'user',
    };
    const scope = roles.find(role => scopeMap[role]);
    return scopeMap[scope as Roles] ?? '';
  }
);

