/**
 * A component that will serve as a drawer container.
 * The component functionality is stateless.
 * Author: Royce
 * Date Created: Wednesday, Aug 13, 3:13 PM
 *
 */

import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DrawerService {
    constructor() { }

    private drawerStatus = new BehaviorSubject('');
    public drawers: any[] = [];
    public enableOverlay = true;
    public isShown = false

    /**
 * This will add an active drawer to the drawer collections
 */
    add(drawer: any) {
        this.drawers.push(drawer);
    }

    /**
     * This will get all active drawers from the collections
     */
    get alldrawers() {
        return this.drawers;
    }

    /**
     * This will get drawer by id from the collections and open it
     */
    open(id: string) {
        const drawer = this.drawers.find(item => item.id === id);
        if (drawer !== undefined) {
            this.isShown = true;
            drawer.open();
            this.drawerStatus.next('isShown');
        }
    }

    /**
     * This will get drawer by id from the collections and close it
     */
    close(id: string) {
        const drawer = this.drawers.find(item => item.id === id);
        if (this.drawers.length > 0) {
            this.isShown = false;
            drawer.close();
            this.drawerStatus.next('isClose');
        }
    }

    moveLeft(id: string) {
        const drawer = this.drawers.find(item => item.id === id);
        drawer.runMoveLeft();
    }

    moveRight(id: string) {
        const drawer = this.drawers.find(item => item.id === id);
        drawer.runMoveRight();
    }

    /**
     * This will remove drawer by id from the collections
     */
    remove(id: string) {
        this.drawers = this.drawers.filter((item) => {
            if (item.id !== id) return item;
        });
    }

    removeClass(id: string) {
        const element: HTMLElement | null = document.getElementById(id);
        if (element) {
            element.children[0].classList.remove('drawer--movedright');
            element.children[0].classList.remove('drawer--movedleft');
        }
    }

    addStyle(id: string, property: string, style: any): boolean {
        const element: any = document.getElementById(id);
        if (element) element.children[0].style[property] = style;
        return true;
    }

    addOverlay() {
        const overlay = document.getElementsByClassName("drawer-overlay");
        overlay[0].classList.add("show");
    }

}
