import { TokenStorgeService } from '@/app/Data/services/Token/token.service';
import { AppState } from '@/app/State/AppState';
import { SignOutAction } from '@/app/State/app/action';
import { FirebaseService } from '@/app/Utilities/firebase/firebase.service';
import { PlatformService } from '@/app/Utilities/platform/platform.service';
import { CANCEL_API_REQUESTS } from '@/app/app.module';
import { IAuthenticationRepository } from '@/app/core/IRepositories/IAuthenticationRepository';
import { MixpanelService } from '@/app/shared/analytics/mixpanel-service.service';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { ILocalStorageService } from '../../Adapters/ILocalStorageService';
import { AuthenticationRepository } from '../../repositories/AuthenticationRepository';
import { DATABASE_NAME, DatabaseService } from '../Database/database.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // onLogout$ = new Subject()
  constructor(
    private router: Router,
    @Inject(AuthenticationRepository) private authRepository: IAuthenticationRepository,
    private platformService: PlatformService,
    private databaseService: DatabaseService,
    private localStorageService: ILocalStorageService,
    private mixpanelService: MixpanelService,
    private firebaseService: FirebaseService,
    private tokenService: TokenStorgeService,
    @Inject(CANCEL_API_REQUESTS) private cancelApiRequests$: Subject<boolean>,
    private store: Store<AppState>
  ) { }

  async logout(): Promise<any> {
    try {
      await this.firebaseService.onLogout()
    } catch (e) {
      console.error(e)
    }
    this.localStorageService.clear()
    this.cancelApiRequests$.next(true)
    if (this.databaseService.isOpen()) {
      console.log("[LOGOUT CLEAR]")
      try {
        const DBDeleteRequest = window?.indexedDB?.deleteDatabase(DATABASE_NAME);
        DBDeleteRequest.onerror = (e) => { };
        DBDeleteRequest.onsuccess = (e) => { };
      } catch (err) {
        console.log("[LOGOUT]: ", err)
      }
      await this.databaseService.clear();
      this.store.dispatch(SignOutAction());
      if (this.platformService.isElectronApp) {
        this.router.navigateByUrl('auth/login').then();
      } else {
        this.router.navigateByUrl('auth/login').then();
      }
      setTimeout(async () => {
        await this.mixpanelService.reset()
      }, 100);
    }

  }

  refreshToken(refreshToken: string) {
    return this.authRepository.refreshToken({ refreshToken: refreshToken }).pipe(
      map(data => {
        if ((data as any)?.status == 400) throw data;
        this.tokenService.saveToken(data.token);
        this.tokenService.saveRefreshToken(data.refreshToken);
        return data;
      }));
  }
}
