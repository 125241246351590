export enum errors {
  business_name_required = 'errors.business_name_required',
  email_already_used = 'errors.email_already_used',
  email_not_valid = 'errors.email_not_valid',
  email_required = 'errors.email_required',
  business_name_already_used = 'errors.business_name_already_used',
  message_fail_to_send = 'errors.message_fail_to_send',
  note_fail_to_create = 'errors.note_fail_to_create',
  blocked_phone = 'errors.blocked_phone',
  invalid_email = 'errors.invalid_email',
  invalid_phone = 'errors.invalid_phone',
  first_name_required = 'errors.first_name_required',
  last_name_required = 'errors.last_name_required',
  phone_required = 'errors.phone_required',
  required = 'errors.required',
  password_length = 'errors.password_length',
  password_inconsistent = 'errors.password_inconsistent',
  attachment_could_not_be_uploaded = 'errors.attachment_could_not_be_uploaded',
  please_include_instagram_attachment = 'errors.please_include_instagram_attachment',
  full_name_required = 'errors.full_name_required'
}