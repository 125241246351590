import { createSelector } from '@ngrx/store';
import { AppState } from '@/app/State/AppState';
import { ContactState } from '@/app/State/contact/contact.reducer';

const contactState = (state: AppState) => state.contact;

export const selectActiveContactId = createSelector(
  contactState,
  (state: ContactState) => state.contactId
);

export const selectActiveContact = createSelector(
  contactState,
  (state: ContactState) => state.contact
);

export const selectFetchingContact = createSelector(
  contactState,
  (state: ContactState) => state.fetchingContac
);
