import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NotificationItemComponent } from '@/app/Ui/components/notification-item/notification-item.component';
import { ShortDateModule } from '@/app/Ui/pipes/short-date.pipe';
import { AvatarModule } from '@/app/Ui/components/avatar/avatar.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AngularSvgIconModule } from 'angular-svg-icon';
@NgModule({
  declarations: [NotificationItemComponent],
  exports: [NotificationItemComponent],
  imports: [
    CommonModule,
    MatRippleModule,
    MatIconModule,
    MatButtonModule,
    AvatarModule,
    MatProgressSpinnerModule,
    AngularSvgIconModule,
    ShortDateModule,
    NgScrollbarModule,
  ],
  providers: [],
})
export class NotificationItemModule {
  constructor() {}
}
