import { IReformulateTextRepository } from '@/app/core/IRepositories/IReformulateTextRepository';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { map, Observable, pluck } from 'rxjs';
import { ReformulationDto, ReformulationResponseDto } from '../DTO/reformulationDto';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';


@Injectable({
  providedIn: 'root',
})
export class ReformulateTextRepository implements IReformulateTextRepository {
  constructor(
    private httpService: HttpService
  ) {}

  reformulate(request: ReformulationDto): Observable<ReformulationResponseDto> {
    const requestURL = `${environment.apiURL}reformulate_message`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<ReformulationResponseDto>;
        return res.results;
      })
    );
  }

}