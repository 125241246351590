import { createAction, props, union } from '@ngrx/store';

export enum RightPanelIndexes {
	transcription = 0,
	contact = 1,
	notes = 2,
	tasks = 3,
	users = 4,
	teamChat = 5,
}


export const SET_RIGHT_PANEL_ACTIVE_WINDOW = '[media observer] Set Right Panel Active Window';
export const SetRightPanelActiveWindowAction = createAction(
  SET_RIGHT_PANEL_ACTIVE_WINDOW,
  props<{ payload: RightPanelIndexes }>()
);

export const VERIFY_SMALL_SCREENS_FOR_LEFT_SIDE_PANELS = '[media observer] verify small screens for left side panels';
export const VerifySmallScreensForLeftSidePanelsAction = createAction(
  VERIFY_SMALL_SCREENS_FOR_LEFT_SIDE_PANELS
);

export const TOGGLE_PAGE_LEFT_SIDE_PANEL = '[media observer] toggle page left side panel';
export const TogglePageLeftSidePanelAction = createAction(
  TOGGLE_PAGE_LEFT_SIDE_PANEL
);

export const TOGGLE_INBOX_DETAILS_PANEL = '[media observer] toggle Inbox details panel';
export const ToggleInboxDetailsPanelAction = createAction(
  TOGGLE_INBOX_DETAILS_PANEL
);

export const TOGGLE_SIDE_NAV = '[media observer] toggle side nav';
export const ToggleSideNav = createAction(
  TOGGLE_SIDE_NAV
);

export const TOGGLE_DARK_MODE = '[media observer] toggle dark mode';
export const ToggleDarkMode = createAction(
  TOGGLE_DARK_MODE,
  props<{ payload: boolean }>()
);

// export const TOGGLE_COMPACT_MODE = '[media observer] toggle Compact mode';
// export const ToggleCompactMode = createAction(
//   TOGGLE_COMPACT_MODE,
//   props<{ payload: boolean }>()
// );

const all = union({
  VerifySmallScreensForLeftSidePanelsAction,
  TogglePageLeftSidePanelAction,
  ToggleInboxDetailsPanelAction,
  ToggleSideNav,
  ToggleDarkMode,
  // ToggleCompactMode
});

export type MediaObserverActions = typeof all;
