<div class="flex items-center gap-4">
  <button
    class="absolute ml-4"
    style="height: 1rem; width: 1.5rem;"
    *ngIf="selectionType === 'phone'"
    countryCodeSelector
    (countryChange)="countryChange($event)"
  >
    <img
      class="w-full h-full object-cover rounded-sm"
      [src]="'assets/flags/' + countryCode.toLowerCase() + '.svg'"
    />
  </button>
  <input
    [ngClass]="{invalid: invalid}"
    class="phone-input"
    cdkFocusInitial
    [placeholder]="(isEmail ? localization.placeholder.enter_email : localization.placeholder.enter_number_or_name) | translate"
    [formControl]="inputController"
    (input)="inputController.value.length && contactsMenuTrigger.openMenu()"
    (keyup.enter)="_addPhoneNumber(); contactsMenuTrigger.closeMenu()"
    (blur)="partiallyValidPhoneNumber && _addPhoneNumber()"
    #contactsMenuTrigger="matMenuTrigger"
    [mat-menu-trigger-for]="contactsMenu"
  >
</div>

<mat-menu
  #contactsMenu="matMenu"
  class="contacts-menu"
>
  <div
    class="w-full surface-bg-color text-dark"
    style="max-height: 15rem; width: 22.5rem;"
  >
    <div
      *ngIf="partiallyValidPhoneNumber"
      class="cursor-pointer flex items-center p-4 border-b secondary-border-color hover:light-bg-color gap-2"
      [ngClass]="{'border-b': contacts.length}"
      (click)="_addPhoneNumber()"
    >
      <p class="font-medium text-mild">{{ localization.messages.press_button_to_add | translate: { buttonName: isMac ?
        'Return' : 'Enter' , value: isEmail ? inputController.value : formattedPhone } }}</p>
    </div>
    <ng-container *ngFor="let contact of contacts; trackBy: trackContact, index as i">
      <div
        class="cursor-pointer flex items-center p-2 px-3 hover:light-bg-color gap-3"
        (click)="_addContactChannel(i)"
      >
        <app-avatar
          class="h-7 w-7 shrink-0"
          [url]="contact.avatar"
          [name]="contact.displayName"
          [fontSize]="10"
        ></app-avatar>
        <div class="flex items-center justify-between gap-4 w-full overflow-hidden">
          <p class="text-dark font-medium truncate">{{ contact.displayName }}</p>
          <p class="text-sm text-pale font-medium truncate shrink-0">
            {{ isEmail ? (contact.channelValue || contact.email) : (contact.channelValue | phone) }}
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</mat-menu>