<!-- -------------------- conference members start ----------------------- -->
<div class="flex flex-col">
	<ng-container *ngFor="let member of conferenceMembers">
		<div
			*ngIf="member.displayName || $any(member)?.number as displayName"
			mat-ripple
			class="flex flex-col justify-start items-center py-4 px-6 relative hover:bg-gray-100 cursor-pointer"
			[class.active-caller]="member.activeCaller && !callSession.isTransfer"
			(click)="selectActiveMember.emit(member.id)"
		>
			<div class="flex w-full flex-row justify-start items-center gap-4">
				<app-avatar
					class="h-11 w-11 shrink-0"
					[url]="member.avatar"
					[name]="displayName"
				></app-avatar>
				<div class="flex flex-col gap-1 flex-1">
					<span class="text-base font-semibold text-zinc-800">
						{{ displayName | phone }}
					</span>
					<span
						class="text-xs font-semibold text-zinc-700"
						*ngIf="member.displayName && $any(member)?.number"
					>
						{{ $any(member)?.number | phone }}
					</span>
				</div>
				<ng-container
					*ngIf="member.type == 'member'"
					[ngSwitch]="member.status"
				>
					<span
						*ngSwitchCase="'hold'"
						class="text-md font-semibold text-zinc-800"
					>
						On hold
					</span>
					<span
						*ngSwitchCase="'unhold'"
						class="text-md font-semibold text-zinc-800"
					>
						In the call
					</span>
					<span
						*ngSwitchCase="'ringing'"
						class="text-md font-semibold text-zinc-800"
					>
						Ringing
					</span>
					<span
						*ngSwitchCase="'in-progress'"
						class="text-md font-semibold text-zinc-800"
					>
						In progress
					</span>
				</ng-container>
				<div
					*ngIf="member.type !== 'member'"
					class="flex items-center gap-2 text-zinc-800"
				>
					<span
						*ngIf=" member.status == 'hold'"
						class="text-md font-semibold italic"
					>
						On hold
					</span>
					<span
						*ngIf=" member.status !== 'hold' && member.type === 'customer'"
						class="text-md font-medium text-zinc-800"
					>
						{{ callTimer }}
					</span>
				</div>
			</div>
		</div>
	</ng-container>
</div>
<!-- ----------------------- conference members end ------------------------ -->
<!-- <mat-divider></mat-divider> -->
<!-- ------------------------ actions buttons start ------------------------ -->
<div class="flex flex-col gap-2 px-6 py-4 relative">
	<div class="flex justify-center flex-1 drop-shadow-lg">
		<div class="actions-container gap-4">
			<!-- ---------------------------- answer button ---------------------------- -->
			<button
				*ngIf="!callSession.live && !callSession.outgoing"
				mat-ripple
				matTooltip="Accept Call"
				class="rounded-full border-0 flex justify-center items-center pickup-btn hover:shadow"
				(click)="accept.emit()"
			>
				<img
					src="assets/icons/new-set/dialer/pickup.svg"
					alt="pickup"
					class="svg-white-filter"
					style="width: 22px; height: 22px"
				>
			</button>
			<!-- ---------------------------- hungup button ---------------------------- -->
			<button
				mat-ripple
				matTooltip="End Call"
				class="rounded-full border-0 flex justify-center items-center hangup-btn hover:shadow"
				(click)="hangup.emit()"
			>
				<img
					src="assets/icons/new-set/dialer/hangup.svg"
					alt="pickup"
					class="svg-white-filter"
					style="width: 22px; height: 22px"
				>
			</button>
			<!-- ----------------------------- mute button ----------------------------- -->
			<button
				mat-ripple
				matTooltip="Mute"
				class="rounded-full border-0 flex justify-center items-center bg-white hover:shadow"
				(click)="toggleMute.emit()"
			>
				<img
					class="svg-gray-filter"
					style="width: 22px; height: 22px"
					[src]="callSession.mute 
						? 'assets/icons/new-set/dialer/microphone-off.svg'
						: 'assets/icons/new-set/dialer/microphone-on.svg'"
					alt="microphone-on-off"
				>
			</button>
			<!-- ----------------------------- hold button ----------------------------- -->
			<button
				*ngIf="!callSession.isTransfer"
				mat-ripple
				matTooltip="Hold"
				class="rounded-full border-0 flex justify-center items-center bg-white hover:shadow"
				(click)="putOnHold.emit()"
			>
				<img
					class="svg-gray-filter"
					style="width: 22px; height: 22px"
					[src]="activeCallerOnHold
						? 'assets/icons/new-set/dialer/play.svg'
						: 'assets/icons/new-set/dialer/pause.svg'"
					alt="play-pause"
				>
			</button>
			<!-- --------------------------- dialpad button ---------------------------- -->
			<button
				*ngIf="callSession.live || callSession.outgoing"
				mat-ripple
				matTooltip="Dialer pad"
				class="rounded-full border-0 flex justify-center items-center bg-white hover:shadow"
				(click)="toggleDialpad()"
			>
				<img
					[ngClass]="[ activePanel === 'dialpad' ? 'svg-primary-filter' : 'svg-gray-filter' ]"
					style="width: 22px; height: 22px"
					src="assets/icons/new-set/dialer/dialpad.svg"
					alt="play-pause"
				>
			</button>
			<!-- ------------------------- adjustments button -------------------------- -->
			<button
				mat-ripple
				matTooltip="Settings"
				class="rounded-full border-0 flex justify-center items-center bg-white hover:shadow"
				[dropDownPanel]="settingsRef"
				[panelClass]="['custom-dialog-panel']"
			>
				<img
					class="svg-gray-filter"
					style="width: 22px; height: 22px"
					src="assets/icons/new-set/dialer/adjustments.svg"
					alt="play-pause"
				>
			</button>
			<ng-template #settingsRef>
				<app-dialer-audio-settings></app-dialer-audio-settings>
			</ng-template>
			<!-- ------------------------- swap active caller -------------------------- -->
			<button
				*ngIf="conferenceMembers.length > 1"
				mat-ripple
				matTooltip="Swap sellected caller"
				class="rounded-full border-0 flex justify-center items-center bg-white hover:shadow"
				(click)="swapActiveCaller()"
			>
				<img
					class="svg-gray-filter"
					style="width: 22px; height: 22px"
					src="assets/icons/new-set/dialer/swap.svg"
					alt="swap"
				>
			</button>
			<!-- ----------------------------- Invite user ----------------------------- -->
			<button
				*ngIf="callSession.live && callSession.conference?.participants?.length === 2"
				mat-ripple
				matTooltip="Invite member"
				class="rounded-full border-0 flex justify-center items-center bg-white hover:shadow"
				(click)="toggleTransferList()"
			>
				<img
					[ngClass]="[ activePanel === 'transfer-list' ? 'svg-primary-filter' : 'svg-gray-filter' ]"
					style="width: 22px; height: 22px"
					src="assets/icons/new-set/dialer/add-member.svg"
					alt="add-user"
				>
			</button>
			<!-- ----------------------------- merge calls ----------------------------- -->
			<button
				*ngIf="canMerge"
				mat-ripple
				matTooltip="Merge calls"
				class="rounded-full border-0 flex justify-center items-center bg-white hover:shadow"
				(click)="mergeCall.emit()"
			>
				<img
					class="svg-gray-filter"
					style="width: 22px; height: 22px"
					src="assets/icons/new-set/dialer/merge.svg"
					alt="merge"
				>
			</button>
			<!-- ---------------------------- transfer call ---------------------------- -->
			<button
				*ngIf="canTransfer"
				mat-ripple
				matTooltip="Transfer call"
				class="rounded-full border-0 flex justify-center items-center bg-white hover:shadow"
				(click)="forwardCall.emit()"
			>
				<img
					class="svg-gray-filter"
					style="width: 22px; height: 22px"
					src="assets/icons/new-set/dialer/transfer.svg"
					alt="merge"
				>
			</button>
		</div>
	</div>

	<div
		class="flex flex-col overflow-hidden active-panel"
		[class.panel-open]="!!activePanel"
		[ngSwitch]="activePanel"
	>
		<app-dialer-pad
			*ngSwitchCase="'dialpad'"
			[ivrDigit]="digit"
			(onDigit)="onDigit($event)"
		></app-dialer-pad>
		<app-dialer-transfer-list
			*ngSwitchCase="'transfer-list'"
			class="mt-4"
			[members]="teamMembers"
			(onAddEvent)="inviteUser.emit($event); toggleTransferList()"
		></app-dialer-transfer-list>
	</div>
</div>