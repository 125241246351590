import { ITenantRepository } from '@/app/core/IRepositories/ITenantRepository';
import { InternalChannel } from '@/app/core/Models/Channel';
import { IndustryTenantTypes, Tenant, TenantCustomFields } from '@/app/core/Models/tenant';
import { getUploadEventProgress } from '@/app/Utilities/functions/getUploadEventProgress';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { liveQuery } from 'dexie';
import {
  from, last, map,
  Observable, Observer, pluck,
  tap
} from 'rxjs';
import { EditProfileDto } from '../DTO/EditProfileDto';
import { ProfileImageUpdatResponseDto } from '../DTO/ProfileImageUpdatResponseDto';
import { SuccessResponse } from '../DTO/successResponse';
import { DatabaseService } from '../services/Database/database.service';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';
import { CompanyLogoUpdatResponseDto } from '../DTO/CompanyLogoUpdatResponseDto';
import { fingerprintDto } from '../DTO/fingerprintDto';
import { IActivity } from '@/app/Data/services/Activity/activity.service';

@Injectable({
  providedIn: 'root',
})
export class TenantRepository implements ITenantRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) { }

  deleteProfileImage(userId: number): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}user/delete_avatar?user_id=${userId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  toggleDoNotDisturb(userId: number, status: boolean): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}user/update/do_not_disturb`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { do_not_disturb: status ? 'On' : 'Off', user_id: userId },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }


  updateProfileImage(
    data: FormData,
    userId: number,
    progress$: Observer<any>
  ): Observable<ProfileImageUpdatResponseDto> {
    const requestURL = `${environment.apiURL}user/update_avatar?user_id=${userId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false,
      null,
      true
    );
    return this.httpService.upload(options).pipe(
      tap((event: any) => getUploadEventProgress(event, progress$)),
      last(),
      map((item) => {
        let response = new SuccessApiResponse<ProfileImageUpdatResponseDto>();
        response.results = item;
        return response;
      }),
      pluck('results'),
      pluck('body'),
      map((item) => {
        let res = item as SuccessApiResponse<ProfileImageUpdatResponseDto>;
        return res.results;
      })
    );
  }

  updateCompanyLogo(
    data: FormData,
    progress$: Observer<any>
  ): Observable<CompanyLogoUpdatResponseDto> {
    const requestURL = `${environment.apiURL}logo`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false,
      null,
      true
    );
    return this.httpService.upload(options).pipe(
      tap((event: any) => getUploadEventProgress(event, progress$)),
      last(),
      map((item) => {
        let response = new SuccessApiResponse<ProfileImageUpdatResponseDto>();
        response.results = item;
        return response;
      }),
      tap((kk) => console.log({ kk })),
      pluck('results'),
      pluck('body'),
      map((item) => {
        let res = item as SuccessApiResponse<CompanyLogoUpdatResponseDto>;
        return res.results;
      })
    );
  }

  editProfile(request: EditProfileDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}user-profile`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  getInternalChannels(): Observable<InternalChannel[]> {
    const requestURL = `${environment.apiURL}internal-channels`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<InternalChannel[]>;
        return res.results;
      })
    );
  }

  saveInternalChannels(channels: InternalChannel[]): void {
    this.databaseService
      .transaction('rw!', this.databaseService.internalChannels, async () => {
        const localInternalChannels = await this.databaseService.internalChannels.toArray();
        const newChannelsKeys = channels.map((channel) => channel.channel_id);
        const deletedChannels = localInternalChannels
          .filter((item) => newChannelsKeys.indexOf(item.channel_id) == -1)
          .map((item) => item.channel_id as unknown as string);
        await this.databaseService.internalChannels.bulkDelete(deletedChannels);
        await this.databaseService.internalChannels.bulkPut(channels);
      })
  }

  getLocalInternalChannels(): Observable<InternalChannel[]> {
    return from(
      liveQuery(() => {
        this.databaseService.internalChannels.mapToClass(InternalChannel);
        return this.databaseService.internalChannels.toArray();
      })
    );
  }

  getSavedTenantProfile(): Observable<Tenant | undefined | null> {
    return from(
      liveQuery(async () => {
        this.databaseService.tenants?.mapToClass(Tenant);
        const list = await this.databaseService.tenants?.toArray();
        return list?.pop();
      })
    );
  }

  fetchUserSignatureStatus(): Observable<boolean> {
    const requestURL = `${environment.apiURL}user_signature/status`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<{ attribute_name: 'SIGNATURE_STATUS', attribute_value: boolean }>;
        return res.results.attribute_value;
      })
    );
  }

  setUserSignatureStatus(request: { status: boolean }): Observable<boolean> {
    const requestURL = `${environment.apiURL}user_signature/status`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<{ attribute_name: 'SIGNATURE_STATUS', attribute_value: boolean }>;
        return res.results.attribute_value;
      })
    );
  }

  fetchUserSignatureValue(): Observable<string> {
    const requestURL = `${environment.apiURL}user_signature/value`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<{ attribute_name: 'SIGNATURE_VALUE', attribute_value: string }>;
        return res.results.attribute_value;
      })
    );
  }

  setUserSignatureValue(request: { signature_value: string }): Observable<string> {
    const requestURL = `${environment.apiURL}user_signature/value`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<{ attribute_name: 'SIGNATURE_VALUE', attribute_value: string }>;
        return res.results.attribute_value;
      })
    );
  }

  sendUserFingerPrint(request: fingerprintDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}users/fingerprint`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  /* ------------------------- autopilot default state ------------------------ */

  setAutopilotDefaultState(request: { status: boolean }): Observable<boolean> {
    const requestURL = `${environment.apiURL}autopilotdefaultstate`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<{ attribute_name: 'SYNTH_AUTOPILOT_DEFAULT_STATE', attribute_value: boolean }>;
        return res.results.attribute_value;
      })
    );
  }

  fetchAutopilotDefaultState(): Observable<boolean> {
    const requestURL = `${environment.apiURL}autopilotdefaultstate`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<{ attribute_name: 'SYNTH_AUTOPILOT_DEFAULT_STATE', attribute_value: boolean }>;
        return res.results.attribute_value;
      })
    );
  }

  setBusinessName(businessName: string): Observable<string> {
    const requestURL = `${environment.apiURL}admin/tenants/update-name`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      { 'business_name': businessName },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<{ business_name: string }>;
        return res.results.business_name;
      })
    );
  }

  setPhoneNumber(request: { phone_number: string }): Observable<string> {
    const requestURL = `${environment.apiURL}user_phonenumber/value`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<{ attribute_name: "PREFERRED_PHONE_NUMBER", attribute_value: string }>;
        return res.results.attribute_value;
      })
    );
  }

  fetchUserPhoneNumber(): Observable<string> {
    const requestURL = `${environment.apiURL}user_phonenumber/value`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<{ attribute_value: string }>;
        return res.results.attribute_value;
      })
    );
  }

  getActivity(entity_name: 'company', entity_id: string): Observable<IActivity[]> {
    const requestURL = `${environment.apiURL}activity_log?entity_name=${entity_name}&entity_id=${entity_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<IActivity[]>;
        return res.results;
      })
    );
  }

  getTenantCustomFields(): Observable<TenantCustomFields[]> {
    const requestURL = `${environment.apiURL}company/custom_fields`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TenantCustomFields[]>;
        return res.results;
      })
    );
  }

  createTenantCustomFields(items: Array<{ custom_field_name: string }>): Observable<TenantCustomFields[]> {
    const requestURL = `${environment.apiURL}company/custom_fields`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { "custom_field_names": items },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TenantCustomFields[]>;
        return res.results;
      })
    );
  }

  updateTenantCustomFields(items: Array<{ custom_field_name: string, custom_field_key_id: string }>): Observable<TenantCustomFields[]> {
    const requestURL = `${environment.apiURL}company/custom_fields_name`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      items,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TenantCustomFields[]>;
        return res.results;
      })
    );
  }

  deleteTenantCustomFields(custom_field_key_id: string): Observable<string> {
    const requestURL = `${environment.apiURL}company/${custom_field_key_id}/custom_fields_name`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<string>;
        return res.results;
      })
    );
  }

  getTenantIndustry(): Observable<IndustryTenantTypes[]> {
    const requestURL = `${environment.apiURL}company-industry`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<IndustryTenantTypes[]>;
        return res.results;
      })
    );
  }

  updateTenantIndustry(data: Array<{ industry_type: string, description: string, tenant_company_industry_id?: number }>): Observable<IndustryTenantTypes> {
    const requestURL = `${environment.apiURL}company-industry`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      { "industries": data },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<IndustryTenantTypes>;
        return res.results;
      })
    );
  }


}