import { createAction, props } from '@ngrx/store';
import { SocketConfig } from '@/app/Data/Adapters/ISocketClient';
import { SocketInteraction } from '@/app/core/Models/Interaction';

export const SOCKET_CONNECT = '[Socket] Connecting Socket...';
export const SocketConnectAction = createAction(
  SOCKET_CONNECT,
  props<{ payload: SocketConfig }>()
);

export const SOCKET_CONNECTED = '[Socket] Socket Connected';
export const SocketConnectedAction = createAction(
  SOCKET_CONNECTED,
  props<{ payload: any }>()
);

export const SOCKET_DISCONNECTED = '[Socket] Socket Disconnected';
export const SocketDisconnectedAction = createAction(
  SOCKET_DISCONNECTED,
  props<{ payload: any }>()
);

export const SOCKET_CONNECT_FAIL = '[Socket] Socket Connect Failed';
export const SocketFailedAction = createAction(
  SOCKET_CONNECT_FAIL,
  props<{ payload: any }>()
);

export const SOCKET_INCOMING_INTERACTION =
  '[Socket] Socket incoming interaction';
export const IncomingInteractionAction = createAction(
  SOCKET_INCOMING_INTERACTION,
  props<{ payload: SocketInteraction }>()
);
