import {
  AssociatedCompanies,
  AssociatedCompany,
  AssociatedContact,
  Company,
  CompanyCustomFields,
  CompanyNotes,
  CompanyTasks,
  SelectedPipeline
} from '@/app/core/Models/company';

import {
  FetchCompanyByIdAction,
  FetchCompanyByIdFailedAction,
  FetchCompanyByIdSuccessAction,
  GetAssociateContactCompanySuccessAction,
  GetCompaniesListAction,
  GetCompaniesListFailAction,
  GetCompaniesListSuccessAction,
  ResetCompanyStateAction,
  SetActiveCompanyId,
  ShowCompanyPageAction,
  CreateCompanyNoteAction,
  UpdateCompanyNoteAction,
  DeleteCompanyNoteAction,
  GetCompanyNoteAction,
  GetCompanyNoteSuccessAction,
  GetCompanyTaskAction,
  GetCompanyTaskSuccessAction,
  GetCompanyTaskFailAction,
  GetCompanyCustomFieldsAction,
  GetCompanyCustomFieldsSuccessAction,
  GetAssociateCompanyToCompanySuccessAction,
  GetAssociateDealsToCompanySuccessAction,
  GetCompanyPipelinesSuccessAction,
  GetCompanyAttachmentsSuccess, CompanyCreateSuccessAction

} from './companies.action';
import { SignOutAction } from '../app/action';

import { createReducer, on } from '@ngrx/store';
import { Deal } from '@/app/core/Models/deal';
export interface CompaniesState {
  companies: {
    isLastpage: boolean,
    fetching: boolean,
    page?: number,
  }
  active?: {
    companyId: string;
    company?: Company,
    fetching?: boolean,
  },
  associatedContacts?: AssociatedContact[],
  associatedCompanies?: AssociatedCompanies,
  associatedNotes?: CompanyNotes[],
  associatedTasks?: CompanyTasks[],
  associatedCustomFields?: CompanyCustomFields[]
  associatedDeals?: Deal[]
  associatedAttachments?: any[]
  associatedPipelines?: SelectedPipeline[],
  newlyCreatedCompany?: Company
}

export const initCompaniesState: CompaniesState = {
  companies: {
    fetching: false,
    isLastpage: false,
  }
};

export const CompaniesReducer = createReducer(
  initCompaniesState,
  on(
    GetCompaniesListAction,
    (state: CompaniesState, { }) => {
      return {
        ...state,
        companies: {
          ...state.companies,
          fetching: true
        }
      }
    }
  ),
  on(
    ShowCompanyPageAction,
    (state: CompaniesState, { payload }) => {
      return {
        ...state,
        active: {
          company: payload,
          companyId: payload.yobi_crm_company_id
        }
      }
    }
  ),
  on(
    GetCompaniesListSuccessAction,
    (state: CompaniesState, { payload: { companies, params } }) => {
      return {
        ...state,
        companies: {
          ...state.companies,
          page: params.page,
          fetching: false,
          isLastpage: companies.length < params.page_size
        }
      }
    }
  ),
  on(
    GetCompaniesListFailAction,
    (state: CompaniesState, { }) => {
      return {
        ...state,
        companies: {
          ...state.companies,
          fetching: false
        }
      }
    }
  ),
  on(SetActiveCompanyId, (state: CompaniesState, { payload: { companyId } }) => {
    return {
      ...state,
      active: {
        companyId,
      }
    };
  }),
  on(FetchCompanyByIdAction, (state: CompaniesState, { payload }) => {
    return {
      ...state,
      active: {
        ...state.active,
        companyId: payload,
        fetching: true
      }
    };
  }),
  on(FetchCompanyByIdSuccessAction, (state: CompaniesState, { payload }) => {
    return {
      ...state,
      active: {
        ...state.active!,
        fetching: false,
        company: payload,
      }
    };
  }),
  on(FetchCompanyByIdFailedAction, (state: CompaniesState, { }) => {
    return {
      ...state,
      active: {
        ...state.active!,
        fetching: false,
      }
    };
  }),
  on(SignOutAction, () => {
    return {
      ...initCompaniesState,
    };
  }),
  on(ResetCompanyStateAction, (state: CompaniesState, { }) => {
    return {
      ...state,
      active: undefined
    };
  }),
  on(GetAssociateContactCompanySuccessAction, (state: CompaniesState, { payload }) => {
    return {
      ...state,
      associatedContacts: payload
    };
  }),
  on(GetCompanyNoteSuccessAction, (state: CompaniesState, { payload }) => {
    return {
      ...state,
      associatedNotes: payload
    };
  }),
  on(GetCompanyTaskSuccessAction, (state: CompaniesState, { payload }) => {
    return {
      ...state,
      associatedTasks: payload
    }
  }),
  on(GetCompanyCustomFieldsSuccessAction, (state: CompaniesState, { payload }) => {
    return {
      ...state,
      associatedCustomFields: payload
    }
  }),
  on(GetAssociateDealsToCompanySuccessAction, (state: CompaniesState, { payload }) => {
    return {
      ...state,
      associatedDeals: payload
    }
  }),
  on(GetAssociateCompanyToCompanySuccessAction, (state: CompaniesState, { payload }) => {
    return {
      ...state,
      associatedCompanies: payload
    }
  }),
  on(GetCompanyAttachmentsSuccess, (state: CompaniesState, { payload }) => {
    return {
      ...state,
      associatedAttachments: payload.attachments
    }
  }),
  on(GetCompanyPipelinesSuccessAction, (state: CompaniesState, { payload }) => {
    return {
      ...state,
      associatedPipelines: payload
    }
  }),
  on(CompanyCreateSuccessAction, (state: CompaniesState, { payload }) => {
    return {
      ...state,
      newlyCreatedCompany: payload.data
    }
  })
);

