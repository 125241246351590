import { ConferenceUpdateStatus, ConferenceUpdateType } from '@/app/core/Models/ConferenceParticipant';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../AppState';
import { VoiceClientConferenceCustomerUpdateAction, VoiceClientConferenceHostUpdateAction, VoiceClientConferenceInfoUpdateAction, VoiceClientConferenceMemberUpdateAction, VoiceClientConferenceRemoveHostAction, VoiceClientConferenceRemoveMemberAction } from '../VoiceClient/voice-client-transfer.action';
import { selectVoiceClientState } from '../VoiceClient/voice-client..selector';
import { ConferenceParicipantTypes } from '../VoiceClient/voice-client.reducer';
import { ConferenceCustomerParticipant, ConferenceHostParticipant, ConferenceMemberParticipant } from './../../core/Models/ConferenceParticipant';
import { VoiceClientConferenceUpdateDto } from './../../Data/DTO/VoiceClient/VoiceClientConferenceUpdateDto';

@Injectable({
  providedIn: 'root',
})
export class ConferenceStateMapperService {
  callState$ = this.store.select(selectVoiceClientState);

  constructor(private store: Store<AppState>) { }

  handleConferenceUpdate(conferenceUpdate: VoiceClientConferenceUpdateDto) {
    console.log('--------------------------------------------');
    const conference = conferenceUpdate.conference.data
    console.log(conferenceUpdate.conference);
    const merged = conference.merged;
    const particpantsNumber = conference.participants_number;
    const parentCallSid = conferenceUpdate.conference.parent_call_sid;
    const updateEvent = conferenceUpdate.update;
    const date = conferenceUpdate.date;
    /* ------------------------------- conference ------------------------------- */
    this.stillAvailable(updateEvent.data.status) && this.store.dispatch(
      VoiceClientConferenceInfoUpdateAction({
        payload: {
          parentCallSid,
          conference: {
            date,
            merged,
            particpantsNumber,
          }
        },
      })
    );
    /* ------------------------------ participants ------------------------------ */
    let host: ConferenceHostParticipant | undefined = undefined;
    let customer: ConferenceCustomerParticipant | undefined = undefined;
    let members: ConferenceMemberParticipant[] = [];
    for (const [key, value] of Object.entries(conference.participants)) {
      switch (key) {
        case 'customer':
          customer = value as ConferenceCustomerParticipant;
          break;
        case 'host':
          host = value as ConferenceHostParticipant;
          break;
        default:
          key.includes('agent') && members.push(value as ConferenceMemberParticipant);
          break;
      }
    }
    host && this.store.dispatch( // TODO this dispatch could be deleted to be verified
      VoiceClientConferenceHostUpdateAction({
        payload: {
          parentCallSid,
          host: {
            ...host,
            type: ConferenceParicipantTypes.host,
            id: ConferenceParicipantTypes.host,
          }
        }
      })
    );
    switch (updateEvent.type) {
      case ConferenceUpdateType.callStatus:
        /* ----------------------- customer call status update ---------------------- */
        if (updateEvent.data.type.includes('customer')) {
          customer &&
            this.store.dispatch(
              VoiceClientConferenceCustomerUpdateAction({
                payload: {
                  parentCallSid,
                  customer: {
                    ...customer,
                    status: updateEvent.data.status,
                    type: ConferenceParicipantTypes.customer,
                    id: ConferenceParicipantTypes.customer,
                  }
                }
              })
            );
        }
        /* ------------------------ agent call status update ----------------------- */
        if (updateEvent.data.type.includes('agent')) {
          const member = members.find((item) => item.user_id == updateEvent?.data?.user_id);
          if (this.stillAvailable(updateEvent.data.status)) {
            member &&
              this.store.dispatch(
                VoiceClientConferenceMemberUpdateAction({
                  payload: {
                    parentCallSid,
                    member: {
                      ...member,
                      status: updateEvent.data.status,
                      type: ConferenceParicipantTypes.member,
                      id: member.user_id,
                    },
                  },
                })
              );
          } else {
            this.store.dispatch(
              VoiceClientConferenceRemoveMemberAction({
                payload: {
                  parentCallSid,
                  member: {
                    // mute: false,
                    // on_hold: false,
                    status: updateEvent.data.status,
                    type: ConferenceParicipantTypes.member,
                    user_id: updateEvent.data.user_id,
                    id: updateEvent.data.user_id,
                  },
                },
              })
            );
          }
        }
        break;
      case ConferenceUpdateType.join:
      case ConferenceUpdateType.hold:
      case ConferenceUpdateType.unhold:
        /* ---------------------------- host event update --------------------------- */
        if (updateEvent.data?.type == 'host') {
          host && this.store.dispatch(
            VoiceClientConferenceHostUpdateAction({
              payload: {
                parentCallSid,
                host: {
                  ...host,
                  id: ConferenceParicipantTypes.host,
                  type: ConferenceParicipantTypes.host,
                  status: updateEvent.data.status,
                },
              },
            })
          );
        }
        /* -------------------------- customer event update ------------------------- */
        if (updateEvent.data?.type == 'customer') {
          customer && this.store.dispatch(
            VoiceClientConferenceCustomerUpdateAction({
              payload: {
                parentCallSid,
                customer: {
                  ...customer,
                  id: ConferenceParicipantTypes.customer,
                  type: ConferenceParicipantTypes.customer,
                  status: updateEvent.data.status,
                },
              },
            })
          );
        }
        /* --------------------------- agent event update --------------------------- */
        if (updateEvent.data.type.includes('agent')) {
          const member = members.find((item) => item.user_id == updateEvent?.data?.user_id);
          member && this.store.dispatch(
            VoiceClientConferenceMemberUpdateAction({
              payload: {
                parentCallSid,
                member: {
                  ...member,
                  id: member.user_id,
                  type: ConferenceParicipantTypes.member,
                  status: updateEvent.data.status,
                },
              },
            })
          );
        }
        break;
      case ConferenceUpdateType.leave:
        /* ---------------------------- host leave update --------------------------- */
        if (updateEvent.data.type.includes('host')) {
          this.store.dispatch(
            VoiceClientConferenceRemoveHostAction({
              payload: {
                parentCallSid
              },
            })
          );
        }
        /* -------------------------- customer leave update ------------------------- */
        if (updateEvent.data.type.includes('customer')) { }
        /* --------------------------- agent leave update --------------------------- */
        if (updateEvent.data.type.includes('agent')) {
          const member = members.find((item) => item.user_id == updateEvent?.data?.user_id);
          member && this.store.dispatch(
            VoiceClientConferenceRemoveMemberAction({
              payload: {
                parentCallSid,
                member: {
                  // mute: false,
                  // on_hold: false,
                  id: updateEvent.data.user_id,
                  type: ConferenceParicipantTypes.member,
                  status: updateEvent.data.status,
                  user_id: updateEvent.data.user_id,
                },
              },
            })
          );
        }
        break;
    }
    this.checkActiveCaller();
  }

  stillAvailable(status: ConferenceUpdateStatus): boolean {
    return ![
      ConferenceUpdateStatus.busy,
      ConferenceUpdateStatus.noAnswer,
      ConferenceUpdateStatus.completed,
      ConferenceUpdateStatus.canceled,
      ConferenceUpdateStatus.leave,
    ].includes(status);
  }

  checkActiveCaller(): void {
    // TODO: check active caller
  }
}
