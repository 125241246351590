export enum calls {
  outgoing_call_from = 'calls.outgoing_call_from',
  incoming_call_from = 'calls.incoming_call_from',
  missed_call_from = 'calls.missed_call_from',
  waiting_time = 'calls.waiting_time',
  incoming_call = 'calls.incoming_call',
  outgoing_call = 'calls.outgoing_call',
  answered_by = 'calls.answered_by',
  call_duration = 'calls.call_duration',
  forward_calls = 'calls.forward_calls',
  forward_calls_description = 'calls.forward_calls_description',
  set_ring_duration_description = 'calls.set_ring_duration_description',
  custom_voice = 'calls.custom_voice',
  add_custom_voice_description = 'calls.add_custom_voice_description',
  custom_greeting = 'calls.custom_greeting',
  add_custom_greeting_description = 'calls.add_custom_greeting_description',
  call_notifications = 'calls.call_notifications',
  missed_call_notifications = 'calls.missed_call_notifications',
  voicemail_notifications = 'calls.voicemail_notifications',
  hide_summary = 'calls.hide_summary',
  see_full_summary = 'calls.see_full_summary',
}