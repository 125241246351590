import { AvatarModule } from '@/app/Ui/components/avatar/avatar.module';
import { TaskDetailsComponent } from '@/app/Ui/tasks/task-details/task-details.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MentionModule } from 'angular-mentions';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ButtonModule } from '@/app/Ui/components/Button/button.module';
import { TextInputModule } from '@/app/Ui/components/text-input/text-input.module';
import { ShortDateModule } from '@/app/Ui/pipes/short-date.pipe';
import { TaskCommentInputComponent } from '@/app/Ui/tasks/task-comment-input/task-comment-input.component';
import { TaskCommentComponent } from '@/app/Ui/tasks/task-comment/task-comment.component';
import { TaskDescriptionComponent } from '@/app/Ui/tasks/task-description/task-description.component';
import { TaskLogsComponent } from '@/app/Ui/tasks/task-logs/task-logs.component';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxViewerModule } from 'ngx-viewer';
import { TranslateModule } from '@ngx-translate/core';
import { AttachmentsViewerModule } from '../../components/attachments-viewer/attachments-viewer.module';
import { InputWrapperModule } from '../../components/new-input-wrapper/input-wrapper.module';
import { FormattedTextareaModule } from '../../components/formatted-textarea/formatted-textarea.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DropDownPanelDirectiveModule } from '../../directives/dropdown-panel.directive';
import { TagsModule } from '../../tags/tags.module';
import { FileUploaderModule } from '../../components/file-uploader/file-uploader.module';

@NgModule({
  declarations: [
    TaskDetailsComponent,
    TaskLogsComponent,
    TaskCommentInputComponent,
    TaskDescriptionComponent,
    TaskCommentComponent,
  ],
  exports: [TaskDetailsComponent],
  imports: [
    CommonModule,
    TextInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxViewerModule,
    TranslateModule.forChild({ extend: true }),
    AvatarModule,
    MatDividerModule,
    TagsModule,
    FormsModule,
    MentionModule,
    MatTooltipModule,
    ShortDateModule,
    AttachmentsViewerModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    FlexLayoutModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    ButtonModule,
    InputWrapperModule,
    AngularSvgIconModule,
    FormattedTextareaModule,
    MatDatepickerModule,
    DropDownPanelDirectiveModule,
    FileUploaderModule
  ],
  providers: [],
})
export class TaskDetailsModule { }
