import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MentionModule } from 'angular-mentions';
import { FormattedTextareaComponent } from './formatted-textarea.component';
import { TranslateModule } from '@ngx-translate/core';
import { SwipeDirectiveModule } from '../../directives/swipe.directive';
import { ResizableDirectiveModule } from '../../directives/resizable.directive';
import { NameInitialPipeModule } from "../../pipes/initials.pipe";
import { CursorPositionDirectiveModule } from '../../directives/cursor-position.directive';;

@NgModule({
  declarations: [FormattedTextareaComponent],
  imports: [
    CommonModule,
    FormsModule,
    ResizableDirectiveModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    ReactiveFormsModule,
    SwipeDirectiveModule,
    FormsModule,
    MentionModule,
    NameInitialPipeModule,
    CursorPositionDirectiveModule
  ],
  exports: [FormattedTextareaComponent]
})
export class FormattedTextareaModule { }
