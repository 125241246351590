import { BusinessHoursResponse } from '@/app/Data/DTO/BusinessHoursDto';
import { ChannelType } from './ChannelTypes';
import { UnclassifiedContact } from './contact';

export abstract class Channel {
  channel_id: number;
  channel_name: string;
  channel_type: ChannelType;
  channel_value: string;
  created_dt: string;
  tenant_id: number;
}

export class UnclassifiedChannel extends Channel {
  autoreplay_enabled: boolean;
  avatar: string;
  contact_info: string
  is_blocked: boolean
  unclassified_channel_id: string | number
  mapToUnclassifiedContact(): UnclassifiedContact {
    return Object.assign<UnclassifiedContact, Partial<UnclassifiedContact>>(new UnclassifiedContact(), {
      unclassifiedChannelId: this.unclassified_channel_id as any,
      channelValue: this.channel_value,
      channelType: this.channel_type,
      channelId: this.channel_id,
      is_blocked: this.is_blocked,
      avatar: this.avatar,
      tenant_id: this.tenant_id,
      displayName: this.contact_info,
    });
  }
}

export class InternalChannel extends Channel {
  first_active_dt: string;
  is_record_disabled: boolean;
  last_active_dt: string;
  user_id: number;
  username: string;
  vendor_channel_id?: string;
  video_enabled?: boolean;
  voice_enabled?: boolean;
  synth?: boolean;
  synth_voice_answer?: boolean;
  isLink?: boolean;
}
export class ContactChannel extends Channel {
  contact_id: number;
}

export class PhoneInternalChannel extends InternalChannel {

  record: boolean;
  forward: string;
  incoming_custom_message?: string; // greating message url
  custom_voice_mail?: string; // voice mail url
  business_hours?: BusinessHoursResponse

  // RECORD?: 'true' | 'false';
  // FORWARD?: 'OFF' | string;
  // 'BUSINESS-HOURS'?: "OFF" | "ON";
}

export enum CustomVoiceAttributeName {
  incoming_custom_message = 'incoming_custom_message',
  custom_voice_mail = 'custom_voice_mail'
}
