import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { A11yModule } from "@angular/cdk/a11y";

import { TextInputComponent } from './text-input.component';

@NgModule({
  declarations: [TextInputComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, A11yModule],
  exports: [TextInputComponent],
})
export class TextInputModule { }
