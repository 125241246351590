import { Channel, InternalChannel } from "./Channel";
import { ChannelType } from "./ChannelTypes";

export class Inbox {
    inbox_id: number;
    inbox_name?: string;
    unread_count: number;
    inbox_attributes?: {
        access: boolean,
        visible: boolean,
        notification: boolean,
    };
    inbox_type?: 'tags';
    channel_name?: string;
    channel_type?: ChannelType;
    channel_value?: string;
    created_dt?: string;

    get name(): string {
        return this.inbox_name
            ? this.inbox_name
            : [ChannelType.Phone, ChannelType.Outlook, ChannelType.Google, ChannelType.Email, ChannelType.Whatsapp].includes(this.channel_type!)
                ? this.channel_value! : this.channel_name!
    }

    get is_custom(): boolean {
        return !this.is_channel
    }

    get is_channel(): boolean {
        return !!this.channel_type
    }

    get is_visible(): boolean {
        return !this.inbox_attributes || this.inbox_attributes.visible
    }
}

export interface inboxesResponseDto {
    inboxes: ChannelInbox[],
    custom_inboxes: customInbox[]
}

export interface ChannelInbox extends Channel {
    inbox_attributes: {
        access: boolean,
        visible: boolean,
        notification: boolean,
    };
    unread_count: number;
}

export interface EmailInbox extends Channel {
    email_classifications: {
        classification: string,
        email_classification_id: number,
        unread_count: number,
        priority: number,
    }[];
}

export interface customInbox {
    inbox_id: number;
    inbox_name: string;
    inbox_type: 'tags';
    created_dt: Date;
    updated_dt: Date;
}

export interface InboxAttibuteQuery {
    inboxId: number,
    attribute_name: "access" | "notification" | "visible",
    attribute_value: boolean
}

export interface CustomInboxDto {
    inbox_type: "tags",
    inbox_id: number
}