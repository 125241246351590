import {
  InteractionThread,
  RecentInteraction,
  RecentMessagesQueryParams
} from '@/app/core/Models/Interaction';
import { TeamInteraction } from '@/app/core/Models/interaction-team';
import { TeamMember } from '@/app/core/Models/TeamMember';
import { InternalMessageStatusDto } from '@/app/Data/DTO/InternalMessageStatusDto';
import { TeamMessageBody } from '@/app/Data/DTO/TeamChatMessageDto';
import { TeamChatThreadMessagesQueryDto } from '@/app/Data/DTO/TeamChatThreadMessagesQueryDto';
import { createAction, props, union } from '@ngrx/store';

/**************************** RECENT MESSAGES *********************************/
export const FETCH_TEAM_RECENT_MESSAGES = '[Team Inbox] Get Recent Messages';
export const FetchTeamRecentMessagesAction = createAction(
  FETCH_TEAM_RECENT_MESSAGES,
  props<{ payload: RecentMessagesQueryParams }>()
);

export const FETCH_TEAM_RECENT_MESSAGES_SUCCESS = '[Team Inbox] Successfully Fetched Recent Messages';
export const FetchTeamRecentMessagesSuccessAction = createAction(
  FETCH_TEAM_RECENT_MESSAGES_SUCCESS,
  props<{ payload: RecentInteraction[] }>()
);

export const FETCH_TEAM_RECENT_MESSAGES_FAIL = '[Team Inbox] Failed To Fetch Team Recent Messages';
export const FetchTeamRecentMessagesFailAction = createAction(
  FETCH_TEAM_RECENT_MESSAGES_FAIL,
  props<{ payload: string }>()
);

export const FETCH_TEAM_CHAT_MESSAGES = '[Team Inbox] Fetch Team chat Messages';
export const FetchTeamChatMessagesAction = createAction(
  FETCH_TEAM_CHAT_MESSAGES,
  props<{ payload: TeamChatThreadMessagesQueryDto }>()
);

export const FETCH_TEAM_CHAT_MESSAGES_SUCCESS = '[Team Inbox] Fetch Team chat Messages success';
export const FetchTeamChatMessagesSuccessAction = createAction(
  FETCH_TEAM_CHAT_MESSAGES_SUCCESS,
  props<{ payload: { interactions: InteractionThread[], page: number } }>()
);


export const FETCH_TEAM_CHAT_MESSAGES_FAIL = '[Team Inbox] Fetch Team chat Messages fail';
export const FetchTeamChatMessagesFailAction = createAction(
  FETCH_TEAM_CHAT_MESSAGES_FAIL,
  props<{ payload: string }>()
);

export const SEND_TEAM_CHAT_MESSAGE = '[Team Inbox] send Team chat Message';
export const SendTeamChatMessageAction = createAction(
  SEND_TEAM_CHAT_MESSAGE,
  props<{ payload: TeamMessageBody }>()
);

/* ------------------------- delete team interaction ------------------------ */
export const DELETE_TEAM_CHAT_MESSAGE = '[Team Inbox] Delete Team chat Message';
export const DeleteTeamChatMessageAction = createAction(
  DELETE_TEAM_CHAT_MESSAGE,
  props<{ payload: { interaction_id: number } }>()
);

export const DELETE_TEAM_CHAT_MESSAGE_SUCCESS = '[Team Inbox] Delete Team chat Message success';
export const DeleteTeamChatMessageSuccessAction = createAction(
  DELETE_TEAM_CHAT_MESSAGE_SUCCESS,
  props<{ payload: { threadInteraction: InteractionThread } }>()
);

export const DELETE_TEAM_CHAT_MESSAGE_FAIL = '[Team Inbox] Delete Team chat Message FAIL';
export const DeleteTeamChatMessageFailAction = createAction(
  DELETE_TEAM_CHAT_MESSAGE_FAIL,
  props<{ payload: string }>()
);
/* ------------------------- react to team interaction ------------------------ */
export const REACT_TO_TEAM_CHAT_MESSAGE = '[Team Inbox] React to Team chat Message';
export const ReactToTeamChatMessageAction = createAction(
  REACT_TO_TEAM_CHAT_MESSAGE,
  props<{ payload: { reaction: string, interaction_id: number } }>()
);

export const HANDLE_REACT_TO_TEAM_CHAT_MESSAGE = '[Team Inbox] Handle React to Team chat Message';
export const HandleReactToTeamChatMessageAction = createAction(
  HANDLE_REACT_TO_TEAM_CHAT_MESSAGE,
  props<{ payload: { reaction: string, interaction_id: number, user_id: string | number } }>()
);

export const REACT_TO_TEAM_CHAT_MESSAGE_SUCCESS = '[Team Inbox] React to Team chat Message success';
export const ReactToTeamChatMessageSuccessAction = createAction(
  REACT_TO_TEAM_CHAT_MESSAGE_SUCCESS,
  props<{ payload: { reaction: string, interaction_id: number | string, user_id: number | string } }>()
);

export const REACT_TO_TEAM_CHAT_MESSAGE_FAIL = '[Team Inbox] React to Team chat Message FAIL';
export const ReactToTeamChatMessageFailAction = createAction(
  REACT_TO_TEAM_CHAT_MESSAGE_FAIL,
  props<{ payload: { reaction: string, interaction_id: number | string, user_id: number | string } }>()
);

// export const SEND_TEAM_CHAT_FILES = '[Team Inbox] send Team chat FILES';
// export const SendTeamChatFilesAction = createAction(
//   SEND_TEAM_CHAT_FILES,
//   props<{ payload: { data: FormData, progress$: Observer<any> } }>()
// );

export const UPDATE_TEAM_CHAT_MESSAGE_STATUS = '[Team Inbox] update Team chat Message status';
export const UpdateTeamChatMessageStatusAction = createAction(
  UPDATE_TEAM_CHAT_MESSAGE_STATUS,
  props<{ payload: { interation_id: number, data: InternalMessageStatusDto } }>()
);
export const CLEAR_PENDING_TEAM_CHAT_MESSAGE = '[Team Inbox] clear pendingn Team chat Message';
export const ClearPendingTeamChatMessageAction = createAction(
  CLEAR_PENDING_TEAM_CHAT_MESSAGE,
  props<{ payload: { msg_id: number } }>()
);
export const SEND_TEAM_CHAT_MESSAGE_SUCCESS = '[Team Inbox] send Team chat Message success';
export const SendTeamChatMessageSuccessAction = createAction(
  SEND_TEAM_CHAT_MESSAGE_SUCCESS,
  props<{ payload: { teamInteraction: TeamInteraction[], msg_id: number } }>()
);
export const SEND_TEAM_CHAT_MESSAGE_FAIL = '[Team Inbox] send Team chat Message fail';
export const SendTeamChatMessageFailAction = createAction(
  SEND_TEAM_CHAT_MESSAGE_FAIL,
  props<{ payload: { errorStr: string, msg_id: number } }>()
);
export const RESEND_TEAM_CHAT_MESSAGE = '[Team Inbox] Resend Team chat Message ';
export const ResendTeamChatMessageAction = createAction(
  RESEND_TEAM_CHAT_MESSAGE,
  props<{ payload: { msg_id: number } }>()
);

export const OPEN_TEAM_CHAT_THREAD = '[Team Inbox] Open Team Chat thread';
export const OpenTeamChatThreadAction = createAction(
  OPEN_TEAM_CHAT_THREAD,
  props<{ payload: { user: TeamMember | undefined, idle?: boolean } }>()
);
export const CLOSE_TEAM_CHAT_THREAD = '[Team Inbox] Close Team Chat thread';
export const CloseTeamChatThreadAction = createAction(
  CLOSE_TEAM_CHAT_THREAD,
  props<{ payload: { userId: number } }>()
);

export const CLOSE_ALL_TEAM_CHAT_THREADS = '[Team Inbox] Close All Team Chat threads';
export const CloseAllTeamChatThreadsAction = createAction(
  CLOSE_ALL_TEAM_CHAT_THREADS,
);

const all = union({

  FetchTeamRecentMessagesAction,
  FetchTeamRecentMessagesSuccessAction,
  FetchTeamRecentMessagesFailAction,

  FetchTeamChatMessagesAction,
  FetchTeamChatMessagesSuccessAction,
  FetchTeamChatMessagesFailAction,

  SendTeamChatMessageAction,
  // SendTeamChatFilesAction,
  UpdateTeamChatMessageStatusAction,
  SendTeamChatMessageSuccessAction,
  SendTeamChatMessageFailAction,
  ClearPendingTeamChatMessageAction,

  DeleteTeamChatMessageAction,
  DeleteTeamChatMessageSuccessAction,
  DeleteTeamChatMessageFailAction,

  ReactToTeamChatMessageAction,
  ReactToTeamChatMessageSuccessAction,
  HandleReactToTeamChatMessageAction,
  ReactToTeamChatMessageFailAction,

  OpenTeamChatThreadAction,
  CloseTeamChatThreadAction,
});

export type TeamInboxActions = typeof all;
