<div class="w-full h-full flex flex-col gap-1">
  <div 
    matRipple
    *ngFor="let member of members"
    class="flex py-3 px-4 items-center hover:bg-gray-100 rounded-lg cursor-pointer gap-3"
    (click)="addMember(member)"
  >
    <app-avatar
      class="h-11 w-11 shrink-0"
      [data]="member"
      [class.online]="member.active"
    ></app-avatar>
    <div class="flex-grow">{{ member?.given_name }} {{ member?.family_name }}</div>
    <img
      class="h-6 w-6 svg-gray-filter hover:filter-none"
      src="assets/icons/new-set/dialer/add-member.svg"
      alt="add-user"
    >
  </div>
</div>