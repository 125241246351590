import { createAction, props, union } from '@ngrx/store';
import { User, UserInvitation } from '@/app/core/Models/User';
import { TeamMember } from '@/app/core/Models/TeamMember';
import { TeamMemberOnlineEventDto } from '@/app/Data/DTO/Team/TeamMemberOnlineEventDto';
import { InviteUserDto, EditUserDto, CreateUserDto } from '@/app/Data/DTO/CreateUserDto';
import { Tenant } from '@/app/core/Models/tenant';

/* ------------------------------- FETCH USERS ------------------------------ */
export const FETCH_USERS_LIST = '[USERS] Fetch Users List';
export const FetchUsersListAction = createAction(FETCH_USERS_LIST);

export const USER_LIST_LOADED = '[USERS] Users List Loaded';
export const UsersListLoadedAction = createAction(
  USER_LIST_LOADED,
  props<{ payload: User[] }>()
);

export const USER_LIST_FAILED_TO_LOAD = '[USERS] Users List Failed To Load';
export const UsersListFailedToLoad = createAction(
  USER_LIST_FAILED_TO_LOAD,
  props<{ payload: string }>()
);

/* ---------------------------- FETCH INVITATIONS --------------------------- */
export const FETCH_INVITATIONS_LIST = '[INVITATIONS] Fetch Invitations List';
export const FetchInvitationsListAction = createAction(FETCH_INVITATIONS_LIST);

export const FETCH_INVITATIONS_LIST_SUCCESS = '[INVITATIONS] Invitations List Loaded';
export const InvitationsListLoadedAction = createAction(
  FETCH_INVITATIONS_LIST_SUCCESS,
  props<{ payload: UserInvitation[] }>()
);

export const FETCH_INVITATIONS_LIST_FAIL = '[INVITATIONS] Invitations List Failed To Load';
export const InvitationsListFailedToLoad = createAction(
  FETCH_INVITATIONS_LIST_FAIL,
  props<{ payload: string }>()
);

/* ---------------------------- DELETE INVITATIONS -------------------------- */
export const DELETE_INVITATION = '[INVITATIONS] Delete invitation';
export const DeleteInvitationAction = createAction(
  DELETE_INVITATION,
  props<{ payload: { invitation_id: number } }>()
);

export const DELETE_INVITATION_SUCCESS = '[INVITATIONS] Delete invitation success';
export const DeleteInvitationSuccessAction = createAction(
  DELETE_INVITATION_SUCCESS
);

export const DELETE_INVITATION_FAIL = '[INVITATIONS] Delete invitation fail';
export const DeleteInvitationFailAction = createAction(
  DELETE_INVITATION_FAIL,
  props<{ payload: string }>()
);

/* --------------------------- FETCH TEAM MEMBERS --------------------------- */
export const FETCH_TEAM_MEMBERS = '[USERS] Fetch Team Members List';
export const FetchTeamMembersAction = createAction(FETCH_TEAM_MEMBERS);

export const TEAM_MEMBERS_LIST_LOADED = '[USERS] Team Members List Loaded';
export const TeamMembersLoadedAction = createAction(
  TEAM_MEMBERS_LIST_LOADED,
  props<{ payload: TeamMember[] }>()
);

export const TEAM_MEMBERS_LIST_FAILED_TO_LOAD = '[USERS] Team Members List Failed To Load';
export const TeamMembersListFailedToLoadAction = createAction(
  TEAM_MEMBERS_LIST_FAILED_TO_LOAD,
  props<{ payload: string }>()
);

/* ------------------------------- CREATE USER ------------------------------ */
export const INVITE_USER = '[USERS] Invite User';
export const InviteUserAction = createAction(
  INVITE_USER,
  props<{ payload: InviteUserDto }>()
);
export const INVITE_USER_SUCCESS = '[USERS] Invite User SUCCESS';
export const InviteUserSuccessAction = createAction(
  INVITE_USER_SUCCESS,
);
export const INVITE_USER_FAILED = '[USERS] Invite User Failed';
export const InviteUserFailedAction = createAction(
  INVITE_USER_FAILED,
  props<{ payload: { message: string } }>()
);

/* ------------------------------- UPDATE USER ------------------------------ */
export const UPDATE_USER = '[USERS] Update User';
export const UpdateUserAction = createAction(
  UPDATE_USER,
  props<{ payload: { request: EditUserDto, userId: number} }>()
);
export const UPDATE_USER_SUCCESS = '[USERS] Update User SUCCESS';
export const UpdateUserSuccessAction = createAction(
  UPDATE_USER_SUCCESS,
);
export const UPDATE_USER_FAILED = '[USERS] Update User Failed';
export const UpdateUserFailedAction = createAction(
  UPDATE_USER_FAILED,
  props<{ payload: { message: string } }>()
);

/* ------------------------------ ONLINE EVENT ------------------------------ */
export const TEAM_MEMBER_ONLINE_EVENT = '[USERS] Team member online event';
export const TeamMemberOnlineEventAction = createAction(
  TEAM_MEMBER_ONLINE_EVENT,
  props<{ payload: TeamMemberOnlineEventDto }>()
);

const all = union({
  InviteUserAction,
  InviteUserFailedAction,
  DeleteInvitationAction,
  UpdateUserAction,
  UpdateUserFailedAction
});

export type UsersActions = typeof all;
