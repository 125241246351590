import { ContactBlockDto } from '@/app/Data/DTO/ContactBlockDto';
import { renameFieldReuqestDto } from '@/app/Data/DTO/CustomFieldDto';
import { ContactChannel, UnclassifiedChannel } from '@/app/core/Models/Channel';
import { Contact } from '@/app/core/Models/contact';
import { createAction, props, union } from '@ngrx/store';


export const RESET_CONTACT_STATE = '[CONTACT] Reset contact State';
export const ResetContactStateAction = createAction(
  RESET_CONTACT_STATE);
/* ------------------------- fetch contact c hannels ------------------------ */
export const FETCH_CONTACT_CHANNELS = '[CONTACT] Fetch Contact Channels';
export const FetchContactChannelsByIdAction = createAction(
  FETCH_CONTACT_CHANNELS,
  props<{ payload: number }>()
);

export const CONTACT_CHANNELS_LOADED = '[CONTACT] Contact Channels Loaded';
export const ContactChannelsLoadedAction = createAction(
  CONTACT_CHANNELS_LOADED,
  props<{ payload: { contactId: number, channels: ContactChannel[] } }>()
);

export const CONTACT_CHANNELS_FAILED_TO_FETCH =
  '[CONTACT] Contact Channels Failed Fetch';
export const ContactChannelsFailedToLoadAction = createAction(
  CONTACT_CHANNELS_FAILED_TO_FETCH
);

/* ----------------------------- create contact ----------------------------- */
export const CONTACT_CREATE_SUCCESS =
  '[CONTACT] Contact create success';
export const ContactCreateSuccessAction = createAction(
  CONTACT_CREATE_SUCCESS
);

/* ------------------------------ fetch contact ----------------------------- */
export const FETCH_CONTACT_BY_ID = '[CONTACT] Fetch Contact By Id';
export const FetchContactByIdAction = createAction(
  FETCH_CONTACT_BY_ID,
  props<{ payload: number }>()
);

export const FETCH_CONTACT_BY_ID_SUCCESS = '[CONTACT] Fetch Contact By Id Success';
export const FetchContactByIdSuccessAction = createAction(
  FETCH_CONTACT_BY_ID_SUCCESS,
  props<{ payload: Contact }>()
);

export const FETCH_CONTACT_BY_ID_FAILED = '[CONTACT] Fetch Contact By Id Failed';
export const FetchContactByIdFailedAction = createAction(
  FETCH_CONTACT_BY_ID_FAILED,
  props<{ payload: { status: number } }>()
);

/* ----------------------- fetch Unclassified contact ----------------------- */

export const GET_UNCLASSIFIED_CHANNEL_DETAIL = "[Unclassified] Get unclassified channel detail"
export const GetUnclassifiedChannelDetailAction = createAction(
  GET_UNCLASSIFIED_CHANNEL_DETAIL,
  props<{ payload: number }>()
);

export const GET_UNCLASSIFIED_CHANNEL_DETAIL_SUCCESS = "[Unclassified] Get unclassified channel detail success"
export const GetUnclassifiedChannelDetailSuccessAction = createAction(
  GET_UNCLASSIFIED_CHANNEL_DETAIL_SUCCESS,
  props<{ payload: UnclassifiedChannel }>()
);

export const GET_UNCLASSIFIED_CHANNEL_DETAIL_FAIL = "[Unclassified] Get unclassified channel detail fail"
export const GetUnclassifiedChannelDetailFailAction = createAction(
  GET_UNCLASSIFIED_CHANNEL_DETAIL_FAIL
);

/* ----------------------------- delete contact ----------------------------- */
export const DELETE_CONTACT = '[CONTACTS] Delete Contact';
export const DeleteContactAction = createAction(
  DELETE_CONTACT,
  props<{ payload: number }>()
);
export const DELETE_CONTACT_SUCCESS = '[CONTACTS] Contact Successfully Deleted';
export const ContactDeleteSuccessAction = createAction(
  DELETE_CONTACT_SUCCESS,
  props<{ payload: number }>()
);
export const DELETE_CONTACT_FAIL = '[CONTACTS] Failed To Delete Contact';
export const ContactDeleteFailAction = createAction(
  DELETE_CONTACT_FAIL,
  props<{ payload: string }>()
);

/* ------------------------------ block Contact ----------------------------- */
export const BLOCK_CONTACT = '[CONTACT] Block Contact';
export const BlockContactAction = createAction(
  BLOCK_CONTACT,
  props<{ payload: ContactBlockDto }>()
);
export const BLOCK_CONTACT_SUCCESS = '[CONTACT] Contact Blocked Successfully';
export const BlockContactSuccessAction = createAction(
  BLOCK_CONTACT_SUCCESS,
  props<{ payload: number }>()
);
export const BLOCK_CONTACT_FAIL = '[CONTACT] Failed To Block Contact';
export const BlockContactFailAction = createAction(
  BLOCK_CONTACT_FAIL,
  props<{ payload: string }>()
);

/* ------------------------------ custom fields ----------------------------- */
export const RENAME_CUSTOM_FIELD = '[CONTACT] Rename custom field';
export const RenameCustomFieldAction = createAction(
  RENAME_CUSTOM_FIELD,
  props<{ payload: renameFieldReuqestDto }>()
);

const all = union({
  FetchContactChannelsByIdAction,
  ContactChannelsLoadedAction,
  ContactCreateSuccessAction,
  ContactChannelsFailedToLoadAction,
  GetUnclassifiedChannelDetailAction,
  FetchContactByIdAction,
  ContactDetailLoadedAction: FetchContactByIdSuccessAction,
  ContactDetailFailedToLoadAction: FetchContactByIdFailedAction,
  DeleteContactAction,
  ContactDeleteSuccessAction,
  ContactDeleteFailAction,
  BlockContactAction,
  BlockContactSuccessAction,
  BlockContactFailAction,
  RenameCustomFieldAction
});
export type ContactActions = typeof all;
