import { AddYobiPhoneDto } from '@/app/Data/DTO/AddYobiPhoneDto';
import { GetTenantInternalChannelsAction } from '@/app/State/Tenant/tenant.action';
import { Localizations } from '@/app/Utilities/localization';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';
import { IChannelRepository } from '@/app/core/IRepositories/IChannelRepository';
import { IOnboardingRepository } from '@/app/core/IRepositories/IOnboardingRepository';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import { AppState } from '../AppState';
import { GetTenantProfileAction } from '../Tenant/tenant.action';

import { ErrorApiReponse } from '@/app/Data/services/Networking/ApiResponse';
import { MatDialog } from '@angular/material/dialog';
import {
  PhoneRequestActions,
  PHONE_CHANNEL_ADD,
  PHONE_CHANNEL_ADD_SUCCESS,
  PHONE_VERIFICATION_CODE_REQUEST,
  PHONE_VERIFICATION_CODE_REQUEST_SUCCESS,
  PHONE_VERIFICATION_CODE_VALIDATION,
  PHONE_VERIFICATION_CODE_VALIDATION_SUCCESS,
  PhoneChannelAddFailAction,
  PhoneChannelAddSuccessAction,
  PhoneVerificationCodeRequestFailAction,
  PhoneVerificationCodeRequestSuccessAction,
  PhoneVerificationCodeValidationFailAction,
  PhoneVerificationCodeValidationSuccessAction,
} from './phoneRequest.action';
import { RequestPhoneVerificationCodeDto } from '@/app/Data/DTO/verifications/RequestPhoneVerificationCodeDto';
import { ValidatePhoneVerificationCodeDto } from '@/app/Data/DTO/verifications/ValidatePhoneVerificationCodeDto';
import { IAuthenticationRepository } from '@/app/core/IRepositories/IAuthenticationRepository';

@Injectable()
export class PhoneRequestEffect {
  constructor(
    private actions$: Actions<PhoneRequestActions>,
    private matRef: MatDialog,
    private channelRepository: IChannelRepository,
    private onboardingRepository: IOnboardingRepository,
    private snackBarService: SnackbarService,
    private authRepository: IAuthenticationRepository,
    private store$: Store<AppState>
  ) { }

  /* ------------------------------ Verify phone ------------------------------ */

  onPhoneVerificationCodeRequestAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PHONE_VERIFICATION_CODE_REQUEST),
        map((action: any) => action.payload),
        exhaustMap((request: RequestPhoneVerificationCodeDto) => {
          return this.authRepository.requestPhoneVerificationCode(request).pipe(
            map((result) => {
              return PhoneVerificationCodeRequestSuccessAction();
            }),
            catchError((error: ErrorApiReponse<any>) => {
              console.log('errrr ', error);
              let str = '';
              if (error?.responseError?.error?.result) {
                str = error?.responseError?.error?.result;
              } else {
                if (error?.responseError.statusCode == 400) {
                  str = 'Phone number already exits.';
                }
                if (error?.responseError.statusCode == 500) {
                  str =
                    error?.responseError?.error?.message ?? 'Operation was not possible';
                }
              }
              this.snackBarService.openAlert({
                message: str ?? Localizations.alert_dialog.something_went_wrong,
                type: 'failure',
                duration: 4000
              });
              return of(
                PhoneVerificationCodeRequestFailAction({ payload: str })
              );
            })
          );
        })
      ),
    { dispatch: true }
  );

  onPhoneVerificationCodeValidationtAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PHONE_VERIFICATION_CODE_VALIDATION),
        map((action: any) => action.payload),
        exhaustMap((request: ValidatePhoneVerificationCodeDto) => {
          return this.authRepository
            .validatePhoneVerificationCode(request)
            .pipe(
              map((result) => {
                return PhoneVerificationCodeValidationSuccessAction();
              }),
              catchError((error: ErrorApiReponse<any>) => {
                let str = 'Verification code could not be verified.';
                if (error?.responseError?.error?.result) {
                  str = error?.responseError?.error?.result;
                }
                return of(
                  PhoneVerificationCodeValidationFailAction({ payload: str })
                );
              })
            );
        })
      ),
    { dispatch: true }
  );

  onPhoneVerificationCodeRequestSuccessAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PHONE_VERIFICATION_CODE_REQUEST_SUCCESS),
        tap(() => {
          this.snackBarService.openAlert({
            message: Localizations.alert_dialog.check_your_phone,
            type: 'info',
            duration: 4000
          });
        })
      ),
    { dispatch: false }
  );

  /* -------------------------------- Buy phone ------------------------------- */
  AddChannelPhoneChannelAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PHONE_CHANNEL_ADD),
        map((action: any) => action.payload),
        exhaustMap((payload: AddYobiPhoneDto) => {
          return this.channelRepository.addYobiPhone(payload).pipe(
            map(() => {
              return PhoneChannelAddSuccessAction();
            }),
            catchError((error: ErrorApiReponse<any>) => {
              if (error.statusCode == 401) {
                this.snackBarService.openAlert({
                  message: Localizations.alert_dialog.one_phone_number_allowed,
                  type: 'failure',
                  duration: 4000
                });
              }
              return of(PhoneChannelAddFailAction());
            })
          );
        })
      ),
    { dispatch: true }
  );

  updateTenantProfileOnAddChannelPhoneChannelSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PHONE_VERIFICATION_CODE_VALIDATION_SUCCESS),
        map(() => {
          return GetTenantProfileAction();
        })
      ),
    { dispatch: true }
  );

  updateInternalChannelsOnAddChannelPhoneChannelSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PHONE_CHANNEL_ADD_SUCCESS),
        map(() => {
          return GetTenantInternalChannelsAction();
        })
      ),
    { dispatch: true }
  );

  // getOnboardingQuestionsResult$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(ONBOARDING_GET_QUESTIONS_RESULT),
  //       switchMap(() => {
  //         return this.onboardingRepository.getResults().pipe(
  //           map(payload => OnboardingGetQuestionsResultSuccessAction({payload})),
  //           catchError( () => of())
  //         )
  //       })
  //     ),
  //   { dispatch: true }
  // );

  // fetchingNextQuestion$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(ONBOARDING_GET_NEXT_QUESTION),
  //       switchMap( () => this.store$.select(selectOnboardingQuestionsForce).pipe(take(1))),
  //       switchMap((force) => {
  //         return this.onboardingRepository.getNextQuestion(force).pipe(
  //           map(payload => payload ? OnboardingGetNextQuestionSuccessAction({payload}): OnboardingQuestionCompletedAction()),
  //           catchError( () =>   of(OnboardingGetNextQuestionFailAction()))
  //         )
  //       })
  //     ),
  //   { dispatch: true }
  // );

  // onOnboardingSkippSuccess$ = createEffect( 
  //    () => this.actions$.pipe(
  //     ofType(ONBOARDING_SKIP_QUESTIONS_SUCCESS),
  //     tap(() => {
  //       this.matRef.closeAll()
  //     })
  //    ), {dispatch: false});

  // onboardingQuestionsCompleted$ = createEffect( () => this.actions$.pipe(
  //   ofType(ONBOARDING_QUESTIONS_COMPLETED),
  //   delay(3000),
  //   tap(() => {
  //     this.matRef.closeAll()
  //   })
  // ), { dispatch: false })

  // skipOnboardingQuestions$ = createEffect( () => this.actions$.pipe(
  //   ofType(ONBOARDING_SKIP_QUESTIONS),
  //   switchMap( () => {
  //       return this.onboardingRepository.skipOnboardingQuestions().pipe(
  //          map(() => OnboardingSkipQuestionSuccessAction()),
  //          catchError(() => of())
  //          )
  //   })
  // ), { dispatch: true })

  // sendQuestionAnswer$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(ONBOARDING_SEND_QUESTION_ANSWER),
  //       switchMap(({payload}) => {
  //         return   this.onboardingRepository.answerQuestion(payload).pipe(
  //           switchMap((nextQuestionPayload) => {
  //             return this.onboardingRepository.getResults().pipe(
  //               tap( (payload) => {
  //                 this.store$.dispatch(OnboardingGetQuestionsResultSuccessAction( { payload}))
  //               }  ),
  //               delay(800),
  //               map(() => nextQuestionPayload ?
  //               OnboardingGetNextQuestionSuccessAction({payload: nextQuestionPayload!}): 
  //               OnboardingQuestionCompletedAction()
  //              ),
  //               catchError( () => of())
  //             )
  //           }),
  //           tap( r => {
  //             /*
  //              of( {
  //                 question: "yes boba",
  //                 question_id: 3443,
  //                 can_be_empty: false,
  //                 have_options: false,
  //                 answer_options: {},
  //                 datatype: "string",
  //             }) 
  //             this.onboardingRepository.answerQuestion(payload)
  //           */
  //             let c: any =  [{"answer":"engineer","answer_options":null,"can_be_empty":false,"datatype":null,
  //             "have_options":false,"question":"What is your current job title/position?",
  //             "question_id":1},
  //             {"answer":"AGRICULTURE","answer_options":null,"can_be_empty":false,"datatype":null,
  //             "have_options":false,"question":"What industry would you classify your business in?",
  //             "question_id":1}]
  //             // this.store$.dispatch(OnboardingGetQuestionsResultSuccessAction({payload: c}))
  //            // this.store$.dispatch(OnboardingGetQuestionsResultAction())
  //           }),
  //           catchError( () =>  {
  //            return of(OnboardingSendQuestionAnswerFailAction())
  //           } )
  //         )
  //       })
  //     ),
  //   { dispatch: true }
  // );


}
