import { lightwightSignupDto } from '@/app/Data/DTO/EmailVerificationCodeDto';
import { PasswordValidator } from '@/app/Utilities/Validators/pasword-validator';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounce, interval, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { TextInputComponent } from '../../components/new-text-input/text-input.component';
import { AuthViewModel } from '../../ViewModels/authViewModel';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';
import { OnboardingViewModel } from '../../ViewModels/onboardingViewModel';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, OnDestroy {
  passwordRestrictions = false
  infomationForm: FormGroup;
  private subs = new SubSink();
  onBusinessNameChange$ = new Subject<string>();
  onEmailChange$ = new Subject<string>();
  localization = this.localizationViewModel.localization;
  singUpState$ = this.authViewModel.singUpState$;
  @ViewChild('email') emailInput: TextInputComponent
  industryOptions = [
    { value: 'Dental', label: 'Dental' },
    { value: 'Healthcare', label: 'Healthcare' },
    { value: 'Media & Entertainment', label: 'Media & Entertainment' },
    { value: 'Recruiting & Staffing', label: 'Recruiting & Staffing' },
    { value: 'Hospitality & Hotels', label: 'Hospitality & Hotels' },
    { value: 'Tourism', label: 'Tourism' },
    { value: 'Sales', label: 'Sales' },
    { value: 'Automotive Services', label: 'Automotive Services' },
    { value: 'Real Estate', label: 'Real Estate' },
    { value: 'Other', label: 'Other' }
  ];
  constructor(
    private fb: FormBuilder,
    private authViewModel: AuthViewModel,
    private localizationViewModel: LocalizationViewModel,
    private onboardingViewModel: OnboardingViewModel
  ) { }

  createForms() {
    this.infomationForm = this.fb.group({
      channel_type: new FormControl('email', Validators.required),
      channel_value: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,20}$'),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        // PasswordValidator(),
      ]),
      invite_code: new FormControl(''),
      full_name: new FormControl('', Validators.required),
      business_name: new FormControl('', Validators.required),
      industry: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.createForms();
    this.subs.sink = this.onBusinessNameChange$
      .pipe(debounce(() => interval(300)))
      .subscribe((value: string) => {
        this.authViewModel.checkIfBusinessNameExist(value);
      });

    this.subs.sink = this.onEmailChange$
      .pipe(debounce(() => interval(300)))
      .subscribe((value: string) => {
        this.authViewModel.checkIfEmailExist(value);
      });

    this.subs.sink = this.singUpState$.subscribe((value) => {
      if (!value?.emailValid) {
        this.infomationForm.controls['channel_value']?.updateValueAndValidity({
          onlySelf: true,
        });
        this.infomationForm
          ?.get('channel_value')
          ?.setErrors({ exists: 'exists' });
      }
      // if (!value?.businessNameValid) {
      //   this.infomationForm.controls['business_name']?.updateValueAndValidity({
      //     onlySelf: true,
      //   });
      //   this.infomationForm.controls['business_name']?.setErrors({
      //     exists: 'exists',
      //   });
      // }
    });

    // this.subs.sink = this.activeRoute.queryParams.subscribe((data: any) => {
    //   const { business_name, full_name, channel_value } = data;
    //   if (business_name && full_name && channel_value) {
    //     this.infomationForm.controls['business_name']?.setValue(business_name);
    //     this.infomationForm.get('full_name')?.setValue(full_name);
    //     this.infomationForm.get('channel_value')?.setValue(channel_value);
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  businessNameChanged(event: any): void {
    // if (this.infomationForm.get('business_name')?.valid) {
    //   this.onBusinessNameChange$.next(event);
    // }
  }

  emailChanged(event: string): void {
    if (this.infomationForm.get('channel_value')?.valid) {
      this.onEmailChange$.next(event?.trim()?.toLocaleLowerCase());
    }
  }

  onFormSubmit(): void {
    this.infomationForm.markAllAsTouched();
    console.log(this.infomationForm.value);
    if (!this.infomationForm.valid) return
    this.authViewModel.singUpState$.pipe(take(1)).subscribe(state => {
      if (!state.loading) {
        if (this.infomationForm.valid) {
          const values = this.infomationForm.value
          this.authViewModel.signupWithCredentials(values as lightwightSignupDto);
        }
      }
    })
    this.onboardingViewModel.setOnboardingUsername(this.infomationForm.get('full_name')?.value);

  }

  openPrivacyPolicy(): void {
    this.authViewModel.openPrivacyPolicy();
  }

  openTermsAndConditions(): void {
    this.authViewModel.openTermsAndConditions();
  }

  googleLogin() {
    this.authViewModel.loginWithGoogle(true);
  }

  appleLogin() {
    this.authViewModel.loginWithApple(true);
  }

  facebookLogin() {
    this.authViewModel.loginWithFacebook(true);
  }
}
