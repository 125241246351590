import { ResetPasswordViewModel } from './../../ViewModels/resetPasswordViewModel';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { take } from 'rxjs/operators';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';
import { emailRegExp } from '@/app/Utilities/regExp';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  completed$ = this.resetPasswordViewModel.completed$;
  localization = this.localizationViewModel.localization;
  loading$ = this.resetPasswordViewModel.loading$;
  emailController = new FormControl('', [
    Validators.required,
    Validators.pattern(emailRegExp),
  ]);

  constructor(
    private resetPasswordViewModel: ResetPasswordViewModel,
    private localizationViewModel: LocalizationViewModel,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(take(1)).subscribe((res) => {
      if (res['email']) {
        this.emailController.setValue(res['email']);
      }
    });
  }

  sendLink(): void {
    !this.loading$.getValue() &&
    this.emailController.valid &&
    this.resetPasswordViewModel.requestReset(this.emailController.value);
  }
}
