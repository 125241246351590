import { IContactsRepository } from '@/app/core/IRepositories/IContactsRepository';
import { ContactChannel } from '@/app/core/Models/Channel';
import { SelectedPipeline } from '@/app/core/Models/company';
import { Contact, ContactsLookupDto, ContactsQueryParams, LookupContact } from '@/app/core/Models/contact';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { liveQuery } from 'dexie';
import { from, map, Observable, of, pluck } from 'rxjs';
import { DatabaseService } from '../services/Database/database.service';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';
@Injectable({
  providedIn: 'root',
})
export class ContactsRepository implements IContactsRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) { }


  mergeContact(id: String, mergedId: string): Observable<Contact> {
    const requestURL = `${environment.apiURL}contacts/${id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.patch,
      this.httpService.createHeader(),
      requestURL,
      { contact_id: mergedId },
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Contact>;
        return res.results;
      })
    );
  }

  getContacts(params: ContactsQueryParams): Observable<Contact[]> {
    const requestURL = `${environment.apiURL}contacts_v2`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      params
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Contact[]>;
        return res.results;
      })
    );
  }

  lookupContacts(request: ContactsLookupDto): Observable<LookupContact[]> {
    const requestURL = `${environment.apiURL}contacts/lookup`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<LookupContact[]>;
        return res.results;
      })
    );
  }

  saveContacts(contacts: Contact[], params: ContactsQueryParams): void {
    const mapped = contacts?.map((contact: Contact) => {
      contact = Object.assign(new Contact(), contact, {
        given_name: contact.given_name?.trim(),
        family_name: contact.family_name?.trim(),
        full_name: `${contact.given_name?.trim() ?? ''} ${contact.family_name?.trim() ?? ''}`.trim().toLowerCase()
      });
      return contact;
    });
    this.databaseService
      .transaction('rw!', this.databaseService.contacts, async () => {
        Boolean(params.search) && await this.databaseService.contacts.clear()
        // params.page == 1 &&
        // await this.databaseService.contacts.clear(); // need to commented out causing bug on paginated contact
        await this.databaseService.contacts.bulkPut(mapped);
      })
      .catch((error) => {
        console.error('Transaction Failed: ', error);
      });
  }

  getLocalContacts(): Observable<Contact[]> {
    return from(
      liveQuery(() => {
        this.databaseService.contacts.mapToClass(Contact);
        return this.databaseService.contacts.orderBy('full_name').toArray();
      })
    );
  }

  getLocalContactWithChannels(
    contactId: number
  ): Observable<Contact | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          [
            this.databaseService.contacts,
            this.databaseService.contactsChannels,
          ],
          async () => {
            this.databaseService.contacts.mapToClass(Contact);
            const contact = await this.databaseService.contacts
              .where({ contact_id: contactId })
              .first();
            if (contact) {
              const channels = await this.databaseService.contactsChannels
                .where({ contact_id: contactId })
                .toArray();
              contact.channels = channels;
            }
            return contact;
          }
        );
      })
    );
  }

  getContactPipelines(contact_id: string, pipeline_id: number): Observable<any> {
    const requestURL = `${environment.apiURL}contact/${contact_id}/pipeline/${pipeline_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  selectContactStage(data: Array<{ contact_id: number, yobi_crm_pipeline_id: number, current_stage_id: number, description: string }>): Observable<any> {
    const requestURL = `${environment.apiURL}pipeline-contact/associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      { "pipeline_contact_associations": data },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  getAllPipelinesContacts(contact_id: string): Observable<SelectedPipeline[]> {
    const requestURL = `${environment.apiURL}contact/${contact_id}/pipelines`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SelectedPipeline[]>;
        return res.results;
      })
    );
  }

}
