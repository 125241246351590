import {
  Component,
  EventEmitter, Input, Output
} from '@angular/core';
@Component({
  selector: 'app-dialer-pad',
  templateUrl: './dialer-pad.component.html',
  styleUrls: ['./dialer-pad.component.scss'],
})
export class DialerPadComponent {
  @Output() onDigit = new EventEmitter<string>();
  @Input() ivrDigit: string;

  padContent: any = [
    [
      { digit: 1, text: null },
      { digit: 2, text: 'ABC' },
      { digit: 3, text: 'DEF' },
    ],
    [
      { digit: 4, text: 'GHI' },
      { digit: 5, text: 'JKL' },
      { digit: 6, text: 'MNO' },
    ],
    [
      { digit: 7, text: 'PQRS' },
      { digit: 8, text: 'TUV' },
      { digit: 9, text: 'WXYZ' },
    ],
    [
      { digit: '*', text: null },
      { digit: 0, text: '+' },
      { digit: '#', text: null },
    ],
  ];

  dialNumber(item: any): void {
    this.onDigit.emit(item.digit);
  }
}
