import { BusinessNameFieldDto } from '@/app/Data/DTO/authDto';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOnboardingRepository } from '../../IRepositories/IOnboardingRepository';
import { UseCase } from '../UseCase';

@Injectable({
  providedIn: 'root',
})
export class checkBusinessNameExistingUseCase
  implements UseCase<BusinessNameFieldDto, SuccessResponse>
{
  constructor(private onboardingRepository: IOnboardingRepository) {}
  execute(request: BusinessNameFieldDto): Observable<SuccessResponse> {
    return this.onboardingRepository.checkBusinessNameExisting(request);
  }
}
