<div
  *ngIf="ivrDigit"
  class="text-center text-base my-2"
>
  {{ ivrDigit }}
</div>

<div
  *ngFor="let row of padContent"
  style="
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    grid-column-gap: 1.2rem;
  "
>
  <ng-container *ngFor="let btn of row">
    <button
      tabindex="-1"
      mat-ripple
      class="flex flex-col justify-center items-center hover:light-bg-color rounded-full h-16 w-16 mx-auto"
      (click)="dialNumber(btn)"
    >
      <span class="text-lg text-strong">{{ btn.digit }}</span>
      <span class="text-sm text-mild">{{ btn.text }}</span>
    </button>
  </ng-container>
</div>