import { ConferenceParicipantTypes } from "@/app/State/VoiceClient/voice-client.reducer";

export interface ConferenceParticipant {
  id: string;
  // mute?: boolean;
  // on_hold?: boolean;
  status?: ConferenceUpdateStatus;
  displayName?: string;
  avatar?: string;
  activeCaller?: boolean;
  type: ConferenceParicipantTypes;
  user_id?: string;
  number?: string;

}
/* ---------------------------- host participant ---------------------------- */
export interface ConferenceHostParticipant extends ConferenceParticipant {
  type: ConferenceParicipantTypes.host;
}
/* --------------------------- member participant --------------------------- */
export interface ConferenceMemberParticipant extends ConferenceParticipant {
  user_id: string;
  type: ConferenceParicipantTypes.member;
}
/* -------------------------- customer participant -------------------------- */
export interface ConferenceCustomerParticipant extends ConferenceParticipant {
  number: string;
  type: ConferenceParicipantTypes.customer;
}

export enum ConferenceUpdateStatus {
  busy = 'busy',
  hold = 'hold',
  leave = 'leave',
  unhold = 'unhold',
  ringing = 'ringing',
  canceled = 'canceled',
  noAnswer = 'no-answer',
  completed = 'completed',
  initiated = 'initiated',
  inProgress = 'in-progress',
}

export enum ConferenceUpdateType {
  join = 'join',
  hold = 'hold',
  leave = 'leave',
  unhold = 'unhold',
  callStatus = 'call_status',
}
