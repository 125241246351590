import { LocalizationViewModel } from './../../ViewModels/localizationViewModel';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
 
@Component({
  selector: 'app-update-notification',
  templateUrl: './app-update-notification.component.html',
  styleUrls: ['./app-update-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppUpdateNotificationComponent implements OnInit {
 
  @Output() onUpdate = new EventEmitter<Plyr>();
 
  ngOnInit(): void {}
 
  update(): void {
      this.onUpdate.emit()
  }
  localization = this.localizationViewModel.localization
  constructor(private localizationViewModel: LocalizationViewModel) {}
}
