import { ILocalStorageService } from '@/app/Data/Adapters/ILocalStorageService';
import { VoiceClientViewModel } from '@/app/Ui/ViewModels/VoiceClientViewModel';
import { LocalizationViewModel } from '@/app/Ui/ViewModels/localizationViewModel';
import { INPUT_DEVICE, OUTPUT_DEVICE } from '@/app/Utilities/TokenKeys';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-dialer-audio-settings',
  templateUrl: './dialer-audio-settings.component.html',
  styleUrls: ['./dialer-audio-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialerAudioSettingsComponent implements OnInit, OnDestroy {
  currentInput = new FormControl();
  currentOutput = new FormControl();
  availableInputDevices: {
    deviceId: string;
    label: string;
    selected?: boolean
  }[] = [];
  availableOutputDevices: {
    deviceId: string;
    label: string;
  }[] = [];

  sub = new SubSink()

  localization = this.localizationViewModel.localization
  constructor(
    private voiceClientViewModel: VoiceClientViewModel,
    private localizationViewModel: LocalizationViewModel,
    private localStorageService: ILocalStorageService
  ) { }

  ngOnInit(): void {
    console.log(this.voiceClientViewModel.activeInputDevices);
    this.sub.sink = this.currentInput.valueChanges.subscribe((val) => {
      this.voiceClientViewModel.setAudioInputDevice(val);
    });

    this.sub.sink = this.currentOutput.valueChanges.subscribe((val) => {
      this.voiceClientViewModel.setAudioOutputDevice(val);
    });

    this.init()
  }

  init() {
    const input_device = this.localStorageService.getItem(INPUT_DEVICE)
    /* ---------------------------------- input --------------------------------- */
    const inputDeviceId = [input_device, this.voiceClientViewModel.activeInputDevices?.deviceId, 'default'].find(id => this.voiceClientViewModel.availableInputDevices.has(id as string))
    inputDeviceId && this.currentInput.patchValue(inputDeviceId)
    const inputs = Array.from(this.voiceClientViewModel.availableInputDevices.values()).filter(x => !['default', 'communications'].includes(x.deviceId))
    this.availableInputDevices = inputs.map(({ deviceId, label }) => ({ deviceId, label }))

    const output_device = this.localStorageService.getItem(OUTPUT_DEVICE)
    /* ---------------------------------- output --------------------------------- */
    const outputDeviceId = [output_device, this.voiceClientViewModel.activeOutputDevices?.deviceId, 'default'].find(id => this.voiceClientViewModel.availableOutputDevices.has(id as string))
    outputDeviceId && this.currentOutput.patchValue(outputDeviceId)
    const outputs = Array.from(this.voiceClientViewModel.availableOutputDevices.values()).filter(x => !['default', 'communications'].includes(x.deviceId))
    this.availableOutputDevices = outputs.map(({ deviceId, label }) => ({ deviceId, label }))
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe()
  }

  testSpeaker(): void {
    this.voiceClientViewModel.testOutputSpeakerDevice();
  }
}
