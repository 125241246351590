import { processFiles } from '@/app/Utilities/helpers';
import { Localizations } from '@/app/Utilities/localization';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';
import { FileModel } from '@/app/core/Models/file';
import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appFilePicker]',
  host: {
    '(click)': 'handleClick()',
    '(keyup.enter)': 'handleClick()',
  },
})
export class FilePickerDirective implements OnInit, OnDestroy {

  private inputElement: HTMLInputElement

  /** File size limit in bytes (default: 25MB). */
  @Input() limitSize = 26214400;

  @Input() accept: string;

  @Input() multiple = false;

  /**
   * Indicates whether the component should process files. 
   * Default is `false`.
   */
  @Input() processFiles = false;

  /**
   * Emits an array of selected files when the `processFiles` flag is set to `false`.
   */
  @Output() filesSelected = new EventEmitter<File[]>();

  /**
   * Emits an array of file models when the `processFiles` flag is set to `true`.
   */
  @Output() filesModelChange = new EventEmitter<FileModel[]>();

  constructor(
    private snackBarService: SnackbarService,
    private domSanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.initInputElement()
  }

  ngOnDestroy(): void {
    this.inputElement?.remove()
  }

  handleClick() {
    this.inputElement.click()
  }

  initInputElement() {
    this.inputElement = document.createElement('input')
    this.inputElement.type = 'file'
    this.inputElement.multiple = this.multiple;
    this.inputElement.style.display = 'none'
    if (this.accept) {
      this.inputElement.accept = this.accept
    }
    document.body.appendChild(this.inputElement)
    this.inputElement.addEventListener('change', this.onFiles.bind(this))
  }

  onFiles(event: Event) {
    const files = Array.from((event.target as HTMLInputElement).files || [])
    const filtreadFiles = files.filter(file => !this.limitSize || file.size < this.limitSize)
    if (filtreadFiles.length < files.length) {
      this.snackBarService.openAlert({
        message: Localizations.alert_dialog.file_max_size,
        translateParams: { size: `${this.limitSize / (1024 * 1024)}Mbts` },
        type: 'info',
      });
    }
    filtreadFiles?.length && (
      !this.processFiles
        ? this.filesSelected.emit(filtreadFiles)
        : processFiles(files, this.domSanitizer)
          .then(attachments => this.filesModelChange.emit(attachments))
    );
    (event.target as any).value = '';
  }

}

/* ---------------------------- direective module --------------------------- */
import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  declarations: [FilePickerDirective],
  exports: [FilePickerDirective],
  imports: [],
  providers: [],
})
export class FilePickerDirectiveModule {
  constructor() { }
}
