import { NotesViewModel } from '@/app/Ui/ViewModels/NotesViewModel';
import { UsersViewModel } from '@/app/Ui/ViewModels/usersViewModel';
import { Localizations } from '@/app/Utilities/localization';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';
import { Note } from '@/app/core/Models/Note';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, first, firstValueFrom } from 'rxjs';
import { SubSink } from 'subsink';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';
import { FormattedTextareaComponent } from '../../components/formatted-textarea/formatted-textarea.component';
import { FileModel } from '@/app/core/Models/file';
import { fileTypeFromName } from '@/app/Utilities/helpers';

declare type dataModel = {
	note?: Note;
	contactId?: number;
	unclassifiedChannelId?: number;
}
@Component({
	selector: 'app-note-form-dialog',
	templateUrl: './note-form-dialog.component.html',
	styleUrls: ['./note-form-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteFormDialogComponent implements OnInit, OnDestroy {
	@ViewChild(FormattedTextareaComponent) formattedTextareaComponent: FormattedTextareaComponent;

	savingNote = false;
	content = '';
	mentions$ = this.usersViewModel.mentions$;
	sub = new SubSink();
	isUpdate = false;
	attachments: FileModel[] = []
	localization = this.localiztionViewModel.localization
	uploadProgress$ = new Subject<number>();

	constructor(
		private localiztionViewModel: LocalizationViewModel,
		private snackBarService: SnackbarService,
		private usersViewModel: UsersViewModel,
		private notesViewModel: NotesViewModel,
		private cdr: ChangeDetectorRef,
		public dialogRef: MatDialogRef<NoteFormDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: dataModel,
	) { }

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

	ngOnInit(): void {
		this.resetContent()
		this.isUpdate = !!this.data?.note;
		// setTimeout(() => {
		// 	this.formattedTextareaComponent.input.nativeElement.focus()
		// }, 250);
	}

	async resetContent() {
		if (this.data?.note) {
			let members = await firstValueFrom(this.mentions$.pipe(first(t => !!t)))
			this.content = this.data.note.note_body.map(item => {
				switch (item.type) {
					case 'text':
						return item.text
					case 'mention':
						const member = members.find(m => m.id === item.id)
						return member ? `<@mention_${item.id}>` : item.user.replace(/\s+/ig, '_')
					default:
						return ''
				}
			}).join('');
			members.forEach(member => {
				this.content = this.content.replace(new RegExp(`<@mention_${member.id}>`, 'ig'), `@${member.name}`)
			});
			this.attachments = this.data.note.attachments?.map(({ url, type }) => ({ url, type: fileTypeFromName(type), file: null as any })) || []
		} else {
			this.content = ''
			this.attachments = []
		}
		this.cdr.detectChanges()
	}

	async save(event?: KeyboardEvent) {
		let content = this.content.trim();
		if (content) {
			let members = await (firstValueFrom(this.mentions$))
			this.savingNote = true;
			members.forEach(member => {
				content = content.replace(new RegExp(`@${member.name}`, 'ig'), `<@mention_${member.id}>`);
			});
			this.data?.contactId
				? this.handleNoteForContact(content)
				: this.handleNoteForChannel(content);
		}
	}

	handleNoteForContact(content: string): void {
		if (this.data!.note?.note_id) {
			this.notesViewModel
				.updateNote(content, this.data!.contactId!, this.data!.note.note_id, this.attachments, this.uploadProgress$)
				.subscribe({
					next: (res) => this.closeDialog(res),
					error: () => this.handleError(Localizations.alert_dialog.note_not_updated)
				});
			return;
		}
		this.notesViewModel
			.addNote(content, this.data!.contactId!, this.attachments, this.uploadProgress$)
			.subscribe({
				next: (res) => this.closeDialog(res),
				error: () => this.handleError(Localizations.alert_dialog.note_not_added)
			});
	}

	handleNoteForChannel(content: string): void {
		if (this.data?.note && this.data.unclassifiedChannelId) {
			this.data.note.note_id && this.notesViewModel.updateNoteUnclassifiedChannels(content, this.data.unclassifiedChannelId, this.data.note.note_id, this.attachments, this.uploadProgress$)
				.subscribe({
					next: (res) => this.closeDialog(res),
					error: () => this.handleError(Localizations.alert_dialog.note_not_updated)
				});
			return;
		}
		if (!this.data?.note && this.data?.unclassifiedChannelId) {
			this.notesViewModel
				.addNoteUnclassifiedChannels(content, this.data.unclassifiedChannelId, this.attachments, this.uploadProgress$)
				.subscribe({
					next: (res) => this.closeDialog(res),
					error: () => this.handleError(Localizations.alert_dialog.note_not_added)
				});
		}
	}

	addEmoji(event: any): void {
		this.content = this.content.concat(event?.emoji?.native + '')
	}

	handleError(message: string): void {
		this.snackBarService.openAlert({
			message,
			type: 'failure',
		});
		this.reset();
	}

	closeDialog(res: Note): void {
		this.reset()
		this.dialogRef?.close({ result: res });
	}

	reset(): void {
		this.savingNote = false;
		this.content = '';
		this.attachments = [];
		this.cdr.detectChanges()
	}

	onFiles(files: FileModel[]) {
		files.forEach(attachment => this.addAttachment(attachment));
	}

	addAttachment(attachment: FileModel) {
		if (this.attachments.length >= 3) {
			this.snackBarService.openAlert({
				message: Localizations.alert_dialog.max_attachments,
				translateParams: { max: '3' },
				type: 'failure',
			});
			return
		}
		this.attachments.push(attachment);
		this.cdr.detectChanges();
	}

	removeAttachment(index: number) {
		this.attachments.splice(index, 1);
		this.cdr.detectChanges();
	}
}
