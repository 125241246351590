import { ElectronService } from './../../Data/services/Electron/electron.service';
import { selectTenantProfile } from '../../State/Tenant/tenant.selector';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/app/State/AppState';
import { take } from 'rxjs';

import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root',
})
export class SentryViewModel extends ElectronService {
  profile$ = this.store.select(selectTenantProfile);
  constructor(
    private store: Store<AppState>
  ) {
    super();
  }

  clearUser(): void {
    Sentry.configureScope((scope) => {
      scope.setUser(null);
    });
    if (this.isElectronApp) {
      this.electronRender?.send('sentry_user', null);
    }
  }
  updateUser(): void {
    this.profile$.pipe(take(1)).subscribe((profile) => {
      const user = {
        email: profile?.email,
        username: profile?.username,
        userId: profile?.userId,
        tenantId: profile?.tenantId,
        name: `${profile?.firstName} ${profile?.lastName}`,
      };
      Sentry.configureScope((scope) => {
        scope.setUser(user);
      });
      if (this.isElectronApp) {
        this.electronRender?.send('sentry_user', user);
      }
    });
  }
}
