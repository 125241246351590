import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PasswordInputComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PasswordInputComponent,
    },
  ],
})
export class PasswordInputComponent implements ControlValueAccessor, OnInit {
  @Input() autocomplete = false;
  @Input() validationError: string = '';
  @Input() label = '';
  @Input() placeholder = 'Type your password here';
  @Input() invalid?= false;
  @Output() blur = new EventEmitter()
  isValid = true;
  inputType: 'password' | 'text' = 'password';
  hidden = true;

  value: string;

  onChange: Function = (value: any) => { };

  onTouched = () => { };

  touched = false;

  disabled = false;

  constructor() { }

  ngOnInit(): void { }

  toggleVisibility(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.hidden = !this.hidden;
    this.inputType = this.hidden ? 'password' : 'text';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  onInputChange(event: any): void {
    this.value = event.target?.value;
    this.onChange(this.value);
  }

  onFocus(): void {
    this.markAsTouched();
  }

  onBlur(): void {
    this.blur.emit();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: AbstractControl) {
    setTimeout(() => {
      this.isValid = control.valid || control.pristine;
    });
  }

} 
