<app-text-input
  placeholder="Team member search.."
  (input)="onSearch($event)"
></app-text-input>
<div style="max-height: 270px; overflow-y: auto">
  <div
    *ngFor="let member of teamMembers$ | async"
    (click)="select(member)"
    class="flex flex-row justify-start gap-4 p-3 px-4 cursor-pointer hover:light-bg-color rounded-lg items-center"
  >
    <app-avatar
      [url]="member?.avatar"
      class="h-9 w-9 shrink-0"
      [name]="member?.given_name + ' ' + member?.family_name"
      [class.online]="member.active"
    ></app-avatar>
    <div class="text-sm text-dark">
      {{ member?.given_name }} {{ member?.family_name }}
    </div>
  </div>
</div>