import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';

import { ITaskRepository } from '@/app/core/IRepositories/ITaskepository';

import { CompaniesViewModel } from '@/app/Ui/ViewModels/companiesViewModel';
import { ContactsViewModel } from '@/app/Ui/ViewModels/contactsViewModel';
import { TasksViewModel } from '@/app/Ui/ViewModels/tasksViewModel';
import { EventViewModel } from '@/app/Ui/ViewModels/eventViewModel';

import { UseCase } from '../UseCase';
import { Task, TaskDetail } from '@/app/core/Models/Task';
import { CreatTaskDto } from '@/app/Data/DTO/TaskDto';

import { Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CreateTaskUseCase implements UseCase<CreatTaskDto, TaskDetail> {
  constructor(
    private companiesViewModel: CompaniesViewModel,
    private contactsViewModel: ContactsViewModel,
    private tasksViewModel: TasksViewModel,
    private eventViewModel: EventViewModel,
    private taskRepository: ITaskRepository,
    private router: Router
  ) { }

  execute(request: CreatTaskDto): Observable<TaskDetail> {
    let s: any = this.patchTaskLocally(request);
    return this.taskRepository.createTask(request.task).pipe(
      tap(async (task) => {
        this.readAssociatedTasks(request);
        const mutatedTask = { ...task, created_dt_timestamp: new Date(task.created_dt).getTime(), ...s };
        this.saveTaskDetailDataStructure(mutatedTask as TaskDetail);
        this.saveTaskListDataStructure(mutatedTask as TaskDetail);
        this.taskRepository.isUnread().subscribe();
        if (this.router.url === '/main/events') this.readTaskEvent();
      })
    )
  }

  async patchTaskLocally(request: CreatTaskDto) {
    let stash: any = {};
    if (request.task?.assignees?.length) {
      let t = this.tasksViewModel.getTaskAssigneesByUserId(request.task.assignees);
      stash['task_assignees'] = await this.tasksViewModel.getTaskAssigneesByUserId(request.task.assignees);
    }
    return stash;
  }

  readTaskEvent() {
    this.eventViewModel.getEvents().catch((error) => {
      this.eventViewModel.showSnackbar(error.message, 'failure');
    });
  }

  saveTaskDetailDataStructure(task: TaskDetail) {
    this.taskRepository.saveTaskDetail(task);
  }

  saveTaskListDataStructure(task: TaskDetail) {
    const createdTask = Object.assign(new Task(), { ...task });
    this.tasksViewModel.saveTask(createdTask);
  }

  readAssociatedTasks(request: CreatTaskDto): void {
    if (request.task.yobi_crm_company_id) this.companiesViewModel.getCompanyTasks({ page: 1, page_size: 20, companyId: request.task.yobi_crm_company_id as string });
    if (request.task.contact_id) this.contactsViewModel.getContactTasks({ page: 1, page_size: 20, contact_id: request.task.contact_id as string });
  }

}
