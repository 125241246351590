import { ChannelType } from "@/app/core/Models/ChannelTypes";
import { IntegrationsTypes } from "@/app/core/Models/integrations/integrations";

export enum AnalyticsEventsNames {
    // LATEST EVENTS
    SIGNUP_STARTED = 'Sign Up Started',
    LOGIN_STARTED = 'Login Started',
    PASSWORD_FORGOTTEN_STARTED = 'Password Forgotten Started',
    LOGOUT = "Logout",
    APP_CLOSED = 'App Closed',
    CHANNEL_LINKING_STARTED = "Channel Linking Started",
    INTEGRATION_STARTED = "Integration Started",
    //PHONE_VALIDATION = "Personal Phone Validation Started",
    OPEN_CONVERSATION = "Message Read",
    TRANSCRIPTION_VIEWED = "Audio Transcription Viewed",
    SUMMARIZATION_VIEWED = "Audio Summarization Viewed",
    SENTIMENT_ANALYSIS_VIEWED = "Sentiment Analysis Viewed",
    MESSAGE_TRANSLATED_VIEWED = "Message Translated Viewed",
    SNIPPPET_USED = "Snippet Used",
    PAGE_VIEW = 'Page View',

    // DEPRECATED
    // LOGIN_ATTEMPT = 'Login Attempt',
    SESSION_START = 'Session Start',
    SESSION_END = 'Session End',
    APP_OPENED = 'App Opened',
    PWA_APP_INSTALLED = 'PWA app installed',
    //CALL_TO_ACTION = 'Call To Action',
}

export enum CallToActionEventsTypes {
    NEW_MESSAGE_CLICKED = 'New Message',
    MAKE_CALL_CLICKED = 'Make Call',
    MESSAGES_FILTER_DROPDOWN = 'Messages Filter Drop Down',
    MESSAGES_FILTER_INBOX = 'Messages Filter Inbox',
    MESSAGES_FILTER_UNREAD = 'Messages Filter Unread',
    MESSAGES_FILTER_BLOCKED = 'Messages Filter Blocked',
    MESSAGES_FILTER_ARCHIVED = 'Messages Filter Archived',
    TEAM_CHAT_CLICKED = 'Team Chat Clicked',
    TRANSCRIPTION = 'Transcription',
    SENTIMENT_ANALYSIS = 'Sentiment Analysis',
    TRANSLATE_MESSAGE = 'Translate Message',
    SNIPPET_USED = 'Snippet Used',
    SUMMARIZATION = 'Summarization',
}

export type AnalyticEvent = (
    | { name: AnalyticsEventsNames, properties?: {} }
  //  | { name: AnalyticsEventsNames.LOGIN_ATTEMPT, properties: { username?: string, auth_type: 'native' | 'google' | 'apple' | 'facebook' } }
    // new Analytics
    | { name: AnalyticsEventsNames.SIGNUP_STARTED,
         properties?: {registration_method: SignupMethod } & {phone: string} | {email: string} }
    | { name: AnalyticsEventsNames.LOGIN_STARTED,  properties: { login_method: 'Google' | 'Facebook' | 'Apple' | 'E-mail' } }
    | { name: AnalyticsEventsNames.APP_CLOSED, properties?: {last_viewed_screen:  string} }
    | { name: AnalyticsEventsNames.CHANNEL_LINKING_STARTED, properties: {channel_name: ChannelType, entry_point: string} }
    | { name: AnalyticsEventsNames.INTEGRATION_STARTED, properties: {integration_app_name: IntegrationsTypes, entry_point: string} }
    //| { name: AnalyticsEventsNames.PHONE_VALIDATION, properties: {$phone: string, entry_point: string} }
    | { name: AnalyticsEventsNames.OPEN_CONVERSATION, properties: {conversation_id: string, is_contact: boolean} }
    | { name: AnalyticsEventsNames.TRANSCRIPTION_VIEWED, properties: { 
        conversation_id: string,
        is_contact: boolean, 
        is_participant?: boolean,
        transcription_length?: number,
        audio_duration: number,
        audio_type: 'Call' | 'Voicemail'} }
    | { name: AnalyticsEventsNames.SUMMARIZATION_VIEWED, properties: { 
        conversation_id: string,
        is_contact: boolean,     
        is_participant?: boolean,
        summarization_length: number,
        audio_duration?: number,
        audio_type: 'Call' | 'Voicemail'} }
    |
    
    { name: AnalyticsEventsNames.SENTIMENT_ANALYSIS_VIEWED, properties: { 
        conversation_id: string,
        is_contact: boolean,     
        sentiment: string,
        caller_sentiment: string,
        receiver_sentiment: string,
        audio_type: 'Call' | 'Voicemail'} }
    |

    { name: AnalyticsEventsNames.MESSAGE_TRANSLATED_VIEWED, properties: { 
        language_translated_from: string,
        language_translated_to: string,     
        conversation_id: string,
        is_contact: boolean,     
    }}
    |
    
    { name: AnalyticsEventsNames.SNIPPPET_USED, properties: { 
        tenant_id: string,
        snippet_title: string,     
        snippet_body_length: string,
        conversation_id: string,
        is_contact: boolean,     
    }}
    |
     
     { name: AnalyticsEventsNames.PAGE_VIEW, properties: { duration?: number, previous_page: string, current_page: string } }

     /*
    |
    {
        name: AnalyticsEventsNames.CALL_TO_ACTION, properties?: {
            action_type: CallToActionEventsTypes
        }
    }
        */
) 

export enum SignupMethod {
    FACEBOOK = 'Facebook',
    GOOGLE = 'Google',
    APPLE = 'Apple',
    EMAIL = "Email"
  }