import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppUpdateNotificationComponent } from './app-update-notification.component';

@NgModule({
  declarations: [AppUpdateNotificationComponent],
  imports: [CommonModule, TranslateModule.forChild({extend: true}), AngularSvgIconModule],
  exports: [AppUpdateNotificationComponent],
})
export class AppUpdateNotificationModule {}
