export enum messages {
  sent_you_an_attachment = 'messages.sent_you_an_attachment',
  sent_you_a_file = 'messages.sent_you_a_file',
  sent_you_an_image = 'messages.sent_you_an_image',
  sent_you_a_recording = 'messages.sent_you_a_recording',
  sent_you_a_video = 'messages.sent_you_a_video',
  you_missed_call = 'messages.you_missed_call',
  new_task_has_been_created = 'messages.new_task_has_been_created',
  you_sent_an_attachment = 'messages.you_sent_an_attachment',
  you_sent_a_file = 'messages.you_sent_a_file',
  you_sent_an_image = 'messages.you_sent_an_image',
  you_sent_recording = 'messages.you_sent_recording',
  you_sent_a_video = 'messages.you_sent_a_video',
  unsupported_content = 'messages.unsupported_content',
  you_have_not_linked_any_app = 'messages.you_have_not_linked_any_app',
  contact_us_for_custom_pricing = 'messages.contact_us_for_custom_pricing',
  no_payment_method = 'messages.no_payment_method',
  no_linked_connection = 'messages.no_linked_connection',
  add_users_hint = 'messages.add_users_hint',
  creating_the_task = 'messages.creating_the_task',
  new_update_available = 'messages.new_update_available',
  tell_us_what_should_to_improve = 'messages.tell_us_what_should_to_improve',
  have_read_and_accept = 'messages.have_read_and_accept',
  or_choose_from_existing_tags = 'messages.or_choose_from_existing_tags',
  please_wait = 'messages.please_wait',
  your_message_will_be_sent_on = 'messages.your_message_will_be_sent_on',
  messages_will_be_set = 'messages.messages_will_be_set',
  message_could_not_be = 'messages.message_could_not_be',
  message_has_been = 'messages.message_has_been',
  _status_the_message = 'messages._status_the_message',
  press_button_to_add = 'messages.press_button_to_add',
  upgrade_plan_to_take_advantage = 'messages.upgrade_plan_to_take_advantage',
  please_select_the_channels_for_which_you_would_like_to_create_a_post = 'messages.please_select_the_channels_for_which_you_would_like_to_create_a_post',
  create_and_share_post_by_adding_your_favorite_channels = 'messages.create_and_share_post_by_adding_your_favorite_channels',
  thank_you_for_submitting_your_post = 'messages.thank_you_for_submitting_your_post',
  publishing_your_post_to = 'messages.publishing_your_post_to',
  your_post_has_been_successfully_published = 'messages.your_post_has_been_successfully_published',
  sorry_we_could_not_publish_your_post_on = 'messages.sorry_we_could_not_publish_your_post_on',
  view_and_manage_business_information = 'messages.view_and_manage_business_information',
  business_information_empty = 'messages.business_information_empty',
  threads_are_only_visible_on_yobi  = "messages.threads_are_only_visible_on_yobi"
}