import { ContactChannel, InternalChannel } from '../../core/Models/Channel';
import { InteractionDirection, InteractionStatus, InteractionThread, InteractionType } from '../../core/Models/Interaction';
import { ChannelType } from '@/app/core/Models/ChannelTypes';
import { Message } from '@/app/core/Models/Message';
import { RecentInteractionsFilter } from '@/app/core/Models/RecentInteractionsFilter';
import { TeamMember } from '@/app/core/Models/TeamMember';
import { Tenant } from '@/app/core/Models/tenant';
import { BaseInlineNoteBody } from '@/app/Data/DTO/InlineNoteDto';


export class ThreadPreferences {
    threadId: string;
    primaryContactChannel?: ContactChannel;
    primaryChannel?: InternalChannel;
}

export interface RecentInteractionsState {
    filter: RecentInteractionsFilter;
    isLastPage: boolean;
    page: number;
}

export class PendingMessage {
    constructor(private data: { message: Message,  threadId: number, failed?: boolean, profile?: Tenant}) { }
    date = new Date();
    message = this.data.message;
    threadId = this.data.threadId;
    getBody(): any {
        const body = [];
        if (this.data.message.hasAttachment) {
            this.data.message.attachment?.forEach((item) => {
                body.push({
                    content_type: item.file.type.startsWith('image/') ? 'image' : 'video',
                    content: item?.url,
                });
            });
        }
        const text =
            (this.data.message.getBody() as any)?.body ||
            (this.data.message.getBody() as any)?.message;
        if (text) {
            body.push({
                content_type: 'text',
                content: text,
            });
        }
        return body;
    }

    get interaction(): InteractionThread {
        const interaction = new InteractionThread();
        interaction.created_dt = new Date().toISOString();
        interaction.interaction_dt = this.date;
        interaction.interaction_from = this.data.message.from.channel_value;
        interaction.direction = InteractionDirection.outbound;
        interaction.interaction_id = Math.floor(Math.random() * 10000);
        interaction.interaction_to = this.data.message.to.channel_value;
        interaction.interaction_type = InteractionType.message;
        interaction.user_id = '';
        interaction.updated_dt = new Date().toISOString();
        interaction.status = this.data.failed ? InteractionStatus.failed : InteractionStatus.sending;
        interaction.text_body = JSON.stringify(this.getBody());
        interaction.channel_id = 0;
        interaction.channel_type = ChannelType.Phone;
        interaction.page = 1;
        interaction.avatar = this.data.profile?.avatar,
        interaction.channel_value = this.data.message.from.channel_value;
        interaction.yobi_tenant_id = 0;
        interaction.msg_id = this.data.message.msg_id
        interaction.inbox_id = this.data.message.from.channel_id
        return interaction;
    }
}

export class PendingInlineNote {
    constructor(
        public noteBody: BaseInlineNoteBody,
        public members: TeamMember[],
        public threadId: number,
        private failed?: boolean
    ) { }
    date = new Date()
    getBody(): any {
        let content = this.noteBody.content;
        let attachments = this.noteBody.attachments;
        const mentionRegex = /<@mention_(\S+)>/gi
        let match
        const matchs: RegExpExecArray[] = []
        while ((match = mentionRegex.exec(content)) !== null) {
            matchs.push(match);
        }
        matchs.forEach(match => {
            let member = this.members.find((m) => m.user_id == match[1]);
            content = content.replace(match[0], member ? `@${member?.given_name}_${member?.family_name}` : `@mention`);
        });
        return [
            {
                content_type: 'note',
                content,
            },
            attachments.length ? {
                content_type: 'attachments',
                content: attachments.map(({ type, url }) => ({ type, url })),
            } : undefined
        ].filter(x => !!x);
    }
    get interaction(): InteractionThread {
        const interaction = new InteractionThread();
        interaction.created_dt = new Date().toISOString();
        interaction.interaction_dt = this.date;
        interaction.interaction_from = '';
        interaction.direction = InteractionDirection.outbound;
        interaction.interaction_id = Math.floor(Math.random() * 10000);
        interaction.interaction_to = '';
        interaction.interaction_type = InteractionType.note;
        interaction.user_id = '';
        interaction.updated_dt = new Date().toISOString();
        interaction.status = this.failed ? InteractionStatus.failed : InteractionStatus.sending;
        interaction.text_body = JSON.stringify(this.getBody());
        interaction.channel_id = 0;
        interaction.channel_type = ChannelType.Phone;
        interaction.page = 1;
        interaction.channel_value = '';
        interaction.yobi_tenant_id = 0;
        interaction.msg_id = this.noteBody.msg_id
        interaction.inbox_id = this.noteBody.inbox_id!
        return interaction;
    }
}