import { LocalizationViewModel } from '@/app/Ui/ViewModels/localizationViewModel';
import { environment } from './../../../../environments/environment';
import { PlatformService } from './../../../Utilities/platform/platform.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Route, Router } from '@angular/router';
import { AuthService } from '@/app/Data/services/Auth/auth.service';

@Component({
  selector: 'app-subscription-restriction',
  templateUrl: './subscription-restriction.component.html',
  styleUrls: ['./subscription-restriction.component.scss'],
})
export class SubscriptionRestrictionComponent implements OnInit {
  isTrial = false;
  isDesktop = this.platformService.isElectronApp
  isMacApp = this.platformService.isMacOS
  localization = this.localizationViewModel.localization

  constructor(
    private localizationViewModel: LocalizationViewModel,
    private router: Router,
    private platformService: PlatformService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<SubscriptionRestrictionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      isTrial: boolean
    }
  ) {
    this.isTrial = data?.isTrial
  }
 
  ngOnInit(): void {}

  openBilling(): void {
      if(this.isDesktop) {
        this.platformService.openURL(environment.appURL + '/main/settings/billing/plans')
      } else {
        this.router.navigateByUrl('main/settings/billing/plans');
        this.dialogRef.close()
      }
  }

  logout(): void {
    this.authService.logout()
  }
}
