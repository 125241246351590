import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export interface PasswordValidatorErrors extends ValidationErrors {
  uppercase?: boolean;
  lowercase?: boolean;
  digit?: boolean;
  specialchar?: boolean;
  minLength?: boolean;
}
export function PasswordValidator(): ValidatorFn {
  const upperFilter = new RegExp('(?=.*[A-Z])');
  const lowerFilter = new RegExp('(?=.*[a-z])');
  const digitFilter = new RegExp('(?=.*[0-9])');
  const specialCharFilter = new RegExp('([^A-Za-z0-9])');
  const lengthFilter = new RegExp('(?=.{8,})');

  return (control: AbstractControl): PasswordValidatorErrors | null => {
    let results: any = [];
    results.push(upperFilter.test(control.value) ? null : { uppercase: true });
    results.push(lowerFilter.test(control.value) ? null : { lowercase: true });
    results.push(digitFilter.test(control.value) ? null : { digit: true });
    results.push(
      specialCharFilter.test(control.value) ? null : { specialchar: true }
    );
    results.push(lengthFilter.test(control.value) ? null : {minLength: true});

    const result = results.reduce((obj: any, item: any) => {
      if (!obj) obj = {};
      obj = { ...obj, ...item };
      return obj;
    }, null);
    return result;
  };
}
