import { EmailClassification, RecentEmailInteraction, ThreadEmailInteraction } from "@/app/core/Models/email-interaction";
import { Injectable } from "@angular/core";
import { RecentEmailsQueryDto, ThreadEmailsQueryDto } from "../DTO/emailMessagingDto";
import { Observable, from, map, pluck } from "rxjs";
import { environment } from "@/environments/environment";
import { HttpRequestMethod } from "../services/Networking/HttpRequestMethod";
import { HttpService } from "../services/Networking/HttpService";
import { SuccessApiResponse } from "../services/Networking/ApiResponse";
import { IEmailMessagingRepository } from "@/app/core/IRepositories/IEmailMessagingRepository";
import { EmailInbox } from "@/app/core/Models/inbox";
import { DatabaseService } from "../services/Database/database.service";
import { liveQuery } from "dexie";
import { InteractionStatus } from "@/app/core/Models/Interaction";


@Injectable({
    providedIn: 'root',
})
export class emailMessagingRepository implements IEmailMessagingRepository {


    constructor(
        private httpService: HttpService,
        private databaseService: DatabaseService
    ) { }

    fetchEmailClassifications(channel_id: number): Observable<EmailInbox> {
        const requestURL = `${environment.apiURL}email/internal_channel/${channel_id}/inboxes`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false,
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => {
                let res = (item as SuccessApiResponse<[EmailInbox]>);
                return res.results[0];
            })
        );
    }

    fetchRecentEmails({ channelId, classification_id, ...query }: RecentEmailsQueryDto): Observable<RecentEmailInteraction[]> {
        const requestURL = `${environment.apiURL}email/email_classification/${classification_id}/internal_channel/${channelId}/recent`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false,
            query
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => {
                let res = item as SuccessApiResponse<RecentEmailInteraction[]>;
                return res.results;
            })
        );
    }

    fetchEmailInteractions({ conversation_id, ...query }: ThreadEmailsQueryDto): Observable<ThreadEmailInteraction[]> {
        const requestURL = `${environment.apiURL}email/conversation/${conversation_id}/interactions`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false,
            query
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => {
                let res = item as SuccessApiResponse<ThreadEmailInteraction[]>;
                return res.results;
            })
        );
    }

    saveRecentEmailMessages(data: RecentEmailInteraction[], { page, page_size, channelId, classification_id }: RecentEmailsQueryDto): void {
        this.databaseService.transaction(
            'rw!',
            this.databaseService.recentEmailInteractions,
            async () => {
                // if (page == 1) {
                //   this.databaseService.recentEmailInteractions.clear();
                // } else {
                // }
                const recentInteractionsByPage =
                    await this.databaseService.recentEmailInteractions
                        .where('_page')
                        .equals(Number(page))
                        .and((item) => item._classificationId == classification_id)
                        .and((item) => item.internal_channel_id == channelId)
                        .offset(0)
                        .limit(page_size)
                        .toArray();
                if (recentInteractionsByPage.length) {
                    const recentInteractionsIds = recentInteractionsByPage.map((item) => item.conversation_id as unknown as string);
                    const newRecentInteractionsIds = data.map((item) => item.conversation_id  as unknown as string);
                    const removed = recentInteractionsIds.filter((item) => newRecentInteractionsIds.indexOf(item) == -1);
                    await this.databaseService.recentEmailInteractions.bulkDelete(removed);
                }
                data.length && this.databaseService.recentEmailInteractions.bulkPut(data);
            }
        );
    }

    saveThreadEmailMessages(data: ThreadEmailInteraction[], { page, page_size, conversation_id }: ThreadEmailsQueryDto): void {
        this.databaseService.transaction(
            'rw!',
            this.databaseService.threadEmailInteractions,
            async () => {
                // if (page == 1) {
                //   this.databaseService.threadEmailInteractions.clear();
                // } else {
                // }
                const recentInteractionsByPage =
                    await this.databaseService.threadEmailInteractions
                        .where('_page')
                        .equals(Number(page))
                        .and((item) => item.conversation_id == conversation_id)
                        .offset(0)
                        .limit(page_size)
                        .toArray();
                if (recentInteractionsByPage.length) {
                    const recentInteractionsIds = recentInteractionsByPage.map((item) => item.conversation_id as unknown as string);
                    const newRecentInteractionsIds = data.map((item) => item.conversation_id as unknown as string);
                    const removed = recentInteractionsIds.filter((item) => newRecentInteractionsIds.indexOf(item) == -1);
                    await this.databaseService.threadEmailInteractions.bulkDelete(removed);
                }
                this.databaseService.threadEmailInteractions.bulkPut(data);
            }
        );
    }

    updateLocalThreadEmailMessagesStatus(
        interactionsIds: number[],
        conversationId: number,
        status: InteractionStatus
    ): void {
        this.databaseService.transaction('rw!', [
            this.databaseService.recentEmailInteractions,
            this.databaseService.threadEmailInteractions
        ], () => {
            this.databaseService.recentEmailInteractions
                .where('conversation_id')
                .equals(conversationId)
                .modify((item: RecentEmailInteraction) => item.unread_count = 0)
                .catch((err) => console.error('error updating status ', err));
            this.databaseService.threadEmailInteractions
                .where('email_interaction_id')
                .anyOf(interactionsIds)
                .modify((item: ThreadEmailInteraction) => item.status = status)
                .catch((err) => console.log('error updating status ', err));
        });
    }

    saveThreadEmailMessage(data: ThreadEmailInteraction, page = 1): void {
        data = Object.assign(new ThreadEmailInteraction(), data)
        data.page = page,
            this.databaseService.threadEmailInteractions.put(data);
    }

    getLocalRecentMessages({ page, page_size, channelId, classification_id }: RecentEmailsQueryDto): Observable<RecentEmailInteraction[]> {
        return from(
            liveQuery(() => {
                this.databaseService.recentEmailInteractions.mapToClass(RecentEmailInteraction);
                return this.databaseService.recentEmailInteractions
                    .orderBy('conversation_dt')
                    .filter((item) => item._classificationId == classification_id)
                    .and((item) => item.internal_channel_id == channelId)
                    .offset(0)
                    .limit(page_size * (page ?? 1))
                    .reverse()
                    .toArray()
            })
        );
    }

    getLocalThreadMessages({ page, page_size, conversation_id }: ThreadEmailsQueryDto): Observable<ThreadEmailInteraction[]> {
        return from(
            liveQuery(() => {
                this.databaseService.threadEmailInteractions.mapToClass(ThreadEmailInteraction);
                return this.databaseService.threadEmailInteractions
                    .orderBy('created_dt')
                    .filter((item) => item.conversation_id == conversation_id)
                    .offset(0)
                    .limit(page_size * (page ?? 1))
                    .reverse()
                    .toArray()
            })
        );
    }

    findRecentEmailMessage(id: number): Observable<RecentEmailInteraction | undefined> {
        return from(
            liveQuery(() => {
                this.databaseService.recentEmailInteractions.mapToClass(RecentEmailInteraction);
                return this.databaseService.recentEmailInteractions
                    .where('conversation_id')
                    .equals(id)
                    .first();
            })
        );
    }

    sendOutlookReplyEmail(request: FormData): Observable<any> {
        const requestURL = `${environment.apiURL}email/outlook/reply`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            request,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => {
                let res = item as SuccessApiResponse<any>;
                return res.results
            })
        );
    }
    sendGoogleReplyEmail(request: FormData): Observable<any> {
        const requestURL = `${environment.apiURL}email/google/reply`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            request,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => {
                let res = item as SuccessApiResponse<any>;
                return res.results
            })
        );
    }
    sendEmailInlineNote({ content, conversationId }: { content: string, conversationId: number }): Observable<ThreadEmailInteraction> {
        const requestURL = `${environment.apiURL}email/conversation/${conversationId}/inline-note`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            { note: content },
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => {
                let res = item as SuccessApiResponse<ThreadEmailInteraction>;
                return res.results
            })
        );
    }

    updateReadStatus(request: { channelId: number; conversation_id: number }): Observable<ThreadEmailInteraction[]> {
        const requestURL = `${environment.apiURL}email/internal_channel/${request.channelId}/conversation/${request.conversation_id}/read`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.put,
            this.httpService.createHeader(),
            requestURL,
            request,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => {
                let res = item as SuccessApiResponse<ThreadEmailInteraction[]>;
                return res.results
            })
        );
    }
}