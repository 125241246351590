/**
 * A component that will serve as multiple file uploader.
 * The component functionality is stateful.  It requires view model or repository to integrate with denpending on the usecase.
 * Author: Royce
 * Date Created: Wednesday, Sep 14, 4:02 PM
 *
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

import { ConfirmationDialogComponent } from '../confirm-dialog/confirmation-dialog.component';

import { CompaniesViewModel } from '../../ViewModels/companiesViewModel';
import { ICompanyRepository } from '@/app/core/IRepositories/ICompanyRepository';
import { IContactRepository } from '@/app/core/IRepositories/IContactRepository';
import { ContactsViewModel } from '../../ViewModels/contactsViewModel';
import { ContactViewModel } from '../../ViewModels/contactViewModel';

import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';
import { ISynthRepository } from '@/app/core/IRepositories/ISynthREpository';

import { distinctUntilChanged, firstValueFrom, Subject, tap } from 'rxjs';
import { SubSink } from 'subsink';

import { formatBytes, validateFilesType } from '@/app/Utilities/helpers';

@Component({
    selector: 'file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploaderComponent implements OnInit, OnDestroy {

    @Input() files: any[] = [];
    @Input() useCase: 'synth' | 'contact' | 'company' = 'synth';
    @Input() max: number = 5;
    @Input() error: boolean;
    @Input() description: string;
    @Input() errorMessage: string;
    @Input() validFileExt: string[]; // required

    @Output() onGetFiles = new EventEmitter();
    @Output() onDeleteFiles = new EventEmitter();
    @Output() uploading = new EventEmitter();

    uploadProgress$ = new Subject<number>();

    sub = new SubSink();

    constructor(
        private dialog: MatDialog,
        private snackbarService: SnackbarService,
        private synthRepository: ISynthRepository,
        private cdref: ChangeDetectorRef,
        private companyRepository: ICompanyRepository,
        private companiesViewModel: CompaniesViewModel,
        private contactsViewModel: ContactsViewModel,
        private contactViewModel: ContactViewModel,
        private contactRepository: IContactRepository,
        private httpClient: HttpClient
    ) { }

    ngOnInit(): void {
        this.autoRun();
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    autoRun(): void {
        this.init();
        this.listen();
    }

    init(): void {
        if (!this.validFileExt.length) console.error('Valid extensions required');
    }

    listen(): void {
        if (this.useCase === 'company') {
            this.listenOnGetCompanyAttachments();
        }
        if (this.useCase === 'contact') {
            this.listenOnGetContactAttachments();
        }
    }

    listenOnGetContactAttachments(): void {
        this.sub.sink = this.contactsViewModel.associatedAttachmentsLocal$.pipe(
            distinctUntilChanged(),
            tap((items) => {
                if(items != undefined && Array.isArray(items)) {
                  this.files = items?.map((item) => {
                    return { name: item.file_name, type: item.file_type, fileSize: formatBytes(item.size, 0), uploading: false, progress: 100, raw_filename: item.raw_filename, url: item.url };
                  }) as Array<{ name: string, type: string, fileSize: number, uploading: boolean, progress: number, raw_filename: string, url: string }>;
                  this.detect();
                }
            })).subscribe();
    }

    listenOnGetCompanyAttachments(): void {
        this.sub.sink = this.companiesViewModel.associatedAttachmentsLocal$.pipe(
            distinctUntilChanged(),
            tap((items) => {
                this.files = items?.map((item) => {
                    return { name: item.file_name, type: item.file_type, fileSize: formatBytes(item.size, 0), uploading: false, progress: 100, raw_filename: item.raw_filename, url: item.url };
                }) as Array<{ name: string, type: string, fileSize: number, uploading: boolean, progress: number, raw_filename: string, url: string }>;
                this.detect();
                console.log('clg company files', this.files);
            })).subscribe();
    }

    onFileDropped($event: any) {
        if (this.files.length < this.max) {
            this.prepareFilesList($event);
        } else {
            this.runSnackBar({ message: "You've reached the limit of 5 files.", type: "failure" });
        }
    }

    resetUploadedDocuments(event: MouseEvent) {
        (event.target as HTMLInputElement).value = null as any;
    }

    fileBrowseHandler(files: any) {
        if (this.files.length < this.max) {
            const stashFiles = validateFilesType(files, this.validFileExt);
            this.prepareFilesList(stashFiles);
        } else {
            this.runSnackBar({ message: "You've reached the limit of 5 files.", type: "failure" });
        }
    }

    deleteFile(index: number) {
        switch (this.useCase) {
            case 'synth':
                this.files.splice(index, 1);
                this.detect();
                this.onDeleteFiles.emit({ data: this.files, index: index });
                break;

            case 'company':
                const companyFile = this.files[index];
                this.dialog.open(ConfirmationDialogComponent, {
                    data: {
                        title: 'Delete File',
                        message: `Are you sure you want to delete "${companyFile.name}"?`,
                        confirm: 'Delete',
                        warning: true
                    }
                }).afterClosed().subscribe((res) => {
                    if (res) {
                        this.deleteCompanyFile(index);
                        this.files.splice(index, 1);
                        this.detect();
                        this.onDeleteFiles.emit({ data: this.files, index: index });
                    }
                });
                break;
            case 'contact':
                const contactFile = this.files[index];
                this.dialog.open(ConfirmationDialogComponent, {
                    data: {
                        title: 'Delete File',
                        message: `Are you sure you want to delete "${contactFile.name}"?`,
                        confirm: 'Delete',
                        warning: true
                    }
                }).afterClosed().subscribe((res) => {
                    if (res) {
                        this.deleteContactFile(index);
                        this.files.splice(index, 1);
                        this.detect();
                        this.onDeleteFiles.emit({ data: this.files, index: index });
                    }
                });
                break;
        }
    }

    onClick(){

    }

    openFileInNewTab(url:string) {
      console.log(url)
      window.open(url, '_blank');
  }

    async deleteContactFile(index: number) {
        const file = this.files[index];
        if ("raw_filename" in file) {
            const contactId = await firstValueFrom(this.contactViewModel.activeContactId$);
            const payload = [file['raw_filename']];
            const response = await firstValueFrom(this.contactRepository.deleteContactAttachments(payload as string[], contactId as any));
            this.contactsViewModel.getContactAttachments(contactId as any);
        }
    }

    async deleteCompanyFile(index: number) {
        const file = this.files[index];
        if ("raw_filename" in file) {
            const company = await firstValueFrom(this.companiesViewModel.activeCompanyState$);
            const payload = [file['raw_filename']];
            const response = await firstValueFrom(this.companyRepository.deleteCompanyAttachments(payload as string[], company?.companyId as string));
            this.companiesViewModel.getCompanyAttachments(company?.companyId as string);
        }
    }

    uploadFilesSimulator(index: number) {
        setTimeout(() => {
            if (index === this.files.length) {
                this.uploading.emit(false);
                return;
            } else {
                const progressInterval = setInterval(() => {
                    if (this.files[index].progress === 100) {
                        this.files[index].uploading = false;
                        this.detect();
                        clearInterval(progressInterval);
                        this.uploadFilesSimulator(index + 1);
                    } else {
                        this.files[index].progress += 5;
                        this.cdref.detectChanges();
                    }
                }, 200);
            }
        }, 250);
    }

    prepareFilesList(files: Array<any>) {
        switch (this.useCase) {
            case 'synth':
                this.synthUploadFiles(files);
                break;
            case 'company':
                this.companyUploadFiles(files);
                break;
            case 'contact':
                this.contactUploadFiles(files);
                break;
        }
    }

    async companyUploadFiles(files: Array<any>) {
        const company = await firstValueFrom(this.companiesViewModel.activeCompanyState$);
        const form = new FormData();
        for (const file of files) {
            form.append('files', file);
            file.progress = 0;
            file.uploading = true;
            file.fileSize = formatBytes(file.size, 0);
            this.files.push(file);
        }
        this.uploadFilesSimulator(0);
        const response_files = await firstValueFrom(this.companyRepository.uploadCompanyAttachments(form, company?.companyId as string, this.uploadProgress$));
        if (response_files) this.companiesViewModel.getCompanyAttachments(company?.companyId as string);
        this.detect();
    }

    async contactUploadFiles(files: Array<any>) {
        const contactId = await firstValueFrom(this.contactViewModel.activeContactId$);
        const form = new FormData();
        for (const file of files) {
            form.append('files', file);
            file.progress = 0;
            file.uploading = true;
            file.fileSize = formatBytes(file.size, 0);
            this.files.push(file);
        }
        this.uploadFilesSimulator(0);
        const response_files = await firstValueFrom(this.contactRepository.uploadContactAttachments(form, contactId as any, this.uploadProgress$));
        this.contactsViewModel.getContactAttachments(contactId as any);
        this.detect();
    }

    synthUploadFiles(files: Array<any>) {
        this.uploading.emit(true);
        for (const file of files) {
            file.progress = 0;
            file.uploading = true;
            file.fileSize = formatBytes(file.size, 0);
            this.files.push(file);
        }
        this.onGetFiles.emit(this.files);
        this.uploadFilesSimulator(0);
    }


    runSnackBar(options: any) {
        this.snackbarService.openAlert(options);
    }

    convertBase64ToBlob(base64Image: string) {
        const parts = base64Image.split(';base64,');
        const imageType = parts[0].split(':')[1];
        const decodedData = window.atob(parts[1]);
        const uInt8Array = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: imageType });
    }

    detect(): void {
        this.cdref.detectChanges();
    }

    downloadFile(url: string, raw_filename: string) {
        this.sub.sink = this.httpClient.get(url, { responseType: 'blob' }).pipe(
            tap((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = raw_filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            })).subscribe();
    }

    decodeFileName(fileName: string): string {
        return decodeURIComponent(fileName.replace(/\.txt$/, '')).trim();
    }
}
