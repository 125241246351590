import { InteractionDirection, RecentInteraction } from './../../core/Models/Interaction';
import { InteractionThread, Interaction } from '@/app/core/Models/Interaction';
import { environment } from '@/environments/environment';
import { Inject, Injectable } from '@angular/core';
import { from, map, Observable, of, pluck, tap } from 'rxjs';
import { ThreadMessagesQueryDto } from '../DTO/ThreadMessagesQueryDto';
import { DatabaseService } from '../services/Database/database.service';

import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';
import { IThreadRepository } from '@/app/core/IRepositories/IMessagesThreadRepository';
import { liveQuery } from 'dexie';
import { ThreadPreferences } from '@/app/State/Inbox/inbox.model';
import { SuccessResponse } from '../DTO/successResponse';
@Injectable({
  providedIn: 'root',
})
export class ThreadRepository implements IThreadRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) { }

  getUnclassifiedMessages(
    { params: { inbox, page }, channel }: ThreadMessagesQueryDto
  ): Observable<InteractionThread[]> {
    const requestURL = `${environment.apiURL}comm_channels/${channel}/interactions`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      { page, inboxes: inbox?.is_channel ? inbox.inbox_id : '', custom: inbox?.is_custom ? inbox.inbox_id : '' }
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<InteractionThread[]>;
        return res.results;
      })
    );
  }

  saveThreadMessages({
    interactions, page, unclassified, threadId
  }: {
    interactions: InteractionThread[], page: number, unclassified: boolean, threadId: number
  }): void {
    this.databaseService.transaction(
      'rw!',
      this.databaseService.threadInteractions,
      async () => {
        let localInteractions;
        localInteractions = await this.databaseService.threadInteractions
          .where(unclassified ? 'channel_id' : 'yobi_contact_id')
          .equals(threadId)
          // TODO: check if this is needed
          //.and((item) => !inboxes.length || !item.inbox_id || inboxes.includes(item.inbox_id))
          //.and((item) => !nextPagesExist && item.page == page)
          .toArray();
        if (localInteractions.length) {
          const localIds = localInteractions.map((item) => item.interaction_id as unknown as string);
          const newIds = interactions.map((item) => item.interaction_id as unknown as string);
          const removed = localIds.filter((item) => newIds.indexOf(item) == -1);
          await this.databaseService.threadInteractions.bulkDelete(removed);
        }
        this.databaseService.threadInteractions
          .bulkPut(interactions)
          .catch(console.error);
      }
    );
  }

  getLocalThreadMessages(
    { unclassified, threadId, page, inbox }: {
      unclassified: boolean;
      threadId: number;
      page: number;
      inbox?: number;
    }): Observable<InteractionThread[]> {
    return from(
      liveQuery(() => {
        const pages: number[] = [];
        for (let i = 1; i <= page; i++) {
          pages.push(i);
        }
        this.databaseService.threadInteractions.mapToClass(InteractionThread);
        return this.databaseService.threadInteractions
          .where(unclassified ? 'channel_id' : 'yobi_contact_id')
          .equals(threadId)
          .and((item) => !inbox || !item.inbox_id || inbox == item.inbox_id)
          .and((item) => pages.indexOf(item.page) > -1)
          .reverse()
          .sortBy('interaction_dt');
      })
    );
  }

  updateLocalThreadMessage(interaction: Partial<InteractionThread> & Pick<InteractionThread, 'interaction_id'>): void {
    this.databaseService.threadInteractions
      .update(interaction.interaction_id as unknown as string, interaction)
  }

  getLocalThreadMessage(query: {
    unclassified: boolean;
    threadId: number;
    page: number;
  }): Observable<InteractionThread[]> {
    return from(
      liveQuery(() => {
        const pages: number[] = [];
        for (let i = 1; i <= query.page; i++) {
          pages.push(i);
        }
        this.databaseService.threadInteractions.mapToClass(InteractionThread);
        let rt;
        if (query?.unclassified) {
          rt = this.databaseService.threadInteractions
            .where({ channel_id: query.threadId })
            .and((item) => pages.indexOf(item.page) > -1);
        } else {
          rt = this.databaseService.threadInteractions
            .where({ yobi_contact_id: query.threadId })
            .and((item) => pages.indexOf(item.page) > -1);
        }
        return rt.sortBy('interaction_dt');
      })
    );
  }

  getClassifiedMessages(
    { params: { inbox, page }, threadId }: ThreadMessagesQueryDto
  ): Observable<InteractionThread[]> {
    const requestURL = `${environment.apiURL}contacts/${threadId}/interactions-v2`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      { page, inboxes: inbox?.is_channel ? inbox.inbox_id : '', custom: inbox?.is_custom ? inbox.inbox_id : '' }
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<InteractionThread[]>;
        return res.results;
      })
    );
  }

  resendUndeliveredMesage(interaction_id: number): Observable<InteractionThread> {
    const requestURL = `${environment.apiURL}messages/resend/${interaction_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<InteractionThread>;
        return res.results;
      })
    );
  }

  addThreadMessage(data: InteractionThread): void {
    this.databaseService.threadInteractions
      .put(data)
      .then()
      .catch(console.error);
  }

  appendRecentInteractionToThreadInteractions(data: RecentInteraction): void {
    const threadInteraction = data.mapToInteractionThread
    this.databaseService.transaction(
      'rw!',
      this.databaseService.threadInteractions,
      async () => {
        let localInteractions = [];
        if (data.yobi_contact_id) {
          localInteractions = await this.databaseService.threadInteractions
            .where('yobi_contact_id')
            .equals(data.yobi_contact_id)
            .toArray();
        } else if (data.channel_id) {
          localInteractions = await this.databaseService.threadInteractions
            .where('channel_id')
            .equals(data.channel_id)
            .toArray();
        }
        if (!localInteractions?.length) {
          this.databaseService.threadInteractions
            .put(threadInteraction)
            .then()
            .catch(console.error);
        }
      }
    );
  }

  setCurrentThreadContact(): void { }

  saveThreadPreferences(data: ThreadPreferences): void {
    this.databaseService.transaction(
      'rw!',
      this.databaseService.threadPreferences,
      async () => {
        const pref = await this.databaseService.threadPreferences.get(
          data.threadId?.toString()
        );
        this.databaseService.threadPreferences.put({ ...pref, ...data });
      }
    );
  }

  getThreadPreferences(
    threadId: number
  ): Observable<ThreadPreferences | undefined> {
    return from(
      liveQuery(() => {
        this.databaseService.threadPreferences.mapToClass(ThreadPreferences);
        return this.databaseService.threadPreferences.get(threadId?.toString());
      })
    );
  }
}
