import { environment } from '@/environments/environment';
import { PlatformService } from '@/app/Utilities/platform/platform.service';
import { ElectronViewModel } from '@/app/Ui/ViewModels/electronViewModel';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './restriction-error-dialog.component.html',
  styleUrls: ['./restriction-error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestrictionErrorDialogComponent implements OnInit {
  message = '';
  danger: boolean;
  isDesktop = this.platformService.isElectronApp
  isMacApp = this.platformService.isMacOS
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { message: string },
    private platformService: PlatformService,
    private dialogRef: MatDialogRef<RestrictionErrorDialogComponent>
  ) {}

  ngOnInit(): void {
    this.message = this.data.message;
  }

  confirm() {
    if(this.isDesktop) {
      this.platformService.openURL(environment?.appURL);
    }
    this.dialogRef.close(!this.isDesktop);
  }
}
