import { ConferenceUpdateStatus } from '@/app/core/Models/ConferenceParticipant';
import { TeamMember } from '@/app/core/Models/TeamMember';
import { Tenant } from '@/app/core/Models/tenant';
import { CallSessionState, ConferenceParicipantTypes, VoiceClientState } from '@/app/State/VoiceClient/voice-client.reducer';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-dialer-call-session-item',
	templateUrl: './dialer-call-session-item.component.html',
	styleUrls: ['./dialer-call-session-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialerCallSessionItemComponent {

	@Input('callSession') set _callSession(value: CallSessionState) {
		this.callSession = value;
	};
	@Input() profile: Tenant
	@Input() callTimer: string
	@Input() teamMembers: TeamMember[]
	@Output() accept = new EventEmitter();
	@Output() hangup = new EventEmitter();
	@Output() toggleMute = new EventEmitter();
	@Output() putOnHold = new EventEmitter();
	@Output() mergeCall = new EventEmitter();
	@Output() forwardCall = new EventEmitter();
	@Output() sendDigit = new EventEmitter<string>();
	@Output() inviteUser = new EventEmitter<TeamMember>();
	@Output() selectActiveMember = new EventEmitter<string>();

	callSession: CallSessionState;
	activePanel: 'dialpad' | 'transfer-list' | null = null;
	digit: string = '';

	constructor(
		private changeDetectorRef: ChangeDetectorRef
	) { }

	get conferenceMembers() {
		return [...this.callSession.conference?.participants ?? []]
			.filter(participant => participant.user_id !== this.profile?.userId?.toString()) ?? [];
	}

	get activeCallerOnHold() {
		return this.callSession.conference?.participants
			.find(member => member.activeCaller)
			?.status === ConferenceUpdateStatus.hold ?? false;
	}

	get canTransfer() {
		return this.callSession.conference?.particpantsNumber
			&& this.callSession.conference?.particpantsNumber > 2
			&& this.callSession.conference?.participants.find(member => member.type === ConferenceParicipantTypes.host)?.user_id === this.profile?.userId?.toString();
	}

	get canMerge() {
		return this.canTransfer && !this.callSession.conference?.merged;
	}

	toggleDialpad() {
		this.activePanel = this.activePanel === 'dialpad' ? null : 'dialpad';
		this.changeDetectorRef.detectChanges();
	}

	toggleTransferList() {
		this.activePanel = this.activePanel === 'transfer-list' ? null : 'transfer-list';
		this.changeDetectorRef.detectChanges();
	}

	onDigit(digit: string): void {
		this.digit += digit + '';
		this.sendDigit.emit(digit);
	}

	swapActiveCaller() {
		this.selectActiveMember.emit(this.conferenceMembers.find(member => !member.activeCaller)?.id);
	}
}
