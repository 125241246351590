import { createReducer, on } from '@ngrx/store';
import { PhoneChannelAddAction, PhoneChannelAddFailAction, PhoneChannelAddSuccessAction, PhoneVerificationCodeRequestAction, PhoneVerificationCodeRequestFailAction, PhoneVerificationCodeRequestSuccessAction, PhoneVerificationCodeValidationAction, PhoneVerificationCodeValidationFailAction, PhoneVerificationCodeValidationSuccessAction, PhoneVerificationResetAction } from './phoneRequest.action';
import { SignOutAction } from '../app/action';
export interface PhoneVerificationState {
  channelValue?: string;
  loading: boolean;
  requestSuccess: boolean;
  requestFailed: boolean;
  verificationSuccess: boolean;
  verificationFailed: boolean;
  message?: string;
}

export interface PhoneRequestState {
  loading?: boolean;
  phoneVerification: PhoneVerificationState;
}

export const initPhoneRequestState: PhoneRequestState = {
  loading: false,
  phoneVerification: {
    loading: false,
    requestFailed: false,
    requestSuccess: false,
    verificationSuccess: false,
    verificationFailed: false,
  },
};

export const PhoneRequestReducer = createReducer(
  initPhoneRequestState,
  
  /* ------------------------------ Verify phone ------------------------------ */
  on(
    PhoneVerificationCodeRequestAction,
    (state: PhoneRequestState, { payload }) => {
      return {
        ...state,
        phoneVerification: {
          ...state.phoneVerification,
          channelValue: payload.channel_value,
          loading: true,
          message: undefined,
        },
      };
    }
  ),
  on(PhoneVerificationCodeRequestSuccessAction, (state: PhoneRequestState) => {
    return {
      ...state,
      phoneVerification: {
        ...state.phoneVerification,
        requestSuccess: true,
        requestFailed: false,
        loading: false,
        message: undefined,
      },
    };
  }),
  on(
    PhoneVerificationCodeRequestFailAction,
    (state: PhoneRequestState, { payload }) => {
      return {
        ...state,
        phoneVerification: {
          ...state.phoneVerification,
          requestSuccess: false,
          requestFailed: true,
          loading: false,
          message: payload,
        },
      };
    }
  ),
  on(
    PhoneVerificationCodeValidationAction,
    (state: PhoneRequestState, { payload }) => {
      return {
        ...state,
        phoneVerification: {
          ...state.phoneVerification,
          loading: true,
        },
      };
    }
  ),
  on(
    PhoneVerificationCodeValidationSuccessAction,
    (state: PhoneRequestState, {}) => {
      return {
        ...state,
        phoneVerification: {
          ...state.phoneVerification,
          loading: false,
          verificationSuccess: true,
          verificationFailed: false,
          message: undefined,
        },
      };
    }
  ),
  on(
    PhoneVerificationCodeValidationFailAction,
    (state: PhoneRequestState, { payload }) => {
      return {
        ...state,
        phoneVerification: {
          ...state.phoneVerification,
          loading: false,
          verificationFailed: true,
          message: payload,
        },
      };
    }
  ),
  on(PhoneVerificationResetAction, (state: PhoneRequestState) => {
    return {
      ...state,
      phoneVerification: {
        ...initPhoneRequestState.phoneVerification,
      },
    };
  }),

  /* -------------------------------- Buy phone ------------------------------- */
  on(PhoneChannelAddAction, (state: PhoneRequestState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(PhoneChannelAddFailAction, (state: PhoneRequestState) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(PhoneChannelAddSuccessAction, (state: PhoneRequestState) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(SignOutAction, (state: PhoneRequestState) => {
    return {
      ...initPhoneRequestState,
    };
  })

);

