import { PhoneRequestReducer, PhoneRequestState } from './phoneRequest/phoneRequest.reducer';
import { navigationReducer, NavigationState } from './Router/reducer';
import { tasksReducer, TasksState } from './Tasks/reducer';
import { ActionReducerMap } from '@ngrx/store';
import {
  LoginState,
  loginReducer,
  singupReducer,
  SingupState,
} from './Auth/reducer';

import { inboxReducer, InboxState } from './Inbox/inbox.reducer';
import { tenantReducer, TenantState } from '@/app/State/Tenant/tenant.reducer';
import { contactReducer, ContactState } from '@/app/State/contact/contact.reducer';
import { taskReducer, TaskState } from '@/app/State/Task/reducer';
import { callReducer, VoiceClientState } from '@/app/State/VoiceClient/voice-client.reducer';
import {
  notificationsReducer,
  NotificationsState,
} from '@/app/State/Notifications/notifications.reducer';
import { configReducer, ConfigState } from '@/app/State/app/reducer';
import { UsersState, usersReducer } from './users/reducer';
import { integrationsReducer, IntegrationsState } from './Integrations/reducer';
import { BillingReducer, BillingState } from './billing/billing.reducer';
import { teamInboxReducer, TeamInboxState } from './TeamInbox/teamInbox.reducer';
import { MediaObserverReducer, MediaObserverState } from './mediaObserver/media.observer.reducer';
import { tagsReducer, TagsState } from './Tags/tags.reducer';
import { fullTextSearchReducer, FullTextSearchState } from './fullTextSearch/fullTextSearch.reducer';
import { InteractionsReducer, InteractionsState } from './Interaction/interaction.reducer';
import { threadReducer, ThreadState } from './thread/thread.reducer';
import { SocialPublisherReducer, SocialPublisherState } from './Social-Publisher/Social-Publisher.reducer';
import { OnboardingReducer, OnboardingState } from './onboarding/onboarding.reducer';
import { ContactsReducer, ContactsState } from './contacts/contacts.reducer';
import { emailInboxReducer, EmailInboxState } from './email-inbox/email-inbox.reducer';
import { VoicemailsReducer, VoicemailsState } from './voicemails/voicemails.reducer';
import { messageComposerReducer, messageComposerState } from './message-composer/message-composer.reducer';
import { synthReducer, SynthState } from './synth/synth.reducer';
import { CompaniesReducer, CompaniesState } from './companies/companies.reducer';
import { roomReducers, RoomState } from './Room/room.reducer';
import { ProductsReducer, ProductsState } from './products/products.reducer';
import { DealsReducer, DealsState } from './deals/deals.reducer';
export interface FeatureState {
  counter: number;
}
export interface AppState {
  readonly login: LoginState;
  readonly config: ConfigState;
  readonly singup: SingupState;
  readonly inbox: InboxState;
  readonly emailInbox: EmailInboxState;
  readonly thread: ThreadState;
  readonly teamInbox: TeamInboxState;
  readonly tags: TagsState;
  readonly messageComposer: messageComposerState;
  readonly billing: BillingState;
  readonly tenant: TenantState;
  readonly contact: ContactState;
  readonly contacts: ContactsState;
  readonly companies: CompaniesState;
  readonly products: ProductsState;
  readonly deals: DealsState;
  readonly voicemails: VoicemailsState;
  readonly task: TaskState;
  readonly synth: SynthState;
  readonly tasks: TasksState;
  readonly call: VoiceClientState;
  readonly users: UsersState;
  readonly phoneRequest: PhoneRequestState;
  readonly onboarding: OnboardingState;
  readonly notifications: NotificationsState;
  readonly fullTextSearch: FullTextSearchState;
  readonly integrations: IntegrationsState;
  readonly interactions: InteractionsState;
  readonly mediaObserver: MediaObserverState;
  readonly socialPublisher: SocialPublisherState;
  readonly navigation: NavigationState;
  readonly room: RoomState;
}
export const Reducer: ActionReducerMap<AppState> = {
  login: loginReducer,
  config: configReducer,
  singup: singupReducer,
  thread: threadReducer,
  billing: BillingReducer,
  inbox: inboxReducer,
  emailInbox: emailInboxReducer,
  teamInbox: teamInboxReducer,
  tags: tagsReducer,
  messageComposer: messageComposerReducer,
  tenant: tenantReducer,
  contact: contactReducer,
  contacts: ContactsReducer,
  companies: CompaniesReducer,
  products: ProductsReducer,
  deals: DealsReducer,
  voicemails: VoicemailsReducer,
  task: taskReducer,
  synth: synthReducer,
  tasks: tasksReducer,
  call: callReducer,
  users: usersReducer,
  phoneRequest: PhoneRequestReducer,
  onboarding: OnboardingReducer,
  notifications: notificationsReducer,
  integrations: integrationsReducer,
  interactions: InteractionsReducer,
  mediaObserver: MediaObserverReducer,
  fullTextSearch: fullTextSearchReducer,
  socialPublisher: SocialPublisherReducer,
  navigation: navigationReducer,
  room: roomReducers
};
