import { Observer } from "rxjs";
import { NoteDto } from '@/app/Data/DTO/NoteDto';

export class NoteBody {
  uploadProgress$: Observer<any>

  constructor(
    private content: string,
    private noteId: number | undefined,
    private contactId: number | undefined,
    private unclassifiedChannelId: number | undefined,
    private attachments: { file?: File, type: string, url: string }[] = []
  ) { }


  get hasNewAttachments(): boolean {
    return this.attachments.filter(a => !!a.file).length > 0;
  }

  getBodyAttachment(): FormData {
    const form = new FormData();
    this.attachments
      .map((attachment) => attachment.file)
      .filter((file): file is File => !!file)
      .forEach((file) => {
        form.append(file.type.startsWith('image/') ? 'image' : file.type.startsWith('video/') ? 'video' : 'file', file, file.name || '');
      });
    return form;
  }

  getBody(): BaseNoteDto {
    return {
      note: {
        content: this.content,
        attachments: this.attachments.filter(a => !a.file).map(({ type, url }) => ({ type, url })),
      },
      noteId: this.noteId,
      contactId: this.contactId,
      unclassifiedChannelId: this.unclassifiedChannelId,
    };
  }
}

interface BaseNoteDto {
  note: NoteDto;
  noteId?: number;
  contactId?: number;
  unclassifiedChannelId?: number;
}