import { InteractionBody } from "../Interaction"

export type InteractionCommentBody = {
  type: string;
  text: string;
  id?: string | number;
  user?: string;
}[];
export class InteractionComment {
    comment_id: number;
    content: string;
    contentRAW: string;
    created_dt: string
    interaction_id: string
    is_record_disabled: boolean
    tenant_id: string
    updated_dt: string
    user_id: number | string
    avatar?: string
    given_name?: string
    family_name?: string
    sending?: boolean;
    failed?: boolean;
  
    get body(): InteractionCommentBody {
        let body: {
          type: string;
          text: string;
          id?: string | number;
          user?: string;
        }[] = [];
        try {
          body = JSON.parse(this.content);
          if (Array.isArray(body) && !body.length) {
            body = [{
              type: 'text',
              text: 'Message type not supported, please contact Yobi Customer Support for further assistance'
            }]
          }
          if (!Array.isArray(body)) {
            throw new Error("body detected as number");
          }
        } catch {
          body = [
            {
              type: 'text',
              text: this.content,
            },
          ];
        }
        return body;
      }
} 