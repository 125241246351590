import { AppState } from '@/app/State/AppState';
import { createSelector } from '@ngrx/store';
import { VoiceClientState } from '@/app/State/VoiceClient/voice-client.reducer';

const callState = (state: AppState) => state.call;

export const selectVoiceClientState = createSelector(
  callState,
  (state: VoiceClientState) => state
);

// export const selectVoiceClientSessions = createSelector(
//   callState,
//   (state: VoiceClientState) => state.callSessions
// );

export const selectVoiceClientDeviceState = createSelector(
  callState,
  (state: VoiceClientState) => state.device.state
);