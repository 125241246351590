import { Tenant } from '@/app/core/Models/tenant';
import { Injectable } from '@angular/core';
declare let fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class FacebookPixelService {

  tenant: Tenant

  constructor() { }

  init(tenant: Tenant) {
    this.tenant = tenant
  }

  track(event: 'FirstVisit', unique = false) {
    if (unique && localStorage.getItem(`fbq_${ event }`)) {
      return
    }
    fbq('trackCustom', event, {
      tenantId: this.tenant?.tenantId,
      userId: this.tenant?.userId,
      email: this.tenant?.email
    });
    if (unique) {
      localStorage.setItem(`fbq_${ event }`, 'true')
    }
  }
}
