import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DialerAudioSettingsComponent } from './dialer-audio-settings.component';
import { InputWrapperModule } from '../../components/new-input-wrapper/input-wrapper.module';
@NgModule({
  declarations: [DialerAudioSettingsComponent],
  exports: [DialerAudioSettingsComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule.forChild({extend: true}),
    MatIconModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    InputWrapperModule
  ],
  providers: [],
})
export class DialerAudioSettingsModule {
  constructor() {}
}
