import { AppState } from '@/app/State/AppState';
import { createSelector } from '@ngrx/store';
import { TaskState } from '@/app/State/Task/reducer';

const taskState = (state: AppState) => state.task;

export const selectEditableTaskCommentId = createSelector(
  taskState,
  (state: TaskState) => state.editableCommentId
);
