import { createReducer, on } from '@ngrx/store';
import { InternalChannel } from '../../core/Models/Channel';

import { SignOutAction } from '@/app/State/app/action';
import { RecentInteractionsFilter } from '@/app/core/Models/RecentInteractionsFilter';
import { User } from '@/app/core/Models/User';
import {
  UpdateSupportChannelAction
} from '../thread/thread.action';
import {
  FetchInboxesAction,
  FetchInboxesSuccessAction,
  FetchRecentMessagesAction,
  FetchRecentMessagesSuccessAction,
  InboxForceRefreshAction,
  InboxForceRefreshRequestAction,
  InboxSelectionAction,
  IncreaseInboxUnreadCountAction,
  OpenConversationThreadAction,
  ResetActiveThreadIdAction,
  SetActiveThreadIdAction,
  SetTypingUserAction,
  ToggleScheduledMessagesAction,
  UpdateRecentInteractionsFilterAction
} from './inbox.action';
import { RecentInteractionsState, ThreadPreferences } from './inbox.model';
import { Inbox } from '@/app/core/Models/inbox';
import { ToggleMessageComposerAction } from '../message-composer/message-composer.action';


export interface InboxState {
  inboxes: Inbox[]
  selectedInbox?: Inbox;
  fetchingInboxes: boolean;
  fetching: boolean;
  usersTyping: {
    channelId: number,
    user: User
  }[],
  showScheduledMessages: boolean;
  threadId?: number;
  force_refresh: boolean;
  unclassified?: boolean;
  recentInteractions: RecentInteractionsState;
  threadContact?: any;

  thread?: ThreadPreferences;
  supportChannel?: InternalChannel | null;
}

export const initInboxState: InboxState = {
  inboxes: [],
  fetchingInboxes: false,
  fetching: false,
  usersTyping: [],
  showScheduledMessages: false,
  force_refresh: false,
  recentInteractions: {
    page: 1,
    isLastPage: false,
    filter: RecentInteractionsFilter.inbox,
  }
};

export const inboxReducer = createReducer(
  initInboxState,
  on(FetchRecentMessagesAction, (state: InboxState, { payload }) => {
    return {
      ...state,
      fetching: true,
      recentInteractions: {
        ...state?.recentInteractions,
        page: payload?.page,
      },
    };
  }),
  on(FetchRecentMessagesSuccessAction, (state: InboxState, { payload }) => {
    return {
      ...state,
      fetching: false,
      recentInteractions: {
        ...state.recentInteractions,
        isLastPage: !payload?.length,
      },
    };
  }),
  on(SetTypingUserAction,
    (state: InboxState, { payload: { channelId, status, user } }) => {
      let usersTyping = state.usersTyping.filter(u => u.user.user_id !== user.user_id)
      status && usersTyping.push({ user, channelId })
      return {
        ...state,
        usersTyping
      };
    }
  ),
  on(OpenConversationThreadAction,
    (state: InboxState, { payload: { threadId, unClassified: unclassified } }) => {
      return {
        ...state,
        threadId,
        unclassified,
      };
    }
  ),
  on(SetActiveThreadIdAction, (state: InboxState, { payload }) => {
    return {
      ...state,
      threadId: payload?.id,
      unclassified: payload?.unClassified,
    };
  }),
  on(ResetActiveThreadIdAction, (state: InboxState) => {
    return {
      ...state,
      threadId: undefined,
      unclassified: undefined,
    };
  }),

  on(UpdateRecentInteractionsFilterAction, (state: InboxState, { payload }) => {
    const currentFilter = state.recentInteractions.filter;
    return {
      ...state,
      recentInteractions: {
        ...state.recentInteractions,
        page: 1,
        filter: payload === currentFilter ? RecentInteractionsFilter.inbox : payload,
      },
    };
  }),

  on(InboxSelectionAction, (state: InboxState, { payload: { inbox } }) => {
    return {
      ...state,
      selectedInbox: inbox,
      recentInteractions: {
        ...state.recentInteractions,
        page: 1,
      },
    };
  }),

  on(FetchInboxesAction, (state: InboxState) => {
    return {
      ...state,
      fetchingInboxes: true
    };
  }),
  on(FetchInboxesSuccessAction, (state: InboxState, { payload: { inboxes } }) => {
    return {
      ...state,
      inboxes,
      fetchingInboxes: false
    };
  }),

  on(IncreaseInboxUnreadCountAction, (state: InboxState, { payload: { inboxId } }) => {
    return {
      ...state,
      inboxes: state.inboxes.map(inbox => inbox.inbox_id == inboxId ? Object.assign(new Inbox(), inbox, { unread_count: inbox.unread_count + 1 }) : inbox),
      recentInteractions: {
        ...state.recentInteractions,
      },
    };
  }),

  on(UpdateSupportChannelAction, (state: InboxState, { payload }) => {
    return {
      ...state,
      supportChannel: payload,
    };
  }),

  on(InboxForceRefreshRequestAction, (state: InboxState) => {
    return {
      ...state,
      force_refresh: true
    };
  }),
  on(InboxForceRefreshAction, (state: InboxState) => {
    return {
      ...state,
      force_refresh: false
    };
  }),

  on(ToggleScheduledMessagesAction, (state: InboxState, { payload: { status } }) => {
    return {
      ...state,
      showScheduledMessages: status ?? !state.showScheduledMessages
    }
  }),
  
  on(ToggleMessageComposerAction, (state: InboxState, { payload: { status } }) => {
    return {
      ...state,
      showScheduledMessages: status ? false : state.showScheduledMessages
    }
  }),
  on(SignOutAction, (state: InboxState) => {
    return {
      ...initInboxState,
    };
  }),

);
