import { DatabaseService } from './../services/Database/database.service';
import { SuccessApiResponse } from './../services/Networking/ApiResponse';

import { IMediaRepository } from '@/app/core/IRepositories/IMediaRepository';
import { getUploadEventProgress } from '@/app/Utilities/functions/getUploadEventProgress';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { last, map, Observable, Observer, pluck, tap } from "rxjs";
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from './../services/Networking/HttpService';

@Injectable({
  providedIn: 'root',
})
export class MediaRepository extends IMediaRepository {

  constructor(private httpService: HttpService, private databaseService: DatabaseService) {
    super()
  }


  uploadImages(data: FormData, progress$: Observer<any>): Observable<string[]> {
    const requestURL = `${environment.apiURL}media/upload_image/image`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false,
      null,
      true
    );
    return this.httpService.upload(options).pipe(
      tap((event: any) => getUploadEventProgress(event, progress$)),
      last(),
      pluck('body'),
      map((item) => {
        let res = item as SuccessApiResponse<string[]>;
        return res.results;
      })
    );
  }

  uploadAttachments(data: FormData, progress$: Observer<any>): Observable<{
    type: string,
    url: string
  }[]> {
    const requestURL = `${environment.apiURL}attachment/upload`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false,
      null,
      true
    );
    return this.httpService.upload(options).pipe(
      tap((event: any) => getUploadEventProgress(event, progress$)),
      last(),
      pluck('body'),
      map((item) => {
        let res = item as SuccessApiResponse<{
          type: string,
          url: string
        }[]>;
        return res.results;
      })
    );
  }

  uploadAttachments2(data: FormData): Observable<{
    type: string,
    url: string
  }[]> {
    const requestURL = `${environment.apiURL}attachment/upload`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false,
      null
    );
    return this.httpService.upload(options).pipe(
      map((item) => {
        let res = item as SuccessApiResponse<{
          type: string,
          url: string
        }[]>;
        return res.results;
      })
    );
  }





}