import { ISocketClient } from '@/app/Data/Adapters/ISocketClient';
import { AuthenticationRepository } from '@/app/Data/repositories/AuthenticationRepository';
import { ChannelRepository } from '@/app/Data/repositories/ChannelRepository';
import { ContactRepository } from '@/app/Data/repositories/ContactRepository';
import { ContactsRepository } from '@/app/Data/repositories/ContactsRepository';
import { InteractionRepository } from '@/app/Data/repositories/InteractionRepository';
import { MessagesRepository } from '@/app/Data/repositories/MessagesRepository';
import { MessagingRepository } from '@/app/Data/repositories/MessagingRepository';
import { NotesRepository } from '@/app/Data/repositories/NotesRepository';
import { NotificationRepository } from '@/app/Data/repositories/NotificationRepository';
import { TaskRepository } from '@/app/Data/repositories/TaskRepository';
import { TasksRepository } from '@/app/Data/repositories/TasksRepository';
import { TenantRepository } from '@/app/Data/repositories/TenantRepository';
import { ThreadRepository } from '@/app/Data/repositories/ThreadRepository';
import { UsersRepository } from '@/app/Data/repositories/UsersRepository';
import { VoiceClientRepository } from '@/app/Data/repositories/VoiceClientRepository';
import { SocketService } from '@/app/Data/socket/socket.service';
import { DialerModule } from '@/app/Ui/DIaler/dialer.module';
import { AuthGuard } from '@/app/Ui/guards/auth.guard';
import { GuestGuard } from '@/app/Ui/guards/guest.guard';
import { IAuthenticationRepository } from '@/app/core/IRepositories/IAuthenticationRepository';
import { IChannelRepository } from '@/app/core/IRepositories/IChannelRepository';
import { IContactRepository } from '@/app/core/IRepositories/IContactRepository';
import { IContactsRepository } from '@/app/core/IRepositories/IContactsRepository';
import { IInteractionRepository } from '@/app/core/IRepositories/IInteractionRepository';
import { IMessagesRepository } from '@/app/core/IRepositories/IMessagesRepository';
import { IThreadRepository } from '@/app/core/IRepositories/IMessagesThreadRepository';
import { IMessagingRepository } from '@/app/core/IRepositories/IMessagingRepository';
import { INotesRepository } from '@/app/core/IRepositories/INotesRepository';
import { INotificationRepository } from '@/app/core/IRepositories/INotificationRepository';
import { ISocialPublisherRepository } from '@/app/core/IRepositories/ISocialPublisherRepository';
import { ITaskRepository } from '@/app/core/IRepositories/ITaskepository';
import { ITasksRepository } from '@/app/core/IRepositories/ITasksRepository';
import { ITenantRepository } from '@/app/core/IRepositories/ITenantRepository';
import { IUserRepository } from '@/app/core/IRepositories/IUserRepository';
import { IUsersRepository } from '@/app/core/IRepositories/IUsersRepository';
import { IVoiceClientRepository } from '@/app/core/IRepositories/IVoiceClientRepository';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localePt from '@angular/common/locales/pt';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from "@angular/service-worker";
import { HotkeysModule } from '@ngneat/hotkeys';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import * as Sentry from "@sentry/angular";
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { UiScrollModule } from 'ngx-ui-scroll';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { ILocalStorageService } from './Data/Adapters/ILocalStorageService';
import { BillingRepository } from './Data/repositories/BillingRepository';
import { CustomFieldsRepository } from './Data/repositories/CustomFieldsRepository';
import { FullTextSearachRepository } from './Data/repositories/FullTextSearachRepository';
import { ImportContactRepository } from './Data/repositories/ImportContactsRepository';
import { InboxesRepository } from './Data/repositories/InboxesRepository';
import { InlineNotesRepository } from './Data/repositories/InlineNotesRepository';
import { IntegrationsRepository } from './Data/repositories/IntegrationsRepository';
import { MediaRepository } from './Data/repositories/MediaRepository';
import { MessagesSchedulerRepository } from './Data/repositories/MessagesSchedulerRepository';
import { OnboardingRepository } from './Data/repositories/OnboardingRepository';
import { ReformulateTextRepository } from './Data/repositories/ReformulateTextRepository';
import { SnippetsRepository } from './Data/repositories/SnippetsRepository';
import { SocialPublisherRepository } from './Data/repositories/SocialPublisherRepository';
import { TagsRepository } from './Data/repositories/TagsRepository';
import { TeamInteractionRepository } from './Data/repositories/TeamInteractionRepository';
import { TranslateRepository } from './Data/repositories/TranslateRepository';
import { UserRepository } from './Data/repositories/UserRepository';
import { LocalSorageService } from './Data/services/LocalStorage/localStorage.service';
import { HttpErrorInterceptor } from './Data/services/Networking/HttpErrorInterceptor';
import { HttpTokenInterceptor } from './Data/services/Networking/HttpTokenInterceptor';
import { RestrictionErrorInterceptor } from './Data/services/Networking/ResrictionErrorInterceptor';
import { StateStoreRootModule } from './State/store-root.module';
import { AlertModule } from './Ui/Modals/alert/alert.module';
import { RestrictionErrorDialogModule } from './Ui/Modals/restriction-error-dialog/restriction-error-dialog.module';
import { RegistrationModule } from './Ui/Registration/registration.module';
import { BackDoorLoginComponent } from './Ui/back-door-login/back-door-login.component';
import { AppUpdateNotificationModule } from './Ui/components/app-update-notification/app-update-notification.module';
import { BlackFridayAnnocementModule } from './Ui/components/black-friday-annocement/black-friday-annocement.module';
import { OnboardingGuard } from './Ui/guards/onboarding.guard';
import { InteractionPreviewPipeModule } from './Ui/pipes/interaction-preview.pipe';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { IBillingRepository } from './core/IRepositories/IBillingRepository';
import { ICustomFieldsRepository } from './core/IRepositories/ICustomFieldsRepository';
import { IFullTextSearachRepository } from './core/IRepositories/IFullTextSearchRepository';
import { IImportContactsRepository } from './core/IRepositories/IImportContactsRepository';
import { IInboxesRepository } from './core/IRepositories/IInboxesRepository';
import { IInlineNotesRepository } from './core/IRepositories/IInlineNotesRepository';
import { IIntegrationsRepository } from './core/IRepositories/IIntegrationsRepository';
import { IMediaRepository } from './core/IRepositories/IMediaRepository';
import { IMessagesSchedulerRepository } from './core/IRepositories/IMessagesSchedulerRepository';
import { IOnboardingRepository } from './core/IRepositories/IOnboardingRepository';
import { IReformulateTextRepository } from './core/IRepositories/IReformulateTextRepository';
import { ISnippetsRepository } from './core/IRepositories/ISnippetsRepository';
import { ITagsRepository } from './core/IRepositories/ITagsRepository';
import { ITeamInteractionRepository } from './core/IRepositories/ITeamInteractionRepository';
import { ITranslateRepository } from './core/IRepositories/ITranslateRepository';
import { ConfigurationService } from './shared/Config/configuration.service';
import { ConsoleLogger } from './shared/Logging/consoleLogger.service';
import { SharedModule } from './shared/shared.module';
import { emailMessagingRepository } from './Data/repositories/emailMessagingRepository';
import { IEmailMessagingRepository } from './core/IRepositories/IEmailMessagingRepository';
import { VoiceMailSocketService } from './Data/socket/voiceMail-socket.service';
import { IVoicemailInteractiosRepository } from './core/IRepositories/IVoicemailInteractionsRepository';
import { VoicemailInteractionsRepository } from './Data/repositories/VoicemailInteractionsRepository';
import { SynthRepository } from './Data/repositories/SynthRepository';
import { ISynthRepository } from './core/IRepositories/ISynthREpository';
import { ICompanyRepository } from './core/IRepositories/ICompanyRepository';
import { CompanyRepository } from './Data/repositories/CompanyRepository';
import { ProductRepository } from './Data/repositories/ProductRepository';
import { IProductRepository } from './core/IRepositories/IProductRepository';
import { IPipelineRepository } from './core/IRepositories/IPipelineRepository';
import { PipelineRepository } from './Data/repositories/CRMRepository';
import { IDealRepository } from './core/IRepositories/IDealRepository';
import { DealRepository } from './Data/repositories/DealRepository';
import {TaskFormModule} from "@/app/Ui/tasks/task-form/task-form.module";


registerLocaleData(localeFr)
registerLocaleData(localeDe)
registerLocaleData(localePt)
export const CANCEL_API_REQUESTS = "CANCEL_API_REQUESTS"
export const cancelApiRequest$ = new Subject()
const materials = [MatDialogModule, MatNativeDateModule, MatDatepickerModule];

@NgModule({
  declarations: [AppComponent, BackDoorLoginComponent],
  imports: [
    BrowserModule,
    CommonModule,
    TaskFormModule,
    HttpClientModule,
    DialerModule,
    MatSnackBarModule,
    BlackFridayAnnocementModule,
    AppUpdateNotificationModule,
    AngularFireModule.initializeApp(environment.firebase),
    OverlayModule,
    MatProgressBarModule,
    BrowserAnimationsModule,
    RegistrationModule,
    UiScrollModule,
    AngularSvgIconModule.forRoot(),
    ToastrModule.forRoot(),
    InteractionPreviewPipeModule,
    FormsModule,
    AppRoutingModule,
    StateStoreRootModule,
    SharedModule.forRoot(),
    HotkeysModule,
    RestrictionErrorDialogModule,
    AlertModule,
    NgxStripeModule.forRoot(environment.stripePublicApiKey),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ...materials,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => (new TranslateHttpLoader(http, './assets/i18n/', '.json')),
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AuthGuard,
    GuestGuard,
    OnboardingGuard,
    { provide: ISocketClient, useClass: SocketService, multi: true },
    { provide: ISocketClient, useClass: VoiceMailSocketService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: HTTP_INTERCEPTORS, useClass: RestrictionErrorInterceptor, multi: true },
    { provide: ILocalStorageService, useClass: LocalSorageService },
    { provide: IInboxesRepository, useClass: InboxesRepository },
    { provide: IBillingRepository, useClass: BillingRepository },
    { provide: IAuthenticationRepository, useClass: AuthenticationRepository },
    { provide: IContactsRepository, useClass: ContactsRepository },
    { provide: IMessagesRepository, useClass: MessagesRepository },
    { provide: IThreadRepository, useClass: ThreadRepository },
    { provide: IProductRepository, useClass: ProductRepository },
    { provide: ITenantRepository, useClass: TenantRepository },
    { provide: ISynthRepository, useClass: SynthRepository },
    { provide: IMessagingRepository, useClass: MessagingRepository },
    { provide: IEmailMessagingRepository, useClass: emailMessagingRepository },
    { provide: IContactRepository, useClass: ContactRepository },
    { provide: ICompanyRepository, useClass: CompanyRepository },
    { provide: IUsersRepository, useClass: UsersRepository },
    { provide: INotesRepository, useClass: NotesRepository },
    { provide: IInlineNotesRepository, useClass: InlineNotesRepository },
    { provide: IChannelRepository, useClass: ChannelRepository },
    { provide: ITasksRepository, useClass: TasksRepository },
    { provide: ITaskRepository, useClass: TaskRepository },
    { provide: INotificationRepository, useClass: NotificationRepository },
    { provide: IVoiceClientRepository, useClass: VoiceClientRepository },
    { provide: IPipelineRepository, useClass: PipelineRepository },
    { provide: IDealRepository, useClass: DealRepository },
    { provide: IInteractionRepository, useClass: InteractionRepository },
    { provide: ITeamInteractionRepository, useClass: TeamInteractionRepository },
    { provide: IUserRepository, useClass: UserRepository },
    { provide: IMessagesSchedulerRepository, useClass: MessagesSchedulerRepository },
    { provide: IIntegrationsRepository, useClass: IntegrationsRepository },
    { provide: ISnippetsRepository, useClass: SnippetsRepository },
    { provide: ITagsRepository, useClass: TagsRepository },
    { provide: IMediaRepository, useClass: MediaRepository },
    { provide: IImportContactsRepository, useClass: ImportContactRepository },
    { provide: IFullTextSearachRepository, useClass: FullTextSearachRepository },
    { provide: ISocialPublisherRepository, useClass: SocialPublisherRepository },
    { provide: ICustomFieldsRepository, useClass: CustomFieldsRepository },
    { provide: ITranslateRepository, useClass: TranslateRepository },
    { provide: IOnboardingRepository, useClass: OnboardingRepository },
    { provide: IReformulateTextRepository, useClass: ReformulateTextRepository },
    { provide: IVoicemailInteractiosRepository, useClass: VoicemailInteractionsRepository },
    { provide: CANCEL_API_REQUESTS, useValue: cancelApiRequest$ }
  ],
  bootstrap: [AppComponent],
  exports: [TranslateModule]
})
export class AppModule {
  constructor(
    consoleLogger: ConsoleLogger,
    trace: Sentry.TraceService,
    configurationService: ConfigurationService
  ) {
    configurationService.initializeDB().catch();
    consoleLogger.init();
  }
}
