import { ChannelType } from './ChannelTypes';
export class ScheduledMessage {
  celery_task_id: string
  channel_type: ChannelType
  channel_value: string
  created_dt: string
  id: number
  msg_data: string
  send_at: string | number
  send_to: string
  tenant_id: number
  updated_dt: string
  user_data: {
    avatar: string
    family_name: string
    given_name: string
    user_id: number,
  }
  contact_data?: {
    avatar: string
    family_name: string
    given_name: string
    contact_id: number,
  }


  user_id: number
  user_timezone: string
  get username() {
    return `${ this.user_data.given_name } ${ this.user_data.family_name }`
  }
  get contactname() {
    return this.contact_data?.contact_id ? `${ this.contact_data?.given_name } ${ this.contact_data?.family_name }`.trim() : ''
  }
  get body(): {
    content_type: 'text' | 'image';
    content: string | string[];
    user?: {
      user_id: number;
      family_name: string;
      given_name: string;
      avatar: string | null;
    };
    contact?: {
      contact_id: number;
      family_name: string;
      given_name: string;
      avatar: string | null;
    };
  }[] {
    let body: {
      content_type: 'text' | 'image';
      content: string | string[];
    }[] = [];
    try {
      body = JSON.parse(this.msg_data.replace(/None/gi, 'null')); // to fix backend issue
    } catch {
      body = [
        {
          content_type: 'text',
          content: this.msg_data,
        },
      ];
    }
    return body;
  }

}