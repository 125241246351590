import { fullTextSearch, FullTextSearchResult, LocalFullTextSearch } from '@/app/core/Models/fullTextSearch';
import { FullTextSearchQueryDto } from '@/app/Data/DTO/FullTextSearachDto';
import { createAction, props, union } from '@ngrx/store';

export const GET_FULLTEXTSEARCH = '[Full Text Search] execute query';
export const GetFullTextSearchAction = createAction(
  GET_FULLTEXTSEARCH,
  props<{ payload: FullTextSearchQueryDto }>()
);

export const GET_FULLTEXTSEARCH_SUCCESS = '[Full Text Search] execute query success';
export const GetFullTextSearchSuccess = createAction(
  GET_FULLTEXTSEARCH_SUCCESS,
  props<{ payload: { data: FullTextSearchResult, query: FullTextSearchQueryDto } }>()
);

export const GET_FULLTEXTSEARCH_FAIL = '[Full Text Search] execute query Fail';
export const GetFullTextSearchFail = createAction(
  GET_FULLTEXTSEARCH_FAIL,
  props<{ payload: { message: string, query: string } }>()
);

export const FULLTEXTSEARCH_NEXT_PAGE = '[Full Text Search] next page';
export const FullTextSearchNextPage = createAction(
  FULLTEXTSEARCH_NEXT_PAGE,
  props<{ payload: { page: number, type: fullTextSearch.type } }>()
);

export const GET_LOCAL_FULLTEXTSEARCH = '[Full Text Search] get local result';
export const GetLocalFullTextSearchAction = createAction(
  GET_LOCAL_FULLTEXTSEARCH,
  props<{ payload: { value: string } }>()
);

export const GET_LOCAL_FULLTEXTSEARCH_SUCCESS = '[Full Text Search] get local result success';
export const GetLocalFullTextSearchSuccess = createAction(
  GET_LOCAL_FULLTEXTSEARCH_SUCCESS,
  props<{ payload: { data: LocalFullTextSearch  } }>()
);

export const GET_LOCAL_FULLTEXTSEARCH_FAIL = '[Full Text Search] execute query Fail';
export const GetLocalFullTextSearchFail = createAction(
  GET_LOCAL_FULLTEXTSEARCH_FAIL,
  props<{ payload: { message: string, query: FullTextSearchQueryDto } }>()
);

export const DELETE_FULLTEXTSEARCH_FROM_HISTORY = '[Full Text Search] delete from history';
export const DeleteFullTextSearchFromHistoryAction = createAction(
  DELETE_FULLTEXTSEARCH_FROM_HISTORY,
  props<{ payload: { value: string } }>()
);


const all = union({
  GetFullTextSearch: GetFullTextSearchAction,
  GetFullTextSearchSuccess: GetFullTextSearchSuccess,
  GetLocalFullTextSearch: GetLocalFullTextSearchAction,
  GetLocalFullTextSearchSuccess: GetLocalFullTextSearchSuccess,
  DeleteFullTextSearchFromHistoryAction: DeleteFullTextSearchFromHistoryAction
})
export type FullTextSearchActions = typeof all;
