import { LocalizationViewModel } from '@/app/Ui/ViewModels/localizationViewModel';
import { emailRegExp } from '@/app/Utilities/regExp';
import { Roles } from '@/app/core/Models/Roles';
import { User } from '@/app/core/Models/User';
import { Inbox } from '@/app/core/Models/inbox';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CountryCode } from 'libphonenumber-js';
import { map } from 'rxjs';
import { SubSink } from 'subsink';
import { EditUserDto, InviteUserDto } from './../../../Data/DTO/CreateUserDto';
import { UsersViewModel } from './../../ViewModels/usersViewModel';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, OnDestroy {
  @Output() onCreate = new EventEmitter();
  creatingUser$ = this.usersViewModel.creatingUser$
  roles = Roles
  userForm: FormGroup;
  isUpdate = false;
  countryCode: CountryCode = 'US';
  sub = new SubSink()
  localization = this.localizationViewModel.localization
  inboxes$ = this.usersViewModel.inboxes$.pipe(map(inboxes => inboxes.filter(i => i.is_channel)));

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UserFormComponent>,
    private usersViewModel: UsersViewModel,
    private localizationViewModel: LocalizationViewModel,
    @Inject(MAT_DIALOG_DATA) public data: { user: User },
  ) {
    this.isUpdate = !!data.user
  }

  ngOnInit(): void {
    this.initForm()
    this.sub.sink = this.inboxes$
      .subscribe(inboxes => {
        this.initInboxes(inboxes)
      });
  }

  initForm(): void {
    this.userForm = this.fb.group({
      firstname: this.isUpdate ? [this.data.user!.firstName ?? '', [Validators.required]] : undefined,
      lastname: this.isUpdate ? [this.data.user!.lastName ?? '', [Validators.required]] : undefined,
      email: !this.isUpdate ? ['', [Validators.required, Validators.pattern(emailRegExp)]] : undefined,
      role: [this.data?.user?.roles[0] ?? null, [Validators.required]],
      channels: this.fb.array([]),
    });
  }

  initInboxes(inboxes: Inbox[]): void {
    inboxes.forEach(inboxe => {
      this.channels.push(
        new FormGroup({
          channel_name: new FormControl(inboxe.name),
          channel_label: new FormControl(inboxe.channel_name),
          channel_type: new FormControl(inboxe.channel_type),
          channel_id: new FormControl(inboxe.inbox_id),
          access: new FormControl(false),
          send: new FormControl(false),
        })
      );
    });
    if (this.isUpdate) {
      this.data.user!.accessibility.forEach(inbox => {
        this.channels.controls.find(c => c.value.channel_id === inbox.inbox_id)?.patchValue({
          access: inbox.access,
          send: inbox.send,
        })
      })
    }
  }

  get channels(): FormArray {
    return this.userForm.get('channels') as FormArray;
  }

  close(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.userForm.markAllAsTouched();
    const values = this.userForm?.value;
    // this.userForm.get('channels')!.setErrors(
    //   (values as (InviteUserDto | EditUserDto)).channels.every(c => !c.access && !c.send)
    //     ? { empty: true }
    //     : null
    // )

    this.userForm.valid &&
      this.isUpdate
      ? this.updateUser(values)
      : this.addNewUser(values);
  }

  addNewUser(request: InviteUserDto): void {
    this.usersViewModel.createUser(request);
  }

  updateUser(request: EditUserDto): void {
    this.usersViewModel.updateUser(request, this.data.user.user_id);
  }

  resetForm(): void {
    this.userForm.reset();
  }

  ngOnDestroy(): void {
      this.sub.unsubscribe()
  }
}
