import { FacebookPostDto, InstagramPostDto, LinkedinPostDto, SocialPostForm, TwitterPostDto } from '@/app/Data/DTO/socialPublish/SocialPostDto';
import { createAction, props, union } from '@ngrx/store';
/*********** FACEBOOK POST *******************/

export const PUBLISH_FACEBOOK_POST = '[Social Publisher] Publish Facebook Post';
export const PublishFacebookPostAction= createAction(
    PUBLISH_FACEBOOK_POST,
  props<{ payload: FacebookPostDto }>()
);
export const PUBLISH_FACEBOOK_POST_FAIL = '[Social Publisher] Facebook Publish Failed';
export const PublishFacebookPostFailAction= createAction(
  PUBLISH_FACEBOOK_POST_FAIL,
  props<{ payload: {body: FacebookPostDto, error?: string} }>()
);
export const PUBLISH_FACEBOOK_POST_SUCCESS = '[Social Publisher] Facebook Completed';
export const PublishFacebookPostSuccessAction= createAction(
  PUBLISH_FACEBOOK_POST_SUCCESS,
  props<{ payload: FacebookPostDto }>()
);

/***********  INSTAGRAM POST *******************/
export const PUBLISH_INSTAGRAM_POST = '[Social Publisher] Publish Instagram Post';
export const PublishInstagramPostAction= createAction(
  PUBLISH_INSTAGRAM_POST,
  props<{ payload: InstagramPostDto }>()
);
export const PUBLISH_INSTAGRAM_POST_FAIL = '[Social Publisher] Instagram Publish Failed';
export const PublishInstagramPostFailAction= createAction(
  PUBLISH_INSTAGRAM_POST_FAIL,
  props<{ payload: {body:  InstagramPostDto , error?: string}}>()
);
export const PUBLISH_INSTAGRAM_POST_SUCCESS = '[Social Publisher] Instagram Completed';
export const PublishInstagramPostSuccessAction= createAction(
  PUBLISH_INSTAGRAM_POST_SUCCESS,
  props<{ payload: InstagramPostDto }>()
);


/***********  TWITTER POST *******************/
export const PUBLISH_TWITTER_POST = '[Social Publisher] Publish Twitter Post';
export const PublishTwitterPostAction= createAction(
  PUBLISH_TWITTER_POST,
  props<{ payload: TwitterPostDto }>()
);
export const PUBLISH_TWITTER_POST_FAIL = '[Social Publisher] Twitter Publish Failed';
export const PublishTwitterPostFailAction= createAction(
  PUBLISH_TWITTER_POST_FAIL,
  props<{ payload: {body: TwitterPostDto, error?: string} }>()
);
export const PUBLISH_ITWITTER_POST_SUCCESS = '[Social Publisher] Twitter Completed';
export const PublishTwitterPostSuccessAction= createAction(
  PUBLISH_ITWITTER_POST_SUCCESS,
  props<{ payload: TwitterPostDto }>()
);

/***********  LINKEDIN POST *******************/
export const PUBLISH_LINKEDIN_POST = '[Social Publisher] Publish Linkedin Post';
export const PublishLinkedinPostAction= createAction(
  PUBLISH_LINKEDIN_POST,
  props<{ payload: LinkedinPostDto }>()
);
export const PUBLISH_LINKEDIN_POST_FAIL = '[Social Publisher] Linkedin Publish Failed';
export const PublishLinkedinPostFailAction= createAction(
  PUBLISH_LINKEDIN_POST_FAIL,
  props<{ payload: {body: LinkedinPostDto, error?: string} }>()
);
export const PUBLISH_LINKEDIN_POST_SUCCESS = '[Social Publisher] Linkedin Completed';
export const PublishLinkedinPostSuccessAction= createAction(
  PUBLISH_LINKEDIN_POST_SUCCESS,
  props<{ payload: LinkedinPostDto }>()
);




export const SOCIAL_POST_PUBLISH = '[Social Publisher] Publish Social Post';
export const SocialPostPublishAction= createAction(
  SOCIAL_POST_PUBLISH,
props<{ payload: SocialPostForm }>()
);
export const SOCIAL_PUBLISH_COMPLETED = '[Social Publisher] Publish Completed';
export const SocialPublishCompletedAction= createAction(
  SOCIAL_PUBLISH_COMPLETED
);

/**************** MEDIA UPLOAD **********************/
export const SOCIAL_POST_ATTACHMENT_UPLOAD= '[Social Publisher] Social Post Attachment Upload';
export const SocialPostAttachmentUploadAction= createAction(
  SOCIAL_POST_ATTACHMENT_UPLOAD,
props<{ payload: number }>()
);
export const SOCIAL_POST_ATTACHMENT_UPLOADED= '[Social Publisher] Social Post Attachment successfully Uploaded';
export const SocialPostAttachmentUploadSuccessAction= createAction(
  SOCIAL_POST_ATTACHMENT_UPLOADED,
  props<{ payload: {attachment_url: string} }>()
);

export const SOCIAL_POST_ATTACHMENT_UPLOAD_FAIL= '[Social Publisher] Social Post Attachment Upload failed';
export const SocialPostAttachmentUploadFailAction= createAction(
  SOCIAL_POST_ATTACHMENT_UPLOAD_FAIL);

export const SOCIAL_POST_CANCEL_EVENT= '[Social Publisher] Social Post Cancel Event';
export const SocialPostCancelEventAction= createAction(SOCIAL_POST_CANCEL_EVENT);


export const SOCIAL_POST_TRY_AGAIN= '[Social Publisher] Social Post Try again';
export const SocialPostTryAgainAction= createAction(SOCIAL_POST_TRY_AGAIN);


const all = union({
  SocialPostPublishAction,
  SocialPostAttachmentUploadAction,
  PublishFacebookPostAction,
  SocialPublishCompletedAction,
  PublishTwitterPostSuccessAction,
  PublishInstagramPostSuccessAction,
  PublishTwitterPostFailAction,
  PublishLinkedinPostAction,
  PublishLinkedinPostFailAction,
  PublishLinkedinPostSuccessAction,
  PublishInstagramPostFailAction,
  PublishInstagramPostAction,
  PublishTwitterPostAction,
  SocialPostCancelEventAction,
  SocialPostTryAgainAction
});

export type SocialPostPublisherActions = typeof all;