export interface ProductsQueryParams {
    page: number;
    page_size: number;
    search?: string;
    column: string;
    direction: 'asc' | 'desc'

}
export class ProductBase {
    yobi_crm_product_id: string;
    product_name: string;
    product_type: string;
    unit_price: number;
    billing_frequency: string;
    sku: string;
    url: string;
    total_count: number
}

export class Product extends ProductBase {
    description: string;
    created_dt: string;
    updated_dt: string;
    isChecked?: boolean;
}

export class DealProduct extends ProductBase {
    quantity: number
}