import { createReducer, on } from '@ngrx/store';
import { SignOutAction } from '../app/action';
import { bulkSmsDTO } from '@/app/Data/DTO/smsDTO';
import { ReformulateMultipleMessagesContentAction, ReformulateMultipleMessagesContentResponseAction, ResetReformulateMultipleMessagesContentAction, ScheduleMultipleMessagesAction, ScheduleMultipleMessagesFailAction, ScheduleMultipleMessagesSuccessAction, SendMultipleMessagesAction, SendMultipleMessagesFailAction, SendMultipleMessagesSuccessAction, ToggleMessageComposerAction } from './message-composer.action';
import { ScheduleBulkDto } from '@/app/Data/DTO/ScheduleMessageDto';
import { ToggleScheduledMessagesAction } from '../Inbox/inbox.action';


export interface messageComposerState {
  active: boolean,
  sending: boolean,
  reformulateState: {
    originalText?: string,
    content?: string,
    reformulating?: boolean
  },
  clearState?: number,
  sentMessages: (bulkSmsDTO | ScheduleBulkDto)[]
}

export const messageComposerState: messageComposerState = {
  active: false,
  sending: false,
  reformulateState: {},
  sentMessages: []
};

export const messageComposerReducer = createReducer(messageComposerState,
  on(ToggleMessageComposerAction, (state: messageComposerState, { payload: { status } }) => {
    return {
      ...state,
      active: status ?? !state.active,
    }
  }),
  on(ToggleScheduledMessagesAction, (state: messageComposerState, { payload: { status } }) => {
    return {
      ...state,
      active: status ? false : state.active
    }
  }),
  /* --------------------------- send bulk messages --------------------------- */
  on(SendMultipleMessagesAction, (state: messageComposerState, { }) => {
    return {
      ...state,
      sending: true,
    }
  }),
  on(SendMultipleMessagesSuccessAction, (state: messageComposerState, { payload }) => {
    return {
      ...state,
      sending: false,
      clearState: (state.clearState ?? 0) + 1,
      sentMessages: [...state.sentMessages, payload]
    }
  }),
  on(SendMultipleMessagesFailAction, (state: messageComposerState) => {
    return {
      ...state,
      sending: false
    }
  }),
  /* ------------------------- schedule bulk messages ------------------------- */
  on(ScheduleMultipleMessagesAction, (state: messageComposerState, { }) => {
    return {
      ...state,
      sending: true,
    }
  }),
  on(ScheduleMultipleMessagesSuccessAction, (state: messageComposerState, { payload }) => {
    return {
      ...state,
      sending: false,
      clearState: (state.clearState ?? 0) + 1,
      sentMessages: [...state.sentMessages, payload]
    }
  }),
  on(ScheduleMultipleMessagesFailAction, (state: messageComposerState) => {
    return {
      ...state,
      sending: false
    }
  }),
/* -------------------------- message reformulation ------------------------- */
  on(ReformulateMultipleMessagesContentAction, (state: messageComposerState) => {
    return {
      ...state,
      reformulateState: {
        ...state.reformulateState,
        reformulating: true
      }
    }
  }),
  on(ReformulateMultipleMessagesContentResponseAction, (state: messageComposerState, { payload }) => {
    return {
      ...state,
      reformulateState: {
        reformulating: false,
        content: payload.res?.new_text,
        originalText: payload.res?.original_text
      }
    }
  }),
  on(ResetReformulateMultipleMessagesContentAction, (state: messageComposerState) => {
    return {
      ...state,
      reformulateState: { }
    }
  }),

  on(SignOutAction, (state: messageComposerState) => {
    return {
      ...state
    }
  })
);
