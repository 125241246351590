import { Injectable } from '@angular/core';

import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { HttpService } from '@/app/Data/services/Networking/HttpService';

import { CreateProductDto } from '../DTO/productDto';

import { SuccessApiResponse } from '@/app/Data/services/Networking/ApiResponse';
import { HttpRequestMethod } from '@/app/Data/services/Networking/HttpRequestMethod';
import { IProductRepository } from '@/app/core/IRepositories/IProductRepository';
import { Product, ProductsQueryParams } from '@/app/core/Models/product';

import { environment } from '@/environments/environment';

import { liveQuery } from 'dexie';
import { from, map, Observable, pluck } from 'rxjs';

@Injectable()
export class ProductRepository implements IProductRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) { }

  createProduct(data: CreateProductDto): Observable<Product> {
    const requestURL = `${environment.apiURL}product`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Product>;
        return res.results;
      })
    );
  }

  editProduct(productId: string, data: CreateProductDto): Observable<Product> {
    const requestURL = `${environment.apiURL}product/${productId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        const res = item as SuccessApiResponse<Product>;
        return res.results;
      })
    );
  }

  deleteProduct(productIds: string[]): Observable<Product> {
    const requestURL = `${environment.apiURL}product`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      { "yobi_crm_product_id": productIds },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        const res = item as SuccessApiResponse<Product>;
        return res.results;
      })
    );
  }

  getProducts(params: ProductsQueryParams): Observable<{ products: Product[], total_count: number }> {
    const requestURL = `${environment.apiURL}product?page=${params.page}&page_size=${params.page_size}&column=${params.column}&direction=${params.direction}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      params
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        const res = item as SuccessApiResponse<{ products: Product[], total_count: number }>;
        return res.results
      })
    );
  }

  getProductById(productId: string): Observable<Product> {
    const requestURL = `${environment.apiURL}product/${productId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        const res = item as SuccessApiResponse<Product>;
        return res.results;
      })
    );
  }

  /* ---------------------------------- LOCAL --------------------------------- */

  saveProducts(products: Product[], params: ProductsQueryParams): void {
    this.databaseService
      .transaction('rw!', this.databaseService.products, async () => {
        params.page == 1 && await this.databaseService.products.clear();
        await this.databaseService.products.bulkPut(products);
      })
      .catch((error) => {
        console.error('Transaction Failed: ', error);
      });
  }

  getLocalProducts(): Observable<Product[]> {
    return from(
      liveQuery(() => {
        this.databaseService.products.mapToClass(Product);
        return this.databaseService.products.toArray()
      })
    );
  }

  getLocalProduct(productId: string): Observable<Product | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.products,
          async () => {
            this.databaseService.products.mapToClass(Product);
            return this.databaseService.products.get(productId as unknown as string)
          }
        );
      })
    );
  }

  saveProduct(product: Product): void {
    this.databaseService.products.put(product);
  }

  deleteLocalProduct(productId: string): void {
    this.databaseService.products.delete(productId)
  }
}
