import {
    CreateSynthConversationDto,
    UpdateSynthConversationDto,
    CreateSynthInteraction,
    UpdateSynthInteraction
} from "@/app/Data/DTO/synthDto";
import { Observable, Observer } from "rxjs";
import {
    Synth,
    SynthConversation,
    SynthRecentConversation,
    SynthInteraction,
    RecentSynthConversationsQueryParams,
    SynthConversationInteractionsQuery,
    CreateSynthAgentDto,
    SynthChannel,
    SynthAgent,
    SynthTrainerParams,
    SynthAgentScript,
    ITemplateEngine,
    IYobiOneMetadata,
    SynthVersionHistory,
    SynthVersionHistoryResponse
} from "../Models/synth";

export abstract class ISynthRepository {

    abstract getSynths(params: SynthTrainerParams): Observable<Synth[]>;

    abstract createSynthConversation(data: CreateSynthConversationDto): Observable<SynthConversation>;

    abstract updateSynthConversation(data: UpdateSynthConversationDto, synth_conversation_id: number | string): Observable<SynthConversation>;

    abstract getSynthConversation(): Observable<SynthConversation>;

    abstract getSynthRecentConversations(query: RecentSynthConversationsQueryParams): Observable<SynthRecentConversation[]>;

    abstract getSynthInteractionsByConversation(query: SynthConversationInteractionsQuery): Observable<SynthInteraction[]>;

    abstract createSynthInteraction(data: CreateSynthInteraction): Observable<SynthInteraction>;

    abstract updateSynthInteraction(data: UpdateSynthInteraction, synth_interaction_id: number | string): Observable<SynthInteraction>;

    abstract uploadKnowledgeBaseFiles(data: FormData, progress$?: Observer<any>): Observable<any>;

    abstract createSynthAgent(data: CreateSynthAgentDto): Observable<Synth>;

    abstract getSynthChannels(synth_id: number): Observable<SynthChannel[]>;

    abstract linkSynth(synth_id: number, channel_id: string): Observable<any>;

    abstract unLinkSynth(synth_id: number, channel_id: string): Observable<any>;

    abstract getSynthTrainer(): Observable<SynthAgent[]>;

    abstract getSynthDetails(synth_id: number): Observable<Synth>;

    abstract updateSynthAgent(data: CreateSynthAgentDto, synth_id: number): Observable<Synth>;

    abstract getSynthAgentScript(synth_id: number): Observable<SynthAgentScript>;

    abstract createCallSynthAgent(retell_agent_id: string, conversation_id: number): Observable<{ access_token: string }>;

    abstract saveSynthsLocal(synth: Synth[], params: SynthTrainerParams): void;

    abstract getLocalSynths(): Observable<Synth[]>;

    abstract getLocalSynth(synth_id: number): Observable<Synth | undefined>;

    abstract updateLocalSythDetails(synth_id: string, details: any): void;

    abstract getLocalSynthTemplateEngine(synth_id: number): Observable<ITemplateEngine | undefined>;

    abstract generateTranscriptsFeedback(synth_id: number, transcript: string, synth_conversation_id: number): Observable<any>;

    abstract applyTranscriptsFeedback(synth_conversation_id: number, synth_agent_id: number, transcript: string, changes: Array<{ type: string, description: string }>, feedback_id: number): Observable<any>;

    abstract deleteSynthAgent(ids: number[]): Observable<number[]>;

    abstract deleteSynthLocal(ids: number[]): void;

    abstract callYobiOne(retell_agent_id: string, metadata: IYobiOneMetadata): Observable<{ access_token: string, call_id: string }>;

    abstract callYobiOneTeamChat(retell_agent_id: string): Observable<{ access_token: string, call_id: string }>;

    abstract callOnboardingAgent(retell_agent_id: string, metadata?: any): Observable<{ access_token: string, call_id: string }>;

    abstract getShareLink(retellAgentId: string): Observable<{ token: string }>;

    abstract getSynthVersionControl(synth_agent_id: number, page: number, page_size: number): Observable<SynthVersionHistoryResponse>;

    abstract getLocalSynthVersionHisory(synth_id: number): Observable<SynthVersionHistory[] | undefined>;

}