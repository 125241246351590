import {
  Interaction,
  InteractionStatus,
  InteractionThread,
  RecentInteraction,
  RecentMessagesQuery,
} from '@/app/core/Models/Interaction';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { from, last, map, Observable, Observer, pluck, tap } from 'rxjs';
import { DatabaseService } from '../services/Database/database.service';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';
import { IInteractionRepository } from '@/app/core/IRepositories/IInteractionRepository';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { TaskStatus } from '@/app/core/Models/Task';
import { InteractionClassificationQueryDto, SynthInteractionFeadbackDto } from '../DTO/InteractionDto';
import { InteractionClassification } from '@/app/core/Models/interactionClassification';
import { liveQuery } from 'dexie';
import { AddInteractionComment } from '../DTO/Interaction/addInteractionCommentDto';
import { InteractionComment } from '@/app/core/Models/Interaction/InteractionComment';
import { InteractionPinDto } from '../DTO/InteractionPinDto';

@Injectable({
  providedIn: 'root',
})
export class InteractionRepository implements IInteractionRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) { }
  updateComment(commentId: string | number, content: string): Observable<InteractionComment> {
    const requestURL = `${environment.apiURL}interaction-comments/${commentId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      { content },
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessApiResponse<any>).results)
    );
  }


  deleteComment(commentId: string | number): Observable<InteractionComment> {
    const requestURL = `${environment.apiURL}interaction-comments/${commentId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessApiResponse<any>).results)
    );
  }

  getInteractionComments(interactionId: string | number): Observable<InteractionComment[]> {
    const requestURL = `${environment.apiURL}interaction-comments?interaction_id=${interactionId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<InteractionComment[]>;
        return res.results.map(item => Object.assign(new InteractionComment(), item));
      })
    );
  }

  addInteractionComment(request: AddInteractionComment): Observable<InteractionComment> {
    const requestURL = `${environment.apiURL}interaction-comments`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<InteractionComment>;
        return res.results;
      })
    );
  }


  archiveInteraction(channelId: number): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}channels/${channelId}/archive`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  unArchiveInteraction(channelId: number): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}channels/${channelId}/unarchive`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }
  unPinInteraction(payload: InteractionPinDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}pin/channel/${payload.channelId}?scope=${payload.scope}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  pinInteraction(payload: InteractionPinDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}pin/channel`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      {
        channel_id: payload.channelId,
        scope: payload.scope
      },
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  updateStatus(
    interactionsIds: number[],
    status: InteractionStatus
  ): Observable<SuccessResponse> {
    const paramString =
      'interaction_id=' + interactionsIds.join('&interaction_id=');
    const requestURL = `${environment.apiURL}interactions/status?${paramString}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.patch,
      this.httpService.createHeader(),
      requestURL,
      { status },
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  updateLocalInteractionsStatus(
    interactionsIds: number[],
    status: InteractionStatus
  ): void {
    this.databaseService.transaction(
      'rw!',
      [
        this.databaseService.recentInteractions,
        this.databaseService.threadInteractions,
      ],
      () => {
        this.databaseService.recentInteractions
          .where('interaction.interaction_id')
          .anyOf(interactionsIds)
          .modify((item: RecentInteraction) => {
            item.interaction.status = status;
          })
          .catch((err) => {
            console.error('error updating status ', err);
          });
        this.databaseService.threadInteractions
          .where('interaction_id')
          .anyOf(interactionsIds)
          .modify((item: InteractionThread) => {
            item.status = status;
          })
          .catch((err) => {
            console.log('error updating status ', err);
          });
      }
    );
  }

  updateLocalTaskInteractionStatus(
    interactionsId: number,
    status: TaskStatus
  ): void {
    this.databaseService.transaction(
      'rw!',
      this.databaseService.threadInteractions,
      () => {
        this.databaseService.threadInteractions
          .where('interaction_id')
          .equals(interactionsId)
          .modify((item: InteractionThread) => {
            let body = JSON.parse(item.text_body);
            body[0].status = status
            item.text_body = JSON.stringify(body)
          })
          .catch((err) => {
            console.error('error updating status ', err);
          });
      }
    );
  }

  archiveLocalInteraction(channelId: number): void {
    this.databaseService.recentInteractions
      .where('channel_id')
      .equals(channelId)
      .modify({ is_archived: true, is_blocked: false });
  }

  unArchiveLocalInteraction(channelId: number): void {
    this.databaseService.recentInteractions
      .where('channel_id')
      .equals(channelId)
      .modify({ is_archived: false, is_blocked: false });
  }
  pinLocalInteraction(channelId: number): void {
    this.databaseService.recentInteractions
      .where('channel_id')
      .equals(channelId)
      .modify({ is_pinned: true });
  }

  unPinLocalInteraction(channelId: number): void {
    this.databaseService.recentInteractions
      .where('channel_id')
      .equals(channelId)
      .modify({ is_pinned: false });
  }

  deleteThreadInteraction(
    interaction_id: number
  ): Observable<SuccessResponse<string>> {
    const requestURL = `${environment.apiURL}interaction/${interaction_id}/soft`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => res as SuccessResponse<string>)
    );
  }

  sendSynthInteractionFeedback(
    request: SynthInteractionFeadbackDto
  ): Observable<SuccessResponse<string>> {
    const requestURL = `${environment.apiURL}synth/feedback`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => res as SuccessResponse<string>)
    );
  }

  deleteCallRecording(
    interaction_id: number
  ): Observable<SuccessResponse<string>> {
    const requestURL = `${environment.apiURL}interaction/${interaction_id}/media`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => res as SuccessResponse<string>)
    );
  }

  private getEventProgress(
    event: HttpEvent<any>,
    progress$: Observer<any>
  ): any {
    if (event.type == HttpEventType.Sent) {
      progress$.next(0);
    } else if (event.type == HttpEventType.UploadProgress) {
      const percentDone = Math.round((100 * event.loaded) / (event.total ?? 0));
      progress$.next(percentDone);
    }
  }

  downloadCallRecording(
    interaction_id: number,
    progress$?: Observer<any>
  ): Observable<string> {
    const requestURL = `${environment.apiURL}download_audio/interaction/${interaction_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      null,
      true
    );
    return this.httpService.execute(options).pipe(
      tap((event: any) => progress$ && this.getEventProgress(event, progress$)),
      last(),
      pluck('results'),
      pluck('body'),
      map((res) => (res as SuccessResponse<string>).results)
    );
  }

  updateLocalThreadInteraction(interaction: InteractionThread): void {
    this.databaseService.threadInteractions?.mapToClass(InteractionThread);
    this.databaseService.threadInteractions.put(interaction);
  }

  getInteractionClassification(data: InteractionClassificationQueryDto): Observable<InteractionClassification> {
    const requestURL = `${environment.apiURL}get_interaction_info`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      tap((i) => console.log({ i })),
      map((res) => (res as SuccessResponse<InteractionClassification>).results)
    );
  }

  saveInteractionClassification(interaction: InteractionClassification): void {
    this.databaseService.interactionClassification
      .put(interaction)
      .catch(console.log);
  }

  getLocalInteractionClassificationById(interaction_id: number): Observable<InteractionClassification | undefined> {
    return from(
      liveQuery(() => {
        this.databaseService.interactionClassification.mapToClass(InteractionClassification);
        let query = this.databaseService.interactionClassification
          .where('interaction_id')
          .equals(interaction_id)
        return query.first();
      })
    );
  }

  getLocalInteractionClassification(): Observable<InteractionClassification[]> {
    return from(
      liveQuery(() => {
        this.databaseService.interactionClassification.mapToClass(InteractionClassification);
        return this.databaseService.interactionClassification.toArray()
      })
    );
  }

  getLocalThreadInteraction(interaction_id: string | number): Observable<InteractionThread | undefined> {
    return from(
      liveQuery(() => {
        this.databaseService.threadInteractions.mapToClass(InteractionThread);
        let query = this.databaseService.threadInteractions
          .where('interaction_id')
          .equals(interaction_id)
        return query.first();
      })
    );
  }
}
