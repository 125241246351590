import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { VerificationCodeDto } from '@/app/Data/DTO/VerificationCodeDto';
import { Inject, Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { IAuthenticationRepository } from '../../IRepositories/IAuthenticationRepository';
import { UseCase } from '../UseCase';
import { ILoginWithCredentialsResponse } from '@/app/Data/DTO/LoginResponse';
import { Tenant } from '../../Models/tenant';
import { ITenantRepository } from '../../IRepositories/ITenantRepository';
import { TokenStorgeService } from '@/app/Data/services/Token/token.service';

@Injectable({
  providedIn: 'root',
})
export class ValidateVerificationCodeUseCase
  implements UseCase<VerificationCodeDto, Tenant>
{
  constructor(
    private authRepository: IAuthenticationRepository,
    private tenantRepository: ITenantRepository,
    private tokenStorgeService: TokenStorgeService,
  ) { }

  async execute(request: VerificationCodeDto): Promise<Tenant> {
    const credential = await lastValueFrom(
      this.authRepository.validateVerificationCode(request)
    )
    // await this.databaseService.init();
    this.tokenStorgeService.saveToken(credential.results.token)
    this.tokenStorgeService.saveRefreshToken(credential.results.refreshToken)
    const user = await lastValueFrom(
      this.authRepository.getUserObject()
    );
    this.tokenStorgeService.saveUser(user)
    this.authRepository.saveTenantProfileObject(user);
    const channels = await lastValueFrom(
      this.tenantRepository.getInternalChannels()
    );
    this.tenantRepository.saveInternalChannels(channels);
    return user;
  }

}
