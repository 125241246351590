import { map, Observable, pluck } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@/environments/environment';
import { HttpService } from '../services/Networking/HttpService';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { IOnboardingRepository } from '@/app/core/IRepositories/IOnboardingRepository';
import { OnboardingQuestionsSignature } from '@/app/core/Models/Onboarding';
import { OnboardingQuestionAnswerBody } from '../DTO/OnboardingDto';
import { BusinessInfoDto, BusinessNameFieldDto, OnboardingBusinessInfoDto } from '../DTO/authDto';
import { SuccessResponse } from '../DTO/successResponse';

@Injectable({
  providedIn: 'root',
})

export class OnboardingRepository implements IOnboardingRepository {

  constructor(private httpService: HttpService) { }

  checkBusinessNameExisting(
    request: BusinessNameFieldDto
  ): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}registration/v2/tenant/validation/business_name`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  updateBusinessAndFullName(
    request: BusinessInfoDto
  ): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}onboarding/business_info`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  getQuestions(): Observable<OnboardingQuestionsSignature[]> {
    const requestURL = `${environment.apiURL}onboarding/questions`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<OnboardingQuestionsSignature[]>;
        return res.results;
      })
    );
  }

  answerQuestion(request: OnboardingQuestionAnswerBody): Observable<SuccessApiResponse<any>> {
    const requestURL = `${environment.apiURL}onboarding/questions_answer`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res;
      })
    );
  }

  getOnboardingInteraction(): Observable<SuccessApiResponse<any>> {
    const requestURL = `${environment.apiURL}get_onboarding_interaction`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res;
      })
    );
  }
  postOnboardingBusinessInfo(request: OnboardingBusinessInfoDto): Observable<SuccessApiResponse<any>> {
    const requestURL = `${environment.apiURL}onboarding/business-info`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res;
      })
    );
  }

  completeOnboarding(): Observable<SuccessApiResponse<any>> {
    const requestURL = `${environment.apiURL}onboarding/complete_onboarding`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res;
      })
    );
  }
}
