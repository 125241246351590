import { ITasksRepository } from '@/app/core/IRepositories/ITasksRepository';
import { Task, TaskStatus } from '@/app/core/Models/Task';
import { TasksListByChannelIdDto } from '@/app/Data/DTO/TasksListDto';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from '../UseCase';

@Injectable({
  providedIn: 'root',
})
export class GetTasksByChannelIdUseCase
  implements UseCase<TasksListByChannelIdDto, Task[]>
{
  constructor(
    private tasksRepository: ITasksRepository,
  ) { }
  execute({ filter, status, channelId }: TasksListByChannelIdDto): Observable<Task[]> {
    return this.tasksRepository.getTasksByChannelId(filter, status, channelId)
  }
}
