import { createReducer, on } from '@ngrx/store';

import { SignOutAction } from '@/app/State/app/action';
import { User } from '@/app/core/Models/User';
import { Inbox } from '@/app/core/Models/inbox';
import { FetchEmailClassificationsSuccessAction, FetchEmailInboxesSuccessAction, FetchEmailInteractionsAction, FetchEmailInteractionsFailAction, FetchEmailInteractionsSuccessAction, FetchRecentEmailsAction, FetchRecentEmailsFailAction, FetchRecentEmailsSuccessAction, SelectEmailClassificationAction, SelectEmailInboxAction } from './email-inbox.action';
import { ThreadEmailInteraction } from '@/app/core/Models/email-interaction';


export interface EmailInboxState {
  inboxes: Inbox[]
  selectedInbox?: Inbox;
  fetching?: boolean;
  usersTyping: {
    conversationId: number,
    user: User
  }[],
  classifications: {
    classification: string;
    emailClassificationId: number;
    unreadCount: number;
  }[],
  recentEmailsState?: {
    emailClassificationId: number;
    page?: number;
    pageEnd: boolean
  },
  activeConversationState?: {
    fetching?: boolean;
    conversationId?: number,
    page?: number,
    pageEnd?: boolean,
    pendingConversations: ThreadEmailInteraction[]
  }
}

export const initEmailInboxState: EmailInboxState = {
  inboxes: [],
  classifications: [],
  usersTyping: [],
};

export const emailInboxReducer = createReducer(
  initEmailInboxState,

  on(FetchEmailInboxesSuccessAction, (state: EmailInboxState, { payload: { inboxes } }) => {
    return {
      ...state,
      inboxes,
    };
  }),

  on(FetchEmailClassificationsSuccessAction, (state: EmailInboxState, { payload }) => {
    const selectedClassificationId = payload.email_classifications.find(c => c.email_classification_id === state.recentEmailsState?.emailClassificationId as any)?.email_classification_id
    return {
      ...state,
      classifications: payload.email_classifications.map(c => ({
        emailClassificationId: c.email_classification_id,
        classification: c.classification,
        unreadCount: c.unread_count,
      })),
      recentEmailsState: selectedClassificationId ? {
        pageEnd: false,
        emailClassificationId: selectedClassificationId
      } : undefined
    };
  }),
  on(SelectEmailClassificationAction, (state: EmailInboxState, { payload: { classificationId } }) => {
    return {
      ...state,
      recentEmailsState: {
        page: 1,
        pageEnd: false,
        emailClassificationId: classificationId,
      }
    };
  }),
  /* ----------------------------------- --- ---------------------------------- */
  on(FetchRecentEmailsAction, (state: EmailInboxState, { payload: { query } }) => {
    return {
      ...state,
      fetching: true,
    };
  }),
  on(FetchRecentEmailsSuccessAction, (state: EmailInboxState, { payload: { query: { classification_id, page, page_size }, recentEmails } }) => {
    return {
      ...state,
      fetching: false,
      recentEmailsState: {
        page,
        emailClassificationId: classification_id,
        pageEnd: recentEmails.length < page_size,
      }
    };
  }),
  on(FetchRecentEmailsFailAction, (state: EmailInboxState, { payload: { } }) => {
    return {
      ...state,
      fetching: false,
    };
  }),

  on(SelectEmailInboxAction, (state: EmailInboxState, { payload: { inbox } }) => {
    return {
      ...state,
      selectedInbox: inbox,
      classifications: [],
      recentEmailsState: undefined
    };
  }),

  /* ----------------------------------- --- ---------------------------------- */
  on(FetchEmailInteractionsAction, (state: EmailInboxState, { payload }) => {
    return {
      ...state,
      activeConversationState: {
        ...state.activeConversationState,
        fetching: true,
        pendingConversations: []
      }
    };
  }),

  on(FetchEmailInteractionsSuccessAction, (state: EmailInboxState, { payload: { query: { conversation_id, page, page_size }, threadEmails } }) => {
    return {
      ...state,
      fetching: false,
      activeConversationState: {
        conversationId: conversation_id,
        fetching: false,
        page,
        pageEnd: threadEmails.length < page_size,
        pendingConversations: []
      }
    };
  }),
  on(FetchEmailInteractionsFailAction, (state: EmailInboxState, { payload }) => {
    return {
      ...state,
      activeConversationState: {
        ...state.activeConversationState,
        fetching: false,
        pendingConversations: []
      }
    };
  }),

  /* ----------------------------------- --- ---------------------------------- */
  on(SignOutAction, (state: EmailInboxState) => {
    return {
      ...initEmailInboxState,
    };
  }),

);
