import { map, Observable, pluck } from 'rxjs';
import { Notification } from '@/app/core/Models/Notification';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { NotificationsSettingDto } from '@/app/Data/DTO/NotificationsSettingDto';

export abstract class INotificationRepository {
  abstract getNotifications(page: number): Observable<Notification[]>;
  abstract getUnreadNotificationsCount(): Observable<number>;
  abstract setNotificationReadStatus(id: number): Observable<SuccessResponse>;
  abstract updateNotificationsSetting(
    data: NotificationsSettingDto
  ): Observable<SuccessResponse>;
  abstract saveNotificationsLocally(list: Notification[]): void;
  abstract getCachedNotifications(): Observable<Notification[]>;
  abstract dismissNotifications(): Observable<SuccessResponse>;
  abstract registerFCMToken(token: string): Observable<SuccessResponse>
  abstract unRegisterFCMToken(token: string, tenantId: number): Observable<SuccessResponse>
}
