import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IAuthenticationRepository } from '../../IRepositories/IAuthenticationRepository';
import { Tenant } from '../../Models/tenant';
import { UseCase } from '../UseCase';
import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { ITenantRepository } from '@/app/core/IRepositories/ITenantRepository';
import { TokenStorgeService } from '@/app/Data/services/Token/token.service';
@Injectable({
  providedIn: 'root',
})
export class LoginWithTokensUseCase
  implements UseCase<{ yib_tk: string; yib_rt: string }, Tenant>
{
  constructor(
    private authRepository: IAuthenticationRepository,
    private tenantRepository: ITenantRepository,
    private tokenStorgeService: TokenStorgeService,
    private databaseService: DatabaseService
  ) { }

  async execute(tokens: { yib_tk: string; yib_rt: string }): Promise<Tenant> {
    this.tokenStorgeService.saveToken(tokens.yib_tk)
    this.tokenStorgeService.saveRefreshToken(tokens.yib_rt)
    const user = await lastValueFrom(
      this.authRepository.getUserObject()
    );
    this.tokenStorgeService.saveUser(user)
    this.authRepository.saveTenantProfileObject(user);
    this.databaseService.tenants.put(user);
    const channels = await lastValueFrom(
      this.tenantRepository.getInternalChannels()
    );
    this.tenantRepository.saveInternalChannels(channels);
    return user;
  }
}
