import { createReducer, on } from '@ngrx/store';
import { InviteUserAction, InviteUserFailedAction, InviteUserSuccessAction, InvitationsListLoadedAction, TeamMemberOnlineEventAction, UpdateUserAction, UpdateUserFailedAction, UpdateUserSuccessAction } from './action';
import { TeamMemberOnlineEventDto } from '@/app/Data/DTO/Team/TeamMemberOnlineEventDto';
import { UserInvitation } from '@/app/core/Models/User';

export interface UsersState {
  invitations: UserInvitation[];
  onlineMembers: TeamMemberOnlineEventDto[];
  creatingUser: boolean;
  updatingUser: boolean;
}

export const initUsersState: UsersState = {
  invitations: [],
  onlineMembers: [],
  creatingUser: false,
  updatingUser:false
};

export const usersReducer = createReducer(
  initUsersState,
  on(TeamMemberOnlineEventAction, (state: UsersState, { payload }) => {
    return {
      ...state,
      onlineMembers: state.onlineMembers
        .filter((obj) => obj?.user_id !== payload?.user_id)
        .concat(payload),
    };
  }),
  /* ---------------------------- invitatios loaded --------------------------- */
  on(InvitationsListLoadedAction, (state: UsersState, { payload }) => {
    return {
      ...state,
      invitations: payload 
    };
  }),
  /* ------------------------------- create user ------------------------------ */
  on(InviteUserAction, (state: UsersState, { payload }) => {
    return {
      ...state,
      creatingUser: true
    };
  }),
  on(InviteUserSuccessAction, (state: UsersState) => {
    return {
      ...state,
      creatingUser: false
    };
  }),
  on(InviteUserFailedAction, (state: UsersState) => {
    return {
      ...state,
      creatingUser: false
    };
  }),
  /* ------------------------------- update user ------------------------------ */
  on(UpdateUserAction, (state: UsersState, { payload }) => {
    return {
      ...state,
      creatingUser: true
    };
  }),
  on(UpdateUserSuccessAction, (state: UsersState) => {
    return {
      ...state,
      creatingUser: false
    };
  }),
  on(UpdateUserFailedAction, (state: UsersState) => {
    return {
      ...state,
      creatingUser: false
    };
  }),
);

