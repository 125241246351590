import { Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '@/environments/environment';
import { ISocketClient } from '@/app/Data/Adapters/ISocketClient';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SocketService extends Socket implements ISocketClient {
  constructor() {
    super({
      url: environment.socketURL + '/alerts',
      options: { autoConnect: false, withCredentials: true},
    });
  }

  send(eventName: string, ...args: any[]): void {
    this.emit(eventName, args);
  }

  onReceive<T>(data: string): Observable<T> {
    return this.fromEvent(data);
  }

  connectSocket({ token, device }: { token: string; device: string }) {
    this.ioSocket.close();
    this.ioSocket.io.url = environment.socketURL + '/alerts';
    this.ioSocket.io.opts.query = { token, device };
    this.ioSocket.io.opts.withCredentials = true;
    this.ioSocket.io.opts.autoConnect = true;
    this.ioSocket.io.opts.reconnection = true;
    this.ioSocket.io.opts.reconnectionDelayMax = 10000;
    this.ioSocket.io.opts.transports = ['websocket', 'polling'];
    this.connect();
  }

  destroy(): void {
    this.ioSocket.close();
  }
}
