import { LocalizationViewModel } from '@/app/Ui/ViewModels/localizationViewModel';
import { Tag } from '@/app/core/Models/tag';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { TagsViewModel } from '../../ViewModels/tagsViewModel';

@Component({
  selector: 'app-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.scss']
})
export class AddTagComponent {
  tagForm: FormGroup;
  loading = false;
  colors = [
    { value: "#d4cb24", active: false },
    { value: "#e0632e", active: false },
    { value: "#00f2c9", active: false },
    { value: "#20c423", active: false },
    { value: "#446eef", active: false },
    { value: "#9b3fe0", active: true }
  ];
  subject = new BehaviorSubject<any>(null);
  localization = this.localiztionViewModel.localization

  constructor(
    private localiztionViewModel: LocalizationViewModel,
    private tagsViewModel: TagsViewModel,
    private dialogRef: MatDialogRef<AddTagComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      contactId: number | null,
      channelId: number | null,
      tags: Tag[],
      tag_name: string
    },
  ) {
    this.initForm()
  }

  initForm(): void {
    this.tagForm = this.fb.group({
      tag_name: new FormControl(this.data.tag_name ?? '', [Validators.required]),
      description: new FormControl(''),
      color: new FormControl(this.colors.find(c => c.active)?.value, [Validators.required]),
    });
  }

  selectColor(value: string): void {
    this.tagForm.get('color')!.patchValue(value)
    this.colors.forEach((color, index) => {
      color.active = value == color.value;
    })
  }

  pickColor(event: Event) {
    const value = (event.target as HTMLInputElement).value
    this.tagForm.get('color')!.patchValue(value)
    this.colors.forEach((color, index) => {
      color.active = false;
    })
    this.colors.push({
      active: true, value
    })
  }

  setFromTenantTags(tag: Tag): void {
    this.tagForm.patchValue({
      tag_name: tag.tag_name,
      description: tag.description ?? '',
      color: tag.color?.startsWith('#') ? tag.color : ''
    })
    this.save();
  }

  save(): void {
    this.tagForm.markAllAsTouched();
    if (this.tagForm.invalid || this.loading) return;
    this.loading = true;
    let data = this.tagForm.value;
    this.data.contactId ? this.tagsViewModel.createContactTag([data], this.data.contactId)
      : this.data.channelId ? this.tagsViewModel.createUnclassifiedChannelTag([data], this.data.channelId!)
        : this.tagsViewModel.createTag(data);
    this.close(true)
  }

  close(state: boolean) {
    this.dialogRef.close(state)
  }
}
