<div class="flex flex-col gap-5">
   <div><b>{{localization.subscription.almost_there | translate}}!</b></div>
   <div>
     {{ (
        isTrial 
          ? (isDesktop ? localization.subscription.trial_over_desktop: localization.subscription.trial_over_web) 
          : (isDesktop ? localization.subscription.subscription_over_desktop: localization.subscription.subscription_over_web)
      ) | translate
    }}</div>
   <div class="flex justify-end items-center">
        <div *ngIf="!isMacApp; else logoutRef" class="bg-primary py-2 px-5 text-white cursor-pointer shadow-sm hover:shadow-lg" (click)="openBilling()">
            {{ (isDesktop ? localization.labels.visit : localization.labels.upgrade)| translate}}
        </div> 
        <ng-template #logoutRef>
          <div   class="bg-primary py-2 px-5 text-white cursor-pointer shadow-sm hover:shadow-lg" (click)="logout()">
            {{ ( localization.labels.logout)| translate}}
        </div> 
        </ng-template>
   </div>
</div>