import { VoiceClientCallTransferRequestDto } from '@/app/Data/DTO/VoiceClient/VoiceClientCallTransferRequestDto';
import { map, Observable, pluck } from 'rxjs';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { HttpService } from '@/app/Data/services/Networking/HttpService';
import { environment } from '@/environments/environment';
import { HttpRequestMethod } from '@/app/Data/services/Networking/HttpRequestMethod';
import { SuccessApiResponse } from '@/app/Data/services/Networking/ApiResponse';
import { Injectable } from '@angular/core';
import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { IVoiceClientRepository } from '@/app/core/IRepositories/IVoiceClientRepository';
import { VoiceClientTokenDto } from '@/app/Data/DTO/VoiceClientTokenDto';
import { VoiceClientSetUserOnHoldDto } from '../DTO/VoiceClient/VoiceClientSetUserOnHoldDto';
import { VoiceClientTransferToUserDto } from '../DTO/VoiceClient/VoiceClientTransferToUserDto';
import { VoiceClientWarmTranferDto } from '../DTO/VoiceClient/VoiceClientWarmTranferDto';
import { VoiceClientDropContactDto } from '../DTO/VoiceClient/VoiceClientDropContactDto';
import { VoiceClientHoldContactDto } from '../DTO/VoiceClient/VoiceClientHoldContactDto';
import { VoiceClientCallSidDto } from '../DTO/VoiceClient/VoiceClientCallSidDto';
import { VoiceClientDropMemberParticipantDto } from '../DTO/VoiceClient/VoiceClientDropUserDto';

@Injectable()
export class VoiceClientRepository extends IVoiceClientRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) {
    super();
  }
  /*
  saveTaskDetail(request: TaskDetail): void {
    this.databaseService.transaction('rw!', this.databaseService.tasks, async () => {
      const task  = await  this.databaseService.tasks.get(request.task_id);
      const savedTask = {...task, ...request};
      this.databaseService.tasks.put(savedTask);
    })
  }
*/
  getTwilioToken(): Observable<VoiceClientTokenDto> {
    const requestURL = `${environment.apiURL}twilio/token`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<VoiceClientTokenDto>;
        return res.results;
      })
    );
  }

  warmTransfer(
    request: VoiceClientWarmTranferDto
  ): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}webhooks/call/transfer/warm_transfer`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }
  exitConference(request: VoiceClientCallSidDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}webhooks/call/transfer/hangup-all`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }
  setUserOnHold(
    request: VoiceClientSetUserOnHoldDto
  ): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}webhooks/call/transfer/hold`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request.body(),
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  setContactOnHold(
    request: VoiceClientHoldContactDto
  ): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}webhooks/call/transfer/hold`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request.body,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }
  dropUser(
    request: VoiceClientDropMemberParticipantDto
  ): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}webhooks/call/transfer/hangup`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request.body(),
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  dropContact(request: VoiceClientDropContactDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}webhooks/call/transfer/hangup`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  transferToUser(
    request: VoiceClientCallTransferRequestDto
  ): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}webhooks/call/transfer/make_call`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  mergeCall(request: VoiceClientCallSidDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}webhooks/call/transfer/merge`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  transferCall(
    request: VoiceClientTransferToUserDto
  ): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}webhooks/call/transfer/warm_transfer`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }
}
