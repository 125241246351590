import { CreateProductDto } from "@/app/Data/DTO/productDto";

import { Observable } from "rxjs";
import { Product, ProductsQueryParams } from "../Models/product";

export abstract class IProductRepository {
    abstract createProduct(data: CreateProductDto): Observable<Product>;
    abstract editProduct(productId: string, data: CreateProductDto): Observable<Product>;
    abstract deleteProduct(productIds: string[]): Observable<Product>;
    abstract getProducts(params: ProductsQueryParams): Observable<{ products: Product[], total_count: number }>;
    abstract getProductById(productId: string): Observable<Product>;
    abstract saveProducts(products: Product[], params: ProductsQueryParams): void;
    abstract getLocalProducts(): Observable<Product[]>;
    abstract getLocalProduct(productId: string): Observable<Product | undefined>;
    abstract saveProduct(product: Product): void;
    abstract deleteLocalProduct(productId: string): void;

}