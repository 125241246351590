import { createAction, props } from '@ngrx/store';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import {
  DeleteNoteContactDto,
  DeleteNoteUnclassifiedChannelDto,
} from '@/app/Data/DTO/DeleteNoteDto';

export const DELETE_CONTACT_NOTE = '[NOTES] Delete Contact Note';
export const DeleteContactNoteAction = createAction(
  DELETE_CONTACT_NOTE,
  props<{ payload: DeleteNoteContactDto }>()
);

export const DELETE_UNCLASSIFIED_CHANNEL_NOTE =
  '[NOTES] Delete Unclassified Channel Note';
export const DeleteUnclassifiedChannelNoteAction = createAction(
  DELETE_UNCLASSIFIED_CHANNEL_NOTE,
  props<{ payload: DeleteNoteUnclassifiedChannelDto }>()
);

export const NOTE_DELETED_SUCCESSFULLY = '[NOTES] Note Deleted Successfully';
export const NoteDeletedSuccessfullyAction = createAction(
  NOTE_DELETED_SUCCESSFULLY,
  props<{ payload: SuccessResponse }>()
);

export const NOTE_FAILED_TO_DELETE = '[NOTES] Note Delete Failed';
export const NoteDeleteFailedAction = createAction(
  NOTE_FAILED_TO_DELETE,
  props<{ payload: string }>()
);

export const GET_NOTES_BY_CONTACT_ID = '[NOTES] Get notes by contact ID';
export const getNotesByContactIdAction = createAction(
  GET_NOTES_BY_CONTACT_ID,
  props<{ payload:  {contact_id: number} }>()
);

export const GET_NOTES_BY_UNCLASSIFIED_CHANNEl_ID = '[NOTES] Get notes by unclassified channel ID';
export const getNotesByUnclassifiedChannelIdAction = createAction(
  GET_NOTES_BY_UNCLASSIFIED_CHANNEl_ID,
  props<{ payload:  {channel_id: number} }>()
);


