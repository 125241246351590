import { MentionMember } from '@/app/core/Models/Mention';
import { TaskComment } from '@/app/core/Models/Task';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../components/confirm-dialog/confirmation-dialog.component';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';
import { PlatformService } from '@/app/Utilities/platform/platform.service';

@Component({
  selector: 'app-task-comment',
  templateUrl: './task-comment.component.html',
  styleUrls: ['./task-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCommentComponent {
  @Input() comment: TaskComment;
  @Input('editableCommentId') set editableComment(commentId: number | any) {
    if (this.comment.comment_id == commentId) {
      this.edit = true;
    } else {
      this.edit = false;
    }
  }
  @Input() userId?: number;
  @Input() members: MentionMember[];
  @Output() onEdit = new EventEmitter<{
    comment: string;
    commentId: number;
  }>();
  @Output() onEditEnabled = new EventEmitter<{ commentId: number }>();
  @Output() onCommentDelete = new EventEmitter<{ commentId: number }>();
  @Output() onCommentResend = new EventEmitter<{ commentId: number }>();
  edit = false; commentControl = new FormControl('');
  localization = this.localiztionViewModel.localization

  constructor(
    public dialog: MatDialog,
    private localiztionViewModel: LocalizationViewModel,
    private platformService: PlatformService,
  ) { }

  isMac = this.platformService.isMac

  detectMention(text: any) {
    let currentText = text;
    this.members.forEach((user, i) => {
      let search = text.search(`@${user.name}`);
      if (search >= 0) {
        currentText = currentText.replace(`@${user.name}`, (mention: any) => {
          return `<span class="mentioned-user">@${user.name}</span>`;
        });
      }
    });
    return currentText;
  }

  get body(): ({ type: 'text', text: string } | { type: 'mention', user: string, id: number })[] | null {
    try {
      return JSON.parse(this.comment.comment)
    } catch {
      return null
    }
  }

  cancelEdit(): void {
    this.onEditEnabled.emit({ commentId: 0 });
  }

  saveEdit(): void {
    this.edit = false;
  }

  enableEdit(): void {
    let comment: string
    if (this.body) {
      comment = this.body.map(item => {
        switch (item.type) {
          case 'text':
            return item.text
          case 'mention':
            const member = this.members.find(m => m.id === item.id)
            return member ? `<@mention_${item.id}>` : item.user.replace(/\s+/ig, '_')
          default:
            return ''
        }
      }).join('');
      this.members.forEach(member => {
        comment = comment.replace(new RegExp(`<@mention_${member.id}>`, 'ig'), `@${member.name}`)
      })
    } else {
      comment = this.comment.comment
    }
    this.commentControl.setValue(comment);
    this.onEditEnabled.emit({ commentId: this.comment.comment_id });
  }

  editComment() {
    let comment = this.commentControl.value.trim()
    if (!comment) { return }
    this.members.forEach(member => {
      comment = comment.replace(new RegExp(`@${member.name}`, 'ig'), `<@mention_${member.id}>`)
    })
    this.onEdit.emit({
      comment,
      commentId: this.comment.comment_id,
    });
  }
  deleteComment(commentId: any) {
    this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
        message: this.localization.confirm.delete_comment,
        confirm: this.localization.labels.delete,
        warning: true
      }
    }).afterClosed().subscribe((res) => {
      res && this.onCommentDelete.emit({ commentId: this.comment.comment_id });
    });
  };
  resendComment() {
    this.onCommentResend.emit({ commentId: this.comment.comment_id })
  }
}
