<mat-progress-bar
  style="position: absolute; top: 0"
  *ngIf="showNavigationIndicator"
  color="accent"
  mode="indeterminate"
>
</mat-progress-bar>
<div
  class="overflow-hidden"
>
  <div class="h-screen w-screen flex flex-col">
    <app-update-notification
      *ngIf="isNewUpdate$ | async"
      (onUpdate)="forceUpdate()"
    ></app-update-notification>
    <router-outlet></router-outlet>
  </div>
</div>
<app-tour-control></app-tour-control>