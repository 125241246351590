import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/app/State/AppState';
import { DialogService } from '@/app/Utilities/Dialog/dialog.service';
import { TaskDetail } from '@/app/core/Models/Task';
import {
  BehaviorSubject,
  exhaustMap,
  filter,
  Observable,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { selectTenantProfile } from '@/app/State/Tenant/tenant.selector';
import { IUsersRepository } from '@/app/core/IRepositories/IUsersRepository';
import { ITaskRepository } from '@/app/core/IRepositories/ITaskepository';
import { TaskStatusDto } from '@/app/Data/DTO/TaskStatusDto';
import {
  AddTaskCommentAction,
  DeleteTaskCommentAction,
  EditTaskCommentAction,
  EnableEditForTaskCommentAction,
  GetTaskDetailAction,
  PatchTaskAction,
  UpdateTaskPriorityAction,
  UpdateTaskStatusAction,
} from '@/app/State/Task/action';
import { TaskCommentDto } from '@/app/Data/DTO/TaskCommentDto';
import { selectEditableTaskCommentId } from '@/app/State/Task/selector';
import { CreatTaskDto, PatchTaskDto, TaskDto } from '@/app/Data/DTO/TaskDto';
import { CreateTaskUseCase } from '@/app/core/usecases/Task/CreateTaskUseCase';
import { EditTaskUseCase } from '@/app/core/usecases/Task/EditTaskUseCase';
import { TaskPriorityDto } from '@/app/Data/DTO/TaskPriorityDto';
import { IMediaRepository } from '@/app/core/IRepositories/IMediaRepository';

@Injectable({
  providedIn: 'root',
})
export class TaskViewModel {
  readonly profile$ = this.store.select(selectTenantProfile);
  readonly editableCommentId$ = this.store.select(selectEditableTaskCommentId);
  updateChannelTasks$ = new BehaviorSubject(false);
  constructor(
    private store: Store<AppState>,
    private taskRepository: ITaskRepository,
    private createTaskUseCase: CreateTaskUseCase,
    private editTaskUseCase: EditTaskUseCase,
    private mediaRepository: IMediaRepository
  ) { }

  private _currentTaskId$ = new BehaviorSubject<number>(0);

  task$ = this._currentTaskId$.pipe(
    filter((item) => !!item),
    switchMap((taskId) => this.taskRepository.getLocalTaskById(taskId)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  taskLinkCopied = new BehaviorSubject<boolean>(false);

  updateTaskStatus(request: TaskStatusDto): void {
    this.profile$.pipe(take(1)).subscribe((profile) => {
      request.userId = profile?.userId;
      this.store.dispatch(UpdateTaskStatusAction({ payload: request }));
    });
  }

  updateTaskPriority(request: TaskPriorityDto): void {
    this.store.dispatch(UpdateTaskPriorityAction({ payload: request }));
  }

  loadTaskDetail(taskId: number): void {
    this._currentTaskId$.next(taskId);
    this.store.dispatch(GetTaskDetailAction({ payload: taskId }));
  }

  addComment(request: TaskCommentDto): void {
    this.store.dispatch(AddTaskCommentAction({ payload: request }));
  }

  editComment(request: TaskCommentDto): void {
    this.store.dispatch(EditTaskCommentAction({ payload: request }));
  }

  enableEditForComment(request: { commentId: number }): void {
    this.store.dispatch(
      EnableEditForTaskCommentAction({ payload: request.commentId })
    );
  }

  deleteComment(commentId: number, taskId: number): void {
    this.store.dispatch(
      DeleteTaskCommentAction({ payload: { commentId, taskId } })
    );
  }

  createTask(data: CreatTaskDto): Observable<TaskDetail> {
    return this.createTaskUseCase.execute(data);
  }

  editTask(data: CreatTaskDto): Observable<TaskDetail> {
    return this.editTaskUseCase.execute(data);
  }

  uploadAttachments(data: FormData): Observable<Array<{ type: string, url: string, filename?: string }>> {
    return this.mediaRepository.uploadAttachments2(data);
  }

  getBodyAttachment(attachments: Array<{ filename: string, type: string, file_url: string, file?: File }>): FormData | undefined {
    const form = new FormData();
    attachments
      .map((attachment) => attachment.file)
      .filter((file): file is File => !!file)
      .forEach((file) => {
        form.append(file.type.startsWith('image/') ? 'image' : file.type.startsWith('video/') ? 'video' : 'file', file, file.name || '');
      });
    let counter = 0;
    form.forEach((item) => { counter++; });
    return counter ? form : undefined;
  }

  patchTask(payload: PatchTaskDto) {
    this.store.dispatch(PatchTaskAction({ payload }));
  }
}
