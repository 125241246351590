<div class="mat-dialog-header text-dark">
  <h2 class="text-lg font-semibold">
    {{ localization.labels.choose_country | translate}}
  </h2>
  <svg-icon
    class="text-pale hover:text-warning cursor-pointer ml-auto"
    [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
    src="assets/icons/new-set/close.svg"
    mat-dialog-close
  >
  </svg-icon>
</div>

<div class="mat-dialog-body text-dark flex flex-col gap-4">
  <app-text-input
    [formControl]="filter"
    placeholder="Search a country"
  >
    <svg-icon
      suffix
      src="assets/icons/new-set/search.svg"
    ></svg-icon>
  </app-text-input>
  <div class="overflow-y-auto">
    <div
      *ngFor="let country of filtredCountriesList$ | async"
      class="flex flex-row gap-6 cursor-pointer p-3 hover:secondary-bg-color rounded"
      mat-ripple
      (click)="select(country)"
    >
      <img
        *ngIf="country.code"
        [src]="'assets/flags/' + country.code.toLowerCase() + '.svg'"
        style="height: 24px; width: 24px"
      />
      <span class="uppercase font-semibold">{{ country.name }}</span>
      <span class="ml-auto">({{ country.dial_code }})</span>
    </div>
  </div>
</div>