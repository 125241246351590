import { DealsQueryParams, Deal, UpdatedDealStageResponse } from '@/app/core/Models/deal';
import { CreateDealDto, EditDealPriorityDto, EditDealStageDto } from '@/app/Data/DTO/dealDto';
import { createAction, props, union } from '@ngrx/store';

export const GET_DEALS_LIST = '[Deals] Get Deals List';
export const GetDealsListAction = createAction(
  GET_DEALS_LIST,
  props<{ payload: DealsQueryParams }>()
);

export const GET_DEALS_LIST_SUCCESS = '[Deals] Get Deals List Success';
export const GetDealsListSuccessAction = createAction(
  GET_DEALS_LIST_SUCCESS,
  props<{ payload: { params: DealsQueryParams, deals: Deal[] } }>()
);

export const GET_DEALS_LIST_FAIL = '[Deals] Get Deals List Fail';
export const GetDealsListFailAction = createAction(
  GET_DEALS_LIST_FAIL);

// export const LOOKUP_DEAL = '[Deals] lookup deals action';
// export const LookupDealsAction = createAction(
//   LOOKUP_DEAL,
//   props<{ payload: DealsLookupDto }>()
// );

// export const LOOKUP_DEAL_SUCCESS = '[Deals] lookup deals success action';
// export const LookupDealsSuccessAction = createAction(
//   LOOKUP_DEAL_SUCCESS,
//   props<{ payload: LookupDeal[] }>()
// );

export const SHOW_DEAL_PAGE = '[Deals] Show Deal Page';
export const ShowDealPageAction = createAction(
  SHOW_DEAL_PAGE,
  props<{ payload: Deal }>()
);

export const SET_ACTIVE_DEAL_ID = '[Deals] set active deal id';
export const SetActiveDealId = createAction(
  SET_ACTIVE_DEAL_ID,
  props<{ payload: { dealId: string } }>()
);

export const RESET_DEAL_STATE = '[DEAL] Reset deal State';
export const ResetDealStateAction = createAction(
  RESET_DEAL_STATE);

/* ----------------------------- create deal ----------------------------- */
export const DEAL_CREATE = '[DEAL] Deal create';
export const DealCreateAction = createAction(
  DEAL_CREATE,
  props<{ payload: { data: CreateDealDto, dialogId?: string } }>()
);

export const DEAL_CREATE_SUCCESS = '[DEAL] Deal create success';
export const DealCreateSuccessAction = createAction(
  DEAL_CREATE_SUCCESS
);

export const DEAL_CREATE_FAIL = '[DEAL] Deal create Fail';
export const DealCreateFailAction = createAction(
  DEAL_CREATE_FAIL
);

/* ----------------------------- create deal ----------------------------- */
export const DEAL_UPDATE = '[DEAL] Deal update';
export const DealUpdateAction = createAction(
  DEAL_UPDATE,
  props<{ payload: { data: CreateDealDto, dialogId?: string } }>()
);

export const DEAL_UPDATE_SUCCESS = '[DEAL] Deal update success';
export const DealUpdateSuccessAction = createAction(
  DEAL_UPDATE_SUCCESS
);

export const DEAL_UPDATE_FAIL = '[DEAL] Deal update Fail';
export const DealUpdateFailAction = createAction(
  DEAL_UPDATE_FAIL
);

export const DEAL_PRIORITY_UPDATE = '[DEAL] Deal priority update';
export const DealPriorityUpdateAction = createAction(
  DEAL_PRIORITY_UPDATE,
  props<{ payload: { data: EditDealPriorityDto } }>()
);

export const DEAL_PRIORITY_UPDATE_SUCCESS = '[DEAL] Deal priority update success';  
export const DealPriorityUpdateSuccessAction = createAction(
  DEAL_PRIORITY_UPDATE_SUCCESS
);

export const DEAL_PRIORITY_UPDATE_FAIL = '[DEAL] Deal priority update fail';
export const DealPriorityUpdateFailAction = createAction(
  DEAL_PRIORITY_UPDATE_FAIL
);

export const DEAL_STAGE_UPDATE = '[DEAL] Deal stage update';
export const DealStageUpdateAction = createAction(
  DEAL_STAGE_UPDATE,
  props<{ payload: { data: EditDealStageDto } }>()
);

export const DEAL_STAGE_UPDATE_SUCCESS = '[DEAL] Deal stage update success';
export const DealStageUpdateSuccessAction = createAction(
  DEAL_STAGE_UPDATE_SUCCESS,
  props<{ payload: { data: UpdatedDealStageResponse } }>()
); 

export const DEAL_STAGE_UPDATE_FAIL = '[DEAL] Deal stage update fail';
export const DealStageUpdateFailAction = createAction(
  DEAL_STAGE_UPDATE_FAIL
); 

/* ------------------------------ fetch deal ----------------------------- */
export const FETCH_DEAL_BY_ID = '[DEAL] Fetch Deal By Id';
export const FetchDealByIdAction = createAction(
  FETCH_DEAL_BY_ID,
  props<{ payload: string }>()
);

export const FETCH_DEAL_BY_ID_SUCCESS = '[DEAL] Fetch Deal By Id Success';
export const FetchDealByIdSuccessAction = createAction(
  FETCH_DEAL_BY_ID_SUCCESS,
  props<{ payload: Deal }>()
);

export const FETCH_DEAL_BY_ID_FAILED = '[DEAL] Fetch Deal By Id Failed';
export const FetchDealByIdFailedAction = createAction(
  FETCH_DEAL_BY_ID_FAILED,
  props<{ payload: { status: number } }>()
);

/* ----------------------------- delete deal ----------------------------- */
export const DELETE_DEAL = '[DEALS] Delete Deal';
export const DeleteDealAction = createAction(
  DELETE_DEAL,
  props<{ payload: string[] }>()
);

export const DELETE_DEAL_SUCCESS = '[DEALS] Deal Successfully Deleted';
export const DealDeleteSuccessAction = createAction(
  DELETE_DEAL_SUCCESS,
  props<{ payload: string[] }>()
);
export const DELETE_DEAL_FAIL = '[DEALS] Failed To Delete Deal';
export const DealDeleteFailAction = createAction(
  DELETE_DEAL_FAIL,
  props<{ payload: string }>()
);

export const RESET_DEALS_STATE = '[DEALS] Reset Deals State';
export const ResetDealsStateAction = createAction(
  RESET_DEALS_STATE
);


const all = union({
  DealCreateAction,
  DealUpdateAction,
  GetDealsListAction,
  GetDealsListSuccessAction,
  GetDealsListFailAction,
  // LookupDealsAction,
  // LookupDealsSuccessAction,
  ShowDealPageAction,
  SetActiveDealId,
  ResetDealStateAction,
  DealCreateSuccessAction,
  FetchDealByIdAction,
  FetchDealByIdSuccessAction,
  FetchDealByIdFailedAction,
  DeleteDealAction,
  DealDeleteSuccessAction,
  DealDeleteFailAction,
});
export type DealsActions = typeof all;