import { BaseInteraction, InteractionType, InteractionDirection, RecentInteraction, Interaction, InteractionThread, InteractionStatus } from "./Interaction";
import { TaskStatus } from "./Task";
import { RoomDetails, TeamMember } from "./TeamMember";
import { Room, User } from "./User";
import { Tenant } from "./tenant";

export class TeamInteraction<T extends string | Partial<User> = Partial<User>> extends BaseInteraction {
  public userThread?: boolean
  public msg_id?: number;
  public attachments: {
    url: string,
    type: string,
    name?: string,
  }[];
  public is_room: boolean;
  public room_id?: string
  public type: InteractionType;
  public receiver_id: number;
  public receiver: T;
  public sender_id: number;
  public sender: T;
  reactions: {
    reaction: string,
    user_id: string | number
  }[]
  public sender_name?: string
  public sender_avatar?: string
  public data: string
    | { firstname: string, lastname: string, status: TaskStatus, task_id: number, title: string }[]
    | { filename: string, filetype: string, yobi_filename: string }
  users: TeamMember[] = []
  profile: Tenant;
  receivers_status: {
    status: InteractionStatus,
    updated_dt: string,
    user_id: number | string
    avatar?: string,
    name?: string
  }[]
  room?: Room

  mapUserToTeamMember(user: Partial<User>): TeamMember {
    return {
      active: false,
      avatar: user.avatar!,
      family_name: user.lastName! ?? (user as any).familyName ?? '',
      given_name: user.firstName! ?? (user as any).givenName ?? '',
      user_id: user.user_id!,
      username: user.username!
    }
  }
  mapRoomDetails(room: Partial<Room>): RoomDetails {
    return {
      active: false,
      avatar: room.avatar!,
      room_name: room.room_name!,
      room_id: room.room_id!,
      private: room.private!
    }
  }
  private get _sender(): TeamMember | undefined {
    // null is also object and the value comes either a string a record or null
    return !!this.sender && typeof this.sender == 'object' ? this.mapUserToTeamMember(this.sender) : this.users?.length ? this.users.find(u => u.user_id == this.sender_id) : undefined
  }

  private get _receiver(): TeamMember | undefined {
    return !!this.receiver && typeof this.receiver == 'object' ? this.mapUserToTeamMember(this.receiver) : this.users?.length ? this.users.find(u => u.user_id == this.receiver_id) : undefined
  }

  private get _room(): Room | undefined {
    return this.is_room ? typeof this.receiver == 'object' ? this.mapRoomDetails(this.receiver) : undefined : undefined
  }

  private get _user(): TeamMember | undefined {
    return this._direction == InteractionDirection.inbound ? this._sender : this._receiver
  }

  private get _status() {
    return this.status === InteractionStatus.sending
      ? this.status
      : this._direction === InteractionDirection.inbound
        ? (
          this.profile && (this.receivers_status ?? []).some(({ user_id, status }) => user_id === this.profile.userId && status === InteractionStatus.read)
            ? InteractionStatus.read
            : InteractionStatus.unread
        )
        : (this.receivers_status ?? []).some(({ user_id, status }) => user_id === this.receiver_id && status === InteractionStatus.read)
          ? InteractionStatus.read
          : InteractionStatus.sent
  }

  private get _direction(): InteractionDirection {
    return this.direction
      ? [InteractionDirection.inbound, 'incoming'].includes(this.direction) ? InteractionDirection.inbound : InteractionDirection.outbound
      : this.sender_id == this.profile?.userId
        ? InteractionDirection.outbound
        : InteractionDirection.inbound
  }

  get interactionParsedDate(): Date {
    return new Date(this.created_dt);
  }

  get text_body_builder() {
    if (typeof this.data == 'string') {
      const body = [];
      this.data && body.push({
        content_type: 'text',
        content: this.is_room && this.direction === InteractionDirection.inbound && this.userThread && this._sender
          ? `${this._sender?.given_name}: ${this.data}`
          : this.data,
      })
      this.attachments?.some(x => !x.type.includes('audio')) && body.push({
        content_type: 'attachments',
        content: this.attachments.filter(x => !x.type.includes('audio')).map((attachment) => ({
          ...attachment, type: attachment.url.includes('pdf') && attachment.name?.includes('pdf') ? 'pdf' : attachment.type
        })),
      })
      this.attachments?.some(x => x.type.includes('audio')) && body.push({
        content_type: 'memos',
        content: this.attachments.filter(x => x.type.includes('audio')),
      })
      return JSON.stringify(body)
    }
    if (Array.isArray(this.data) && this.data[0]?.task_id) {
      return JSON.stringify([{ ...this.data[0], content_type: 'task', user: this._user }])
    }
    if (typeof this.data == 'object' && (this.data as any).filename) {
      let data = this.data as { filename: string, filetype: string, yobi_filename: string }
      return JSON.stringify([{ content: data.yobi_filename, filename: data.filename, content_type: ['jpg', 'png', 'webp', 'gif', 'jpeg'].includes(data.filetype) ? 'image' : 'file', user: this._user }])
    }
    return 'TODO handle this kind of messages'
  }


  get _receivers_status() {
    if (this.receivers_status && this.receivers_status.length) {
      this.receivers_status = this.receivers_status.map((item) => {
        const user = this.users.find(user => user.user_id === item.user_id);
        return { ...item, name: `${user?.given_name} ${user?.family_name}`, avatar: user?.avatar };
      });
      return this.receivers_status;
    } else {
      return [];
    }
  }


  mapToRecentInteraction(): RecentInteraction {
    const user = this._user
    const room = this._room ? this._room : this.room;
    const interaction = Object.assign<Interaction, Partial<Interaction>>(new Interaction(), {
      created_dt: this.created_dt,
      direction: this._direction,
      interaction_dt: this.interactionParsedDate,
      interaction_id: this.interaction_id,
      interaction_type: this.type,
      status: this.is_room ? this.status : this._status,
      text_body: this.text_body_builder,
      user_id: this.is_room ? room!.room_id : this._direction == InteractionDirection.inbound ? this.sender_id.toString() : this.receiver_id.toString(),
      translation: this.translation,
      summarization: this.summarization,
    })
    const recentInteraction = Object.assign<RecentInteraction, Partial<RecentInteraction>>(new RecentInteraction(), {
      interaction,
      id: this.interaction_id,
      is_room: this.is_room,
      room_id: this.is_room ? room!.room_id || '' : '',
      private: this.is_room ? room!.private || false : false,
      avatar: this.is_room ? (room!.avatar || '') : (user?.avatar || ''),
      given_name: this.is_room ? (room?.room_name || '') : user?.given_name || '',
      family_name: this.is_room ? '' : user?.family_name || '',
      interaction_dt: this.interactionParsedDate,
      sender: this.is_room ? this.sender as any : user?.user_id + '',
    });
    return recentInteraction;
  }
  mapToThreadInteraction(): InteractionThread {
    const interaction = Object.assign<InteractionThread, Partial<InteractionThread>>(new InteractionThread(), {
      created_dt: this.created_dt,
      msg_id: this.msg_id,
      direction: this._direction,
      is_room: this.is_room,
      page: 1,
      interaction_dt: this.interactionParsedDate,
      interaction_id: this.interaction_id,
      interaction_type: this.type,
      status: this.is_room ? this.status : this._status,
      avatar: this.is_room && typeof this.sender === "object" ? this.sender.avatar : this._receiver?.avatar || '',
      sender_id: typeof this.sender === "object" ? this.sender.user_id : typeof this.sender_id === "string" ? this.sender_id : '',
      user_id: this.is_room ? this.receiver_id.toString() : this._direction == InteractionDirection.inbound ? this.sender_id.toString() : this.receiver_id.toString(),
      sender_name: `${this._sender?.given_name} ${this._sender?.family_name}`.trim(),
      text_body: this.text_body_builder,
      updated_dt: this.updated_dt,
      translation: this.translation,
      summarization: this.summarization,
      reactions: this.reactions ?? [],
      receivers_status: this._receivers_status
    });
    return interaction;
  }

}
