import { AppState } from '@/app/State/AppState';
import { selectSignUpState } from '@/app/State/Auth/selector';
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { lastValueFrom, map, Observable } from 'rxjs';
import { EmailVerificationComponent } from '../Registration/email-verification/email-verification.component';

@Injectable()
export class EmailVerificationGuard
  implements CanActivate, CanDeactivate<EmailVerificationComponent>
{
  constructor(private store$: Store<AppState>, private router: Router) {}

  canDeactivate(
    component: EmailVerificationComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
      return true;
   /* if (component.allowLeave) {
      return true;
    } else {
      return window.confirm('Are you sure to leave?');
    }*/
  }
 
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.store$.select(selectSignUpState).pipe(
      map((data) => {
        if (!!data.emailVerification?.authorize) {
          return true;
        } else {
          this.router.navigateByUrl('/auth/login');
          return false;
        }
      })
    );
  }
}
