import { AlertComponent } from '@/app/Ui/Modals/alert/alert.component';
import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { MatSnackBarConfig } from '@angular/material/snack-bar/snack-bar-config';
import { ComponentType } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) { }

  open(
    message: string,
    action: string = 'Dismiss',
    config: MatSnackBarConfig = { duration: 3000 }
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, action, config);
  }

  openFromTemplate(template: TemplateRef<any>, config?: MatSnackBarConfig<any> | undefined) {
    return this.snackBar.openFromTemplate(template, config);
  }
  openFromComponent<T>(template: ComponentType<T>, config?: MatSnackBarConfig<any> | undefined) {
    return this.snackBar.openFromComponent(template, { ...config, panelClass: 'snack-bar-white-container', duration: 30000 });
  }
  openAlert<T>(data: { message: string, action?: string, duration?: number, type: 'info' | 'success' | 'failure', translateParams?: Object }) {
    return this.snackBar.openFromComponent(AlertComponent, {
      data: { 
        message: data.message, 
        translateParams: data.translateParams, 
        success: data.type == 'success',
        info: data.type == 'info'
      },
      panelClass: 'snack-bar-white-container',
      duration: data.duration ?? 3000
    });
  }
}
