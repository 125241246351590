import { createAction, props, union } from '@ngrx/store';
import { TaskStatusDto } from '@/app/Data/DTO/TaskStatusDto';
import { Task, TaskComment, TaskDetail } from '@/app/core/Models/Task';
import { TaskCommentDto } from '@/app/Data/DTO/TaskCommentDto';
import { TaskCommentDeleteDto } from '@/app/Data/DTO/TaskCommentDeleteDto';
import { CreatTaskDto, TaskDto } from '@/app/Data/DTO/TaskDto';
import { TaskPriorityDto } from '@/app/Data/DTO/TaskPriorityDto';

/* ------------------------------- task status ------------------------------ */
export const UPDATE_TASK_STATUS = '[TASK] Update Task Status';
export const UpdateTaskStatusAction = createAction(
  UPDATE_TASK_STATUS,
  props<{ payload: TaskStatusDto }>()
);

export const UPDATE_TASK_STATUS_SUCCESS = '[TASK] Update Task Status Success';
export const UpdateTaskStatusSuccessAction = createAction(
  UPDATE_TASK_STATUS_SUCCESS,
  props<{ payload: Task }>()
);

export const RESPOND_TASK_STATUS_SUCCESS = '[TASK] Respond Task Status Success';
export const RespondTaskStatusSuccessAction = createAction(
  RESPOND_TASK_STATUS_SUCCESS,
  props<{ payload: Task }>()
);

export const UPDATE_TASK_STATUS_FAIL = '[TASK] Failed To Update Task Status';
export const UpdateTaskStatusFailAction = createAction(
  UPDATE_TASK_STATUS_FAIL,
  props<{ payload: string; rollbackPayload?: Partial<Task> }>()
);

/* ------------------------------ task priority ----------------------------- */
export const UPDATE_TASK_PRIORITY = '[TASK] Update Task Priority';
export const UpdateTaskPriorityAction = createAction(
  UPDATE_TASK_PRIORITY,
  props<{ payload: TaskPriorityDto }>()
);

export const UPDATE_TASK_PRIORITY_SUCCESS = '[TASK] Update Task Priority Success';
export const UpdateTaskPrioritySuccessAction = createAction(
  UPDATE_TASK_PRIORITY_SUCCESS,
  props<{ payload: Task }>()
);

export const UPDATE_TASK_PRIORITY_FAIL = '[TASK] Update Task Priority Fail';
export const UpdateTaskPriorityFailAction = createAction(
  UPDATE_TASK_PRIORITY_FAIL,
  props<{ payload: string }>()
);

/* -------------------------------- get task -------------------------------- */
export const GET_TASK_DETAIL = '[TASK] Get Task Detail';
export const GetTaskDetailAction = createAction(
  GET_TASK_DETAIL,
  props<{ payload: number }>()
);
export const GET_TASK_DETAIL_FAIL = '[TASK] Fail To Get Task Detail';
export const GetTaskDetailFailAction = createAction(
  GET_TASK_DETAIL_FAIL,
  props<{ payload: string }>()
);
export const GET_TASK_DETAIL_SUCCESS = '[TASK] Get Task Detail Success';
export const GetTaskDetailSuccessAction = createAction(
  GET_TASK_DETAIL_SUCCESS,
  props<{ payload: TaskDetail }>()
);

/* ---------------------------- add task comment ---------------------------- */
export const ADD_TASK_COMMENT = '[TASK] Add Task Comment';
export const AddTaskCommentAction = createAction(
  ADD_TASK_COMMENT,
  props<{ payload: TaskCommentDto }>()
);
export const ADD_TASK_COMMENT_SUCCESS = '[TASK] Add task comment Success';
export const AddTaskCommentSuccessAction = createAction(
  ADD_TASK_COMMENT_SUCCESS,
  props<{ payload: number }>()
);
export const ADD_TASK_COMMENT_FAIL = '[TASK] Fail To add task comment';
export const AddTaskCommentFailAction = createAction(
  ADD_TASK_COMMENT_FAIL,
  props<{ payload: string }>()
);
export const ADD_INCOMMING_TASK_COMMENT = '[TASK] Add incomming Task Comment';
export const AddIncommingTaskCommentAction = createAction(
  ADD_INCOMMING_TASK_COMMENT,
  props<{ payload: TaskComment }>()
);

/* ---------------------------- edit task comment --------------------------- */
export const EDIT_TASK_COMMENT = '[TASK] Edit Task Comment';
export const EditTaskCommentAction = createAction(
  EDIT_TASK_COMMENT,
  props<{ payload: TaskCommentDto }>()
);
export const EDIT_TASK_COMMENT_SUCCESS = '[TASK] Edit task comment Success';
export const EditTaskCommentSuccessAction = createAction(
  EDIT_TASK_COMMENT_SUCCESS,
  props<{ payload: number }>()
);
export const EDIT_TASK_COMMENT_FAIL = '[TASK] Fail To Edit task comment';
export const EditTaskCommentFailAction = createAction(
  EDIT_TASK_COMMENT_FAIL,
  props<{ payload: string }>()
);

/* --------------------------- delete task comment -------------------------- */
export const DELETE_TASK_COMMENT = '[TASK] Delete Task Comment';
export const DeleteTaskCommentAction = createAction(
  DELETE_TASK_COMMENT,
  props<{ payload: TaskCommentDeleteDto }>()
);
export const DELETE_TASK_COMMENT_SUCCESS = '[TASK] Delete Task Comment Success';
export const DeleteTaskCommentSuccessAction = createAction(
  DELETE_TASK_COMMENT_SUCCESS,
  props<{ payload: number }>()
);
export const DELETE_TASK_COMMENT_FAIL = '[TASK] Failed To Delete Task Comment';
export const DeleteTaskCommentFailAction = createAction(
  DELETE_TASK_COMMENT_FAIL,
  props<{ payload: string }>()
);

/* ------------------------------- create task ------------------------------ */
export const CREATE_TASK = '[TASK] Create Task';
export const CreateTaskAction = createAction(
  CREATE_TASK,
  props<{ payload: CreatTaskDto }>()
);
export const CREATE_TASK_SUCCESS = '[TASK] Create Task  Success';
export const CreateTaskSuccessAction = createAction(
  CREATE_TASK_SUCCESS,
  props<{ payload: TaskDetail }>()
);
export const CREATE_TASK_FAIL = '[TASK] Create Task  Fail';
export const CreateTaskFailAction = createAction(
  CREATE_TASK_FAIL,
  props<{ payload: string }>()
);

/* ------------------------------ task comment ------------------------------ */
export const ENABLE_EDIT_FOR_TASK_COMMENT = '[TASK] Enable Edit For Comment';
export const EnableEditForTaskCommentAction = createAction(
  ENABLE_EDIT_FOR_TASK_COMMENT,
  props<{ payload: number }>()
);

export const DISABLE_EDIT_FOR_TASK_COMMENT = '[TASK] Disable Edit For Comment';
export const DisableEditForTaskCommentAction = createAction(
  DISABLE_EDIT_FOR_TASK_COMMENT,
  props<{ payload: number }>()
);



const all = union({
  UpdateTaskStatusAction,
  UpdateTaskStatusSuccessAction,
  UpdateTaskPriorityAction,
  UpdateTaskPrioritySuccessAction,
  GetTaskDetailAction,
  GetTaskDetailSuccessAction,
  AddTaskCommentAction,
  AddTaskCommentSuccessAction,
  AddIncommingTaskCommentAction,
  EditTaskCommentAction,
  EditTaskCommentSuccessAction,
  DeleteTaskCommentAction,
  DeleteTaskCommentSuccessAction,
  CreateTaskAction,
  CreateTaskSuccessAction,
  EnableEditForTaskCommentAction,
  DisableEditForTaskCommentAction,
})

export type TaskActions = typeof all;
