import { AppState } from '@/app/State/AppState';
import {
  BillingTokenLoginAction,
  SocialoLoginAction,
  ValidateEmailVerificationCodeAction,
} from '@/app/State/Auth/action';
import {
  SetActiveThreadIdAction,
} from '@/app/State/Inbox/inbox.action';
import { MessagesFetchError } from '@/app/core/Models/FetchErrors';
import { Injectable } from '@angular/core';
import {
  NavigationEnd,
  Router
} from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, pairwise, startWith, tap } from 'rxjs';
import { CLASSIFIED_MESSAGES_FETCH_FAIL, UN_CLASSIFIED_MESSAGES_FETCH_FAIL } from '../thread/thread.action';

import { RouterNavigationEndAction } from './action';
import { ChannelType } from '@/app/core/Models/ChannelTypes';
import { FetchEmailInteractionsAction } from '../email-inbox/email-inbox.action';

@Injectable()
export class RouterEffect {
  private navigationTimeStamp = 0;
  constructor(
    private router: Router,
    private actions$: Actions,
    private store$: Store<AppState>
  ) { }



  onRouterNavigation$ = createEffect(
    () =>
      this.router.events.pipe(
        filter(
          (route) =>
            route instanceof NavigationEnd &&
            [
              '/signup?code=',
              '/login?code=',
              'google_signin',
              'facebook_login',
              'apple_signin',
              'billing?access=',
            ].some((param) => route.url.includes(param))
        ),
        map((route) => route as NavigationEnd),
        tap((route: NavigationEnd) => {
          const queryParams = this.router.url.replace('%20', '+').replace('%26', '&').replace('%3D', '=').split('?')[1]
          if (!queryParams) {
            return;
          }
          if (['/signup?code=', '/login?code='].some((param) => route.url.includes(param))) {
            const [code, email] = queryParams.replace('code=', '').split('&email=')
            code &&
              email &&
              this.store$.dispatch(
                ValidateEmailVerificationCodeAction({
                  payload: { channel_type: ChannelType.Email, channel_value: email, code },
                })
              );
            return;
          }
          if (['google_signin', 'facebook_login', 'apple_signin'].some((param) => route.url.includes(param))) {
            const [yib_tk, yib_rt] = queryParams
              .replace('apple_signin=', '')
              .replace('google_signin=', '')
              .replace('facebook_login=', '')
              .split('&refresh_token=')
            yib_tk &&
              yib_rt &&
              this.store$.dispatch(
                SocialoLoginAction({ payload: { yib_rt, yib_tk } })
              );
            return;
          }
          if (route.url.includes('billing?access=')) {
            const [yib_tk, yib_rt] = queryParams.replace('access=', '').split('&')
            yib_tk &&
              this.store$.dispatch(
                BillingTokenLoginAction({ payload: { yib_rt: '', yib_tk } })
              );
            return;
          }
        })
      ),
    { dispatch: false }
  );

  onClassifiedThreadNavigation$ = createEffect(
    () =>
      this.router.events.pipe(
        filter(
          (route) =>
            route instanceof NavigationEnd &&
            !!route.url?.includes('main/inbox/thread')
        ),
        tap((route: any) => {
          const params =
            this.router.getCurrentNavigation()?.extractedUrl.queryParams;
          const contactId = params && params['threadId'];
          const channel = params && params['channel'];
          if (channel && contactId) {
            return;
          }
          if (contactId) {
            this.store$.dispatch(
              SetActiveThreadIdAction({
                payload: { id: contactId, unClassified: false },
              })
            );
          } else if (channel) {
            this.store$.dispatch(
              SetActiveThreadIdAction({
                payload: { id: channel, unClassified: true },
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  onEmailConversationNavigation$ = createEffect(
    () =>
      this.router.events.pipe(
        filter((route) => route instanceof NavigationEnd && !!route.url?.includes('main/email/conversation')),
        map((route) => this.router.getCurrentNavigation()?.extractedUrl.queryParams['id']),
        filter(id => !!id),
        map((conversation_id) => FetchEmailInteractionsAction({
          payload: { query: { conversation_id, page: 1, page_size: 20 } },
        }))
      ),
    { dispatch: true }
  );

  onFetchUnClassifiedConversationThreadAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          UN_CLASSIFIED_MESSAGES_FETCH_FAIL,
          CLASSIFIED_MESSAGES_FETCH_FAIL
        ),
        map((action: any) => action.payload),
        tap((error: MessagesFetchError) => {
          error.codeStatus === 404 &&
            this.router.navigateByUrl('/main/inbox').then().catch();
        })
      ),
    { dispatch: false }
  );


  private extractLastSegment(url: string): string {
    if (!url) {
      return '';
    }
    const indexOfQueryParams: number = url.indexOf("?");
    if (indexOfQueryParams !== -1) {
      url = url.substring(0, indexOfQueryParams);
    }
    const segments: string[] = url.split("/");
    return segments[segments.length - 1];
  }

  onRouterNavigationEnd$ = createEffect(
    () =>
      this.router.events.pipe(
        filter((route) => route instanceof NavigationEnd),
        startWith(null),
        distinctUntilChanged((event1, event2) => (event1 as NavigationEnd)?.url.split('?')[0] === (event2 as NavigationEnd)!.url.split('?')[0]),
        pairwise(),
        map(([event1, event2]) => {
          let timestamp = 0;
          if (this.navigationTimeStamp == 0) {
            this.navigationTimeStamp = new Date().getTime();
          } else {
            timestamp = Math.round(Math.abs((new Date().getTime() - this.navigationTimeStamp) / 1000));
            this.navigationTimeStamp = new Date().getTime();
          }
          const previous_page = this.extractLastSegment((event1 as NavigationEnd)?.url);
          const current_page = this.extractLastSegment((event2 as NavigationEnd)?.url);
          return RouterNavigationEndAction({ payload: { timestamp, previous_page, current_page } })
        }),
      ),
    { dispatch: true }
  )
}
