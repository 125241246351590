import { createAction, props, union } from '@ngrx/store';
import { Tenant } from '@/app/core/Models/tenant';
import { LangModel, TranslationLanguage } from '@/app/core/Models/lang';
import { updateTranslatePreferencesDto } from '@/app/Data/DTO/translationDto';
import { BasicCustomFieldDetails } from '@/app/core/Models/contact';
import { ToggleChannelSynthRequestDto, ToggleChannelSynthRequest } from '@/app/Data/DTO/ChannelDto';

export const GET_TENANT_INTERNAL_CHANNELS = '[Tenant] Get Internal Channels';
export const GetTenantInternalChannelsAction = createAction(
  GET_TENANT_INTERNAL_CHANNELS
);

export const GET_TENANT_INTERNAL_CHANNELS_SUCCESS =
  '[Tenant] Internal Channels loaded successfully"';
export const GetInternalChannelsSuccessAction = createAction(
  GET_TENANT_INTERNAL_CHANNELS_SUCCESS
);

export const GET_TENANT_INTERNAL_CHANNELS_FAIL =
  '[Tenant] Internal Channels Failed to load';
export const GetInternalChannelsFailAction = createAction(
  GET_TENANT_INTERNAL_CHANNELS_FAIL
);

// tenant profile -----------------------------------------------------
export const GET_TENANT_PROFILE = '[TENANT] Get Tenant Profile';
export const GetTenantProfileAction = createAction(GET_TENANT_PROFILE);

export const UPDATE_TENANT_PROFILE = '[Profile] Update Tenant Profile';
export const UpdateTenantProfile = createAction(
  UPDATE_TENANT_PROFILE,
  props<{ payload: Tenant }>()
);

export const UPDATE_TENANT_BUSINESS_NAME = '[TENANT] Update Tenant Business Name';
export const UpdateTenantBusinessNameAction = createAction(
  UPDATE_TENANT_BUSINESS_NAME,
  props<{ payload: { businessName: string } }>()
);

export const UPDATE_TENANT_BUSINESS_NAME_SUCCESS = '[TENANT] Update Tenant Business Name Success';
export const UpdateTenantBusinessNameSuccessAction = createAction(
  UPDATE_TENANT_BUSINESS_NAME_SUCCESS,
  props<{ payload: { businessName: string } }>()
);

export const UPDATE_TENANT_BUSINESS_NAME_ERROR = '[TENANT] Update Tenant Busienss Name Error';
export const UpdateTenantBusinessNameErrorAction = createAction(
  UPDATE_TENANT_BUSINESS_NAME_ERROR,
  props<{ payload: { errorMessage: string } }>()
);
// tenant custom fields -----------------------------------------------------
export const GET_TENANT_CUSTOM_FIELDS = '[TENANT] Get Tenant Custom Fields';
export const GetTenantCustomFieldsAction = createAction(GET_TENANT_CUSTOM_FIELDS);

export const UPDATE_TENANT_CUSTOM_FIELDS = '[Custom Fields] Update Tenant Custom Fields';
export const UpdateTenantCustomFields = createAction(
  UPDATE_TENANT_CUSTOM_FIELDS,
  props<{ payload: BasicCustomFieldDetails[] }>()
);


export const TOGGLE_DO_NOT_DISTURB = '[TENANT] Toggle do not disturb status';
export const ToggleDoNotDisturbStatus = createAction(
  TOGGLE_DO_NOT_DISTURB,
  props<{ payload: { status: boolean, userId: number } }>()
);


export const SET_TRANSLATION_LANGUAGE_PREFERENCES = '[TENANT] set translation language Preferences';
export const setTranslationLanguagePreferencesAction = createAction(
  SET_TRANSLATION_LANGUAGE_PREFERENCES,
  props<{ payload: updateTranslatePreferencesDto }>()
);

export const SET_TRANSLATION_LANGUAGE_PREFERENCES_SUCCESS = '[TENANT] set translation language Preferences success';
export const setTranslationLanguagePreferencesSuccessAction = createAction(
  SET_TRANSLATION_LANGUAGE_PREFERENCES_SUCCESS,
  props<{ payload: TranslationLanguage }>()
);

//  ----------------------------------------------------------------------
export const GET_TRANSLATION_LANGUAGE_PREFERENCES = '[TENANT] get translation language preferences';
export const getTranslationPrefeerencesAction = createAction(
  GET_TRANSLATION_LANGUAGE_PREFERENCES,
);

export const GET_TRANSLATION_LANGUAGE_PREFERENCES_SUCCESS = '[TENANT] get translation language success preferences';
export const getTranslationPrefeerencesSuccessAction = createAction(
  GET_TRANSLATION_LANGUAGE_PREFERENCES_SUCCESS,
  props<{ payload: TranslationLanguage }>()
);

//  ----------------------------------------------------------------------
export const FETCH_SUPPORTED_LANGUAGES = '[TENANT] fetch Supported Languages';
export const fetchSupportedLanguagesAction = createAction(
  FETCH_SUPPORTED_LANGUAGES,
);

export const FETCH_SUPPORTED_LANGUAGES_SUCCESS = '[TENANT] fetch Supported Languages success';
export const fetchSupportedLanguagesSuccessAction = createAction(
  FETCH_SUPPORTED_LANGUAGES_SUCCESS,
  props<{ payload: { languages: LangModel[] } }>()
);

/* ---------------------------- signatrure status --------------------------- */
export const FETCH_USER_SIGNATURE_STATUS = '[TENANT] fetch Signature status';
export const FetchUserSignatureStatusAction = createAction(
  FETCH_USER_SIGNATURE_STATUS,
);

export const FETCH_USER_SIGNATURE_STATUS_SUCCESS = '[TENANT] fetch Signature status success';
export const FetchUserSignatureStatusSuccessAction = createAction(
  FETCH_USER_SIGNATURE_STATUS_SUCCESS,
  props<{ payload: { status: boolean } }>()
);

export const SET_USER_SIGNATURE_STATUS = '[TENANT] set user signature Status';
export const SetUserSignatureStatusAction = createAction(
  SET_USER_SIGNATURE_STATUS,
  props<{ payload: { status: boolean } }>()
);

export const SET_USER_SIGNATURE_STATUS_FAIL = '[TENANT] set user signature Status fail';
export const SetUserSignatureStatusFailAction = createAction(
  SET_USER_SIGNATURE_STATUS_FAIL,
  props<{ payload: { errorMessage: string } }>()
);

/* ---------------------------- signatrure status --------------------------- */

export const FETCH_USER_SIGNATURE_VALUE = '[TENANT] fetch Signature Value';
export const FetchUserSignatureValueAction = createAction(
  FETCH_USER_SIGNATURE_VALUE,
);

export const FETCH_USER_SIGNATURE_VALUE_SUCCESS = '[TENANT] fetch Signature Value success';
export const FetchUserSignatureValueSuccessAction = createAction(
  FETCH_USER_SIGNATURE_VALUE_SUCCESS,
  props<{ payload: { value: string } }>()
);

export const SET_USER_SIGNATURE_VALUE = '[TENANT] set user signature Value';
export const SetUserSignatureValueAction = createAction(
  SET_USER_SIGNATURE_VALUE,
  props<{ payload: { value: string } }>()
);

export const SET_USER_SIGNATURE_VALUE_FAIL = '[TENANT] set user signature Value fail';
export const SetUserSignatureValueFailAction = createAction(
  SET_USER_SIGNATURE_VALUE_FAIL,
  props<{ payload: { errorMessage: string } }>()
);

/* ----------------------------------- --- ---------------------------------- */

export const TOGGLE_CHANNEL_SYNTH = '[TENANT] toggle channel synth';
export const ToggleChannelSynthAction = createAction(
  TOGGLE_CHANNEL_SYNTH,
  props<{ payload: ToggleChannelSynthRequest }>()
);

export const TOGGLE_CHANNEL_SYNTH_SUCCESS = '[TENANT] toggle channel synth success';
export const ToggleChannelSynthSuccessAction = createAction(
  TOGGLE_CHANNEL_SYNTH_SUCCESS,
  props<{ payload: ToggleChannelSynthRequest }>()
);

export const TOGGLE_CHANNEL_SYNTH_FAIL = '[TENANT] toggle channel synth fail';
export const ToggleChannelSynthFailAction = createAction(
  TOGGLE_CHANNEL_SYNTH_FAIL,
  props<{ payload: ToggleChannelSynthRequest }>()
);

/* ------------------------- synth autoreply default ------------------------ */

export const FETCH_SYNTHAUTOREPLYDEFAULT_ACTION = '[TENANT] fetch synth autoreply default action'
export const FetchSynthAutoreplyDefaultAction = createAction(
  FETCH_SYNTHAUTOREPLYDEFAULT_ACTION
)

export const SET_SYNTHAUTOREPLYDEFAULT_ACTION = '[TENANT] Set synth autoreply default action'
export const SetSynthAutoreplyDefaultAction = createAction(
  SET_SYNTHAUTOREPLYDEFAULT_ACTION,
  props<{ payload: { status: boolean } }>()
)

export const FETCH_SYNTHAUTOREPLYDEFAULT_ACTION_SUCCESS = '[TENANT] fetch synth autoreply default success';
export const fetchSynthAutoreplyDefaultSuccessAction = createAction(
  FETCH_SYNTHAUTOREPLYDEFAULT_ACTION_SUCCESS,
  props<{ payload: { status: boolean } }>()
)
export const SET_SYNTHAUTOREPLYDEFAULT_ACTION_FAIL = '[TENANT] Set synth autoreply default fail';
export const SetSynthAutoreplyDefaultFailAction = createAction(
  SET_SYNTHAUTOREPLYDEFAULT_ACTION_FAIL,
  props<{ payload: { errorMessage: string } }>()
)

export const SEND_USER_FINGERPRINT = '[TENANT] Send User Fingerprint Action';
export const SendUserFingerprintAction = createAction(
  SEND_USER_FINGERPRINT
);

export const SEND_USER_FINGERPRINT_SUCCESS = '[TENANT] Send User Fingerprint success Action';
export const SendUserFingerprintSuccessAction = createAction(
  SEND_USER_FINGERPRINT_SUCCESS
);

const all = union({
  GetTenantInternalChannelsAction,
  ToggleChannelSynthSuccessAction,
  ToggleChannelSynthFailAction,
  SetSynthAutoreplyDefaultAction,
  GetInternalChannelsSuccessAction,
  GetInternalChannelsFailAction,
  UpdateTenantProfile,
  ToggleChannelSynthAction,
  GetTenantProfileAction,
  ToggleDoNotDisturbStatus,
  getTranslationPrefeerencesAction,
  setTranslationLanguagePreferencesAction,
  getTranslationPrefeerencesSuccessAction,
  fetchSupportedLanguagesAction,
  FetchUserSignatureStatusAction,
  SetUserSignatureStatusAction,
  FetchUserSignatureValueAction,
  SetUserSignatureValueAction,
  UpdateTenantBusinessNameAction,
  UpdateTenantBusinessNameSuccessAction,
  UpdateTenantBusinessNameErrorAction
})

export type TenantActions = typeof all;
