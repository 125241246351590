import {
  ChangeDetectionStrategy,
  Component,
  Input,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() disabled = false;
  @Input() loading = false;
  @Input() type = 'button';
  @Output() onClick = new EventEmitter();
  @Input() color:
    | 'primary'
    | 'light'
    | 'warning'
    | 'success'
    | 'accent'
    | 'transparent-primary'
    | 'transparent-warning'
    | 'transparent-secondary' = 'primary'

  ngOnInit(): void { }
}
