/**
 * A directive that will serve as a flag for drag and drop.
 * Author: Royce
 * Date Created: Wednesday, Sep 14, 4:03 PM
 * 
 */

import {
    Directive,
    Output,
    Input,
    EventEmitter,
    HostBinding,
    HostListener
} from '@angular/core';

import { validateFilesType } from '@/app/Utilities/helpers';

@Directive({
    selector: '[appFileUploader]'
})
export class FileUploaderDirective {
    @HostBinding('class.fileover') fileOver: boolean;
    @Input() validFileExt: string[]; // required
    @Input() processFiles = false;

    @Output() fileDropped = new EventEmitter<any>();

    @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.fileOver = true;
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.fileOver = false;
    }

    @HostListener('drop', ['$event']) public onDrop(event: any) {
        event.preventDefault();
        event.stopPropagation();
        this.fileOver = false;
        const files = validateFilesType(event.dataTransfer.files, this.validFileExt);
        files.length > 0 && this.fileDropped.emit(files);
    }

}
