import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, tap } from 'rxjs';
import { UseCase } from '../UseCase';
import { NoteBody } from '@/app/Data/DTO/NoteBodyDto';
import { Note } from '@/app/core/Models/Note';
import { INotesRepository } from '@/app/core/IRepositories/INotesRepository';
import { INotificationRepository } from '@/app/core/IRepositories/INotificationRepository';
import { IMediaRepository } from '../../IRepositories/IMediaRepository';

@Injectable({
  providedIn: 'root',
})
export class CreateUnclassifiedChannelNoteUseCase
  implements UseCase<NoteBody, Note>
{
  constructor(
    private notesRepository: INotesRepository,
    private mediaRepository: IMediaRepository,
  ) { }

  async execute(body: NoteBody): Promise<Note> {
    const { note, unclassifiedChannelId } = body.getBody();
    if (body.hasNewAttachments) {
      const media_urls = await firstValueFrom(
        this.mediaRepository.uploadAttachments(body.getBodyAttachment(), body.uploadProgress$)
      );
      note.attachments = media_urls
    }
    const noteResult = await firstValueFrom(
      this.notesRepository.createUnclassifiedChannelNote(note, unclassifiedChannelId!)
    );
    console.log(noteResult);
    this.notesRepository.saveNotes([noteResult]);
    return noteResult;
  }
  
}
