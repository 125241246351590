import { ButtonModule } from '@/app/Ui/components/Button/button.module';
import { AvatarModule } from '@/app/Ui/components/avatar/avatar.module';
import { TaskDetailsModule } from '@/app/Ui/tasks/task-details/task-details.module';
import { TaskFormComponent } from '@/app/Ui/tasks/task-form/task-form.component';
import { A11yModule } from '@angular/cdk/a11y';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AttachmentsViewerModule } from '../../components/attachments-viewer/attachments-viewer.module';
import { FormattedTextareaModule } from '../../components/formatted-textarea/formatted-textarea.module';
import { InputWrapperModule } from '../../components/new-input-wrapper/input-wrapper.module';
import { TextInputModule } from '../../components/new-text-input/text-input.module';
import { TeamMemberPickerModule } from '../../components/team-member-picker/team-member-picker.module';
import { DropDownPanelDirectiveModule } from '../../directives/dropdown-panel.directive';
import { FilePickerDirectiveModule } from '../../directives/file-picker.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
@NgModule({
  declarations: [TaskFormComponent],
  exports: [TaskFormComponent],
  imports: [
    FormattedTextareaModule,
    CommonModule,
    TextInputModule,
    FormsModule,
    TaskDetailsModule,
    MatRippleModule,
    ButtonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    AngularSvgIconModule,
    MatIconModule,
    TeamMemberPickerModule,
    CdkScrollableModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    DropDownPanelDirectiveModule,
    AvatarModule,
    MatDividerModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSelectModule,
    TranslateModule,
    A11yModule,
    AttachmentsViewerModule,
    FilePickerDirectiveModule,
    InputWrapperModule,
    MatDatepickerModule,
  ],
  providers: [],
})
export class TaskFormModule { }
