import { Pipeline, Stage } from "./crm";
import { DealProduct } from "./product";

export class Deal {
    amount: number;
    deal_name: string;
    client_id: string;
    close_date: string;
    created_dt: string;
    stage_updated_dt: string;
    description: string;
    is_company: true;
    tenant_id: string;
    updated_dt: string;
    owner_id: string;
    yobi_crm_deal_id: string;
    isChecked?: boolean
    owner?: Owner;
    current_stage: CurrentStage;
    pipeline: Pipeline;
    assigned_company: AssignedCompany;
    assigned_contact: AssignedContact;
    priority: 'None' | 'Low' | 'Medium' | 'High'
    source: string;
    total_count: number;
    pipeline_name?: string;
    stage_name?: string;
    company_name?: string;
    contact_name?: string;
    user_name?: string;
    products?: Product[]
    created_dt_timestamps?: number
    attachments?: Array<{ filename: string, type: string, url: string }>
    assignee?: string;
    stage_updated_dt_timestamps?: number
  
}

export class Product {
    yobi_crm_product_id: string
    product_name: string
    unit_price: number
    description: string
}

export class Owner {
    owner_id: string;
    owner_name: string;
    username: string;
    avatar: string
}

export class AssignedCompany {
    company_name: string;
    avatar: string;
    yobi_crm_company_id: string;
}

export class AssignedContact {
    contact_name: string;
    avatar: string;
    yobi_crm_contact_id: string;
    contact_id?: string;
}

export class CurrentStage {
    description: string;
    stage_name: string;
    stage_order: number;
    yobi_crm_stage_id: string;
}
export class DealDetail extends Deal {
    avatar: string;
    client_name: string;
    journey_name: string;
    includes: DealProduct[];
}

export interface DealsQueryParams {
    page: number;
    page_size: number;
    yobi_crm_journey_id?: string;
    column?: string;
    direction?: 'asc' | 'desc';
    search?: string
    client_id?: string;
    is_company?: boolean;
    user_id?: string;
    is_canceled?: boolean;
    order_by?: string;
    get_my_deals?: boolean;
    pipeline_id?: string;
    stage_id?: string;
}
export interface UpdatedDealStageResponse {
  yobi_crm_deal_id: number
}
export interface UpdatedDealPriorityResponse {
  yobi_crm_deal_id: number
}
