export enum alert_dialog {
  Profile_updated = 'alert_dialog.Profile_updated',
  task_created = 'alert_dialog.task_created',
  task_updated = 'alert_dialog.task_updated',
  add_phone_channel = 'alert_dialog.add_phone_channel',
  busuness_hours_not_set = 'alert_dialog.busuness_hours_not_set',
  busuness_hours_set = 'alert_dialog.busuness_hours_set',
  calling_only_us_numbers_allowed = 'alert_dialog.calling_only_us_numbers_allowed',
  channel_disconnected = 'alert_dialog.channel_disconnected',
  channel_not_added = 'alert_dialog.channel_not_added',
  channel_not_exist = 'alert_dialog.channel_not_exist',
  channels_mismatch = 'alert_dialog.channels_mismatch',
  check_your_email = 'alert_dialog.check_your_email',
  check_your_phone = 'alert_dialog.check_your_phone',
  comment_not_added = 'alert_dialog.comment_not_added',
  comment_not_deleted = 'alert_dialog.comment_not_deleted',
  comment_not_edited = 'alert_dialog.comment_not_edited',
  contact_deleted = 'alert_dialog.contact_deleted',
  contact_not_created = 'alert_dialog.contact_not_created',
  contact_not_deleted = 'alert_dialog.contact_not_deleted',
  contact_not_updated = 'alert_dialog.contact_not_updated',
  contact_picture_not_updated = 'alert_dialog.contact_picture_not_updated',
  contact_picture_updated = 'alert_dialog.contact_picture_updated',
  copied_to_clipboard = 'alert_dialog.copied_to_clipboard',
  deleted = 'alert_dialog.deleted',
  download_started = 'alert_dialog.download_started',
  email_not_found = 'alert_dialog.email_not_found',
  file_max_size = 'alert_dialog.file_max_size',
  file_type_not_allowed = 'alert_dialog.file_type_not_allowed',
  phone_number_invalid = 'alert_dialog.phone_number_invalid',
  forward_number_invalid = 'alert_dialog.forward_number_invalid',
  invitation_email_not_sent = 'alert_dialog.invitation_email_not_sent',
  invitation_email_sent = 'alert_dialog.invitation_email_sent',
  invitation_sms_not_sent = 'alert_dialog.invitation_sms_not_sent',
  invitation_sms_sent = 'alert_dialog.invitation_sms_sent',
  max_attachments = 'alert_dialog.max_attachments',
  message_deleted = 'alert_dialog.message_deleted',
  message_not_deleted = 'alert_dialog.message_not_deleted',
  message_not_sent = 'alert_dialog.message_not_sent',
  note_deleted = 'alert_dialog.note_deleted',
  note_not_added = 'alert_dialog.note_not_added',
  note_not_deleted = 'alert_dialog.note_not_deleted',
  note_not_updated = 'alert_dialog.note_not_updated',
  one_phone_number_allowed = 'alert_dialog.one_phone_number_allowed',
  operation_not_possible = 'alert_dialog.operation_not_possible',
  password_changed = 'alert_dialog.password_changed',
  profile_not_updated = 'alert_dialog.profile_not_updated',
  profile_picture_not_updated = 'alert_dialog.profile_picture_not_updated',
  profile_picture_updated = 'alert_dialog.profile_picture_updated',
  promotion_code_applied = 'alert_dialog.promotion_code_applied',
  promotion_code_deleted = 'alert_dialog.promotion_code_deleted',
  recording_deleted = 'alert_dialog.recording_deleted',
  recording_not_deleted = 'alert_dialog.recording_not_deleted',
  saving_contact = 'alert_dialog.saving_contact',
  select_channel = 'alert_dialog.select_channel',
  select_contact_channel = 'alert_dialog.select_contact_channel',
  something_went_wrong = 'alert_dialog.something_went_wrong',
  tag_created = 'alert_dialog.tag_created',
  tag_deleted = 'alert_dialog.tag_deleted',
  tag_name_empty = 'alert_dialog.tag_name_empty',
  tag_not_created = 'alert_dialog.tag_not_created',
  tag_not_deleted = 'alert_dialog.tag_not_deleted',
  task_not_created = 'alert_dialog.task_not_created',
  task_not_updated = 'alert_dialog.task_not_updated',
  task_unassigned = 'alert_dialog.task_unassigned',
  translation_failed = 'alert_dialog.translation_failed',
  reformulation_failed = 'alert_dialog.reformulation_failed',
  unblock_contact_before_send = 'alert_dialog.unblock_contact_before_send',
  user_created = 'alert_dialog.user_created',
  user_deleted = 'alert_dialog.user_deleted',
  user_not_deleted = 'alert_dialog.user_not_deleted',
  user_updated = 'alert_dialog.user_updated',
  select_correponding_channel = 'alert_dialog.select_correponding_channel',
  wait_upload_to_complete = 'alert_dialog.wait_upload_to_complete',
  contact_could_not_be_merged = 'alert_dialog.contact_could_not_be_merged'
}