import { createSelector } from '@ngrx/store';
import { threadId } from 'worker_threads';
import { AppState } from '../AppState';
import { TeamInboxState } from './teamInbox.reducer';

const inboxState = (state: AppState) => state.teamInbox;

export const selectTeamInboxState = createSelector(
  inboxState,
  (state: TeamInboxState) => state
);

export const selectFocusedUser = createSelector(
  inboxState,
  (state: TeamInboxState) => state.focusedUser
);

export const selectTeamChatActiveUsers = createSelector(
  inboxState,
  (state: TeamInboxState) => state.activeUsers
);

export const selectActiveUser = createSelector(
  inboxState,
  (state: TeamInboxState) => state.activeUsers.find(u => u.user_id == state.focusedUser)
);

