import { createReducer, on } from "@ngrx/store";
import { UpdateIntegrationsStatusAction } from "./actions";

export interface IntegrationsState{
    Zoho: boolean;
    Zoom: boolean;
    Slack: boolean;
    Zapier: boolean;
    Zendesk: boolean;
    Outlook: boolean;
    Hubspot: boolean;
    Shopify: boolean;
    Calendly: boolean;
    Pipedrive: boolean;
    Bigcommerce: boolean;
    Microsoft_teams: boolean;
}

export const initIntegrationsState: IntegrationsState = {
    Zoho: false,
    Zoom: false,
    Slack: false,
    Zapier: false,
    Zendesk: false,
    Outlook: false,
    Hubspot: false,
    Shopify: false,
    Calendly: false,
    Pipedrive: false,
    Bigcommerce: false,
    Microsoft_teams: false
}

export const integrationsReducer = createReducer(
    initIntegrationsState,
    on(UpdateIntegrationsStatusAction, (state: IntegrationsState, { payload }) => {
        return {
            ...state,
            Zoho: payload.Zoho,
            Zoom: payload.Zoom,
            Slack: payload.Slack,
            Zapier: payload.Zapier,
            Zendesk: payload.Zendesk,
            Outlook: payload.Outlook,
            Hubspot: payload.Hubspot,
            Shopify: payload.Shopify,
            Calendly: payload.Calendly,
            Pipedrive: payload.Pipedrive,
            Bigcommerce: payload.Bigcommerce,
            Microsoft_teams: payload.Microsoft_teams
        }

    })
)