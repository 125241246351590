export enum NotificationType {
  add_user = 'add_user',
  note_mention = 'note_mention',
  task = 'task',
  task_mention = 'task_mention',
  task_done = 'task_done',
  task_created = 'create_task',
  task_commented = 'task_comment',
  inline_note = 'inline_note',
  inline_note_mention = 'inline_note_mention',
  task_reminder = 'task_reminder',
  team_chat_mention = 'team_chat_mention'
}

export class NotificationUser {
  avatar: string | null;
  created_dt: string;
  do_not_disturb: boolean;
  firstName: string;
  lastName: string;
  tenant_id: number;
  updated_dt: string;
  user_id: number;
  username: string;
}
export class Notification {
  additional_data:
    | { channel_id: number; contact_id: number | null; note_id: number }
    | number;
  created_dt: string;
  notification_description: string;
  notification_id: number;
  notification_type: NotificationType;
  read: boolean;
  tenant_id: number;
  triggered_by: NotificationUser;
  user_id: number;
  task_title: string;
  room_details: {
    avatar: string
    created_dt: string
    private: boolean
    room_id: any
    room_name: string
    tenant_id: string
    updated_dt: string
  }
}
