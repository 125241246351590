import { Observable } from 'rxjs';
import { VoicemailInteractionsQueryDto } from '@/app/Data/DTO/voicemailInteractionsQuery.dto';
import { VoicemailInteraction } from '../Models/Interaction';

export abstract class IVoicemailInteractiosRepository {
  abstract fetchVoicemails(query: VoicemailInteractionsQueryDto): Observable<VoicemailInteraction[]>;
  abstract saveVoicemails(data: VoicemailInteraction[], query: VoicemailInteractionsQueryDto): void;
  abstract saveVoicemail(data: VoicemailInteraction): void;
  abstract getLocalVoicemails(query: VoicemailInteractionsQueryDto): Observable<VoicemailInteraction[]>;
  abstract getLocalVoicemailInteraction(interaction_id: string | number): Observable<VoicemailInteraction | undefined>;
  abstract getLocalVoicemailInteractionByExternalId(external_interaction_id: string | number): Observable<VoicemailInteraction | undefined>;
  abstract updateLocalVoicemailInteraction(interaction: Partial<VoicemailInteraction> & Pick<VoicemailInteraction, 'interaction_id'>): void;

}
