import { VoiceMailTranscriptionDto } from '@/app/Data/DTO/TranscriptionDto';
import { VoicemailInteractionsQueryDto } from '@/app/Data/DTO/voicemailInteractionsQuery.dto';
import { VoicemailInteraction } from '@/app/core/Models/Interaction';
import { createAction, props, union } from '@ngrx/store';

/* --------------------------- voicemails fetching -------------------------- */
export const SET_ACTIVE_VOICEMAIL = '[VOICEMAILS] Set active voicemail';
export const SetActiveVoicemailAction = createAction(
  SET_ACTIVE_VOICEMAIL,
  props<{ payload: VoicemailInteraction }>()
);

/* --------------------------- voicemails fetching -------------------------- */
export const FETCH_VOICEMAILS = '[VOICEMAILS] FETCH VOICEMAILS';
export const FetchVoicemailsAction = createAction(
  FETCH_VOICEMAILS,
  props<{ payload: { query: VoicemailInteractionsQueryDto } }>()
);

export const FETCH_VOICEMAILS_SUCCESS = '[VOICEMAILS] FETCH VOICEMAILS Success';
export const FetchVoicemailsSuccessAction = createAction(
  FETCH_VOICEMAILS_SUCCESS,
  props<{ payload: { voicemails: VoicemailInteraction[], query: VoicemailInteractionsQueryDto } }>()
);

export const FETCH_VOICEMAILS_FAIL = '[VOICEMAILS] FETCH VOICEMAILS Fail';
export const FetchVoicemailsFailAction = createAction(
  FETCH_VOICEMAILS_FAIL);

/* --------------------------- voicemail handling --------------------------- */
export const HANDLE_INCOMING_VOICEMAIL = '[VOICEMAILS] HANDLE INCOMING VOICEMAIL';
export const HandleIncomingVoicemailAction = createAction(
  HANDLE_INCOMING_VOICEMAIL,
  props<{ payload: VoicemailInteraction  }>()
);

export const INSERT_VOICEMAIL_LIVE_TRANSCRIPTION = '[VOICEMAILS] Insert Voicemail Live Transcription';
export const InsertVoicemailLiveTranscriptionAction = createAction(
  INSERT_VOICEMAIL_LIVE_TRANSCRIPTION,
  props<{ payload: VoiceMailTranscriptionDto }>()
);

export const UPDATE_LOCAL_VOICEMAIL_INTERACTION = '[VOICEMAILS] update local voicemail interaction';
export const UpdateLocalVoicemailInteraction = createAction(
  UPDATE_LOCAL_VOICEMAIL_INTERACTION,
  props<{ payload: { interaction: Partial<VoicemailInteraction> & Pick<VoicemailInteraction, 'interaction_id'> } }>()
);

export const SET_NEW_UNREAD_VOICEMAIL = '[VOICEMAILS] Set New Unread Voicemail Action';
export const SetNewUnreadVoicemailAction = createAction(
  SET_NEW_UNREAD_VOICEMAIL,
)
;
export const RESET_VOICEMAIL_STATE = '[VOICEMAILS] reset voicemail state Action';
export const ResetVoicemailStateAction = createAction(
  RESET_VOICEMAIL_STATE,
);

const all = union({
  FetchVoicemailsAction,
  FetchVoicemailsSuccessAction,
  UpdateLocalVoicemailInteraction,
  InsertVoicemailLiveTranscriptionAction,
  SetActiveVoicemailAction,
  FetchVoicemailsFailAction,
  HandleIncomingVoicemailAction,
});
export type VoicemailsActions = typeof all;