import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ChannelIconComponent } from '@/app/Ui/components/channel-icon/channel-icon.component';

@NgModule({
  declarations: [ChannelIconComponent],
  imports: [CommonModule, AngularSvgIconModule],
  exports: [ChannelIconComponent],
})
export class ChannelIconModule {}
