import { Localizations } from '@/app/Utilities/localization';
import { ITenantRepository } from '@/app/core/IRepositories/ITenantRepository';
import { InternalChannel } from '@/app/core/Models/Channel';
import { EditProfileUseCase } from '@/app/core/usecases/Tenant/EditProfileUseCase';
import { EditProfileDto } from '@/app/Data/DTO/EditProfileDto';
import { ProfileImageUpdatResponseDto } from '@/app/Data/DTO/ProfileImageUpdatResponseDto';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { updateTranslatePreferencesDto } from '@/app/Data/DTO/translationDto';
import { ErrorApiReponse } from '@/app/Data/services/Networking/ApiResponse';
import { AppState } from '@/app/State/AppState';
import { FetchUserSignatureStatusAction, fetchSupportedLanguagesAction, GetTenantInternalChannelsAction, GetTenantProfileAction, setTranslationLanguagePreferencesAction, getTranslationPrefeerencesAction, SetUserSignatureStatusAction, FetchUserSignatureValueAction, SetUserSignatureValueAction, SetSynthAutoreplyDefaultAction, FetchSynthAutoreplyDefaultAction, UpdateTenantBusinessNameAction } from '@/app/State/Tenant/tenant.action';
import { selectSupportedLanguages, selectSynthAutoreplyDefault, selectTenantCustomFields, selectTenantProfile, selectTenantSignature, selectTranslationLanguage } from '@/app/State/Tenant/tenant.selector';
import { selectSupportChannel } from '@/app/State/thread/thread.selector';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, combineLatest, firstValueFrom, map, Observable, Observer, shareReplay, tap } from 'rxjs';
import { CompanyLogoUpdatResponseDto } from '@/app/Data/DTO/CompanyLogoUpdatResponseDto';
import { Roles } from '@/app/core/Models/Roles';

@Injectable({
  providedIn: 'root',
})
export class TenantViewModel {
  readonly profile$ = this.store.select(selectTenantProfile);
  readonly customFields$ = this.store.select(selectTenantCustomFields);
  readonly translationLanguage$ = this.store.select(selectTranslationLanguage);
  readonly supportedLanguages$ = this.store.select(selectSupportedLanguages);
  readonly signature$ = this.store.select(selectTenantSignature);
  readonly isAdmin$ = this.profile$.pipe(map(p => !!p?.roles.includes(Roles.admin)))
  readonly synthAutoreplyDefault$ = this.store.select(selectSynthAutoreplyDefault)
  readonly userPermissisions = this.profile$.pipe(map((p) => { return p?.user_permissions; }));

  internalChannels$: Observable<InternalChannel[]> = combineLatest([
    this.tenantRepository.getLocalInternalChannels(),
    this.store.select(selectSupportChannel),
  ]).pipe(
    map(([internalChannels, supportChannel]) =>
      [...internalChannels, supportChannel!].filter(c => !!c)
    ),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  tenantCustomFields$ = this.getTenantCustomFields();

  constructor(
    private store: Store<AppState>,
    private editProfileUseCase: EditProfileUseCase,
    private tenantRepository: ITenantRepository,
    private snackBarService: SnackbarService
  ) { }

  getTenantInternalChannels(): void {
    this.store.dispatch(GetTenantInternalChannelsAction());
  }

  fetchTenantProfile(): void {
    this.store.dispatch(GetTenantProfileAction());
  }
  editProfile(data: EditProfileDto): Observable<SuccessResponse> {
    return this.editProfileUseCase.execute(data).pipe(
      tap(() => {
        this.snackBarService.openAlert({
          message: Localizations.alert_dialog.Profile_updated,
          type: 'success',
        });
        this.store.dispatch(GetTenantProfileAction());
      }),
      catchError((error: ErrorApiReponse<any>) => {
        this.snackBarService.openAlert({
          message: Localizations.alert_dialog.profile_not_updated,
          type: 'failure',
        });
        throw error;
      })
    );
  }

  updateProfilePicture(
    data: FormData,
    userId: number,
    progress$: Observer<any>
  ): Observable<ProfileImageUpdatResponseDto> {
    return this.tenantRepository
      .updateProfileImage(data, userId, progress$)
      .pipe(
        tap(() => {
          this.snackBarService.openAlert({
            message: Localizations.alert_dialog.profile_picture_updated,
            type: 'success',
          });
          this.store.dispatch(GetTenantProfileAction());
        }),
        catchError((error) => {
          this.snackBarService.openAlert({
            message: Localizations.alert_dialog.profile_picture_not_updated,
            type: 'failure',
          });
          throw error;
        })
      );
  }

  updateCompanyLogo(
    data: FormData,
    progress$: Observer<any>
  ): Observable<CompanyLogoUpdatResponseDto> {
    return this.tenantRepository
      .updateCompanyLogo(data, progress$)
      .pipe(
        tap(() => {
          this.snackBarService.openAlert({
            message: 'Company logo updated successfully',
            type: 'success',
          });
          this.store.dispatch(GetTenantProfileAction());
        }),
        catchError((error) => {
          this.snackBarService.openAlert({
            message: 'Company logo could not be updated',
            type: 'failure',
          });
          throw error;
        })
      );
  }

  deleteProfilePicture(userId: number): Observable<SuccessResponse> {
    return this.tenantRepository.deleteProfileImage(userId).pipe(
      tap(() => {
        this.store.dispatch(GetTenantProfileAction());
      }),
      catchError((error) => {
        this.snackBarService.openAlert({
          message: Localizations.alert_dialog.operation_not_possible,
          type: 'failure',
        });
        throw error;
      })
    );
  }

  copiedToClipbaord(valueOrType = '') {
    this.snackBarService.openAlert({
      message: Localizations.alert_dialog.copied_to_clipboard,
      translateParams: { type: valueOrType },
      type: 'success',
    });
  }

  fetchTranslationLanguagePreferences() {
    this.store.dispatch(getTranslationPrefeerencesAction())
  }

  setTranslationLanguagePreferences(preferences: updateTranslatePreferencesDto) {
    this.store.dispatch(setTranslationLanguagePreferencesAction({ payload: preferences }))
  }

  fetchSupportedLanguages() {
    firstValueFrom(this.supportedLanguages$).then((supportedLanguages) =>
      !supportedLanguages.length && this.store.dispatch(fetchSupportedLanguagesAction())
    )
  }

  fetchSignatureStatus() {
    this.store.dispatch(FetchUserSignatureStatusAction())
  }

  fetchSignatureValue() {
    this.store.dispatch(FetchUserSignatureValueAction())
  }

  setUserSignatureStatus(status: boolean) {
    this.store.dispatch(SetUserSignatureStatusAction({ payload: { status } }))
  }

  setUserSignatureValue(value: string) {
    this.store.dispatch(SetUserSignatureValueAction({ payload: { value } }))
  }

  fetchSynthAutoreplyDefault() {
    this.store.dispatch(FetchSynthAutoreplyDefaultAction())
  }

  setSynthAutoreplyDefault(status: boolean) {
    this.store.dispatch(SetSynthAutoreplyDefaultAction({ payload: { status } }))
  }

  setBusinessName(businessName: string): void {
    this.store.dispatch(UpdateTenantBusinessNameAction({ payload: { businessName } }));
  }

  setUserPreferredNumber(phone_number: string): Observable<string> {
    return this.tenantRepository.setPhoneNumber({ phone_number }).pipe(
      tap(() => {
        this.snackBarService.openAlert({
          message: `Your preferred phone number has been updated to ${phone_number}.`,
          type: "success"
        }), catchError((httpError: ErrorApiReponse<any>) => {
          this.snackBarService.openAlert({ message: httpError.message, type: 'failure' });
          throw httpError;
        })
      }));
  }

  fetchUserPreferredPhoneNumber(): Observable<string> {
    return this.tenantRepository.fetchUserPhoneNumber();
  }

  getTenantCustomFields() {
    return this.tenantRepository.getTenantCustomFields();
  }

  deleteTenantCustomField(id: string) {
    return this.tenantRepository.deleteTenantCustomFields(id);
  }

  getTenantIndustry() {
    return this.tenantRepository.getTenantIndustry();
  }

  updateTenantIndustry(data: Array<{ industry_type: string, description: string, tenant_company_industry_id?: number }>) {
    return this.tenantRepository.updateTenantIndustry(data).pipe(
      tap(() => {
        return this.getTenantIndustry();
      }));
  }

}
