import { AnalyticEvent, AnalyticsEventsNames } from '@/app/shared/analytics/analyticsEvents';
import { Tenant } from '@/app/core/Models/tenant';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { init, Mixpanel } from 'mixpanel-browser';
import { BehaviorSubject, filter, firstValueFrom, tap } from 'rxjs';
import { PlatformService } from '../../Utilities/platform/platform.service';

// using a $ sign befor a property like $user_id will become User Id (super property)
@Injectable({
	providedIn: 'root'
})
export class MixpanelService {
	mixpanel$ = new BehaviorSubject<Mixpanel | null>(null)
	mixpanel: Mixpanel
	tenant: Tenant | undefined
	constructor(
		private platformService: PlatformService
	) { }

	init() {
		init(environment.mixpanel.token, {
			// debug: !environment.production,
			loaded: (mixpanel) => {
				this.mixpanel$.next(mixpanel);
			}
		})
	};

	async register(tenant: Tenant) {
		// https://help.mixpanel.com/hc/en-us/articles/115004497803
		if (tenant.userId !== this.tenant?.userId) {
			const mixpanel = this.mixpanel ?? await this.getMixpanelInstance();
			this.tenant = tenant
			mixpanel.identify(tenant.userId.toString())
			mixpanel.register_once({
				tenant_id: tenant.tenantId,
				user_id: tenant.userId,
				email: tenant.email,
				plan_type: tenant.plan_type
			})
			this.updateProfile(tenant)
			mixpanel.set_group('tenant_id', tenant.tenantId)


		}
	}

	async updateProfile(tenant: Tenant) {
		const mixpanel = this.mixpanel ?? await this.getMixpanelInstance()
		mixpanel.people.set({
			first_name: tenant.firstName,
			last_name: tenant.lastName,
			email: tenant.email,
			business_name: tenant.businessName,
			tenant_id: tenant.tenantId,
			user_id: tenant.userId,
		})
	}

	async track(event: AnalyticEvent) {
		let api_transport: 'XHR' | 'sendBeacon' = "XHR";
	if	(event.name == AnalyticsEventsNames.APP_CLOSED ||
		 event.name == AnalyticsEventsNames.SESSION_END) {
			api_transport = "sendBeacon"
		 }
		const mixpanel = this.mixpanel ?? await this.getMixpanelInstance()
		mixpanel.track(event.name, {
			...event.properties,
			...this.defaultProperties,
			api_transport
		})
	};

	async trackAppClosed() {
		const mixpanel = this.mixpanel ?? await this.getMixpanelInstance()
		mixpanel.set_config({ api_transport: 'XHR', batch_flush_interval_ms: 1000 });
		mixpanel.track(AnalyticsEventsNames.SESSION_END, {
			...this.defaultProperties
		})
		mixpanel.track(AnalyticsEventsNames.APP_CLOSED, {
			...this.defaultProperties
		})
	};

	async reset() {
		//return; // track events after logout like app closed
		const mixpanel = await this.getMixpanelInstance()
		this.tenant = undefined
		mixpanel.reset()
		//mixpanel.clear_opt_in_out_tracking()
	}

	get defaultProperties() {
		return{
			device_type: this.platformService.device,
			app_version: environment.appVersion,
			tenant_id: this.tenant?.tenantId,
			user_id: this.tenant?.userId,
			email: this.tenant?.email,
		}
	}

	getMixpanelInstance(): Promise<Mixpanel> {
		return firstValueFrom(this.mixpanel$.pipe(
			filter((id: Mixpanel | null): id is Mixpanel => id !== null),
			tap(mixpanel => this.mixpanel = mixpanel)
		))
	}
}
