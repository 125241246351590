import { LocalizationViewModel } from '@/app/Ui/ViewModels/localizationViewModel';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-auth-banner',
  templateUrl: './auth-banner.component.html',
  styleUrls: ['./auth-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthBannerComponent implements OnInit {

  localization = this.localizationViewModel.localization;

  constructor(
    private localizationViewModel: LocalizationViewModel
  ) { }

  ngOnInit(): void {
  }

}
