import { ILogEntry } from './ILogEntry';
import { Severity } from './Severity';

export class LogEntry implements ILogEntry {
  constructor(
    public source: string,
    public message: string,
    public severity: Severity,
    public timestamp: Date,
    public tags: string[] = []
  ) {}
}
