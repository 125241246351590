import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nameInitial'
})

export class NameInitialPipe implements PipeTransform {
    transform(name: string): string {
        // Remove "(Tenant n)" and special characters
        const cleanedName = name.replace(/\(Tenant (\d)+\)/, '').replace(/[-\/:-@\[-\`{-~]/g, '');

        // Split the cleaned name into words
        const words = cleanedName.split(/\s+/).filter(item => !!item.length);

        // If there are more than one word, use them; otherwise, split the single word into characters
        const parts = words.length > 1 ? words : words[0]?.split('');

        // Take the first two words and capitalize their first letters
        const initials = parts.slice(0, 2).map(word => word.charAt(0).toUpperCase());

        // Set the result as the 'text' property
        return initials.join('');
    }
}

@NgModule({
    declarations: [NameInitialPipe],
    exports: [NameInitialPipe],
    imports: [],
    providers: [],
})
export class NameInitialPipeModule {
    constructor() { }
}