import { TeamMember } from '@/app/core/Models/TeamMember';
import { VoiceClientHoldContactDto } from '@/app/Data/DTO/VoiceClient/VoiceClientHoldContactDto';
import { createAction, props, union } from '@ngrx/store';
import { VoiceClientTokenDto } from '@/app/Data/DTO/VoiceClientTokenDto';
import { CallRequestDto } from '@/app/Data/DTO/CallRequestDto';
import { Call } from '@twilio/voice-sdk';
import { IncomingCallParamsDto } from '@/app/Data/DTO/IncomingCallParamsDto';
import { PhoneState } from '@/app/Data/twilio/voice-client.service';

export const GET_VOICE_CLIENT_TOKEN = '[VOICE CLIENT] Get Voice Client Token';
export const GetVoiceClientTokenAction = createAction(GET_VOICE_CLIENT_TOKEN);

export const UPDATE_VOICE_CLIENT_TOKEN = '[VOICE CLIENT] Update Voice Client Token';
export const UpdateVoiceClientTokenAction = createAction(UPDATE_VOICE_CLIENT_TOKEN);


export const GET_VOICE_CLIENT_TOKEN_SUCCESS = '[VOICE CLIENT] Get Voice Client Token Success';
export const GetVoiceClientTokenSuccessAction = createAction(
  GET_VOICE_CLIENT_TOKEN_SUCCESS,
  props<{ payload: VoiceClientTokenDto }>()
);

export const MAKE_VOICE_CALL_REQUEST = '[VOICE CLIENT] Make Voice Call Request';
export const MakeVoiceRequestCallAction = createAction(
  MAKE_VOICE_CALL_REQUEST,
  props<{ payload: CallRequestDto }>()
);

export const MAKE_VOICE_CALL_CONNECTED = '[VOICE CLIENT] Voice call request connected';
export const MakeVoiceCallConnectedAction = createAction(
  MAKE_VOICE_CALL_CONNECTED,
  props<{ payload: Call }>()
);

export const CALL_HANGUP = '[VOICE CLIENT] Call Hangup';
export const CallHangUpAction = createAction(
  CALL_HANGUP,
  props<{ payload: { parentCallSid: string } }>()
);

export const CALL_TOGGLE_MUTE = '[VOICE CLIENT] Toggle Call Mute';
export const CallToggleMuteAction = createAction(CALL_TOGGLE_MUTE);

export const OPEN_DIALER = '[DIALER] Open Dialer';
export const OpenDialerAction = createAction(OPEN_DIALER);

export const CLOSE_DIALER = '[DIALER] Close Dialer';
export const CloseDialerAction = createAction(CLOSE_DIALER);

export const CLOSE_ACTIVE_DIALER = '[DIALER] Close Active Dialer';
export const CloseActiveDialerAction = createAction(CLOSE_ACTIVE_DIALER);

export const SET_INCOMING_CALL_INFORMATION = '[VOICE CLIENT] Set Incoming Call Information';
export const SetIncomingCallInformationAction = createAction(
  SET_INCOMING_CALL_INFORMATION,
  props<{ payload: IncomingCallParamsDto }>()
);

//////////////////// DEVICE STATE ///////////////////////
export const VOICE_DEVICE_STATE_CHANGED = '[VOICE CLIENT] Viuce Device State Changed';
export const VoiceDeviceStateChangeAction = createAction(
  VOICE_DEVICE_STATE_CHANGED,
  props<{ payload: { state: PhoneState, parentCallSid: string } }>()
);

////// ACTIVE CALL //////

export const SET_MUTE = '[VOICE CLIENT] Set Mute ';
export const SetMute = createAction(
  SET_MUTE,
  props<{ payload: { mute: boolean, parentCallSid: string } }>()
);

// Incoming Call Canceled
export const VOICE_CLIENT_INCOMING_CALL_CANCELED = '[VOICE CLIENT] Incoming Call Canceled ';
export const VoiceClientIncomingCallCanceledAction = createAction(
  VOICE_CLIENT_INCOMING_CALL_CANCELED,
  props<{ payload: { parentCallSid: string } }>()
);

export const VOICE_CLIENT_DEVICE_ERROR = '[VOICE CLIENT] Voice Client Device Error';
export const VoiceClientDeviceErrorAction = createAction(
  VOICE_CLIENT_DEVICE_ERROR,
  props<{ payload: { parentCallSid: string } }>()
);

export const VOICE_CLIENT_ACTIVE_CALL_DISCONNECT = '[VOICE CLIENT] Voice Client Active Call Disconnect';
export const VoiceClientActiveCallDisconnectAction = createAction(
  VOICE_CLIENT_ACTIVE_CALL_DISCONNECT,
  props<{ payload: { parentCallSid: string } }>()
);

export const VOICE_CLIENT_ACTIVE_CALL_REJECT = '[VOICE CLIENT] Voice Client Active Call Reject';
export const VoiceClientActiveCallRejectAction = createAction(
  VOICE_CLIENT_ACTIVE_CALL_REJECT,
  props<{ payload: { parentCallSid: string } }>()
);

export const VOICE_CLIENT_ClearCallSessionAction = '[VOICE CLIENT] Voice Client clear call session';
export const VoiceClientClearCallSessionAction = createAction(
  VOICE_CLIENT_ClearCallSessionAction,
  props<{ payload: { parentCallSid: string } }>()
);

export const VOICE_CLIENT_ACTIVE_CALL_CLEAR_STATE = '[VOICE CLIENT] Voice Client Active Call Clear State';
export const VoiceClientActiveCallClearStateAction = createAction(
  VOICE_CLIENT_ACTIVE_CALL_CLEAR_STATE);

export const VOICE_CLIENT_CUSTOMER_HOLD = '[VOICE CLIENT] Customer put on hold';
export const VoiceClientCustomerHoldAction = createAction(
  VOICE_CLIENT_CUSTOMER_HOLD,
  props<{ payload: VoiceClientHoldContactDto }>()
);

export const VOICE_CLIENT_UPDATE_INCOMING_TRANSFER_CALL_FROM_USER = '[VOICE CLIENT] Update incoming transfer call from user';
export const VoiceClientUpdateIncomingtransferCallFromUserAction = createAction(
  VOICE_CLIENT_UPDATE_INCOMING_TRANSFER_CALL_FROM_USER,
  props<{ payload: { user: TeamMember, parentCallSid: string } }>()
);

export const VOICE_CLIENT_VERIFY_ACTIVE_CALLER = '[VOICE CLIENT] Verify Active Caller';
export const VoiceClientVerifyActiveCallerAction = createAction(
  VOICE_CLIENT_VERIFY_ACTIVE_CALLER
);

const all = union({
  GetVoiceClientTokenAction,
  UpdateVoiceClientTokenAction,
  GetVoiceClientTokenSuccessAction,
  MakeVoiceRequestCallAction,
  MakeVoiceCallConnectedAction,
  CallHangUpAction,
  CallToggleMuteAction,
  OpenDialerAction,
  CloseDialerAction,
  CloseActiveDialerAction,
  SetIncomingCallInformationAction,
  VoiceDeviceStateChangeAction,
  SetMute,
  VoiceClientIncomingCallCanceledAction,
  VoiceClientDeviceErrorAction,
  VoiceClientActiveCallDisconnectAction,
  VoiceClientActiveCallRejectAction,
  VoiceClientActiveCallClearStateAction,
  VoiceClientCustomerHoldAction,
  VoiceClientUpdateIncomingtransferCallFromUserAction,
  VoiceClientVerifyActiveCallerAction
});

export type VoiceClientActionsUnion = typeof all;