import { Component, OnInit } from '@angular/core';
import { TourService } from '../../Data/services/Tour/tour.service';

@Component({
  selector: 'app-tour-control',
  template: ``,
  styles: [`
    .tour-button {
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 9997;
      padding: 10px 20px;
      background: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    }
  `]
})
export class TourControlComponent implements OnInit {
  constructor(public tourService: TourService) {}

  ngOnInit() {
    console.log('Tour control component initialized');
  }

  async startTour() {
    console.log('Starting tour from button click');
    // Add delay before starting tour
    await new Promise(resolve => setTimeout(resolve, 1500));
    this.tourService.startTour();
  }
}
