<div class="mat-dialog-header border-b">
  <h2 class="text-dark m-0 text-lg font-semibold">
    {{ localization.labels.create_tag | translate }}
  </h2>
  <svg-icon
    class="text-pale hover:text-warning cursor-pointer ml-auto"
    [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
    src="assets/icons/new-set/close.svg"
    (click)="close(false)"
  >
  </svg-icon>
</div>
<div
  class="mat-dialog-body flex flex-col gap-4 max-h-96"
  [formGroup]="tagForm"
>
  <app-text-input
    placeholder="Type a tag name"
    formControlName="tag_name"
    [invalid]="tagForm.get('tag_name')?.invalid && tagForm.get('tag_name')?.touched"
  >
  </app-text-input>
  <app-formatted-textarea
    formControlName="description"
    [placeholder]="localization.placeholder.type_description | translate"
    [ngClass]="{ 'border-warning': tagForm.get('snip_template')?.invalid && tagForm.get('snip_template')?.touched }"
  ></app-formatted-textarea>
  <div class="flex items-center gap-2">
    <div
      *ngFor="let color of colors"
      class="w-10 h-10 rounded cursor-pointer text-dark flex items-center justify-center"
      [style.backgroundColor]="color.value"
      (click)="selectColor(color.value)"
    >
      <svg-icon
        *ngIf="color.active"
        [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
        src="assets/icons/new-set/check.svg"
      >
      </svg-icon>
    </div>
    <div
      class="w-10 h-10 rounded cursor-pointer highlight-bg-color hover:secondary-bg-color text-dark flex items-center justify-center"
      (click)="colorPicker.click()"
    >
      <svg-icon
        [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
        src="assets/icons/new-set/circle-plus.svg"
      >
      </svg-icon>
    </div>
    <input
      #colorPicker
      class="w-0 h-0 opacity-0"
      type="color"
      (change)="pickColor($event)"
    >
  </div>
</div>

<div class="mat-dialog-footer border-t">
  <app-button
    *ngIf="data?.tags?.length"
    color="light"
    [matMenuTriggerFor]="tagsMenu"
  >
    {{localization.messages.or_choose_from_existing_tags | translate}}
  </app-button>
  <app-button
    color="primary"
    (click)="save()"
  >
    {{localization.labels.save | translate}}
    <mat-spinner
      class="svg-stroke-white"
      [diameter]="20"
      *ngIf="loading"
    ></mat-spinner>
  </app-button>
</div>

<mat-menu #tagsMenu="matMenu">
  <button
    *ngFor="let tag of data.tags"
    mat-menu-item
    (click)="setFromTenantTags(tag)"
  >
    <div class="flex items-center gap-2">
      <span
        class="h-4 w-4 rounded-full mr-2 bg-gray-500"
        [style.backgroundColor]="tag.color"
      >
      </span>
      <span>{{tag.tag_name}}</span>
    </div>
  </button>
</mat-menu>