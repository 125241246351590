
import { createSelector } from '@ngrx/store';
import { AppState } from '@/app/State/AppState';
import { TenantState } from '../Tenant/tenant.reducer';
import { ContactsState } from './contacts.reducer';

const tenantState = (state: AppState) => state.tenant;
const contacstState = (state: AppState) => state.contacts;

export const selectYobiContactSupportId = createSelector(
  tenantState,
  (state: TenantState) => state.profile?.supportContactId
);

export const selectLookupContacts = createSelector(
  contacstState,
  (state: ContactsState) => state.lookupContacts
);

export const selectLookingupContacts = createSelector(
  contacstState,
  (state: ContactsState) => state.lookingUp
);

export const selectContactsState = createSelector(
  contacstState,
  (state: ContactsState) => state.contacts
);

export const associatedDeals = createSelector(
  contacstState,
  (state: ContactsState) => state.associatedDeals
);

export const associatedContacts = createSelector(
  contacstState,
  (state: ContactsState) => state.associatedContacts
);

export const associatedTasks = createSelector(
  contacstState,
  (state: ContactsState) => state.associatedTasks
);

export const associatedCompanies = createSelector(
  contacstState,
  (state: ContactsState) => state.associatedCompanies
);

export const associatedAttachments = createSelector(
  contacstState,
  (state: ContactsState) => state.associatedAttachments
);


export const associatedPipelines = createSelector(
  contacstState,
  (state: ContactsState) => state.associatedPipelines
);