import { ErrorApiReponse } from '@/app/Data/services/Networking/ApiResponse';
import { BillingViewModel } from '@/app/Ui/ViewModels/billingViewModel';
import { LocalizationViewModel } from '@/app/Ui/ViewModels/localizationViewModel';
import Countries from '@/app/Ui/components/countries-code-list/Countries';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CreateTokenCardData,
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { StripeCardComponent } from 'ngx-stripe';
import { ILocalStorageService } from '@/app/Data/Adapters/ILocalStorageService';
@Component({
  selector: 'app-add-credit-card-dialog',
  templateUrl: './add-credit-card-dialog.component.html',
  styleUrls: ['./add-credit-card-dialog.component.scss']
})
export class AddCreditCardDialogComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  @Input() inlineMode = false
  @Input() isOnboarding = false
  @Output() complete = new EventEmitter()
  requestAddress = true
  countries = Countries
  loading = false;
  errorMessage = '';
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };
  cardOptions: StripeCardElementOptions
  form: FormGroup;
  localization = this.localizationViewModel.localization

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      isOnboarding: boolean
    },
    @Inject(ILocalStorageService) private localStorage: ILocalStorageService,
    @Optional() private dialogRef: MatDialogRef<AddCreditCardDialogComponent>,
    private billingViewModel: BillingViewModel,
    private localizationViewModel: LocalizationViewModel,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      address_country: [this.countries.find(c => c.code === 'US')?.code, [Validators.required]],
      address_line1: [null, [Validators.required]],
      address_line2: [''],
      address_state: [null, [Validators.required]],
      address_city: [null, [Validators.required]],
      address_zip: [null, [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.data.isOnboarding = this.isOnboarding;
    this.cardOptions = { hidePostalCode: this.requestAddress }
  }

  onCardInfosChange(event: StripeCardElementChangeEvent): void {
    this.errorMessage = event.error?.message || '';
  }

  onSubmit() {
    this.form.markAllAsTouched()
    if (this.form.valid && !this.loading) {
      const flag = this.localStorage.getItem('yib_p_flag') as boolean;
      const data = this.form.value as Partial<CreateTokenCardData> & Required<Pick<CreateTokenCardData, 'name' | 'address_country'>>
      const filtredUndefinedData = { ...data }
      for (let key in filtredUndefinedData) {
        if (!filtredUndefinedData[key as keyof {}]) {
          delete filtredUndefinedData[key as keyof {}];
        }
      }
      this.loading = true;
      this.errorMessage = '';
      this.billingViewModel.savPaymentMethod(this.card, { ...filtredUndefinedData, is_onboarding: flag ?? false })
        .subscribe({
          next: () => {
            if (flag) this.localStorage.removeItem('yip_p_flag');
            this.billingViewModel.fetchCurrentPaymentMethode()
            this.loading = false;
            if (!this.errorMessage) {
              this.inlineMode
                ? this.complete.emit()
                : this.dialogRef.close(true);
            }
          },
          error: (error: ErrorApiReponse<any>) => {
            this.loading = false;
            try {
              this.errorMessage = error.responseError?.error.errors?.message ?? error.responseError?.error.message ?? error.message ?? 'Something went wrong.';
            } catch {
              this.errorMessage = 'Something went wrong.'
            }
          },
        })
    }
  }
}
