import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuestGuard } from './Ui/guards/guest.guard';
import { AuthGuard } from './Ui/guards/auth.guard';
import { OnboardingGuard } from './Ui/guards/onboarding.guard';
import { BackDoorLoginComponent } from './Ui/back-door-login/back-door-login.component';
import { MainResolverService } from './Ui/layouts/MainResolver.service';
import { OnboardingResolverService } from './Ui/onboarding/OnboardingResolver.service';
const routes: Routes = [
  {
    path: 'auth',
    canLoad: [GuestGuard],
    canActivate: [GuestGuard],
    loadChildren: () =>
      import(`@/app/Ui/Registration/registration.module`).then(
        (module) => module.RegistrationModule
      ),
  },
  {
    path: 'main',
    canLoad: [AuthGuard, OnboardingGuard],
    canActivate: [AuthGuard, OnboardingGuard],
    loadChildren: () =>
      import(`@/app/Ui/layouts/main.module`).then(
        (module) => module.MainModule
      ),
  },
  {
    path: 'getting-started',
    resolve: {
      data: OnboardingResolverService,
    },
    loadChildren: () =>
      import(`@/app/Ui/onboarding/onboarding.module`).then(
        (module) => module.OnboardingModule
      ),
  },
  {
    path: 'billing',
    component: BackDoorLoginComponent
  },
  {
    path: 'signup',
    component: BackDoorLoginComponent
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth/login',
  },
  {
    path: '**',
    redirectTo: 'auth/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
