import { createSelector } from '@ngrx/store';
import { AppState } from '../AppState';
import { InboxState } from '../Inbox/inbox.reducer';
import { ThreadState } from './thread.reducer';
const threadState = (state: AppState) => state.thread;

const inboxState = (state: AppState) => state.inbox;

export const selectPendingMessages = createSelector(
  threadState,
  (state: ThreadState) => state.pendingMessages
);
export const selectPendingInlineNotes = createSelector(
  threadState,
  (state: ThreadState) => state.pendingInlineNotes
);

export const selectSupportChannel = createSelector(
  inboxState,
  (state: InboxState) => state.supportChannel
);


export const selectThreadInputState = createSelector(
  threadState,
  (state: ThreadState) => state.threadsInputState
);

export const selectTranslating = createSelector(
  threadState,
  (state: ThreadState) => state.translating
);

export const selectReformulating = createSelector(
  threadState,
  (state: ThreadState) => state.reformulating
);

export const selectThreadState = createSelector(
  threadState,
  (state: ThreadState) => state
);

export const selectActiveThread = createSelector(
  threadState,
  (state: ThreadState) => state.activeThread
);

export const selectActiveThreadInteractions = createSelector(
  threadState,
  (state: ThreadState) => state.activeThread?.interactions
);

export const selectActiveThreadId = createSelector(
  threadState,
  (state: ThreadState) => state.activeThread?.threadId
);

export const selectThreadSynthAutoReply = createSelector(
  threadState,
  (state: ThreadState) => state.activeThread?.synthAutoReply
);
