<div class="flex flex-col gap-1">
  <label
    *ngIf="label"
    [for]="label"
    class="font-semibold text-pale"
  >{{ label }}</label>
  <div
    class="flex flew-row items-center focus-within:border-primary border-2  px-4 rounded-md secondary-border-color surface-bg-color"
    [ngClass]="{
        invalid: invalid || !isValid,
        'currency': currency
    }">
    <ng-content></ng-content>
    <span *ngIf="currency">{{currency}}</span>
    <input
      #inputElement
      [step]="step"
      [type]="type"
      [placeholder]="placeholder"
      [attr.maxlength]="!!maxlength && maxlength "
      (focus)="onFocus()"
      (blur)="onBlur()"
      [(ngModel)]="value"
      [disabled]="disabled"
      (keyup.enter)="enter.emit()"
      [min]="min"
      (input)="onInputChange($event)"
      class="text-dark ring-transparent border-0 bg-transparent w-full focus:border-0 focus:ring-transparent h-12 px-0"
      [class]="classes"
    />
    <span *ngIf="maxlength" class="text-orange-400 whitespace-nowrap m-2 -mr-2">
      {{ maxlength - (value ? value.length : 0) }}/<span class="text-xs">{{ maxlength }}</span>
    </span>
    <ng-content select="[suffix]"></ng-content>
  </div>
</div>
