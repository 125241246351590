
<app-auth-banner class="absolute flex-1 w-full h-full"></app-auth-banner> 

<div class="wrapper">
  <ng-container *ngIf="!(completed$ | async); else successRef">
    <div class="flex flex-col gap-4 text-center">
      <div class="text-3xl font-semibold">
        {{ localization.auth.reset_password_title | translate}}
      </div>
      <div>{{ localization.auth.reset_password_enter_email  | translate}}</div>
    </div>
    <div class="w-full">
      <app-input-wrapper>
        <input
          input 
          (keyup.enter)="sendLink()"
          [formControl]="emailController"
          [placeholder]="localization.auth.reset_password_email_placeholder  | translate"
        />`
      </app-input-wrapper>
    </div>
    <div class="w-full flex flex-col gap-4">
      <app-button
        (click)="sendLink()"
        [disabled]="!emailController.valid"
      >
        <ng-container *ngIf="!(loading$ | async); else loadingRef">
          {{ localization.labels.send | translate }}
        </ng-container>
        <ng-template #loadingRef>
          <mat-spinner [diameter]="21"></mat-spinner>
        </ng-template>
      </app-button>
      <span
        class="cursor-pointer font-bold text-center"
        routerLink="/auth/login"
      >
        <a>{{ localization.auth.reset_password_sign_in | translate  }}</a>
      </span>
    </div>
  </ng-container>
  
  <ng-template #successRef>
    <div class="text-3xl font-semibold text-center">
      {{ localization.auth.reset_password_title | translate}}
    </div>
    <div class="flex flex-1 flex-col justify-center items-center">
      <div class="flex flex-col flex-1 text-center justify-center items-center gap-6 px-3">
        <img
          src="assets/images/email-sent.svg"
          class="h-40"
        />
        <div class="text-lg whitespace-pre-line">
          {{ localization.auth.reset_password_email_sent_to  | translate}}
          <b>{{ emailController.value }}</b>
          <br />
          {{ localization.auth.reset_password_not_received  | translate}}
          <span
            class="text-primary text-lg font-semibold cursor-pointer"
            (click)="sendLink()"
          >
          {{ localization.labels.send_again  | translate}}
          </span>.
        </div>
      </div>
      <div class="text-center flex items-end p-2 mt-5">
        <span
          class="cursor-pointer text-primary text-xl font-bold"
          routerLink="/auth/login"
        >
        {{ localization.auth.reset_password_sign_in | translate }}
        </span>
      </div>
    </div>
  </ng-template>
</div>
