import { Injectable } from '@angular/core';
import { first, Observable, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/app/State/AppState';
import { UpdateIntegrationsStatusAction } from '@/app/State/Integrations/actions';
import { IIntegrationsRepository } from '@/app/core/IRepositories/IIntegrationsRepository';
import { IntegrationsState } from '@/app/State/Integrations/reducer';
import { integrationsSatus } from '@/app/State/Integrations/selector';
import { IntegrationsResult } from '@/app/core/Models/integrations';

@Injectable({
  providedIn: 'root',
})
export class InboxIntegrationsViewModel {
  constructor(
    private integrationsRepository: IIntegrationsRepository,
    private store$: Store<AppState>,
  ) { }

  loadIntegrationsLinkStatus(): Observable<IntegrationsResult> {
    return this.integrationsRepository.loadLinkedIntegrations().pipe(
      first(),
      tap(integrations => {
        this.store$.dispatch(UpdateIntegrationsStatusAction({payload: integrations.integrations}));
      })
    )
  }

  integrationsStatus(): Observable<IntegrationsState>{
    return this.store$.select(integrationsSatus);
  }

  requestZoomLink(): Observable<string> {
    return this.integrationsRepository.requestZoomLink();
  }

  requestCalendlyLink(): Observable<string> {
    return this.integrationsRepository.requestCalendlyLink();
  }

}