import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextInputComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => TextInputComponent),
    },
  ],
})

export class TextInputComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() maxlength?: number;
  @Input() classes: string;
  @Input() disabled = false;
  @Input() invalid? = false;
  @Input() min?: number | string;
  @Input() step?: string;
  value: string = '';
  @Input() type = 'text';
  @Input() currency: string;
  @Output() enter = new EventEmitter();
  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();
  isValid = true;
  @ViewChild('inputElement') inputElementRef!: ElementRef<HTMLInputElement>;

  onChange: Function = (value: any) => { };

  onTouched = () => { };

  touched = false;


  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.focusInput();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  onInputChange(event: any): void {
    this.value = this.type == 'tel'
      ? this.value.replace(/[^0-9()-]/g, '').replace(/(\..*)\./g, '$1')
      : this.value;
    this.onChange(this.value);
  }

  onFocus(): void {
    this.focus.emit();
  }

  onBlur(): void {
    this.blur.emit();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: AbstractControl) {
    setTimeout(() => {
      this.isValid = control.valid || control.pristine;
    });
  }

  focusInput(): void {
    (this.inputElementRef && this.inputElementRef.nativeElement) &&
      this.inputElementRef.nativeElement.focus();
  }

}
