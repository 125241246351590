import { Affiliate } from './affiliate';
import { IOverviewTemplate } from "@/app/Ui/components/crm-overview/crm-overview.interface";
import { User } from './User';
import { Deal } from './deal';
import { IActivity } from '@/app/Data/services/Activity/activity.service';

export class ImportCompaniesCSV {
  redis_id: string;
  dropped_company_names: string[];
  company_data: {
    company: Array<any>
  }
  csv_columns: string[]
  custom_fields: Array<any>
}

export class ICompanyAttachments {
  yobi_crm_company_id: string;
  company_name: string
  attachments: Array<{ file_name: string, file_type: string, last_modified: string, raw_filename: string, size: number, url: string }>
}

export interface CompaniesQueryParams {
  page: number;
  page_size: number;
  search?: string
  companyId?: string;
  company_tags_id?: number[]
  users?: User[]
  column?: string;
  direction?: string;
}

export class AssociatedContact {
  yobi_crm_company_id: any
  contact_id: string
  relationship_type?: string
  contact_name?: string
  phone_number?: string
  avatar?: string
}
export class AssociatedContacts {
  company_name: string
  company_type: string
  email_address: string
  industry: string
  yobi_crm_company_id: string
  contacts: AssociatedContact[]
}

export class AssociatedCompany {
  yobi_crm_company_id: string
  associate_company_name: string
  industry: string
  company_type: string
  avatar: string
  association_type: string
  associated_company_id: string
  company_association_id: string
}

export class AssociatedCompanies {
  yobi_crm_company_id: string
  company_name: string
  industry: string
  company_type: string
  avatar: string
  associated_companies: AssociatedCompany[]
}

export interface CompanyTenantTags {
  color: string;
  tag_color?: string;
  created_dt: string;
  description: string;
  tag_id: number;
  tag_name: string;
  tenant_id: string;
  ts_field: string;
  updated_dt: string;
}
export interface CompanyAssignee {
  user_id: string;
  family_name: string;
  given_name: string;
  avatar: string;
}

export class CompanyNotes {
  yobi_crm_company_id: string | number
  note_id: number
  content: string
  attachments: Array<{ filename: string, type: string, url: string }>
  created_dt: string
  updated_dt: string
  is_deleted: boolean
  user_id: string
  created_by: string
}

export class CompanyTasks {
  task_id: string
  yobi_crm_company_id: string | number
  title: string
  description: string
  assigned_to: string
  assigned_user: User
  status: number
  priority: number
  created_dt: string
  updated_dt: string
  attachments: Array<{ filename: string, attachment_url: string }>
}

export class CompanyCustomFields {
  yobi_cm_company_id: string | number
  company_custom_field_id: string
  custom_field_key_id: string
  custom_field_name: string
  custom_field_value: string
  created_dt: string
  updated_dt: string
}

export class SelectedPipeline {
  description: string
  pipeline_name: string
  tenant_id: string
  yobi_crm_pipeline_id: number
  contact: {
    contact_name: string
    current_stage_id: number
    stage_name: string
    contact_id: number
  }
  company: {
    company_name: string
    current_stage_id: number
    stage_name: string
    yobi_crm_company_id: number
  }
  deals: Array<{
    current_stage_id: number
    deal_name: string
    stage_name: string
    yobi_crm_company_id: number
    yobi_crm_deal_id: number
  }>
}

export class Company {
  avatar: string;
  company_name: string;
  company_owner_id: string;
  company_tags: CompanyTenantTags[]
  created_dt: string;
  tenant_id: string | number;
  updated_dt: string;
  yobi_crm_company_id: string
  industry: {
    description: string,
    industry_type: string,
    tenant_company_industry_id: number
  };
  company_assignees: CompanyAssignee[];
  company_type: string;
  affiliates?: Affiliate[];
  number_of_employees: number;
  phone_number: string;
  email_address: string;
  total_count?: number
  website_url: string;
  address_street: string;
  address_streetline1: string;
  address_city: string;
  address_state: string;
  address_country: string;
  address_postal_code: string;
  social_linkedin: string;
  social_twitter: string;
  social_facebook: string;
  social_instagram: string;
  created_dt_timestamps?: number
  isChecked?: boolean;
  associated_contacts?: AssociatedContacts
  associated_companies?: AssociatedCompanies
  associated_notes?: CompanyNotes[]
  associated_tasks?: CompanyTasks[]
  associated_custom_fields?: CompanyCustomFields[]
  associated_deals?: Deal[]
  relationship_type?: string
  activities: IActivity[]
  contacts: AssociatedContact[]
  companies: AssociatedCompany[]
  associated_attachments: any[]

  get _contacts(): string[] {
    return Boolean(this.contacts) && this.contacts.length ? this.contacts.map(item => item.contact_id) : [];
  }

  get _companies(): string[] {
    return Boolean(this.companies) && this.companies.length ? this.companies.map(item => item.yobi_crm_company_id) : [];
  }

  get assignees(): string[] {
    return Boolean(this.company_assignees) && this.company_assignees.length ? this.company_assignees.map(item => item.user_id) : [];
  }

  get overviewTemplate(): IOverviewTemplate {
    return this.mapOverViewBaseTemplate();
  }

  get _getTagsId() {
    if (Boolean(this.company_tags) && this.company_tags.length) {
      return this.company_tags.map((item) => {
        return { name: item.tag_name, color: item.color ?? item.tag_color, tag_id: item.tag_id };
      });
    } else {
      return [];
    }
  }

  get _socialLinks() {
    const links = [];
    this.social_facebook && links.push({ icon: 'assets/icons/new-set/channels/brand-facebook2.svg', link: this.social_facebook });
    this.social_instagram && links.push({ icon: 'assets/icons/new-set/channels/brand-instagram2.svg', link: this.social_instagram });
    this.social_twitter && links.push({ icon: 'assets/icons/new-set/channels/brand-x2.svg', link: this.social_twitter });
    this.social_linkedin && links.push({ icon: 'assets/icons/new-set/channels/brand-linkedin2.svg', link: this.social_linkedin });
    return links;
  }

  get _fullCompanyAddress() {
    const addressParts = [
      this.address_street,
      this.address_city,
      this.address_state,
      this.address_postal_code,
      this.address_country
    ];
    return addressParts.filter(part => part !== undefined && part !== null && part !== '').join(' ');
  }

  get tags(): number[] {
    return Boolean(this.company_tags) && this.company_tags.length ? this.company_tags.map(item => item.tag_id) : [];
  }

  mapOverViewBaseTemplate() {
    return {
      name: this.company_name,
      avatar: this.avatar,
      title: 'Overview',
      menus: [
        { name: 'Contacts', icon: 'assets/icons/new-set/users.svg', class: 'text-primary' },
        { name: 'Edit', icon: 'assets/icons/new-set/edit.svg', class: 'text-primary' },
        { name: 'Note', icon: 'assets/icons/notes.svg', class: 'text-primary' },
        { name: 'Task', icon: 'assets/icons/new-set/clipboard-check.svg', class: 'text-primary' },
        { name: 'Delete', icon: 'assets/icons/new-set/trash.svg', class: 'text-warning' }
      ],
      infos: {
        company: {
          web: this.website_url,
          industry: this.industry !== null ? this.industry.industry_type : '',
          phone: this.phone_number,
          email: this.email_address,
          location: this._fullCompanyAddress,
          links: this._socialLinks,
          tags: this._getTagsId,
          created_dt: this.created_dt,
          updated_dt: this.updated_dt
        }
      }
    }
  }
}