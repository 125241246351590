import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MentionMember } from '@/app/core/Models/Mention';
import { Tenant } from '@/app/core/Models/tenant';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';
import { HotkeysService } from '@/app/Utilities/hotkeys/hotkeys.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-task-comment-input',
  templateUrl: './task-comment-input.component.html',
  styleUrls: ['./task-comment-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCommentInputComponent implements OnInit, OnDestroy {
  @Input() profile?: Tenant | null | undefined;
  @Input() members: MentionMember[];
  @Output() onComment = new EventEmitter<{
    comment: string;
  }>();
  localization = this.localiztionViewModel.localization
  comment: string = '';
  sub = new SubSink()

  constructor(
    private hotkeysService: HotkeysService,
    private localiztionViewModel: LocalizationViewModel
  ) { }

  ngOnInit(): void {
  this.sub.sink = this.hotkeysService.onSubmit$.subscribe((e: KeyboardEvent) => (e.target as HTMLElement).id === 'taskCommentInput' && this.addComment())      
  }

  addComment() {
    let comment = this.comment.trim()
    if (!comment) return;
    for (const member of this.members) {
      comment = comment.replace(
        new RegExp(`@${member.name}`, 'ig'),
        `<@mention_${member.id}>`
      )
    }
    this.onComment.emit({ comment });
    this.comment = '';
  }

  ngOnDestroy(): void {
      this.sub.unsubscribe()
  }

}
