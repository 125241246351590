export enum integrations {
  my_apps = 'integrations.my_apps',
  add_apps = 'integrations.add_apps',
  no_app = 'integrations.no_app',
  bigcommerce_description = 'integrations.bigcommerce_description',
  calendly_description = 'integrations.calendly_description',
  hubspot_description = 'integrations.hubspot_description',
  hubspot_get_notified_of_incoming_calls = 'integrations.hubspot_get_notified_of_incoming_calls',
  hubspot_get_notified_of_missed_calls = 'integrations.hubspot_get_notified_of_missed_calls',
  hubspot_get_notified_of_incoming_messages = 'integrations.hubspot_get_notified_of_incoming_messages',
  hubspot_get_notified_of_incoming_voicemail = 'integrations.hubspot_get_notified_of_incoming_voicemail',
  import_hubspot_contacts = 'integrations.import_hubspot_contacts',
  export_hubspot_contacts = 'integrations.export_hubspot_contacts',
  microsoft_teams_description = 'integrations.microsoft_teams_description',
  microsoft_teams_notification_description = 'integrations.microsoft_teams_notification_description',
  microsoft_teams_onboarding = 'integrations.microsoft_teams_onboarding',
  outlook_description = 'integrations.outlook_description',
  import_outlook_contacts = 'integrations.import_outlook_contacts',
  import_outlook_contacts_to_yobi = 'integrations.import_outlook_contacts_to_yobi',
  export_yobi_contacts_to_outlook = 'integrations.export_yobi_contacts_to_outlook',
  google_description = 'integrations.google_description',
  import_google_contacts = 'integrations.import_google_contacts',
  import_google_contacts_to_yobi = 'integrations.import_google_contacts_to_yobi',
  export_yobi_contacts_to_google = 'integrations.export_yobi_contacts_to_google',
  pipedrive = 'integrations.pipedrive',
  pipedrive_description = 'integrations.pipedrive_description',
  slack_description = 'integrations.slack_description',
  zapier_description = 'integrations.zapier_description',
  zendesk_title = 'integrations.zendesk_title',
  zendesk_description = 'integrations.zendesk_description',
  zendesk_team_description = 'integrations.zendesk_team_description',
  zendesk_credentials_description = 'integrations.zendesk_credentials_description',
  zohoCrm_description = 'integrations.zohoCrm_description',
  zoom_description = 'integrations.zoom_description',
  shopify_description = 'integrations.shopify_description',
  microsoft_team_event_description = 'integrations.microsoft_team_event_description',
  microsoft_team_notification_description = 'integrations.microsoft_team_notification_description',
  export_yobi_contacts = 'integrations.export_yobi_contacts',
  empty_contacts_list = 'integrations.empty_contacts_list',
  import_pipedrive_contacts = 'integrations.import_pipedrive_contacts',
  export_pipedrive_contacts = 'integrations.export_pipedrive_contacts',
  slack_incoming_call_notification = 'integrations.slack_incoming_call_notification',
  slack_missed_call_notification = 'integrations.slack_missed_call_notification',
  slack_incoming_messages_notification = 'integrations.slack_incoming_messages_notification',
  slack_incoming_voicemail_notification = 'integrations.slack_incoming_voicemail_notification',
  zapier_overview = 'integrations.zapier_overview',
  go_to_zapier = 'integrations.go_to_zapier',
  zapier_enter_yobi_account_password_step = 'integrations.zapier_enter_yobi_account_password_step',
  zapier_request_permission_access = 'integrations.zapier_request_permission_access',
  zapier_link_to_perform_action_get_info = 'integrations.zapier_link_to_perform_action_get_info',
  zapier_give_permission_confirm = 'integrations.zapier_give_permission_confirm',
}