import { Directive, ElementRef, EventEmitter, HostListener, NgModule, Output } from '@angular/core';

@Directive({
  selector: '[appCursorPosition]'
})
export class CursorPositionDirective {

  @Output() cursorPositionChange = new EventEmitter<number>();

  constructor(private el: ElementRef) {}

  @HostListener('keyup')
  @HostListener('click')
  updateCursorPosition() {
    const input = this.el.nativeElement as HTMLInputElement | HTMLTextAreaElement;
    const position = input.selectionStart || 0;
    this.cursorPositionChange.emit(position);
  }

}


@NgModule({
  declarations: [CursorPositionDirective],
  exports: [CursorPositionDirective],
  imports: [],
  providers: [],
})
export class CursorPositionDirectiveModule {
  constructor() { }
}
