import { bulkEmailDTO, forwardEmailDTO } from '@/app/Data/DTO/emailDTO';
import { bulkSmsDTO } from '@/app/Data/DTO/smsDTO';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { SuccessApiResponse } from '@/app/Data/services/Networking/ApiResponse';
import {
  ChatWidgetMessageBody,
  EmailMessageBody,
  PhoneMessageBody,
  SocialChannelMessageBody,
  TwitterChannelMessageBody,
  WhatsappChannelMessageBody,
  YobiMessageBody,
} from '@/app/core/Models/Message';
import { Observable } from 'rxjs';
import { SocketInteraction } from '../Models/Interaction';

export abstract class IMessagingRepository {
  abstract sendMultipleMessages(
    sms: bulkSmsDTO
  ): Observable<SuccessResponse<any>>;

  abstract sendSmsMessage(
    message: PhoneMessageBody
  ): Observable<SocketInteraction>;
  
  abstract sendBulkGoogleEmails(data: FormData): Observable<SuccessApiResponse<string>>

  abstract sendBulkOutlookEmails(data: FormData): Observable<SuccessApiResponse<string>>

  abstract forwardGoogleEmails(message: FormData): Observable<SuccessApiResponse<string>>

  abstract forwardOutlookEmails(message: FormData): Observable<SuccessApiResponse<string>>

  abstract sendSmsAttachmentMessage(
    request: FormData
  ): Observable<SocketInteraction>;

  abstract sendFacebookMessage(
    message: SocialChannelMessageBody
  ): Observable<SocketInteraction>;

  abstract sendWhatsappMessage(
    message: WhatsappChannelMessageBody
  ): Observable<SocketInteraction>;

  abstract sendFacebookAttachmentMessage(
    request: FormData
  ): Observable<SocketInteraction>;

  abstract sendInstagramMessage(
    message: SocialChannelMessageBody
  ): Observable<SocketInteraction>;

  abstract sendInstagramAttachmentMessage(
    request: FormData
  ): Observable<SocketInteraction>;

  abstract sendTwitterMessage(
    message: TwitterChannelMessageBody
  ): Observable<SocketInteraction>;

  abstract sendViberMessage(
    message: SocialChannelMessageBody
  ): Observable<SocketInteraction>;

  abstract sendYobiMessage(
    message: YobiMessageBody
  ): Observable<SocketInteraction>;

  abstract sendChatWidgetMessage(
    message: ChatWidgetMessageBody
  ): Observable<SocketInteraction>;
}
