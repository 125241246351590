import { createAction, props } from '@ngrx/store';

export const DEEP_LINK_HANDLE = '[Router] deep link handling';
export const DeepLinkHandleAction = createAction(
  DEEP_LINK_HANDLE,
  props<{ payload: string }>()
);

export const ROUTER_NAVIGATION_END = '[Router] Navigation end';

export const RouterNavigationEndAction = createAction(
  ROUTER_NAVIGATION_END,
  props<{ payload:  { timestamp: number, previous_page?:  string, current_page: string } }>()
);

