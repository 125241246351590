import { Injectable } from '@angular/core';
import { Contact } from '../../Models/contact';
import { UseCase } from '../UseCase';
import { map, Observable, switchMap, tap } from 'rxjs';
import { IContactRepository } from '@/app/core/IRepositories/IContactRepository';

@Injectable({
  providedIn: 'root',
})
export class EditContactUseCase implements UseCase<Contact, Contact> {
  constructor(private contactRepository: IContactRepository) { }

  execute(request: Contact): Observable<Contact> {
    return this.contactRepository.editContact(request.contact_id, request).pipe(
      switchMap(contact => this.contactRepository.getContactChannels(contact.contact_id).pipe(
        map(channels => ({contact, channels})))
      ),
      tap(({ contact, channels }) => {
        delete contact?.channels;
        this.contactRepository.saveContactChannels(
          contact.contact_id,
          channels
        );
        this.contactRepository.saveContact(contact);
        contact.channels = channels
      }),
      map(({ contact, channels }) => contact)
    );
  }
}
