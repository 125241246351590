import { VoicemailInteraction } from '@/app/core/Models/Interaction';
import { createReducer, on } from '@ngrx/store';
import { FetchVoicemailsAction, FetchVoicemailsFailAction, FetchVoicemailsSuccessAction, ResetVoicemailStateAction, SetActiveVoicemailAction, SetNewUnreadVoicemailAction } from './voicemails.action';
import { SignOutAction } from '../app/action';

export interface VoicemailsState {
  activeVoicemail?: VoicemailInteraction,
  unreadVoicemails?: boolean,
  voicemails: {
    channel_value: string,
    pageSize: number,
    isLastpage: boolean,
    fetching: boolean,
    page?: number,
  }
}

export const initVoicemailsState: VoicemailsState = {
  unreadVoicemails: false,
  voicemails: {
    channel_value: '',
    pageSize: 200,
    fetching: false,
    isLastpage: false,
  },
};

export const VoicemailsReducer = createReducer(
  initVoicemailsState,
  on(
    SetActiveVoicemailAction,
    (state: VoicemailsState, { payload }) => {
      return {
        ...state,
        activeVoicemail: payload
      }
    }
  ),
  on(
    FetchVoicemailsAction,
    (state: VoicemailsState, { payload: { query } }) => {
      return {
        ...state,
        voicemails: {
          ...state.voicemails,
          channel_value: query.params.channel_value,
          fetching: true,
          page: query.params.page
        }
      }
    }
  ),
  on(
    FetchVoicemailsSuccessAction,
    (state: VoicemailsState, { payload: { query, voicemails } }) => {
      return {
        ...state,
        voicemails: {
          ...state.voicemails,
          fetching: false,
          isLastpage: voicemails.length < query.params.pageSize
        }
      }
    }
  ),
  on(
    FetchVoicemailsFailAction,
    (state: VoicemailsState, { }) => {
      return {
        ...state,
        voicemails: {
          ...state.voicemails,
          fetching: false,
        }
      }
    }
  ),
  on(
    SetNewUnreadVoicemailAction,
    (state: VoicemailsState, { }) => {
      return {
        ...state,
        unreadVoicemails: true
      }
    }
  ),
  on(ResetVoicemailStateAction, SignOutAction, (state: VoicemailsState) => {
    return {
      ...initVoicemailsState
    }
  }),
);

