import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './back-door-login.component.html',
  styleUrls: ['./back-door-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackDoorLoginComponent implements OnInit {
  message = ''
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.url.subscribe(url => {
      const currentRoute = url[0].path as 'signup' | 'billing';
      switch (currentRoute) {
        case 'billing':
          this.message = 'Just a moment...'
          break;
        case 'signup':
          this.message = 'Verifying your email...'
          break;
      
        default:
          break;
      }
    });
  }
}
