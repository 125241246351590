import { async } from '@angular/core/testing';
import { DatabaseService } from './../services/Database/database.service';
import { liveQuery } from 'dexie';
import { environment } from '@/environments/environment';
import { HttpService } from './../services/Networking/HttpService';
import { IMessagesSchedulerRepository } from "@/app/core/IRepositories/IMessagesSchedulerRepository";
import { from, map, Observable, pluck, tap } from "rxjs";
import { ScheduleBulkDto, ScheduledMessageDeleteDto, ScheduleSmsMessageDto } from "../DTO/ScheduleMessageDto";
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { Injectable } from '@angular/core';
import { ScheduledMessage } from '@/app/core/Models/ScheduledMessage';
import { SuccessResponse } from '../DTO/successResponse';

@Injectable({
  providedIn: 'root',
})
export class MessagesSchedulerRepository extends IMessagesSchedulerRepository {

  constructor(private httpService: HttpService, private databaseService: DatabaseService) {
    super()
  }

  updateScheduledMessage(request: ScheduleSmsMessageDto): Observable<ScheduledMessage> {
    const requestURL = `${environment.apiURL}messages/schedule`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as any;
        return res.results;
      })
    );
  }

  cancelScheduledMessage(request: ScheduledMessageDeleteDto): Observable<SuccessResponse<string>> {
    const requestURL = `${environment.apiURL}messages/schedule`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      request,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as any;
        return res.results;
      })
    );
  }

  deleteLocalScheduledMessage(id: number): void {
    this.databaseService.scheduledMessage.delete(id).then()
  }


  saveScheduledMessages(messages: ScheduledMessage[]): void {
    this.databaseService.transaction('rw!', this.databaseService.scheduledMessage, async () => {
      const savedScheduledMessages = await this.databaseService.scheduledMessage
        .toArray();
      const newIds = messages.map(item => item.id);
      const deletedIds = savedScheduledMessages.filter(
        (item) => newIds.indexOf(item.id) == -1
      ).map(item => item.id);
      if (deletedIds?.length) {
        this.databaseService.scheduledMessage.bulkDelete(deletedIds).then().catch();
      }
      await this.databaseService.scheduledMessage.bulkPut(messages).then();
    })
  }

  getLocalScheduledMessages(): Observable<ScheduledMessage[]> {
    return from(
      liveQuery(() => {
        this.databaseService.scheduledMessage.mapToClass(ScheduledMessage);
        /* let qt =
           this.databaseService.recentInteractions.orderBy('interaction_dt');
      */
        return this.databaseService.scheduledMessage
          .reverse()
          .toArray()
      })
    );

  }

  getScheduledMessages(): Observable<ScheduledMessage[]> {
    const requestURL = `${environment.apiURL}messages/schedule`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as any;
        return res.results;
      })
    );
  }



  scheduleSmsMessage(request: ScheduleSmsMessageDto): Observable<any> {
    const requestURL = `${environment.apiURL}messages/schedule`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as any;
        return res.results;
      })
    );

  }

  scheduleBulk(request: ScheduleBulkDto): Observable<any> {
    const requestURL = `${environment.apiURL}messages/schedule/bulk`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as any;
        return res.results;
      })
    );
  }



}