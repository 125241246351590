<div class="flex flex-col w-full gap-4">
  <div class="flex flex-wrap gap-3">
    <app-tag
      *ngFor="let tag of tags"
      [content]="tag"
      [isContact]="isContact"
      [contactId]="contactId"
      (deleteTagEvent)="deleteTag($event)">
    </app-tag>
    <div class="flex items-center secondary-bg-color h-8 px-3 text-sm rounded-full">
      <input
        type="text"
        class="text-dark font-normal"
        [placeholder]="localization.placeholder.type_tag | translate"
        [formControl]="TagInputValue"
        [matAutocomplete]="auto"/>
      <svg-icon
        (click)="addTag()"
        class="text-pale hover:text-primary cursor-pointer"
        src="assets/icons/new-set/circle-plus.svg"
        [svgStyle]="{ 'height.rem': 1.3, 'width.rem': 1.3 }"
        [matTooltip]="localization.labels.create_tag | translate">
        </svg-icon>
    </div>
  </div>
  <mat-autocomplete
  [panelWidth]="300"
    (optionSelected)="initTag($event.option.value)"
    autoActiveFirstOption
    #auto="matAutocomplete">
    <mat-option
      *ngFor="let tag of tenantTags$ | async"
      [value]="tag.tag_name">
      <div class="flex items-center gap-2">
        <span
          class="h-4 w-4 rounded-full mr-2 bg-gray-500 shrink-0"
          [style.backgroundColor]="tag.color">
        </span>
        <span class="truncate">{{tag.tag_name}}</span>
      </div>
    </mat-option>
    <mat-option
      class="new-tag"
      [value]="TagInputValue.value">
      {{localization.labels.create_this_tag | translate: {tag: TagInputValue.value ?? '' } }}
    </mat-option>
  </mat-autocomplete>
</div>
