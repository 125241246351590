import { IThreadRepository } from '@/app/core/IRepositories/IMessagesThreadRepository';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IMessagesRepository } from '../../IRepositories/IMessagesRepository';
import { RecentInteraction, SocketInteraction } from '../../Models/Interaction';
import { UseCase } from '../UseCase';

@Injectable({
  providedIn: 'root',
})
export class HandleIncomingInteractionUseCase
  implements UseCase<any, Observable<RecentInteraction>>
{
  constructor(
    private messagesRepository: IMessagesRepository,
    private threadRepository: IThreadRepository
  ) {}

  execute(payload: SocketInteraction): Observable<RecentInteraction> {
    const interaction = Object.assign(new SocketInteraction(), payload, {
      contact_details: {
        ...payload.contact_details,
        unclassified_channel_id:
          payload.contact_details?.unclassified_channel_id ?? 0,
        contact_id: payload.contact_details.contact_id ?? 0,
      },
      yobi_tenant_id: payload.yobi_tenant_id ?? (payload.contact_details.tenant_id ?? 0),
      yobi_contact_id: payload.yobi_contact_id ?? ( payload.contact_details.contact_id ?? 0),
    });

    const recentInteraction = interaction.mapToRecentInteraction();
    const threadInteraction = interaction.mapToThreadInteraction();
    this.messagesRepository.addRecentMessage(recentInteraction);
    this.threadRepository.addThreadMessage(threadInteraction);
   
    return of(recentInteraction);
  }
}
