import { EmailVerificationCodeDto, lightwightSignupDto } from '@/app/Data/DTO/EmailVerificationCodeDto';
import { ILoginWithCredentialsResponse } from '@/app/Data/DTO/LoginResponse';
import { PasswordResetDto } from '@/app/Data/DTO/PasswordResetDto';
import { PasswordResetResponseDto } from '@/app/Data/DTO/PasswordResetResponseDto';
import { PasswordResetTokenDto } from '@/app/Data/DTO/PasswordResetTokenDto';
import { RegisterTenantPasswordDto } from '@/app/Data/DTO/RegisterTenantPasswordDto';
import { VerificationCodeDto } from '@/app/Data/DTO/VerificationCodeDto';
import {
  EmailFieldDto,
  RefreshTokenDto,
  RefreshTokenResultDto
} from '@/app/Data/DTO/authDto';
import { RequestPhoneVerificationCodeDto } from '@/app/Data/DTO/verifications/RequestPhoneVerificationCodeDto';
import { ValidatePhoneVerificationCodeDto } from '@/app/Data/DTO/verifications/ValidatePhoneVerificationCodeDto';
import { Observable } from 'rxjs';
import { LoginCredentialsRequest } from '../Models/LoginCredentials';
import { Tenant } from '../Models/tenant';
import { PasswordChangeDto } from './../../Data/DTO/PasswordChangeDto';
import { PasswordResetRequestLinkDto } from './../../Data/DTO/PasswordResetRequestLinkDto';
import { SuccessResponse } from './../../Data/DTO/successResponse';

export abstract class IAuthenticationRepository {
  abstract refreshToken(
    credential: RefreshTokenDto
  ): Observable<RefreshTokenResultDto>;

  abstract LoginWithCredentials(
    credential: LoginCredentialsRequest
  ): Observable<ILoginWithCredentialsResponse>;
  abstract LoginWithApple(): Observable<string>;
  abstract LoginWithGoogle(): Observable<string>;
  abstract LoginWithFacebook(): Observable<string>;
  abstract getUserObject(): Observable<Tenant>;

  abstract checkEmailExisting(
    request: EmailFieldDto
  ): Observable<SuccessResponse>;
  abstract requestEmailVerificationCode(
    request: EmailVerificationCodeDto
  ): Observable<SuccessResponse>;
  abstract validateVerificationCode(
    request: VerificationCodeDto
  ): Observable<ILoginWithCredentialsResponse>;
  
  abstract signupWithCredentials(
    request: lightwightSignupDto
  ): Observable<ILoginWithCredentialsResponse>;
  
  // abstract registerTenantWithPassword(
  //   request: RegisterTenantPasswordDto
  // ): Observable<SuccessResponse>;

  abstract passwordChange(
    request: PasswordChangeDto
  ): Observable<SuccessResponse>;

  abstract requestPhoneVerificationCode(
    request: RequestPhoneVerificationCodeDto
  ): Observable<SuccessResponse>;
  abstract validatePhoneVerificationCode(
    request: ValidatePhoneVerificationCodeDto
  ): Observable<any>;
  abstract saveTenantProfileObject(data: Tenant): void;
  abstract resetPassowrd(
    request: PasswordResetDto
  ): Observable<PasswordResetResponseDto>;
  abstract verifyPasswordResetToken(
    request: PasswordResetTokenDto
  ): Observable<SuccessResponse>;
  abstract requestPasswordResetLink(
    request: PasswordResetRequestLinkDto
  ): Observable<SuccessResponse>;

}
