import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';

export const DIALOG_DATA = new InjectionToken<any>('DIALOG_DATA');

export interface DialogConfig<D = any> {
  panelClass?: string | string[];
  /** Whether the overlay has a backdrop. */
  hasBackdrop?: boolean;
  /** Custom class to add to the backdrop */
  backdropClass?: string | string[];
  /** The width of the overlay panel. If a number is provided, pixel units are assumed. */
  width?: number | string;
  /** The height of the overlay panel. If a number is provided, pixel units are assumed. */
  height?: number | string;
  /** The min-width of the overlay panel. If a number is provided, pixel units are assumed. */
  minWidth?: number | string;
  /** The min-height of the overlay panel. If a number is provided, pixel units are assumed. */
  minHeight?: number | string;
  /** The max-width of the overlay panel. If a number is provided, pixel units are assumed. */
  maxWidth?: number | string;
  /** The max-height of the overlay panel. If a number is provided, pixel units are assumed. */
  maxHeight?: number | string;
  /** --- */
  origin?: HTMLElement;

  position?: ConnectionPositionPair,

  disableClose?: boolean,
  closeOnClick?: boolean,

  data: D
  context?: Object,


  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  offsetX?: number
  offsetY?: number
}
