import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: {
      message: string,
      success: boolean,
      info?: boolean,
      translateParams?: Object,
    },
    private matSnackRef: MatSnackBarRef<AlertComponent>
  ) { }

  dismiss(): void {
    this.matSnackRef.dismiss();
  }

}
