import { BillingDto, BillingInvoiceDto } from "@/app/Data/DTO/BillingDto";
import { BillingPlanDto } from "@/app/Data/DTO/BillingPlanDto";
import { BillingSubscriptionDto } from "@/app/Data/DTO/BillingSubscriptionDto";
import { BillingUpgradePromptDto } from "@/app/Data/DTO/BillingUpgradePromptDto";
import { CreditCardDto } from "@/app/Data/DTO/CriditCardDto";
import { PaymentMethodDto } from "@/app/Data/DTO/PaymentMethodDto";
import { SuccessResponse } from "@/app/Data/DTO/successResponse";
import { Observable } from "rxjs";
import { BillingSubscription, PromoCode } from "../Models/billing";

export abstract class IBillingRepository {

  constructor() {}
  
  abstract getBillingPlans(): Observable<BillingPlanDto[]>;

  abstract getInvoices(): Observable<BillingInvoiceDto[]>;
  
  abstract addPaymentMethod(card: CreditCardDto): Observable<PaymentMethodDto>; 
  
  abstract deletePaymentMethod(): Observable<any>;  

  abstract getCurrentPaymentMethod(): Observable<PaymentMethodDto>;
  
  abstract getActiveSubscription(): Observable<BillingSubscriptionDto | null>;

  abstract updateBillingSubscription(plan_id: number): Observable<BillingSubscriptionDto>;

  abstract customPlan(): Observable<string>;

  abstract reactivateBillingSubscription(): Observable<BillingSubscriptionDto>;

  abstract CancelBillingSubscription(data: {
    reason: string,
    improve: string,
  }): Observable<any>;

  abstract getCancellationReasons(): Observable<BillingDto.CancellationReasonsItem[]>;

  abstract getEstimation(plan_id: number): Observable<BillingDto.Estimation>;
  
  abstract applyPromoCode(data: { code: string }): Observable<BillingPlanDto[]>;
  
  abstract deletePromoCode(data: { codeId: number }): Observable<BillingPlanDto[]>;

  abstract getActivePromoCodes(): Observable<PromoCode[]>;

  abstract GetBillingPromptStatus(): Observable<{ expired: boolean }>;

  abstract BillingPromptEvent(data: BillingUpgradePromptDto): Observable<SuccessResponse>;
  
  
}
