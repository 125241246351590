<app-avatar
  class="h-8 w-8 shrink-0"
  [url]="profile?.avatar"
></app-avatar>
<app-formatted-textarea
  class="flex-1 border-2 secondary-border-color focus-within:border-primary rounded"
  [placeholder]="localization.placeholder.type_your_comment_here | translate "
  [(ngModel)]="comment"
  [members]="members"
  [id]="'taskCommentInput'"
>
</app-formatted-textarea>
<svg-icon
  tabindex="0"
  src="assets/icons/new-set/send.svg"
  class="cursor-pointer text-primary"
  [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
  (click)="addComment()"
  (keyup.enter)="addComment()"
></svg-icon>

<!-- <ng-container>

    <div class="flex flex-col w-full">
        <div class="flex flex-row">
            <app-formatted-textarea
                class="flex-1 border-2 secondary-border-color focus-within:border-primary rounded"
                [placeholder]="localization.placeholder.type_your_comment_here | translate "
                [(ngModel)]="comment"
                [members]="members"
                [id]="'taskCommentInput'">
            </app-formatted-textarea>
        </div>
        <div class="flex flex-row">
            
        </div>

    </div>

</ng-container> -->