export class Tag {
    color: string;
    contact_id: number | null;
    contact_tag_id: number;
    tag_name: string;
    created_dt: string;
    updated_dt: string;
    description: string;
    unclassified_channel_id: number | null;
    tag_id: number;
}