<p
  *ngIf="task?.body; else description"
  class="text-base text-dark"
>
  <ng-container *ngFor="let section of taskBody">
    <ng-container [ngSwitch]="section.type">
      <span
        class="text-base"
        *ngSwitchCase="'text'"
        [innerHtml]="$any(section).text"
      >
      </span>
      <span
        *ngSwitchCase="'mention'"
        class="mention"
      >
        {{ $any(section).user }}
      </span>
    </ng-container>
  </ng-container>
</p>
<ng-template #description>
  <p
    class="text-base text-dark"
    [innerHTML]="detectMention(task.description)"
  >
  </p>
</ng-template>