
<div class="text-dark flex flex-col gap-4 p-3">
    <ng-container *ngIf="task$ | async as task; else loadingRef">

        <form 
            [formGroup]="taskForm">
            <!-- FORM -->
            <div class="w-full bg-white flex flex-col gap-5">

                <!-- COVERSATION -->
                <!-- <div class="flex w-full justify-end">
                    <div class="flex flex-row text-blue-600 text-sm items-center">
                        <a href="#">
                            Go to conversation 
                        </a>
                        <svg-icon
                            src="assets/icons/new-set/arrow-up-right.svg"
                            [svgStyle]="{ 'width.px': 16, 'height.px': 16 }">
                        </svg-icon>
                    </div>
                </div> -->

                <!-- TITLE -->
                <div class="flex w-full items-center">
                    <ng-container>
                        <ng-container 
                            *ngIf="!taskViewControls.title">
                            <div class="w-full hover:secondary-bg-color cursor-pointer">
                                <h1 
                                    [matTooltip]="'Edit title'"
                                    matTooltipPosition="above"
                                    (click)="taskViewControls.title = true;"
                                    class="text-2xl font-bold cursor-pointer">
                                    {{title?.value}}
                                </h1>
                            </div>
                  
                        </ng-container>
                        <ng-container 
                            *ngIf="taskViewControls.title" >
                            <div class="flex flex-row gap-2.5 w-full">
                                <div class="flex flex-col w-full">
                                    <app-input-wrapper
                                        class="flex-1"
                                        [maxLength]="150"
                                        [currentLength]="title?.value.length"
                                        [invalid]="title?.invalid && title?.touched">
                                        <input
                                            input
                                            type="text"
                                            cdkFocusInitial
                                            autocomplete="off"
                                            [placeholder]="'Enter your title'"
                                            (change)="onChangeTitle($event)"
                                            formControlName="title"/>
                                    </app-input-wrapper>
                                    <mat-error *ngIf="title?.hasError('maxlength')">
                                        Please use a short name.
                                    </mat-error>
                                </div>
                                <svg-icon
                                    (click)="taskViewControls.title = false"
                                    class="cursor-pointer hover:text-warning "
                                    [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
                                    src="assets/icons/new-set/close.svg">
                                </svg-icon>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

                <!-- STATUS -->
                <div class="flex w-full items-center flex-row">
                    <div class="flex items-center text-gray-600 lc">
                        <span class="text-gray-400 mr-2">
                            <svg-icon
                                src="assets/icons/new-set/task-status/upcoming.svg"
                                [svgStyle]="{ 'width.px': 18, 'height.px': 18 }">
                            </svg-icon>
                        </span>
                        <span class="text-base">Status</span>
                    </div>

                    <div 
                        [ngSwitch]="status?.value"
                        class="flex flex-col rc hover:secondary-bg-color cursor-pointer" 
                        [matMenuTriggerFor]="statusMenu">

                        <!-- OPEN -->
                        <ng-container
                            *ngSwitchCase="'open'">
                            <div class="flex flex-row gap-1.5 items-center">
                                <ng-container>
                                    <svg-icon
                                        src="assets/icons/new-set/task-status/upcoming.svg"
                                        [svgStyle]="{ 'width.px': 18, 'height.px': 18 }">
                                    </svg-icon>
                                    <span class="text-base">
                                        Open
                                    </span>
                                </ng-container>
                            </div>
                        </ng-container>

                        <!-- IN PROGRESS -->
                        <ng-container
                            *ngSwitchCase="'in-progress'">
                            <div class="flex flex-row gap-1.5 items-center">
                                <ng-container>
                                    <svg-icon
                                        src="assets/icons/task/task-status/inProgress.svg"
                                        [svgStyle]="{ 'width.px': 18, 'height.px': 18 }">
                                    </svg-icon>
                                    <span class="text-base">
                                        In Progress
                                    </span>
                                </ng-container>
                            </div>
                        </ng-container>

                        <!-- IN PROGRESS -->
                        <ng-container
                            *ngSwitchCase="'done'">
                            <div class="flex flex-row gap-1.5 items-center">
                                <ng-container>
                                    <svg-icon
                                        src="assets/icons/task/task-status/done.svg"
                                        [svgStyle]="{ 'width.px': 18, 'height.px': 18 }">
                                    </svg-icon>
                                    <span class="text-base">
                                        Done
                                    </span>
                                </ng-container>
                            </div>
                        </ng-container>

                        <!-- EMPTY -->
                        <ng-container
                            *ngSwitchDefault>
                            <span>Empty</span>
                        </ng-container>
                        
                    </div>
                </div>
                
                <!-- CREATED BY -->
                <div class="flex w-full items-center flex-row">
                    <div class="flex items-center text-gray-600 lc">
                        <span class="text-gray-400 mr-2">
                            <svg-icon
                                src="assets/icons/task/assign-to.svg"
                                [svgStyle]="{ 'width.px': 18, 'height.px': 18 }">
                            </svg-icon>
                        </span>
                        <span class="text-base">Created by</span>
                    </div>

                    <div class="flex flex-col rc">
                        <div class="flex flex-row gap-1.5 items-center">
                            <ng-container>
                                <app-avatar
                                    class="w-8 h-8"
                                    [fontSize]="11"
                                    [url]="creator?.value!.avatar"
                                    [name]="creator?.value!.firstName + ' ' + creator?.value!.lastName"
                                    matTooltip="{{creator?.value!.firstName + ' ' + creator?.value!.firstName}}">
                                </app-avatar>
                                <span class="text-base">{{creator?.value!.firstName + ' ' + creator?.value!.lastName}}</span>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <!-- ASSIGNESS -->
                <div class="flex w-full items-center flex-row">

                    <div class="flex items-center text-gray-600 lc">
                        <span class="mr-2">
                            <svg-icon
                                src="assets/icons/new-set/users.svg"
                                [svgStyle]="{ 'width.px': 20, 'height.px': 20 }">
                            </svg-icon>
                        </span>
                        <span class="w-20">Assignees</span>
                    </div>
    
                    <div class="flex flex-row items-center gap-1 rc">
                        <ng-container
                            *ngIf="task_assignees?.value[0]?.user_id">
                            <div class="flex items-center space-x-[-8px]">
                            
                                <!-- FIRST ASSIGNEE -->
                                <div class="rounded-full text-white flex items-center justify-center text-sm font-bold border-2 border-white">
                                    <app-avatar
                                        class="w-8 h-8"
                                        [fontSize]="11"
                                        [url]="task_assignees?.value[0]!.avatar"
                                        [name]="task_assignees?.value[0]!.given_name + ' ' + task_assignees?.value[0]!.family_name"
                                        matTooltip="{{task_assignees?.value[0]!.given_name + ' ' + task_assignees?.value[0]!.family_name}}">
                                    </app-avatar>
                                </div>
                
                                <!-- SECOND ASSIGGNEE -->
                                <ng-container
                                    *ngIf="task_assignees?.value?.length >= 2">
                                    <img
                                        [src]="task_assignees?.value[1]!.avatar"
                                        [alt]="task_assignees?.value[1]!.given_name + ' ' + task_assignees?.value[1]!.family_name"
                                        class="w-8 h-8 rounded-full border-2 border-white"/>
                                                    
                                    <!-- REMAINING COUNT ASSIGNEES -->
                                    <div 
                                        *ngIf="task_assignees?.value?.length - 2"
                                        class="h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center text-sm font-semibold border-2 border-white">
                                        <span>+{{task_assignees?.value?.length - 2}}</span>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                        
                        <!-- ADD ASSIGNEES -->
                        <button
                            class="text-gray-400 text-sm hover:bg-gray-200 p-1 rounded-full"
                            [matMenuTriggerFor]="assigneesMenu"
                            matTooltip="Change assignee">
                            <svg-icon
                                src="assets/icons/task/add-bt.svg"
                                [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                                class="cursor-pointer">
                            </svg-icon>
                        </button>
                    </div>
                </div>

                <!-- DUE DATE -->
                <div class="flex flex-row gap-1 w-full items-center text-gray-600">
                    <div class="flex flex-row gap-1 lc text-gray-600">
                        <span class="text-gray-400 mr-2">
                            <svg-icon
                                src="assets/icons/task/date.svg"
                                [svgStyle]="{ 'width.px': 20, 'height.px': 20 }">
                            </svg-icon>
                        </span>
                        <span>Due date</span>
                    </div>
                    <div 
                        #calendarTrigger="matMenuTrigger"
                        [matMenuTriggerFor]="calendarMenu"
                        class="flex rc hover:secondary-bg-color cursor-pointer">
                        <span 
                            class="text-base">
                            {{deadline?.value ? deadline?.value : 'Empty'}}
                        </span>
                    </div>
                </div>

                <!-- PRIORITY -->
                <div class="flex flex-row gap-1 w-full items-center text-gray-600">
                    <div class="flex flex-row gap-1 lc">
                        <span class="mr-2">
                            <svg-icon
                                class="text-gray-400"
                                src="assets/icons/new-set/high.svg"
                                [svgStyle]="{ 'width.px': 20, 'height.px': 20 }">
                            </svg-icon>
                        </span>
                        <span>Priority</span>
                    </div>
                    <div 
                        [matMenuTriggerFor]="priorityMenu"
                        [ngSwitch]="priority?.value"
                        class="flex rc hover:secondary-bg-color cursor-pointer">

                        <!-- HIGH -->
                        <ng-container
                            *ngSwitchCase="'High'">
                            <div class="flex flex-row gap-1.5 items-center High">
                                <ng-container>
                                    <svg-icon
                                        src="assets/icons/new-set/high.svg"
                                        [svgStyle]="{ 'width.px': 18, 'height.px': 18 }">
                                    </svg-icon>
                                    <span class="text-base">
                                        High
                                    </span>
                                </ng-container>
                            </div>
                        </ng-container>

                        <!-- MEDIUM -->
                        <ng-container
                            *ngSwitchCase="'Medium'">
                            <div class="flex flex-row gap-1.5 items-center Medium">
                                <ng-container>
                                    <svg-icon
                                        src="assets/icons/new-set/medium.svg"
                                        [svgStyle]="{ 'width.px': 18, 'height.px': 18 }">
                                    </svg-icon>
                                    <span class="text-base">
                                        Medium
                                    </span>
                                </ng-container>
                            </div>
                        </ng-container>

                         <!-- LOW -->
                         <ng-container
                            *ngSwitchCase="'Low'">
                            <div class="flex flex-row gap-1.5 items-center Low">
                                <ng-container>
                                    <svg-icon
                                        src="assets/icons/new-set/low.svg"
                                        [svgStyle]="{ 'width.px': 18, 'height.px': 18 }">
                                    </svg-icon>
                                    <span class="text-base">
                                        Low
                                    </span>
                                </ng-container>
                            </div>
                        </ng-container>

                        <!-- NONE -->
                        <ng-container
                            *ngSwitchCase="'None'">
                            <div class="flex flex-row gap-1.5 items-center Mone">
                                <ng-container>
                                    <svg-icon
                                        src="assets/icons/new-set/circle-off.svg"
                                        [svgStyle]="{ 'width.px': 18, 'height.px': 18 }">
                                    </svg-icon>
                                    <span class="text-base">
                                        None
                                    </span>
                                </ng-container>
                            </div>
                        </ng-container>

                        <!-- DEFAULT -->
                        <ng-container
                            *ngSwitchDefault>
                            <span>Empty</span>
                        </ng-container>
                    </div>
                </div>

                <!-- TAGS -->
                <div class="flex flex-row gap-1 w-full items-center text-gray-600">
                    <div class="flex flex-row gap-1 lc">
                        <span class="mr-2">
                            <svg-icon
                                class="text-gray-400"
                                src="assets/icons/new-set/tag.svg"
                                [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'fill': '' }">
                            </svg-icon>
                        </span>
                        <span>Tags</span>
                    </div>
                    <div class="flex rc hover:secondary-bg-color cursor-pointer max-h-32 overflow-y-scroll">
                        <div class="flex flex-row gap-2.5">
                            <ng-container>
                                <div class="px-2">
                                    <app-tags
                                        [tags]="tags?.value"
                                        [isContact]="false"
                                        (onGetTag)="onGetTagOnTask($event)">
                                    </app-tags>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <!-- REMINDER -->
                <div class="flex flex-col gap-2.5">
                    <div class="flex flex-row gap-1 w-full items-center text-gray-600">
                        <div class="flex flex-row gap-1 lc">
                            <span class="mr-2">
                                <svg-icon
                                    class="text-gray-400"
                                    src="assets/icons/new-set/clock.svg"
                                    [svgStyle]="{ 'width.px': 20, 'height.px': 20 }">
                                </svg-icon>
                            </span>
                            <span>Reminder</span>
                        </div>
                        <div 
                            [matMenuTriggerFor]="remindersMenu"
                            class="flex rc hover:secondary-bg-color cursor-pointer">
                            <span 
                                class="text-base">
                                {{reminder?.value ? reminder?.value === 'custom' ? '' : reminder?.value : 'Empty'}}
                            </span>
                        </div>
                    </div>

                    <!-- CUSTOM REMINDER -->
                    <ng-container
                        *ngIf="reminder?.value === 'custom'">
                        <div class="flex items-centrer gap-4">
                            <app-input-wrapper
                                [dropDownPanel]="datePickerReminder"
                                class="flex-1">
                                <p
                                    input
                                    class="cursor-text w-full flex items-center">
                                    {{ currentDateReminder.toDateString() == reminderDate.toDateString() ? 'Today' : reminderDate |
                                    date: 'mediumDate'}}
                                </p>
                                <svg-icon
                                    suffix
                                    src="assets/icons/new-set/calendar.svg"
                                    class="text-mild mr-4"
                                    [svgStyle]="{ 'height.px': 18, 'width.px': 18 }">
                                </svg-icon>
                            </app-input-wrapper>
                            <app-input-wrapper class="flex-1">
                                <input
                                    input
                                    #timePicker
                                    type="time"
                                    [(ngModel)]="reminderTime"
                                    [ngModelOptions]="{standalone: true}"
                                    (ngModelChange)="onChangeReminderTime()"
                                    [min]="minimumTime"
                                    placeholder="Set end time"/>
                                    <svg-icon
                                        suffix
                                        src="assets/icons/new-set/clock.svg"
                                        class="text-mild mr-4 cursor-pointer"
                                        (click)="$any(timePicker).showPicker()"
                                        [svgStyle]="{ 'height.px': 18, 'width.px': 18 }">
                                    </svg-icon>
                            </app-input-wrapper>
                            <svg-icon
                                (click)="patchReminder('none')"
                                class="cursor-pointer hover:text-warning "
                                [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
                                src="assets/icons/new-set/close.svg">
                            </svg-icon>
                        </div>
                    </ng-container>
                </div>
          
                <!-- ASSOCIATION -->
                <div class="flex flex-row gap-1 w-full items-center text-gray-600">
                    <div class="flex flex-row gap-1 lc">
                        <span class="mr-2">
                            <svg-icon
                                class="text-gray-400"
                                src="assets/icons/new-set/association_people.svg"
                                [svgStyle]="{ 'width.px': 20, 'height.px': 20 }">
                            </svg-icon>
                        </span>
                        <span>Associate with</span>
                    </div>
                    <div class="flex rc">

                        <!-- COMPANY ASSOCIATION -->
                        <ng-container
                            *ngIf="yobi_crm_company_id?.value">
                            <ng-container
                                *ngIf="gettingCompanyDetails === false && associatedCompanyModel?.value !== null">
                                <div 
                                    [ngStyle]="{
                                        'background': '#eff8ff',
                                        'border-radius': '4px'
                                    }"
                                    class="flex flex-row items-center gap-1.5 p-1">
                                    <app-avatar
                                        [fontSize]="11"
                                        [url]="associatedCompanyModel?.value!.avatar"
                                        [name]="associatedCompanyModel?.value!.company_name"
                                        matTooltip="{{associatedCompanyModel?.value!.company_name}}">
                                    </app-avatar>
                                    <span class="text-sm">{{associatedCompanyModel?.value!.company_name}}</span>
                                    <svg-icon   
                                        (click)="removeCompany()"
                                        class="cursor-pointer hover:text-warning"
                                        matTooltipPosition="above"
                                        matTooltip="Remove {{associatedCompanyModel?.value!.company_name}} company"
                                        [svgStyle]="{ 'height.px': 18, 'width.px': 18 }"
                                        src="assets/icons/new-set/close.svg">
                                    </svg-icon>
                                </div>
                            </ng-container>

                            <ng-container
                                *ngIf="gettingCompanyDetails">
                                <ng-template>
                                    <div class="flex flex-1 w-full h-full">
                                        <div class="animate-pulse flex flex-1 flex-col gap-6">
                                            <div class="flex secondary-bg-color rounded-md h-12"></div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </ng-container>

                        <!-- CONTACT ASSOCIATION -->
                        <ng-container
                            *ngIf="contact_id?.value">
                            <ng-container
                                *ngIf="gettingContactDetails === false && associatedContactModel?.value !== null">
                                <div 
                                    [ngStyle]="{
                                        'background': '#eff8ff',
                                        'border-radius': '4px'
                                    }"
                                    class="flex flex-row items-center gap-1.5 p-1">
                                    <app-avatar
                                        [fontSize]="11"
                                        [url]="associatedContactModel?.value!.avatar"
                                        [name]="associatedContactModel?.value.given_name + associatedContactModel?.value.family_name"
                                        matTooltip="{{associatedContactModel?.value.given_name + associatedContactModel?.value.family_name}}">
                                    </app-avatar>
                                    <span class="text-sm">{{associatedContactModel?.value.given_name + associatedContactModel?.value.family_name}}</span>
                                    <svg-icon   
                                        (click)="removeContact()"
                                        class="cursor-pointer hover:text-warning"
                                        matTooltipPosition="above"
                                        matTooltip="Remove {{associatedContactModel?.value.given_name + associatedContactModel?.value.family_name}} contact"
                                        [svgStyle]="{ 'height.px': 18, 'width.px': 18 }"
                                        src="assets/icons/new-set/close.svg">
                                    </svg-icon>
                                </div>
                            </ng-container>


                            <ng-container
                                *ngIf="gettingContactDetails">
                                <ng-template>
                                    <div class="flex flex-1 w-full h-full">
                                        <div class="animate-pulse flex flex-1 flex-col gap-6">
                                            <div class="flex secondary-bg-color rounded-md h-12"></div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>

                        </ng-container>
          
                        <!-- ADD ASSOCIATION -->
                        <button
                            class="text-gray-400 text-sm hover:bg-gray-200 p-1 rounded-full"
                            [ngClass]="{
                                'pointer-events-auto': !gettingContactDetails || !gettingCompanyDetails,
                                'pointer-events-none': gettingContactDetails || gettingCompanyDetails
                            }"
                            [matMenuTriggerFor]="associationsMenu"
                            matTooltip="Change association">
                            <svg-icon
                                src="assets/icons/task/add-bt.svg"
                                [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                                class="cursor-pointer">
                            </svg-icon>
                        </button>
                    </div>
                </div>
        
                <!-- DESCRIPTION -->
                <ng-container>
                    <ng-container
                        *ngIf="!taskViewControls.description">
                        <div 
                            [matTooltip]="'Edit description'"
                            matTooltipPosition="above"
                            (click)="taskViewControls.description = true"
                            class="mt-6 w-full mx-auto p-6 bg-white border border-gray-200 rounded-lg cursor-pointer">
                            <h3 class="text-lg font-semibold mb-2">Description</h3>
                            <p class="text-gray-600 text-sm">
                                <app-task-description 
                                    #taskDescription
                                    [task]="task" [mentionMembers]="(mentions$ | async) ??[]">
                                </app-task-description>
                            </p>
                        </div>
                    </ng-container>

                    <ng-container
                        *ngIf="taskViewControls.description">
                        <div class="flex flex-row gap-2.5">
                            <app-formatted-textarea
                                class="flex-1 border-2 secondary-border-color focus-within:border-primary rounded"
                                formControlName="description"
                                [id]="'task-desc'"
                                (change)="onChangeDesc($event)"
                                [placeholder]="localization.placeholder.task_description_placeholder | translate"
                                [members]="mentions$ | async">
                            </app-formatted-textarea>
                            <svg-icon
                                (click)="taskViewControls.description = false"
                                class="cursor-pointer hover:text-warning "
                                [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
                                src="assets/icons/new-set/close.svg">
                            </svg-icon>
                        </div>
                    </ng-container>
                </ng-container>
          
                <!-- ATTACHMENTS -->
                <ng-container>
                    <div class="mt-6 w-full mx-auto p-6 bg-white border border-gray-200 rounded-lg flex flex-col gap-2.5">
                        <h3 class="text-lg font-semibold mb-2">Attachments</h3>
                        <div
                            class="flex flex-col gap-1 w-full">
                            <app-attachments-viewer
                                #attachmentViewer
                                (remove)="handleRemoveFile($event)"
                                [attachments]="attachments?.value"
                                [canRemove]="true">
                            </app-attachments-viewer>
                        </div>
                        <div
                            appFileUploader
                            [validFileExt]="[
                                'image/jpeg',
                                'image/jpg',
                                'image/png',
                                'image/heic',
                                'image/gif',
                                'text/plain',
                                'application/pdf',
                                'video/mp4',
                                'audio/mpeg',
                                'text/csv',
                                'audio/wav',
                                'video/quicktime',
                                'image/webp',
                                'audio/x-m4a',
                                'audio/amr',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            ]"
                            (fileDropped)="handleFileDrop($event)"
                            class="relative border border-dashed border-gray-300 rounded-lg p-4 text-center text-gray-500 hover:text-blue-600 cursor-pointer">
                            Drop your file here to 
                                <input 
                                    #fileDropRef 
                                    type="file"
                                    id="taskFile" 
                                    multiple 
                                    (click)="resetUploadedDocuments($event)"
                                    (change)="clickFileBrowseHandler($any($event.target).files)"/>
                            <a href="#" class="text-blue-600 hover:underline">upload</a>
                        </div>
                    </div>
                </ng-container>
        
            </div>
        </form>
    </ng-container>

    <ng-template #loadingRef>
        <div class="flex flex-1 w-full h-full">
            <div class="animate-pulse flex flex-1 flex-col gap-6">
            <div class="flex secondary-bg-color rounded-md h-12"></div>
            <div class="flex secondary-bg-color rounded-md h-12"></div>
            <div class="flex secondary-bg-color rounded-md h-28"></div>
            <div class="flex secondary-bg-color rounded-md flex-1"></div>
            <div class="flex secondary-bg-color rounded-md h-16"></div>
            </div>
        </div>
    </ng-template>
</div>
<!-- ----------------------------- Activities ------------------------------ -->
<div
    class="surface-bg-color light-shadow flex flex-col py-3 px-4 rounded-md gap-3 overflow-y-auto gap-2.5"
    style="flex: 1 0 auto; height: 100vh;"
    #activities_container
    *ngIf="profile$ | async as profile">

    <div class="flex flex-row gap-2.5">
        <div 
            [ngClass]="{
                'text-primary font-semibold light-bg-color p-3 rounded-md pointer-events-none': tab === 'Comments',
                'pointer-events-auto cursor-pointer': tab !== 'Comments'
            }"
            (click)="tab='Comments'"
            class="flex flex-row gap-2.5 items-center">
            <svg-icon
                src="assets/icons/new-set/message-circle.svg"
                [svgStyle]="{
                    'height.px': 20,
                    'width.px': 20
                }">
            </svg-icon>
            <span class="text-base font-medium">Comments</span>
        </div>
        <div 
            [ngClass]="{
                'text-primary font-semibold light-bg-color p-3 rounded-md pointer-events-none': tab === 'Activity',
                'pointer-events-auto cursor-pointer': tab !== 'Activity'
            }"
            (click)="tab='Activity'"
            class="flex flex-row gap-2.5 items-center">
            <svg-icon
                src="assets/icons/new-set/activity.svg"
                [svgStyle]="{
                    'height.px': 20,
                    'width.px': 20
                }">
            </svg-icon>
            <span class="text-base font-medium">Activity</span>
        </div>
    </div>
    <!-- height: calc(50vh - 100px); -->
    <div class="overflow-y-auto"
        style="max-height: 80vh;">
        
        <ng-container 
            *ngFor="let activity of activity$ | async">
            <!-- COMMENTS -->
            <ng-container
                *ngIf="tab === 'Comments'">
                <app-task-comment
                    class="ml-4"
                    *ngIf="activity.comment"
                    (onEdit)="commentEdit($event, $any(taskId))"
                    (onEditEnabled)="enableEditForComment($event)"
                    (onCommentDelete)="deleteComment($event,  $any(taskId))"
                    (onCommentResend)="resendComment(activity,  $any(taskId))"
                    [comment]="activity.comment"
                    [editableCommentId]="editableCommentId$ | async"
                    [userId]="(profile$ | async)?.userId"
                    [members]="(mentions$ | async) ?? []">
                </app-task-comment>
            </ng-container>

            <!-- ACTIVITY -->
            <ng-container
                *ngIf="tab==='Activity'">
                <app-task-logs
                    class="block ml-4"
                    [log]="activity.log"
                    *ngIf="activity.log">
                </app-task-logs>
            </ng-container>

        </ng-container>
    </div>
</div>

<div class="absolute bottom-0 w-full p-2.5">
  <app-task-comment-input
    *ngIf="task$ | async as task"
    (onComment)="addTaskComment($event, task.task_id)"
    [profile]="profile$ | async"
    [members]="(mentions$ | async) ?? []">
  </app-task-comment-input>
</div>

<mat-menu 
    #statusMenu="matMenu">
    <ng-template matMenuContent let-actions="actions">
        <button
            (click)="onChangeStatus('open')"
            [ngClass]="{
                'bg-gray-200 pointer-events-none': status?.value === 'open'
            }"
            mat-menu-item>
            <div class="flex items-center gap-3">
                <svg-icon
                    src="assets/icons/new-set/task-status/upcoming.svg"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                To do
            </div>
        </button>
        <button
            (click)="onChangeStatus('in-progress')"
            [ngClass]="{
                'bg-gray-200 pointer-events-none': status?.value === 'in-progress'
            }"
            mat-menu-item>
            <div class="flex items-center gap-3">
                <svg-icon
                    src="assets/icons/task/task-status/inProgress.svg"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                In Progress
            </div>
        </button>
        <button
            (click)="onChangeStatus('done')"
            [ngClass]="{
                'bg-gray-200 pointer-events-none': status?.value === 'done'
            }"
            mat-menu-item>
            <div class="flex items-center gap-3">
                <svg-icon
                    src="assets/icons/task/task-status/done.svg"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                Done
            </div>
        </button>
        <!-- <button
            (click)="onChangeStatus('trash')"
            [ngClass]="{
                'bg-gray-200 pointer-events-none': status?.value === 'trash'
            }"
            mat-menu-item>
            <div class="flex items-center gap-3">
                <svg-icon
                    src="assets/icons/new-set/trash.svg"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                Trash
            </div>
        </button> -->
    </ng-template>
</mat-menu>

<mat-menu 
    #calendarMenu="matMenu" 
    [hasBackdrop]="false">
    <ng-template matMenuContent>
        <div class="w-72 h-full bg-white flex flex-col p-0.5">
            <svg-icon
                (click)="calendarTrigger.closeMenu()"
                class="cursor-pointer hover:text-warning flex justify-end"
                [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
                src="assets/icons/new-set/close.svg">
            </svg-icon>
            <mat-calendar
                (click)="$event.stopPropagation();$event.preventDefault();"
                (selectedChange)="onSelectDate($event); closeDatePicker()"
                [(selected)]="currentDate">
            </mat-calendar>
        </div>
    </ng-template>
</mat-menu>

<mat-menu 
    #priorityMenu="matMenu">
    <ng-template matMenuContent let-actions="actions">
        <button
            (click)="onChangePriority(4)"
            [ngClass]="{
                'bg-gray-200 pointer-events-none': priority?.value === 'High'
            }"
            mat-menu-item>
            <div 
                class="flex items-center gap-3 High">
                <svg-icon
                    src="assets/icons/new-set/high.svg"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                <span class="text-base">High</span>
            </div>
        </button>
        <button
            (click)="onChangePriority(3)"
            [ngClass]="{
                'bg-gray-200 pointer-events-none': priority?.value === 'Medium'
            }"
            mat-menu-item>
            <div 
                class="flex items-center gap-3 Medium">
                <svg-icon
                    src="assets/icons/new-set/medium.svg"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                <span class="text-base">Medium</span>
            </div>
        </button>
        <button
            (click)="onChangePriority(2)"
            [ngClass]="{
                'bg-gray-200 pointer-events-none': priority?.value === 'Low'
            }"
            mat-menu-item>
            <div 
                class="flex items-center gap-3 Low">
                <svg-icon
                    src="assets/icons/new-set/low.svg"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                <span class="text-base">Low</span>
            </div>
        </button>
        <button
            (click)="onChangePriority(1)"
            [ngClass]="{
                'bg-gray-200 pointer-events-none': priority?.value === 'None'
            }"
            mat-menu-item>
            <div 
                class="flex items-center gap-3 None">
                <svg-icon
                    src="assets/icons/new-set/circle-off.svg"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                <span class="text-base">None</span>
            </div>
        </button>
    </ng-template>
</mat-menu>

<mat-menu 
    #assigneesMenu="matMenu">
    <ng-template 
        matMenuContent>
        <button
            mat-menu-item
            *ngFor="let user of usersList$ | async;">
            <div class="flex flex-row items-center gap-4 text-base" (click)="selectAssignees(user)">
                <ng-container 
                    *ngIf="((user?.firstName ?? '') + ' ' + (user?.lastName ?? '')) as username">
                    <div class="flex flex-row justify-center items-center gap-2.5">
                        <app-avatar
                            class="w-8 h-8 shrink-0"
                            [url]="user.avatar"
                            [name]="username"
                            [fontSize]="10">
                        </app-avatar>
                        <span class="text-base truncate">{{username}}</span>
                    </div>
                </ng-container>
                <svg-icon
                    *ngIf="isSelected(user.user_id)"
                    class="ml-auto text-gray-600"
                    src="assets/icons/new-set/check.svg"
                    [svgStyle]="{ 'width.px': 22, 'height.px': 22 }">
                </svg-icon>
            </div>
        </button>
    </ng-template>
</mat-menu>

<ng-template
    let-close="close"
    #datePicker>
    <div class="w-72 bg-white">
        <mat-calendar
            [selected]="currentDate">
        </mat-calendar>
    </div>
</ng-template>

<ng-template
    let-close="close"
    #datePickerReminder>
    <div class="w-72 bg-white">
        <mat-calendar
            (selectedChange)="onChangeReminderDate($event); close()"
            [selected]="reminderDate"
            [minDate]="currentDateReminder">
        </mat-calendar>
    </div>
</ng-template>

<mat-menu 
    #remindersMenu="matMenu">
    <ng-template 
        matMenuContent>
        <button
            (click)="patchReminder('none')"
            mat-menu-item>
            <div class="flex items-center gap-4 text-base">
                <ng-container>
                    <span class="text-base">None</span>
                </ng-container>
            </div>
        </button>

        <button
            (click)="patchReminder('in_15_minutes')"
            mat-menu-item>
            <div class="flex items-center gap-4 text-base">
                <ng-container>
                    <span class="text-base">In 15 Minutes</span>
                </ng-container>
            </div>
        </button>

        <button
            (click)="patchReminder('in_30_minutes')"
            mat-menu-item>
            <div class="flex items-center gap-4 text-base">
                <ng-container>
                    <span class="text-base">In 30 Minutes</span>
                </ng-container>
            </div>
        </button>
        <button
            (click)="patchReminder('in_one_day')"
            mat-menu-item>
            <div class="flex items-center gap-4 text-base">
                <ng-container>
                    <span class="text-base">In One Day</span>
                </ng-container>
            </div>
        </button>
        <button
            (click)="patchReminder('in_two_days')"
            mat-menu-item>
            <div class="flex items-center gap-4 text-base">
                <ng-container>
                    <span class="text-base">In Two Days</span>
                </ng-container>
            </div>
        </button>
        <button
            (click)="patchReminder('in_one_week')"
            mat-menu-item>
            <div class="flex items-center gap-4 text-base">
                <ng-container>  
                    <span class="text-base">In One Week</span>
                </ng-container>
            </div>
        </button>
        <button
            (click)="patchReminder('custom')"
            mat-menu-item>
            <div class="flex items-center gap-4 text-base">
                <ng-container>
                    <span class="text-base">Custom</span>
                </ng-container>
            </div>
        </button>
    </ng-template>
</mat-menu>

<mat-menu 
    #associationsMenu="matMenu">
    <ng-template 
        matMenuContent>
        <button
            mat-menu-item
            [matMenuTriggerFor]="contactListings">
            <div class="flex items-center gap-4 text-base">
                <span class="text-base">Contacts</span>
            </div>
        </button>
        <button
            mat-menu-item
            [matMenuTriggerFor]="companyListings">
            <div class="flex items-center gap-4 text-base">
                <span class="text-base">Companies</span>
            </div>
        </button>
    </ng-template>
</mat-menu>

<mat-menu 
    #contactListings="matMenu">
    <ng-template 
        *ngIf="{
            filteredContacts: filtredContacts$ | async
        } as streams"
        matMenuContent>
        <app-input-wrapper>
            <input
                input
                type="text"
                autocomplete="off"
                placeholder="Search Contact Name"
                [formControl]="contactSearchControl">
        </app-input-wrapper>
        <button
            mat-menu-item
            *ngFor="let contact of streams.filteredContacts ?? [];"
            (click)="selectContact(contact.contact_id)">
            <div class="flex items-center gap-4 text-base flex-row gap-2.5">
                <app-avatar
                    class="w-8 h-8 shrink-0"
                    [url]="contact?.avatar"
                    [name]="contact?.given_name"
                    [fontSize]="10">
                </app-avatar>
                <span class="text-base">{{contact?.given_name}} {{contact?.family_name}}</span>
            </div>
        </button>
    </ng-template>
</mat-menu>

<mat-menu 
    #companyListings="matMenu">
    <ng-template 
        *ngIf="{
            filtredCompanies: filtredCompanies$ | async
        } as streams"
        matMenuContent>
        <app-input-wrapper>
            <input
                input
                type="text"
                autocomplete="off"
                placeholder="Search Company Name"
                [formControl]="companySearchControl">
        </app-input-wrapper>
        <button
            mat-menu-item
            *ngFor="let company of streams.filtredCompanies?.companies ?? [];"
            (click)="selectCompany(company.yobi_crm_company_id)">
            <div class="flex items-center gap-4 text-base flex-row gap-2.5">
                <app-avatar
                    class="w-8 h-8 shrink-0"
                    [url]="company?.avatar"
                    [name]="company?.company_name"
                    [fontSize]="10">
                </app-avatar>
                <span class="text-base">{{ company?.company_name}}</span>
            </div>
        </button>
    </ng-template>
</mat-menu>