import { AnalyticsEventsNames } from '@/app/shared/analytics/analyticsEvents';
import * as CrossProcessExports from 'electron';
import * as Electron from 'electron';
import { WebFrame } from 'electron/renderer';
import { ReplaySubject, Subject } from 'rxjs';

type CrossProcessExports = typeof CrossProcessExports;
export 
enum DesktopEvent { open = "open", close = "close" }
export class ElectronService {
  private _electronProcess: CrossProcessExports;
  onDeepLink$ = new Subject<string>();

  constructor() {
    this.listenForDeepLink();
  }

  protected microphoneAccessStatus(): Promise<('not-determined' | 'granted' | 'denied' | 'restricted' | 'unknown')> {
    return this.electronRender?.invoke('microphone-status') as Promise<any>
  }

  protected async askMicrophonePermission(): Promise<boolean> {
    return this.electronRender?.invoke('microphone-permission')
  }

  public get electronRender(): Electron.IpcRenderer | null {
    return this.electronProcess?.ipcRenderer ?? null;
  }

  private get electronProcess(): CrossProcessExports | null {
    if (!this._electronProcess) {
      if (window && window.require) {
        this._electronProcess = window.require('electron');
        return this._electronProcess;
      }
      return null;
    }
    return this._electronProcess;
  }


  public get isElectronApp(): boolean {
    return !!window.navigator.userAgent.match(/Electron/);
  }

  public get isMacOS(): boolean {
    return this.isElectronApp && (window?.electron?.isMac || process.platform === 'darwin');
  }

  public get isWindows(): boolean {
    return this.isElectronApp && (window?.electron?.isWindows || process.platform === 'win32');
  }

  public get isLinux(): boolean {
    return this.isElectronApp && (window?.electron?.isLinux || process.platform === 'linux');
  }

  public get isX86(): boolean {
    return this.isElectronApp && process.arch === 'ia32';
  }

  public get isX64(): boolean {
    return this.isElectronApp && process.arch === 'x64';
  }

  public get isArm(): boolean {
    return this.isElectronApp && process.arch === 'arm';
  }

  public get webFrame(): WebFrame | null {
    return this.electronProcess ? this.electronProcess.webFrame : null;
  }

  public get shell(): CrossProcessExports.Shell | null {
    return this.electronProcess ? this.electronProcess.shell : null;
  }

  private listenForDeepLink() {
    if (this.isElectronApp) {
      this.electronRender?.on('deep-link-opened', (event, data: string) => {
        this.onDeepLink$.next(data);
      });
    }
  }

  openContextMenu(): void {
    this.electronRender?.send('show-context-menu')
  }
}
