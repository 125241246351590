import { REFRESH_TOKEN_KEY, TOKEN_KEY } from '@/app/Utilities/TokenKeys';
import { Injectable } from '@angular/core';
import { ILocalStorageService } from '../../Adapters/ILocalStorageService';

@Injectable({
  providedIn: 'root',
})
export class TokenStorgeService {
  constructor(private localStorageService: ILocalStorageService) { }

  public saveUser(user: any): void {
    this.localStorageService.setItem('yib_user', user);
  }

  public saveToken(token: string): void {
    this.localStorageService.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return (this.localStorageService.getItem(TOKEN_KEY));
  }

  public saveRefreshToken(token: string): void {
    this.localStorageService.setItem(REFRESH_TOKEN_KEY, token);
  }

  public getRefreshToken(): string | null {
    return (
      this.localStorageService.getItem(REFRESH_TOKEN_KEY)
    );
  }
}
