import { TenantRepository } from '@/app/Data/repositories/TenantRepository';
import {
  FETCH_SUPPORTED_LANGUAGES,
  FETCH_USER_SIGNATURE_STATUS,
  FetchUserSignatureStatusSuccessAction,
  GET_TENANT_CUSTOM_FIELDS,
  GET_TENANT_INTERNAL_CHANNELS, GET_TENANT_INTERNAL_CHANNELS_SUCCESS, GET_TENANT_PROFILE,
  GetInternalChannelsFailAction,
  GetInternalChannelsSuccessAction,
  GetTenantInternalChannelsAction,
  SEND_USER_FINGERPRINT,
  SET_TRANSLATION_LANGUAGE_PREFERENCES,
  SET_USER_SIGNATURE_STATUS,
  SendUserFingerprintSuccessAction,
  SetUserSignatureStatusFailAction,
  TOGGLE_CHANNEL_SYNTH,
  TOGGLE_DO_NOT_DISTURB,
  ToggleChannelSynthFailAction,
  ToggleChannelSynthSuccessAction,
  UpdateTenantCustomFields,
  UpdateTenantProfile,
  fetchSupportedLanguagesSuccessAction,
  FetchUserSignatureValueSuccessAction,
  SetUserSignatureValueFailAction,
  SET_USER_SIGNATURE_VALUE,
  FETCH_USER_SIGNATURE_VALUE,
  TOGGLE_CHANNEL_SYNTH_SUCCESS,
  SET_SYNTHAUTOREPLYDEFAULT_ACTION,
  FETCH_SYNTHAUTOREPLYDEFAULT_ACTION,
  fetchSynthAutoreplyDefaultSuccessAction,
  SetSynthAutoreplyDefaultFailAction,
  UPDATE_TENANT_BUSINESS_NAME,
  UpdateTenantBusinessNameSuccessAction,
  UpdateTenantBusinessNameErrorAction
} from '@/app/State/Tenant/tenant.action';
import { FacebookPixelService } from '@/app/Utilities/facebook-pixel/facebook-pixel.service';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';
import { ICustomFieldsRepository } from '@/app/core/IRepositories/ICustomFieldsRepository';
import { ITranslateRepository } from '@/app/core/IRepositories/ITranslateRepository';
import { GetInternalChannelsUseCase } from '@/app/core/usecases/Tenant/GetInternalChannelsUseCase';
import { GetTenantProfileUseCase } from '@/app/core/usecases/Tenant/GetTenantProfileUseCase';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, firstValueFrom, from, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { GET_TRANSLATION_LANGUAGE_PREFERENCES, TenantActions, UPDATE_TENANT_PROFILE, getTranslationPrefeerencesSuccessAction, setTranslationLanguagePreferencesSuccessAction } from './tenant.action';
import { IChannelRepository } from '@/app/core/IRepositories/IChannelRepository';
import { AppState } from '../AppState';
import { FetchContactByIdAction, GetUnclassifiedChannelDetailAction } from '../contact/contact.action';
import { Store } from '@ngrx/store';
import { FingerprintingService } from '@/app/Utilities/fingerprinting.service';
import { fingerprintDto } from '@/app/Data/DTO/fingerprintDto';

@Injectable()
export class TenantEffect {
  constructor(
    private actions$: Actions<TenantActions>,
    private tenantRepository: TenantRepository,
    private getTenantProfileUseCase: GetTenantProfileUseCase,
    private channelRepo: IChannelRepository,
    private facebookPixelService: FacebookPixelService,
    private store: Store<AppState>,
    private getTenantInternalChannels: GetInternalChannelsUseCase,
    private translateRepository: ITranslateRepository,
    private customFieldsRepository: ICustomFieldsRepository,
    private snackbarService: SnackbarService,
    private fingerprintingService: FingerprintingService
  ) { }

  onGetTenantInternalChannelsAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GET_TENANT_INTERNAL_CHANNELS),
        exhaustMap(() => {
          return this.getTenantInternalChannels.execute().pipe(
            map((result) => {
              return GetInternalChannelsSuccessAction();
            }),
            catchError((error: any) => {
              return of(GetInternalChannelsFailAction());
            })
          );
        })
      ),
    { dispatch: false }
  );

  onGetTranslationPrefeerencesAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GET_TRANSLATION_LANGUAGE_PREFERENCES),
        switchMap(() => {
          return this.translateRepository.getUserTranslationPreferences().pipe(
            map((translationLanguage) =>
              getTranslationPrefeerencesSuccessAction({ payload: translationLanguage })
            ),
            catchError((error: any) => of())
          );
        })
      ),
    { dispatch: true }
  );

  onChangeTranslationLanguageValue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_TRANSLATION_LANGUAGE_PREFERENCES),
        map(action => action.payload),
        switchMap((payload) => {
          return this.translateRepository.setUserTranslationPreferences(payload).pipe(
            map((translationLanguage) =>
              setTranslationLanguagePreferencesSuccessAction({ payload: translationLanguage })
            ),
            catchError((error: any) => of())
          );
        })
      ),
    { dispatch: true }
  );

  onUpdateTenantProfile$ = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_TENANT_PROFILE),
    tap((action) => {
      this.facebookPixelService.init(action.payload)
    })
  ), { dispatch: false })

  onUpdateTenantBusinessName$ = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_TENANT_BUSINESS_NAME),
    map(action => action.payload),
    switchMap(({ businessName }) => {
      return this.tenantRepository.setBusinessName(businessName).pipe(
        map((businessName: string) => {
          return (UpdateTenantBusinessNameSuccessAction({ payload: { businessName } }));
        }),
        catchError((httpError: HttpErrorResponse) => {
          this.snackbarService.openAlert({
            message: httpError.error.errors.message ?? 'Something went wrong',
            type: 'failure'
          })
          return of(UpdateTenantBusinessNameErrorAction({ payload: { errorMessage: httpError.error.errors.message } }));
        })
      )
    })
  ), { dispatch: true })

  onGetTenantProfileAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GET_TENANT_PROFILE),
        exhaustMap(() => {
          return this.getTenantProfileUseCase.execute().pipe(
            // should be updated automatically: usecase => db => effect update tenant profile
            // map((result) => {
            //   return UpdateTenantProfile({ payload: result });
            // })
          );
        })
      ),
    { dispatch: false }
  );
  onGetTenantCustomFieldsAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GET_TENANT_CUSTOM_FIELDS),
        exhaustMap(() =>
          this.customFieldsRepository.getCustomFields().pipe(
            map((result) => UpdateTenantCustomFields({ payload: result })),
            catchError(() => of())
          )
        )
      ),
    { dispatch: true }
  );

  initLoadTenantProfileObject = createEffect(
    () =>
      this.tenantRepository.getSavedTenantProfile().pipe(
        map((tenant) => {
          return UpdateTenantProfile({ payload: tenant! });
        })
      ),
    { dispatch: true }
  );

  onToggleDoNotDisturbStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TOGGLE_DO_NOT_DISTURB),
        map((action) => action.payload),
        switchMap(({ status, userId }) => this.tenantRepository.toggleDoNotDisturb(userId, status)),
        switchMap(() => this.getTenantProfileUseCase.execute().pipe(
          map((result) => UpdateTenantProfile({ payload: result }))
        ))
      ),
    { dispatch: true }
  );

  onfetchSupportedLanguagesAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FETCH_SUPPORTED_LANGUAGES),
        switchMap(() => this.translateRepository.getSupportedLnaguages().pipe(
          map(languages => fetchSupportedLanguagesSuccessAction({ payload: { languages } })),
          catchError(() => of())
        )),
      ),
    { dispatch: true }
  );

  onfetchSignatureStatusAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FETCH_USER_SIGNATURE_STATUS),
        switchMap(() => this.tenantRepository.fetchUserSignatureStatus().pipe(
          map(status => FetchUserSignatureStatusSuccessAction({ payload: { status } })),
          catchError(() => of())
        )),
      ),
    { dispatch: true }
  );

  onSetUserSignatureStatusAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_USER_SIGNATURE_STATUS),
        map(action => action.payload),
        switchMap(({ status }) => this.tenantRepository.setUserSignatureStatus({ status }).pipe(
          map(status => FetchUserSignatureStatusSuccessAction({ payload: { status } })),
          catchError((err: HttpErrorResponse) => {
            this.snackbarService.openAlert({
              message: err.error?.errors.message ?? 'Something went wrong',
              type: 'failure'
            })
            return of(SetUserSignatureStatusFailAction({ payload: { errorMessage: err.error.errors.message } }))
          })
        )),
      ),
    { dispatch: true }
  );

  onfetchSignatureValueAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FETCH_USER_SIGNATURE_VALUE),
        switchMap(() => this.tenantRepository.fetchUserSignatureValue().pipe(
          map(value => FetchUserSignatureValueSuccessAction({ payload: { value } })),
          catchError(() => of())
        )),
      ),
    { dispatch: true }
  );

  onSetUserSignatureValueAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_USER_SIGNATURE_VALUE),
        map(action => action.payload),
        switchMap(({ value }) => this.tenantRepository.setUserSignatureValue({ signature_value: value }).pipe(
          map(value => FetchUserSignatureValueSuccessAction({ payload: { value } })),
          catchError((err: HttpErrorResponse) => {
            console.log(err);
            this.snackbarService.openAlert({
              message: err.error?.errors.message ?? 'Something went wrong',
              type: 'failure'
            })
            return of(SetUserSignatureValueFailAction({ payload: { errorMessage: err.error.errors.message } }))
          })
        )),
      ),
    { dispatch: true }
  );

  internalChannelsGetSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(GET_TENANT_INTERNAL_CHANNELS_SUCCESS),
    tap(() => {
      // this.phoneRequestViewModel.init(true);
    })
  ), {
    dispatch: false
  })

  onSendUserFingerprint$ = createEffect(
    () => this.actions$.pipe(
      ofType(SEND_USER_FINGERPRINT),
      withLatestFrom(firstValueFrom(from(this.fingerprintingService.getInformation().catch(() => { })))),
      map(([action, fingerprint]) => fingerprint),
      filter((fingerprint): fingerprint is fingerprintDto => !!fingerprint),
      switchMap((fingerprint) => this.tenantRepository.sendUserFingerPrint(fingerprint).pipe(
        map(() => SendUserFingerprintSuccessAction())
      )),
    ), {
    dispatch: true
  })

  toggleChannelSynth$ = createEffect(() => this.actions$.pipe(
    ofType(TOGGLE_CHANNEL_SYNTH),
    map(payload => payload.payload),
    switchMap(payload => {
      const { channelId, ...request } = payload
      return this.channelRepo.toggleChannelSynth(request).pipe(
        map(() => ToggleChannelSynthSuccessAction({ payload })),
        catchError(() =>
          of(ToggleChannelSynthFailAction({ payload }))
        )
      )
    })
  ), {
    dispatch: true
  })

  toggleChannelSynthSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TOGGLE_CHANNEL_SYNTH_SUCCESS),
    map(payload => payload.payload),
    map(({ is_contact, contact_id, channelId }) =>
      is_contact
        ? FetchContactByIdAction({ payload: contact_id })
        : GetUnclassifiedChannelDetailAction({ payload: channelId! })
    ),
  ), {
    dispatch: true
  })

  /* ------------------------ synth auto reply default ------------------------ */

  onFetchSynthAutoreplyDefaultAction = createEffect(
    () => this.actions$.pipe(
      ofType(FETCH_SYNTHAUTOREPLYDEFAULT_ACTION),
      switchMap(() => this.tenantRepository.fetchAutopilotDefaultState().pipe(
        map(status => fetchSynthAutoreplyDefaultSuccessAction({ payload: { status } })),
        catchError(() => of())
      )),
    ),
    { dispatch: true }
  );

  onSetSynthAutoreplyDefaultAction = createEffect(
    () => this.actions$.pipe(
      ofType(SET_SYNTHAUTOREPLYDEFAULT_ACTION),
      map(action => action.payload),
      switchMap(({ status }) => this.tenantRepository.setAutopilotDefaultState({ status }).pipe(
        map(status => fetchSynthAutoreplyDefaultSuccessAction({ payload: { status } })),
        catchError((err: HttpErrorResponse) => {
          this.snackbarService.openAlert({
            message: err.error?.errors.message ?? 'Something went wrong',
            type: 'failure'
          })
          let errorMessage = 'Something went wrong';
          if (err.error && err.error.errors && err.error.errors.message) {
            errorMessage = err.error.errors.message;
          }
          return of(SetSynthAutoreplyDefaultFailAction({ payload: { errorMessage } }))
        })
      )),
    ),
    { dispatch: true }
  );
}
