import { AppState } from '@/app/State/AppState';
import { selectIsPrivateAccess, selectTenantProfile } from '@/app/State/Tenant/tenant.selector';
import { Roles } from '@/app/core/Models/Roles';
import { Tenant } from '@/app/core/Models/tenant';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, first, map } from 'rxjs';

@Injectable()
export class OnboardingGuard
  implements CanActivate {
  constructor(
    private store$: Store<AppState>,
    private router: Router
  ) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    // this.store$.dispatch(GrantUserStartAction())
    return combineLatest([
      this.store$.select(selectTenantProfile).pipe(first(x => !!x)),
      this.store$.select(selectIsPrivateAccess),
      // this.store$.select(selectCurrentPaymentMethod).pipe(first((x): x is PaymentMethod => !!x)),
    ]).pipe(
      map(([profile, privateAccess]) => {
        const granted = this.grantAccess(profile, privateAccess);
        !granted && this.router.navigateByUrl('getting-started');
        // this.store$.dispatch(GrantUserEndAction())
        return granted
      })
    )
  }

  canLoad(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    // this.store$.dispatch(GrantUserStartAction())
    return combineLatest([
      this.store$.select(selectTenantProfile).pipe(first(x => !!x)),
      this.store$.select(selectIsPrivateAccess),
      // this.store$.select(selectCurrentPaymentMethod).pipe(first((x): x is PaymentMethod => !!x)),
    ]).pipe(
      map(([profile, privateAccess]) => {
        const granted = this.grantAccess(profile, privateAccess);
        !granted && this.router.navigateByUrl('getting-started');
        // this.store$.dispatch(GrantUserEndAction())
        return granted
      })
    )
  }

  grantAccess(profile: Tenant | undefined, privateAccess: boolean | undefined) {
    const newOnboardingDate = new Date('02/28/2024')
    // const questionnaireDate = new Date('05/16/2024')
    return !!privateAccess
      || isNaN(Date.parse(profile!.tenant_created_dt as any))
      || (profile!.tenant_created_dt < newOnboardingDate)
      || profile!.roles.includes(Roles.user)
      || (
        !!profile?.account.is_updated 
        // && profile.account.phone.validation 
        // && profile.VOICE_ONBOARDING_COMPLETED 
        // && (!!profile?.onboarding_questions_answered || profile!.tenant_created_dt < questionnaireDate)
      );
  }
}
