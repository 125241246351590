import { DialogService, MyOverlayRef } from "@/app/Utilities/Dialog/dialog.service";
import { ConnectionPositionPair } from "@angular/cdk/overlay";
import { Directive, ElementRef, HostListener, Input, NgModule, TemplateRef, Type } from "@angular/core";

@Directive({
    selector: '[dropDownPanel]',
    exportAs: 'dropDownPanelDirective',
})
export class DropDownPanelDirective {

    @Input() dropDownPanel: string | TemplateRef<any> | Type<any>;
    @Input() panelClass: string[];
    @Input() relativeWidth: boolean = false;
    @Input() dropDownContext = {};
    @Input() dropDownOrigin: HTMLElement
    @Input() dropDownOffset: number
    @Input() position: 'right-top' | 'right-bottom' | 'top-right' // ... add as needed
    ref?: MyOverlayRef<void, void>

    constructor(
        private dialogService: DialogService,
        private originRef: ElementRef
    ) { }

    @HostListener('click', ['$event'])
    click() {
        const position =
            this.position === "right-top" ? new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'start', overlayY: 'bottom' })
                : this.position === "right-bottom" ? new ConnectionPositionPair({ originX: 'end', originY: 'top' }, { overlayX: 'start', overlayY: 'top' })
                    : this.position === "top-right" ? new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'bottom' })
                        : undefined
        if (this.dropDownPanel && !this.ref?.overlay.hasAttached()) {
            this.ref = this.dialogService.open<void, void>(
                this.dropDownPanel,
                {
                    data: undefined,
                    width: this.relativeWidth ? this.originRef.nativeElement.offsetWidth + 'px' : undefined,
                    origin: this.dropDownOrigin ?? this.originRef.nativeElement,
                    context: this.dropDownContext,
                    position,
                    panelClass: this.panelClass ?? ['border-highlighter', 'surface-bg-color', 'rounded-xl', 'overflow-hidden', 'shadow-md'],
                    backdropClass: 'cdk-overlay-transparent-backdrop',
                    offsetY: this.dropDownOffset ?? 8,
                }
            )
        }
    }
}

@NgModule({
    declarations: [DropDownPanelDirective],
    exports: [DropDownPanelDirective],
    imports: [],
    providers: [],
})
export class DropDownPanelDirectiveModule {
    constructor() { }
}