import { ThreadMessagesQueryDto } from '@/app/Data/DTO/ThreadMessagesQueryDto';
import { ThreadPreferences } from '@/app/State/Inbox/inbox.model';
import { Observable } from 'rxjs';
import { InteractionThread } from '../Models/Interaction';
import { RecentInteraction } from './../Models/Interaction';

export abstract class IThreadRepository {
  abstract getClassifiedMessages(
    query: ThreadMessagesQueryDto
  ): Observable<InteractionThread[]>;
  abstract resendUndeliveredMesage(
    interaction_id: number
  ): Observable<InteractionThread>;
  abstract getLocalThreadMessages(query: {
    unclassified: boolean; threadId: number; page: number; inbox?: number
  }): Observable<InteractionThread[]>;
  abstract saveThreadMessages(data: {
    interactions: InteractionThread[], page: number; unclassified: boolean; threadId: number
  }): void;
  abstract updateLocalThreadMessage(interaction: Partial<InteractionThread> & Pick<InteractionThread, 'interaction_id'>): void;
  abstract getUnclassifiedMessages(
    query: ThreadMessagesQueryDto
  ): Observable<InteractionThread[]>;
  abstract addThreadMessage(
    data: InteractionThread
  ): void;
  abstract saveThreadPreferences(
    data: ThreadPreferences
  ): void;
  abstract getThreadPreferences(
    threadId: number
  ): Observable<ThreadPreferences | undefined>;
  abstract appendRecentInteractionToThreadInteractions(
    data: RecentInteraction
  ): void;
}
