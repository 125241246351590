import { ErrorApiReponse } from '@/app/Data/services/Networking/ApiResponse';

export const handleLoginErrors = (error: ErrorApiReponse<any>): string => {
  return 'Email or password are incorrect';
};

export const handleSocialLoginErrors = (
  social: 'Google' | 'Facebook' | 'Apple'
): string => {
  return `Login with ${social} failed`;
};
