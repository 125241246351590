import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input,
  Output,
  ViewChild
} from '@angular/core';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';
import { DropDownPanelDirective } from '../../directives/dropdown-panel.directive';


@Component({
  selector: 'app-emoji-button',
  templateUrl: './emoji-button.component.html',
  styleUrls: ['./emoji-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiButtonComponent {
  @ViewChild('emojiPanelRef', { read: DropDownPanelDirective }) emojiPanelRef: DropDownPanelDirective
  @Input() origin: HTMLElement
  @Input() size = 20
  @Input() tooltip = 'Emoji'
  @Output() onSelect = new EventEmitter<string>();
  @Input() position: 'top' | 'bottom' = 'top'

  localization = this.localizationViewModel.localization;

  constructor(
    private localizationViewModel: LocalizationViewModel,
  ) { }

  addEmoji(event: any): void {
    this.onSelect.emit(event?.emoji?.native)
  }

  toggleEmoji() {
    this.emojiPanelRef.ref ? this.emojiPanelRef.ref.close({}) : this.emojiPanelRef.click()
  }
}
