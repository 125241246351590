import { ISnippetsRepository } from '@/app/core/IRepositories/ISnippetsRepository';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { from, map, Observable, pluck } from 'rxjs';
import { CreateSnippetDto, EditSnippetDto, SnippetDetails } from '../DTO/snippetsDto';
import { DatabaseService } from '../services/Database/database.service';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';
import { liveQuery } from 'dexie';

@Injectable({
    providedIn: 'root',
})

export class SnippetsRepository implements ISnippetsRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService) {}

  createSnippets(data: CreateSnippetDto): Observable<SnippetDetails> {
    const requestURL = `${environment.apiURL}snippet`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  getSnippets(): Observable<SnippetDetails[]>{
    const requestURL = `${environment.apiURL}snippet`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  editSnippet(request: EditSnippetDto, snippetId: number): Observable<SnippetDetails>{
    const requestURL = `${environment.apiURL}snippet/${snippetId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  deleteSnippet(snippetId: number): Observable<SnippetDetails>{
    const requestURL = `${environment.apiURL}snippet/${snippetId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }


  saveSnippets(snippets: SnippetDetails[]): void {
    this.databaseService
      .transaction('rw', [this.databaseService.snippets], async () => {
        await this.databaseService.snippets.clear()
        await this.databaseService.snippets.bulkPut(snippets);
      })
      .catch((error) => {
        console.error('Transaction Failed: ', error);
      });
  }

  getLocalSnippets(): Observable<SnippetDetails[]> {
    return from(
      liveQuery(() => {
        this.databaseService.snippets.mapToClass(SnippetDetails);
        return this.databaseService.snippets.toArray()
      })
    );
  }
}
