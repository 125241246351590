<div>
    <mat-icon
        (click)="dismiss()"
    >close</mat-icon>
    <div class="flex items-center gap-4">
        <div class="relative w-12 h-12">
            <img
                class="absolute top-0 left-0 h-full w-full object-fit"
                [src]="
                    data.success ? 'assets/yobi_emoji/yobi_happy_emoji.svg' 
                    : data.info ? 'assets/yobi_emoji/yobi_peace_emoji.svg' 
                    : 'assets/yobi_emoji/yobi_dead_emoji.svg'
                "
            />
        </div>
        <p>
            {{ data.message | translate : data.translateParams }}
        </p>
    </div>
</div>