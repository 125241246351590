import { ScheduledMessage } from './../../../core/Models/ScheduledMessage';
import { Contact } from '@/app/core/Models/contact';
import { ContactChannel, InternalChannel } from '@/app/core/Models/Channel';
import {
  InteractionThread,
  RecentInteraction,
  VoicemailInteraction,
} from '@/app/core/Models/Interaction';
import { Injectable } from '@angular/core';
import Dexie, { Table } from 'dexie';
import { Tenant } from '@/app/core/Models/tenant';
import { User } from '@/app/core/Models/User';
import { TeamMember } from '@/app/core/Models/TeamMember';
import { Note } from '@/app/core/Models/Note';
import { Task, TaskDetail } from '@/app/core/Models/Task';
import { Notification } from '@/app/core/Models/Notification';
import { ThreadPreferences } from '@/app/State/Inbox/inbox.model';
import { Tag } from '@/app/core/Models/tag';
import { InteractionClassification } from '@/app/core/Models/interactionClassification';
import { LocalFullTextSearch } from '@/app/core/Models/fullTextSearch';
import { RecentEmailInteraction, ThreadEmailInteraction } from '@/app/core/Models/email-interaction';
import { SnippetDetails } from '../../DTO/snippetsDto';
import { Company } from '@/app/core/Models/company';
import { Product } from '@/app/core/Models/product';
import { Deal } from '@/app/core/Models/deal';
import { Pipeline } from '@/app/core/Models/crm';
import { Synth } from '@/app/core/Models/synth';
import { Event } from '../../DTO/eventDto';

export const DATABASE_NAME = 'Yobi'
@Injectable({
  providedIn: 'root',
})
export class DatabaseService extends Dexie {
  contacts!: Table<Contact, string>;
  companies!: Table<Company, string>;
  products!: Table<Product, string>;
  deals!: Table<Deal, string>;
  pipelines!: Table<Pipeline, string>;
  contactsChannels!: Table<ContactChannel, string>;
  fullTextSearch!: Table<LocalFullTextSearch, string>;
  interactionClassification!: Table<InteractionClassification, number>;
  internalChannels!: Table<InternalChannel, string>;
  notes!: Table<Note, string>;
  notifications!: Table<Notification, string>;
  recentEmailInteractions!: Table<RecentEmailInteraction, string>;
  recentInteractions!: Table<RecentInteraction, string>;
  scheduledMessage!: Table<ScheduledMessage, number>;
  tags!: Table<Tag, string>;
  tasks!: Table<TaskDetail, string>;
  tasksList!: Table<Task, string>;
  teamMembers!: Table<TeamMember, string>;
  teamRecentInteractions!: Table<RecentInteraction, string>;
  teamThreadInteractions!: Table<InteractionThread, string>;
  tenants!: Table<Tenant, string>;
  threadEmailInteractions!: Table<ThreadEmailInteraction, string>;
  threadInteractions!: Table<InteractionThread, string>;
  threadPreferences!: Table<ThreadPreferences, string>;
  users!: Table<User, string>;
  snippets!: Table<SnippetDetails, number>;
  voicemailInteractions!: Table<VoicemailInteraction, string>;
  synth: Table<Synth, string>;
  events: Table<Event, string>;

  constructor() {
    super(DATABASE_NAME);
    this.version(24).stores({
      tags:
        '&tag_id, contact_id, unclassified_channel_id',
      scheduledMessage:
        '&id, celery_task_id, interaction_dt',
      recentInteractions:
        '&id, channel_id, interaction_dt, yobi_contact_id, page, unclassified_channel_id, interaction.interaction_id ',
      recentEmailInteractions:
        '&conversation_id, conversation_dt, _page, _classificationId, internal_channel_id',
      threadInteractions:
        '&interaction_id, [yobi_contact_id+page], [channel_id+page], interaction_dt, yobi_contact_id, page, channel_id, inbox_id',
      threadEmailInteractions:
        '&email_interaction_id, conversation_id, _page, created_dt',
      interactionClassification:
        '&interaction_id',
      teamRecentInteractions:
        '&interaction.user_id, interaction_dt, page, interaction.interaction_id, room_id',
      teamThreadInteractions:
        '&interaction_id, user_id, page, interaction_dt',
      contacts:
        '&contact_id, full_name, associated_contacts.contact_id, associated_companies.contact_id',
      companies:
        '&yobi_crm_company_id, company_name, associated_contacts.yobi_crm_company_id, associated_companies.yobi_crm_company_id, associated_attachments.yobi_crm_company_id',
      synth:
        '&synth_id, synth_display_name',
      products:
        '&yobi_crm_product_id',
      deals:
        '&yobi_crm_deal_id',
      pipelines:
        '&yobi_crm_pipeline_id, pipeline_name',
      contactsChannels:
        '&channel_id, contact_id',
      tenants:
        '&tenantId',
      internalChannels:
        '&channel_id, tenant_id',
      threadPreferences:
        '&threadId, primaryChannelValue, primaryContactChannelValue',
      users:
        '&user_id, created_dt',
      snippets:
        '&snippet_id',
      fullTextSearch:
        '&value, date',
      teamMembers:
        '&user_id',
      notes:
        '&note_id, contact_id, unclassified_channel_id, user_id',
      voicemailInteractions:
        '&interaction_id, external_interaction_id, interaction_dt, page',
      tasksList:
        '&task_id, priority, assigned_to, status, created_dt, user_assigned, filter, user_create',
      tasks:
        '&task_id',
      notifications:
        '&notification_id, created_dt, notification_type, user_id',
      events:
        '&id'
    }).upgrade(() => {
      this.clear();
    });
  }

  async clear() {
    await this.delete()
    await this.open()
  }


  async init(): Promise<any> {
    //this.close();
    // if (!this.isOpen()) {
    try {
      await this.open();
    } catch (e: any) {
      console.error(e);
      window.location.reload()
      this.clear()
    }
    /*  if (!this.isOpen()) {
        await this.open();
      }*/
    //}
  }
}