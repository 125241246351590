export enum auth {
  sign_in = 'auth.sign_in',
  sign_up = 'auth.sign_up',
  agree_and_sign_up = 'auth.agree_and_sign_up',
  agree_and_join = 'auth.agree_and_join',
  have_account = 'auth.have_account',
  reset_password_title = 'auth.reset_password_title',
  reset_password_enter_email = 'auth.reset_password_enter_email',
  reset_password_email_placeholder = 'auth.reset_password_email_placeholder',
  reset_password_sign_in = 'auth.reset_password_sign_in',
  reset_password_email_sent_to = 'auth.reset_password_email_sent_to',
  reset_password_not_received = 'auth.reset_password_not_received',
  sign_up_slogan = 'auth.sign_up_slogan',
  sign_up_title = 'auth.sign_up_title',
  sign_up_tos = 'auth.sign_up_tos',
  sign_up_use_social = 'auth.sign_up_use_social',
  app_slogan = 'auth.app_slogan',
  sign_in_sub_slogan = 'auth.sign_in_sub_slogan',
  yobi_app = 'auth.yobi_app',
  sign_in_forget_password = 'auth.sign_in_forget_password',
  sign_in_get_started = 'auth.sign_in_get_started',
  sign_in_no_account = 'auth.sign_in_no_account',
  sign_in_reset_now = 'auth.sign_in_reset_now',
  sign_in_slogan = 'auth.sign_in_slogan',
  sign_in_title = 'auth.sign_in_title',
  sign_in_use_social = 'auth.sign_in_use_social',
  password_secure_description = 'auth.password_secure_description',
  must_contain = 'auth.must_contain',
  one_lowercase_letter_restriction = 'auth.one_lowercase_letter_restriction',
  one_uppercase_letter_restriction = 'auth.one_uppercase_letter_restriction',
  one_digit_restriction = 'auth.one_digit_restriction',
  one_special_character_restriction = 'auth.one_special_character_restriction',
  eight_character_restriction = 'auth.eight_character_restriction',
  verify_your_email = 'auth.verify_your_email',
  enter_email_code = 'auth.enter_email_code',
  login_page = 'auth.login_page',
}