import { TeamMember } from '@/app/core/Models/TeamMember';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-dialer-transfer-list',
  templateUrl: './dialer-transfer-list.component.html',
  styleUrls: ['./dialer-transfer-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialerTransferListComponent implements OnInit, OnDestroy {
  @Input() members: TeamMember[];
  @Output() onAddEvent = new EventEmitter<TeamMember>();

  ngOnInit(): void {}
  ngOnDestroy(): void {}

  addMember(member: TeamMember): void {
    this.onAddEvent.emit(member);
  }
}
