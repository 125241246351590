import { AvatarModule } from '@/app/Ui/components/avatar/avatar.module';
import { TextInputModule } from './../new-text-input/text-input.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { TeamMemberPickerComponent } from './team-member-picker.component';

@NgModule({
  declarations: [TeamMemberPickerComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    TextInputModule,
    AvatarModule,
    MatRippleModule,
    FlexLayoutModule,
  ],
  exports: [TeamMemberPickerComponent],
})
export class TeamMemberPickerModule {}
