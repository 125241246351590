import { createSelector } from '@ngrx/store';
import { AppState } from '@/app/State/AppState';
import { BillingState } from './billing.reducer';

const billingState = (state: AppState) => state.billing;

export const selectActivePromotionCodes = createSelector(
  billingState,
  (state: BillingState) => state.activePromotionCodes
);

export const selectFetchingBillingPlans = createSelector(
  billingState,
  (state: BillingState) => state.fetchingBillingPlans
);

export const selectBillingPlans = createSelector(
  billingState,
  (state: BillingState) => state.billingPlans
);

export const applyingPromoCode = createSelector(
  billingState,
  (state: BillingState) => state.applyingPromoCode
);

export const selectActiveBillingSubscription = createSelector(
  billingState,
  (state: BillingState) => state.activeSubscription
);

export const selectCurrentPaymentMethod = createSelector(
  billingState,
  (state: BillingState) => state.currentPaymentMethod
);


export const selectBillingInvoices = createSelector(
  billingState,
  (state: BillingState) => state.billingInvoices
);


export const selectIsSubscriptionFullyResricted = createSelector(
  billingState,
  (state: BillingState) => state.activeSubscription?.is_fully_restricted
);

export const selectBillingPromptOpen = createSelector(
  billingState,
  (state: BillingState) => !!state.billingPromptOpen
);
