import { PlatformService } from '@/app/Utilities/platform/platform.service';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ILocalStorageService } from '@/app/Data/Adapters/ILocalStorageService';
import { Injectable } from '@angular/core';
import { environment } from '@/environments/environment';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: ILocalStorageService,
    private platformService: PlatformService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const hq = req.clone({
      setHeaders: {
        device: this.platformService.device,
        appVersion: environment.appVersion,
        'Encrypt-Response': 'true',
      },
    });
    let token =
      this.localStorageService.getItem('yib_tk');
    if (token) {
      hq.headers.append('Authorization', 'Bearer ' + token);
    }
    return next.handle(hq);
  }
}
