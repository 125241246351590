import { createReducer, on } from '@ngrx/store';
import {
  OpenTeamChatThreadAction,
  CloseTeamChatThreadAction,
  FetchTeamRecentMessagesAction,
  FetchTeamRecentMessagesFailAction,
  FetchTeamRecentMessagesSuccessAction,
  FetchTeamChatMessagesSuccessAction,
  FetchTeamChatMessagesAction,
  SendTeamChatMessageAction,
  ClearPendingTeamChatMessageAction,
  FetchTeamChatMessagesFailAction,
  DeleteTeamChatMessageSuccessAction,
  CloseAllTeamChatThreadsAction,
  SendTeamChatMessageSuccessAction,
  SendTeamChatMessageFailAction,
  ResendTeamChatMessageAction,
} from './teamInbox.action';
import { InteractionDirection, InteractionStatus, InteractionThread, InteractionType, RecentInteraction } from '@/app/core/Models/Interaction';
import { SignOutAction } from '../app/action';
import { TeamMember } from '@/app/core/Models/TeamMember';
import { TeamInteraction } from '@/app/core/Models/interaction-team';
import { TeamMessageBody } from '@/app/Data/DTO/TeamChatMessageDto';

export interface TeamInteractionsState<T> {
  fetching: boolean;
  isLastPage: boolean;
  page: number;
  nextPagesThreads: T[],
  error?: string,
}


export interface TeamInboxState {
  focusedUser: number;
  activeUsers: TeamMember[];
  recentInteractions: TeamInteractionsState<RecentInteraction>;
  threadInteractions: TeamInteractionsState<InteractionThread>;
  pendingThreadInteractions: { interaction: InteractionThread, body: TeamMessageBody }[];
  sending?: boolean;
}

export const initTeamInboxState: TeamInboxState = {
  focusedUser: 0,
  activeUsers: [],
  recentInteractions: {
    page: 1,
    fetching: false,
    isLastPage: false,
    nextPagesThreads: []
  },
  threadInteractions: {
    page: 1,
    fetching: false,
    isLastPage: false,
    nextPagesThreads: []
  },
  pendingThreadInteractions: [],
  sending: false
};

export const teamInboxReducer = createReducer(initTeamInboxState,
  // new message ---------------------------------------------------------------
  on(SendTeamChatMessageAction, (state: TeamInboxState, { payload }) => {
    let body = payload.getBody()
    let pendingTeamInteraction = Object.assign<TeamInteraction, Partial<TeamInteraction>>(new TeamInteraction(), {
      created_dt: new Date().toUTCString(),
      attachments: body.attachments.map(({ type, url, name }) => ({ type, url, name })),
      data: body.data,
      direction: InteractionDirection.outbound,
      status: InteractionStatus.sending,
      type: InteractionType.message,
      interaction_id: 0,
      is_room: false,
      receiver_id: body.receiver_id,
      sender_id: body.user_id,
      msg_id: body.msg_id
    });
    return {
      ...state,
      sending: true,
      pendingThreadInteractions: [
        ...state.pendingThreadInteractions,
        {
          interaction: pendingTeamInteraction.mapToThreadInteraction(),
          body: payload
        }]
    };
  }),
  on(SendTeamChatMessageSuccessAction, (state: TeamInboxState, { payload }) => {
    return {
      ...state,
      sending: false,
    };
  }),
  on(SendTeamChatMessageFailAction, (state: TeamInboxState, { payload: { msg_id } }) => {
    const { body, interaction } = state.pendingThreadInteractions.find(x => x.body.msg_id === msg_id)!
    return {
      ...state,
      sending: false,
      pendingThreadInteractions: [
        ...state.pendingThreadInteractions.filter(x => x.body.msg_id !== msg_id),
        {
          body,
          interaction: Object.assign<InteractionThread, Partial<InteractionThread>>(
            new InteractionThread(),
            { ...interaction, status: InteractionStatus.failed }
          )
        }
      ]
    };
  }),
  on(ResendTeamChatMessageAction, (state: TeamInboxState, { payload: { msg_id } }) => {
    const { body, interaction } = state.pendingThreadInteractions.find(x => x.body.msg_id === msg_id)!
    return {
      ...state,
      sending: true,
      pendingThreadInteractions: [
        ...state.pendingThreadInteractions.filter(x => x.body.msg_id !== msg_id),
        {
          body,
          interaction: Object.assign<InteractionThread, Partial<InteractionThread>>(
            new InteractionThread(),
            { ...interaction, status: InteractionStatus.sending }
          )
        }
      ]
    };
  }),
  on(ClearPendingTeamChatMessageAction, (state: TeamInboxState, { payload }) => {
    return {
      ...state,
      pendingThreadInteractions: state.pendingThreadInteractions.filter(m => m.body.msg_id && m.body.msg_id !== payload.msg_id)
    };
  }),
  // recent messages ---------------------------------------------------------------
  on(FetchTeamRecentMessagesAction, (state: TeamInboxState, { payload }) => {
    return {
      ...state,
      recentInteractions: {
        ...state.recentInteractions,
        page: payload.page,
        fetching: true,
      },
    };
  }),
  on(FetchTeamRecentMessagesSuccessAction, (state: TeamInboxState, { payload }) => {
    return {
      ...state,
      recentInteractions: {
        ...state.recentInteractions,
        fetching: false,
        isLastPage: !payload?.length,
      },
    };
  }),
  on(FetchTeamRecentMessagesFailAction, (state: TeamInboxState, { payload }) => {
    return {
      ...state,
      recentInteractions: {
        ...state.recentInteractions,
        fetching: false,
      },
    };
  }),

  // thread messages -------------------------------------------------------------
  on(FetchTeamChatMessagesAction, (state: TeamInboxState, { payload: { page, userId, page_size } }) => {
    return {
      ...state,
      threadInteractions: {
        ...state?.threadInteractions,
        fetching: true,
        error: undefined,
      },
    };
  }),
  on(FetchTeamChatMessagesSuccessAction, (state: TeamInboxState, { payload: { interactions, page } }) => {
    let nextPagesThreads = state.threadInteractions.nextPagesThreads.filter(i => i.page != page)
    nextPagesThreads = [...interactions, ...nextPagesThreads].sort((a, b) => b.interaction_dt.getTime() - a.interaction_dt.getTime())
    return {
      ...state,
      threadInteractions: {
        page,
        fetching: false,
        isLastPage: !interactions?.length,
        nextPagesThreads: page == 1 ? [] : nextPagesThreads
      },
    };
  }),
  on(DeleteTeamChatMessageSuccessAction, (state: TeamInboxState, { payload: { threadInteraction } }) => {
    let nextPagesThreads = state.threadInteractions.nextPagesThreads.filter(i => i.interaction_id != threadInteraction.interaction_id)
    return {
      ...state,
      threadInteractions: {
        ...state.threadInteractions,
        nextPagesThreads
      },
    };
  }),
  on(FetchTeamChatMessagesFailAction, (state: TeamInboxState, { payload }) => {
    return {
      ...state,
      threadInteractions: {
        ...state.threadInteractions,
        fetching: false,
        error: payload
      },
    };
  }),
  // -----------------------------------------------------------------------------
  on(OpenTeamChatThreadAction, (state: TeamInboxState, { payload: { user, idle } }) => {
    if (user) {
      let activeUsers = state.activeUsers.find(u => u.user_id == user.user_id)
        ? [...state.activeUsers]
        : [...state.activeUsers, user]
      activeUsers.length > 3 && activeUsers.shift()
      return {
        ...state,
        focusedUser: !idle ? user.user_id : (state.focusedUser || user.user_id),
        activeUsers
      };
    }

    return state
  }),
  on(CloseTeamChatThreadAction, (state: TeamInboxState, { payload: { userId } }) => {
    return {
      ...state,
      focusedUser: state.focusedUser == userId ? 0 : state.focusedUser,
      activeUsers: state.activeUsers.filter(u => u.user_id !== userId),
      threadInteractions: {
        page: 1,
        fetching: false,
        isLastPage: false,
        nextPagesThreads: []
      },
      pendingThreadInteractions: []
    };
  }),
  on(CloseAllTeamChatThreadsAction, (state: TeamInboxState,) => {
    return {
      ...state,
      focusedUser: 0,
      activeUsers: [],
      threadInteractions: {
        page: 1,
        fetching: false,
        isLastPage: false,
        nextPagesThreads: []
      },
      pendingThreadInteractions: []
    };
  }),
  on(SignOutAction, (state: TeamInboxState) => {
    return {
      ...initTeamInboxState,
    };
  })
);
