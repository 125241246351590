import { Notification, NotificationType } from '@/app/core/Models/Notification';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationItemComponent {
  @Input() notification: Notification;

  get displayName() {
    return `${this.notification.triggered_by.firstName} ${this.notification.triggered_by.lastName}`
  }

  get icon(): any {
    const type = this.notification.notification_type
    return type === NotificationType.inline_note_mention ? 'assets/icons/new-set/note.svg'
      : type === NotificationType.note_mention ? 'assets/icons/new-set/note.svg'
        : type === NotificationType.add_user || type === NotificationType.team_chat_mention ? 'assets/icons/new-set/users.svg'
          : type.includes('task') ? 'assets/icons/new-set/clipboard-check.svg'
            : ''
  }

  get color(): any {
    const type = this.notification.notification_type
    return type === NotificationType.inline_note_mention ? 'text-yellow-500'
      : type === NotificationType.note_mention ? 'text-yellow-500'
        : type === NotificationType.add_user ? 'text-primary'
          : type.includes('task') ? 'text-success'
            : ''
  }

  get description() {
    return this.notification.notification_description
      ? this.notification.notification_description
      : this.notification.notification_type === 'task_reminder'
        ? 'Task reminder' : this.notification.notification_type === 'team_chat_mention' ? `mentioned you in ${this.notification.room_details?.room_name}`
        : ''
  }
}
