<div
  class="text-dark p-4 flex flex-col gap-4 w-72"
  (click)="$event.stopPropagation()"
>
  <div class="flex flex-col gap-1">
    <p class="font-medium">{{localization.terms.microphones | translate }}</p>
    <app-input-wrapper>
      <mat-select
        select
        [formControl]="currentInput"
        [disableOptionCentering]="true"
      >
        <mat-option
          *ngFor="let device of availableInputDevices"
          [value]="device.deviceId"
        >
          {{ device?.label }}
        </mat-option>
      </mat-select>
    </app-input-wrapper>
  </div>

  <div class="flex flex-col gap-1">
    <p class="font-medium">{{localization.terms.speakers | translate }}</p>
    <app-input-wrapper>
      <mat-select
        select
        [formControl]="currentOutput"
        [disableOptionCentering]="true"
      >
        <mat-option
          *ngFor="let device of availableOutputDevices"
          [value]="device.deviceId"
        >
          {{ device?.label }}
        </mat-option>
      </mat-select>
    </app-input-wrapper>
  </div>
</div>