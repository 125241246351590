import { CreateUserDto, CreateUserResponseDto, InviteUserResponseDto } from './../../Data/DTO/CreateUserDto';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { InviteUserDto } from '../../Data/DTO/CreateUserDto';
import { Observable } from 'rxjs';
import { User, UserInvitation } from '@/app/core/Models/User';
import { TeamMember } from '@/app/core/Models/TeamMember';
import { SuccessApiResponse } from '@/app/Data/services/Networking/ApiResponse';
export abstract class IUsersRepository {
  abstract getUsers(): Observable<User[]>;
  abstract getInvitations(): Observable<UserInvitation[]>;
  abstract deleteInvitation(invitationId: number): Observable<SuccessApiResponse<any>>;
  abstract saveUsersList(users: User[]): void;
  abstract saveTeamMembers(users: TeamMember[]): void;
  abstract getLocalUsersList(): Observable<User[]>;
  abstract inviteUser(request: InviteUserDto): Observable<InviteUserResponseDto>;
  abstract createUser(request: CreateUserDto ): Observable<CreateUserResponseDto>;
  abstract getTeamMembers(): Observable<TeamMember[]>;
  abstract getLocalTeamMembers(): Observable<TeamMember[]>;
}
