import { OnboardingQuestionAnswerBody } from '@/app/Data/DTO/OnboardingDto';
import { OnboardingVoiceSynthDto } from '@/app/Data/DTO/OnboardingVoiceSynthDto';
import { VoiceMailTranscriptionDto } from '@/app/Data/DTO/TranscriptionDto';
import { OnboardingQuestionsSignature } from '@/app/core/Models/Onboarding';
import { createAction, props, union } from '@ngrx/store';

/* ------------------------------ Verify phone ------------------------------ */
export const LOAD_ONBOARDING_QUESTIONS =
  '[Onboarding Questions] Load Onboarding Questions';
export const LoadOnboardingQuestionsAction = createAction(
  LOAD_ONBOARDING_QUESTIONS,
);

export const LOAD_ONBOARDING_QUESTIONS_SUCCESS =
  '[Onboarding Questions] Load Onboarding Questions Success';
export const LoadOnboardingQuestionsSuccessAction = createAction(
  LOAD_ONBOARDING_QUESTIONS_SUCCESS,
  props<{ payload: OnboardingQuestionsSignature[] }>()
);
export const LOAD_ONBOARDING_QUESTIONS_FIAL =
  '[Onboarding Questions] Load Onboarding Questions Fail';
export const LoadOnboardingQuestionsFailAction = createAction(
  LOAD_ONBOARDING_QUESTIONS_FIAL,
);

/* ------------------------- onboarding voice synth ------------------------- */
export const VOICE_SYNTH_ONBOARDING_STATUS_UPDATE = '[Synth onboarding] voice synth onboarding status update';
export const VoiceSynthOnboardingStatusUpdateAction = createAction(
  VOICE_SYNTH_ONBOARDING_STATUS_UPDATE,
  props<{ payload: OnboardingVoiceSynthDto }>()
);

export const VOICE_SYNTH_ONBOARDING_TRANSCRIPTION = '[Synth onboarding] voice synth onboarding transcription';
export const VoiceSynthOnboardingTranscriptionAction = createAction(
  VOICE_SYNTH_ONBOARDING_TRANSCRIPTION,
  props<{ payload: VoiceMailTranscriptionDto }>()
);


export const SUBMIT_ONBOARDING_QUESTIONS =
  '[Onboarding Questions] Submit Onboarding Questions';
export const SubmitOnboardingQuestionsAction = createAction(
  SUBMIT_ONBOARDING_QUESTIONS,
  props<{ payload: OnboardingQuestionAnswerBody }>()
);

export const SUBMIT_ONBOARDING_QUESTIONS_SUCCESS =
  '[Onboarding Questions] Submit Onboarding Questions Success';
export const SubmitOnboardingQuestionsSuccessAction = createAction(
  SUBMIT_ONBOARDING_QUESTIONS_SUCCESS,
);


export const SUBMIT_ONBOARDING_QUESTIONS_FIAL =
  '[Onboarding Questions] Submit Onboarding Questions Fail';
export const SubmitOnboardingQuestionsFailAction = createAction(
  SUBMIT_ONBOARDING_QUESTIONS_FIAL,
);

export const COMPLETE_ONBOARDING = '[Onboarding] Complete Onboarding';
export const CompleteOnboardingAction = createAction(
  COMPLETE_ONBOARDING,
);

export const COMPLETE_ONBOARDING_SUCCESS = '[Onboarding] Complete Onboarding Success';
export const CompleteOnboardingSuccessAction = createAction(
  COMPLETE_ONBOARDING_SUCCESS,
);

export const COMPLETE_ONBOARDING_FAIL = '[Onboarding] Complete Onboarding Fail';
export const CompleteOnboardingFailAction = createAction(
  COMPLETE_ONBOARDING_FAIL,
  props<{ error: any }>()
);


const all = union({
  // LoadOnboardingQuestionsAction,
  // LoadOnboardingQuestionsSuccessAction,
  SubmitOnboardingQuestionsAction,
  SubmitOnboardingQuestionsSuccessAction,
  CompleteOnboardingAction,
  CompleteOnboardingSuccessAction,
  CompleteOnboardingFailAction
});

export type OnboardingActions = typeof all;