<app-auth-banner class="absolute flex-1 w-full h-full"></app-auth-banner>

<div class="wrapper">
  <div class="flex flex-col text-center gap-5">
    <div class="text-3xl font-semibold">
      {{ ( (isResetPassword$ | async) ?
      localization.auth.reset_password_title: localization.placeholder.type_your_password) | translate }}
    </div>
    <p class="text-base">{{localization.auth.password_secure_description | translate}}</p>
  </div>
  <div class="flex flex-col gap-7 w-full">
    <app-password-input
      (keyup.enter)="submit()"
      [formControl]="passwordControl"
    ></app-password-input>
    <div class="flex flex-col gap-2">
      <p class="font-semibold text-base mb-1">{{localization.auth.must_contain | translate}}:</p>
      <p
        class="text-base"
        [class.validCriteria]="!passwordControl.getError('lowercase')"
      >
        {{localization.auth.one_lowercase_letter_restriction | translate}}
      </p>
      <p
        class="text-base"
        [class.validCriteria]="!passwordControl.getError('uppercase')"
      >
        {{localization.auth.one_uppercase_letter_restriction | translate}}
      </p>
      <p
        class="text-base"
        [class.validCriteria]="!passwordControl.getError('digit')"
      >
        {{localization.auth.one_digit_restriction | translate}}
      </p>
      <p
        class="text-base"
        [class.validCriteria]="!passwordControl.getError('specialchar')"
      >
        {{localization.auth.one_special_character_restriction | translate}}
      </p>
      <p
        class="text-base"
        [class.validCriteria]="!passwordControl.getError('minLength')"
      >
        {{localization.auth.eight_character_restriction | translate}}
      </p>
    </div>
  </div>
  <div
    *ngIf="authViewModel.addPasswordState$ | async as vm"
    class="w-full"
  >
    <app-button
      [disabled]="!passwordControl.valid"
      (click)="submit()"
    >
      {{ (vm.loading ? localization.labels.wait_please:localization.labels.finish) | translate }}
    </app-button>
  </div>
</div>