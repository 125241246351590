import { IMessagesRepository } from '@/app/core/IRepositories/IMessagesRepository';
import { UnclassifiedChannel } from '@/app/core/Models/Channel';
import { ChannelType } from '@/app/core/Models/ChannelTypes';
import { Inbox } from '@/app/core/Models/inbox';
import { RecentInteraction, RecentMessagesQueryParams } from '@/app/core/Models/Interaction';
import { RecentInteractionsFilter } from '@/app/core/Models/RecentInteractionsFilter';
import { AppState } from '@/app/State/AppState';
import { FetchContactByIdSuccessAction } from '@/app/State/contact/contact.action';
import { SelectEmailInboxAction } from '@/app/State/email-inbox/email-inbox.action';
import {
  FetchRecentMessagesAction,
  InboxSelectionAction,
  OpenConversationThreadAction,
  SetActiveThreadIdAction,
  UpdateRecentInteractionsFilterAction
} from '@/app/State/Inbox/inbox.action';
import {
  selectFetchingInboxes,
  selectInboxes,
  selectIsRecentInteractionFetching,
  selectRecentInteractionsFilter,
  selectRecentInteractionsPage,
  selectRecentInteractionsState,
  selectSelectedInbox,
} from '@/app/State/Inbox/inbox.selector';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest, debounceTime,
  map, shareReplay,
  switchMap,
  tap
} from 'rxjs';
import { TaskViewModel } from './taskViewModel';

@Injectable({
  providedIn: 'root',
})
export class MessagesViewModel {

  selectRecentInteractionsState$ = this.store.select(
    selectRecentInteractionsState
  );
  selectRecentInteractionsStatePage$ = this.store.select(
    selectRecentInteractionsPage
  );

  isFetching$ = this.store
    .select(selectIsRecentInteractionFetching)
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  recentInteractionsFilter$ = this.store
    .select(selectRecentInteractionsFilter)
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  inboxes$ = this.store
    .select(selectInboxes)
    .pipe(
      map(inboxes => [...inboxes].sort((a, b) => !a.is_visible ? 1 : !b.is_visible ? -1 : 0)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  fetchingInboxes$ = this.store
    .select(selectFetchingInboxes)
  selectedInboxId$ = new BehaviorSubject<any | undefined>(undefined);
  selectedInbox$ = this.store
    .select(selectSelectedInbox)
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  recentMessages$ = combineLatest([
    this.selectRecentInteractionsStatePage$,
    this.recentInteractionsFilter$,
    this.selectedInbox$
  ]).pipe(
    debounceTime(150),
    switchMap(([page, filter, inbox]) =>
      this.messageRepository.getLocalRecentMessages({
        page,
        page_size: 30,
        filter,
        inboxId: inbox?.is_channel ? inbox.inbox_id : undefined
      })
    )
  );

  mapTaskUnclassified: any = {};


  constructor(
    private store: Store<AppState>,
    private messageRepository: IMessagesRepository,
    private taskViewModel: TaskViewModel
  ) { }


  validateActiveTask() {
    const taskId = this.taskViewModel.taskchannelId$.getValue();
    if (taskId !== 0) this.taskViewModel.taskchannelId$.next(null);
  }

  deleteRecentInteractionByUnclassifiedChannelId(unclassifiedId: number) {
    this.messageRepository.deleteLocalRecentInteractionByUnclassifiedChannelId(unclassifiedId)
  }

  fetchRecentMessages(query: RecentMessagesQueryParams): void {
    this.store.dispatch(FetchRecentMessagesAction({ payload: query }));
  }

  openConversationThread(payload: { threadId: number; unClassified: boolean; interaction?: RecentInteraction }): void {
    this.store.dispatch(OpenConversationThreadAction({ payload }));
    this.validateActiveTask();
  }

  mapUnclassified(threadId: any, interaction: RecentInteraction) {
    let g = new UnclassifiedChannel()
    g.channel_id = interaction?.channel_id!
    g.channel_type = interaction?.channel_type!
    g.unclassified_channel_id = interaction?.unclassified_channel_id!
    g.channel_value = interaction?.channel_value!
    g.contact_info = interaction?.displayName!
    g.is_blocked = interaction?.is_blocked!
    g.tenant_id = interaction?.tenant_id!
    g.created_dt = interaction?.interaction.created_dt!
    g.avatar = interaction?.avatar!
    let f = g.mapToUnclassifiedContact();
    this.store.dispatch(FetchContactByIdSuccessAction({
      payload: f
    }))
    this.SetActiveThreadIdAction({ id: threadId, unClassified: true });
  }


  updateRecentInteractionFilter(filter: RecentInteractionsFilter): void {
    //this.resetCurrentRecentInteractionsPage();
    this.store.dispatch(UpdateRecentInteractionsFilterAction({ payload: filter }));
  }

  selectInbox(inbox?: Inbox) {
    this.selectedInboxId$.next(inbox?.inbox_type === 'tags' ? inbox.inbox_id : undefined);
    inbox && [ChannelType.Outlook, ChannelType.Google].includes(inbox?.channel_type as any)
      ? this.store.dispatch(SelectEmailInboxAction({ payload: { inbox } }))
      : this.store.dispatch(InboxSelectionAction({ payload: { inbox } }));
  }

  getLocalMesssagesIsPinned() {
    return this.messageRepository.getLocalMesssagesIsPinned();
  }

  getRecentMessagesByChannelId(channelId: any) {
    return this.messageRepository.getRecentMessagesByChannelId(channelId);
  }

  SetActiveThreadIdAction(payload: { id: any, unClassified: boolean }) {
    this.store.dispatch(SetActiveThreadIdAction({ payload }));
  }

  saveRecentMessages(data: RecentInteraction[]) {
    return this.messageRepository.saveRecentMessages(data, {
      "params": {
        "page": 1,
        "page_size": 30
      },
      "filter": RecentInteractionsFilter.inbox,
      inbox: undefined
    });
  }

}
