import { RecentEmailsQueryDto, ThreadEmailsQueryDto } from "@/app/Data/DTO/emailMessagingDto";
import { Observable } from "rxjs";
import { RecentEmailInteraction, ThreadEmailInteraction } from "../Models/email-interaction";
import { EmailInbox } from "../Models/inbox";
import { InteractionStatus } from "../Models/Interaction";

export abstract class IEmailMessagingRepository {
    abstract fetchEmailClassifications(channel_id: number): Observable<EmailInbox>;
    abstract fetchRecentEmails(query: RecentEmailsQueryDto): Observable<RecentEmailInteraction[]>;
    abstract fetchEmailInteractions(query: ThreadEmailsQueryDto): Observable<ThreadEmailInteraction[]>;
    abstract saveRecentEmailMessages(data: RecentEmailInteraction[], query: RecentEmailsQueryDto): void;
    abstract saveThreadEmailMessages(data: ThreadEmailInteraction[], query: ThreadEmailsQueryDto): void;
    abstract saveThreadEmailMessage(data: ThreadEmailInteraction, page?: number): void
    abstract getLocalRecentMessages({ page, page_size, channelId, classification_id }: RecentEmailsQueryDto): Observable<RecentEmailInteraction[]>;
    abstract getLocalThreadMessages({ page, page_size, conversation_id }: ThreadEmailsQueryDto): Observable<ThreadEmailInteraction[]>
    abstract findRecentEmailMessage(id: number): Observable<RecentEmailInteraction | undefined>;
    abstract sendOutlookReplyEmail(request: FormData): Observable<any>
    abstract sendGoogleReplyEmail(request: FormData): Observable<any>
    abstract sendEmailInlineNote(query: { content: string, conversationId: number }): Observable<ThreadEmailInteraction>
    abstract updateReadStatus(request: { channelId: number; conversation_id: number }): Observable<ThreadEmailInteraction[]>
    abstract updateLocalThreadEmailMessagesStatus(interactionsIds: number[], conversationId: number, status: InteractionStatus): void
}

