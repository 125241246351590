import { EnvironmentType } from '@/app/shared/Config/IConfig';
import minApiVersion from './min-version-api';
const { version } = require('../../package.json')

export const environment = {
  production: false,
  name: 'dev',
  jotformId: '240635268614054',
  synthNumber: '(213) 513-4847',
  sentry_web: 'https://335f317f7dd148c28606ef0502ca3be6@o1249731.ingest.sentry.io/6410563',
  sentry_desktop: 'https://a044aa24652d4bfdb64c2b10ca10eb57@o1249731.ingest.sentry.io/6436602',
  envType: EnvironmentType.dev,
  mixpanel: {
    token: 'd141d8b61c6c40efc39b4e3f3c8055ac'
  },
  appName: 'yobi',
  VMsocketURL: 'https://ws-dev1.yib.io:3300',
  socketURL: 'https://ws-dev1.yib.io:3000',
  twilioSocketURL: 'https://ws-dev1.yib.io:3000',
  apiURL: 'https://api-dev1.yib.io/api/v1/',
  callback: 'http://localhost:4200',
  server_url: 'https://api-dev1.yib.io/',
  appURL: 'https://dev1.yib.io',
  outboundCallURL: 'https://outbound-dev1.yib.io',
  synthTrainerWorkflowUrl: 'https://devsynth-trainer.yib.io',
  min_version_api: minApiVersion,
  appVersion: version,
  facebookAppId: '300552591731208',
  stripePublicApiKey: 'pk_test_51JeOrpLbN1ZShO2FJcm409KGySJllRmc68w5mM1ZtciaDFUP7tTXDwdLTLUJqVwyyVk9wmtENMH4b6xAflWMYqFD00o25QLiQe',
  firebase: {
    apiKey: 'AIzaSyDiV9sytygTJH-ywTCbkvfjKSS-K-sB4i4',
    authDomain: 'yobi-qa.firebaseapp.com',
    databaseURL: 'https://yobi-qa-default-rtdb.firebaseio.com',
    projectId: 'yobi-qa',
    storageBucket: 'yobi-qa.appspot.com',
    messagingSenderId: '645784234743',
    appId: '1:645784234743:web:11fa4e1be9c8415fa8c1f2',
    measurementId: 'G-MHQQCPXQ3N',
  },
  chatWidget: {
    token: "e6ce0782-11ce-4fdd-b098-b7991c98b0d1",
    inbox: "e22e801b-c19f-4235-b28a-430a055572d7",
  }
};
