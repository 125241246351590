import { NotificationsViewModel } from '@/app/Ui/ViewModels/NotificationsViewModel';
import { Notification } from '@/app/core/Models/Notification';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { LocalizationViewModel } from '../ViewModels/localizationViewModel';

@Component({
  selector: 'app-notifications-center',
  templateUrl: './notifications-center.component.html',
  styleUrls: ['./notifications-center.component.scss'],
})
export class NotificationsCenterComponent implements OnInit {

  @Output() close = new EventEmitter()
  notificationsState$ = this.notificationsViewModel.notificationsState$;
  notifications$ = this.notificationsViewModel.notifications$;
  localization = this.localiztionViewModel.localization

  constructor(
    private localiztionViewModel: LocalizationViewModel,
    private notificationsViewModel: NotificationsViewModel,
  ) { }

  ngOnInit() {
    this.notificationsViewModel.getNotifications(1);
    this.notificationsViewModel.getUnreadNotificationsCount();
  }

  onScroll(event: Event) {
    const target = event.target as HTMLElement;
    if (target.scrollTop + target.clientHeight + 2 >= target.scrollHeight) {
      firstValueFrom(this.notificationsState$).then(state =>
        !state.loading
        && !state.isLastPage
        && this.notificationsViewModel.getNotifications((state.currentPage ?? 0) + 1)
      )
    }
  }

  dismiss() {
    this.notificationsViewModel.dismissNotifications();
  }

  openNotification(notification: Notification) {
    this.notificationsViewModel.openNotification(notification);
    this.close.emit();
  }

}
