<button
  #btn
  mat-ripple
  [type]="type"
  [disabled]="disabled"
  class="base-button"
  [ngClass]="
    disabled ? 'secondary-bg-color text-mild cursor-not-allowed'
    : color == 'primary' ? 'text-gray-100 bg-primary disabled:active:bg-light active:bg-blue-400'
    : color == 'success' ? 'text-white bg-success disabled:active:bg-light active:success'
    : color == 'accent' ? 'text-gray-100 bg-accent disabled:active:bg-light active:bg-blue-400'
    : color == 'warning' ? 'text-gray-100  bg-warning outline-none' 
    : color == 'light' ? 'text-dark light-bg-color outline-none'
    : color == 'transparent-warning' ? 'text-warning hover:secondary-bg-color outline-none'
    : color == 'transparent-primary' ? 'text-primary hover:light-bg-color outline-none'
    : color == 'transparent-secondary' ? 'text-mild hover:text-dark hover:secondary-bg-color outline-none' 
    : ''
  "
  (click)="onClick.emit($event)"
>
  <ng-content></ng-content>
</button>