import { ScheduleBulkDto } from "@/app/Data/DTO/ScheduleMessageDto";
import { ReformulationDto, ReformulationResponseDto } from "@/app/Data/DTO/reformulationDto";
import { bulkSmsDTO } from "@/app/Data/DTO/smsDTO";
import { createAction, props, union } from "@ngrx/store";

export const TOGGLE_MESSAGE_COMPOSER = '[Message composer] toggle message composer';
export const ToggleMessageComposerAction = createAction(TOGGLE_MESSAGE_COMPOSER,
  props<{ payload: { status?: boolean } }>()
);

/* --------------------------- send bulk messages --------------------------- */
export const SEND_MULTIPLE_MESSAGES_ACTION = '[Message composer] Send Multiple Messages';
export const SendMultipleMessagesAction = createAction(
  SEND_MULTIPLE_MESSAGES_ACTION,
  props<{ payload: bulkSmsDTO }>()
);

export const SEND_MULTIPLE_MESSAGES_SUCCESS_ACTION = '[Message composer] Send Multiple Messages Success';
export const SendMultipleMessagesSuccessAction = createAction(
  SEND_MULTIPLE_MESSAGES_SUCCESS_ACTION,
  props<{ payload: bulkSmsDTO }>()
);

export const SEND_MULTIPLE_MESSAGES_FAIL_ACTION = '[Message composer] Send Multiple Messages Fail';
export const SendMultipleMessagesFailAction = createAction(
  SEND_MULTIPLE_MESSAGES_FAIL_ACTION
);

/* --------------------------- schedule bulk messages --------------------------- */
export const SCHEDULE_MULTIPLE_MESSAGES_ACTION = '[Message composer] Sheudle Multiple Messages';
export const ScheduleMultipleMessagesAction = createAction(
  SCHEDULE_MULTIPLE_MESSAGES_ACTION,
  props<{ payload: ScheduleBulkDto }>()
);

export const SCHEDULE_MULTIPLE_MESSAGES_SUCCESS_ACTION = '[Message composer] Sheudle Multiple Messages Success';
export const ScheduleMultipleMessagesSuccessAction = createAction(
  SCHEDULE_MULTIPLE_MESSAGES_SUCCESS_ACTION,
  props<{ payload: ScheduleBulkDto }>()
);

export const SCHEDULE_MULTIPLE_MESSAGES_FAIL_ACTION = '[Message composer] Sheudle Multiple Messages Fail';
export const ScheduleMultipleMessagesFailAction = createAction(
  SCHEDULE_MULTIPLE_MESSAGES_FAIL_ACTION
);


/* -------------------------- message reformulation ------------------------- */
export const REFORMULATE_MULTIPLE_MESSAGES_CONTENT = '[Message composer] reformulate multiple messages content';
export const ReformulateMultipleMessagesContentAction = createAction(
  REFORMULATE_MULTIPLE_MESSAGES_CONTENT,
  props<{ payload: { request: ReformulationDto } }>()
);
export const REFORMULATE_MULTIPLE_MESSAGE_RESPONSES_CONTENT = '[Message composer] reformulate multiple messages content succcess';
export const ReformulateMultipleMessagesContentResponseAction = createAction(
  REFORMULATE_MULTIPLE_MESSAGE_RESPONSES_CONTENT,
  props<{ payload: { res?: ReformulationResponseDto } }>()
);
export const RESET_REFORMULATE_MULTIPLE_MESSAGES_CONTENT = '[Message composer] reset reformulate multiple messages content';
export const ResetReformulateMultipleMessagesContentAction = createAction(
  RESET_REFORMULATE_MULTIPLE_MESSAGES_CONTENT
);

const all = union({
  SendMultipleMessagesAction,
  SendMultipleMessagesSuccessAction,
  ReformulateMultipleMessagesContentAction,
  ReformulateMultipleMessagesContentResponseAction,
  SendMultipleMessagesFailAction,
  ScheduleMultipleMessagesAction,
  ScheduleMultipleMessagesSuccessAction,
  ScheduleMultipleMessagesFailAction,
});

export type messageComposerActions = typeof all;