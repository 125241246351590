import { ILocalStorageService } from '@/app/Data/Adapters/ILocalStorageService';
import { Inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';

@Injectable()
export class SignUpPaymentGuard implements CanLoad {
    constructor(
        @Inject(ILocalStorageService) private localStorage: ILocalStorageService,
        private router: Router
    ) { }

    canLoad(
        route: Route,
        state: UrlSegment[]
    ): boolean {
        const paymentFlag = !!this.localStorage.getItem('yib_p_flag');
        if (paymentFlag) {
            this.router.navigate(['/subscribe/payment']);
            return false;
        }
        return true;
    }
}
