import { lightwightSignupDto } from '@/app/Data/DTO/EmailVerificationCodeDto';
import { createReducer, on } from '@ngrx/store';
import {
  authorizeAddPassword,
  CheckIfBusinessNameNotExistAction,
  CheckIfBusinessNameNotExistFailAction,
  CheckIfEmailNotExistAction,
  CheckIfEmailNotExistFailAction,
  DesktopSocialSignInAction,
  LoginWithCredentialsAction,
  LoginWithCredentialsFailAction,
  LoginWithCredentialsSuccessAction,
  LoginWithGoogleAction,
  PasswordResetSendAction,
  PasswordResetSendFailAction,
  RequestEmailVerificationCodeAction,
  RequestEmailVerificationCodeFailAction,
  RequestEmailVerificationCodeSuccessAction,
  SocialoLoginFailAction,
  SocialoLoginAction,
  ValidateEmailVerificationCodeAction,
  ValidateEmailVerificationCodeFailAction,
  ValidateEmailVerificationCodeSuccessAction,
  CreateUserAction,
  CreateUserFailedAction,
  CreateUserSuccessAction,
  GrantUserStartAction,
  GrantUserEndAction,
  SignupWithCredentialsAction,
  SignupWithCredentialsSuccessAction,
  SignupWithCredentialsFailAction,
} from './action';
import { SignOutAction } from '@/app/State/app/action';

export interface LoginState {
  loading: boolean;
  granting: boolean;
  authenticated: boolean;
  loginError: string;
}

export const initLoginState: LoginState = {
  loading: false,
  granting: false,
  authenticated: false,
  loginError: '',
};

export const loginReducer = createReducer(
  initLoginState,
  /* ------------------------------ Granting user ----------------------------- */
  on(GrantUserStartAction, (state: LoginState) => {
    return {
      ...state,
      granting: true,
    };
  }),
  on(GrantUserEndAction, (state: LoginState) => {
    return {
      ...state,
      granting: false,
    };
  }),// ---------------- credentials ------------------------
  on(LoginWithCredentialsAction, (state: LoginState) => {
    return {
      ...state,
      loading: true,
      loginError: '',
    };
  }),
  on(LoginWithCredentialsSuccessAction, (state: LoginState) => {
    return {
      ...state,
      loading: false,
      authenticated: true,
      loginError: '',
    };
  }),
  on(LoginWithCredentialsFailAction, (state: LoginState, { payload }) => {
    return {
      ...state,
      loading: false,
      loginError: payload,
    };
  }),
  // ---------------- social ------------------------
  on(DesktopSocialSignInAction, (state: LoginState) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(LoginWithGoogleAction, (state: LoginState) => {
    return {
      ...state,
      loading: true,
      loginError: '',
    };
  }),
  on(SocialoLoginAction, (state: LoginState) => {
    return {
      ...state,
      loading: false,
      authenticated: true,
      loginError: '',
    };
  }),
  on(SocialoLoginFailAction, (state: LoginState, { payload }) => {
    return {
      ...state,
      loading: false,
      loginError: payload,
    };
  }),
  // ------------------------------------------------
  on(SignOutAction, (state: LoginState) => {
    return {
      ...initLoginState,
    };
  })
);


/* ------------------------------ ------------ ------------------------------ */
/* ------------------------------ ------------ ------------------------------ */
/* ------------------------ email verification satate ----------------------- */
export interface EmailVerificationSate {
  loading: boolean;
  codeError?: boolean;
  authorize?: boolean;
  message?: string;
  businessName?: string;
  channelType?: string;
  channelValue?: string;
  fullName?: string;
}
export const emailVerificationInitState: EmailVerificationSate = {
  loading: false,
  authorize: false,
  channelType: 'email',
};
/* --------------------------- add password state --------------------------- */
export interface AddPasswordState {
  loading: boolean;
  codeError?: boolean;
  message?: string;
  authorize?: boolean;
  password?: string;
  preventLeave: boolean;
}
export const addPasswordInitState: AddPasswordState = {
  loading: false,
  preventLeave: true,
};
/* ------------------------------ signup state ------------------------------ */
export interface SingupState {
  loading: boolean;
  businessNameError?: string;
  emailValid: boolean;
  businessNameValid: boolean;
  emailError?: string;
  emailVerification: EmailVerificationSate;
  data?: lightwightSignupDto;
  addPassword: AddPasswordState;
}
export const initSignupState: SingupState = {
  loading: false,
  emailValid: true,
  businessNameValid: true,
  emailVerification: emailVerificationInitState,
  addPassword: addPasswordInitState,
};

export const singupReducer = createReducer(
  initSignupState,
  on(CheckIfBusinessNameNotExistAction, (state: SingupState) => {
    return {
      ...state,
      businessNameValid: true,
      businessNameError: '',
    };
  }),
  on(CheckIfEmailNotExistAction, (state: SingupState) => {
    return {
      ...state,
      emailValid: true,
      emailError: '',
    };
  }),
  on(
    CheckIfBusinessNameNotExistFailAction,
    (state: SingupState, { payload }) => {
      return {
        ...state,
        businessNameValid: false,
        businessNameError: payload,
      };
    }
  ),
  on(CheckIfEmailNotExistFailAction, (state: SingupState, { payload }) => {
    return {
      ...state,
      emailValid: false,
      emailError: payload,
    };
  }),

  on(RequestEmailVerificationCodeAction, SignupWithCredentialsAction, (state: SingupState, { payload }) => {
    return {
      ...state,
      loading: true,
      data: payload,
    };
  }),
  on(SignupWithCredentialsSuccessAction, SignupWithCredentialsFailAction, (state: SingupState) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(
    RequestEmailVerificationCodeSuccessAction,
    (state: SingupState, { payload }) => {
      return {
        ...state,
        loading: false,
        emailVerification: {
          ...state.emailVerification,
          authorize: true,
          message: payload,
        },
      };
    }
  ),
  on(
    ValidateEmailVerificationCodeSuccessAction,
    (state: SingupState, { }) => {
      return {
        ...state,
        loading: false,
      };
    }
  ),
  on(CreateUserAction, (state: SingupState, { payload }) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(
    CreateUserSuccessAction,
    CreateUserFailedAction,
    RequestEmailVerificationCodeFailAction,
    (state: SingupState, { payload }) => {
      return {
        ...state,
        loading: false,
      };
    }
  ),
  on(ValidateEmailVerificationCodeAction, (state: SingupState, { payload }) => {
    return {
      ...state,
      emailVerification: {
        loading: true,
        codeError: false,
        message: '',
      },
    };
  }),
  on(
    ValidateEmailVerificationCodeSuccessAction,
    (state: SingupState) => {
      return {
        ...state,
        emailVerification: {
          loading: false,
          codeError: false,
          message: '',
        },
        addPassword: {
          ...state.addPassword,
          preventLeave: true,
          authorize: true,
        },
      };
    }
  ),
  on(
    ValidateEmailVerificationCodeFailAction,
    (state: SingupState, { payload }) => {
      return {
        ...state,
        emailVerification: {
          loading: false,
          codeError: true,
          message: payload,
        },
      };
    }
  ),
  on(PasswordResetSendAction, (state: SingupState) => {
    return {
      ...state,
      addPassword: {
        ...state.addPassword,
        loading: true,
      },
    };
  }),
  on(PasswordResetSendFailAction, (state: SingupState) => {
    return {
      ...state,
      addPassword: {
        ...state.addPassword,
        loading: false,
      },
    };
  }),
  on(
    LoginWithCredentialsSuccessAction,
    (state: SingupState) => {
      return {
        ...state,
        addPassword: {
          ...state.addPassword,
          loading: false,
          codeError: false,
          preventLeave: false,
          message: '',
        },
      };
    }
  ),
  on(authorizeAddPassword, (state: SingupState, { payload }) => {
    return {
      ...state,
      addPassword: {
        loading: false,
        preventLeave: payload,
        codeError: false,
        message: '',
      },
    };
  }),
  on(DesktopSocialSignInAction, (state: SingupState) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(SignOutAction, (state: SingupState) => {
    return {
      ...initSignupState,
    };
  })
);
