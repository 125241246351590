
export enum InteractionDirection {
    outbound = 'outbound',
    inbound = 'inbound',
}

export enum InteractionTheme {
    Daily_life = 'daily_life',
    Pop_culture = 'pop_culture',
    Arts_and_culture = 'arts_&_culture',
    Sports_and_gaming = 'sports_&_gaming',
    Science_and_technology = 'science_&_technology',
    Business_and_entrepreneurs = 'business_&_entrepreneurs',
}

export enum InteractionSentiment {
    Admiration = 'admiration',
    Amusement = 'amusement',
    Anger = 'anger',
    Annoyance = 'annoyance',
    Approval = 'approval',
    Caring = 'caring',
    Confusion = 'confusion',
    Curiosity = 'curiosity',
    Desire = 'desire',
    Disappointment = 'disappointment',
    Disapproval = 'disapproval',
    Disgust = 'disgust',
    Embarrassment = 'embarrassment',
    Excitement = 'excitement',
    Fear = 'fear',
    Gratitude = 'gratitude',
    Grief = 'grief',
    Joy = 'joy',
    Love = 'love',
    Nervousness = 'nervousness',
    Optimism = 'optimism',
    Pride = 'pride',
    Realization = 'realization',
    Relief = 'relief',
    Remorse = 'remorse',
    Sadness = 'sadness',
    Surprise = 'surprise',
    Neutral = 'neutral'
}
export enum InteractionSentimentClassification {
    Positive = 'positive',
    Neutral = 'neutral',
    Negative = 'negative',
}

export class InteractionClassification {
    device_type: string;
    direction: InteractionDirection;
    extracted_dates: string[];
    extracted_emails: string[];
    extracted_location: string[];
    extracted_names: string[];
    extracted_phone_numbers: string[];
    from_location: {
        city: string,
        country: string,
        region: string,
    };
    interaction_id: number;
    interaction_type: 'voice' | 'message';
    key_words: string[];
    major_theme: {
        theme: InteractionTheme;
        theme_score: number
    };
    sentiment: {
        caller: {
            name: string;
            sentiment: InteractionSentiment
        };
        receipient: {
            name: string;
            sentiment: InteractionSentiment
        }
    };
    sms_occurrences: {
        already_added: boolean;
        occurrences: number
    };
    tenant_id: number

    get callerSentimentClassification() {
        return {
            sentiment: this.sentiment?.caller.sentiment,
            classification: this.classifySentiment(this.sentiment?.caller.sentiment)
        }
    }

    get receipientSentimentClassification() {
        return {
            sentiment: this.sentiment?.receipient.sentiment,
            classification: this.classifySentiment(this.sentiment?.receipient.sentiment)
        }
    }

    classifySentiment(sentiment: InteractionSentiment): InteractionSentimentClassification {
        return [
            InteractionSentiment.Admiration,
            InteractionSentiment.Amusement,
            InteractionSentiment.Approval,
            InteractionSentiment.Caring,
            InteractionSentiment.Excitement,
            InteractionSentiment.Gratitude,
            InteractionSentiment.Joy,
            InteractionSentiment.Love,
            InteractionSentiment.Optimism,
        ].includes(sentiment) ? InteractionSentimentClassification.Positive : [
            InteractionSentiment.Anger,
            InteractionSentiment.Annoyance,
            InteractionSentiment.Confusion,
            InteractionSentiment.Disappointment,
            InteractionSentiment.Disapproval,
            InteractionSentiment.Disgust,
            InteractionSentiment.Fear,
            InteractionSentiment.Grief,
            InteractionSentiment.Nervousness,
            InteractionSentiment.Remorse,
            InteractionSentiment.Sadness
        ].includes(sentiment) ? InteractionSentimentClassification.Negative : InteractionSentimentClassification.Neutral
    }
}