import { DeleteInteractionDto } from '@/app/Data/DTO/DeleteInteractionDto';
import { BaseInlineNoteBody } from '@/app/Data/DTO/InlineNoteDto';
import { ReformulationDto, ReformulationResponseDto } from '@/app/Data/DTO/reformulationDto';
import { translationDto, translationResponseDto, translateInteractionDto } from '@/app/Data/DTO/translationDto';
import { InternalChannel } from '@/app/core/Models/Channel';
import { InteractionStatus, InteractionThread, RecentInteraction, SocketInteraction } from '@/app/core/Models/Interaction';
import { Message } from '@/app/core/Models/Message';
import { createAction, props, union } from '@ngrx/store';
import { PendingInlineNote, PendingMessage } from '../Inbox/inbox.model';
import { InteractioReplyMode, ThreadInputState } from './thread.model';
import { ThreadMessagesQueryDto } from '@/app/Data/DTO/ThreadMessagesQueryDto';
import { MessagesFetchError } from '@/app/core/Models/FetchErrors';
import { Inbox } from '@/app/core/Models/inbox';


export const UPDATE_ACTIVE_THREAD = '[Thread] Update Active Thread';
export const UpdateActiveThreadAction = createAction(
  UPDATE_ACTIVE_THREAD,
  props<{ payload: { query: ThreadMessagesQueryDto, interactions: InteractionThread[] } }>()
);

export const THREAD_INBOX_SELECT_FILTER = '[Thread] Filter Thread on inbox selection';
export const ThreadInboxSelectionFilterAction = createAction(
  THREAD_INBOX_SELECT_FILTER,
  props<{ payload: { inbox?: Inbox } }>()
);

/* --------------------------- CLASSIFIED MESSAGES -------------------------- */
export const FETCH_CLASSIFIED_MESSAGES = '[Thread] Fetch Classified Messages';
export const FetchClassifiedMessages = createAction(
  FETCH_CLASSIFIED_MESSAGES,
  props<{ payload: ThreadMessagesQueryDto }>()
);


export const CLASSIFIED_MESSAGES_LOADED = '[Thread] Classified Messages Loaded';
export const ClassifiedMessagesLoadedAction = createAction(
  CLASSIFIED_MESSAGES_LOADED,
  props<{ payload: { query: ThreadMessagesQueryDto, interactions: InteractionThread[] } }>()
);

export const CLASSIFIED_MESSAGES_FETCH_FAIL = '[Thread] Classified Messages Fetch Fail';
export const ClassifiedMessagesFetchFail = createAction(
  CLASSIFIED_MESSAGES_FETCH_FAIL,
  props<{ payload: MessagesFetchError }>()
);

/* -------------------------- UNCLASSIFIED MESSAGES ------------------------- */
export const FETCH_MESSAGES = '[Thread] Fetch Messages';
export const FetchMessagesAction = createAction(
  FETCH_MESSAGES,
  props<{ payload: { threadId: number; unClassified: boolean, page: number } }>()
);

export const FETCH_UN_CLASSIFIED_MESSAGES = '[Thread] Fetch UnClassified Messages';
export const FetchUnClassifiedMessages = createAction(
  FETCH_UN_CLASSIFIED_MESSAGES,
  props<{ payload: ThreadMessagesQueryDto }>()
);

export const UN_CLASSIFIED_MESSAGES_LOADED = '[Thread] UnClassified Messages Loaded';
export const UnClassifiedMessagesLoadedAction = createAction(
  UN_CLASSIFIED_MESSAGES_LOADED,
  props<{ payload: { query: ThreadMessagesQueryDto, interactions: InteractionThread[] } }>()
);

export const UN_CLASSIFIED_MESSAGES_FETCH_FAIL = '[Thread] Unclassified messages fetch failed';
export const UnClassifiedMessagesFetchFail = createAction(
  UN_CLASSIFIED_MESSAGES_FETCH_FAIL,
  props<{ payload: MessagesFetchError }>()
);

/* ------------------------------- reply mode ------------------------------- */
export const LOAD_THREAD_NEXT_PAGE = '[Thread] load inbox next page';
export const LoadInboxNextPageAction = createAction(
  LOAD_THREAD_NEXT_PAGE,
  props<{ payload: ThreadMessagesQueryDto }>()
);

/* ------------------------------- reply mode ------------------------------- */
export const SWITCH_REPLY_MODE = '[Thread INPUT] switch reply mode';
export const SwitchReplyModeAction = createAction(
  SWITCH_REPLY_MODE,
  props<{ payload: InteractioReplyMode }>()
);

/* ------------------------- thread message content ------------------------- */
export const SET_THREAD_MESSAGE_CONTENT = '[Thread INPUT] set thread content';
export const SetThreadMessageContentAction = createAction(
  SET_THREAD_MESSAGE_CONTENT,
  props<{ payload: ThreadInputState }>()
);

/* ------------------------- thread message suggest ------------------------- */
export const SET_THREAD_MESSAGE_SUGGESTION = '[Thread INPUT] set thread Suggestion';
export const SetThreadMessageSuggestionAction = createAction(
  SET_THREAD_MESSAGE_SUGGESTION,
  props<{ payload: { content: string, threadId: number, unclassified: boolean, isEmailInbox?: boolean } }>()
);

/* --------------------------- message translation -------------------------- */
export const TRANSLATE_THREAD_INPUT_MESSAGE = '[Thread INPUT] Translate Thread Input Message';
export const TranslateThreadInputMessageAction = createAction(
  TRANSLATE_THREAD_INPUT_MESSAGE,
  props<{ payload: { request: translationDto, threadId: number, isEmailInbox: boolean } }>()
);

export const TRANSLATE_THREAD_INPUT_MESSAGE_RESPONSE = '[Thread INPUT] Translate Thread Input Message Response';
export const TranslateThreadInputMessageResponseAction = createAction(
  TRANSLATE_THREAD_INPUT_MESSAGE_RESPONSE,
  props<{ payload: { res?: translationResponseDto, threadId: number, isEmailInbox?: boolean } }>()
);

export const TRANSLATE_SENT_MESSAGE_ACTION = '[Interaction] Translate Sent Message'
export const TranslateSentMessageAction = createAction(
  TRANSLATE_SENT_MESSAGE_ACTION,
  props<{ payload: { request: translateInteractionDto } }>()
)

export const TRANSLATE_SENT_MESSAGE_ACTION_FAIL = '[Interaction] Translate Sent Message Fail'
export const TranslateSentMessageActionFail = createAction(
  TRANSLATE_SENT_MESSAGE_ACTION_FAIL
)


/* -------------------------- message reformulation ------------------------- */
export const REFORMULATE_THREAD_INPUT_MESSAGE = '[Thread INPUT] Reformulate Thread Input Message';
export const ReformulateThreadInputMessageAction = createAction(
  REFORMULATE_THREAD_INPUT_MESSAGE,
  props<{ payload: { request: ReformulationDto, threadId: number, isEmailInbox: boolean } }>()
);

export const REFORMULATE_THREAD_INPUT_MESSAGE_RESPONSE = '[Thread INPUT] Reformulate Thread Input Message Response';
export const ReformulateThreadInputMessageResponseAction = createAction(
  REFORMULATE_THREAD_INPUT_MESSAGE_RESPONSE,
  props<{ payload: { res?: ReformulationResponseDto, threadId: number, isEmailInbox?: boolean } }>()
);

/* ----------------------- message send resend delete ----------------------- */
export const SEND_MESSAGE = '[Thread] Send Message';
export const SendMessageAction = createAction(
  SEND_MESSAGE,
  props<{ payload: Message }>()
);

export const SEND_ATTACHMENT_MESSAGE = '[Thread] Send Attachment Message';
export const SentAttachmentMessageAction = createAction(
  SEND_ATTACHMENT_MESSAGE,
  props<{ payload: Message }>()
);

export const SEND_MESSAGE_SUCCESS = '[Thread] Message Successfully sent';
export const SendMessageSuccessAction = createAction(
  SEND_MESSAGE_SUCCESS,
  props<{ payload: { interaction: SocketInteraction } }>()
);

export const SEND_MESSAGE_FAIL = '[Thread] Message sending failed';
export const SendMessageFailAction = createAction(
  SEND_MESSAGE_FAIL,
  props<{ payload: Message }>()
);

export const RESEND_FAILED_MESSAGE = '[Thread] Resend failed message';
export const ResendFailedMessageAction = createAction(
  RESEND_FAILED_MESSAGE,
  props<{ payload: { msg_id: string } }>()
);

export const ADD_PENDING_MESSAGE = '[Thread] Add Pending Message';
export const AddPendingMessageAction = createAction(
  ADD_PENDING_MESSAGE,
  props<{ payload: PendingMessage }>()
);

export const REMOVE_INTERACTION_FROM_PENDING_MESSAGES = '[Thread] Remove Sent Interaction From Pending Messages';
export const RemoveInteractionFromPendingMessagesAction = createAction(
  REMOVE_INTERACTION_FROM_PENDING_MESSAGES,
  props<{ payload: { msg_id: number } }>()
);

export const DELETE_THREAD_INTERACTION = '[Thread] Delete thread interaction';
export const DeleteThreadInteractionAction = createAction(
  DELETE_THREAD_INTERACTION,
  props<{ payload: DeleteInteractionDto }>()
);

export const DELETE_INTERACTION_CALL_RECORDING = '[Thread] Delete interaction call recording';
export const DeleteInteractionCallRecordingAction = createAction(
  DELETE_INTERACTION_CALL_RECORDING,
  props<{ payload: { interaction_id: string | number } }>()
);

export const DELETE_INTERACTION_CALL_RECORDING_SUCCESS = '[Thread] Delete interaction call recording success';
export const DeleteInteractionCallRecordingSuccessAction = createAction(
  DELETE_INTERACTION_CALL_RECORDING_SUCCESS,
  props<{ payload: { interaction_id: string | number } }>()
);

export const RESEND_UNDELIVERED_INTERACTION = '[Thread] resend undelivered interaction'; // walid check this
export const ResendUndeliveredInteractionAction = createAction(
  RESEND_UNDELIVERED_INTERACTION,
  props<{ payload: { interactionId: number } }>()
);

export const RESEND_UNDELIVERED_INTERACTION_FAIL = '[Thread] resend undelivered interaction failed';
export const ResendUndeliveredInteractionFailedAction = createAction(
  RESEND_UNDELIVERED_INTERACTION_FAIL
);

export const UPDATE_LOCAL_INTERACTION = '[Thread] update local interaction';
export const UpdateLocalInteractionAction = createAction(
  UPDATE_LOCAL_INTERACTION,
  props<{ payload: { interaction: Partial<InteractionThread> & Pick<InteractionThread, 'interaction_id'> } }>()
);
/* --------------------- inline note send resend delete --------------------- */
export const SEND_INLINE_NOTE = '[Thread] Send Inline note';
export const SendInlineNoteAction = createAction(
  SEND_INLINE_NOTE,
  props<{ payload: BaseInlineNoteBody }>()
);

export const SEND_INLINE_NOTE_SUCCESS =
  '[Thread] Inline note Successfully sent';
export const SendInlineNoteSuccessAction = createAction(
  SEND_INLINE_NOTE_SUCCESS,
  props<{ payload: { interaction: SocketInteraction } }>()
);

export const SEND_INLINE_NOTE_FAIL = '[Thread] Inline note sending failed';
export const SendInlineNoteFailAction = createAction(
  SEND_INLINE_NOTE_FAIL,
  props<{ payload: BaseInlineNoteBody }>()
);

export const RESEND_FAILED_INLINE_NOTE = '[Thread] Resend failed inline note';
export const ResendFailedInlineNoteAction = createAction(
  RESEND_FAILED_INLINE_NOTE,
  props<{ payload: { msg_id: number } }>()
);

export const ADD_PENDING_INLINE_NOTE = '[Thread] Add Pending Inline note';
export const AddPendingInlineNoteAction = createAction(
  ADD_PENDING_INLINE_NOTE,
  props<{ payload: PendingInlineNote }>()
);

export const REMOVE_INTERACTION_FROM_PENDING_INLINE_NOTES = '[Thread] Remove Sent Interaction From Pending inline notes';
export const RemoveInteractionFromPendingInlineNotesAction = createAction(
  REMOVE_INTERACTION_FROM_PENDING_INLINE_NOTES,
  props<{ payload: { msg_id: number } }>()
);

export const DELETE_INLINE_NOTE = '[Thread] Delete Inline note';
export const DeleteInlineNoteAction = createAction(
  DELETE_INLINE_NOTE,
  props<{ payload: DeleteInteractionDto }>()
);

/* ------------------------- support channel update ------------------------- */
export const UPDATE_SUPPORT_CHANNEL = '[Thread] Update support channel';
export const UpdateSupportChannelAction = createAction(
  UPDATE_SUPPORT_CHANNEL,
  props<{ payload: InternalChannel | null }>()
);

export const LOAD_THREAD_CACHED_INTERACTIONS = '[Thread] Load thread cached Interactions';
export const LoadThreadCachedInteractions = createAction(
  LOAD_THREAD_CACHED_INTERACTIONS,
  props<{
    payload: {
      id: number, unClassified: boolean, page: number, inbox?: Inbox
    }
  }>()
)





export const TOGGLE_SYNTH_AUTO_REPLY = '[Thread] Toggle Synth Auto Reply';
export const ToggleSynthAutoReplyAction = createAction(
  TOGGLE_SYNTH_AUTO_REPLY,
  props<{ payload: boolean }>()
);

const all = union({
  FetchClassifiedMessages,
  ClassifiedMessagesLoaded: ClassifiedMessagesLoadedAction,
  ClassifiedMessagesFetchFail,
  FetchMessagesAction,
  FetchUnClassifiedMessages,
  UnClassifiedMessagesLoadedAction,
  UnClassifiedMessagesFetchFail,
  SetThreadMessageContentAction,
  TranslateThreadInputMessageAction,
  TranslateThreadInputMessageResponseAction,
  ReformulateThreadInputMessageAction,
  ReformulateThreadInputMessageResponseAction,
  SendMessageAction,
  SentAttachmentMessageAction,
  SendMessageSuccessAction,
  LoadThreadCachedInteractions,
  SendMessageFailAction,
  ResendFailedMessageAction,
  ResendUndeliveredInteractionAction,
  UpdateLocalInteractionAction,
  AddPendingMessageAction,
  DeleteThreadInteractionAction,
  RemoveInteractionFromPendingMessagesAction,
  RemoveInteractionFromPendingInlineNotesAction,
  DeleteInteractionCallRecordingAction,
  DeleteInteractionCallRecordingSuccessAction,
  SendInlineNoteAction,
  SendInlineNoteSuccessAction,
  SendInlineNoteFailAction,
  ResendFailedInlineNoteAction,
  AddPendingInlineNoteAction,
  DeleteInlineNoteAction,
  UpdateSupportChannelAction,
  TranslateSentMessageAction
});

export type ThreadActions = typeof all;
