import { NoteBody } from '@/app/Data/DTO/NoteBodyDto';
import { AppState } from '@/app/State/AppState';
import { DeleteContactNoteAction, DeleteUnclassifiedChannelNoteAction, getNotesByContactIdAction, getNotesByUnclassifiedChannelIdAction } from '@/app/State/Notes/action';
import { ConfirmationDialogComponent } from '@/app/Ui/components/confirm-dialog/confirmation-dialog.component';
import { Localizations } from '@/app/Utilities/localization';
import { INotesRepository } from '@/app/core/IRepositories/INotesRepository';
import { Note } from '@/app/core/Models/Note';
import { CreateNoteUseCase } from '@/app/core/usecases/Notes/CreateNoteUseCase';
import { CreateUnclassifiedChannelNoteUseCase } from '@/app/core/usecases/Notes/CreateUnclassifiedChannelNoteUseCase';
import { UpdateNoteUseCase } from '@/app/core/usecases/Notes/UpdateNoteUseCase';
import { UpdateUnclassifiedChannelNoteUseCase } from '@/app/core/usecases/Notes/UpdateUnclassifiedChannelNoteUseCase';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Observer, from, of, shareReplay, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotesViewModel {
  constructor(
    private store: Store<AppState>,
    private updateNoteUseCase: UpdateNoteUseCase,
    private createNoteUseCase: CreateNoteUseCase,
    private updateUnclassifiedChannelNote: UpdateUnclassifiedChannelNoteUseCase,
    private createUnclassifiedChannelNote: CreateUnclassifiedChannelNoteUseCase,
    private notesRepository: INotesRepository,
    private matDialog: MatDialog
  ) { }

  threadId$ = new BehaviorSubject<any>(null);
  unclassified: boolean

  contactNotes$ = this.threadId$.asObservable().pipe(
    switchMap((threadId: any) =>
      threadId ? this.notesRepository.getLocalNotesById(threadId, this.unclassified) : of([])
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /* -------------------------------- FETCHING -------------------------------- */
  fetchNotesByContactId(contactId: number): void {
    this.unclassified = false;
    this.threadId$.next(contactId);
    this.store.dispatch(getNotesByContactIdAction({ payload: { contact_id: contactId } }));
  }


  fetchNotesByUnclassifiedChannelId(channelId: number): void {
    this.unclassified = true;
    this.threadId$.next(channelId);
    this.store.dispatch(getNotesByUnclassifiedChannelIdAction({ payload: { channel_id: channelId } }));
  }

  /* ------------------------------ ADD & UPDATE ------------------------------ */
  addNote(
    content: string,
    contactId: number,
    attachments: { file?: File, type: string, url: string }[],
    uploadProgress$: Observer<any>
  ): Observable<any> {
    const note: NoteBody = new NoteBody(
      content, undefined, contactId, undefined, attachments
    );
    note.uploadProgress$ = uploadProgress$;
    return from(this.createNoteUseCase.execute(note));
  }

  updateNote(
    content: string,
    contactId: number,
    noteId: number,
    attachments: { file?: File, type: string, url: string }[],
    uploadProgress$: Observer<any>
  ): Observable<any> {
    const note: NoteBody = new NoteBody(
      content, noteId, contactId, undefined, attachments
    );
    note.uploadProgress$ = uploadProgress$;
    return from(this.updateNoteUseCase.execute(note));
  }

  addNoteUnclassifiedChannels(
    content: string,
    unclassifiedChannelId: number,
    attachments: { file?: File, type: string, url: string }[],
    uploadProgress$: Observer<any>
  ) {
    const note: NoteBody = new NoteBody(
      content, undefined, undefined, unclassifiedChannelId, attachments
    );
    note.uploadProgress$ = uploadProgress$;
    return from(this.createUnclassifiedChannelNote.execute(note));
  }

  updateNoteUnclassifiedChannels(
    content: string,
    unclassifiedChannelId: number,
    noteId: number,
    attachments: { file?: File, type: string, url: string }[],
    uploadProgress$: Observer<any>
  ) {
    const note: NoteBody = new NoteBody(
      content, noteId, undefined, unclassifiedChannelId, attachments
    );
    note.uploadProgress$ = uploadProgress$;
    return from(this.updateUnclassifiedChannelNote.execute(note));
  }

  /* -------------------------------- DELITING -------------------------------- */
  openDeleteNoteDialog(note: Note): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message: Localizations.confirm.delete_note,
        confirm: Localizations.labels.delete,
        warning: true
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        if (note.unclassified_channel_id) {
          this.store.dispatch(
            DeleteUnclassifiedChannelNoteAction({
              payload: {
                noteId: note.note_id,
                channelId: note.unclassified_channel_id,
              },
            })
          );
        } else if (note.contact_id) {
          this.store.dispatch(
            DeleteContactNoteAction({
              payload: { noteId: note.note_id, contactId: note.contact_id },
            })
          );
        }
      }
    });
  }
}
