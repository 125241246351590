import { Injectable } from '@angular/core';

import { HttpService } from '../Networking/HttpService';
import { ITenantRepository } from '@/app/core/IRepositories/ITenantRepository';

import { HttpRequestMethod } from '@/app/Data/services/Networking/HttpRequestMethod';
import { SuccessResponse } from '../../DTO/successResponse';

import { pluck, tap, map, firstValueFrom } from 'rxjs';
import { environment } from '@/environments/environment.prod';

export interface IActivity {
    action: string
    created_dt: string
    entity_id: string
    entity_name: string
    tenant_id: string
    user_id: string;
    entity_details: {
        old: any,
        user: string
    },
    payload: any;
}

@Injectable({ providedIn: 'root' })
export class ActivityService {
    constructor(
        private httpService: HttpService,
        private tenantRepository: ITenantRepository
    ) { }

    async getActivity(entity_name: 'company', entity_id: string): Promise<IActivity[]> {
        const response = await firstValueFrom(this.tenantRepository.getActivity(entity_name, entity_id));
        return response;
    }








}