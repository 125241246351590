import { DialerActiveCallComponent } from '@/app/Ui/DIaler/dialer-active-call/dialer-active-call.component';
import { DialerComponent } from '@/app/Ui/DIaler/dialer/dialer.component';
import { DialogService, MyOverlayRef } from '@/app/Utilities/Dialog/dialog.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export type DialerRequest = { phoneNumber: string,toNumber?: string,avatar?: string, displayName?: string }

@Injectable({
  providedIn: 'root',
})
export class DialerService {
  dialerRef: MyOverlayRef
  activeDialerRef: MyOverlayRef

  constructor(
    private dialogService: DialogService
  ) { }

  onRequest$ = new BehaviorSubject<DialerRequest | null>(null);

  openDialer(data: {request?: DialerRequest, origin?: HTMLElement}) {
    data.request && this.onRequest$.next(data.request)
    if (!this.dialerRef?.overlay.hasAttached()) {
      this.dialerRef = this.dialogService.open(DialerComponent, {
        top: !data.origin ? '2.5%' : undefined,
        origin: data.origin,
        hasBackdrop: true,
        panelClass: 'custom-dialog-panel',
        backdropClass: 'custom-dialog-backdrop',
        data: {}
      });
    }
  }

  openActiveCallDialer(): void {
    if (!this.activeDialerRef?.overlay.hasAttached()) {
      this.activeDialerRef = this.dialogService.open(DialerActiveCallComponent, {
        right: '25px',
        bottom: '30px',
        hasBackdrop: false,
        disableClose: true,
        backdropClass: 'overlay-backdrop',
        panelClass: 'active-call-dialer-overlay',
        data: {}
      })
    }
    this.closeDialer()
  }

  closeDialer(): void {
    this.dialerRef?.close({ type: 'Close' });
  }

  closeActiveDialer(): void {
    this.activeDialerRef?.close({ type: 'Close' });
  }
}
