import { ToggleChannelSynthRequestDto, ToggleChannelSynthRequest } from './../../Data/DTO/ChannelDto';
import { Observable } from 'rxjs';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { ChannelBlockDto } from '@/app/Data/DTO/ChannelDto';
import { PhoneChannelListRequestDto } from '@/app/Data/DTO/PhoneChannelListRequestDto';
import { PhoneChannel } from '../Models/phoneChannel';
import { AddYobiPhoneDto } from '@/app/Data/DTO/AddYobiPhoneDto';
import { EditPhoneChannelDataRequestDto } from '@/app/Data/DTO/EditPhoneChannelDataRequestDto';
import { CustomVoiceAttributeName, PhoneInternalChannel } from '../Models/Channel';
import { TwilioCustomMessageDto } from '@/app/Data/DTO/TwilioCustomMessageDto';
import { BusinessHoursDto, BusinessHoursResponse, BusinessHoursStausResponse } from '@/app/Data/DTO/BusinessHoursDto';
import { ChatWidgetData, ChatwidgetExternalInfo } from '../Models/chatWidgetChannel';

export abstract class IChannelRepository {
  /* ------------------------------ phone number ------------------------------ */
  abstract isPhoneNumberBlocked(contactId: ChannelBlockDto): Observable<SuccessResponse>;

  abstract getPhoneChannelList(request: PhoneChannelListRequestDto): Observable<PhoneChannel[]>;

  abstract addYobiPhone(request: AddYobiPhoneDto): Observable<SuccessResponse>;

  abstract updatePhoneChannelData(request: EditPhoneChannelDataRequestDto, channel_id: number): Observable<PhoneInternalChannel>

  abstract setPhoneChannelCustomVoice(form: FormData): Observable<TwilioCustomMessageDto>

  abstract deletePhoneChannelCustomVoice(request: {attribute_name: CustomVoiceAttributeName, channel_id: number}): Observable<string>

  abstract setBusinessHoursStatus(channel_id: number,status: 'ON' | 'OFF'): Observable<BusinessHoursStausResponse>

  abstract getBusinessHoursStatus(channel_id: number): Observable<'ON' | 'OFF'>

  abstract setBusinessHours(request: BusinessHoursDto, channel_id: number): Observable<SuccessResponse>

  abstract getBusinessHours(channel_id: number): Observable<BusinessHoursResponse>

  /* -------------------------------- Facebook -------------------------------- */

  abstract getFacebookPages(data: any): Observable<any>;

  abstract initFacebookPage(pageDetails: any): Observable<any>;

  abstract disconnectFacebookChannel(): Observable<any>;

  /* -------------------------------- Instagram ------------------------------- */

  abstract getInstagramPages(data: any
  ): Observable<any>;

  abstract initInstagramPage(pageDetails: any): Observable<any>;

  abstract disconnectInstagramChannel(): Observable<any>;

  /* --------------------------------- Twitter -------------------------------- */

  abstract connectTwitterChannel(): Observable<string>;

  abstract disconnectTwitterChannel(): Observable<string>;

  /* -------------------------------- Linkedin -------------------------------- */

  abstract connectLinkedinChannel(): Observable<string>;

  abstract disconnectLinkedinChannel(page_id: string): Observable<string>;

  /* ---------------------------------- Gmail --------------------------------- */
  abstract connectGmailChannel(): Observable<string>;


  abstract disconnectGmailChannel(): Observable<string>;

  /* --------------------------------- Outlook -------------------------------- */
  abstract connectOutlookChannel(): Observable<string>;

  abstract disconnectOutlookChannel(): Observable<string>;

  abstract toggleChannelSynth(req: ToggleChannelSynthRequestDto): Observable<ToggleChannelSynthRequestDto>;

  /* ------------------------------- chatwidget ------------------------------- */

  abstract getChatWidgetData(): Observable<ChatWidgetData>;
  abstract createChatWidget(data: ChatwidgetExternalInfo): Observable<ChatWidgetData>;
  abstract updateChatWidget(data: ChatwidgetExternalInfo): Observable<ChatWidgetData>;
}
