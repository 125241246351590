<div class="flex flex-col gap-4 h-full w-full px-8 pt-12 bg-slate-800">
    <div class="flex flex-row justify-start items-center gap-6 cursor-pointer" [routerLink]="['/auth']">
        <img class="w-12 h-12" src="assets/icons/logo.white.svg" />
        <div class="text-gray-100 font-bold text-3xl">
            {{ localization.auth.yobi_app | translate }}
        </div>
    </div>

    <div class="flex-grow mt-16 ml-2" style="max-width: 36%;">
        <ng-content></ng-content>
    </div>

    <footer class="items-top flex justify-start items-center ml-12 mb-8 p-1">

    </footer>
</div>
