import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter, OnInit, Output
} from '@angular/core';

@Component({
  selector: 'black-friday-annocement',
  templateUrl: './black-friday-annocement.component.html',
  styleUrls: ['./black-friday-annocement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlackFridayAnnocementComponent implements OnInit {
  @Output() openBilling = new EventEmitter();
  @Output() closeBanner = new EventEmitter();
  key = 'HIDE-BLACKFRIDAY-ANNONCEMENT'
  redeam = new Date('Thu, 01 Dec 2022 07:59:00 GMT').getTime()
  interval: NodeJS.Timeout
  redeamBy = ''
  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  
  ngOnInit(): void {
    const hide = localStorage.getItem(this.key);
    if (!hide && this.redeam - new Date().getTime() > 0) {
      this.interval = setInterval(() => {
        const distance = this.redeam - new Date().getTime();
        if (distance <= 0) {
          this.close();
          return;
        }
        const hours =  Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0');
        const minuts = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
        const seconds = Math.floor((distance % (1000 * 60)) / 1000).toString().padStart(2, '0');
        this.redeamBy = `${ hours }h ${ minuts }m ${ seconds }s`
        this.cdr.detectChanges()
      }, 1000)
      return
    }
    this.close()
  }

  close() {
    localStorage.setItem(this.key, 'true')
    this.interval && clearInterval(this.interval)
    this.closeBanner.emit();
  }
}
