import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

import { LocalizationViewModel } from '../ViewModels/localizationViewModel';
import { TagsViewModel } from '../ViewModels/tagsViewModel';

import { Contact, UnclassifiedContact } from '@/app/core/Models/contact';
import { Tag } from '@/app/core/Models/tag';

import { firstValueFrom, map, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsComponent implements OnInit {
  tagInput = false
  contactId: number;
  TagInputValue = new FormControl();
  tenantTags$ = this.tagsViewModel.tenantTags$.pipe(
    switchMap((tags) => this.TagInputValue.valueChanges.pipe(
      startWith(''),
      map((value: string) => tags.filter(tag => tag.tag_name?.toLowerCase().includes(value?.toLowerCase())))
    )),
  );

  @Input() isContact: boolean = true;
  @Input() contact: Contact | UnclassifiedContact | any;
  @Input() tags: Tag[] | null = [];

  @Output() onGetTag = new EventEmitter<{ data: Tag, action: 'add' | 'delete' }>();
  localization = this.localiztionViewModel.localization;

  constructor(
    private localiztionViewModel: LocalizationViewModel,
    private tagsViewModel: TagsViewModel,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void { }

  async addTag(tag_name = '') {
    this.tagsViewModel.addTag({
      contactId: this.contact ? (this.contact as Contact).contact_id ? this.contact.contact_id : null : null,
      channelId: this.contact ? (this.contact as UnclassifiedContact).unclassifiedChannelId ? (this.contact as UnclassifiedContact).unclassifiedChannelId : null : null,
      tag_name
    });
  }

  deleteTag(tag: Tag): void {
    this.isContact ? this.tagsViewModel.deleteTag(tag) : this.onGetTag.emit({ data: tag, action: 'delete' });
  }

  toggleTagInput(status: boolean) {
    setTimeout(() => {
      this.tagInput = status
      this.cdr.detectChanges();
    }, status ? 0 : 100);
  }

  async initTag(tag_name: string) {
    const tag = (await firstValueFrom(this.tenantTags$)).find(t => t.tag_name == tag_name)!;
    if (!tag) {
      this.addTag(tag_name);
      this.TagInputValue.setValue('');
      return;
    }
    this.isContact ? this.attachTagContact(tag) : this.attactTagCompany(tag);
  }

  attachTagContact(tag: Tag): void {
    let data = [{
      tag_name: tag.tag_name,
      description: tag.description,
      color: tag.color ? tag.color : '#E6DEEC'
    }];
    this.TagInputValue.setValue('');
    if (this.contact.unclassifiedChannelId) {
      this.tagsViewModel.createUnclassifiedChannelTag(data, this.contact.unclassifiedChannelId);
    } else {
      this.tagsViewModel.createContactTag(data, this.contact.contact_id);
    }
  }

  attactTagCompany(tag: Tag): void {
    this.onGetTag.emit({ data: tag, action: 'add' });
    this.TagInputValue.setValue('');
  }

}
