<div
  style="margin: 0 auto"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <span
    style="margin: 0 auto; color: #00c48c; font-size: 40px"
    class="material-icons"
  >
    check_circle
  </span>
  <br />
  <p
    style="font-weight: 500; font-size: 19px; text-align: center"
    [innerHTML]="data.message | translate : data.translateParams"
  >
  </p>
</div>