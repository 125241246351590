export class TeamMember {
  active: boolean;
  avatar: string;
  family_name: string;
  given_name: string;
  user_id: any;
  username: string;
  metadata?: any
  is_bot?: boolean
  bot_type?: string
}


export class RoomDetails {
  active: boolean;
  avatar: string;
  room_name: string;
  room_id: string;
  private: boolean
}