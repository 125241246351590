import { ITagsRepository } from '@/app/core/IRepositories/ITagsRepository';
import { Contact } from '@/app/core/Models/contact';
import { Tag } from '@/app/core/Models/tag';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { liveQuery } from 'dexie';
import { from, map, Observable, pluck } from 'rxjs';
import { createTagDto, tenantTagsDetailsDto } from '../DTO/TagsDto';
import { DatabaseService } from '../services/Database/database.service';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';

@Injectable({
    providedIn: 'root',
})

export class TagsRepository implements ITagsRepository{
  constructor(
      private httpService: HttpService,
      private databaseService: DatabaseService) {}

  getLocalTagsById(data: {id: number, isContact: boolean}): Observable<Tag[]> {
    return from(
      liveQuery(() => {
        this.databaseService.tags.mapToClass(Tag);
        return this.databaseService.tags
          .where(data.isContact ? 'contact_id' : 'unclassified_channel_id')
          .equals(data.id)
          .toArray();
      })
    ).pipe(
      map((tags: any) => tags)
    );
  }

  createTag(data: createTagDto): Observable<Tag> {
    const requestURL = `${environment.apiURL}contacts/tags`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      map((item) => {
        let res = item as SuccessApiResponse<Tag>;
        return res.results;
      })
    );
  }

  createContactTag(data: createTagDto, contactId: number): Observable<Tag[]> {
    let tagData = {
      tags: [data]
    };

    const requestURL = `${environment.apiURL}contacts/${contactId}/tags`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      tagData,
      false
    );
    return this.httpService.execute(options).pipe(
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  createUnclassifiedTag(data: createTagDto, channelId: number): Observable<Tag[]> {
    let tagData = {
      tags: [data]
    };

    const requestURL = `${environment.apiURL}unclassified/${channelId}/tags`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      tagData,
      false
    );
    return this.httpService.execute(options).pipe(
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  getContactTags(contactId: number): Observable<Tag[]>{
    const requestURL = `${environment.apiURL}contacts/${contactId}/tags`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  getUnclassifiedChannelTags(channelId: number): Observable<Tag[]>{
    const requestURL = `${environment.apiURL}unclassified/${channelId}/tags`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  saveAndDeleteTags(tags: Tag[], forId: number, isContact: boolean): void {
    this.databaseService
      .transaction('rw!', this.databaseService.tags, async () => {
        const localTags = await this.databaseService.tags
          .where(isContact ? 'contact_id' : 'unclassified_channel_id')
          .equals(forId)
          .toArray();
        const newTagsKeys = tags.map((tag) => tag.tag_id as unknown as string);
        const deletedTags = localTags
          .filter((item) => !newTagsKeys.includes(item.tag_id as unknown as string))
          .map((item) => item.tag_id as unknown as string);
        await this.databaseService.tags.bulkDelete(deletedTags);
        await this.databaseService.tags.bulkPut(tags);
      })
      .catch((error) => {
        console.log(error, 'Transaction Failed');
      });
  }

  saveTags(tags: Tag[]): void {
    this.databaseService.tags.bulkPut(tags);
  }

  deleteSavedTag(tag: Tag, forId: number): void {
    this.databaseService
      .transaction('rw!', this.databaseService.tags, async () => {
        await this.databaseService.tags
          .where(tag.contact_id ? 'contact_id' : 'unclassified_channel_id')
          .equals(forId)
          .filter(value => value.tag_id == tag.tag_id)
          .delete()
      });
  }

  getAllTenantTags(): Observable<tenantTagsDetailsDto[]> {
    const requestURL = `${environment.apiURL}tenants/tags`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  deleteContactTag(channelId: number, tag: Tag): Observable<string> {
    const requestURL = `${environment.apiURL}contacts/${channelId}/tag/${tag.tag_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  deleteChannelTag(contactId: number, tag: Tag): Observable<string> {
    console.log({tag})
    const requestURL = `${environment.apiURL}unclassified/${tag.unclassified_channel_id}/tag/${tag.tag_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  searchContactsByTags(tag: string): Observable<Contact[]> {
    const requestURL = `${environment.apiURL}contacts/tag/search`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      {"tag_name": tag},
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

}
