import { CreatTaskDto } from '@/app/Data/DTO/TaskDto';
import { Localizations } from '@/app/Utilities/localization';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';
import { MentionMember } from '@/app/core/Models/Mention';
import { TaskDetail } from '@/app/core/Models/Task';
import { TeamMember } from '@/app/core/Models/TeamMember';
import { Attachment, FileModel, FileType } from '@/app/core/Models/file';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first, firstValueFrom } from 'rxjs';
import { SubSink } from 'subsink';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';
import { TaskViewModel } from '../../ViewModels/taskViewModel';
import { UsersViewModel } from '../../ViewModels/usersViewModel';
import momento from '@/app/Utilities/momento';

enum ReminderType {
  none = 'none',
  in_15_minutes = 'in_15_minutes',
  in_30_minutes = 'in_30_minutes',
  in_one_day = 'in_one_day',
  in_two_days = 'in_two_days',
  in_one_week = 'in_one_week',
  custom = 'custom',
}

@Component({
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskFormComponent implements OnInit {
  taskForm: FormGroup;
  loadingTask = false;
  savingTask = false;
  editMode = false;
  linkToContact = !!this.data?.channel_id;
  subSink = new SubSink();
  taskDetail$ = this.taskViewModel.task$;
  mentions$ = this.usersViewModel.mentions$
  localization = this.localiztionViewModel.localization
  limitSize = 26214400;
  mentionedMember: MentionMember | undefined;
  attachments: Attachment[] = []
  reminder = new FormControl(ReminderType.none)
  currentDate = new Date()
  minimumTime: string
  reminderDate!: Date
  reminderTime!: string

  constructor(
    private localiztionViewModel: LocalizationViewModel,
    private snackBarService: SnackbarService,
    private taskViewModel: TaskViewModel,
    private usersViewModel: UsersViewModel,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TaskFormComponent>,
    private elementRef: ElementRef,
    @Inject(MAT_DIALOG_DATA) public data: {
      taskId?: number; channel_id?: number, inbox_id?: number, yobi_crm_company_id?: any, contact_id?: any
    }
  ) {
    this.initDate()
    this.initForm();
  }

  get attachmentsForm(): FormArray {
    return this.taskForm.get('attachments') as FormArray;
  }

  get deletedAttachments(): FormArray {
    return this.taskForm.get('deleted') as FormArray;
  }

  ngOnInit(): void {
    this.subSink.sink = this.reminder.valueChanges.subscribe(value => {
      value === ReminderType.none
        ? this.taskForm.get('assigned_to')!.clearValidators()
        : this.taskForm.get('assigned_to')!.addValidators(Validators.required)
      this.taskForm.get('assigned_to')?.updateValueAndValidity()
    });
    this.subSink.sink = this.attachmentsForm.valueChanges.subscribe((files: { file_url: string, filename: string, type: FileType }[]) => {
      this.attachments = files.map(({ file_url, filename, type }) => ({ url: file_url, name: filename, type }))
      this.cdr.detectChanges()
    });
    this.initTask();
  }

  initDate() {
    const offset = 30 - (momento(this.currentDate).date.getMinutes() % 30);
    this.minimumTime = momento(this.currentDate).add(offset, "minute").format('HH:mm')
    this.reminderTime = this.minimumTime
    this.reminderDate = this.currentDate
  }

  initForm(): void {
    this.taskForm = this.fb.group({
      title: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      assigned_to: new FormControl(null, [Validators.required]),
      channel_id: new FormControl(this.data?.channel_id),
      attachments: this.fb.array([]),
      deleted: this.fb.array([]),
      inbox_id: new FormControl(this.data?.inbox_id ?? ''),
    });
  }

  initTask() {
    if (this.data?.taskId) {
      this.loadingTask = true;
      this.editMode = true;
      this.taskViewModel.loadTaskDetail(this.data.taskId);
      this.subSink.sink = this.taskDetail$.pipe(
        first((task): task is TaskDetail => task?.task_id == this.data?.taskId),
      ).subscribe(async ({ assigned, description, reminder, body, title, inbox_id, attachments }) => {
        this.loadingTask = false
        if (assigned) {
          this.mentionedMember = {
            id: assigned.user_id,
            name: `${assigned.firstName} ${assigned.lastName}`,
            img: assigned.avatar ?? '',
            active: false
          }
        }
        if (reminder) {
          this.reminder.patchValue(ReminderType.custom)
          this.taskForm.get('assigned_to')!.addValidators(Validators.required)
          this.reminderDate = new Date(reminder * 1000)
          this.reminderTime = momento(this.reminderDate).format('HH:mm')
        }
        if (description) {
          const members = await firstValueFrom(this.mentions$)
          description = !body
            ? description
            : (body ?? []).map(item => {
              switch (item.type) {
                case 'text':
                  return item.text
                case 'mention':
                  const member = members.find(m => m.id === item.id)
                  return member ? `<@mention_${item.id}>` : item.user.replace(/\s+/ig, '_')
                default:
                  return ''
              }
            }).join('')
          members.forEach(member => {
            description = description.replace(new RegExp(`<@mention_${member.id}>`, 'ig'), `@${member.name}`)
          })
        }
        this.taskForm.patchValue({ title, description, assigned_to: assigned?.user_id, inbox_id })
        attachments.forEach(({ attachment_id, attachment_url, filename }) => {
          this.attachmentsForm.push(
            this.fb.group({ filename, attachment_id, file_url: attachment_url })
          );
        });
        this.cdr.detectChanges();
      });
    }
  }

  onFiles(files: FileModel[]) {
    files.forEach(attachment => this.addAttachment(attachment));
  }

  addAttachment(attachment: FileModel) {
    if (this.attachments.length >= 3) {
      this.snackBarService.openAlert({
        message: Localizations.alert_dialog.max_attachments,
        translateParams: { max: '3' },
        type: 'info',
      });
      return
    }
    this.attachmentsForm.push(
      this.fb.group({
        type: attachment.type,
        file_url: attachment.url,
        filename: attachment.name,
        file: attachment.file
      })
    );
  }

  removeAttachment(index: number) {
    const attachment = this.attachmentsForm.at(index);
    const file_id = attachment.get('attachment_id')?.value
    file_id && this.deletedAttachments.push(this.fb.group({ file_id }));
    this.attachmentsForm.removeAt(index);
  }

  _onDateChange(event: string) {
    this.reminderDate = new Date(event)
    this.cdr.detectChanges();
  };

  _onTeamMemberSelect(member: TeamMember): void {
    if (member?.user_id) {
      this.taskForm?.get('assigned_to')?.setValue(member?.user_id);
      this.mentionedMember = {
        id: member.user_id,
        name: `${member.given_name} ${member.family_name}`,
        img: member.avatar ?? '',
        active: false
      }
    }
    this.cdr.detectChanges();
  }

  _toggleTaskLink() {
    this.linkToContact = !this.linkToContact
    this.cdr.detectChanges();
  }

  async submit() {
    this.taskForm.markAllAsTouched();
    if (!this.taskForm.valid || this.savingTask || this.reminder.value !== ReminderType.none && !this.taskForm.get('assigned_to')!.value) {
      this.reminder.value !== ReminderType.none && !this.taskForm.get('assigned_to')!.value && this.taskForm.get('assigned_to')?.setErrors({ required: true })
      return;
    }
    let request: CreatTaskDto = {
      task: {
        ...this.taskForm.value,
        channel_id: this.linkToContact
          ? this.data?.channel_id
          : undefined,
      },
      taskId: this.data?.taskId,
    }
    if (request.task.description) {
      let members = await firstValueFrom(this.mentions$)
      members.forEach(member => {
        request.task.description = request.task.description.replace(new RegExp(`@${member.name}`, 'ig'), `<@mention_${member.id}>`)
      })
    }
    request.task.attachments = request.task.attachments.filter(a => !(a as any).attachment_id)
    this.currentDate = new Date()
    const reminder =
      this.reminder.value === 'in_15_minutes' ? momento(this.currentDate).add(15, 'minute') :
        this.reminder.value === 'in_30_minutes' ? momento(this.currentDate).add(30, 'minute') :
          this.reminder.value === 'in_one_week' ? momento(this.currentDate).add(1, 'week') :
            this.reminder.value === 'in_one_day' ? momento(this.currentDate).add(1, 'day') :
              this.reminder.value === 'in_two_days' ? momento(this.currentDate).add(2, 'day') :
                this.reminder.value === 'custom' ? momento(this.reminderDate)
                  .set(momento(this.reminderTime, 'HH:mm').date.getHours(), 'hour')
                  .set(momento(this.reminderTime, 'HH:mm').date.getMinutes(), 'minute')
                  : undefined
    if (reminder && reminder.isBefore(momento(this.currentDate).date)) {
      this.snackBarService.openAlert({
        message: 'Invalid reminder date',
        type: 'failure',
      });
      return
    } else if (reminder) {
      request.task.reminder = reminder.unix()
    }

    this.savingTask = true;
    const formValues: any = request.task;
    Object.keys(formValues).forEach((key: string) => {
      if (formValues[key] === undefined || (!formValues[key].length && typeof formValues[key] !== 'number')) {
        delete formValues[key];
      }
    });
    request = { ...request, task: formValues };
    this.editMode
      ? this.updateTask(request)
      : this.createTask(request)
  }


  uploadTaskAttachments(attachments: Array<{ type: string, file_url: string, filename: string, file?: any }>): Promise<{ state: boolean, result: Array<{ filename: string, type: string, url: string }> }> {
    let _attachments: FormData | undefined = this.taskViewModel.getBodyAttachment(attachments);
    return new Promise(async (resolve) => {
      let uploadedAttachments = await firstValueFrom(this.taskViewModel.uploadAttachments(_attachments as FormData));
      resolve({ state: true, result: uploadedAttachments as any });
    });
  }

  async createTask(request: CreatTaskDto) {
    if (this.data && this.data.yobi_crm_company_id) request = { ...request, task: { ...request.task, yobi_crm_company_id: this.data.yobi_crm_company_id } };
    if (this.data && this.data.contact_id) request = { ...request, task: { ...request.task, contact_id: this.data.contact_id } };
    let uploadTaskAttachments: { state: boolean, result: Array<{ filename: string, type: string, url: string }> };
    if (request.task && request.task.attachments && request.task.attachments.length) {
      uploadTaskAttachments = await this.uploadTaskAttachments(request.task.attachments);
      request = { ...request, task: { ...request.task, attachments: uploadTaskAttachments.result as any } };
    };
    this.taskViewModel.createTask(request).subscribe({
      next: (result) => {
        this.reset();
        this.close({ type: 'created', data: result });
        this.snackBarService.openAlert({
          message: Localizations.alert_dialog.task_created,
          type: 'success'
        })
      },
      error: () => {
        this.savingTask = false;
        this.snackBarService.openAlert({
          message: Localizations.alert_dialog.task_not_created,
          type: 'failure',
        });
      }
    });
  }

  async updateTask(request: CreatTaskDto) {
    if (this.data && this.data.yobi_crm_company_id) request = { ...request, task: { ...request.task, yobi_crm_company_id: this.data.yobi_crm_company_id } };
    if (this.data && this.data.contact_id) request = { ...request, task: { ...request.task, contact_id: this.data.contact_id } };
    let uploadTaskAttachments: { state: boolean, result: Array<{ filename: string, type: string, url: string }> };
    if (request.task && request.task.attachments && request.task.attachments.length) {
      uploadTaskAttachments = await this.uploadTaskAttachments(request.task.attachments);
      request = { ...request, task: { ...request.task, attachments: uploadTaskAttachments.result as any } };
    };
    this.taskViewModel.editTask(request).subscribe({
      next: (res) => {
        this.savingTask = false;
        this.close({ type: 'updated', data: res });
        this.snackBarService.openAlert({
          message: Localizations.alert_dialog.task_updated,
          type: 'success'
        })
      },
      error: () => {
        this.savingTask = false;
        this.snackBarService.openAlert({
          message: Localizations.alert_dialog.task_not_updated,
          type: 'failure',
        });
      }
    });
  }

  reset() {
    this.mentionedMember = undefined
    this.savingTask = false;
    this.taskForm.reset();
    this.attachmentsForm.clear();
    this.deletedAttachments.clear();
    this.cdr.detectChanges();
  }

  close(data?: { type: 'archive' | 'updated' | 'created', data?: TaskDetail }) {
    this.dialogRef.close(data);
  }

  archive() {
    this.close({ type: 'archive' });
  }
}
