export enum terms {
  settings = 'terms.settings',
  password = 'terms.password',
  email = 'terms.email',
  website = 'terms.website',
  contacts = 'terms.contacts',
  interactions = 'terms.interactions',
  notes = 'terms.notes',
  tags = 'terms.tags',
  created_date = 'terms.created_date',
  updated_date = 'terms.updated_date',
  company = 'terms.company',
  tasks = 'terms.tasks',
  notifications = 'terms.notifications',
  blocked = 'terms.blocked',
  inbox = 'terms.inbox',
  unread = 'terms.unread',
  messages = 'terms.messages',
  task = 'terms.task',
  transcription = 'terms.transcription',
  reformulation = 'terms.reformulation',
  reformulate = 'terms.reformulate',
  character = 'terms.character',
  tone = 'terms.tone',
  emoji = 'terms.emoji',
  from = 'terms.from',
  note = 'terms.note',
  inline_note = 'terms.inline_note',
  snippets = 'terms.snippets',
  soon = 'terms.soon',
  ever = 'terms.ever',
  month = 'terms.month',
  year = 'terms.year',
  invoices = 'terms.invoices',
  off = 'terms.off',
  monthly = 'terms.monthly',
  months = 'terms.months',
  plan = 'terms.plan',
  total = 'terms.total',
  users = 'terms.users',
  view = 'terms.view',
  yearly = 'terms.yearly',
  admin = 'terms.admin',
  member = 'terms.member',
  role = 'terms.role',
  apps = 'terms.apps',
  user = 'terms.user',
  address = 'terms.address',
  address_line1 = 'terms.address_line1',
  address_line2 = 'terms.address_line2',
  address_state = 'terms.address_state',
  address_city = 'terms.address_city',
  address_zip = 'terms.address_zip',
  birthday = 'terms.birthday',
  checkbox = 'terms.checkbox',
  date = 'terms.date',
  number = 'terms.number',
  phone = 'terms.phone',
  text = 'terms.text',
  url = 'terms.url',
  description = 'terms.description',
  title = 'terms.title',
  trash = 'terms.trash',
  you = 'terms.you',
  them = 'terms.them',
  microphones = 'terms.microphones',
  devices = 'terms.devices',
  speakers = 'terms.speakers',
  information = 'terms.information',
  gender = 'terms.gender',
  channels = 'terms.channels',
  country = 'terms.country',
  phones = 'terms.phones',
  custom_fields = 'terms.custom_fields',
  subtotal = 'terms.subtotal',
  credits = 'terms.credits',
  base_price = 'terms.base_price',
  expire = 'terms.expire',
  terms_of_service = 'terms.terms_of_service',
  select_file = 'terms.select_file',
  privacy_policy = 'terms.privacy_policy',
  back = 'terms.back',
  next = 'terms.next',
  location = 'terms.location',
  timezone = 'terms.timezone',
  calendar = 'terms.calendar',
  activities = 'terms.activities',
  overview = 'terms.overview',
}
