import { Observable, of } from 'rxjs';
import { BaseInlineNoteDTO } from '@/app/Data/DTO/InlineNoteDto';
import { InteractionThread, SocketInteraction } from '../Models/Interaction';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';

export abstract class IInlineNotesRepository {
  abstract createInlineNote(
    note: BaseInlineNoteDTO
  ): Observable<SocketInteraction>;
  abstract deleteInlineNote(
    note_id: number
  ): Observable<SuccessResponse<SocketInteraction>>;
}
