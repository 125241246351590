import { environment } from '@/environments/environment';
import { Inject, Injectable } from '@angular/core';
import { from, map, NEVER, Observable, of, pluck, switchMap, tap } from 'rxjs';
import { DatabaseService } from '../services/Database/database.service';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { IInlineNotesRepository } from '@/app/core/IRepositories/IInlineNotesRepository';
import { BaseInlineNoteDTO } from '../DTO/InlineNoteDto';
import { SocketInteraction } from '@/app/core/Models/Interaction';

@Injectable({
  providedIn: 'root',
})
export class InlineNotesRepository implements IInlineNotesRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) {}

  createInlineNote(note: BaseInlineNoteDTO): Observable<SocketInteraction> {
    const requestURL = `${environment.apiURL}inline-note`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      note,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SocketInteraction>;
        return res.results;
      })
    );
  }

  deleteInlineNote(
    note_id: number
  ): Observable<SuccessResponse<SocketInteraction>> {
    const requestURL = `${environment.apiURL}inline-note/${note_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => res as SuccessResponse<SocketInteraction>)
    );
  }
}
