import { IInboxesRepository } from '@/app/core/IRepositories/IInboxesRepository';
import { CustomInboxDto, InboxAttibuteQuery, inboxesResponseDto } from '@/app/core/Models/inbox';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { Observable, map, pluck } from 'rxjs';
import { SuccessResponse } from '../DTO/successResponse';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';

@Injectable({
  providedIn: 'root',
})
export class InboxesRepository implements IInboxesRepository {
  constructor(
    private httpService: HttpService,
  ) { }

  getInboxes(): Observable<inboxesResponseDto> {
    const requestURL = `${environment.apiURL}inboxes-list`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => (item as SuccessApiResponse<inboxesResponseDto>).results)
    );
  }

  EditInboxeAttribute({ inboxId, ...data }: InboxAttibuteQuery): Observable<SuccessResponse<any>> {
    const requestURL = `${environment.apiURL}inboxes/${ inboxId }/attribute`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      data,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => (item as SuccessResponse<any>))
    );
  }

  createCustomInbox(data: CustomInboxDto): Observable<SuccessResponse<any>> {
    const requestURL = `${environment.apiURL}inboxes/custom`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => (item as SuccessResponse<any>))
    );
  }

  deleteCustomInbox(inboxId: number): Observable<SuccessResponse<any>> {
    const requestURL = `${environment.apiURL}inboxes/custom/${ inboxId }`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => (item as SuccessResponse<any>))
    );
  }
}
