import { IChannelRepository } from '@/app/core/IRepositories/IChannelRepository';
import { ChannelBlockDto, ToggleChannelSynthRequestDto } from '@/app/Data/DTO/ChannelDto';
import { map, Observable, pluck, tap } from 'rxjs';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { HttpService } from '@/app/Data/services/Networking/HttpService';
import { environment } from '@/environments/environment';
import { HttpRequestMethod } from '@/app/Data/services/Networking/HttpRequestMethod';
import { SuccessApiResponse } from '@/app/Data/services/Networking/ApiResponse';
import { Channel, CustomVoiceAttributeName, PhoneInternalChannel } from '@/app/core/Models/Channel';
import { Injectable } from '@angular/core';
import { PhoneChannelListRequestDto } from '../DTO/PhoneChannelListRequestDto';
import { PhoneChannel } from '@/app/core/Models/phoneChannel';
import { AddYobiPhoneDto } from '../DTO/AddYobiPhoneDto';
import { BusinessHoursDto, BusinessHoursResponse, BusinessHoursStausResponse } from '../DTO/BusinessHoursDto';
import { EditPhoneChannelDataRequestDto } from '../DTO/EditPhoneChannelDataRequestDto';
import { TwilioCustomMessageDto } from '../DTO/TwilioCustomMessageDto';
import { ChatWidgetData, ChatwidgetExternalInfo } from '@/app/core/Models/chatWidgetChannel';

@Injectable()

export class ChannelRepository extends IChannelRepository {
  override toggleChannelSynth(req: ToggleChannelSynthRequestDto): Observable<ToggleChannelSynthRequestDto> {
    const requestURL = `${environment.apiURL}synth/status`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      req,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<ToggleChannelSynthRequestDto>;
        return res.results;
      })
    );
  }

  addYobiPhone(request: AddYobiPhoneDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}buy-phone-number`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  constructor(private httpService: HttpService) {
    super();
  }
  isPhoneNumberBlocked(request: ChannelBlockDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}is_blocked_number`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  getPhoneChannelList(
    request: PhoneChannelListRequestDto
  ): Observable<PhoneChannel[]> {
    const requestURL = `${environment.apiURL}available-phone-numbers-by-filter`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<PhoneChannel[]>;
        return res.results;
      })
    );
  }

  updatePhoneChannelData(
    request: EditPhoneChannelDataRequestDto, channel_id: number
  ): Observable<PhoneInternalChannel> {
    const requestURL = `${environment.apiURL}internal-channels/${channel_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<PhoneInternalChannel>;
        return res.results;
      })
    );
  }

  setPhoneChannelCustomVoice(
    form: FormData
  ): Observable<TwilioCustomMessageDto> {
    const requestURL = `${environment.apiURL}webhooks/twilio/add-custom-message`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      form,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TwilioCustomMessageDto>;
        return res.results;
      })
    );
  }

  deletePhoneChannelCustomVoice(
    request: {
      attribute_name: CustomVoiceAttributeName
      channel_id: number
    }
  ): Observable<string> {
    const requestURL = `${environment.apiURL}webhooks/twilio/delete-custom-message`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<string>;
        return res.results;
      })
    );
  }

  setBusinessHoursStatus(
    channel_id: number,
    status: 'ON' | 'OFF'
  ): Observable<BusinessHoursStausResponse> {
    const requestURL = `${environment.apiURL}business-hours/status?channel_id=${channel_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      { status },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<BusinessHoursStausResponse>;
        return res.results;
      })
    );
  }

  getBusinessHoursStatus(
    channel_id: number
  ): Observable<'ON' | 'OFF'> {
    const requestURL = `${environment.apiURL}business-hours/status?channel_id=${channel_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<'ON' | 'OFF'>;
        return res.results;
      })
    );
  }

  setBusinessHours(
    request: BusinessHoursDto, channel_id: number
  ): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}business-hours?channel_id=${channel_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      tap(console.log),
      map((item) => {
        let res = item as SuccessResponse<string>;
        return res;
      })
    );
  }

  getBusinessHours(
    channel_id: number
  ): Observable<BusinessHoursResponse> {
    const requestURL = `${environment.apiURL}business-hours?channel_id=${channel_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<BusinessHoursResponse>;
        return res.results;
      })
    );
  }

  getFacebookPages(data: any): Observable<any> {
    const requestURL = `${environment.apiURL}messages/fb/getpagesforuser`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res: any) => {
        return res;
      })
    );
  }

  initFacebookPage(pageDetails: any): Observable<any> {
    const requestURL = `${environment.apiURL}messages/fb/init`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      pageDetails,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res: any) => {
        return res;
      })
    );
  }

  disconnectFacebookChannel(): Observable<any> {
    const requestURL = `${environment.apiURL}disconnect/fb`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<string>;
        return res.results;
      })
    );
  }

  getInstagramPages(data: any): Observable<any> {
    const requestURL = `${environment.apiURL}messages/instagram/getpagesforuser`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res: any) => {
        return res;
      })
    );
  }

  initInstagramPage(pageDetails: any): Observable<any> {
    const requestURL = `${environment.apiURL}messages/instagram/init`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      pageDetails,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res: any) => {
        return res;
      })
    );
  }

  disconnectInstagramChannel(): Observable<any> {
    const requestURL = `${environment.apiURL}disconnect/instagram`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<string>;
        return res.results;
      })
    );
  }

  connectTwitterChannel(): Observable<string> {
    const requestURL = `${environment.apiURL}twitter/auth`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<string>;
        return (res as any).result;
      })
    );
  }

  disconnectTwitterChannel(): Observable<string> {
    const requestURL = `${environment.apiURL}disconnect/twitter`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<string>;
        return res.results;
      })
    );
  }

  override connectLinkedinChannel(): Observable<string> {
    const requestURL = `${environment.apiURL}linkedin/authorize`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<string>;
        return (res as any).result;
      })
    );
  }

  disconnectLinkedinChannel(page_id: string): Observable<string> {
    const requestURL = `${environment.apiURL}linkedin/unlink/${page_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<string>;
        return res.results;
      })
    );
  }

  override connectGmailChannel(): Observable<string> {
    const requestURL = `${environment.apiURL}email/google/authorize`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { is_contact: false },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => (item as SuccessResponse<string>).results)
    );
  }

  override disconnectGmailChannel(): Observable<string> {
    const requestURL = `${environment.apiURL}email/google/disconnect`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { is_contact: false },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<string>;
        return res.results;
      })
    );
  }

  override connectOutlookChannel(): Observable<string> {
    const requestURL = `${environment.apiURL}email/outlook/authorize`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => (item as SuccessResponse<string>).results)
    );
  }

  override disconnectOutlookChannel(): Observable<string> {
    const requestURL = `${environment.apiURL}email/outlook/disconnect`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<string>;
        return res.results;
      })
    );
  }


  getChatWidgetData(): Observable<ChatWidgetData> {
    const requestURL = `${environment.apiURL}chatWidget`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<ChatWidgetData>;
        return res.results;
      })
    );
  }
  createChatWidget(data: ChatwidgetExternalInfo): Observable<ChatWidgetData> {
    const requestURL = `${environment.apiURL}chatWidget`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<ChatWidgetData>;
        return res.results;
      })
    );
  }
  updateChatWidget(data: ChatwidgetExternalInfo): Observable<ChatWidgetData> {
    const requestURL = `${environment.apiURL}chatWidget`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<ChatWidgetData>;
        return res.results;
      })
    );
  }

}
