import { vendorType } from "./ChannelTypes";
import { InlineNoteBody, InteractionBody, InteractionDirection, InteractionStatus } from "./Interaction";

export class EmailContact {
    avatar: string;
    contact_id: number;
    contact_name: string;
    channel_value: string;
    is_contact: boolean;
    role: 'cc' | 'bcc' | 'from' | 'to'
}

export class EmailClassification {
    classification: string;
    email_classification_id: number;
    unread_count: number;
    priority: number
}

export class RecentEmailInteraction {
    contact_list: EmailContact[];
    conversation_dt: string;
    conversation_id: number;
    internal_channel_id: number;
    preview: string;
    subject: string;
    unread_count: number;

    _classificationId: number;
    set classificationId(value: number) {
        this._classificationId = value;
    };
    get classificationId(): number {
        return this._classificationId;
    };

    _page: number;
    set page(value: number) {
        this._page = value;
    };
    get page(): number {
        return this._page;
    };
}

export class ThreadEmailInteraction {
    attachment_urls: string;
    content_type: 'note' | 'task' | 'email' = 'email'
    body: string;
    body_preview: string;
    contact_list: EmailContact[];
    conversation_id: number;
    created_dt: string;
    direction: InteractionDirection;
    email_interaction_id: number;
    external_interaction_id: string;
    status: InteractionStatus;
    status_dt: string;
    updated_dt: string;
    user_id: number;
    vendor_name: vendorType.Outlook | vendorType.Google;
    web_link: string;
    _page: number;
    set page(value: number) {
        this._page = value;
    };
    get isNote() { // TODO tmp to be deleted when backend add content_type @param
        return this.body.includes('"content_type": "note"')
    }
    get page(): number {
        return this._page;
    };
    get attachments(): { type: string, url: string }[] {
        try {
            return JSON.parse(this.attachment_urls) || [];
        } catch (error) {
            return [];
        }
    }
    get interactionBody(): InteractionBody {
        try {
            return JSON.parse(this.body) as InteractionBody || [];
        } catch (error) {
            return [];
        }
    }
    get noteBody(): InlineNoteBody | string | undefined {
        return this.interactionBody.find(item => item.content_type === 'note')?.content
    }
}

export class SocketEmailInteraction {
    conversation_id: number
    direction: InteractionDirection
    email_classification_id: number
    external_interaction_id: string
    internal_channel_id: number
    msg_id: number
    silent: boolean
}