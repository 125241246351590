import { AppState } from '@/app/State/AppState';
import { createSelector } from '@ngrx/store';
import { NotificationsState } from '@/app/State/Notifications/notifications.reducer';

const notificationsState = (state: AppState) => state.notifications;

export const selectNotificationsState = createSelector(
  notificationsState,
  (state: NotificationsState) => state
);

export const selectNotifications = createSelector(
  notificationsState,
  (state: NotificationsState) => state.notifications
);

export const selectUnreadCount = createSelector(
  notificationsState,
  (state: NotificationsState) => state.unreadCount
);
