import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Observer } from "rxjs";

export const getUploadEventProgress = (
  event: HttpEvent<any>,
  progress$?: Observer<any>
): any => {
  try {
    if (event.type == HttpEventType.Sent) {
      progress$?.next(0);
    } else if (event.type == HttpEventType.UploadProgress) {
      const percentDone = Math.round((100 * event.loaded) / (event.total ?? 0));
      console.log({ percentDone });
      progress$?.next(percentDone);
    }
  } catch (err) {
    console.log(err);
  }
}