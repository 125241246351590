import { Injectable } from '@angular/core';
import { load } from "@fingerprintjs/fingerprintjs";
import { fingerprintDto } from '../Data/DTO/fingerprintDto';
@Injectable({
  providedIn: 'root'
})
export class FingerprintingService {

  constructor(

  ) { }

  async getInformation() {
    const fp = await load()
    const { components, visitorId } = await fp.get()
    const { canvas, fonts, languages, platform, screenResolution, screenFrame, timezone, videoCard, fontPreferences, audio } = components
    const fingerprint: fingerprintDto = {
      canvas: {
        geometry: await this.hashText(canvas.value?.geometry),
        // text: await this.hashText(canvas.value?.text),
      },
      fonts: fonts?.value,
      languages: languages?.value,
      platform: platform?.value,
      screenResolution: screenResolution?.value,
      screenFrame: screenFrame?.value,
      timezone: timezone?.value,
      videoCard: videoCard?.value,
      fontPreferences: fontPreferences?.value,
      audio: audio?.value,
      userAgent: navigator.userAgent,
      visitorId
    }
    return fingerprint
  }

  async hashText(text: string = '') {
    const encoder = new TextEncoder();
    const data = encoder.encode(text);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashedText = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    return hashedText;
  }
}
