import { createAction, props, union } from '@ngrx/store';
import { InteractionStatusDto } from '@/app/Data/DTO/InteractionStatusDto';
import { InteractionArchiveDto } from '@/app/Data/DTO/InteractionArchiveDto';
import { InteractionClassificationQueryDto, SynthInteractionFeadbackDto } from '@/app/Data/DTO/InteractionDto';
import { InteractionClassification } from '@/app/core/Models/interactionClassification';
import { AddInteractionComment } from '@/app/Data/DTO/Interaction/addInteractionCommentDto';
import { NumberInput } from '@angular/cdk/coercion';
import { InteractionComment } from '@/app/core/Models/Interaction/InteractionComment';
import { Tenant } from '@/app/core/Models/tenant';
import { InteractionPinDto } from '@/app/Data/DTO/InteractionPinDto';

export const UPDATE_INTERACTIONS_STATUS = '[INTERACTION] Update Interactions Status';
export const UpdateInteractionsStatusAction = createAction(
  UPDATE_INTERACTIONS_STATUS,
  props<{ payload: InteractionStatusDto }>()
);

export const UN_ARCHIVE_INTERACTION = '[INTERACTION] UnArchive Interactions Status';
export const UnArchiveInteractionAction = createAction(
  UN_ARCHIVE_INTERACTION,
  props<{ payload: InteractionArchiveDto }>()
);

export const ARCHIVE_INTERACTION = '[INTERACTION] Archive Interactions Status';
export const ArchiveInteractionAction = createAction(
  ARCHIVE_INTERACTION,
  props<{ payload: InteractionArchiveDto }>()
);

export const UN_PIN_INTERACTION = '[INTERACTION] unPin Interactions Status';
export const UnPinInteractionAction = createAction(
  UN_PIN_INTERACTION,
  props<{ payload: InteractionPinDto }>()
);

export const PIN_INTERACTION = '[INTERACTION] Pin Interactions Status';
export const PinInteractionAction = createAction(
  PIN_INTERACTION,
  props<{ payload: InteractionPinDto }>()
);

export const GET_INTERACTION_CLASSIFICATION = '[INTERACTION] get interaction classification';
export const GetInteractionClassification = createAction(
  GET_INTERACTION_CLASSIFICATION,
  props<{ payload: InteractionClassificationQueryDto }>()
);

export const SEND_SYNTH_INTERACTION_FEEDBACK = '[INTERACTION] Send Synth Interaction Feedback';
export const SendSynthInteractionFeedback = createAction(
  SEND_SYNTH_INTERACTION_FEEDBACK,
  props<{ payload: SynthInteractionFeadbackDto }>()
);

export const GET_INTERACTION_CLASSIFICATION_SUCCESS = '[INTERACTION] get interaction classification success';
export const GetInteractionClassificationSuccessAction = createAction(
  GET_INTERACTION_CLASSIFICATION_SUCCESS,
  props<{ classification: InteractionClassification }>()
);

export const GET_INTERACTION_CLASSIFICATION_FAIL = '[INTERACTION] get interaction classification Fail';
export const GetInteractionClassificationFailAction = createAction(
  GET_INTERACTION_CLASSIFICATION_FAIL,
);

export const ADD_INTERACTION_COMMENT = '[INTERACTION] Add comment';
export const AddInteractionCommentAction = createAction(
  ADD_INTERACTION_COMMENT,
  props<{ payload: { data: AddInteractionComment, profile: Tenant, formattedContent: string } }>()
);

export const RESEND_INTERACTION_COMMENT = '[INTERACTION] Resend comment';
export const ResendInteractionCommentAction = createAction(
  RESEND_INTERACTION_COMMENT,
  props<{ payload: { comment: InteractionComment } }>()
);

export const ADD_INTERACTION_COMMENT_SUCCESS = '[INTERACTION] Add comment success';
export const AddInteractionCommentSuccessAction = createAction(
  ADD_INTERACTION_COMMENT_SUCCESS,
  props<{ payload: InteractionComment }>()
);
export const ADD_INTERACTION_COMMENT_FAIL = '[INTERACTION] Add comment fail';
export const AddInteractionCommentFailAction = createAction(
  ADD_INTERACTION_COMMENT_FAIL,
  props<{ payload: AddInteractionComment }>()
);

export const GET_INTERACTION_COMMENT = '[INTERACTION] GEt Interaction comment';
export const GetInteractionCommentAction = createAction(
  GET_INTERACTION_COMMENT,
  props<{ payload: { interactionId: string | number } }>()
);

export const GET_INTERACTION_COMMENT_SUCCESS = '[INTERACTION] GEt Interaction comment success';
export const GetInteractionCommentSuccessAction = createAction(
  GET_INTERACTION_COMMENT_SUCCESS,
  props<{ payload: { comments: InteractionComment[], interactionId: number | string } }>()
);


export const GET_INTERACTION_COMMENT_FAIL = '[INTERACTION] GEt Interaction comment fail';
export const GetInteractionCommentFailAction = createAction(
  GET_INTERACTION_COMMENT_FAIL,
  props<{ payload: { interactionId: string | number } }>()
);

export const TOGGLE_INTERACTION_COMMENTS = '[INTERACTION] toggle interaction comments';
export const ToggleInteractionCommentAction = createAction(
  TOGGLE_INTERACTION_COMMENTS,
  props<{ payload: { interactionId: string | number } }>()
);

export const DELETE_INTERACTION_COMMENT = '[INTERACTION] delete interaction comments';
export const DeleteInteractionCommentAction = createAction(
  DELETE_INTERACTION_COMMENT,
  props<{ payload: { commentId: number } }>()
);

export const ON_RECEIVED_INTERACTION_COMMENT = '[INTERACTION] On Receiveed interaction comment';
export const OnReceivedInteractionComment = createAction(
  ON_RECEIVED_INTERACTION_COMMENT,
  props<{ payload: InteractionComment }>()
);

export const OPEN_INTERACTION_COMMENT_EDIT = '[INTERACTION] Open interaction comment edit';
export const OpenInteractionCommentEditAction = createAction(
  OPEN_INTERACTION_COMMENT_EDIT,
  props<{ payload: InteractionComment }>()
);

export const CLOSE_INTERACTION_COMMENT_EDIT = '[INTERACTION] Close interaction comment edit';
export const CloseInteractionCommentEditAction = createAction(
  CLOSE_INTERACTION_COMMENT_EDIT,
  props<{ payload: InteractionComment }>()
);



export const UPDATE_INTERACTION_COMMENT = '[INTERACTION] Update interaction comment edit';
export const UpdateInteractionCommentAction = createAction(
  UPDATE_INTERACTION_COMMENT,
  props<{ payload: { commentId: any, value: string } }>()
);


export const UPDATE_INTERACTION_COMMENT_SUCCESS = '[INTERACTION] Update interaction comment edit success';
export const UpdateInteractionCommentSuccessAction = createAction(
  UPDATE_INTERACTION_COMMENT_SUCCESS,
  props<{ payload: InteractionComment }>()
);

export const OPEN_INTERACTION_COMMENT_INPUT = '[INTERACTION] Open interaction comment input ';
export const OpenInteractionCommentInputAction = createAction(
  OPEN_INTERACTION_COMMENT_INPUT,
  props<{ payload: { interactionId: number | string } }>()
);
const all = union({
  UpdateInteractionsStatusAction,
  AddInteractionCommentAction,
  ResendInteractionCommentAction,
  SendSynthInteractionFeedback,
  UnArchiveInteractionAction,
  ArchiveInteractionAction,
  UnPinInteractionAction,
  PinInteractionAction,
  GetInteractionClassification,
  ToggleInteractionCommentAction,
  GetInteractionCommentAction,
  GetInteractionCommentSuccessAction,
  DeleteInteractionCommentAction,
  OnReceivedInteractionComment,
  OpenInteractionCommentEditAction,
  UpdateInteractionCommentAction,
  UpdateInteractionCommentSuccessAction,
  OpenInteractionCommentInputAction,
  CloseInteractionCommentEditAction
})
export type InteractionActions = typeof all;
