import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RestrictionErrorDialogComponent } from './restriction-error-dialog.component';
import { ButtonModule } from '../../components/Button/button.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [RestrictionErrorDialogComponent],
  exports: [RestrictionErrorDialogComponent],
  imports: [CommonModule, ButtonModule, MatIconModule],
})
export class RestrictionErrorDialogModule {}
