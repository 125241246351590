import { IAuthenticationRepository } from '@/app/core/IRepositories/IAuthenticationRepository';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap, Subject, throwError, of } from 'rxjs';
import { retry, catchError, filter, take, mergeMap } from 'rxjs/operators';
import { AuthService } from '../Auth/auth.service';
import { TokenStorgeService } from '../Token/token.service';
import { ApiErrorMessage, ApiResponse, ErrorApiReponse } from './ApiResponse';
import { IS_RETRY_ENABLED } from './HttpService';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  displayToUser = true;
  doNotDisplayToUser: false;
  isRefreshing = false;
  refreshTokenSubject = new Subject();
  constructor(
    private authService: AuthService,
    private tokenService: TokenStorgeService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(request.context.get(IS_RETRY_ENABLED) === true ? 0 : 0),
      catchError((error: any) => {
        return this.handleError(error, request, next);
      })
    );
  }

  updateHeader(request: HttpRequest<any>, token: string): any {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse,) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken = this.tokenService.getRefreshToken();
      if (refreshToken) {
        return this.authService.refreshToken(refreshToken)
          .pipe(
            catchError((error) => {
              console.log("refresh status ", error?.status)
              if (error.status == 400 || error?.status == 401) {
                this.authService.logout();
              }
              const apiErrorResponse = new ErrorApiReponse();
              apiErrorResponse.sucess = false;
              apiErrorResponse.timestamp = new Date(Date.now());
              apiErrorResponse.statusCode = error.status;
              apiErrorResponse.message = 'Unexpected HTTP error.';
              apiErrorResponse.responseError = new ApiErrorMessage(
                error.error?.message,
                error.status,
                error.error
              );
              this.isRefreshing = false;
              throw apiErrorResponse;
            }),
            switchMap((data) => {
              this.tokenService.saveToken(data.token);
              this.tokenService.saveRefreshToken(data.refreshToken);
              this.isRefreshing = false;
              this.refreshTokenSubject.next(data.token);
              return next
                .handle(this.updateHeader(request, data.token))
            })
          );
      } else {
        this.isRefreshing = false;
        this.authService.logout();
        const apiErrorResponse = new ErrorApiReponse();
        apiErrorResponse.sucess = false;
        apiErrorResponse.timestamp = new Date(Date.now());
        apiErrorResponse.statusCode = error.status;
        apiErrorResponse.message = 'Unexpected HTTP error.';
        apiErrorResponse.responseError = new ApiErrorMessage(
          error.error?.message,
          error.status,
          error.error
        );
        return throwError(() => error);
      }

    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token: any) => next.handle(this.updateHeader(request, token)))
    );
  }


  protected handleError(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    const apiErrorResponse = new ErrorApiReponse();
    apiErrorResponse.sucess = false;
    apiErrorResponse.timestamp = new Date(Date.now());
    apiErrorResponse.statusCode = error.status;
    apiErrorResponse.message = 'Unexpected HTTP error.';
    const isRetryEnabled = request.context.get(IS_RETRY_ENABLED) === true;
    if (
      error instanceof HttpErrorResponse &&
      (error.status === 401 || error.status === 403)
    ) {
      if (error.url?.includes('buy-phone-number')) {
        // TODO:needs to be fixed on backend
      } else {
        if (isRetryEnabled) {
          return this.handle401Error(request, next, error);
        }
      }
    }

    if (error.error instanceof ErrorEvent) {
      apiErrorResponse.responseError = new ApiErrorMessage(
        `A client-side or network error occurred.`,
        error.status
      );

      return throwError(() => apiErrorResponse);
    } else {
      // The API returned an unsuccessful response.
      if (error instanceof ErrorApiReponse) {
        // A known error r
        return throwError(() => error);
      } else {
        apiErrorResponse.responseError = new ApiErrorMessage(
          `Unsuccessful response. ${error.status}: ${error.statusText}.
             ${error.message}`,
          error.status,
          error.error
        );
        return throwError(() => apiErrorResponse);
      }
    }
  }
}
