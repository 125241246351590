import { EmailVerificationCodeDto } from '@/app/Data/DTO/EmailVerificationCodeDto';
import { VerificationCodeDto } from '@/app/Data/DTO/VerificationCodeDto';
import { BusinessNameFieldDto, EmailFieldDto } from '@/app/Data/DTO/authDto';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { ErrorApiReponse } from '@/app/Data/services/Networking/ApiResponse';
import {
  handleLoginErrors,
  handleSocialLoginErrors
} from '@/app/Utilities/formatters/login.error';
import { Localizations } from '@/app/Utilities/localization';
import { PlatformService } from '@/app/Utilities/platform/platform.service';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';
import { ToastService } from '@/app/Utilities/toast/toast.service';
import { IAuthenticationRepository } from '@/app/core/IRepositories/IAuthenticationRepository';
import { ChannelType } from '@/app/core/Models/ChannelTypes';
import { LoginCredentialsRequest } from '@/app/core/Models/LoginCredentials';
import { SuccessLoginRes } from '@/app/core/Models/SuccessLoginRes';
import { checkBusinessNameExistingUseCase } from '@/app/core/usecases/Auth/CheckBusinessNameExistingUseCase';
import { CheckEmailExistingUseCase } from '@/app/core/usecases/Auth/CheckEmailExistingUseCase';
import { LoginWithCredentialsUseCase } from '@/app/core/usecases/Auth/LoginWithCredentialsUseCase';
import { LoginWithTokensUseCase } from '@/app/core/usecases/Auth/LoginWithTokensUseCase';
import { RequestEmailVerificationCodeUseCase } from '@/app/core/usecases/Auth/RequestEmailVerificationCodeUseCase';
import { SignupWithCredentialsUseCase } from '@/app/core/usecases/Auth/SignupWithCredentialsUseCase';
import { ValidateVerificationCodeUseCase } from '@/app/core/usecases/Auth/ValidateVerificationCodeUseCase';
import { CreateUserUseCase } from '@/app/core/usecases/Users/CreateUserUseCase';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
  Actions,
  concatLatestFrom,
  createEffect, ofType
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  delay,
  exhaustMap, from,
  map,
  of,
  switchMap,
  tap
} from 'rxjs';
import { AppState } from '../AppState';
import { SendUserFingerprintAction } from '../Tenant/tenant.action';
import { PreInitializeApplicationAction } from '../app/action';
import { PasswordResetDto } from './../../Data/DTO/PasswordResetDto';
import {
  AuthActions,
  BILLING_TOKEN_LOGIN,
  CHECK_IF_BUSINESS_NAME_NOT_EXIST,
  CHECK_IF_EMAIL_NOT_EXIST,
  CREATE_USER,
  CheckIfBusinessNameNotExistFailAction,
  CheckIfBusinessNameNotExistSuccessAction,
  CheckIfEmailNotExistFailAction,
  CheckIfEmailNotExistSuccessAction,
  CreateUserFailedAction,
  CreateUserSuccessAction,
  DesktopSocialSignInAction,
  EDIT_SINGUP_FORM,
  LOGIN_WITH_APPLE,
  LOGIN_WITH_CREDENTIAL,
  LOGIN_WITH_CREDENTIAL_SUCCESS,
  LOGIN_WITH_FACEBOOK,
  LOGIN_WITH_GOOGLE,
  LoginWithCredentialsAction,
  LoginWithCredentialsFailAction,
  LoginWithCredentialsSuccessAction,
  PASSWORD_RESET_SEND,
  PasswordResetSendFailAction,
  REQUEST_EMAIL_VERIFICATION_CODE,
  REQUEST_EMAIL_VERIFICATION_CODE_SUCCESS,
  RequestEmailVerificationCodeFailAction,
  RequestEmailVerificationCodeSuccessAction,
  SIGNUP_WITH_CREDENTIALS,
  SIGNUP_WITH_CREDENTIALS_SUCCESS,
  SOCIAL_LOGIN,
  SOCIAL_LOGIN_FAIL,
  SOCIAL_LOGIN_SUCESS,
  SignupWithCredentialsFailAction,
  SignupWithCredentialsSuccessAction,
  SocialLoginSucessAction,
  SocialoLoginAction,
  SocialoLoginFailAction,
  VALIDATE_EMAIL_VERIFICATION_CODE,
  VALIDATE_EMAIL_VERIFICATION_CODE_SUCCESS,
  ValidateEmailVerificationCodeAction,
  ValidateEmailVerificationCodeFailAction,
  ValidateEmailVerificationCodeSuccessAction
} from './action';
import { selectSignUpState } from './selector';
@Injectable()
export class AuthEffect {
  constructor(
    private actions$: Actions<AuthActions>,
    private router: Router,
    private zone: NgZone,
    private store$: Store<AppState>,
    private checkExistingEmailUseCase: CheckEmailExistingUseCase,
    private validateVerificationCode: ValidateVerificationCodeUseCase,
    private requestEmailVerificationCodeUseCase: RequestEmailVerificationCodeUseCase,
    private signupWithCredentialsUseCase: SignupWithCredentialsUseCase,
    private checkExistingBusinessNameUseCase: checkBusinessNameExistingUseCase,
    private database: DatabaseService,
    private snackBarService: SnackbarService,
    private toastService: ToastService,
    private loginWithCredentialsUseCase: LoginWithCredentialsUseCase,
    private loginWithTokensUseCase: LoginWithTokensUseCase,
    private platformService: PlatformService,
    private authRepository: IAuthenticationRepository,
    private createUserUseCase: CreateUserUseCase,
  ) { }

  /* --------------------------- CREATE USER EFFECT --------------------------- */
  onCreateUserAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CREATE_USER),
        map((action) => action.payload),
        switchMap((credential) =>
          from(this.createUserUseCase.execute(credential)).pipe(
            map((tenant) => CreateUserSuccessAction({ payload: { tenant } })),
            catchError((er) => {
              const message = er?.responseError?.error?.message || Localizations.alert_dialog.operation_not_possible
              this.snackBarService.openAlert({
                message, type: 'failure'
              })
              return of(CreateUserFailedAction({ payload: { message } }));
            })
          )
        )
      ),
    { dispatch: true }
  );

  /* ------------------------------ LOGIN EFFECTS ----------------------------- */
  onLoginWithCredentials$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_WITH_CREDENTIAL),
        map((action) => action.payload),
        switchMap((credential: LoginCredentialsRequest) =>
          from(this.loginWithCredentialsUseCase.execute(credential)).pipe(
            map((tenant) => LoginWithCredentialsSuccessAction({ payload: { tenant } })),
            catchError((er) => {
              console.error(er);
              const tokonizedError = handleLoginErrors(er);
              const payload = {
                payload:
                  er?.responseError?.error?.message?.error?.body ||
                  tokonizedError,
              };
              return of(LoginWithCredentialsFailAction(payload));
            })
          )
        )
      ),
    { dispatch: true }
  );


  onValidationOfEmailVerificationCodeAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VALIDATE_EMAIL_VERIFICATION_CODE),
        map((action) => action.payload),
        switchMap((credential: VerificationCodeDto) =>
          from(this.validateVerificationCode.execute(credential))
            .pipe(
              map((result) => {
                return ValidateEmailVerificationCodeSuccessAction();
              }),
              catchError((er: ErrorApiReponse<any>) => {
                const message = er.responseError?.error?.message || 'Code is invalid';
                this.snackBarService.openAlert({ message, type: 'failure' })
                return of(
                  ValidateEmailVerificationCodeFailAction({ payload: message })
                );
              })
            )
        )
      ),
    { dispatch: true }
  );

  onSignupWithCredentialsAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SIGNUP_WITH_CREDENTIALS),
        map((action) => action.payload),
        exhaustMap((credential) =>
          from(this.signupWithCredentialsUseCase.execute(credential))
            .pipe(
              map((result) => {
                return SignupWithCredentialsSuccessAction();
              }),
              catchError((er: ErrorApiReponse<any>) => {
                const message = er.responseError?.error?.message || Localizations.alert_dialog.something_went_wrong;
                this.snackBarService.openAlert({ message, type: 'failure' })
                return of(
                  SignupWithCredentialsFailAction({ payload: message })
                );
              })
            )
        )
      ),
    { dispatch: true }
  );

  onSocialSignInActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          LOGIN_WITH_APPLE,
          LOGIN_WITH_GOOGLE,
          LOGIN_WITH_FACEBOOK,
        ),
        tap(() => {
          this.store$.dispatch(DesktopSocialSignInAction());
        })
      ),
    { dispatch: false }
  );

  onLoginWithGoogle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_WITH_GOOGLE),
        switchMap(() =>
          this.authRepository.LoginWithGoogle().pipe(
            delay(300), //  delay for sake of analytic event to be sent
            tap((url) => {
              this.platformService.openURL(url);
            }),
            catchError((er) => {
              const tokonizedError = handleSocialLoginErrors('Google');
              const payload = {
                payload:
                  er?.responseError?.error?.message?.error?.body ||
                  tokonizedError,
              };
              return of(SocialoLoginFailAction(payload));
            })
          )
        )
      ),
    { dispatch: false } // TODO to be verified
  );

  onLoginWithApple$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_WITH_APPLE),
        switchMap(() =>
          this.authRepository.LoginWithApple().pipe(
            delay(300), //  delay for sake of analytic event to be sent
            tap((url) => {
              this.platformService.openURL(url);
            }),
            catchError((er) => {
              const tokonizedError = handleSocialLoginErrors('Apple');
              const payload = {
                payload:
                  er?.responseError?.error?.message?.error?.body ||
                  tokonizedError,
              };
              return of(SocialoLoginFailAction(payload));
            })
          )
        )
      ),
    { dispatch: false } // TODO to be verified
  );

  onLoginWithFacebook$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_WITH_FACEBOOK),
        switchMap(() =>
          this.authRepository.LoginWithFacebook().pipe(
            delay(300), //  delay for sake of analytic event to be sent
            tap((url) => {
              this.platformService.openURL(url);
            }),
            catchError((er) => {
              const tokonizedError = handleSocialLoginErrors('Facebook');
              const payload = {
                payload:
                  er?.responseError?.error?.message?.error?.body ||
                  tokonizedError,
              };
              return of(SocialoLoginFailAction(payload));
            })
          )
        )
      ),
    { dispatch: false } // TODO to be verified
  );

  onLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          VALIDATE_EMAIL_VERIFICATION_CODE_SUCCESS,
          SIGNUP_WITH_CREDENTIALS_SUCCESS,
          LOGIN_WITH_CREDENTIAL_SUCCESS,
          CreateUserSuccessAction,
          SOCIAL_LOGIN_SUCESS,
        ),
        tap((_) => {
          this.database.init();
          this.router.navigateByUrl('/main');
          this.store$.dispatch(SendUserFingerprintAction());
        }),
        map(() => PreInitializeApplicationAction())
      ),
    { dispatch: true }
  );

  onSocialLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SOCIAL_LOGIN),
        map((action) => action.payload),
        switchMap((payload: SuccessLoginRes) =>
          from(this.loginWithTokensUseCase.execute(payload))
        ),
        map((tenant) => SocialLoginSucessAction({ payload: { tenant } }))),
    { dispatch: true }
  );

  onBillingTokenLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BILLING_TOKEN_LOGIN),
        map((action) => action.payload),
        switchMap((payload: SuccessLoginRes) =>
          from(this.loginWithTokensUseCase.execute(payload))
        ),
        tap(() => {
          !this.platformService.isMacOS && this.router.navigateByUrl('/main/settings/billing');
        }),
        map(() => PreInitializeApplicationAction())
      ),
    { dispatch: true }
  );


  onSocialAuthenticationFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SOCIAL_LOGIN_FAIL),
      tap(() => {
        this.router.navigate(['login'], { replaceUrl: true });
      })
    )
  );

  /* ----------------------------- SIGNUP EFFECTS ----------------------------- */
  onCheckIfEmailnotExistAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CHECK_IF_EMAIL_NOT_EXIST),
        map((action) => action.payload),
        switchMap((credential: EmailFieldDto) =>
          this.checkExistingEmailUseCase.execute(credential).pipe(
            map((item) => CheckIfEmailNotExistSuccessAction()),
            catchError((er: ErrorApiReponse<any>) => {
              let error = '';
              if (er?.statusCode == 400) {
                error = 'Email Already exist.';
              }
              return of(CheckIfEmailNotExistFailAction({ payload: error }));
            })
          )
        )
      ),
    { dispatch: true }
  );

  onCheckIfBusinessNamenotExistAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CHECK_IF_BUSINESS_NAME_NOT_EXIST),
        map((action) => {
          return action.payload;
        }),
        switchMap((credential: BusinessNameFieldDto) =>
          this.checkExistingBusinessNameUseCase.execute(credential).pipe(
            map((item) => CheckIfBusinessNameNotExistSuccessAction()),
            catchError((er: ErrorApiReponse<any>) => {
              let error = '';
              if (er?.statusCode == 400) {
                error = 'Sorry, this business name is already registered.';
              }

              return of(
                CheckIfBusinessNameNotExistFailAction({ payload: error })
              );
            })
          )
        )
      ),
    { dispatch: true }
  );

  onRequestEmailVerificationCodeAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(REQUEST_EMAIL_VERIFICATION_CODE),
        map((action) => action.payload),
        switchMap((credential: EmailVerificationCodeDto) =>
          this.requestEmailVerificationCodeUseCase.execute(credential).pipe(
            map((result: SuccessResponse) => {
              let message = result.results;
              return RequestEmailVerificationCodeSuccessAction({
                payload: message,
              });
            }),
            catchError((er: ErrorApiReponse<any>) => {
              let error = '';
              if (er?.statusCode == 400) {
                error =
                  er.responseError?.message || 'Operation was not possible';
              }
              return of(
                RequestEmailVerificationCodeFailAction({ payload: error })
              );
            })
          )
        )
      ),
    { dispatch: true }
  );

  onRequestEmailVerificationCodeSuccessAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(REQUEST_EMAIL_VERIFICATION_CODE_SUCCESS),
        concatLatestFrom(() => this.store$.select(selectSignUpState)),
        tap((state) => {
          this.router.navigateByUrl('/auth/email-verification').then(() => {
            this.toastService.showInfo(
              'Check your email for verification code'
            );
          });
        })
      ),
    { dispatch: false }
  );

  onEditSingupFormAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EDIT_SINGUP_FORM),
        tap((action) => {
          this.router.navigate(['/auth/signup'], {
            queryParams: { ...action.payload },
          });
        })
      ),
    { dispatch: false }
  );

  // onValidateEmailVerificationCodeSuccessAction$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(VALIDATE_EMAIL_VERIFICATION_CODE_SUCCESS),
  //     withLatestFrom(this.store$, (action, state) => state.singup),
  //     map((data) => {
  //       const payload: LoginCredentialsRequest = {
  //         password: data.data!.password!,
  //         user: data.data!.channel_value!,
  //       };
  //       return LoginWithCredentialsAction({ payload });
  //     })
  //   )
  // );

  /* ---------------------------- PASSWORD EFFECTS ---------------------------- */
  onPasswordResetSendAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PASSWORD_RESET_SEND),
        map((action) => action.payload),
        exhaustMap((data: PasswordResetDto) => {
          return this.authRepository.resetPassowrd(data).pipe(
            map((res) => {
              return LoginWithCredentialsAction({
                payload: {
                  password: data.new_password,
                  user: res.email,
                },
              });
            }),
            catchError((error: ErrorApiReponse<any>) => {
              let message = error.responseError?.error?.message || Localizations.alert_dialog.operation_not_possible;
              this.snackBarService.openAlert({
                message,
                type: 'failure'
              });
              return of(PasswordResetSendFailAction());
            })
          );
        })
      ),
    { dispatch: true }
  );


  onSocialSignInDeepLink$ = createEffect(
    () =>
      this.platformService.onDeepLink$.pipe(
        map((url: any) => url?.split('yobi-desktop://open').pop()),
        tap((deepLink: string) => {
          if (deepLink) {
            this.zone.run(() => {

              if (deepLink.includes('/signup?code=')) {
                let link = deepLink?.replace('/signup?code=', '')?.replace('%20', '+');
                const [code, email] = link.split('&email=');
                code &&
                  email &&
                  this.store$.dispatch(
                    ValidateEmailVerificationCodeAction({
                      payload: { channel_type: ChannelType.Email, channel_value: email, code },
                    })
                  );
              }
              if (deepLink.includes('/login?code=')) {
                let link = deepLink?.replace('/login?code=', '')?.replace('%20', '+');
                const [code, email] = link.split('&email=');
                code &&
                  email &&
                  this.store$.dispatch(
                    ValidateEmailVerificationCodeAction({
                      payload: { channel_type: ChannelType.Email, channel_value: email, code },
                    })
                  );
              }

              if (deepLink.includes('google_signin')) {
                let primaryLink = deepLink?.split('#');
                let link = primaryLink[0]
                  ?.replace('%20', '+')
                  ?.replace('%26', '&')
                  ?.replace('%3D', '=')
                  ?.split('google_signin=');
                const [yib_tk, yib_rt] = link[1].split('&refresh_token=');
                yib_tk &&
                  yib_rt &&
                  this.store$.dispatch(
                    SocialoLoginAction({ payload: { yib_rt, yib_tk } })
                  );
              }

              if (deepLink.includes('facebook_login')) {
                let primaryLink = deepLink?.split('#');
                let link = primaryLink[0]
                  ?.replace('%20', '+')
                  ?.replace('%26', '&')
                  ?.replace('%3D', '=')
                  ?.split('facebook_login=');
                const [yib_tk, yib_rt] = link[1].split('&refresh_token=');
                yib_tk &&
                  yib_rt &&
                  this.store$.dispatch(
                    SocialoLoginAction({ payload: { yib_rt, yib_tk } })
                  );
              }

              if (deepLink.includes('apple_signin')) {
                let primaryLink = deepLink?.split('#');
                let link = primaryLink[0]
                  ?.replace('%20', '+')
                  ?.replace('%26', '&')
                  ?.replace('%3D', '=')
                  ?.split('apple_signin=');
                const [yib_tk, yib_rt] = link[1].split('&refresh_token=');
                yib_tk &&
                  yib_rt &&
                  this.store$.dispatch(
                    SocialoLoginAction({ payload: { yib_rt, yib_tk } })
                  );
              }
              if (deepLink.includes('notifications_settings')) { }
              if (deepLink.includes('login_failed')) { }
              if (deepLink.includes('conversation')) { }
              if (deepLink.includes('task_id')) { }
            });
          }
        })
      ),
    {
      dispatch: false,
    }
  );

}
