import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { from, map, Observable, pluck, take } from 'rxjs';
import { DatabaseService } from '../services/Database/database.service';
import { FullTextSearchQueryDto } from '../DTO/FullTextSearachDto';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';
import { IFullTextSearachRepository } from '@/app/core/IRepositories/IFullTextSearchRepository';
import {
  FullTextSearchResult,
  LocalFullTextSearch,
} from '@/app/core/Models/fullTextSearch';
import { liveQuery } from 'dexie';

@Injectable({
  providedIn: 'root',
})
export class FullTextSearachRepository implements IFullTextSearachRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) {}

  getFullTextSearch({
    query,
    type,
    page,
    page_size,
  }: FullTextSearchQueryDto): Observable<FullTextSearchResult> {
    const requestURL = `${environment.apiURL}search-v2?page=${
      page ?? 1
    }&page_size=${page_size ?? 10}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { query, type },
      false
    );
    return this.httpService.execute(options, false).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<FullTextSearchResult>;
        return res.results;
      })
    );
  }

  saveFullTextSearchResult(data: LocalFullTextSearch) {
    this.databaseService.transaction(
      'rw',
      this.databaseService.fullTextSearch,
      async () => {
        const interval = 86400000; // 24 * 3600 * 1000, 1 day in ms
        let results = await this.databaseService.fullTextSearch.toArray()
        const oldResults = results
          .filter(result => result.date.getTime() + interval < new Date().getTime())
          .map(r => r.value)
        await this.databaseService.fullTextSearch.bulkDelete(oldResults);
        this.databaseService.fullTextSearch.put(data);
      }
    );
  }

  deleteLocalFullTextSearchResult(value: string): void {
    this.databaseService.fullTextSearch.delete(value);
  }

  getLocalFullTextSearch(value: string): Observable<LocalFullTextSearch | undefined> {
    this.databaseService.fullTextSearch.mapToClass(LocalFullTextSearch);
    return from(
      liveQuery(() => this.databaseService.fullTextSearch.get(value))
    ).pipe(take(1));
  }

  getAllLocalFullTextSearch(): Observable<LocalFullTextSearch[]> {
    return from(
      liveQuery(() => {
        const interval = 86400000; // 24 * 3600 * 1000, 1 day in ms
        this.databaseService.fullTextSearch.mapToClass(LocalFullTextSearch);
        return this.databaseService.fullTextSearch
        .filter(result => result.date.getTime() + interval > new Date().getTime())
        .reverse()
        .sortBy('date')
      })
    );
  }
}
