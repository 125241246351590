import { ProductsQueryParams, Product } from '@/app/core/Models/product';
import { CreateProductDto, UpdateProductRequest } from '@/app/Data/DTO/productDto';
import { createAction, props, union } from '@ngrx/store';

export const GET_PRODUCTS_LIST = '[Products] Get Products List';
export const GetProductsListAction = createAction(
  GET_PRODUCTS_LIST,
  props<{ payload: ProductsQueryParams }>()
);

export const GET_PRODUCT_LIST_SUCCESS = '[Products] Get Products List Success';
export const GetProductsListSuccessAction = createAction(
  GET_PRODUCT_LIST_SUCCESS,
  props<{ payload: { params: ProductsQueryParams, products: Product[] } }>()
);

export const GET_PRODUCT_LIST_FAIL = '[Products] Get Products List Fail';
export const GetProductsListFailAction = createAction(
  GET_PRODUCT_LIST_FAIL);

// export const LOOKUP_PRODUCT = '[Products] lookup products action';
// export const LookupProductsAction = createAction(
//   LOOKUP_PRODUCT,
//   props<{ payload: ProductsLookupDto }>()
// );

// export const LOOKUP_PRODUCT_SUCCESS = '[Products] lookup products success action';
// export const LookupProductsSuccessAction = createAction(
//   LOOKUP_PRODUCT_SUCCESS,
//   props<{ payload: LookupProduct[] }>()
// );

export const SHOW_PRODUCT_PAGE = '[Products] Show Product Page';
export const ShowProductPageAction = createAction(
  SHOW_PRODUCT_PAGE,
  props<{ payload: Product }>()
);

export const SET_ACTIVE_PRODUCT_ID = '[Products] set active product id';
export const SetActiveProductId = createAction(
  SET_ACTIVE_PRODUCT_ID,
  props<{ payload: { productId: string } }>()
);

export const RESET_PRODUCT_STATE = '[PRODUCT] Reset product State';
export const ResetProductStateAction = createAction(
  RESET_PRODUCT_STATE);

/* ----------------------------- create product ----------------------------- */
export const PRODUCT_CREATE = '[PRODUCT] Product create';
export const ProductCreateAction = createAction(
  PRODUCT_CREATE,
  props<{ payload: { data: CreateProductDto, dialogId?: string } }>()
);

export const PRODUCT_CREATE_SUCCESS = '[PRODUCT] Product create success';
export const ProductCreateSuccessAction = createAction(
  PRODUCT_CREATE_SUCCESS
);

export const PRODUCT_CREATE_FAIL = '[PRODUCT] Product create Fail';
export const ProductCreateFailAction = createAction(
  PRODUCT_CREATE_FAIL
);

/* ----------------------------- create product ----------------------------- */
export const PRODUCT_UPDATE = '[PRODUCT] Product update';
export const ProductUpdateAction = createAction(
  PRODUCT_UPDATE,
  props<{ payload: { request: UpdateProductRequest, dialogId?: string } }>()
);

export const PRODUCT_UPDATE_SUCCESS = '[PRODUCT] Product update success';
export const ProductUpdateSuccessAction = createAction(
  PRODUCT_UPDATE_SUCCESS
);

export const PRODUCT_UPDATE_FAIL = '[PRODUCT] Product update Fail';
export const ProductUpdateFailAction = createAction(
  PRODUCT_UPDATE_FAIL
);

/* ------------------------------ fetch product ----------------------------- */
export const FETCH_PRODUCT_BY_ID = '[PRODUCT] Fetch Product By Id';
export const FetchProductByIdAction = createAction(
  FETCH_PRODUCT_BY_ID,
  props<{ payload: string }>()
);

export const FETCH_PRODUCT_BY_ID_SUCCESS = '[PRODUCT] Fetch Product By Id Success';
export const FetchProductByIdSuccessAction = createAction(
  FETCH_PRODUCT_BY_ID_SUCCESS,
  props<{ payload: Product }>()
);

export const FETCH_PRODUCT_BY_ID_FAILED = '[PRODUCT] Fetch Product By Id Failed';
export const FetchProductByIdFailedAction = createAction(
  FETCH_PRODUCT_BY_ID_FAILED,
  props<{ payload: { status: number } }>()
);

/* ----------------------------- delete product ----------------------------- */
export const DELETE_PRODUCT = '[PRODUCTS] Delete Product';
export const DeleteProductAction = createAction(
  DELETE_PRODUCT,
  props<{ payload: string[] }>()
);
export const DELETE_PRODUCT_SUCCESS = '[PRODUCTS] Product Successfully Deleted';
export const ProductDeleteSuccessAction = createAction(
  DELETE_PRODUCT_SUCCESS,
  props<{ payload: string[] }>()
);
export const DELETE_PRODUCT_FAIL = '[PRODUCTS] Failed To Delete Product';
export const ProductDeleteFailAction = createAction(
  DELETE_PRODUCT_FAIL,
  props<{ payload: string }>()
);

export const RESET_PRODUCTS_STATE = '[PRODUCTS] Reset Products State';
export const ResetProductsStateAction = createAction(
  RESET_PRODUCTS_STATE
);


const all = union({
  ProductCreateAction,
  ProductUpdateAction,
  GetProductsListAction,
  GetProductsListSuccessAction,
  GetProductsListFailAction,
  // LookupProductsAction,
  // LookupProductsSuccessAction,
  ShowProductPageAction,
  SetActiveProductId,
  ResetProductStateAction,
  ProductCreateSuccessAction,
  FetchProductByIdAction,
  FetchProductByIdSuccessAction,
  FetchProductByIdFailedAction,
  DeleteProductAction,
  ProductDeleteSuccessAction,
  ProductDeleteFailAction,
});
export type ProductsActions = typeof all;