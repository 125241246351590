import { PasswordValidator } from '@/app/Utilities/Validators/pasword-validator';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { AuthViewModel } from '../../ViewModels/authViewModel';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';
import { CreateUserDto } from '@/app/Data/DTO/CreateUserDto';
import { extractQueryParams } from '@/app/Utilities/helpers';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss'],
})
export class InviteUserComponent implements OnInit, OnDestroy {
  passwordRestrictions = false
  infomationForm: FormGroup;
  private subs = new SubSink();
  localization = this.localizationViewModel.localization;
  singUpState$ = this.authViewModel.singUpState$;
  inviteParams: { code: string, account: string, business_name: string }

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private authViewModel: AuthViewModel,
    private localizationViewModel: LocalizationViewModel,
    private router: Router
  ) {
    this.inviteParams = extractQueryParams(router.url.replace('%20', '+').replace('%2B', '+').replace('%26', '&').replace('%3D', '=').split('?')[1]) as any
    if (!this.inviteParams.account || !this.inviteParams.code) {
      this.router.navigate(['/auth/signup']);
    }
  }

  ngOnInit(): void {
    this.createForms();
  }

  createForms() {
    this.infomationForm = this.fb.group({
      firstname: new FormControl('', [Validators.required, Validators.minLength(2)],),
      lastname: new FormControl('', [Validators.required, Validators.minLength(2)]),
      email: new FormControl(this.inviteParams.account),
      password: new FormControl('', [Validators.required, PasswordValidator(),])
    });
  }

  onFormSubmit(): void {
    this.infomationForm.markAllAsTouched();
    if (!this.infomationForm.valid) return
    this.authViewModel.singUpState$.pipe(take(1)).subscribe(state => {
      if (!state.loading) {
        if (this.infomationForm.valid) {
          const values = this.infomationForm.value
          this.authViewModel.createUser({
            firstname: values.firstname,
            lastname: values.lastname,
            email: this.inviteParams.account,
            token: this.inviteParams.code,
            password: values.password
          } as CreateUserDto)
        }
      }
    })
  }

  openPrivacyPolicy(): void {
    this.authViewModel.openPrivacyPolicy();
  }

  openTermsAndConditions(): void {
    this.authViewModel.openTermsAndConditions();
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
