import { MentionMember } from "@/app/core/Models/Mention";
import { Observer } from "rxjs";

export class TeamMessageBody {
    uploadProgress$: Observer<any>

    constructor(
        private receiver_id: number,
        private data: string,
        private is_room: boolean,
        private type: 'message',
        private timeZone: string,
        public msg_id: number,
        private user_id: number,
        private _attachments: { file?: File, type: string, url: string, name?: string }[] = [],
        private participants_ids: string[]
    ) { }

    get attachments() {
        return this._attachments
    }

    get hasAttachments() {
        return !!this.attachments.length
    }

    getBodyAttachment(): FormData {
        const form = new FormData();
        this.attachments
            .filter((file) => !!file.file)
            .forEach((attachment) => {
                form.append(
                    attachment.file!.type.startsWith('image/') ? 'image'
                        : attachment.file!.type.startsWith('video/') ? 'video'
                            : attachment.file!.type.startsWith('audio/') ? 'audio'
                                : 'file',
                    attachment.file!, attachment.file!.name || ''
                );
            });
        return form;
    }

    getBody(): TeamChatMessageDto {
        return {
            receiver_id: this.receiver_id,
            data: this.data,
            is_room: this.is_room,
            type: this.type,
            msg_id: this.msg_id,
            user_id: this.user_id,
            timeZone: this.timeZone,
            attachments: this.attachments.map(({ type, url, name }) => ({ type, url, name })),
            participants_ids: this.participants_ids
        };
    }
}

export interface TeamChatMessageDto {
    receiver_id: number,
    data: string,
    is_room: boolean,
    type: 'message',
    msg_id?: number
    user_id?: number,
    timeZone: string,
    attachments: {
        type: string, url: string, name?: string
    }[],
    participants_ids: string[]
}

export interface InputCreateRoom {
    room_name: string,
    private: boolean,
    participants_ids?: string[]
}

export interface ICreateRoomResponse {
    private: boolean;
    room_name: string;
    participants_ids: string[]
    room_id: string;
}

export interface IParticipantResponse {
    added_dt: string
    avatar: string
    family_name: string
    given_name: string
    is_creator: boolean
    user_id: any
    username: string
}

export interface IRoomAvatarResponseDto {
    room_id: string;
    updated_dt: string;
    avatar: string;
}

export interface IRoomResponse {
    created_dt: string;
    participants: IParticipantResponse[]
    private: boolean
    room_id: string
    room_name: string
    tenant_id: string
    updated_dt: string
    avatar: string
}
