import { Observable } from 'rxjs';
import { ContactChannel } from '../Models/Channel';
import { SelectedPipeline } from '../Models/company';
import { Contact, ContactsLookupDto, ContactsQueryParams, LookupContact } from '../Models/contact';

export abstract class IContactsRepository {
  abstract getContacts(params: ContactsQueryParams): Observable<Contact[]>;
  abstract lookupContacts(request: ContactsLookupDto): Observable<LookupContact[]>;
  abstract saveContacts(contacts: Contact[], params: ContactsQueryParams): void;
  abstract getLocalContacts(): Observable<Contact[]>;
  abstract mergeContact(id: String, mergedId: string): Observable<Contact>;
  abstract getContactPipelines(contact_id: string, pipeline_id: number): any
  abstract selectContactStage(payload: Array<{ contact_id: number, yobi_crm_pipeline_id: number, current_stage_id: number, description: string }>): Observable<any>
  abstract getAllPipelinesContacts(contact_id: string): Observable<SelectedPipeline[]>

}
