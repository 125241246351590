<div class="flex flex-col gap-1">
  <label
    *ngIf="label"
    [for]="label"
    class="font-semibold text-pale"
  >{{ label }}</label>

  <div
    class="flex flew-row items-center focus-within:border-primary border-2  px-4 rounded-md secondary-border-color"
    [class.border-warning]="invalid || !isValid"
  >
    <input
      #passwordInput
      [autocomplete]="autocomplete ? '' : 'new-password'"
      [type]="inputType"
      [name]="'new password' + label"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [(ngModel)]="value"
      [disabled]="disabled"
      (input)="onInputChange($event)"
      [placeholder]="placeholder"
      class="text-dark ring-transparent border-0 bg-transparent w-full focus:border-0 focus:ring-transparent h-12 px-0"
    />
    <svg-icon
      *ngIf="!hidden"
      class="cursor-pointer text-mild hover:text-primary"
      src="assets/icons/new-set/eye-off.svg"
      (click)="toggleVisibility($event)"
    >
    </svg-icon>
    <svg-icon
      *ngIf="hidden"
      class="cursor-pointer text-mild hover:text-primary"
      src="assets/icons/new-set/eye.svg"
      (click)="toggleVisibility($event)"
    >
    </svg-icon>
  </div>
</div>