import { TaskLog } from '@/app/core/Models/Task';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-logs',
  templateUrl: './task-logs.component.html',
  styleUrls: ['./task-logs.component.scss'],
})
export class TaskLogsComponent implements OnInit {
  @Input() log: TaskLog;

  constructor() {}

  ngOnInit(): void {}
}
