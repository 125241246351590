import { Roles } from '@/app/core/Models/Roles';
import {
  NotificationSettings,
  Tenant,
  TenantAccount,
} from '@/app/core/Models/tenant';
import { Channel } from '@/app/core/Models/Channel';

export class TenantObjectResponseDTO {
  account: {
    email: {
      channel_value?: string,
      validation: boolean;
    };
    is_updated: boolean;
    phone: {
      channel_value?: string;
      validation: boolean;
    };
  };
  beta_access: boolean;
  business_name: string;
  onboarding_questions_answered: boolean;
  VOICE_ONBOARDING_COMPLETED: boolean;
  JOTFORM_SUBMISSION_ID: string;
  is_integrated_with_nexhealth: boolean;
  industry?: string;
  has_outbound_call_enabled_agent: boolean;
  user: {
    avatar: string;
    channels: ChannelDTO[];
    data: {
      yobi_tenant_id: number;
      yobi_user_id: string;
    };
    do_not_disturb: boolean;
    email: string;
    firstName: string;
    lastName: string;
    id: string;
    notifications_settings: {
      comment: boolean;
      missed_call: boolean;
      notification_id: number;
      task: boolean;
      tenant_id: number;
      transcription: boolean;
      user_id: number;
      voice_mail: boolean;
    };
    onboarding: {
      addchannel: boolean;
      addcontacts: boolean;
      addusers: boolean;
      buyphone: boolean;
    };
    passwordChangeRequired: boolean;
    passwordLastUpdateInstant: number;
    roles: string[];
    tenantId: number;
    tenant_logo: string;
    tenant_created_dt: Date;
    username: string;
    verified: boolean;
    yobi_support_contact_id: number;
    user_permissions: Array<{ permission_name: string, permission_value: boolean }>
    
  };

  public static mapToDomain(object: TenantObjectResponseDTO): Tenant {
    let tenant = new Tenant();
    tenant.beta_access = object.beta_access;
    tenant.active = true;
    tenant.businessName = object.business_name;
    tenant.onboarding_questions_answered = object.onboarding_questions_answered;
    // tenant.VOICE_ONBOARDING_COMPLETED = object.VOICE_ONBOARDING_COMPLETED;
    tenant.VOICE_ONBOARDING_COMPLETED = true;
    tenant.JOTFORM_SUBMISSION_ID = object.JOTFORM_SUBMISSION_ID;
    tenant.account = object.account as TenantAccount;
    tenant.avatar = object.user.avatar;
    tenant.channels = object.user.channels;
    tenant.email = object.user.email;
    tenant.userId = object.user.data.yobi_user_id as any;
    tenant.firstName = object.user.firstName;
    tenant.lastName = object.user.lastName;
    tenant.notificationsSettings = object.user.notifications_settings as NotificationSettings;
    tenant.roles = object.user.roles.map((item) =>
      item == 'admin' ? Roles.admin : Roles.user
    );
    tenant.tenantId = object.user.tenantId;
    tenant.tenantLogo = object.user.tenant_logo;
    tenant.username = object.user.username;
    tenant.doNotDisturb = object.user.do_not_disturb;
    tenant.verified = object.user.verified;
    tenant.supportContactId = object.user.yobi_support_contact_id;
    tenant.tenant_created_dt = new Date(object.user.tenant_created_dt);
    tenant.user_permissions = object.user.user_permissions;
    tenant.is_integrated_with_nexhealth = object.is_integrated_with_nexhealth;
    tenant.industry = object.industry;
    tenant.has_outbound_call_enabled_agent = object.has_outbound_call_enabled_agent;
    return tenant;
  }
}

export class ChannelDTO extends Channel {
  first_active_dt: string;
  is_record_disabled: boolean;
  last_active_dt: string;
  text_enabled: true;
  updated_dt: string;
  user_id: number;
  username: string;
  video_enabled: boolean;
  voice_enabled: boolean;
}
