import { MatDialog } from '@angular/material/dialog';
import { startWith } from 'rxjs/operators';
import { PasswordResetDto } from './../../Data/DTO/PasswordResetDto';
import { PlatformService } from './../../Utilities/platform/platform.service';

import { EmailVerificationCodeDto, lightwightSignupDto } from '@/app/Data/DTO/EmailVerificationCodeDto';
import { VerificationCodeDto } from '@/app/Data/DTO/VerificationCodeDto';
import { BusinessNameFieldDto, EmailFieldDto } from '@/app/Data/DTO/authDto';
import { AppState } from '@/app/State/AppState';
import {
  CheckIfBusinessNameNotExistAction,
  CheckIfEmailNotExistAction,
  CreateUserAction,
  EditSingupFormAction,
  LoginWithAppleAction,
  LoginWithCredentialsAction,
  LoginWithFacebookAction,
  LoginWithGoogleAction,
  PasswordResetSendAction,
  RequestEmailVerificationCodeAction,
  SignupWithCredentialsAction,
  ValidateEmailVerificationCodeAction,
  authorizeAddPassword,
} from '@/app/State/Auth/action';
import {
  selectAddPasswordState,
  selectEmailVerificationState,
  selectLoginState,
  selectSignUpState,
} from '@/app/State/Auth/selector';
import { TrackEventAction } from '@/app/State/analytics/analytics.action';
import { selectPhoneVerificationLoadingState } from '@/app/State/phoneRequest/phoneRequest.selector';
import { LoginCredentialsRequest } from '@/app/core/Models/LoginCredentials';
import { AnalyticsEventsNames, SignupMethod } from '@/app/shared/analytics/analyticsEvents';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TermsPrivacyComponent } from '../Registration/Signup/terms-privacy/terms-privacy.component';
import { Router } from '@angular/router';
import { environment } from '@/environments/environment.prod';
import { CreateUserDto } from '@/app/Data/DTO/CreateUserDto';

@Injectable({
  providedIn: 'root',
})
export class AuthViewModel {
  readonly authState$ = this.store.select(selectLoginState);
  readonly phoneVerficationLoading$ = this.store
    .select(selectPhoneVerificationLoadingState)
    .pipe(startWith(false));
  readonly singUpState$ = this.store.select(selectSignUpState);
  readonly emailVerificationState$ = this.store.select(
    selectEmailVerificationState
  );
  readonly addPasswordState$ = this.store.select(selectAddPasswordState);

  constructor(
    private store: Store<AppState>,
    private platformService: PlatformService,
    private matDialog: MatDialog,
    private router: Router
  ) { }

  loginWithCredential(user: string, password: string): void {
    let data: LoginCredentialsRequest = { user: user, password: password };
    this.trackUsername(user);
    this.store.dispatch(LoginWithCredentialsAction({ payload: data }));
  }

  trackUsername(username: string): void {
    this.store.dispatch(
      TrackEventAction({
        payload: {
          event: {
            name: AnalyticsEventsNames.LOGIN_STARTED,
            properties: {
              $email: username,
              login_method: 'E-mail',
            }
          }
        }
      })
    );
  }

  loginWithGoogle(signup = false) {
    signup &&
      this.store.dispatch(
        TrackEventAction({
          payload: {
            event: {
              name: AnalyticsEventsNames.SIGNUP_STARTED,
              properties: {
                registration_method: SignupMethod.GOOGLE
              }
            }
          }
        })
      ) ||
      this.store.dispatch(
        TrackEventAction({
          payload: {
            event: {
              name: AnalyticsEventsNames.LOGIN_STARTED,
              properties: {
                login_method: 'Google',
              }
            }
          }
        })
      );
    this.store.dispatch(LoginWithGoogleAction());
  }

  loginWithApple(signup = false) {
    signup &&
      this.store.dispatch(
        TrackEventAction({
          payload: {
            event: {
              name: AnalyticsEventsNames.SIGNUP_STARTED,
              properties: {
                registration_method: SignupMethod.APPLE
              }
            }
          }
        })
      ) ||
      this.store.dispatch(
        TrackEventAction({
          payload: {
            event: {
              name: AnalyticsEventsNames.LOGIN_STARTED,
              properties: {
                login_method: 'Apple',
              }
            }
          }
        })
      );
    this.store.dispatch(LoginWithAppleAction());
  }

  loginWithFacebook(signup: boolean = false) {
    signup &&
      this.store.dispatch(
        TrackEventAction({
          payload: {
            event: {
              name: AnalyticsEventsNames.SIGNUP_STARTED,
              properties: {
                registration_method: SignupMethod.FACEBOOK
              }
            }
          }
        })
      ) ||
      this.store.dispatch(
        TrackEventAction({
          payload: {
            event: {
              name: AnalyticsEventsNames.LOGIN_STARTED,
              properties: {
                login_method: 'Facebook',
              }
            }
          }
        })
      );
    this.store.dispatch(LoginWithFacebookAction());
  }

  checkIfEmailExist(email: string) {
    const emailField = new EmailFieldDto(email);
    this.store.dispatch(CheckIfEmailNotExistAction({ payload: emailField }));
  }

  checkIfBusinessNameExist(name: string) {
    const businessField = new BusinessNameFieldDto(name);
    this.store.dispatch(
      CheckIfBusinessNameNotExistAction({ payload: businessField })
    );
  }

  createUser(request: CreateUserDto) {
    this.store.dispatch(CreateUserAction({ payload: request }));
  }

  requestEmailVerificationCode(request: EmailVerificationCodeDto) {
    this.store.dispatch(
      TrackEventAction({
        payload: {
          event: {
            name: AnalyticsEventsNames.SIGNUP_STARTED,
            properties: {
              $email: request.channel_value,
              registration_method: SignupMethod.EMAIL
            }
          }
        }
      })
    )
    this.store.dispatch(RequestEmailVerificationCodeAction({ payload: request }));
  }

  signupWithCredentials(request: lightwightSignupDto) {
    this.store.dispatch(
      TrackEventAction({
        payload: {
          event: {
            name: AnalyticsEventsNames.SIGNUP_STARTED,
            properties: {
              $email: request.channel_value,
              registration_method: SignupMethod.EMAIL
            }
          }
        }
      })
    )
    this.store.dispatch(SignupWithCredentialsAction({ payload: request }));
  }

  validateEmailVerificationCode(request: VerificationCodeDto) {
    this.store.dispatch(
      ValidateEmailVerificationCodeAction({ payload: request })
    );
  }

  editSignupForm(form: EmailVerificationCodeDto): void {
    this.store.dispatch(EditSingupFormAction({ payload: form }));
  }

  authorizeAddPassword(value: boolean): void {
    this.store.dispatch(authorizeAddPassword({ payload: value }));
  }

  resetPassword(request: PasswordResetDto): void {
    this.store.dispatch(PasswordResetSendAction({ payload: request }));
  }


  openPrivacyPolicy(): void {
    if (!this.platformService.isElectronApp) {
      this.platformService.openURL('https://yobi.app/privacy-policy/', '_blank')
    } else {
      this.matDialog.open(TermsPrivacyComponent, {
        height: '70%',
        width: ' 70vw',
        maxWidth: '80vw',
        data: {
          isTOF: false
        }
      });
    }
  }

  openTermsAndConditions(): void {
    if (!this.platformService.isElectronApp) {
      this.platformService.openURL('https://yobi.app/terms-of-service/', '_blank')
    } else {
      this.matDialog.open(TermsPrivacyComponent, {
        height: '70%',
        width: '50vw',
        data: {
          isTOF: true
        }
      });
    }
  }

  signup(): void {
    this.router.navigateByUrl('/auth/signup');
  }
}
