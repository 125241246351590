import { PaymentMethodDto } from "@/app/Data/DTO/PaymentMethodDto";

export enum PlanName {
    pro = 'Pro',
    free = 'Free',
    trial = 'Trial',
    starter = 'Starter',
    enterprise = 'Enterprise',
    trial_extended = 'Trial Extended',
    essentials = 'Essentials',
    unpaid = 'Unpaid',
    AIReceptionistPMS = 'AIReceptionistwithPMSIntegration',
    AIReceptionist = 'AIReceptionist',
    CustomPlan_AIReceptionist = 'CustomPlan-AIReceptionist'
}
export class SubscriptionFeature {
    current_usage: number
    feature_id: number
    has_usage_limit: boolean
    last_usage_dt: string
    price: number
    sub_detail_id: number
    sub_id: number
    usage_count: number
    usage_frequency: string
}




export class BillingSubscription {
    active_from: string;
    cancelation_reason?: string
    created_dt: string
    plan_name: PlanName
    features: SubscriptionFeature[]
    is_fully_restricted: boolean
    group_id: number
    improve?: any
    subscription_id: number;
    plan_id: number;
    recurring: RecurringType.types;
    status: 'active' | 'incomplete' | 'canceling' | 'past_due';
    is_custom: boolean;
    is_active: boolean;
    is_trial: boolean;
    is_canceled: boolean;
    activeFrom: Date;
    active_to: Date;
    plan_base_price: number;
    plan_base_price_discount?: number;
    users_count: number;
    base_price_per_user: boolean;
    subscription_amount: number;
    subscription_amount_discount?: number;
    percentage?: number
    plan: {
        base_price_per_user: boolean,
        created_dt: string
        default_plan: boolean
        description: string
        external_plan_id?: any
        is_active: boolean
        is_custom: boolean
        is_most_popular: number
        plan_base_price: number
        plan_id: number
        plan_name: string
        recurring: string
        trial_days: number
        updated_dt: string
    }

    get subscriptionName(): string {
        return !this.subscription_amount ? this.plan_name : `${this.plan_name} ${RecurringType.toAdjective(this.recurring)}`
    }

    get paidPlan() {
        return [
            PlanName.pro,
            PlanName.starter,
            PlanName.enterprise,
            PlanName.essentials
        ].includes(this.plan_name)
    }

}

export class PaymentMethod implements PaymentMethodDto {
    last4_digits: string;
    year_exp: number;
    month_exp: number;
    card_type: string;
    created_dt: Date;
    card_token: string;
    pay_method_id: number;
    cardholder: string;

    country: string;
    state?: string | null;
    city?: string | null;
    line1?: string | null;
    line2?: string | null;
    zipcode?: string | null;

    public get isValid(): boolean {
        const currentDate = new Date();
        return this.year_exp > currentDate.getFullYear()
            || (this.year_exp === currentDate.getFullYear() && this.month_exp >= currentDate.getMonth());
    }
}

export class PromoCode {
    coupon_name: string;
    created_at: string;
    duration: 'once';
    external_coupon_id: string;
    external_promotion_id: string;
    is_valid: true;
    percentage: number;
    promotion_code: string;
    promotion_id: number;
    redeem_by: string;
    times_redeemed: number;
    updated_at: string
}

export namespace RecurringType {
    export enum types {
        MONTH = 'month',
        YEAR = 'year',
        EVER = 'ever',
    }
    export function fromString(value: string): types {
        return value === 'month' ? types.MONTH
            : value === 'year' ? types.YEAR
                : types.EVER
    }

    export function toString(type: types): string {
        return type == types.YEAR ? 'year'
            : type == types.MONTH ? 'month'
                : 'ever'
    }

    export function toAdjective(type: types): string {
        return type == types.YEAR ? 'Yearly'
            : type == types.MONTH ? 'Monthly'
                : ''
    }
}
