import { PlatformService } from '@/app/Utilities/platform/platform.service';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { PasswordInputComponent } from '../../components/new-password-input/password-input.component';
import { AuthViewModel } from '../../ViewModels/authViewModel';
import { LocalizationViewModel } from './../../ViewModels/localizationViewModel';
import { SentryViewModel } from './../../ViewModels/sentryViewModel';
import { MatDialog } from '@angular/material/dialog';
import { WaitingListComponent } from '../Signup/waiting-list/waiting-list.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('username') loginFeld: ElementRef;
  @ViewChild('password') passwortFeld: PasswordInputComponent
  authState$ = this.authViewModel.authState$;
  localization = this.localizationViewModel.localization;
  authLocalization: typeof this.localizationViewModel.localization.auth = this.localizationViewModel.localization.auth
  form: FormGroup = this.fb.group({
    user: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  constructor(
    private sentryViewModel: SentryViewModel,
    private dialog: MatDialog,
    private localizationViewModel: LocalizationViewModel,
    private rouer: Router,
    private fb: FormBuilder,
    private platformService: PlatformService,
    @Inject(AuthViewModel) public authViewModel: AuthViewModel
  ) { }

  login() {
    this.form.markAllAsTouched()
    if (!this.form.valid) return;
    this.authState$.pipe(take(1)).subscribe((state) => {
      !state.loading &&
        this.authViewModel.loginWithCredential(
          this.form.get('user')?.value,
          this.form.get('password')?.value
        );
    })
  }

  googleLogin() {
    this.authViewModel.loginWithGoogle();
  }

  appleLogin() {
    this.authViewModel.loginWithApple();
  }

  facebookLogin() {
    this.authViewModel.loginWithFacebook();
  }

  ngOnInit(): void {
    this.sentryViewModel.clearUser();
  }

  showSingup(): void {
     this.authViewModel.signup();
  }

  openWaitingList(): void {
    this.dialog.open(WaitingListComponent, {
      height: '80%',
      width: '70vw',
      minWidth: '650px'
    });
   }

  resetPassword(): void {
    const email = this.form.get('user')?.value ?? '';
    this.rouer.navigate(['auth/reset-password'], {
      queryParams: { email },
    });
  }

  get isMacApp() {
    return this.platformService.isMacOS
  }

  get isWindows() {
    return this.platformService.isWindows
  }
}
