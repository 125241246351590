import { ILocalStorageService } from '@/app/Data/Adapters/ILocalStorageService';
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '@/environments/environment';
@Injectable()
export class AuthGuard implements CanLoad, CanActivate {
  constructor(
    @Inject(ILocalStorageService) private localStorage: ILocalStorageService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const isLoggedIn = !!this.localStorage.getItem('yib_tk');
    if (!isLoggedIn) {
      this.router.navigate(['/auth/login']);
    }
    return isLoggedIn;
  }
  // fro lazy loading modules
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const isLoggedIn = !!this.localStorage.getItem('yib_tk');
    if (!isLoggedIn) {
      this.router.navigate(['/auth/login']);
    }
    return isLoggedIn;
  }
}
