import { TasksState } from './reducer';
import { AppState } from '@/app/State/AppState';
import { createSelector } from '@ngrx/store';

const tasksState = (state: AppState) => state.tasks;

export const selectTasksIsFetching = createSelector(
  tasksState,
  (state: TasksState) => state.fetching
);

export const selectTasksFilter = createSelector(
  tasksState,
  (state: TasksState) => state.filter
);
export const selectTasksActiveStatus = createSelector(
  tasksState,
  (state: TasksState) => state.activeTasksStatus
);

export const selectTasksState = createSelector(
  tasksState,
  (state: TasksState) => state
);
