import { AppState } from '@/app/State/AppState';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotificationsSettingDto } from '@/app/Data/DTO/NotificationsSettingDto';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { DismissAllNotificationsAction, GetNotificationsAction, GetUnreadNotificationsCountAction, UpdateNotificationAsReadAction } from '@/app/State/Notifications/notifications.action';
import { selectNotifications, selectNotificationsState, selectUnreadCount } from '@/app/State/Notifications/notifications.selector';
import { selectTenantProfile } from '@/app/State/Tenant/tenant.selector';
import { INotificationRepository } from '@/app/core/IRepositories/INotificationRepository';
import { Notification, NotificationType } from '@/app/core/Models/Notification';
import { UpdateNotificationsSettingUseCase } from '@/app/core/usecases/Notification/UpdateNotificationsSettingUseCase';
import { Observable, of, shareReplay, switchMap, take } from 'rxjs';
import { TaskDetailsComponent } from '../tasks/task-details/task-details.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TeamChatViewModel } from './TeamChatViewModel';
import { RightPanelIndexes, SetRightPanelActiveWindowAction } from '@/app/State/mediaObserver/media.observer.action';
import { TaskViewModel } from './taskViewModel';
import { DrawerService } from '../components/Drawer/drawer.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsViewModel {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private dialog: MatDialog,
    private notificationRepository: INotificationRepository,
    private updateNotificationsSettingUseCase: UpdateNotificationsSettingUseCase,
    private teamChatViewModel: TeamChatViewModel,
    private taskViewModel: TaskViewModel,
    private drawerService: DrawerService
  ) { }

  profile$ = this.store.select(selectTenantProfile);
  unreadCount$ = this.store.select(selectUnreadCount).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );
  notificationsState$ = this.store.select(selectNotificationsState).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );
  cachedNotifications$ = this.notificationRepository.getCachedNotifications();
  notifications$ = this.store.select(selectNotifications).pipe(
    switchMap((notifications) =>
      notifications.length
        ? of(notifications)
        : this.cachedNotifications$
    )
  );

  getNotifications(page: number): void {
    this.store.dispatch(GetNotificationsAction({ payload: { page } }));
  }

  dismissNotifications() {
    this.store.dispatch(DismissAllNotificationsAction());
  }

  updateNotificationsSettings(request: NotificationsSettingDto): Observable<SuccessResponse> {
    return this.updateNotificationsSettingUseCase.execute(request);
  }

  getUnreadNotificationsCount(): void {
    this.store.dispatch(GetUnreadNotificationsCountAction());
  }

  updateNotificationAsRead(notification: Notification) {
    this.store.dispatch(UpdateNotificationAsReadAction({ payload: { notification } }))
  }

  openNotification(notification: Notification) {
    if (!notification.read) {
      this.updateNotificationAsRead(notification);
    }
    if (notification.notification_type.includes('task')) {
      const taskId = notification?.additional_data as number;
      this.router.navigate(['main', 'tasks', taskId]);
      this.taskViewModel.taskInteractionId$.next(taskId);
      // this.taskViewModel.loadTaskDetail(taskId);
      // this.taskViewModel.openTaskDrawer();
    }
    else if ([NotificationType.note_mention, NotificationType.inline_note_mention].includes(notification.notification_type)) {
      const additional_data = notification.additional_data as { channel_id: number; contact_id: number | null; note_id: number };
      const queryParams = {
        threadId: additional_data.contact_id,
        channel: !additional_data.contact_id ? additional_data.channel_id : undefined
      };
      this.router.navigate(['main/inbox/thread'], { queryParams });
      notification.notification_type === NotificationType.note_mention && this.store.dispatch(SetRightPanelActiveWindowAction({ payload: RightPanelIndexes.notes }))
    }
    else if (notification.notification_type == NotificationType.add_user) {
      this.teamChatViewModel.openTeamChatThread(notification.triggered_by.user_id).catch((err) => console.log('err :>> ', err))
    }
    else if (notification.notification_type == NotificationType.inline_note) {
      console.log({ notification });
    } else if (notification.notification_type === NotificationType.team_chat_mention) {
      let room_details = { room_name: notification.room_details.room_name, private: notification.room_details.private, avatar: notification.room_details.avatar, room_id: notification.room_details.room_id }
      this.teamChatViewModel.setActiveRoomStore(room_details);
      this.teamChatViewModel.tempActiveRoom$.next(room_details);
      this.teamChatViewModel.openRoomTeamChatThread(notification.room_details.room_id)
    }
  }
}
