import { INotesRepository } from '@/app/core/IRepositories/INotesRepository';
import { from, map, Observable, pluck } from 'rxjs';
import { environment } from '@/environments/environment';
import { HttpRequestMethod } from '@/app/Data/services/Networking/HttpRequestMethod';
import {
  ApiResponse,
  SuccessApiResponse,
} from '@/app/Data/services/Networking/ApiResponse';
import { INotificationRepository } from '@/app/core/IRepositories/INotificationRepository';
import { HttpService } from '@/app/Data/services/Networking/HttpService';
import { Injectable } from '@angular/core';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { Notification } from '@/app/core/Models/Notification';
import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { liveQuery } from 'dexie';
import { NotificationsSettingDto } from '@/app/Data/DTO/NotificationsSettingDto';

@Injectable()
export class NotificationRepository extends INotificationRepository {
  unRegisterFCMToken(token: string, tenantId: number): Observable<SuccessResponse<string>> {
    const requestURL = `${environment.apiURL}users/tenant/${tenantId}/token/${token}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }
  
  registerFCMToken(token: string): Observable<SuccessResponse<string>> {
    const requestURL = `${environment.apiURL}users/token`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      {token},
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }
  dismissNotifications(): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}internal_notifications_v2/dismiss`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) {
    super();
  }

  getNotifications(page: number): Observable<Notification[]> {
    const requestURL = `${
      environment.apiURL
    }internal_notifications_v2?page=${page}&page_size=${20}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  getUnreadNotificationsCount(): Observable<number> {
    const requestURL = `${environment.apiURL}internal_notifications/unread_notifications_v2`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        return (item as SuccessApiResponse<number>).results;
      })
    );
  }

  setNotificationReadStatus(id: number): Observable<SuccessResponse> {
    const requestURL = `${
      environment.apiURL
    }internal_notifications_v2/${id}/status/${true}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  updateNotificationsSetting(
    data: NotificationsSettingDto
  ): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}external_notifications_settings`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  getCachedNotifications(): Observable<Notification[]> {
    return from(
      liveQuery(() => {
        this.databaseService.notifications.mapToClass(Notification);
        return this.databaseService.notifications.toArray();
      })
    );
  }

  saveNotificationsLocally(list: Notification[]): void {
    this.databaseService.notifications.bulkPut(list);
  }
}
