import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ChannelType, vendorType as VendorType } from '@/app/core/Models/ChannelTypes';

@Component({
  selector: 'app-channel-icon',
  templateUrl: './channel-icon.component.html',
  styleUrls: ['./channel-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelIconComponent {
  
  
  @Input() outlined = false
  
  @Input('vendor') set setVendor(value: string) {
    switch (value) {
      // in case channel type is email
      case VendorType.Outlook:
        this.url = 'assets/icons/channel/outlook.svg';
        this.vendorUsed = true;
        break;
      case VendorType.Google:
        this.url = 'assets/icons/channel/gmail.svg';
        this.vendorUsed = true;
        break;
    }
  }

  @Input('channel') set setChannel(value: ChannelType | undefined) {
    if (this.vendorUsed) return;
    switch (value) {
      case ChannelType.Facebook:
        this.url = this.outlined
        ? 'assets/icons/new-set/channels/brand-facebook.svg'
        : 'assets/icons/channel/facebook.svg';
        break;
      case ChannelType.Twitter:
        this.url = this.outlined
        ? 'assets/icons/new-set/channels/brand-x.svg'
        : 'assets/icons/channel/twitter.svg';
        break;
      case ChannelType.Whatsapp:
        this.url = this.outlined
        ? 'assets/icons/new-set/channels/brand-whatsapp.svg'
        : 'assets/icons/channel/whatsapp.svg';
        break;
      case ChannelType.Instagram:
        this.url = this.outlined
        ? 'assets/icons/new-set/channels/brand-instagram.svg'
        : 'assets/icons/channel/instagram.svg';
        break;
      case ChannelType.Viber:
        this.url = this.outlined
        ? 'assets/icons/new-set/channels/brand-viber.svg'
        : 'assets/icons/channel/viber.svg';
        break;
      case ChannelType.Yobi:
        this.url = this.outlined
        ? 'assets/icons/new-set/channels/yobi.svg'
        : 'assets/images/Logo.svg';
        break;
      case ChannelType.Email:
        this.url = this.outlined
        ? 'assets/icons/new-set/mail.svg'
        : 'assets/icons/channel/email.svg';
        break;
      case ChannelType.Google:
        this.url = this.outlined
        ? 'assets/icons/new-set/channels/brand-gmail.svg'
        : 'assets/icons/channel/gmail.svg';
        break;
      case ChannelType.Outlook:
        this.url = this.outlined
        ? 'assets/icons/new-set/channels/brand-outlook.svg'
        : 'assets/icons/channel/outlook.svg';
        break;
      case ChannelType.ChatWidget:
        this.url = this.outlined
        ? 'assets/icons/new-set/channels/chat-widget.svg'
        : 'assets/icons/channel/chatWidget.svg';
        break;
      case ChannelType.Phone:
        this.url = this.outlined
        ? 'assets/icons/new-set/phone.svg'
        : 'assets/icons/channel/phone.svg';
        break;
      default:
        this.url = this.outlined
        ? 'assets/icons/new-set/message-dots.svg'
        : 'assets/icons/channel/phone.svg';
        break;
    }
  }


  @Input('size') set setSize(value: any) {
    if (!isNaN(Number(value))) {
      const cof = Number(value);
      this.size = this.defaultSize * cof;
    }
  }
  vendorUsed = false
  url = 'assets/icons/channel/phone.svg';
  defaultSize = 1;
  size = 1;


}
