import { ConfigurationService } from '@/app/shared/Config/configuration.service';
import { IConfiguration } from '@/app/shared/Config/IConfig';
import { Injectable, Optional } from '@angular/core';
import { Observable, ReplaySubject, take, timestamp } from 'rxjs';
import { ILogEntry } from './ILogEntry';
import { LogEntry } from './LogEntry';
import { Severity } from './Severity';

@Injectable()
export class LoggingService {
  seviceName = 'LoggerService';
  config: IConfiguration;

  private loggerSubject: ReplaySubject<ILogEntry> =
    new ReplaySubject<ILogEntry>(1);
  readonly logs$: Observable<ILogEntry> = this.loggerSubject.asObservable();

  constructor(public configService: ConfigurationService) {
    this.log(
      this.seviceName,
      Severity.Information,
      'Starting logging serrvice',
      new Date()
    );
    this.init();
  }

  init(): void {
    if (this.configService) {
      this.configService.settings$
        .pipe(take(1))
        .subscribe((settings) => this.handleSettings(settings));
    }
  }

  handleSettings(settings: IConfiguration): void {
    if (settings) {
      this.config = settings;
    }
  }

  log(
    source: string,
    severity: Severity,
    message: string,
    timestamp: Date,
    tags?: []
  ): void {
    const log = new LogEntry(source, message, severity, timestamp);
    this.loggerSubject.next(log);
  }
}
