export enum success_messages {
  contact_channel_added = 'success_messages.contact_channel_added',
  csv_contacts_import_inprogress = 'success_messages.csv_contacts_import_inprogress',
  csv_contacts_imported = 'success_messages.csv_contacts_imported',
  contact_created = 'success_messages.contact_created',
  contact_updated = 'success_messages.contact_updated',
  contacts_exported_to_integration = 'success_messages.contacts_exported_to_integration',
  contacts_imported_from_integration = 'success_messages.contacts_imported_from_integration',
  credit_card_removed = 'success_messages.credit_card_removed',
  integration_linked = 'success_messages.integration_linked',
  integration_unlinked = 'success_messages.integration_unlinked',
  microsoft_teams_event_added = 'success_messages.microsoft_teams_event_added',
  microsoft_teams_notification = 'success_messages.microsoft_teams_notification',
  payment_method_updated = 'success_messages.payment_method_updated',
  successful_subscription = 'success_messages.successful_subscription',
  successful_unsubscription = 'success_messages.successful_unsubscription',
  trial_period_extended = 'success_messages.trial_period_extended',
  contact_successfully_merged = 'success_messages.contact_successfully_merged'
}
