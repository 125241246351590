import { TasksListDto } from '@/app/Data/DTO/TasksListDto';
import { AppState } from '@/app/State/AppState';
import { selectTasksActiveStatus, selectTasksFilter, selectTasksIsFetching } from './../../State/Tasks/selector';
import { GetAllTasksAction, SetActiveTasksStatusAction } from '@/app/State/Tasks/action';
import { selectTenantProfile } from '@/app/State/Tenant/tenant.selector';
import { IMessagesRepository } from '@/app/core/IRepositories/IMessagesRepository';
import { ITasksRepository } from '@/app/core/IRepositories/ITasksRepository';
import { IUsersRepository } from '@/app/core/IRepositories/IUsersRepository';
import { Task, TaskDetail, TaskFilter, TaskStatus } from '@/app/core/Models/Task';
import { TeamMember } from '@/app/core/Models/TeamMember';
import { GetTasksByChannelIdUseCase } from '@/app/core/usecases/Tasks/GetTasksByChannelIdUseCase';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, catchError, combineLatest, distinctUntilChanged, filter, firstValueFrom, map, of, shareReplay, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { selectInboxActiveThreadId } from '@/app/State/Inbox/inbox.selector';
import { RecentInteraction } from '@/app/core/Models/Interaction';
import { TaskFormComponent } from '../tasks/task-form/task-form.component';
import { MatDialog } from '@angular/material/dialog';
import { UsersViewModel } from './usersViewModel';


@Injectable({
  providedIn: 'root',
})
export class TasksViewModel {
  readonly profile$ = this.store.select(selectTenantProfile);
  readonly team$ = this.usersRepository.getLocalTeamMembers().pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  )
  refreshTasksByChannelId$ = new Subject<void>();
  isFetching$ = this.store.select(selectTasksIsFetching);
  tasksFilter$ = this.store.select(selectTasksFilter);
  TasksActiveStatus$ = this.store.select(selectTasksActiveStatus);

  constructor(
    private store: Store<AppState>,
    private matDialog: MatDialog,
    private tasksRepository: ITasksRepository,
    private usersRepository: IUsersRepository,
    private messagesRepository: IMessagesRepository,
    private getTasksByChannelIdUseCase: GetTasksByChannelIdUseCase,
    private userViewModel: UsersViewModel
  ) { }

  activeChannelTasks$ = combineLatest([
    this.store.select(selectInboxActiveThreadId).pipe(
      switchMap((threadId) => threadId ? this.messagesRepository.findRecentMessage(threadId) : of(undefined)),
      filter((recentInteraction): recentInteraction is RecentInteraction => !!recentInteraction),
      distinctUntilChanged((a, b) => a?.channel_id == b?.channel_id)
    ),
    this.refreshTasksByChannelId$.pipe(startWith(true))
  ]).pipe(
    switchMap(([{ channel_id }]) => this.getTasksByChannelId(channel_id)),
    shareReplay({ bufferSize: 1, refCount: true })
  )
  openedTasks$ = this.activeChannelTasks$.pipe(
    map(tasks => tasks.filter(({ status }) => [TaskStatus.inProgress, TaskStatus.open].includes(status)).length),
  )
  tasks$: Observable<Record<TaskStatus, Task[]>> = this.tasksRepository
    .getLocalTasks(TaskFilter.All)
    .pipe(
      startWith([]),
      distinctUntilChanged(),
      withLatestFrom(this.team$),
      map(([tasks, team]: any) => this.mapTaskUser(tasks, team)),
      map(tasks => ({
        'open': tasks.filter(t => t.status === 'open'),
        'done': tasks.filter(t => t.status === 'done'),
        'in-progress': tasks.filter(t => t.status === 'in-progress'),
        'trash': tasks.filter(t => t.status === 'trash'),
      })),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

  getTasksByChannelId(
    channelId: number,
    status: TaskStatus = TaskStatus.open,
    filter: TaskFilter = TaskFilter.All
  ): Observable<Task[]> {
    return this.getTasksByChannelIdUseCase.execute({ channelId, filter, status }).pipe(
      withLatestFrom(this.team$),
      map(([tasks, team]) => this.mapTaskUser(tasks, team)),
      catchError(() => of([]))
    );
  }

  getAllTasks(): void {
    this.profile$.pipe(
      take(1),
      withLatestFrom(this.TasksActiveStatus$)
    ).subscribe(([profile, status]) => {
      const request: TasksListDto = {
        filter: TaskFilter.All,
        userId: profile?.userId,
        status
      };
      this.store.dispatch(GetAllTasksAction({ payload: request }));
    });
  }

  getTasksByStatus(status: TaskStatus[]): void {
    this.profile$.pipe(
      take(1),
      withLatestFrom(this.TasksActiveStatus$)
    ).subscribe(([profile]) => {
      const request: TasksListDto = {
        filter: TaskFilter.All,
        userId: profile?.userId,
        status
      };
      this.store.dispatch(GetAllTasksAction({ payload: request }));
    });
  }

  getMyTasks(): void {
    this.profile$.pipe(
      take(1),
      withLatestFrom(this.TasksActiveStatus$)
    ).subscribe(([profile, status]) => {
      const request: TasksListDto = {
        filter: TaskFilter.Mine,
        userId: profile?.userId,
        status
      };
      this.store.dispatch(GetAllTasksAction({ payload: request }));
    });
  }

  tasksActiveStatusChange(status: TaskStatus[]) {
    this.store.dispatch(SetActiveTasksStatusAction({ payload: status }))
  }

  mapTaskUser = (tasks: Task[], team: TeamMember[]): Task[] =>
    tasks.map((task) => {
      const user = team.find(u => u.user_id == task.assigned_to)
      if (user) {
        task.assignedUser = user;
        task.userAvatar = user.avatar;
        task.assignedToName = `${user.given_name} ${user.family_name}`;
      }
      return task;
    })

  openNewTask(data?: { channel_id: number, inbox_id?: number }): void {
    this.matDialog.open<any, any, { type: 'archive' | 'updated' | 'created', data?: TaskDetail }>(TaskFormComponent, {
      disableClose: true,
      height: 'fit-content',
      maxHeight: '90vh',
      width: '700px',
      maxWidth: '90vw',
      panelClass: 'custom-dialog-container',
      data
    })
      .afterClosed()
      .subscribe((res) => {
        res?.data?.interaction_id ? this.refreshTasksByChannelId$.next()
          : res?.data ? this.getAllTasks()
            : undefined
      });
  }

  saveTask(task: Task): void {
    this.tasksRepository.saveTask(task);
  }

  getLocalTaskById(taskId: number) {
    return this.tasksRepository.getLocalTaskById(taskId);
  }

  getTaskAssigneesByUserId(assignees: string[]) {

    return new Promise(async (resolve) => {
      const users = await firstValueFrom(this.userViewModel.usersList$);
      const task_assignees: Array<{ avatar: string, family_name: string, given_name: string, user_id: any }> = [];
      assignees.forEach((id) => {
        users.forEach((user: any) => {
          if (user.user_id === id) {
            task_assignees.push({ avatar: user.avatar, family_name: user.family_name, given_name: user.given_name, user_id: user.user_id })
          }
        })
      })
      resolve(task_assignees);
    })

  }


}
