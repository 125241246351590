import { ILocalStorageService } from '@/app/Data/Adapters/ILocalStorageService';
import {
  GET_UNREAD_NOTIFICATIONS_COUNT,
  UpdateUnreadNotificationsCountAction
} from '@/app/State/Notifications/notifications.action';
import {
  AppActions, CHANGE_APP_LANG, LOAD_INTEGRATIONS_STATUS,
  LoadIntegrationsStatus,
  PRE_INITIALIZE_APP,
  PRE_INITIALIZE_MAIN_APP,
  PreInitializeApplicationAction,
  PreInitializeApplicationSuccessAction,
  PreInitializeMainApplicationFailAction,
  PreInitializeMainApplicationSuccessAction
} from '@/app/State/app/action';
import { InboxIntegrationsViewModel } from '@/app/Ui/ViewModels/InboxIntegrationsViewModel';
import { TOKEN_KEY } from '@/app/Utilities/TokenKeys';
import { AppTranslateService } from '@/app/Utilities/localization/translate.service';
import { INotificationRepository } from '@/app/core/IRepositories/INotificationRepository';
import { GetInternalChannelsUseCase } from '@/app/core/usecases/Tenant/GetInternalChannelsUseCase';
import { GetTenantProfileUseCase } from '@/app/core/usecases/Tenant/GetTenantProfileUseCase';
import { GetTeamMembersUseCase } from '@/app/core/usecases/Users/GetTeamMembersUseCase';
import { Injectable } from '@angular/core';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, filter, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { AppState } from '../AppState';
import { FetchInboxesAction } from '../Inbox/inbox.action';
import { FetchActiveSubscriptionAction } from '../billing/billing.actions';
import { GetInternalChannelsSuccessAction, GetTenantCustomFieldsAction, GetTenantInternalChannelsAction } from './../Tenant/tenant.action';
import { GetSnippetsUseCase } from '@/app/core/usecases/snippets/GetSnippetsUseCase';
import { FetchTenantTagsAction } from '../Tags/tags.action';
import { GetSynthsAction } from '../synth/synth.action';
import { FetchTeamRecentMessagesAction } from '../TeamInbox/teamInbox.action';
import { FetchUsersListAction } from '../users/action';

@Injectable()
export class ConfigEffect {
  constructor(
    private actions$: Actions<AppActions>,
    private notificationRepository: INotificationRepository,
    private getTeamMembersUseCase: GetTeamMembersUseCase,
    private getInternalChannelUseCase: GetInternalChannelsUseCase,
    private getTenantProfileUsecase: GetTenantProfileUseCase,
    private integrationsRepository: InboxIntegrationsViewModel,
    private appTranslateService: AppTranslateService,
    private getSnippetsUseCase: GetSnippetsUseCase,
    private store$: Store<AppState>,
    private localStorageService: ILocalStorageService
  ) { }

  rootInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      filter(() => !!this.localStorageService.getItem(TOKEN_KEY)),
      map(() => PreInitializeApplicationAction())
    ),
    { dispatch: true }
  )

  onInit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PRE_INITIALIZE_APP),
        switchMap(() =>
          this.getTenantProfileUsecase.execute()
        ),
        map(() => PreInitializeApplicationSuccessAction())
      ),
    { dispatch: true }
  );

  onMainAppInit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PRE_INITIALIZE_MAIN_APP),
        tap(() => {
          this.store$.dispatch(LoadIntegrationsStatus())
          this.store$.dispatch(FetchActiveSubscriptionAction())
          this.store$.dispatch(GetTenantCustomFieldsAction())
          this.store$.dispatch(FetchTeamRecentMessagesAction({ payload: { page: 1, page_size: 25 } }))
          this.store$.dispatch(GetTenantInternalChannelsAction())
          this.store$.dispatch(FetchInboxesAction())
          this.store$.dispatch(FetchTenantTagsAction())
          this.store$.dispatch(FetchUsersListAction())
          this.store$.dispatch(GetSynthsAction({ payload: { page: 1, page_size: 20, search: '' } }));
        }),
        switchMap(() =>
          forkJoin(
            [
              this.getTeamMembersUseCase.execute(),
              this.getSnippetsUseCase.execute(),
            ]
          )
        ),
        map(() => PreInitializeMainApplicationSuccessAction()),
        catchError(() => of(PreInitializeMainApplicationFailAction())),
      ),
    { dispatch: true }
  );

  onChangeAppLang$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CHANGE_APP_LANG),
        map(action => action.payload.lang),
        tap((lang) => this.appTranslateService.changeLang(lang))
      ),
    { dispatch: false }
  );

  onGetUnreadNotificationsCountAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GET_UNREAD_NOTIFICATIONS_COUNT, PRE_INITIALIZE_APP),
        exhaustMap(() => {
          return this.notificationRepository.getUnreadNotificationsCount().pipe(
            map((count: number) => {
              return UpdateUnreadNotificationsCountAction({
                payload: { count },
              });
            })
          );
        })
      ),
    { dispatch: true }
  );

  onLoadIntegrationsStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOAD_INTEGRATIONS_STATUS),
        switchMap(() =>
          this.integrationsRepository.loadIntegrationsLinkStatus().pipe(catchError(e => {
            return of({})
          }))
        )
      ),
    { dispatch: false }
  );
}
