import { UpdatePendingInteractionToFailedStatusAction, UpdatePendingInlineNoteToFailedStatusAction, InboxSelectionAction } from './../Inbox/inbox.action';
import { createReducer, on } from '@ngrx/store';
import { AddPendingInlineNoteAction, AddPendingMessageAction, ClassifiedMessagesFetchFail, ClassifiedMessagesLoadedAction, FetchClassifiedMessages, FetchUnClassifiedMessages, ReformulateThreadInputMessageAction, ReformulateThreadInputMessageResponseAction, RemoveInteractionFromPendingMessagesAction, RemoveInteractionFromPendingInlineNotesAction, ResendFailedMessageAction, SendInlineNoteSuccessAction, SetThreadMessageContentAction, SetThreadMessageSuggestionAction, SwitchReplyModeAction, ThreadInboxSelectionFilterAction, ToggleSynthAutoReplyAction, TranslateThreadInputMessageAction, TranslateThreadInputMessageResponseAction, UnClassifiedMessagesFetchFail, UnClassifiedMessagesLoadedAction, UpdateActiveThreadAction, UpdateLocalInteractionAction, SendMessageSuccessAction } from './thread.action';
import { InteractioReplyMode, ActiveThreadState, ThreadInputState } from './thread.model';
import { InteractionCommentItem, InteractionThread } from '@/app/core/Models/Interaction';
import { PendingInlineNote, PendingMessage } from '../Inbox/inbox.model';
import { SignOutAction } from '../app/action';
import { UpdateTaskStatusSuccessAction } from '../Task/action';
import { OnReceivedInteractionComment } from '../Interaction/interaction.action';
import { theme } from 'root/tailwind.config';

export interface ThreadState {
  replyMode: InteractioReplyMode;
  translating: boolean;
  reformulating: boolean;
  fetchingThreadInteractions: boolean;
  activeThread?: ActiveThreadState;
  pendingMessages: PendingMessage[];
  pendingInlineNotes: PendingInlineNote[];
  threadsInputState: ThreadInputState[];
}
export const initThreadState: ThreadState = {
  replyMode: 'message',
  translating: false,
  reformulating: false,
  pendingMessages: [],
  pendingInlineNotes: [],
  fetchingThreadInteractions: false,
  threadsInputState: [],
};
export const threadReducer = createReducer(
  initThreadState,

  /* ---------------------------- load interactions --------------------------- */
  on(FetchClassifiedMessages, FetchUnClassifiedMessages, (state: ThreadState) => {
    return {
      ...state,
      fetchingThreadInteractions: true,
    };
  }),

  // will be set when UpdateActiveThreadAction
  // on(ClassifiedMessagesLoadedAction, UnClassifiedMessagesLoadedAction, (state: ThreadState) => {
  //   return {
  //     ...state,
  //     fetchingThreadInteractions: false,
  //   };
  // }),

  on(UpdateActiveThreadAction, (
    state: ThreadState, { payload: { interactions, query: { threadId, channel, params: { page, inbox } } } }
  ) => {
    let threadInteractions = state.activeThread?.interactions ?? []
    if (
      !state.activeThread?.unClassified && state.activeThread?.threadId != threadId ||
      state.activeThread?.unClassified && state.activeThread?.threadId != channel
    ) {
      threadInteractions = interactions
    } else if (interactions.length) {
      const newInteractionsId = new Set(interactions.map(i => i.interaction_id));
      const filtredInteractions = threadInteractions.filter(i => !newInteractionsId.has(i.interaction_id) && (!inbox?.is_channel || inbox.inbox_id == i.inbox_id))

      if (state.activeThread?.currentPage! >= page) {
        threadInteractions = filtredInteractions.concat(interactions).sort((a, b) => a.interaction_dt < b.interaction_dt ? -1 : 1)
      } else {
        threadInteractions = filtredInteractions.concat(interactions)
      }
    }
    return {
      ...state,
      fetchingThreadInteractions: false,
      activeThread:
      {
        ...state.activeThread,
        pageEnd: !interactions.length,
        threadId: threadId! ?? channel!,
        unClassified: !!channel,
        currentPage: page,
        interactions: threadInteractions
      },
    };
  }),

  on(UpdateLocalInteractionAction, (state: ThreadState, { payload: { interaction } }) => {
    return {
      ...state,
      activeThread: state.activeThread
        ? {
          ...state.activeThread,
          interactions: [...state.activeThread.interactions.map(i => i.interaction_id == interaction.interaction_id ? Object.assign(new InteractionThread(), i, interaction) : i)]
        }
        : undefined
    }
  }),

  on(ThreadInboxSelectionFilterAction, (state: ThreadState, { payload: { inbox } }) => {
    const threadInteractions = state.activeThread?.interactions ?? []
    const interactions = threadInteractions.filter(i => !inbox || inbox.is_custom || inbox.inbox_id == i.inbox_id)
    return {
      ...state,
      fetchingThreadInteractions: false,
      activeThread: {
        ...state.activeThread!,
        interactions: interactions
      }
    };
  }),

  on(ClassifiedMessagesFetchFail, UnClassifiedMessagesFetchFail, (state: ThreadState, { payload }) => {
    return {
      ...state,
      fetchingThreadInteractions: false,
    };
  }),

  /* ------------------------------- reply mode ------------------------------- */
  on(SwitchReplyModeAction, (state: ThreadState, { payload }) => {
    return {
      ...state,
      replyMode: payload
    };
  }),

  on(ToggleSynthAutoReplyAction, (state: ThreadState, { payload }) => {
    return {
      ...state,
      activeThread:
      {
        ...state?.activeThread!,
        synthAutoReply: payload
      },
    };
  }),

  /* ------------------------- thread message content ------------------------- */
  on(SetThreadMessageContentAction, (state: ThreadState, { payload }) => {
    return {
      ...state,
      threadsInputState: [...state.threadsInputState.filter(m => m.threadId !== payload.threadId || m.isEmailInbox !== payload.isEmailInbox), payload]
    };
  }),

  /* ------------------------- thread message suggetion ------------------------- */
  on(SetThreadMessageSuggestionAction, (state: ThreadState, { payload }) => {
    const threadId = payload.threadId;
    let threadState: ThreadInputState = state.threadsInputState.find(m => m.threadId == threadId) ?? {
      content: '', files: [], threadId: payload.threadId, isEmailInbox: !!payload.isEmailInbox
    };
    threadState = { ...threadState, suggestion: payload.content };
    return {
      ...state,
      threadsInputState: [...state.threadsInputState.filter(m => m.threadId !== threadId), threadState]
    };
  }),

  /* --------------------------- message translation -------------------------- */
  on(TranslateThreadInputMessageAction, (state: ThreadState) => {
    return {
      ...state,
      translating: true,
    };
  }),

  on(TranslateThreadInputMessageResponseAction, (state: ThreadState, { payload: { res, threadId, isEmailInbox } }) => {
    let content: ThreadState['threadsInputState'][0] = {
      ...(state.threadsInputState.find(m => m.threadId == threadId) ?? {
        content: '',
        files: [],
        threadId,
        isEmailInbox: !!isEmailInbox
      })
    }
    content.content = res?.translated_text ?? content.content;
    content.originalText = res?.original_text ? { content: res?.original_text, targetLang: res?.target_language } : content.originalText;
    return {
      ...state,
      translating: false,
      threadsInputState: [
        ...state.threadsInputState.filter(m => m.threadId !== threadId),
        content
      ]
    };
  }),

  /* -------------------------- message reformulation ------------------------- */
  on(ReformulateThreadInputMessageAction, (state: ThreadState) => {
    return {
      ...state,
      reformulating: true,
    };
  }),

  on(ReformulateThreadInputMessageResponseAction, (state: ThreadState, { payload: { res, threadId, isEmailInbox } }) => {
    let content: ThreadState['threadsInputState'][0] = {
      ...(state.threadsInputState.find(m => m.threadId == threadId) ?? {
        content: '',
        files: [],
        threadId,
        isEmailInbox: !!isEmailInbox
      })
    }
    content.content = res?.new_text.trim() ?? content.content;
    content.originalText = res?.original_text ? { content: res.original_text } : content.originalText;
    return {
      ...state,
      reformulating: false,
      threadsInputState: [
        ...state.threadsInputState.filter(m => m.threadId !== threadId),
        content
      ]
    };
  }),

  /* ------------------------------ send message ------------------------------ */

  on(AddPendingMessageAction, (state: ThreadState, { payload }) => {
    const pendingMessages = [...state.pendingMessages]
    if (!pendingMessages.find(m => m.message.msg_id == payload.message.msg_id)) {
      pendingMessages.push(payload)
    }
    return {
      ...state,
      pendingMessages
    };
  }),

  on(AddPendingInlineNoteAction, (state: ThreadState, { payload }) => {
    const pendingInlineNotes = [...state.pendingInlineNotes]
    if (!pendingInlineNotes.find(i => i.noteBody.msg_id == payload.noteBody.msg_id)) {
      pendingInlineNotes.push(payload)
    }
    return {
      ...state,
      pendingInlineNotes
    };
  }),

  on(UpdatePendingInteractionToFailedStatusAction,
    (state: ThreadState, { payload: { msg_id } }) => {
      return {
        ...state,
        pendingMessages: [...state.pendingMessages.map(m => m.message.msg_id == msg_id
          ? new PendingMessage({ message: m.message, threadId: m.threadId, failed: true })
          : m
        )],
      };
    }
  ),

  on(UpdatePendingInlineNoteToFailedStatusAction,
    (state: ThreadState, { payload: { msg_id } }) => {
      return {
        ...state,
        pendingInlineNotes: [...state.pendingInlineNotes.map((note) => note.noteBody.msg_id == msg_id
          ? new PendingInlineNote(note.noteBody, note.members, note.threadId, true)
          : note
        )],
      };
    }
  ),

  on(ResendFailedMessageAction,
    (state: ThreadState, { payload: { msg_id } }) => {
      return {
        ...state,
        pendingMessages: [...state.pendingMessages.map(m => m.message.msg_id == msg_id
          ? new PendingMessage({ message: m.message, threadId: m.threadId, failed: false })
          : m
        )],
      };
    }
  ),

  on(SendMessageSuccessAction, (state: ThreadState, { payload: { interaction } }) => {
    const pendingInteraction = state.pendingMessages.find((item) => +item?.message.msg_id == interaction.msg_id);
    let threadInteractions = state.activeThread!.interactions
    if (interaction && pendingInteraction && pendingInteraction.threadId == state.activeThread!.threadId) {
      threadInteractions = [
        ...threadInteractions.filter(i => i.interaction_id !== interaction?.interaction_id),
        interaction.mapToThreadInteraction()
      ]
    }
    return {
      ...state,
      pendingMessages: state.pendingMessages.filter(item => +item?.message.msg_id != interaction.msg_id),
      activeThread: {
        ...state.activeThread!,
        interactions: threadInteractions
      }
    };
  }),

  on(RemoveInteractionFromPendingMessagesAction, (state: ThreadState, { payload: { msg_id } }) => {
    return {
      ...state,
      pendingMessages: state.pendingMessages.filter(item => +item?.message.msg_id != msg_id)
    };
  }),

  on(SendInlineNoteSuccessAction, (state: ThreadState, { payload: { interaction } }) => {
    const pendingInteraction = state.pendingInlineNotes.find((item) => +item?.noteBody.msg_id == interaction.msg_id);
    let threadInteractions = state.activeThread!.interactions
    if (interaction && pendingInteraction && pendingInteraction.threadId == state.activeThread!.threadId) {
      threadInteractions = [
        ...threadInteractions.filter(i => i.interaction_id !== interaction.interaction_id),
        interaction.mapToThreadInteraction()
      ]
    }
    return {
      ...state,
      pendingInlineNotes: state.pendingInlineNotes.filter(item => item?.noteBody.msg_id != interaction.msg_id),
      activeThread: {
        ...state.activeThread!,
        interactions: threadInteractions
      }
    };
  }),

  on(RemoveInteractionFromPendingInlineNotesAction, (state: ThreadState, { payload: { msg_id } }) => {
    return {
      ...state,
      pendingInlineNotes: state.pendingInlineNotes.filter(item => item?.noteBody.msg_id != msg_id),
    };
  }
  ),

  on(UpdateTaskStatusSuccessAction, (state: ThreadState, { payload }) => {
    const interactions = state.activeThread?.interactions.map(interaction => {
      if (interaction.interaction_id == payload.interaction_id) {
        const newInteraction = Object.assign(new InteractionThread(), interaction);
        let body = JSON.parse(newInteraction.text_body);
        body[0].status = payload?.status
        newInteraction.text_body = JSON.stringify(body)
        newInteraction.updated_dt = payload?.updated_dt
        return newInteraction
      }
      return interaction
    })
    return {
      ...state,
      fetchingThreadInteractions: false,
      activeThread: state.activeThread
        ? {
          ...state.activeThread!,
          interactions: interactions!
        }
        : undefined
    };
  }),
  // Update interaction comments count
  on(OnReceivedInteractionComment, (state: ThreadState, { payload }) => {
    let thread = !state.activeThread ? undefined :
      {
        ...state.activeThread!,
        interactions: state.activeThread!.interactions.map(item => {
          if (item.interaction_id == payload.interaction_id as any) {
            let newItem = { ...item }
            let user: InteractionCommentItem = { family_name: payload.family_name, given_name: payload.given_name, avatar: payload.avatar, user_id: payload.user_id as any }
            newItem.comments_users = [...(item.comments_users ?? []).filter(u => u.user_id !== payload.user_id), user]
            newItem.comments_count = item.comments_count + 1
            return Object.assign(new InteractionThread(), newItem)
          }
          return item
        })
      }
    return {
      ...state,
      activeThread: thread
    };
  }
  ),

  on(SignOutAction, (state: ThreadState) => {
    return {
      ...initThreadState
    };
  }),

);
