<div class="mat-dialog-header text-dark border-b">
    <h2 class="m-0 text-lg font-semibold">
        {{ (editMode ? localization.labels.edit_task : localization.labels.create_task) | translate }}
    </h2>
    <svg-icon
        class="text-pale hover:text-warning cursor-pointer ml-auto"
        [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
        src="assets/icons/new-set/close.svg"
        (click)="close()"
    >
    </svg-icon>
</div>
<!-- -------------------------------- Body --------------------------------- -->
<div
    class="mat-dialog-body flex flex-col gap-4 text-dark"
    [formGroup]="taskForm"
>
    <ng-container *ngIf="!loadingTask; else loadingTaskTpl">
        <!-- -------------------------------- title -------------------------------- -->
        <app-text-input
            formControlName="title"
            [placeholder]="localization.placeholder.task_title_placeholder | translate"
            [label]="(localization.labels.task_title | translate) + '*'"
            [invalid]=" taskForm.get('title')?.invalid && taskForm.get('title')?.touched "
        ></app-text-input>
        <!-- ----------------------------- description ----------------------------- -->
        <div class="flex flex-col gap-1">
            <label class="font-semibold text-mild">{{ localization.terms.description | translate }}</label>
            <app-formatted-textarea
                formControlName="description"
                [placeholder]="localization.placeholder.task_description_placeholder | translate"
                [members]="mentions$ | async"
                (filesModelChange)="onFiles($event)"
            ></app-formatted-textarea>
        </div>
        <!-- ------------------------------ reminder ------------------------------- -->
        <div class="flex gap-4 xs:flex-col">
            <app-input-wrapper
                class="flex-1"
                [label]="'Remind me' | translate"
            >
                <mat-select
                    select
                    [formControl]="reminder"
                    [disableOptionCentering]="true"
                >
                    <mat-option
                        *ngFor="let i of ['none', 'in_15_minutes', 'in_30_minutes', 'in_one_week', 'in_one_day', 'in_two_days', 'custom']"
                        [value]="i"
                    >
                        {{ ('labels.' + i) | translate }}
                    </mat-option>
                </mat-select>
            </app-input-wrapper>
            <div
                *ngIf="reminder.value === 'custom'"
                class="flex flex-col gap-1"
                style="flex: 2.3 1 0%;"
            >
                <label class="font-semibold text-mild">{{ localization.labels.custom | translate }}</label>
                <div class="flex items-centrer gap-4">
                    <app-input-wrapper
                        [dropDownPanel]="datePicker"
                        class="flex-1"
                    >
                        <p
                            input
                            class="cursor-text w-full flex items-center"
                        >
                            {{ currentDate.toDateString() == reminderDate.toDateString() ? 'Today' : reminderDate |
                            date: 'mediumDate'}}
                        </p>
                        <svg-icon
                            suffix
                            src="assets/icons/new-set/calendar.svg"
                            class="text-mild mr-4"
                            [svgStyle]="{ 'height.px': 18, 'width.px': 18 }"
                        ></svg-icon>
                    </app-input-wrapper>
                    <app-input-wrapper class="flex-1">
                        <input
                            input
                            #timePicker
                            type="time"
                            placeholder="Set end time"
                            [(ngModel)]="reminderTime"
                            [ngModelOptions]="{standalone: true}"
                            [min]="minimumTime"
                        />
                        <svg-icon
                            suffix
                            src="assets/icons/new-set/clock.svg"
                            class="text-mild mr-4 cursor-pointer"
                            (click)="$any(timePicker).showPicker()"
                            [svgStyle]="{ 'height.px': 18, 'width.px': 18 }"
                        ></svg-icon>
                    </app-input-wrapper>

                </div>
            </div>
        </div>
        <!-- ----------------------------- attachments ----------------------------- -->
        <div
            *ngIf="attachments.length"
            class="flex flex-col gap-1 w-full"
        >
            <label class="font-semibold text-mild">{{ localization.labels.task_attachments | translate }}</label>
            <app-attachments-viewer
                [attachments]="attachments"
                [canRemove]="true"
                (remove)="removeAttachment($event)"
            ></app-attachments-viewer>
        </div>

        <div class="flex gap-2 flex-col">
            <mat-checkbox
                *ngIf="data?.channel_id"
                (change)="_toggleTaskLink()"
                [ngModel]="linkToContact"
                [ngModelOptions]="{standalone: true}"
            >
                <span> {{(linkToContact
                    ? localization.labels.linked_to_conversation
                    : localization.labels.not_linked_to_conversation) | translate
                    }}</span>
            </mat-checkbox>
        </div>
    </ng-container>
</div>
<!-- ------------------------------- Footer -------------------------------- -->
<div class="mat-dialog-footer border-t">
    <button
        class="rounded-md h-11 w-11 flex items-center justify-center highlight-bg-color hover:secondary-bg-color shrink-0"
        appFilePicker
        [multiple]="true"
        [processFiles]="true"
        (filesModelChange)="onFiles($event)"
    >
        <svg-icon
            src="assets/icons/new-set/attachment.svg"
            class="text-mild"
            [svgStyle]="{ 'height.px': 21, 'width.px': 21 }"
        ></svg-icon>
    </button>

    <button
        class="rounded-md h-11 flex items-center justify-center shrink-0 px-2 highlight-bg-color hover:secondary-bg-color text-dark gap-2"
        [ngClass]="taskForm.get('assigned_to')?.invalid && taskForm.get('assigned_to')?.touched ? 'border-2 border-warning' : ''"
        [matTooltip]="localization.labels.assign_to_team_member | translate"
        [dropDownPanel]="teamPicker"
    >
        <div class="flex items-center gap-2">
            <app-avatar
                [url]="mentionedMember?.img"
                [name]="mentionedMember?.name"
                [fontSize]="11"
                class="h-7 w-7 shrink-0"
            ></app-avatar>
            <span class="truncate">
                {{ mentionedMember ? mentionedMember.name : localization.labels.assign_to | translate }}
            </span>
        </div>
        <mat-divider
            class="h-6 secondary-border-color"
            [vertical]="true"
        ></mat-divider>
        <svg-icon
            [svgStyle]="{ 'height.px': 16, 'width.px': 16 }"
            src="assets/icons/new-set/chevron-down.svg"
        ></svg-icon>
    </button>

    <div class="flex-grow"></div>
    <app-button
        color="light"
        *ngIf="editMode && !loadingTask"
        (click)="archive()"
    >
        {{ localization.labels.archive_task | translate }}
    </app-button>
    <app-button
        *ngIf="!loadingTask"
        color="primary"
        (click)="submit()"
        [ngClass]="{
            'pointer-events-none': savingTask,
            'pointer-events-auto': !savingTask
        }">
        {{ (editMode ? localization.labels.save : localization.labels.create) | translate }}
        <mat-spinner
            *ngIf="savingTask"
            class="svg-stroke-white"
            [diameter]="18"
        ></mat-spinner>
    </app-button>
</div>

<ng-template
    let-close="close"
    #teamPicker
>
    <app-team-member-picker
        (click)="$event.stopPropagation()"
        (onSelect)="_onTeamMemberSelect($event); close()"
    ></app-team-member-picker>
</ng-template>

<ng-template #loadingTaskTpl>
    <div class="h-full flex flex-col gap-4">
        <div class="animate-pulse flex secondary-bg-color rounded h-12"></div>
        <div class="animate-pulse flex secondary-bg-color rounded h-28"></div>
        <div class="flex items-center gap-3">
            <div class="animate-pulse flex secondary-bg-color rounded h-24 w-24"></div>
            <div class="animate-pulse flex secondary-bg-color rounded h-24 w-24"></div>
            <div class="animate-pulse flex secondary-bg-color rounded h-24 w-28"></div>
        </div>
    </div>
</ng-template>

<ng-template
    let-close="close"
    #datePicker
>
    <div class="w-72 bg-white">
        <mat-calendar
            (selectedChange)="_onDateChange($event); close()"
            [selected]="reminderDate"
            [minDate]="currentDate"
        ></mat-calendar>
    </div>
</ng-template>