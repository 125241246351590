import { Directive, EventEmitter, HostListener, NgModule, Output } from '@angular/core';

@Directive({
  selector: '[appSwipe]'
})
export class SwipeDirective {

  private touchStartX: number = 0;

  @Output() swipeRight = new EventEmitter<void>();
  @Output() swipeLeft = new EventEmitter<void>();

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.touches[0].clientX;
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    const touchEndX = event.changedTouches[0].clientX;
    const swipeDistance = touchEndX - this.touchStartX;

    console.log('clg', swipeDistance);
    if (swipeDistance > 65) {
      this.swipeRight.emit();
    } else if (swipeDistance < -65) {
      this.swipeLeft.emit();
    }
  }

}

@NgModule({
  declarations: [SwipeDirective],
  exports: [SwipeDirective],
  imports: [],
  providers: [],
})
export class SwipeDirectiveModule {
  constructor() { }
}
