
import { Directive, EventEmitter, NgModule, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CountryCode } from 'libphonenumber-js';
import { CountriesCodeListComponent } from '../components/countries-code-list/countries-code-list.component';

@Directive({
  selector: '[countryCodeSelector]',
  host: {
    '(click)': 'handleClick()',
    '(keyup.enter)': 'handleClick()',
  },
})
export class CountryCodeSelectorDirective {

  @Output() countryChange = new EventEmitter<{
    code: CountryCode;
    dial_code: string | null;
  }>()
  constructor(
    private matDialog: MatDialog
  ) { }

  handleClick() {
    this.matDialog.open(CountriesCodeListComponent, {
      height: '420px',
      width: '400px',
      panelClass: 'custom-dialog-container',
    })
      .afterClosed()
      .subscribe((res: { name: string, dial_code: string | null, code: CountryCode }) => {
        res && this.countryChange.emit({ code: res.code, dial_code: res.dial_code })
      });
  }
}

@NgModule({
  declarations: [CountryCodeSelectorDirective],
  exports: [CountryCodeSelectorDirective],
  imports: [],
  providers: [],
})
export class CountryCodeSelectorDirectiveModule {
  constructor() { }
}
