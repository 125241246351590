import { SuccessResponse } from './../../Data/DTO/successResponse';
import { from, Observable, Observer } from 'rxjs';
import { InternalChannel } from '@/app/core/Models/Channel';
import { IndustryTenantTypes, Tenant } from '@/app/core/Models/tenant';
import { EditProfileDto } from '@/app/Data/DTO/EditProfileDto';
import { ProfileImageUpdatResponseDto } from '@/app/Data/DTO/ProfileImageUpdatResponseDto';
import { CompanyLogoUpdatResponseDto } from '@/app/Data/DTO/CompanyLogoUpdatResponseDto';
import { IActivity } from '@/app/Data/services/Activity/activity.service';
import { fingerprintDto } from '@/app/Data/DTO/fingerprintDto';

import { TenantCustomFields } from '@/app/core/Models/tenant';

export abstract class ITenantRepository {
  abstract getInternalChannels(): Observable<InternalChannel[]>;
  abstract getLocalInternalChannels(): Observable<InternalChannel[]>;
  abstract saveInternalChannels(channels: InternalChannel[]): void;
  abstract getSavedTenantProfile(): Observable<Tenant | undefined | null>;
  abstract editProfile(request: EditProfileDto): Observable<SuccessResponse>;
  abstract deleteProfileImage(userId: number): Observable<SuccessResponse>;
  abstract toggleDoNotDisturb(userId: number, status: boolean): Observable<SuccessResponse>
  abstract fetchUserSignatureStatus(): Observable<boolean>;
  abstract setUserSignatureStatus(request: { status: boolean }): Observable<boolean>;
  abstract fetchUserSignatureValue(): Observable<string>;
  abstract setUserSignatureValue(request: { signature_value: string }): Observable<string>;
  abstract updateProfileImage(
    data: FormData,
    userId: number,
    progress$: Observer<any>
  ): Observable<ProfileImageUpdatResponseDto>;
  abstract updateCompanyLogo(
    data: FormData,
    progress$: Observer<any>
  ): Observable<CompanyLogoUpdatResponseDto>;
  abstract sendUserFingerPrint(
    request: fingerprintDto
  ): Observable<SuccessResponse>;
  abstract setAutopilotDefaultState(request: { status: boolean }): Observable<boolean>;
  abstract fetchAutopilotDefaultState(): Observable<boolean>;
  abstract setBusinessName(businessName: string): Observable<string>;
  abstract setPhoneNumber(request: { phone_number: string }): Observable<string>;
  abstract fetchUserPhoneNumber(): Observable<string>;
  abstract getActivity(entity_name: 'company', entity_id: string): Observable<IActivity[]>;
  abstract getTenantCustomFields(): Observable<TenantCustomFields[]>
  abstract createTenantCustomFields(items: Array<{ custom_field_name: string }>): Observable<TenantCustomFields[]>
  abstract updateTenantCustomFields(items: Array<{ custom_field_name: string, custom_field_key_id: string }>): Observable<TenantCustomFields[]>
  abstract deleteTenantCustomFields(custom_field_key_id: string): Observable<string>
  abstract getTenantIndustry(): Observable<IndustryTenantTypes[]>
  abstract updateTenantIndustry(data: Array<{ industry_type: string, description: string, tenant_company_industry_id?: number }>): Observable<IndustryTenantTypes>
}
