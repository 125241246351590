import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BlackFridayAnnocementComponent } from './black-friday-annocement.component';
import { MatIconModule } from '@angular/material/icon';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [BlackFridayAnnocementComponent],
  imports: [CommonModule, AngularSvgIconModule, MatIconModule, ClipboardModule, MatTooltipModule],
  exports: [BlackFridayAnnocementComponent],
})
export class BlackFridayAnnocementModule {}
