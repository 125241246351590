import { Injectable } from '@angular/core';

import { NotesViewModel } from '@/app/Ui/ViewModels/NotesViewModel';
import { ContactViewModel } from './contactViewModel';
import { UsersViewModel } from './usersViewModel';

import { AppState } from '@/app/State/AppState';

import { FetchRecentMessagesAction, OpenConversationThreadAction } from '@/app/State/Inbox/inbox.action';
import {
  CreateAssociateContactToCompanyAction,
  CreateAssociateContactToContactAction,
  DeleteAssociateContactToCompanyAction,
  DeleteAssociateContactToContactAction,
  GetAssociateContactToCompanyAction,
  GetAssociateContactToContactAction,
  GetAssociateDealsToContactAction,
  GetContactAttachments,
  GetContactPipelinesAction,
  GetContactsListAction,
  GetContactTaskAction,
  ShowContactPageAction,
  UpdateAssociateContactToCompanyAction,
  UpdateAssociateContactToContactAction
} from '@/app/State/contacts/contacts.action';

import {
  associatedAttachments,
  associatedPipelines,
  associatedTasks,
  selectContactsState,
  selectLookingupContacts,
  selectLookupContacts,
  selectYobiContactSupportId
} from '@/app/State/contacts/contacts.selector';

import { IContactsRepository } from '@/app/core/IRepositories/IContactsRepository';

import { IContactRepository } from '@/app/core/IRepositories/IContactRepository';

import { RecentMessagesQueryParams } from '@/app/core/Models/Interaction';
import { Contact, ContactAssociationDto, ContactCompanyAssociationDto, ContactsQueryParams, DeleteContactAssociationDto } from '@/app/core/Models/contact';
import { CreateContactUseCase } from '@/app/core/usecases/contact/CreateContactUseCase';
import { EditContactUseCase } from '@/app/core/usecases/contact/EditContactUseCase';

import {
  associatedDeals,
  associatedContacts,
  associatedCompanies
} from '@/app/State/contacts/contacts.selector';

import { Store } from '@ngrx/store';
import {
  Observable,
  distinctUntilChanged,
  startWith,
  tap,
  BehaviorSubject,
  switchMap,
  firstValueFrom
} from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ContactsViewModel {

  contactsState$ = this.store.select(selectContactsState);
  yobiSupportContactId$ = this.store.select(selectYobiContactSupportId);
  lookupContacts$ = this.store.select(selectLookupContacts);
  lookingupContacts$ = this.store.select(selectLookingupContacts);
  contacts$ = this.contactsRepository.getLocalContacts().pipe(startWith([]), distinctUntilChanged());
  currentView$ = new BehaviorSubject<'contact' | 'company'>('contact');
  currentLayout$ = new BehaviorSubject<'list' | 'table'>('list');
  crmContactNavigationActiveRoute$ = new BehaviorSubject<'activity' | 'details' | 'deals' | 'contacts' | 'companies' | 'people' | 'tasks' | 'notes' | 'files' | 'pipelines'>('deals');

  selectedDealContactId: string | undefined;
  selectedStageContactId: string | undefined;

  readonly associatedDealsToContacts$ = this.store.select(associatedDeals);
  readonly associatedDealsToContactsLocal$ = this.contactViewModel.activeContactId$.pipe(
    switchMap((contactId) => this.getAssociatedDealsToContactLocal(contactId as any))
  );

  readonly associatedContactsToContacts$ = this.store.select(associatedContacts);
  readonly associatedContactsToContactsLocal$ = this.contactViewModel.activeContactId$.pipe(
    switchMap((contactId) => this.getAssociatedContactsToContactsLocal(contactId as any))
  );

  readonly associatedTasks$ = this.store.select(associatedTasks);
  readonly associatedTasksLocal$ = this.contactViewModel.activeContactId$.pipe(
    switchMap((contactId) => this.getAssociatedContactTasksLocal(contactId as any))
  );

  readonly associatedCompanies$ = this.store.select(associatedCompanies);
  readonly associatedCompaniesLocal$ = this.contactViewModel.activeContactId$.pipe(
    switchMap((contactId) => this.getAssociatedContactToCompanyLocal(contactId as any))
  );

  readonly associatedAttachments$ = this.store.select(associatedAttachments);
  readonly associatedAttachmentsLocal$ = this.contactViewModel.activeContactId$.pipe(
    switchMap((contactId) => this.getAssociatedAttachmentsContactLocal(contactId as any))
  );

  readonly associatedPipelines$ = this.store.select(associatedPipelines);

  contactNotes$ = this.notesViewModel.contactNotes$;

  pagination: { page: number, page_size: number } = { page: 1, page_size: 30 };
  totalContacts: number;

  constructor(
    private store: Store<AppState>,
    private notesViewModel: NotesViewModel,
    private contactViewModel: ContactViewModel,
    private usersViewModel: UsersViewModel,
    private createContactUseCase: CreateContactUseCase,
    private editContactUseCase: EditContactUseCase,
    private contactsRepository: IContactsRepository,
    private contactRepository: IContactRepository
  ) { }

  getContactAttachments(contact_id: string): void {
    this.store.dispatch(GetContactAttachments({ payload: { contact_id } }));
  }

  getContactsList(params: ContactsQueryParams): void {
    this.store.dispatch(GetContactsListAction({ payload: params }));
  }

  showContact(contact: Contact): void {
    this.store.dispatch(ShowContactPageAction({ payload: contact }));
  }

  createContact(data: Contact, unclassifiedChannel = false): Observable<Contact> {
    return this.createContactUseCase
      .execute(data)
      .pipe(tap(contact => {
        unclassifiedChannel
          && this.store.dispatch(OpenConversationThreadAction({
            payload: {
              threadId: contact.contact_id,
              unClassified: false
            }
          }));
        this.store.dispatch(FetchRecentMessagesAction({ payload: { page: 1, page_size: 30 } }));
      }));
  }

  editContact(data: Contact): Observable<Contact> {
    return this.editContactUseCase.execute(data).pipe(tap(() => {
      const query: RecentMessagesQueryParams = {
        page: 1,
        page_size: 30,
      };
      this.store.dispatch(FetchRecentMessagesAction({ payload: query }));
    }));
  }

  getCRMNavigationActiveRoute(event: 'activity' | 'details' | 'deals' | 'contacts' | 'companies' | 'people' | 'tasks' | 'notes' | 'files' | 'pipelines', contact: Contact): void {
    switch (event) {
      case 'files':
        this.getContactAttachments(contact.contact_id);
        break;
      case 'activity':
        break;
      case 'companies':
        this.getAssociatedContactToCompany({ page: 1, page_size: 20, contact_id: contact.contact_id });
        break;
      case 'deals':
        this.getAssociatedDealsToContact({ page: 1, page_size: 20, contact_id: contact.contact_id });
        break;
      case 'contacts':
        this.getAssociatedContactToContact({ page: 1, page_size: 20, contact_id: contact.contact_id });
        break;
      case 'tasks':
        this.getContactTasks({ page: 1, page_size: 20, contact_id: contact.contact_id });
        break;
      case 'notes':
        this.notesViewModel.fetchNotesByContactId(contact.contact_id);
        break;
      case 'pipelines':
        this.store.dispatch(GetContactPipelinesAction({ payload: { contact_id: contact.contact_id } }))
        break;
    }
  }

  getAssociatedContactToContact(params: ContactsQueryParams): void {
    this.store.dispatch(GetAssociateContactToContactAction({ payload: params }));
  }

  getAssociatedDealsToContact(data: ContactsQueryParams): void {
    this.store.dispatch(GetAssociateDealsToContactAction({ payload: data }));
  }

  getAssociatedDealsToContactLocal(contactId: string) {
    return this.contactRepository.getAssociatedDealsToContactLocal(contactId);
  }

  getAssociatedContactsToContactsLocal(contactId: string) {
    return this.contactRepository.getAssociatedContactToContactLocal(contactId);
  }

  associatedContactToContact(payload: ContactAssociationDto[], dialogId: string): void {
    this.store.dispatch(CreateAssociateContactToContactAction({ payload: { data: payload, dialogId } }));
  }

  removeAssociatedContactToContact(data: DeleteContactAssociationDto): void {
    this.store.dispatch(DeleteAssociateContactToContactAction({ payload: data }));
  }

  updateAssocitedContactType(data: ContactAssociationDto): void {
    this.store.dispatch(UpdateAssociateContactToContactAction({ payload: data }));
  }

  async getContactTasks(data: ContactsQueryParams) {
    const users = await firstValueFrom(this.usersViewModel.usersList$);
    this.store.dispatch(GetContactTaskAction({ payload: { ...data, users } }));
  }

  getAssociatedContactTasksLocal(contactId: string) {
    return this.contactRepository.getAssociatedContactTasksLocal(contactId);
  }

  getAssociatedAttachmentsContactLocal(contactId: string) {
    return this.contactRepository.getContactAttachmentsLocal(contactId);
  }

  getAssociatedContactToCompany(data: ContactsQueryParams): void {
    this.store.dispatch(GetAssociateContactToCompanyAction({ payload: data }));
  }

  getAssociatedContactToCompanyLocal(contactId: string) {
    return this.contactRepository.getAssociatedContactToCompanyLocal(contactId);
  }

  associateContactToCompany(data: ContactCompanyAssociationDto[], dialogId: string): void {
    this.store.dispatch(CreateAssociateContactToCompanyAction({ payload: { data, dialogId } }));
  }

  updateAssociatedContactRelationshipTypeToCompany(data: ContactCompanyAssociationDto): void {
    this.store.dispatch(UpdateAssociateContactToCompanyAction({ payload: data }));
  }

  deleteAssociatedContactToCompany(data: ContactCompanyAssociationDto): void {
    this.store.dispatch(DeleteAssociateContactToCompanyAction({ payload: data }));
  }

  getContactPipelines(contact_id: string, pipeline_id: number) {
    return this.contactsRepository.getContactPipelines(contact_id, pipeline_id);
  }

  selectContactStage(data: Array<{ contact_id: number, yobi_crm_pipeline_id: number, current_stage_id: number, description: string }>) {
    return this.contactsRepository.selectContactStage(data);
  }

  getAllPipelinesContact(contact_id: string) {
    return this.contactsRepository.getAllPipelinesContacts(contact_id);
  }


}
