export enum connections {
  my_connections = 'connections.my_connections',
  add_connection = 'connections.add_connection',
  facebook_description = 'connections.facebook_description',
  facebook_title = 'connections.facebook_title',
  instagram_description = 'connections.instagram_description',
  instagram_title = 'connections.instagram_title',
  linkedin_description = 'connections.linkedin_description',
  linkedin_title = 'connections.linkedin_title',
  phone_description = 'connections.phone_description',
  phone_title = 'connections.phone_title',
  twitter_description = 'connections.twitter_description',
  twitter_title = 'connections.twitter_title',
  viber_description = 'connections.viber_description',
  viber_title = 'connections.viber_title',
  whatsapp_description = 'connections.whatsapp_description',
  whatsapp_title = 'connections.whatsapp_title',
  yobi_assistent_description = 'connections.yobi_assistent_description',
  yobi_assistent_title = 'connections.yobi_assistent_title',
  zoom_description = 'connections.zoom_description',
  zoom_title = 'connections.zoom_title',
}