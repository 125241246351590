import { createAction, props, union } from '@ngrx/store';
import { Notification } from '@/app/core/Models/Notification';

export const GET_NOTIFICATIONS = '[NOTIFICATIONS] Get Notifications';
export const GetNotificationsAction = createAction(
  GET_NOTIFICATIONS,
  props<{ payload: { page: number } }>()
);

export const GET_NOTIFICATIONS_SUCCESS = '[NOTIFICATIONS] Get Notifications Success';
export const GetNotificationsSuccessAction = createAction(
  GET_NOTIFICATIONS_SUCCESS,
  props<{ payload: { page: number, notifications: Notification[] } }>()
);

export const GET_NOTIFICATIONS_FAIL = '[NOTIFICATIONS] Get Notifications fail';
export const GetNotificationsFailAction = createAction(
  GET_NOTIFICATIONS_FAIL,
);

export const DISMISS_ALL_NOTIFICATIONS = '[NOTIFICATIONS] Dismiss All  Notifications';
export const DismissAllNotificationsAction = createAction(
  DISMISS_ALL_NOTIFICATIONS
);

export const GET_UNREAD_NOTIFICATIONS_COUNT = '[NOTIFICATIONS] Get Unread Notifications Count';
export const GetUnreadNotificationsCountAction = createAction(
  GET_UNREAD_NOTIFICATIONS_COUNT
);

export const UPDATE_UNREAD_NOTIFICATIONS_COUNT = '[NOTIFICATIONS] Update Unread Notifications Count';
export const UpdateUnreadNotificationsCountAction = createAction(
  UPDATE_UNREAD_NOTIFICATIONS_COUNT,
  props<{ payload: { count: number } }>()
);

export const UPDATE_NOTIFICATION_AS_READ = '[NOTIFICATIONS] update notification as read';
export const UpdateNotificationAsReadAction = createAction(
  UPDATE_NOTIFICATION_AS_READ,
  props<{ payload: { notification: Notification } }>()
);

const all = union({
  GetNotificationsAction,
  GetNotificationsSuccessAction,
  GetNotificationsFailAction,
  DismissAllNotificationsAction,
  UpdateNotificationAsReadAction,
  GetUnreadNotificationsCountAction,
  UpdateUnreadNotificationsCountAction,
});
export type NotificationsAction = typeof all;