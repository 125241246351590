import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneContactInputComponent } from './phone-contact-input.component';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from '../avatar/avatar.module';
import { CountryCodeSelectorDirectiveModule } from '../../directives/CountryCodeSelector.directive';
import { PhoneFormatterPipeModule } from '../../pipes/phone-formatter.pipe';

@NgModule({
  declarations: [PhoneContactInputComponent],
  imports: [
    CommonModule, 
    ReactiveFormsModule, 
    FormsModule,
    MatMenuModule,
    TranslateModule,
    AvatarModule,
    PhoneFormatterPipeModule,
    CountryCodeSelectorDirectiveModule
  ],
  exports: [PhoneContactInputComponent],
})
export class PhoneContactInputModule { }
