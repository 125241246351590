import { Tag } from '@/app/core/Models/tag';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../components/confirm-dialog/confirmation-dialog.component';
import { TagsViewModel } from '../../ViewModels/tagsViewModel';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {

  @Input() content: Tag;
  @Input() isContact: boolean;
  @Input() contactId: number;
  @Output() deleteTagEvent = new EventEmitter<Tag>();

  constructor(
    private dialog: MatDialog,
    private tagsViewModel: TagsViewModel,
    readonly snackBarService: SnackbarService,
    private localizationViewModel: LocalizationViewModel
  ) { }

  ngOnInit(): void { }

  deleteTag() {
    this.isContact ? this.deleteContactTag() : this.deleteTagEvent.emit(this.content);
  }

  deleteContactTag() {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.localizationViewModel.localization.confirm.delete_tag,
        confirm: this.localizationViewModel.localization.labels.delete,
        warning: true
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.tagsViewModel.deleteTag(this.content)
      }
    });
  }

  get color() {
    return this.content.color ?? '#9AA5FC';
  }
}
