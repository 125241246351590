import { SocketFailedAction } from './../Socket/action';
import { createReducer, on } from '@ngrx/store';
import {
  ChangeAppLangAction,
  PreInitializeApplicationSuccessAction,
  PreInitializeMainApplicationAction,
  PreInitializeMainApplicationFailAction,
  PreInitializeMainApplicationSuccessAction,
  SignOutAction,
} from '@/app/State/app/action';
import { SocketConnectedAction } from '../Socket/action';
import { AppLang } from '@/app/core/Models/lang';

export interface ConfigState {
  lang: AppLang
  appPreInitialized: boolean;
  mainAppInitialized: boolean;
  mainAppInitializing: boolean;
  socket_connected: boolean
}

export const initNotificationsState: ConfigState = {
  lang: AppLang.EN,
  appPreInitialized: false,
  mainAppInitialized: false,
  mainAppInitializing: false,
  socket_connected: false,
};

export const configReducer = createReducer(
  initNotificationsState,

  on(PreInitializeApplicationSuccessAction, (state: ConfigState) => {
    return {
      ...state,
      appPInitialized: true,
    };
  }),
  on(PreInitializeMainApplicationAction, (state: ConfigState) => {
    return {
      ...state,
      mainAppInitializing: true,
    };
  }),
  on(PreInitializeMainApplicationFailAction, (state: ConfigState) => {
    return {
      ...state,
      mainAppInitializing: false,
    };
  }),
  on(PreInitializeMainApplicationSuccessAction, (state: ConfigState) => {
    return {
      ...state,
      mainAppInitialized: true,
      mainAppInitializing: false,
    };
  }),
  on(SignOutAction, (state: ConfigState) => {
    return {
      ...initNotificationsState,
    };
  }),
  on(SocketConnectedAction, (state: ConfigState) => {
    return {
      ...state,
      socket_connected: true,
    };
  }),
  on(SocketFailedAction, (state: ConfigState) => {
    return {
      ...state,
      socket_connected: false,
    };
  }),
  on(ChangeAppLangAction, (state: ConfigState, { payload: { lang } }) => ({
    ...state,
    lang
  }))
);
