export class Stage {
    created_dt: string;
    description: string;
    stage_name: string;
    updated_dt: string;
    yobi_crm_stage_id: string
    stage_label?: string;
    stage_order: number;
    total_companies: number;
    total_contacts: number;
    yobi_crm_pipeline_id: number;

}

export class Pipeline {
    created_dt: string;
    description: string;
    pipeline_name: string;
    updated_dt: string;
    yobi_crm_journey_id: string
    yobi_crm_pipeline_id: string
    stages: Stage[]
}

export class ContactStageAssociation {
    description: string
    stage_name: string
    stage_order: number
    yobi_crm_stage_id: number
    updated_dt: string
    pipeline_name: string
    contacts: Array<{
        address: string
        attachments: any[]
        avatar: string
        contact_id: string
        contact_name: string
        department: string
        email: string
        gender: string
        social_facebook: string
        social_instagram: string
        social_linkedin: string
        social_twitter: string
        title: string
    }>
}

export class CompanyStageAssociation {
    description: string
    stage_name: string
    stage_order: number
    pipeline_name: string
    yobi_crm_stage_id: number
    updated_dt: string
    companies: Array<{
        address_city: string
        address_country: string
        address_postal_code: string
        address_state: string
        address_street: string
        attachments: any[]
        avatar: string
        company_name: string
        company_type: string
        email_address: string
        number_of_employees: number
        phone_number: string
        social_facebook: string
        social_instagram: string
        social_linkedin: string
        social_twitter: string
        yobi_crm_company_id: number
        website_url: string
        owner_name: string
    }>
} 