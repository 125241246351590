import { createReducer, on } from "@ngrx/store";

import { RoomTransactionTypes, RoomActions, RoomDetails } from "@/app/core/Models/Room";

import {
  CreateRoomAction,
  CreateRoomSuccessAction,
  CreateRoomErrorAction,
  UpdateRoomAction,
  UpdateRoomSuccessAction,
  UpdateRoomErrorAction,
  UpdateRoomLogoAction,
  UpdateRoomLogoSuccessAction,
  UpdateRoomLogoErrorAction,
  AddRoomParticipantAction,
  AddRoomParticipantSuccessAction,
  AddRoomParticipantErrorAction,
  RemoveRoomParticipantAction,
  RemoveRoomParticipantSuccessAction,
  RemoveRoomParticipantErrorAction,
  SetActiveRoomActionSuccess,
  FetchTenantRoomsSuccessAction,
  FetchParticipantRoomsSuccessAction,
  FetchTenantRoomsErrorAction,
  FetchParticipantRoomsErrorAction
} from "@/app/State/Room/room.action";

export interface RoomState {
  transacting: boolean;
  transactionType: RoomTransactionTypes | null,
  success: {
    message: string;
    state: boolean;
  };
  error: {
    message: string;
    state: boolean;
  };
  state: {
    actions?: RoomActions;
    roomDetails: RoomDetails | null;
    tenantRooms: RoomDetails[],
    participantRooms: RoomDetails[]
  }
}

export const initRoomStateTree: RoomState = {
  transacting: false,
  transactionType: null,
  success: {
    message: '',
    state: false
  },
  error: {
    message: '',
    state: false
  },
  state: {
    actions: {},
    roomDetails: null,
    tenantRooms: [],
    participantRooms: []
  }
}
export const roomReducers = createReducer(initRoomStateTree,
  on(CreateRoomAction, (state: RoomState, { payload: { room_name, room_private, participants_ids } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: true,
        transactionType: 'createRoom',
        roomDetails: {
          ...state.state.roomDetails,
          room_name: room_name,
          private: room_private,
          participants_ids: participants_ids
        }
      }
    }
  }),
  on(CreateRoomSuccessAction, (state: RoomState, { payload }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: false,
        transactionType: 'createRoom',
        success: {
          message: '',
          state: true,
        },
        error: {
          message: '',
          state: false
        },
        roomDetails: payload
      }
    }
  }),
  on(CreateRoomErrorAction, (state: RoomState, { payload: { room_id, errorMessage } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: false,
        transactionType: 'createRoom',
        success: {
          message: '',
          state: false,
        },
        error: {
          message: errorMessage,
          state: true
        },
        roomDetails: {
          ...state.state.roomDetails,
          room_id
        }
      }
    }
  }),
  on(UpdateRoomAction, (state: RoomState, { payload: { room_name, room_private, participants_ids } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: true,
        transactionType: 'updateRoom',
        roomDetails: {
          ...state.state.roomDetails,
          room_name: room_name,
          private: room_private,
          participants_ids: participants_ids
        }
      }
    }
  }),
  on(UpdateRoomSuccessAction, (state: RoomState, { payload: { room_name, room_private, participants_ids } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: false,
        transactionType: 'updateRoom',
        success: {
          message: '',
          state: true,
        },
        error: {
          message: '',
          state: false
        },
        roomDetails: {
          ...state.state.roomDetails,
          room_name: room_name,
          private: room_private,
          participants_ids: participants_ids
        }
      }
    }
  }),
  on(UpdateRoomErrorAction, (state: RoomState, { payload: { room_id, errorMessage } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: false,
        transactionType: 'updateRoom',
        success: {
          message: '',
          state: false
        },
        error: {
          message: errorMessage,
          state: true
        },
        roomDetails: {
          ...state.state.roomDetails,
          room_id
        }
      }
    }
  }),
  on(UpdateRoomLogoAction, (state: RoomState, { payload: { room_name, room_private, room_id, data } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: true,
        transactionType: 'updateLogo',
        success: {
          message: '',
          state: false
        },
        error: {
          message: '',
          state: false
        },
        roomDetails: {
          ...state.state.roomDetails,
          room_name,
          room_id,
          private: room_private
        }
      }
    }
  }),
  on(UpdateRoomLogoSuccessAction, (state: RoomState, { payload: { room_name, room_private, room_id, avatar, updated_dt } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: false,
        transactionType: 'updateLogo',
        success: {
          message: '',
          state: true
        },
        error: {
          message: '',
          state: false
        },
        roomDetails: {
          ...state.state.roomDetails,
          room_name,
          room_id,
          private: room_private,
          avatar,
          updated_dt
        }
      }
    }
  }),
  on(UpdateRoomLogoErrorAction, (state: RoomState, { payload: { room_id, errorMessage } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: false,
        transactionType: 'updateLogo',
        success: {
          message: '',
          state: false
        },
        error: {
          message: errorMessage,
          state: true
        },
        roomDetails: {
          ...state.state.roomDetails,
          room_id
        }
      }
    }
  }),
  on(AddRoomParticipantAction, (state: RoomState, { payload: { room_id, participants_ids } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: true,
        transactionType: 'addParticipant',
        success: {
          message: '',
          state: false
        },
        error: {
          message: '',
          state: false
        },
        roomDetails: {
          ...state.state.roomDetails,
          participants_ids: participants_ids,
          room_id: room_id
        }
      }
    }
  }),
  on(AddRoomParticipantSuccessAction, (state: RoomState, { payload: { user_id, is_creator } }) => {
    const _participants = state.state.roomDetails?.participants;
    _participants?.push({ user_id, is_creator });
    return {
      ...state,
      state: {
        ...state.state,
        transacting: true,
        transactionType: 'addParticipant',
        success: {
          message: '',
          state: true
        },
        error: {
          message: '',
          state: false
        },
        roomDetails: {
          ...state.state.roomDetails,
          participants: _participants
        }
      }
    }
  }),
  on(AddRoomParticipantErrorAction, (state: RoomState, { payload: { room_id, errorMessage } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: false,
        transactionType: 'addParticipant',
        success: {
          message: '',
          state: true
        },
        error: {
          message: errorMessage,
          state: true
        },
        roomDetails: {
          ...state.state.roomDetails,
          room_id
        }
      }
    }
  }),
  on(RemoveRoomParticipantAction, (state: RoomState, { payload: { room_id, participant_id, isCurrentUser } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: true,
        transactionType: 'removeParticipant',
        success: {
          message: '',
          state: false
        },
        error: {
          message: '',
          state: false
        },
        roomDetails: {
          ...state.state.roomDetails,
          room_id
        }
      }
    }
  }),
  on(RemoveRoomParticipantSuccessAction, (state: RoomState, { payload: { user_id } }) => {
    const _participants = state.state.roomDetails?.participants?.filter((item) => item.user_id !== user_id);
    return {
      ...state,
      state: {
        ...state.state,
        transacting: true,
        transactionType: 'removeParticipant',
        success: {
          message: '',
          state: true
        },
        error: {
          message: '',
          state: false
        },
        roomDetails: {
          ...state.state.roomDetails,
          participants: _participants
        }
      }
    }
  }),
  on(RemoveRoomParticipantErrorAction, (state: RoomState, { payload: { room_id, errorMessage } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: false,
        transactionType: 'removeParticipant',
        success: {
          message: '',
          state: false
        },
        error: {
          message: errorMessage,
          state: true
        },
        roomDetails: {
          ...state.state.roomDetails,
          room_id
        }
      }
    }
  }),
  on(SetActiveRoomActionSuccess, (state: RoomState, { payload }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transactionType: 'setActiveRoom',
        roomDetails: payload
      }
    }
  }),
  on(FetchTenantRoomsSuccessAction, (state: RoomState, { payload: { tenantRooms } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: false,
        transactionType: 'fetchTenantRooms',
        success: {
          message: '',
          state: true
        },
        tenantRooms
      }
    }
  }),
  on(FetchTenantRoomsErrorAction, (state: RoomState, { payload: { errorMessage } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: false,
        transactionType: 'fetchTenantRooms',
        success: {
          message: '',
          state: false
        },
        error: {
          message: errorMessage,
          state: true
        },
      }
    }
  }),
  on(FetchParticipantRoomsSuccessAction, (state: RoomState, { payload: { participantRooms } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: false,
        transactionType: 'fetchParticipantRooms',
        success: {
          message: '',
          state: true
        },
        participantRooms
      }
    }
  }),
  on(FetchParticipantRoomsErrorAction, (state: RoomState, { payload: { errorMessage } }) => {
    return {
      ...state,
      state: {
        ...state.state,
        transacting: false,
        transactionType: 'fetchParticipantRooms',
        success: {
          message: '',
          state: false
        },
        error: {
          message: errorMessage,
          state: true
        }
      }
    }
  })
);
