import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { ConfigContext } from './config.context';
import { IConfiguration } from './IConfig';
//export const APP_CONFIG_TOKEN = new InjectionToken<IConfiguration>('cc');

@NgModule({
  imports: [CommonModule],
})
export class ConfigurationModule {
  static forRoot(
    configContext: ConfigContext
  ): ModuleWithProviders<ConfigurationModule> {
    return {
      ngModule: ConfigurationModule,
      providers: [
        {
          provide: ConfigContext,
          useValue: configContext,
        },
      ],
    };
  }
}
