import { Injectable } from '@angular/core';

import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';
import { ActivityService } from '@/app/Data/services/Activity/activity.service';

import { UsersViewModel } from './usersViewModel';
import { TenantViewModel } from './tenantViewModel';

import {
  AddPeopleCompanyDto,
  CreateCompanyDto,
  UpdateCompanyRequest,
  RemoveAssociatedContactToCompanyDto,
  UpdateAssociatedContactRelationshipTypeDto,
  CreateCompanyNoteDto,
  DeleteCompanyNoteDto,
  DeleteCompanyCustomFieldDto,
  AddCompanyCustomFieldsDto,
  AssociateCompanyToCompanyDto,
  DeleteCompanyAssociationDto
} from '@/app/Data/DTO/companyDto';
import { CompanyImageUpdatResponseDto } from '@/app/Data/DTO/companyImageUpdatResponseDto';
import { AppState } from '@/app/State/AppState';
import {
  AssociateContactToCompanyAction,
  CompanyCreateAction,
  CompanyUpdateAction,
  DeleteAssociateContactToCompanyAction,
  DeleteCompanyAction,
  GetAssociateContactToCompanyAction,
  GetCompaniesListAction,
  ResetCompanyStateAction,
  ShowCompanyPageAction,
  UpdateAssociateContactToCompanyAction,
  CreateCompanyNoteAction,
  UpdateCompanyNoteAction,
  DeleteCompanyNoteAction,
  GetCompanyNoteAction,
  GetCompanyTaskAction,
  GetCompanyCustomFieldsAction,
  DeleteCompanyCustomFieldsAction,
  CreateCompanyCustomFieldsAction,
  UpdateCompanyCustomFieldsAction,
  GetAssociateCompanyToCompanyAction,
  CreateAssociateCompanyToCompanyAction,
  DeleteAssociateCompanyToCompanyAction,
  UpdateAssociateCompanyToCompanyAction,
  GetAssociateDealsToCompanyAction,
  SetActiveCompanyId,
  GetCompanyAttachments,
  GetCompanyPipelinesAction
} from '@/app/State/companies/companies.action';
import {
  associatedContacts,
  associatedCompanies,
  associatedNotes,
  associatedTasks,
  associatedCustomFields,
  selectActiveCompanyState,
  selectCompaniesState,
  associatedDeals,
  associatedPipelines, newlyCreatedCompany,
  associatedAttachments
} from '@/app/State/companies/companies.selector';
import { ICompanyRepository } from '@/app/core/IRepositories/ICompanyRepository';
import { CompaniesQueryParams, Company, CompanyNotes } from '@/app/core/Models/company';
import { ErrorApiReponse } from '@/app/Data/services/Networking/ApiResponse';

import { Store } from '@ngrx/store';
import {
  Observer,
  distinctUntilChanged,
  shareReplay,
  startWith,
  tap,
  catchError,
  Observable,
  switchMap,
  filter,
  firstValueFrom,
  BehaviorSubject
} from 'rxjs';
import { getBodyAttachment } from '@/app/Utilities/helpers';

@Injectable({
  providedIn: 'root',
})
export class CompaniesViewModel {

  readonly companiesState$ = this.store.select(selectCompaniesState);
  readonly companies$ = this.companyRepository.getLocalCompanies().pipe(startWith([]), distinctUntilChanged());
  readonly activeCompanyState$ = this.store.select(selectActiveCompanyState).pipe(shareReplay({ bufferSize: 1, refCount: true }));
  readonly associatedContactsToCompanies$ = this.store.select(associatedContacts);
  readonly associatedContactsLocal$ = this.activeCompanyState$.pipe(
    filter((company) => !!company?.companyId),
    switchMap((selectedCompany) => this.getAssociatedContactsToCompanyLocal(selectedCompany?.companyId as string))
  );
  readonly associatedNotes$ = this.store.select(associatedNotes);
  readonly associatedNotesLocal$ = this.activeCompanyState$.pipe(
    filter((company) => !!company?.companyId),
    switchMap((selectedCompany) => this.getAssociatedCompanyNotesLocal(selectedCompany?.companyId as string))
  );
  readonly associatedTasks$ = this.store.select(associatedTasks);
  readonly associatedTasksLocal$ = this.activeCompanyState$.pipe(
    filter((company) => !!company?.companyId),
    switchMap((selectedCompany) => this.getAssociatedCompanyTasksLocal(selectedCompany?.companyId as string))
  );
  readonly associatedCustomFields$ = this.store.select(associatedCustomFields);
  readonly associatedCustomFieldsLocal$ = this.activeCompanyState$.pipe(
    filter((company) => !!company?.companyId),
    switchMap((selectedCompany) => this.getAssociatedCompanyCustomFieldsLocal(selectedCompany?.companyId as string))
  );

  readonly associatedCompaniesToCompanies$ = this.store.select(associatedCompanies);
  readonly associatedCompaniesToCompaniesLocal$ = this.activeCompanyState$.pipe(
    filter((company) => !!company?.companyId),
    switchMap((selectedCompany) => this.getAssociatedCompaniesToCompanyLocal(selectedCompany?.companyId as string))
  );

  readonly associatedDealsToCompanies$ = this.store.select(associatedDeals);
  readonly associatedDealsToCompaniesLocal$ = this.activeCompanyState$.pipe(
    filter((company) => !!company?.companyId),
    switchMap((selectedCompany) => this.getAssociatedDealsToCompanyLocal(selectedCompany?.companyId as string))
  );

  readonly associatedAttachments$ = this.store.select(associatedAttachments);
  readonly associatedAttachmentsLocal$ = this.activeCompanyState$.pipe(
    filter((company) => !!company?.companyId),
    switchMap((selectedCompany) => this.getAssociatedAttachmentsCompanyLocal(selectedCompany?.companyId as string))
  );

  readonly companyActivities$ = this.activeCompanyState$.pipe(
    filter((company) => !!company?.companyId),
    switchMap((selectedCompany) => this.getCompanyActivitiesLocal(selectedCompany?.companyId as string))
  );

  readonly associatedPipelines$ = this.store.select(associatedPipelines);

  pagination: { page: number, page_size: number } = { page: 1, page_size: 30 };
  sort: { column: string, direction: "asc" | "desc" } = { column: "created_dt", direction: "desc" };

  crmCompaniesNavigationActiveRoute$ = new BehaviorSubject<'activity' | 'details' | 'deals' | 'contacts' | 'companies' | 'people' | 'tasks' | 'notes' | 'files' | 'pipelines'>('activity');
  companyNotesSaving = new BehaviorSubject<boolean>(false);

  companyNotesUploadProgress$: Observer<any>;

  selectedCompanyNote: CompanyNotes;
  selectedDealCompanyId: string | undefined;

  companyDrawerId: 'import-companies' | 'create-industry' | 'create-industry-form' | '';

  companyTableColumns = ['avatar', 'company_name', 'company_type', 'industry', 'address', 'website_url', 'email', 'created_dt', 'action'];
  enableBulkAction = false;
  checkAll = false;
  selectedCompanyIds: string[] = [];

  stashCompany: Company[] = [];
  totalCompanies: number;

  pageState$ = new BehaviorSubject<{ pageIndex: number, pageSize: number, searchValue: string }>({ pageIndex: 1, pageSize: 30, searchValue: '' });
  checkedOne$ = new BehaviorSubject<{ ids: string[] } | null>(null);
  sortState$ = new BehaviorSubject<{ column: string, direction: string }>(this.sort);

  tenantCustomFields$ = this.tenantViewModel.getTenantCustomFields();
  industriesUnderTenant$ = this.tenantViewModel.getTenantIndustry();

  createdCompany = new BehaviorSubject<Company | null>(null);
  newlyCreatedCompany = this.store.select(newlyCreatedCompany);

  constructor(
    private store: Store<AppState>,
    private companyRepository: ICompanyRepository,
    private snackBarService: SnackbarService,
    private activityService: ActivityService,
    private usersViewModel: UsersViewModel,
    private tenantViewModel: TenantViewModel
  ) { }

  getCompanyAttachments(yobi_crm_company_id: string): void {
    this.store.dispatch(GetCompanyAttachments({ payload: { yobi_crm_company_id } }));
  }

  getCompaniesList(params: CompaniesQueryParams): void {
    this.store.dispatch(GetCompaniesListAction({ payload: params }));
  }

  getRawCompaniesLists(params: CompaniesQueryParams) {
    return this.companyRepository.getCompanies(params);
  }

  setActiveCompanyId(id: string): void {
    this.store.dispatch(SetActiveCompanyId({ payload: { companyId: id } }));
  }

  showCompany(company: Company): void {
    this.store.dispatch(ShowCompanyPageAction({ payload: company }));
  }

  resetCompanyState(): void {
    this.store.dispatch(ResetCompanyStateAction());
  }

  createCompany(data: CreateCompanyDto, dialogId?: string): void {
    this.store.dispatch(CompanyCreateAction({ payload: { data, dialogId } }));
  }

  editCompany(data: UpdateCompanyRequest, dialogId?: string): void {
    this.store.dispatch(CompanyUpdateAction({ payload: { data, dialogId } }));
  }

  associatedContactsToCompany(data: AddPeopleCompanyDto[], dialogId: string): void {
    this.store.dispatch(AssociateContactToCompanyAction({ payload: { data, dialogId } }));
  }

  associatedCompanyToCompany(data: AssociateCompanyToCompanyDto[], dialogId: string): void {
    this.store.dispatch(CreateAssociateCompanyToCompanyAction({ payload: { data, dialogId } }));
  }

  getCompanyById(id: string) {
    return this.companyRepository.getCompanyById(id);
  }

  getLocalCompanyById(id: string) {
    return this.companyRepository.getLocalCompany(id);
  }

  getAssociatedContactsToCompanyLocal(companyId: string) {
    return this.companyRepository.getAssociatedContactsToCompanyLocal(companyId);
  }

  getAssociatedCompaniesToCompanyLocal(companyId: string) {
    return this.companyRepository.getAssociatedCompanyToCompanyLocal(companyId);
  }

  getAssociatedAttachmentsCompanyLocal(companyId: string) {
    return this.companyRepository.getCompanyAttachmentsLocal(companyId);
  }

  getAssociatedDealsToCompanyLocal(companyId: string) {
    return this.companyRepository.getAssociatedDealsToCompanyLocal(companyId);
  }

  getAssociatedCompanyNotesLocal(companyId: string) {
    return this.companyRepository.getAssociatedCompanyNotesLocal(companyId);
  }

  getAssociatedCompanyTasksLocal(companyId: string) {
    return this.companyRepository.getAssociatedCompanyTasksLocal(companyId);
  }

  getAssociatedCompanyCustomFieldsLocal(companyId: string) {
    return this.companyRepository.getAssociatedCompanyCustomFieldsLocal(companyId);
  }

  deleteCompany(companyIds: string[]): void {
    this.store.dispatch(DeleteCompanyAction({ payload: companyIds }));
  }

  deleteLocalCompanyDetails(id: string): void {
    this.companyRepository.deleteLocalCompany(id);
  }

  uploadCompanyLogo(data: FormData, companyId: string, progress$: Observer<any>): Observable<CompanyImageUpdatResponseDto> {
    return this.companyRepository.uploadCompanyLogo(data, companyId, progress$).pipe(
      tap(({ avatar }) => {
        this.snackBarService.openAlert({ message: `Company logo has been updated.`, type: "success" });
        this.companyRepository.updateLocalCompanyDetails(companyId, { avatar });
      }),
      catchError((httpError: ErrorApiReponse<any>) => {
        this.snackBarService.openAlert({
          message: httpError.message,
          type: 'failure'
        });
        throw httpError;
      }));
  }

  deleteCompanyLogo(companyId: string): Observable<CompanyImageUpdatResponseDto> {
    return this.companyRepository.deleteCompanyLogo(companyId).pipe(
      tap((response) => {
        this.snackBarService.openAlert({ message: `Company logo has been deleted.`, type: "success" });
        this.companyRepository.updateLocalCompanyDetails(companyId as any, { avatar: '' });
      }),
      catchError((httpError: ErrorApiReponse<any>) => {
        this.snackBarService.openAlert({
          message: httpError.message,
          type: 'failure'
        });
        throw httpError;
      }));
  }

  mutateNewCompany(company: Company, retainState: boolean): void {
    const _c = Object.assign(new Company(), company);
    retainState && this.showCompany(_c);
  }

  getAssociatedContactsToCompany(params: CompaniesQueryParams): void {
    this.store.dispatch(GetAssociateContactToCompanyAction({ payload: params }));
  }

  getAssociatedCompanyToCompany(params: CompaniesQueryParams): void {
    this.store.dispatch(GetAssociateCompanyToCompanyAction({ payload: params }));
  }

  async getCompanyActivity(company_id: string) {
    const response = await this.activityService.getActivity('company', company_id);
    this.companyRepository.updateLocalCompanyDetails(company_id, { 'activities': response });
  }

  getCRMNavigationActiveRoute(event: 'activity' | 'details' | 'deals' | 'contacts' | 'companies' | 'people' | 'tasks' | 'notes' | 'files' | 'pipelines', company: Company): void {
    switch (event) {
      case 'files':
        this.getCompanyAttachments(company.yobi_crm_company_id);
        break;
      case 'activity':
        this.getCompanyActivity(company.yobi_crm_company_id);
        break;
      case 'details':
        this.getCompanyCustomFields({ page: 1, page_size: 20, companyId: company.yobi_crm_company_id });
        break;
      case 'deals':
        this.getAssociatedDealsToCompany({ page: 1, page_size: 20, companyId: company.yobi_crm_company_id });
        break;
      case 'companies':
        this.getAssociatedCompanyToCompany({ page: 1, page_size: 20, companyId: company.yobi_crm_company_id });
        break;
      case 'people':
        this.getAssociatedContactsToCompany({ page: 1, page_size: 20, companyId: company.yobi_crm_company_id });
        break;
      case 'tasks':
        this.getCompanyTasks({ page: 1, page_size: 20, companyId: company.yobi_crm_company_id });
        break;
      case 'notes':
        this.getCompanyNotes({ page: 1, page_size: 20, companyId: company.yobi_crm_company_id });
        break;
      case 'pipelines':
        this.getPipelinesCompany(company.yobi_crm_company_id);
        break;
    }
  }

  removeAssociatedContactToCompanyDto(data: RemoveAssociatedContactToCompanyDto): void {
    this.store.dispatch(DeleteAssociateContactToCompanyAction({ payload: data }));
  }

  updateAssociatedContactRelationShipType(data: UpdateAssociatedContactRelationshipTypeDto): void {
    this.store.dispatch(UpdateAssociateContactToCompanyAction({ payload: data }));
  }

  createCompanyNote(data: CreateCompanyNoteDto): void {
    if (data.attachments?.length) {
      data = { ...data, _attachments: getBodyAttachment(data.attachments) };
    }
    this.store.dispatch(CreateCompanyNoteAction({ payload: data }));
  }

  updateCompanyNote(data: CreateCompanyNoteDto): void {
    if (data.attachments?.length) {
      data = { ...data, _attachments: getBodyAttachment(data.attachments) };
    }
    this.store.dispatch(UpdateCompanyNoteAction({ payload: data }));
  }

  deleteCompanyNote(data: DeleteCompanyNoteDto): void {
    this.store.dispatch(DeleteCompanyNoteAction({ payload: data }));
  }

  getPipelinesCompany(company_id: any) {
    this.store.dispatch(GetCompanyPipelinesAction({ payload: { company_id: company_id } }));
  }

  async getCompanyNotes(data: CompaniesQueryParams) {
    const users = await firstValueFrom(this.usersViewModel.usersList$);
    this.store.dispatch(GetCompanyNoteAction({ payload: { ...data, users } }));
  }

  async getCompanyTasks(data: CompaniesQueryParams) {
    const users = await firstValueFrom(this.usersViewModel.usersList$);
    this.store.dispatch(GetCompanyTaskAction({ payload: { ...data, users } }));
  }

  getCompanyCustomFields(data: CompaniesQueryParams): void {
    this.store.dispatch(GetCompanyCustomFieldsAction({ payload: data }));
  }

  editCompanyNoteScrollTop(): void {
    const cont = document.getElementById("selected-company") as HTMLDivElement;
    cont.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  deleteCompanyCustomField(data: DeleteCompanyCustomFieldDto): void {
    this.store.dispatch(DeleteCompanyCustomFieldsAction({ payload: data }));
  }

  createCompanyCustomFields(data: AddCompanyCustomFieldsDto[], dialogId: string): void {
    this.store.dispatch(CreateCompanyCustomFieldsAction({ payload: { data, dialogId } }));
  }

  updateCompanyCustomFields(data: AddCompanyCustomFieldsDto[], dialogId: string): void {
    this.store.dispatch(UpdateCompanyCustomFieldsAction({ payload: { data, dialogId } }));
  }

  removeAssociatedCompanyToCompany(data: DeleteCompanyAssociationDto): void {
    this.store.dispatch(DeleteAssociateCompanyToCompanyAction({ payload: data }));
  }

  updateAssociatedCompanyType(data: AssociateCompanyToCompanyDto): void {
    this.store.dispatch(UpdateAssociateCompanyToCompanyAction({ payload: data }));
  }

  getAssociatedDealsToCompany(data: CompaniesQueryParams): void {
    this.store.dispatch(GetAssociateDealsToCompanyAction({ payload: data }));
  }

  importCompaniesViaCSV(data: FormData, redis_id: string) {
    return this.companyRepository.importCompaniesViaCSV(data, redis_id);
  }

  getCompanyActivitiesLocal(companyId: string) {
    return this.companyRepository.getCompanyActivityLocal(companyId);
  }

  updateTenantIndustries(data: Array<{ industry_type: string, description: string, tenant_company_industry_id?: number }>) {
    return this.tenantViewModel.updateTenantIndustry(data).pipe(
      tap(() => {
        this.snackBarService.openAlert({ message: `Tenant records has been updated.`, type: "success" });
      }));
  }

  getCompanyPipelines(company_id: number, pipeline_id: number) {
    return this.companyRepository.getCompanyPipelines(company_id, pipeline_id);
  }

  selectCompanyStage(data: Array<{ yobi_crm_company_id: number, yobi_crm_pipeline_id: number, current_stage_id: number, description: string }>) {
    return this.companyRepository.selectCompanyStage(data);
  }

  getAllPipelinesCompany(company_id: number) {
    return this.companyRepository.getAllPipelinesCompany(company_id);
  }

} 
