import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, map, pluck, switchMap, tap } from 'rxjs';
import { HttpService } from '../services/Networking/HttpService';
import { DatabaseService } from '../services/Database/database.service';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { SuccessResponse } from '../DTO/successResponse';
import { IIntegrationsRepository } from '@/app/core/IRepositories/IIntegrationsRepository';
import { IntegrationsResult } from '@/app/core/Models/integrations';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { MicrosoftTeam, MicrosoftTeamChannel } from '@/app/core/Models/integrations/microsoft-teams';
import { OutlookContact } from '@/app/core/Models/integrations/outlook';
import { GoogleContact } from '@/app/core/Models/integrations/google';
import { HubspotParam } from '@/app/core/Models/integrations/hubspot';
import { SlackParam, SlackChannel } from '@/app/core/Models/integrations/slack';
import { HubSpotParamDto } from '../DTO/integrations/HubSpotDto';
import { PipeDeriveContactsResponseDto, PipedriveContactDetaislDto } from '../DTO/integrations/PipedriveDto';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsRepository implements IIntegrationsRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) { }

  loadLinkedIntegrations() {
    const requestURL = `${environment.apiURL}integrations/isLinked`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessResponse<IntegrationsResult>).results),
    );
  }

  
  
  /* -------------------------------- Calendly -------------------------------- */
  linkCalendly(): Observable<string> {
    const requestURL = `${environment.apiURL}calendly/start`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res: any) => {
        return (res as { url: string }).url ?? res?.results
      }
      ))
  }

  removeCalendly(): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}calendly/uninstall`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => {
        return (res as SuccessResponse)
      }
      ))
  }

  requestCalendlyLink(): Observable<string> {
    const requestURL = `${environment.apiURL}calendly/invite_link`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res: any) => {
        return (res as { calendly_link: string }).calendly_link ?? res?.results
      }
      ))
  }
  
  
  /* --------------------------------- Google --------------------------------- */
  linkGoogle(): Observable<SuccessResponse<any>> {
    const requestURL = `${environment.apiURL}email/google/authorize`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { is_contact: true },
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<any>;
        return res;
      })
    );
  }

  unlinkGoogle(): Observable<SuccessResponse<any>> {
    const requestURL = `${environment.apiURL}email/google/disconnect`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { is_contact: true },
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<any>;
        return res;
      })
    );
  }

  retrieveGoogleContacts(data: { has_next: string }): Observable<SuccessResponse<{ contacts: GoogleContact[] }>> {
    const requestURL = `${environment.apiURL}email/google/contacts`;
    const options = this.httpService.createOptions(HttpRequestMethod.post, this.httpService.createHeader(), requestURL, data, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<{ contacts: GoogleContact[] }>;
        return res;
      })
    );
  }

  importGoogleContacts(contacts: GoogleContact[]): Observable<SuccessResponse<any>> {
    const requestURL = `${environment.apiURL}email/google/contacts/import`;
    const options = this.httpService.createOptions(HttpRequestMethod.post, this.httpService.createHeader(), requestURL, { contacts }, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse;
        return res;
      })
    );
  }

  exportGoogleContacts(contacts: any[]): Observable<SuccessResponse<any>> {
    const requestURL = `${environment.apiURL}email/google/contacts/export`;
    const options = this.httpService.createOptions(HttpRequestMethod.post, this.httpService.createHeader(), requestURL, { contacts }, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse;
        return res;
      })
    );
  }
  
  
  /* --------------------------------- Hubspot -------------------------------- */
  importHubspotContacts(): Observable<SuccessResponse<string>> {
    const requestURL = `${environment.apiURL}hubspot/contact/import`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { hub_id: '' },
      false, null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessResponse))
    );
  }

  exportHubspotContacts(): Observable<SuccessResponse<string>> {
    const requestURL = `${environment.apiURL}hubspot/contact/sync`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { hub_id: '' },
      false, null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessResponse))
    );
  }

  linkHubspot(): Observable<string> {
    const requestURL = `${environment.apiURL}hubspot/authorize`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as { result: string }).result)
    );
  }

  hubspotParams(): Observable<SuccessResponse<HubspotParam[]>> {
    const requestURL = `${environment.apiURL}hubspot/toggle_attributes`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessResponse<HubspotParam[]>))
    );
  }

  hubspotUpdateParam(data: HubSpotParamDto): Observable<SuccessResponse<HubspotParam>> {
    const requestURL = `${environment.apiURL}hubspot/toggle_attributes`;
    const options = this.httpService.createOptions(HttpRequestMethod.put, this.httpService.createHeader(), requestURL, data, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessResponse<HubspotParam>))
    )
  }

  removeHubspot(): Observable<boolean> {
    const requestURL = `${environment.apiURL}hubspot/uninstall`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as { status: string }).status === "200")
    );
  }
  
  
  /* -------------------------------- Microsoft ------------------------------- */
  linkMicrosoftTeams(): Observable<SuccessResponse<string | Object>> {
    const requestURL = `${environment.apiURL}microsoft_teams/authorize`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<string | Object>;
        return res;
      })
    );
  }

  unlinkMicrosoftTeams(): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}microsoft_teams/disconnect`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse;
        return res;
      })
    );
  }

  retrieveteams() {
    const requestURL = `${environment.apiURL}microsoft_teams/teams`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<MicrosoftTeam[]>;
        return res.results;
      })
    );
  }

  retrieveTeamChannels(id: string) {
    const requestURL = `${environment.apiURL}microsoft_teams/teams/${id}/channels`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<MicrosoftTeamChannel[]>;
        return res.results;
      })
    );
  }

  importTeamChannels(data: { teamId: string, channelId: string }): Observable<SuccessResponse<Object>> {
    const requestURL = `${environment.apiURL}microsoft_teams/teams/${data.teamId}/channels/${data.channelId}`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<Object>;
        return res;
      })
    );
  }

  saveEvent(event: any) {
    const requestURL = `${environment.apiURL}microsoft_teams/calendar/events`;
    const options = this.httpService.createOptions(HttpRequestMethod.post, this.httpService.createHeader(), requestURL, event, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse;
        return res;
      })
    );
  }
  
  
  /* --------------------------------- Outlook -------------------------------- */
  linkOutlook(): Observable<SuccessResponse<any>> {
    const requestURL = `${environment.apiURL}outlook/authorize`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<any>;
        return res;
      })
    );
  }

  unlinkOutlook(): Observable<SuccessResponse<any>> {
    const requestURL = `${environment.apiURL}outlook/disconnect`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<any>;
        return res;
      })
    );
  }

  retrieveOutlookContacts(data: { has_next: string }): Observable<SuccessResponse<{ contacts: OutlookContact[] }>> {
    const requestURL = `${environment.apiURL}outlook/contacts`;
    const options = this.httpService.createOptions(HttpRequestMethod.post, this.httpService.createHeader(), requestURL, data, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse<{ contacts: OutlookContact[] }>;
        return res;
      })
    );
  }

  importOutlookContacts(contacts: OutlookContact[]): Observable<SuccessResponse<any>> {
    const requestURL = `${environment.apiURL}outlook/contacts/import`;
    const options = this.httpService.createOptions(HttpRequestMethod.post, this.httpService.createHeader(), requestURL, { contacts }, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse;
        return res;
      })
    );
  }

  exportOutlookContacts(contacts: any[]): Observable<SuccessResponse<any>> {
    const requestURL = `${environment.apiURL}outlook/contacts/export`;
    const options = this.httpService.createOptions(HttpRequestMethod.post, this.httpService.createHeader(), requestURL, { contacts }, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse;
        return res;
      })
    );
  }
  
  
  /* -------------------------------- Pipdrive -------------------------------- */
  linkPipdrive(): Observable<string> {
    const requestURL = `${environment.apiURL}pipedrive/authorize`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as (SuccessResponse & { result: string })).result)
    );
  }

  unlinkPipdrive(): Observable<boolean> {
    const requestURL = `${environment.apiURL}pipedrive/uninstall`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessResponse).success)
    );
  }

  getPipedriveContacts(): Observable<PipeDeriveContactsResponseDto> {
    const requestURL = `${environment.apiURL}pipedrive/contacts`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { has_next: '' },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  importPipedriveContacts(contacts: PipedriveContactDetaislDto[]): Observable<string> {
    const requestURL = `${environment.apiURL}pipedrive/contacts/import`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { contacts },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.message;
      })
    );
  }

  exportPipedriveContacts(contacts: PipedriveContactDetaislDto[]): Observable<string> {
    const requestURL = `${environment.apiURL}pipedrive/contacts/export`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { contacts },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.message;
      })
    );
  }
  
  
  /* --------------------------------- Shopify -------------------------------- */
  removeShopify(): Observable<boolean> {
    const requestURL = `${environment.apiURL}shopify/uninstall`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      tap(res => console.log(res)),
      map((res) => !!(res as any)) // TODO verify response object to extract appropriate field
    );
  }
  
  
  /* ---------------------------------- Slack --------------------------------- */
  linkSlack(): Observable<string> {
    const requestURL = `${environment.apiURL}slack/authorize`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessResponse<string>).results)
    );
  }

  slackParams(): Observable<SuccessResponse<SlackParam[]>> {
    const requestURL = `${environment.apiURL}slack/parameters`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessResponse<SlackParam[]>))
    );
  }

  slackUpdateParam(data: { attribute_name: string, attribute_value: string }[]): Observable<boolean> {
    const requestURL = `${environment.apiURL}slack/parameters`;
    const options = this.httpService.createOptions(HttpRequestMethod.put, this.httpService.createHeader(), requestURL, data, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessResponse<string>).success)
    )
  }

  slackChannels(): Observable<SuccessResponse<SlackChannel[]>> {
    const requestURL = `${environment.apiURL}slack/channels_list`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessResponse<SlackChannel[]>))
    );
  }

  removeSlack(): Observable<boolean> {
    const requestURL = `${environment.apiURL}slack/uninstall`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessResponse<string>).status === 200)
    );
  }
  
  
  /* --------------------------------- Zapier --------------------------------- */
  zapierLogin(data: any): Observable<{ message: string, status: 404 } | { url: string, status: 200 }> {
    const requestURL = `${environment.apiURL}zapier/login`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      map((item) => {
        return (item as SuccessApiResponse<{ message: string, status: 404 } | { url: string, status: 200 }>)
          .results!;
      })
    );
  }

  disconnectZapier(): Observable<any> {
    const requestURL = `${environment.apiURL}zapier/unlink`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      map((item) => {
        return item
      })
    );
  }
  
  
  /* --------------------------------- Zendesk -------------------------------- */
  linkZendesk(data: { zd_email: string, zd_user_token: string, zd_subdomain: string }): Observable<SuccessResponse<any>> {
    const requestURL = `${environment.apiURL}zendesk/link`;
    const options = this.httpService.createOptions(HttpRequestMethod.post, this.httpService.createHeader(), requestURL, data, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => (item as SuccessResponse<any>))
    );
  }

  unlinkZendesk(): Observable<SuccessResponse<any>> {
    const requestURL = `${environment.apiURL}zendesk/unlink`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => (item as SuccessResponse<any>))
    );
  }
  
  
  /* ---------------------------------- Zoom ---------------------------------- */
  linkZoom(): Observable<string> {
    const requestURL = `${environment.apiURL}zoom/start`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as { url: string }).url)
    );
  }

  removeZoom(): Observable<boolean> {
    const requestURL = `${environment.apiURL}zoom/disconnect`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as { result: string, status: string }).result === 'zoom unlinked with success')
    );
  }
  requestZoomLink(): Observable<string> {
    const requestURL = `${environment.apiURL}zoom/create_room_link`;
    const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => {
        return (res as { meeting_url: string }).meeting_url
      })
    );
  }

  // isSlackLinked() {
  //   const requestURL = `${environment.apiURL}slack/isLinked`;
  //   const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
  //   return this.httpService.execute(options).pipe(
  //     pluck('results'),
  //     map((res) => (res as { isLinked: boolean }).isLinked)
  //   )
  // }

  // isCalendlyLinked() {
  //   const requestURL = `${environment.apiURL}calendly/isLinked`;
  //   const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
  //   return this.httpService.execute(options).pipe(
  //     pluck('results'),
  //     map((res) => (res as { isLinked: boolean }).isLinked)
  //   )
  // }

  // isHubSpotLinked() {
  //   const requestURL = `${environment.apiURL}hubspot/isLinked`;
  //   const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
  //   return this.httpService.execute(options).pipe(
  //     pluck('results'),
  //     map((res) => (res as { result: boolean, status: number }).result)
  //   )
  // }

  // isZoomLinked() {
  //   const requestURL = `${environment.apiURL}zoom/tenant_linked`;
  //   const options = this.httpService.createOptions(HttpRequestMethod.get, this.httpService.createHeader(), requestURL, null, false, null);
  //   return this.httpService.execute(options).pipe(
  //     pluck('results'),
  //     map((res) => (res as { result: string, status: number }).status == 200)
  //   )
  // }
}
