<div class="flex flex-col rounded-xl">
  <div
    class="flex items-center"
    *ngIf="!inlineMode"
  >
    <div class="font-semibold text-xl">
      {{localization.labels.add_credit_card | translate}}
    </div>
    <svg-icon
      class="text-pale hover:text-warning cursor-pointer ml-auto"
      [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
      src="assets/icons/new-set/close.svg"
      mat-dialog-close
    >
    </svg-icon>
  </div>

  <br>
  <br>

  <form
    (ngSubmit)="onSubmit()"
    [formGroup]="form"
    class="flex flex-col gap-4"
  >
    <div class="flex flex-col gap-2">
      <label #ngxStripeCardContainer>
        <span>{{localization.subscription.card_information | translate}}</span>
        <ngx-stripe-card
          class="block input-style"
          [elementsOptions]="elementsOptions"
          [options]="cardOptions"
          (focus)="ngxStripeCardContainer.classList.add('focused')"
          (blur)="ngxStripeCardContainer.classList.remove('focused')"
          (change)="onCardInfosChange($event)"
        ></ngx-stripe-card>
      </label>
      <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
    </div>


    <label>
      <span>{{localization.subscription.card_holder_name | translate}}</span>
      <input
        [ngClass]="{invalid: form.get('name')!.touched && form.get('name')!.invalid}"
        [placeholder]="(localization.subscription.card_holder_name | translate) + '*'"
        class="input-style"
        formControlName="name"
      >
    </label>

    <ng-container *ngIf="requestAddress">
      <label>
        <span>{{localization.subscription.billing_address | translate}}</span>
        <div class="flex items-start flex-col gap-3 w-full">
          <input
            class="input-style"
            [ngClass]="{invalid: form.get('address_line1')!.touched && form.get('address_line1')!.invalid}"
            [placeholder]="(localization.terms.address_line1 | translate) + '*'"
            formControlName="address_line1"
          >
          <input
            class="input-style"
            [ngClass]="{invalid: form.get('address_line2')!.touched && form.get('address_line2')!.invalid}"
            [placeholder]="(localization.terms.address_line2 | translate)"
            formControlName="address_line2"
          >
          <input
            class="input-style"
            [ngClass]="{invalid: form.get('address_city')!.touched && form.get('address_city')!.invalid}"
            [placeholder]="(localization.terms.address_state | translate) + '*'"
            formControlName="address_city"
          >
          <div class="flex items-center gap-3 w-full">
            <input
              class="input-style"
              [ngClass]="{invalid: form.get('address_state')!.touched && form.get('address_state')!.invalid}"
              [placeholder]="(localization.terms.address_city | translate) + '*'"
              formControlName="address_state"
            >
            <input
              class="input-style"
              [ngClass]="{invalid: form.get('address_zip')!.touched && form.get('address_zip')!.invalid}"
              [placeholder]="(localization.terms.address_zip | translate) + '*'"
              formControlName="address_zip"
            >
          </div>
          <mat-select
            [ngClass]="{invalid: form.get('address_country')!.touched && form.get('address_country')!.invalid}"
            class="input-style"
            formControlName="address_country"
          >
            <mat-option
              *ngFor="let country of countries"
              [value]="country.code"
            >
              {{ country.name }}
            </mat-option>
          </mat-select>
        </div>
      </label>
    </ng-container>


    <div class="flex flex-row items-center mt-6">
      <app-button
        *ngIf="!inlineMode"
        color="light"
      >
        <div
          class="px-6"
          mat-dialog-close
        >
          {{localization.labels.cancel | translate}}
        </div>
      </app-button>
      <app-button
        type="submit"
        color="primary"
        class="ml-auto"
      >
        <span class="px-6 text-base">
          <ng-container *ngIf="!loading; else spinner">
            {{localization.labels.save_card | translate}}
          </ng-container>
        </span>
      </app-button>
    </div>
  </form>
</div>

<ng-template #spinner>
  <mat-spinner diameter="22"> </mat-spinner>
</ng-template>