import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MentionMember } from '@/app/core/Models/Mention';
import { TaskDetail } from '@/app/core/Models/Task';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';

@Component({
  selector: 'app-task-description',
  templateUrl: './task-description.component.html',
  styleUrls: ['./task-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskDescriptionComponent {
  @Input() task: TaskDetail;
  @Input() mentionMembers: MentionMember[] = [];

  localization = this.localiztionViewModel.localization
  constructor(
    private localiztionViewModel: LocalizationViewModel) {}
    
  detectMention(text: string) {
    let currentText = text;
    this.mentionMembers.forEach((user) => {
      let search = text.search(`@${user.name}`);
      if (search >= 0) {
        currentText = currentText.replace(`@${user.name}`, (mention: any) => {
          return `<span class="mentioned-user">@${user.name}</span>`;
        });
      }
    });
    return currentText;
  }

  stripeText(text: string) {
    return text.replace(/<[^>]*>?/gm, '');
  }

  detectUrls(text: string) {
    return this.stripeText(text).replace(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g, '<a class="text-primary" target="_blank" rel="nofollow" href="$1">$1</a>')
  }

  get taskBody(): TaskDetail['body'] {
    return this.task.body!.map(section => 
      section.type == 'text' 
        ? { ...section, text: this.detectUrls(section.text)} 
        : section
    )
  }
}
