import {
  DialogConfig, DIALOG_DATA
} from '@/app/Utilities/Dialog/dialog-tokens';
import { ConnectionPositionPair, Overlay, OverlayConfig, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Injectable, Injector, NgModule, OnInit, TemplateRef, Type } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private overlay: Overlay, private injector: Injector) { }

  open<R, D>(content: string | TemplateRef<any> | Type<any>, dialogConfig: DialogConfig<D>) {
    const config = this.getConfigs(dialogConfig);
    const overlay = this.overlay.create(config);
    const { disableClose, closeOnClick } = dialogConfig;
    const myOverlayRef = new MyOverlayRef<R, D>(overlay, content, dialogConfig.data, { disableClose, closeOnClick });
    myOverlayRef.context = dialogConfig.context;
    overlay.attach(
      new ComponentPortal(
        OverlayComponent,
        null,
        this.createInjector(myOverlayRef)
      )
    );
    return myOverlayRef;
  }

  private getConfigs(dialogConfig: DialogConfig): OverlayConfig {
    const config = new OverlayConfig({
      hasBackdrop: dialogConfig.hasBackdrop ?? true,
      width: dialogConfig.width,
      height: dialogConfig.height,
      minWidth: dialogConfig.minWidth,
      maxWidth: dialogConfig.maxWidth,
      minHeight: dialogConfig.minHeight,
      maxHeight: dialogConfig.maxHeight,
      panelClass: dialogConfig.panelClass,
      backdropClass: dialogConfig.backdropClass,
    });
    if (dialogConfig.origin instanceof HTMLElement) {
      config.scrollStrategy = this.overlay.scrollStrategies.reposition()
      config.positionStrategy = this.getOverlayPosition(dialogConfig);
    } else {
      const positionStrategy = this.overlay.position().global()
      if (dialogConfig.top || dialogConfig.bottom) {
        dialogConfig.top && positionStrategy.top(dialogConfig.top);
        dialogConfig.bottom && positionStrategy.bottom(dialogConfig.bottom);
      } else {
        positionStrategy.centerVertically()
      }
      if (dialogConfig.right || dialogConfig.left) {
        dialogConfig.right && positionStrategy.right(dialogConfig.right);
        dialogConfig.left && positionStrategy.left(dialogConfig.left);
      } else {
        positionStrategy.centerHorizontally()
      }
      config.positionStrategy = positionStrategy
    }
    return config;
  }

  private getOverlayPosition(config: DialogConfig) {
    const positions = [
      new ConnectionPositionPair(
        { originX: 'start', originY: 'bottom' },
        { overlayX: 'start', overlayY: 'top' },
        0,
        (config.offsetY ?? 0)
      ),
      new ConnectionPositionPair(
        { originX: 'start', originY: 'top' },
        { overlayX: 'start', overlayY: 'bottom' },
        0,
        -(config.offsetY ?? 0)
      ),
      new ConnectionPositionPair(
        { originX: 'end', originY: 'bottom' },
        { overlayX: 'end', overlayY: 'top' },
        0,
        (config.offsetY ?? 0)
      ),
      new ConnectionPositionPair(
        { originX: 'end', originY: 'top' },
        { overlayX: 'end', overlayY: 'bottom' },
        0,
        -(config.offsetY ?? 0)
      ),
      new ConnectionPositionPair(
        { originX: 'end', originY: 'top' },
        { overlayX: 'end', overlayY: 'bottom' }
      ),
    ];
    config.position && positions.unshift(config.position)
    return this.overlay
      .position()
      .flexibleConnectedTo(config.origin!)
      .withPositions(positions)
      .withFlexibleDimensions(false)
      .withDefaultOffsetX(config.offsetX ?? 0)
      .withDefaultOffsetY(config.offsetY ?? 0)
  }

  private createInjector = (ref: MyOverlayRef) => {
    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: MyOverlayRef, useValue: ref },
        { provide: DIALOG_DATA, useValue: ref.data },
      ]
    })
  }
}

export class MyOverlayRef<R = any, D = any> {
  afterClosed$ = new Subject<OverlayCloseEvent<R>>();
  afterInit$ = new Subject<void>();
  context?: Object;

  constructor(
    public overlay: OverlayRef,
    public content: string | TemplateRef<any> | Type<any>,
    public data?: D,
    public config: Pick<DialogConfig, 'disableClose' | 'closeOnClick'> = {}
  ) {
    if (!config.disableClose) {
      overlay.backdropClick().pipe(
        takeUntil(this.afterClosed$),
        tap(() => this.close({ type: 'BackdropClick' }))
      ).subscribe();
    }
  }

  close(payload: OverlayCloseEvent<R>) {
    this.overlay.dispose();
    this.afterClosed$.next(payload);
    this.afterClosed$.complete();
  }
}

interface OverlayCloseEvent<R> {
  type?: 'BackdropClick' | 'Close' | 'Escape' | 'InnerClick';
  result?: R;
}

@Component({
  selector: 'app-overlay',
  template: `
    <ng-container [ngSwitch]="contentType">

      <ng-container *ngSwitchCase="'string'">
        <div class="box">
          <div [innerHTML]="content"></div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'template'">
        <ng-container *ngTemplateOutlet="$any(content); context: context"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'component'">
        <ng-container *ngComponentOutlet="$any(content)"></ng-container>
      </ng-container>

    </ng-container>
  `,
  styles: [`
  :host {
    width: 100%;
  }
`],
  host: {
    '(click)': 'handleClick()',
    '(document:keydown)': 'handleKeydown($event)'
  },
})

export class OverlayComponent implements OnInit, AfterViewInit {
  contentType: 'template' | 'string' | 'component';
  content: string | TemplateRef<any> | Type<any>;
  context: Object;

  constructor(private ref: MyOverlayRef) { }

  handleClick() {
    this.ref.config.closeOnClick && !this.ref.config.disableClose && this.ref.close({ type: 'InnerClick' })
  }

  handleKeydown(event: KeyboardEvent) {
    event.key === 'Escape' && !this.ref.config.disableClose && this.ref.close({ type: 'Escape' });
  }

  close() {
    this.ref.close({ type: 'Close' });
  }

  ngOnInit() {
    this.content = this.ref.content;
    if (typeof this.content === 'string') {
      this.contentType = 'string';
    } else if (this.content instanceof TemplateRef) {
      this.contentType = 'template';
      this.context = { ...(this.ref.context ?? {}), close: this.ref.close.bind(this.ref) };
    } else {
      this.contentType = 'component';
    }
  }

  ngAfterViewInit(): void {
    this.ref.afterInit$.next();
    this.ref.afterInit$.complete();
  }
}

@NgModule({
  declarations: [OverlayComponent],
  imports: [
    CommonModule,
    OverlayModule,
    PortalModule
  ]
})
export class MyOverlayModule { }
