import { Directive, ElementRef, EventEmitter, HostListener, Input, NgModule, Output } from '@angular/core';

@Directive({
  selector: '[resizable]'
})
export class ResizableDirective {

  @Input() resizable: 'right' | 'top' | 'left' | 'bottom';
  @Input() resizableProperty: 'explecite' | 'flexBasis';
  @Input() handleWidth = 3;
  @Input() minWidth = '0';
  @Input() minHeight = '0';
  @Output() onResize = new EventEmitter<number>();
  private isResizing = false
  private originalX: number;
  private originalWidth: number;
  private originalY: number;
  private originalHeight: number;
  private resizableElement: HTMLElement
  private flexible = false
  resizeHandle: HTMLDivElement


  constructor(private el: ElementRef) { }

  ngAfterViewInit(): void {
    this.resizableElement = this.el.nativeElement as HTMLElement;
    this.resizableElement.style.position = 'relative';

    const resizeHandle = document.createElement('div');
    this.resizeHandle = resizeHandle
    resizeHandle.classList.add('absolute', 'hover:bg-cyan-600', 'rounded-full', 'z-10');
    switch (this.resizable) {
      case 'right':
        resizeHandle.style.right = '-1px';
        resizeHandle.style.top = '0';
        resizeHandle.style.bottom = '0';
        resizeHandle.style.width = `${this.handleWidth}px`;
        resizeHandle.style.cursor = 'col-resize';
        break;
      case 'top':
        resizeHandle.style.top = '-1px';
        resizeHandle.style.left = '0';
        resizeHandle.style.right = '0';
        resizeHandle.style.height = `${this.handleWidth}px`;
        resizeHandle.style.cursor = 'row-resize';
        break;
      case 'left':
        resizeHandle.style.left = '-1px';
        resizeHandle.style.top = '0';
        resizeHandle.style.bottom = '0';
        resizeHandle.style.width = `${this.handleWidth}px`;
        resizeHandle.style.cursor = 'col-resize';
        break;
      case 'bottom':
        resizeHandle.style.bottom = '-1px';
        resizeHandle.style.left = '0';
        resizeHandle.style.right = '0';
        resizeHandle.style.height = `${this.handleWidth}px`;
        resizeHandle.style.cursor = 'row-resize';
        break;
    }
    this.resizableElement.appendChild(resizeHandle);
    resizeHandle.addEventListener('mousedown', (e) => {
      this.onMouseDown(e);
    });
  }

  get direction() {
    return this.resizable === 'left' || this.resizable === 'right' ? 'horizontal' : 'vertical'
  }

  onMouseDown(e: MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
    this.isResizing = true;
    this.resizeHandle.classList.add('bg-cyan-600')
    switch (this.resizable) {
      case 'left':
      case 'right':
        this.originalX = e.clientX;
        this.originalWidth = parseFloat(getComputedStyle(this.resizableElement, null).getPropertyValue('width'));
        break;
      case 'top':
      case 'bottom':
        this.originalY = e.clientY;
        this.originalHeight = parseFloat(getComputedStyle(this.resizableElement, null).getPropertyValue('height'));
        break;
    }

    document.addEventListener('mousemove', this.resize.bind(this));
    document.addEventListener('mouseup', this.stopResize.bind(this));
  }

  resize(e: MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
    if (!this.isResizing) return;
    const newSize =
      this.resizable === 'right' ? this.originalWidth + (e.clientX - this.originalX)
        : this.resizable === 'left' ? this.originalWidth - (e.clientX - this.originalX)
          : this.resizable === 'top' ? this.originalHeight - (e.clientY - this.originalY)
            : this.resizable === 'bottom' ? this.originalHeight + (e.clientY - this.originalY) : 0
    this.onResize.emit(newSize);
    !this.resizableProperty
      ? this.onResize.emit(newSize)
      : this.resizableProperty === 'flexBasis'
        ? (this.resizableElement.style[this.resizableProperty] = `${newSize}px`) && !this.flexible && this.setFlexible()
        : this.direction === 'horizontal'
          ? (this.resizableElement.style['minWidth'] = `${newSize}px`) && (this.resizableElement.style['maxWidth'] = `${newSize}px`) && (this.resizableElement.style['width'] = `${newSize}px`)
          : (this.resizableElement.style['minHeight'] = `${newSize}px`) && (this.resizableElement.style['maxHeight'] = `${newSize}px`) && (this.resizableElement.style['height'] = `${newSize}px`);
  }

  stopResize() {
    this.isResizing = false;
    this.resizeHandle.classList.remove('bg-cyan-600')
    document.removeEventListener('mousemove', this.resize.bind(this));
    document.removeEventListener('mouseup', this.stopResize.bind(this));
  }

  setFlexible() {
    this.flexible = true
    switch (this.direction) {
      case 'horizontal':
        this.resizableElement.style.maxWidth = '100vw';
        this.resizableElement.style.minWidth = this.minWidth;
        break;
      case 'vertical':
        this.resizableElement.style.maxHeight = '100vh'
        this.resizableElement.style.minHeight = this.minHeight
        break;
    }
  }
}


@NgModule({
  declarations: [ResizableDirective],
  exports: [ResizableDirective],
  imports: [],
  providers: [],
})
export class ResizableDirectiveModule {
  constructor() { }
}
