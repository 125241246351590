import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentsViewerComponent } from './attachments-viewer.component';
import { NgxViewerModule } from 'ngx-viewer';
import { MatIconModule } from '@angular/material/icon';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FilenameFormatterModule } from '../../pipes/filename-formatter.pipe';



@NgModule({
  declarations: [
    AttachmentsViewerComponent
  ],
  imports: [
    CommonModule,
    NgxViewerModule,
    MatIconModule,
    AngularSvgIconModule,
    FilenameFormatterModule
  ],
  exports: [
    AttachmentsViewerComponent
  ]
})
export class AttachmentsViewerModule { }
