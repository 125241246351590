import { FullTextSearchQueryDto } from '@/app/Data/DTO/FullTextSearachDto';
import { Observable } from 'rxjs';
import { FullTextSearchResult, LocalFullTextSearch } from '../Models/fullTextSearch';

export abstract class IFullTextSearachRepository {
  abstract getFullTextSearch(
    query: FullTextSearchQueryDto
  ): Observable<FullTextSearchResult>

  abstract saveFullTextSearchResult(data: LocalFullTextSearch): void

  abstract getLocalFullTextSearch(value: string): Observable<LocalFullTextSearch | undefined>

  abstract deleteLocalFullTextSearchResult(value: string): void

  abstract getAllLocalFullTextSearch(): Observable<LocalFullTextSearch[]>
}

