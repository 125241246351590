<div class="mat-dialog-header text-dark border-b">
    <h2 class="m-0 text-lg font-semibold">
        {{ (editMode ? localization.labels.edit_task : localization.labels.create_task) | translate }}
    </h2>
    <svg-icon
        class="text-pale hover:text-warning cursor-pointer ml-auto"
        [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
        src="assets/icons/new-set/close.svg"
        (click)="close()"
    >
    </svg-icon>
</div>
<!-- -------------------------------- Body --------------------------------- -->
<div
    class="mat-dialog-body flex flex-col gap-4 text-dark"
    [formGroup]="taskForm"
>
    <ng-container *ngIf="!loadingTask; else loadingTaskTpl">
        <!-- -------------------------------- title -------------------------------- -->
        <app-text-input
            formControlName="title"
            [placeholder]="localization.placeholder.task_title_placeholder | translate"
            [label]="(localization.labels.task_title | translate) + '*'"
            [invalid]=" taskForm.get('title')?.invalid && taskForm.get('title')?.touched "
        ></app-text-input>
        <!-- ----------------------------- description ----------------------------- -->
        <div class="flex flex-col gap-1">
            <label class="font-semibold text-mild">{{ localization.terms.description | translate }}</label>
            <app-formatted-textarea
                formControlName="description"
                [placeholder]="localization.placeholder.task_description_placeholder | translate"
                [members]="mentions$ | async"
                (filesModelChange)="onFiles($event)"
            ></app-formatted-textarea>
        </div>

          <!-- STATUS -->
          <div class="flex flex-row gap-1 w-full items-center text-gray-600">
            <div 
                [matMenuTriggerFor]="statusMenu"
                class="flex cursor-pointer w-full">
                <app-text-input
                    class="w-full"
                    formControlName="status"
                    [label]="'Status'">
                </app-text-input>
            </div>
        </div>

        <!-- deadline-->
        <div class="flex flex-row gap-1 w-full items-center text-gray-600">
            <div 
                #calendarTrigger="matMenuTrigger"
                [matMenuTriggerFor]="calendarMenu"
                class="flex rc cursor-pointer w-full">
                <app-text-input
                    class="w-full"
                    formControlName="deadlineViewModel"
                    [label]="'Due Date'">
                </app-text-input>
            </div>
        </div>

        <!-- TAGS -->
        <div class="flex flex-row gap-1 w-full items-center text-gray-600">
            <div class="flex flex-row gap-1 lc">
                <span class="mr-2">
                    <svg-icon
                        class="text-gray-400"
                        src="assets/icons/new-set/tag.svg"
                        [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'fill': '' }">
                    </svg-icon>
                </span>
                <span>Tags</span>
            </div>
            <div class="flex cursor-pointer max-h-32 overflow-y-scroll">
                <div class="flex flex-row gap-2.5">
                    <ng-container>
                        <div class="px-2">
                            <app-tags
                                [tags]="tagsModel?.value"
                                [isContact]="false"
                                (onGetTag)="onGetTagOnTask($event)">
                            </app-tags>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

          <!-- PRIORITY -->
          <div class="flex flex-row gap-1 w-full items-center text-gray-600">
            <div 
                [matMenuTriggerFor]="priorityMenu"
                class="flex cursor-pointer w-full">
                <app-text-input
                    class="w-full"
                    formControlName="task_priority"
                    [label]="'Priority'">
                </app-text-input>
            </div>
        </div>

        <!-- ------------------------------ reminder ------------------------------- -->
        <div class="flex gap-4 xs:flex-col">
            <app-input-wrapper
                class="flex-1"
                [label]="'Remind me' | translate"
            >
                <mat-select
                    select
                    [formControl]="reminder"
                    [disableOptionCentering]="true"
                >
                    <mat-option
                        *ngFor="let i of ['none', 'in_15_minutes', 'in_30_minutes', 'in_one_week', 'in_one_day', 'in_two_days', 'custom']"
                        [value]="i"
                    >
                        {{ ('labels.' + i) | translate }}
                    </mat-option>
                </mat-select>
            </app-input-wrapper>
            <div
                *ngIf="reminder.value === 'custom'"
                class="flex flex-col gap-1"
                style="flex: 2.3 1 0%;"
            >
                <label class="font-semibold text-mild">{{ localization.labels.custom | translate }}</label>
                <div class="flex items-centrer gap-4">
                    <app-input-wrapper
                        [dropDownPanel]="datePicker"
                        class="flex-1"
                    >
                        <p
                            input
                            class="cursor-text w-full flex items-center"
                        >
                            {{ shouldShowActualDate() ? (reminderDate | date: 'mediumDate') : (currentDate.toDateString() == reminderDate.toDateString() ? 'Today' : reminderDate | date: 'mediumDate') }}
                        </p>
                        <svg-icon
                            suffix
                            src="assets/icons/new-set/calendar.svg"
                            class="text-mild mr-4"
                            [svgStyle]="{ 'height.px': 18, 'width.px': 18 }"
                        ></svg-icon>
                    </app-input-wrapper>
                    <app-input-wrapper class="flex-1">
                        <input
                            input
                            #timePicker
                            type="time"
                            placeholder="Set end time"
                            [(ngModel)]="reminderTime"
                            [ngModelOptions]="{standalone: true}"
                            [min]="minimumTime"
                        />
                        <svg-icon
                            suffix
                            src="assets/icons/new-set/clock.svg"
                            class="text-mild mr-4 cursor-pointer"
                            (click)="$any(timePicker).showPicker()"
                            [svgStyle]="{ 'height.px': 18, 'width.px': 18 }"
                        ></svg-icon>
                    </app-input-wrapper>

                </div>
            </div>
        </div>
        <!-- ----------------------------- attachments ----------------------------- -->
        <div
            *ngIf="attachments.length"
            class="flex flex-col gap-1 w-full"
        >
            <label class="font-semibold text-mild">{{ localization.labels.task_attachments | translate }}</label>
            <app-attachments-viewer
                [attachments]="attachments"
                [canRemove]="true"
                (remove)="removeAttachment($event)"
            ></app-attachments-viewer>
        </div>

        <div class="flex gap-2 flex-col">
            <mat-checkbox
                *ngIf="data?.channel_id"
                (change)="_toggleTaskLink()"
                [ngModel]="linkToContact"
                [ngModelOptions]="{standalone: true}"
            >
                <span> {{(linkToContact
                    ? localization.labels.linked_to_conversation
                    : localization.labels.not_linked_to_conversation) | translate
                    }}</span>
            </mat-checkbox>
        </div>
    </ng-container>
</div>
<!-- ------------------------------- Footer -------------------------------- -->
<div class="mat-dialog-footer border-t">
    <button
        class="rounded-md h-11 w-11 flex items-center justify-center highlight-bg-color hover:secondary-bg-color shrink-0"
        appFilePicker
        [multiple]="true"
        [processFiles]="true"
        (filesModelChange)="onFiles($event)"
    >
        <svg-icon
            src="assets/icons/new-set/attachment.svg"
            class="text-mild"
            [svgStyle]="{ 'height.px': 21, 'width.px': 21 }"
        ></svg-icon>
    </button>
    <!-- [dropDownPanel]="teamPicker" -->
    <button
        class="rounded-md h-11 flex items-center justify-center shrink-0 px-2 highlight-bg-color hover:secondary-bg-color text-dark gap-2"
        [ngClass]="assignees?.invalid && assignees?.touched ? 'border-2 border-warning' : ''"
        [matTooltip]="localization.labels.assign_to_team_member | translate"
        [matMenuTriggerFor]="assigneesMenu">
        <div class="flex items-center gap-2">

            <span 
                *ngIf="!assignedUserViewModel?.value?.length"
                class="truncate">
                Assignees
            </span>

            <ng-container
                *ngIf="assignedUserViewModel?.value?.length">
                <div class="flex items-center space-x-[-8px]">
                
                    <!-- FIRST ASSIGNEE -->
                    <div class="rounded-full text-white flex items-center justify-center text-sm font-bold border-2 border-white">
                        <app-avatar
                            class="w-8 h-8"
                            [fontSize]="11"
                            [url]="assignedUserViewModel?.value[0]!.avatar"
                            [name]="assignedUserViewModel?.value[0]!.given_name + ' ' + assignedUserViewModel?.value[0]!.family_name">
                        </app-avatar>
                    </div>

                    <!-- SECOND ASSIGGNEE -->
                    <ng-container
                        *ngIf="assignedUserViewModel?.value?.length >= 2">
                        <img
                            [src]="assignedUserViewModel?.value[1]!.avatar"
                            [alt]="assignedUserViewModel?.value[1]!.given_name + ' ' + assignedUserViewModel?.value[1]!.family_name"
                            class="w-8 h-8 rounded-full border-2 border-white"/>
                                        
                        <!-- REMAINING COUNT ASSIGNEES -->
                        <div 
                            *ngIf="assignedUserViewModel?.value?.length - 2"
                            class="h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center text-sm font-semibold border-2 border-white">
                            <span>+{{assignedUserViewModel?.value?.length - 2}}</span>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <!-- <app-avatar
                [url]="mentionedMember?.img"
                [name]="mentionedMember?.name"
                [fontSize]="11"
                class="h-7 w-7 shrink-0"
            ></app-avatar>
            <span class="truncate">
                {{ mentionedMember ? mentionedMember.name : localization.labels.assign_to | translate }}
            </span> -->
        </div>
        <mat-divider
            class="h-6 secondary-border-color"
            [vertical]="true"
        ></mat-divider>
        <svg-icon
            [svgStyle]="{ 'height.px': 16, 'width.px': 16 }"
            src="assets/icons/new-set/chevron-down.svg"
        ></svg-icon>
    </button>

    <div class="flex-grow"></div>
    <app-button
        color="light"
        *ngIf="editMode && !loadingTask"
        (click)="archive()"
    >
        {{ localization.labels.archive_task | translate }}
    </app-button>
    <app-button
        *ngIf="!loadingTask"
        color="primary"
        (click)="submit()"
        [ngClass]="{
            'pointer-events-none': savingTask,
            'pointer-events-auto': !savingTask
        }">
        {{ (editMode ? localization.labels.save : localization.labels.create) | translate }}
        <mat-spinner
            *ngIf="savingTask"
            class="svg-stroke-white"
            [diameter]="18"
        ></mat-spinner>
    </app-button>
</div>

<ng-template
    let-close="close"
    #teamPicker
>
    <app-team-member-picker
        (click)="$event.stopPropagation()"
        (onSelect)="_onTeamMemberSelect($event); close()"
    ></app-team-member-picker>
</ng-template>

<ng-template #loadingTaskTpl>
    <div class="h-full flex flex-col gap-4">
        <div class="animate-pulse flex secondary-bg-color rounded h-12"></div>
        <div class="animate-pulse flex secondary-bg-color rounded h-28"></div>
        <div class="flex items-center gap-3">
            <div class="animate-pulse flex secondary-bg-color rounded h-24 w-24"></div>
            <div class="animate-pulse flex secondary-bg-color rounded h-24 w-24"></div>
            <div class="animate-pulse flex secondary-bg-color rounded h-24 w-28"></div>
        </div>
    </div>
</ng-template>

<ng-template
    let-close="close"
    #datePicker
>
    <div class="w-72 bg-white">
        <mat-calendar
            (selectedChange)="_onDateChange($event); close()"
            [selected]="reminderDate"
            [minDate]="currentDate"
        ></mat-calendar>
    </div>
</ng-template>

<mat-menu 
    #calendarMenu="matMenu" 
    [hasBackdrop]="false">
    <ng-template matMenuContent>
        <div class="w-72 h-full bg-white flex flex-col p-0.5">
            <svg-icon
                (click)="calendarTrigger.closeMenu()"
                class="cursor-pointer hover:text-warning flex justify-end"
                [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
                src="assets/icons/new-set/close.svg">
            </svg-icon>
            <mat-calendar
                [minDate]="todayDate"
                (click)="$event.stopPropagation();$event.preventDefault();"
                (selectedChange)="onSelectDate($event); closeDatePicker()"
                [(selected)]="currentDate">
            </mat-calendar>
        </div>
    </ng-template>
</mat-menu>

<mat-menu 
    #priorityMenu="matMenu">
    <ng-template matMenuContent let-actions="actions">
        <button
            (click)="onChangePriority(4)"
            mat-menu-item>
            <div 
                class="flex items-center gap-3">
                <svg-icon
                    class="text-orange-600"
                    src="assets/icons/new-set/high.svg"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                <span class="text-base">High</span>
            </div>
        </button>
        <button
            (click)="onChangePriority(3)"
            mat-menu-item>
            <div 
                class="flex items-center gap-3">
                <svg-icon
                    src="assets/icons/new-set/medium.svg"
                    class="text-yellow-600"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                <span class="text-base">Medium</span>
            </div>
        </button>
        <button
            (click)="onChangePriority(2)"
            mat-menu-item>
            <div 
                class="flex items-center gap-3 Low">
                <svg-icon
                    src="assets/icons/new-set/low.svg"
                    class="text-blue-600"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                <span class="text-base">Low</span>
            </div>
        </button>
        <button
            (click)="onChangePriority(1)"
            mat-menu-item>
            <div 
                class="flex items-center gap-3 None">
                <svg-icon
                    class="text-gray-600"
                    src="assets/icons/new-set/circle-off.svg"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                <span class="text-base">None</span>
            </div>
        </button>
    </ng-template>
</mat-menu>

<mat-menu 
    #statusMenu="matMenu">
    <ng-template matMenuContent let-actions="actions">
        <button
            (click)="onChangeStatus('Open')"
            [ngClass]="{
                'bg-gray-200 pointer-events-none': status?.value === 'Open'
            }"
            mat-menu-item>
            <div class="flex items-center gap-3">
                <svg-icon
                    src="assets/icons/new-set/task-status/upcoming.svg"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                Open
            </div>
        </button>
        <button
            (click)="onChangeStatus('In Progress')"
            [ngClass]="{
                'bg-gray-200 pointer-events-none': status?.value === 'In Progress'
            }"
            mat-menu-item>
            <div class="flex items-center gap-3">
                <svg-icon
                    src="assets/icons/task/task-status/inProgress.svg"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                In Progress
            </div>
        </button>
        <button
            (click)="onChangeStatus('Done')"
            [ngClass]="{
                'bg-gray-200 pointer-events-none': status?.value === 'Done'
            }"
            mat-menu-item>
            <div class="flex items-center gap-3">
                <svg-icon
                    src="assets/icons/task/task-status/done.svg"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                Done
            </div>
        </button>
        <!-- <button
            (click)="onChangeStatus('trash')"
            [ngClass]="{
                'bg-gray-200 pointer-events-none': status?.value === 'trash'
            }"
            mat-menu-item>
            <div class="flex items-center gap-3">
                <svg-icon
                    src="assets/icons/new-set/trash.svg"
                    [svgStyle]="{
                        'height.px': 20,
                        'width.px': 20
                    }">
                </svg-icon>
                Trash
            </div>
        </button> -->
    </ng-template>
</mat-menu>

<mat-menu 
    yPosition="above"
    [hasBackdrop]="false"
    #assigneesMenu="matMenu">
    <ng-template 
        matMenuContent>
        <app-input-wrapper>
            <input
                (click)="$event.stopPropagation();$event.preventDefault();"
                input
                type="text"
                autocomplete="off"
                [formControl]="userSearchControl"
                placeholder="Team member search">
        </app-input-wrapper>
        <button
            mat-menu-item
            *ngFor="let user of usersList$ | async;">
            <div class="flex flex-row items-center gap-4 text-base" (click)="selectAssignees(user)">
                <ng-container 
                    *ngIf="((user?.firstName ?? '') + ' ' + (user?.lastName ?? '')) as username">
                    <div class="flex flex-row justify-center items-center gap-2.5">
                        <app-avatar
                            class="w-8 h-8 shrink-0"
                            [url]="user.avatar"
                            [name]="username"
                            [fontSize]="10">
                        </app-avatar>
                        <span class="text-base truncate">{{username}}</span>
                    </div>
                </ng-container>
                <svg-icon
                    *ngIf="isSelected(user.user_id)"
                    class="ml-auto text-gray-600"
                    src="assets/icons/new-set/check.svg"
                    [svgStyle]="{ 'width.px': 22, 'height.px': 22 }">
                </svg-icon>
            </div>
        </button>

    </ng-template>
</mat-menu>