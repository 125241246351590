import { HubSpotParamDto } from "@/app/Data/DTO/integrations/HubSpotDto";
import { SuccessResponse } from "@/app/Data/DTO/successResponse";
import { Observable } from "rxjs";
import { IntegrationsResult } from "../Models/integrations";
import { GoogleContact } from "../Models/integrations/google";
import { MicrosoftTeam, MicrosoftTeamChannel } from "../Models/integrations/microsoft-teams";
import { OutlookContact } from "../Models/integrations/outlook";
import { HubspotParam } from "../Models/integrations/hubspot";
import { SlackChannel, SlackParam } from "../Models/integrations/slack";
import { PipeDeriveContactsResponseDto, PipedriveContactDetaislDto } from "@/app/Data/DTO/integrations/PipedriveDto";

export abstract class IIntegrationsRepository {
    abstract loadLinkedIntegrations(): Observable<IntegrationsResult>;
    /* -------------------------------- Calendly -------------------------------- */
    abstract linkCalendly(): Observable<string>;
    abstract removeCalendly(): Observable<SuccessResponse>;
    abstract requestCalendlyLink(): Observable<string>;
    /* --------------------------------- Google --------------------------------- */
    abstract linkGoogle(): Observable<SuccessResponse<any>>;
    abstract unlinkGoogle(): Observable<SuccessResponse<any>>;
    abstract retrieveGoogleContacts(data: { has_next: string }): Observable<SuccessResponse<{ contacts: GoogleContact[] }>>;
    abstract importGoogleContacts(contacts: GoogleContact[]): Observable<SuccessResponse<any>>
    abstract exportGoogleContacts(contacts: any[]): Observable<SuccessResponse<any>>
    /* --------------------------------- Hubspot -------------------------------- */
    abstract linkHubspot(): Observable<string>;
    abstract hubspotParams(): Observable<SuccessResponse<HubspotParam[]>>;
    abstract hubspotUpdateParam(data: HubSpotParamDto): Observable<SuccessResponse<HubspotParam>>
    abstract removeHubspot(): Observable<boolean>
    abstract importHubspotContacts(): Observable<SuccessResponse<string>>
    abstract exportHubspotContacts(): Observable<SuccessResponse<string>>
    /* -------------------------------- Microsoft ------------------------------- */
    abstract linkMicrosoftTeams(): Observable<SuccessResponse<string | Object>>;
    abstract unlinkMicrosoftTeams(): Observable<SuccessResponse>
    abstract retrieveteams(): Observable<MicrosoftTeam[]>;
    abstract retrieveTeamChannels(id: string): Observable<MicrosoftTeamChannel[]>;
    abstract importTeamChannels(data: { teamId: string, channelId: string }): Observable<SuccessResponse<Object>>;
    abstract saveEvent(event: any): Observable<SuccessResponse>;
    /* --------------------------------- Outlook -------------------------------- */
    abstract linkOutlook(): Observable<SuccessResponse<any>>;
    abstract unlinkOutlook(): Observable<SuccessResponse<any>>;
    abstract retrieveOutlookContacts(data: { has_next: string }): Observable<SuccessResponse<{ contacts: OutlookContact[] }>>;
    abstract importOutlookContacts(contacts: OutlookContact[]): Observable<SuccessResponse<any>>
    abstract exportOutlookContacts(contacts: any[]): Observable<SuccessResponse<any>>
    /* -------------------------------- Pipdrive -------------------------------- */
    abstract linkPipdrive(): Observable<string>;
    abstract unlinkPipdrive(): Observable<boolean>;
    abstract getPipedriveContacts(): Observable<PipeDeriveContactsResponseDto>;
    abstract importPipedriveContacts(contacts: PipedriveContactDetaislDto[]): Observable<string>;
    abstract exportPipedriveContacts(contacts: PipedriveContactDetaislDto[]): Observable<string>;
      
    /* --------------------------------- Shopify -------------------------------- */
    abstract removeShopify(): Observable<boolean>
    /* ---------------------------------- Slack --------------------------------- */
    abstract linkSlack(): Observable<string>;
    abstract slackParams(): Observable<SuccessResponse<SlackParam[]>>;
    abstract slackUpdateParam(data: { attribute_name: string, attribute_value: string }[]): Observable<boolean>;
    abstract slackChannels(): Observable<SuccessResponse<SlackChannel[]>>;
    abstract removeSlack(): Observable<boolean>;
    /* --------------------------------- Zapier --------------------------------- */
    abstract zapierLogin(data: any): Observable<{ message: string, status: 404 } | { url: string, status: 200 }>;
    abstract disconnectZapier(): Observable<any>;
    /* --------------------------------- Zendesk -------------------------------- */
    abstract linkZendesk(data: { zd_email: string, zd_user_token: string, zd_subdomain: string }): Observable<SuccessResponse<any>>;
    abstract unlinkZendesk(): Observable<SuccessResponse<any>>;
    /* ---------------------------------- Zoom ---------------------------------- */
    abstract linkZoom(): Observable<string>;
    abstract removeZoom(): Observable<boolean>;
    abstract requestZoomLink(): Observable<string>;
}
