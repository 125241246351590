import { ITagsRepository } from '@/app/core/IRepositories/ITagsRepository';
import { Tag } from '@/app/core/Models/tag';
import { createTagDto } from '@/app/Data/DTO/TagsDto';
import { AppState } from '@/app/State/AppState';
import { CreateChannelTagAction, CreateContactTagAction, CreateTagAction, DeleteChannelTagAction, DeleteContactTagAction, FetchTagsAction, FetchTenantTagsAction } from '@/app/State/Tags/tags.action';
import { selectTenantTags } from '@/app/State/Tags/tags.selector';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, map, Observable, of, shareReplay, startWith, switchMap } from 'rxjs';
import { AddTagComponent } from '../tags/add-tag/add-tag.component';

@Injectable({
  providedIn: 'root',
})
export class TagsViewModel {
  tenantTags$ = this.store.select(selectTenantTags);
  threadId$ = new BehaviorSubject<{ id: number | string, isContact: boolean } | undefined>(undefined);
  tagSearchValue = new FormControl('');
  filtredTenantTags$ = this.tenantTags$.pipe(
    switchMap((tags) => this.tagSearchValue.valueChanges.pipe(
      startWith(''),
      map((value: string) => tags.filter(tag => tag.tag_name.toLowerCase().includes(value.toLowerCase())))
    )),
  )
  contactTags$ = this.threadId$.pipe(
    switchMap((data) =>
      data ? this.tagsRepositoy.getLocalTagsById({ id: data.id as any, isContact: data.isContact }) : of([])
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  createTagsSuccess$ = new BehaviorSubject(false);
  contactId$ = new BehaviorSubject<any>(-1);

  constructor(
    private tagsRepositoy: ITagsRepository,
    private store: Store<AppState>,
    private dialog: MatDialog
  ) { }

  fetchTagsByContactId(contactId: number): void {
    this.threadId$.next({ id: contactId, isContact: true });
    this.store.dispatch(FetchTagsAction({ payload: { contact_id: contactId, channel_id: null } }));
  }

  fetchTagsByChannelId(channelId: number): void {
    this.threadId$.next({ id: channelId, isContact: false });
    this.store.dispatch(FetchTagsAction({ payload: { contact_id: null, channel_id: channelId } }));
  }

  createTag(data: createTagDto) {
    this.store.dispatch(CreateTagAction({ payload: { data } }))
  }

  createContactTag(data: createTagDto[], contactId: number) {
    this.store.dispatch(CreateContactTagAction({ payload: { data: data[0], contact_id: contactId } }))
  }

  createUnclassifiedChannelTag(data: createTagDto[], unclassifiedId: number): void {
    this.store.dispatch(CreateChannelTagAction({ payload: { data: data[0], channel_id: unclassifiedId } }))
  }

  getAllTenantTags() {
    this.store.dispatch(FetchTenantTagsAction())
  }

  deleteTag(tag: Tag): void {
    tag.contact_id
      ? this.store.dispatch(DeleteContactTagAction({ payload: { tag } }))
      : this.store.dispatch(DeleteChannelTagAction({ payload: { tag } }))
  }

  async addTag(data: { tag_name?: string, contactId?: number | null, channelId?: number | null }) {
    console.log('clg data', data);
    let tags = await firstValueFrom(this.tenantTags$);
    this.dialog.open(AddTagComponent, {
      width: '90vh',
      maxWidth: '650px',
      backdropClass: 'templateBackDrop',
      panelClass: 'custom-dialog-container',
      data: {
        ...data,
        tags,
      }
    }).afterClosed().subscribe((result) => {
      this.createTagsSuccess$.next(result);
    });
  }
}