import { MentionMember } from '@/app/core/Models/Mention'/*  */;
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';
import { processFiles } from '@/app/Utilities/helpers';
import { FileModel } from '@/app/core/Models/file';
import { PlatformService } from '@/app/Utilities/platform/platform.service';
import { HotkeysService } from '@/app/Utilities/hotkeys/hotkeys.service';
import { formatMentionContent } from "@/app/Utilities/helpers";

@Component({
  selector: 'app-formatted-textarea',
  templateUrl: './formatted-textarea.component.html',
  styleUrls: ['./formatted-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormattedTextareaComponent),
      multi: true
    }
  ],
})
export class FormattedTextareaComponent implements ControlValueAccessor, OnDestroy, OnInit {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @Input() id = '';
  @Input() suggestionAvailable: boolean;
  @Input('disabled') set setDisabled(disabled: boolean) {
    this.setDisabledState(disabled)
  };
  @Input() placeholder = '';
  @Input() mentionsDropUp = true;
  @Input() members: MentionMember[] | any;
  @Input() selectedMembers: MentionMember[] | any;
  @Input() maxlength?: number;
  @Input() suggestion: string
  @Input() dynamicMention: boolean = false;
  @Output() controlEnterPressed = new EventEmitter()
  @Output() useSuggestion = new EventEmitter()
  @Output() filesModelChange = new EventEmitter<FileModel[]>()
  @Output() onGetTextContent = new EventEmitter();
  @Output() onGetSelectedMention = new EventEmitter<MentionMember>();
  @Output() onNoteUpdate = new EventEmitter();

  @HostListener('paste', ['$event'])
  onPaste($event: ClipboardEvent) {
    const file = $event.clipboardData?.items?.[0]?.getAsFile();
    if (file) {
      $event.preventDefault();
      $event.stopPropagation();
      processFiles([file]).then((attachments) => this.filesModelChange.emit(attachments));
    }
  }

  localization = this.localizationViewModel.localization
  formattedContent = '';
  content = new FormControl('')
  subscription: Subscription = new Subscription()
  isSuggestion = false
  isMac = this.platformService.isMac;
  onChange = (value: string) => { };
  onTouched = () => { };

  constructor(
    private cdr: ChangeDetectorRef,
    private platformService: PlatformService,
    private localizationViewModel: LocalizationViewModel,
    private hotkeysService: HotkeysService
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.content.valueChanges.subscribe(value => {
        if (this.dynamicMention) {
          this.filterSelectedMentions(value);
          this.onGetTextContent.emit(value);
        }
        this.onNoteUpdate.emit(value)
        this.valueChanges(value ?? '')
        this.isSuggestion = false
      }))
    this.subscription.add(
      this.hotkeysService.onSubmit$.subscribe((e: KeyboardEvent) => (e.target as HTMLElement) == this.input.nativeElement && this.controlEnterPressed.emit())
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  filterSelectedMentions(text: string): void {
    this.members = this.members.filter((item: MentionMember) => !item.selected);
    this.selectedMembers.forEach((item: MentionMember, index: number) => {
      if (!text.includes(item.name)) {
        item = { ...item, selected: false };
        this.members.push(item);
        this.selectedMembers.splice(index, 1);
      }
    });
  }

  onSwipeRight() {
    if (this.suggestion && !this.content.value) {
      this.useSuggestion.emit()
    }
  }

  writeValue(value: string): void {
    this.content.patchValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    disabled ? this.content.disable() : this.content.enable()
  }

  valueChanges(value: string): void {
    this.onChange(value);
    const htmlClean = (value as string).replace(/<[^>]*>/g, '')
    value === htmlClean
      ? this.formatContent(value)
      : this.content.patchValue(htmlClean)
  }

  tabPressed(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.suggestion && !this.content.value) {
      this.useSuggestion.emit()
    }
  }

  blure(event: KeyboardEvent) {
    (event.target as HTMLTextAreaElement).blur();
  }

  // formatContent(value: string) {
  //   this.formattedContent = formatMentionContent(value);
  //   this.cdr.detectChanges();
  // }

  formatContent(value: string) {
    let trimmedInput = value.trimEnd();
    let wordArray = trimmedInput.split(/(\s+)/);
    let processedWords = wordArray.map(word => {
      if (word.trim() !== '') {
        if (this.selectedMembers) {
          let isMentionedMember = this.selectedMembers.some((member: MentionMember) => '@' + member.name === word);
          return isMentionedMember ? formatMentionContent(word) : word;
        } else {
          return formatMentionContent(word);
        }
      }
      return word;
    });
    this.formattedContent = processedWords.join('');
    this.cdr.detectChanges();
}



  formatMention(member: MentionMember) {
    return `@${member.name} `;
  }

  focus() {
    this.input.nativeElement.focus()
  }

  handleSelectedMention(event: MentionMember): void {
    this.onGetSelectedMention.emit(event);
    setTimeout(() => {
      this.input.nativeElement.blur();
      this.input.nativeElement.focus();
    });

  }


  /* KeyPressed(e: Event) {
     ((e as KeyboardEvent).key === 'Enter' && ((e as KeyboardEvent).metaKey || (e as KeyboardEvent).ctrlKey && !this.isMac))
     && this.controlEnterPressed.emit()
   }*/
}
