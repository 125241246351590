import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { FileUploaderComponent } from './file-uploader.component';

import { FileUploaderDirective } from './file-uploader.directive';

@NgModule({
    imports: [
        CommonModule,
        MatTooltipModule,
        AngularSvgIconModule
    ],
    exports: [
        FileUploaderComponent,
        FileUploaderDirective
    ],
    declarations: [
        FileUploaderComponent,
        FileUploaderDirective
    ],
    providers: []
})
export class FileUploaderModule { }
