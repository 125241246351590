import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { VoiceClientCallSidDto } from '@/app/Data/DTO/VoiceClient/VoiceClientCallSidDto';
import { VoiceClientCallTransferRequestDto } from '@/app/Data/DTO/VoiceClient/VoiceClientCallTransferRequestDto';
import { VoiceClientDropMemberParticipantDto } from '@/app/Data/DTO/VoiceClient/VoiceClientDropUserDto';
import { VoiceClientHoldContactDto } from '@/app/Data/DTO/VoiceClient/VoiceClientHoldContactDto';
import { VoiceClientSetUserOnHoldDto } from '@/app/Data/DTO/VoiceClient/VoiceClientSetUserOnHoldDto';
import { VoiceClientTransferToUserDto } from '@/app/Data/DTO/VoiceClient/VoiceClientTransferToUserDto';
import { VoiceClientWarmTranferDto } from '@/app/Data/DTO/VoiceClient/VoiceClientWarmTranferDto';
import { VoiceClientTokenDto } from '@/app/Data/DTO/VoiceClientTokenDto';
import { Observable } from 'rxjs';
import { VoiceClientDropContactDto } from './../../Data/DTO/VoiceClient/VoiceClientDropContactDto';

export abstract class IVoiceClientRepository {
  abstract getTwilioToken(): Observable<VoiceClientTokenDto>;
  abstract warmTransfer(
    request: VoiceClientWarmTranferDto
  ): Observable<SuccessResponse>;
  abstract exitConference(
    request: VoiceClientCallSidDto
  ): Observable<SuccessResponse>;
  abstract setUserOnHold(
    request: VoiceClientSetUserOnHoldDto
  ): Observable<SuccessResponse>;
  abstract setContactOnHold(
    request: VoiceClientHoldContactDto
  ): Observable<SuccessResponse>;
  abstract dropUser(
    request: VoiceClientDropMemberParticipantDto
  ): Observable<SuccessResponse>;
  abstract dropContact(
    request: VoiceClientDropContactDto
  ): Observable<SuccessResponse>;
  abstract transferToUser(
    request: VoiceClientCallTransferRequestDto
  ): Observable<SuccessResponse>;
  abstract mergeCall(
    request: VoiceClientCallSidDto
  ): Observable<SuccessResponse>;
  abstract transferCall(
    request: VoiceClientTransferToUserDto
  ): Observable<SuccessResponse>;
}
