import { createSelector } from '@ngrx/store';
import { threadId } from 'worker_threads';
import { AppState } from '../AppState';
import { TagsState } from './tags.reducer';

const tags = (state: AppState) => state.tags;

export const selectTenantTags = createSelector(
  tags,
  (state: TagsState) => state.tenantTags
);
