<div
    class="content-wrapper"
    resizable="top"
    [resizableProperty]="'explecite'"
    appSwipe
    (swipeRight)="onSwipeRight()"
>
    <div class="content-container">
        <ng-container *ngIf="suggestion && !content.value">

            <div class="formatted-content no-scrollbar opacity-80">
                <span>{{ suggestion }}</span>
                <p
                    class="border border-primary font-semibold inline ml-1 rounded text-primary text-sm truncate xs:hidden"
                    style="padding: 2px;"
                >
                    Tab ⇋&nbsp;
                </p>
            </div>
            <svg
                class="absolute w-64 top-1/2 left-1/2 z-1 hidden xs:block -translate-y-1/2 -translate-x-1/2 opacity-90 shadow"
                width="150"
                height="20"
                viewBox="0 0 150 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs></defs>
                <path
                    id="hand"
                    d="M 10.416 7.768 C 10.416 6.613 11.666 5.891 12.666 6.468 C 13.13 6.736 13.416 7.232 13.416 7.768 L 13.416 9.018 C 13.416 7.613 14.666 6.891 15.666 7.468 C 16.13 7.736 16.416 8.232 16.416 8.768 L 16.416 13.268 C 16.416 16.581 13.73 19.268 10.416 19.268 L 8.416 19.268 L 8.624 19.268 C 6.605 19.268 4.722 18.253 3.612 16.568 C 3.546 16.468 3.481 16.368 3.416 16.268 C 3.104 15.789 2.009 13.88 0.13 10.54 C -0.268 9.832 -0.031 8.935 0.666 8.518 C 1.4 8.077 2.34 8.192 2.946 8.798 L 4.416 10.268 L 4.416 1.768 C 4.416 0.613 5.666 -0.109 6.666 0.468 C 7.13 0.736 7.416 1.232 7.416 1.768 L 7.416 9.018 L 7.416 6.768 C 7.416 5.613 8.666 4.891 9.666 5.468 C 10.13 5.736 10.416 6.232 10.416 6.768 L 10.416 9.069"
                    style="stroke-linejoin: round; stroke-linecap: round;"
                    fill="#6979f8"
                ></path>
                <style>
                    @keyframes swipeRight {
                        0% {
                            transform: translate(0, 0);
                        }

                        100% {
                            transform: translate(132px, 0);
                        }
                    }

                    /* Apply the animation to the dot */
                    #hand {
                        animation: swipeRight 2s infinite cubic-bezier(0.23, 1, 0.320, 1);
                    }
                </style>
            </svg>
        </ng-container>
        <div
            *ngIf="!suggestion || content.value"
            class="formatted-content no-scrollbar"
            [id]="id"
            [innerHTML]="formattedContent"
        ></div>

        <textarea
            #input
            [id]="id"
            type="text"
            class="mousetrap no-scrollbar"
            [attr.maxlength]="!!maxlength && maxlength "
            [formControl]="content"
            [placeholder]="placeholder"
            (keyup.esc)="blure($any($event))"
            (keydown.Tab)="tabPressed($event)"
            (itemSelected)="handleSelectedMention($event)"
            [mentionConfig]="members ? {triggerChar: '@', items: members, mentionSelect: formatMention, labelKey: 'name', dropUp: mentionsDropUp } : {}"
            [mentionListTemplate]="mentionListTemplate"
        ></textarea>
    </div>
</div>


<ng-template
    #mentionListTemplate
    let-item="item"
>
    <div class="flex items-center gap-4 relative h-8">
        <div class="relative flex items-center secondary-bg-color h-8 w-8 relative rounded-full text-center" style="background-color: #c1c1c1;">
            <img
                *ngIf="item.img"
                [src]="item.img"
                class="h-full w-full block rounded-full object-cover shadow-sm"
            />
            <p
            *ngIf="!item.img"
            class="absolute tracking-widest text-white font-small initials"
          >
            {{ item.name | nameInitial }}
          </p>
        </div>
        <ng-container [ngSwitch]="item.name">
            <!-- <div *ngSwitchCase="'All'">
                {{ localization.labels.notify_all_users | translate}}
            </div> -->
            <p
                *ngSwitchDefault
                class="text-mild"
                [class.active-mention-member]="item.active"
            >
                {{ item.name }}
            </p>
        </ng-container>
    </div>
</ng-template>
