import { Observable } from "rxjs";
import { CustomInboxDto, InboxAttibuteQuery } from 
"../Models/inbox";
import { SuccessResponse } from "@/app/Data/DTO/successResponse";
import { inboxesResponseDto } from "../Models/inbox";

export abstract class IInboxesRepository {
    abstract getInboxes(): Observable<inboxesResponseDto>;
    abstract EditInboxeAttribute(query: InboxAttibuteQuery): Observable<SuccessResponse<any>>;
    abstract createCustomInbox(data: CustomInboxDto): Observable<SuccessResponse<any>>;
    abstract deleteCustomInbox(inboxId: number): Observable<SuccessResponse<any>>;
}