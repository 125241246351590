import { Injectable } from '@angular/core';

import { DatabaseService } from '../services/Database/database.service';
import { HttpService } from '@/app/Data/services/Networking/HttpService';

import { IPipelineRepository } from '@/app/core/IRepositories/IPipelineRepository';
import { CompanyStageAssociation, ContactStageAssociation, Pipeline, Stage } from '@/app/core/Models/crm';
import { SuccessApiResponse } from '@/app/Data/services/Networking/ApiResponse';
import { HttpRequestMethod } from '@/app/Data/services/Networking/HttpRequestMethod';

import { CreatePipelineDto } from '../DTO/pipelineDto';

import { environment } from '@/environments/environment';

import { from, map, Observable, pluck } from 'rxjs';
import { liveQuery } from 'dexie';

@Injectable()
export class PipelineRepository implements IPipelineRepository {
  constructor(
    private databaseService: DatabaseService,
    private httpService: HttpService
  ) { }

  getPipelines(): Observable<{ pipelines: Pipeline[], total_count: number }> {
    const requestURL = `${environment.apiURL}pipeline`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<{ pipelines: Pipeline[], total_count: number }>;
        return res.results;
      })
    );
  }

  getStagesByPipelineId(pipelineId: string): Observable<Stage[]> {
    const requestURL = `${environment.apiURL}stage/${pipelineId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<{ stages: Stage[] }>;
        return res.results.stages;
      })
    );
  }

  createPipeline(request: CreatePipelineDto): Observable<Pipeline> {
    const requestURL = `${environment.apiURL}pipeline`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Pipeline>;
        return res.results;
      })
    );
  }

  editPipeline(request: CreatePipelineDto, yobi_crm_pipeline_id: string): Observable<Pipeline> {
    const requestURL = `${environment.apiURL}pipeline/${yobi_crm_pipeline_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Pipeline>;
        return res.results;
      })
    );
  }

  savePipelinesLocal(pipelines: Pipeline[]): void {
    this.databaseService.transaction('rw!', this.databaseService.pipelines, async () => {
      await this.databaseService.pipelines.bulkPut(pipelines);
    }).catch((error) => {
      console.error('Transaction Failed: ', error);
    });
  }

  deletePipelineLocal(yobi_crm_pipeline_id: string): void {
    this.databaseService.pipelines.delete(yobi_crm_pipeline_id as string).catch();
  }

  getLocalPipelines(): Observable<Pipeline[]> {
    return from(
      liveQuery(() => {
        this.databaseService.pipelines.mapToClass(Pipeline);
        return this.databaseService.pipelines.orderBy('pipeline_name').toArray();
      })
    );
  }

  deletePipeline(yobi_crm_pipeline_id: string): Observable<Pipeline> {
    const requestURL = `${environment.apiURL}pipeline/${yobi_crm_pipeline_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Pipeline>;
        return res.results;
      })
    );
  }

  getStagesUnderPipelineLocal(yobi_crm_pipeline_id: string) {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.pipelines,
          async () => {
            const pipeline = await this.databaseService.pipelines.get({ 'yobi_crm_pipeline_id': yobi_crm_pipeline_id });
            return pipeline?.stages;
          }
        );
      })
    );
  }

  savePipelineStagesLocal(stages: Stage[]): void {
    this.databaseService.pipelines.update('stages', stages).then();
  }

  getContactStages(stage_id: number, search: string): Observable<ContactStageAssociation> {
    const requestURL = `${environment.apiURL}stage/${stage_id}/contacts?search=${search}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<ContactStageAssociation>;
        return res.results;
      })
    );
  }

  getCompanyStages(stage_id: number, search: string): Observable<CompanyStageAssociation> {
    const requestURL = `${environment.apiURL}stage/${stage_id}/companies?search=${search}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<CompanyStageAssociation>;
        return res.results;
      })
    );
  }

}