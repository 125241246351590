import { TeamMember } from '@/app/core/Models/TeamMember';
import { PhoneState } from '@/app/Data/twilio/voice-client.service';
import { VoiceClientViewModel } from '@/app/Ui/ViewModels/VoiceClientViewModel';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UsersViewModel } from '../../ViewModels/usersViewModel';
export interface DialerState {
  phoneState: PhoneState;
  isMuted: boolean;
}
@Component({
  selector: 'app-dialer-active-call',
  templateUrl: './dialer-active-call.component.html',
  styleUrls: ['./dialer-active-call.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialerActiveCallComponent {
  voiceClientState$ = this.voiceClientViewModel.voiceClientState$;
  callTimer$ = this.voiceClientViewModel.callTimer$;
  profile$ = this.voiceClientViewModel.profile$;
  teamMembers$ = combineLatest([
    this.usersViewModel.teamMembers$.pipe(first()),
    this.profile$.pipe(first()),
  ]).pipe(map(([members, profile]) => members.filter(m => m.user_id != profile!.userId)));

  accept(parentCallSid: string) {
    this.voiceClientViewModel.accept(parentCallSid);
  }

  hangup(parentCallSid: string) {
    this.voiceClientViewModel.hangup(parentCallSid)
  }

  toggleMute(parentCallSid: string) {
    this.voiceClientViewModel.toggleMute(parentCallSid);
  }

  putOnHold(parentCallSid: string) {
    this.voiceClientViewModel.holdCallParticipant(parentCallSid);
  }

  mergeCall(parentCallSid: string) {
    this.voiceClientViewModel.mergeCall(parentCallSid);
  }

  forwardCall(parentCallSid: string) {
    this.voiceClientViewModel.transferCall(parentCallSid);
  }

  sendDigit(parentCallSid: string, digit: string) {
    this.voiceClientViewModel.sendDigit(parentCallSid, digit);
  }

  inviteUser(parentCallSid: string, member: TeamMember) {
    this.voiceClientViewModel.transferToUser(parentCallSid, member);
  }

  selectActiveMember(parentCallSid: string, id: string) {
    this.voiceClientViewModel.selectActiveMember(parentCallSid, id);
  }

  constructor(
    private voiceClientViewModel: VoiceClientViewModel,
    private usersViewModel: UsersViewModel
  ) { }

}
