import { INotificationRepository } from '@/app/core/IRepositories/INotificationRepository';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Injectable } from '@angular/core';
import {firstValueFrom } from 'rxjs';
import { ILocalStorageService } from '@/app/Data/Adapters/ILocalStorageService';
import { FCM_TOKEN } from '../TokenKeys';
import { AppState } from '@/app/State/AppState';
import { Store } from '@ngrx/store';
import { selectTenantProfile } from '@/app/State/Tenant/tenant.selector';
@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  readonly profile$ = this.store.select(selectTenantProfile);

  constructor(
    private store: Store<AppState>,
    private angularFireMessaging: AngularFireMessaging,
    private notificationRepository: INotificationRepository,
    private localSorageService: ILocalStorageService,
  ) { }

  requestPermission(): void {
    this.angularFireMessaging.requestToken.subscribe({
      next: async (token) => {
        if (token) {
          this.localSorageService.setItem(FCM_TOKEN, token);
          try { 
            await firstValueFrom(this.notificationRepository.registerFCMToken(token));
            console.log("push notifications registered.")
          } catch(e) {
            console.error(e);
          }
        }
      },
      error: (err) => {
        console.warn('Unable to get permission to notify.', err);
      }
    });
  }



  async onLogout(): Promise<any> { 
    const token = this.localSorageService.getItem(FCM_TOKEN) as string;
    try {
      let profile = await firstValueFrom(this.profile$)
      await firstValueFrom(this.notificationRepository.unRegisterFCMToken(token, profile?.tenantId!))
    } catch(e){
      console.error(e);
    }
  }
}
