import { createReducer, on } from '@ngrx/store';
import { SetActiveContactId } from '@/app/State/contacts/contacts.action';
import { SignOutAction } from '@/app/State/app/action';
import { Contact, UnclassifiedContact } from '@/app/core/Models/contact';
import { FetchContactByIdAction, FetchContactByIdFailedAction, FetchContactByIdSuccessAction, GET_UNCLASSIFIED_CHANNEL_DETAIL, GetUnclassifiedChannelDetailAction, GetUnclassifiedChannelDetailFailAction, GetUnclassifiedChannelDetailSuccessAction, ResetContactStateAction } from './contact.action';

export interface ContactState {
  contactId?: number;
  contact?: Contact | UnclassifiedContact,
  fetchingContac?: boolean,
  unclassified?: boolean
}

export const initContactState: ContactState = {};

export const contactReducer = createReducer(
  initContactState,
  on(SetActiveContactId, (state: ContactState, { payload: { unclassified, contactId } }) => {
    return {
      ...state,
      contactId,
      unclassified: !!unclassified
    };
  }),
  on(FetchContactByIdAction, GetUnclassifiedChannelDetailAction, (state: ContactState, { payload, type }) => {
    return {
      ...state,
      fetchingContac: true,
      contactId: payload,
      unclassified: type === GET_UNCLASSIFIED_CHANNEL_DETAIL
    };
  }),
  on(FetchContactByIdSuccessAction, (state: ContactState, { payload }) => {
    return {
      ...state,
      fetchingContac: false,
      contact: payload,
    };
  }),
  on(GetUnclassifiedChannelDetailSuccessAction, (state: ContactState, { payload }) => {
    return {
      ...state,
      fetchingContac: false,
      contact: payload.mapToUnclassifiedContact(),
    };
  }),
  on(FetchContactByIdFailedAction, GetUnclassifiedChannelDetailFailAction, (state: ContactState, { }) => {
    return {
      ...state,
      fetchingContac: false,
    };
  }),

  on(SignOutAction, ResetContactStateAction, (state: ContactState) => {
    return {
      ...initContactState,
    };
  })
);
