import { LocalizationViewModel } from './../../ViewModels/localizationViewModel';
import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './expired-token.component.html',
  styleUrls: ['./expired-token.component.scss'],
})
export class ExpiredTokenComponent implements OnInit {
  localization = this.localizationViewModel.localization;

  constructor(
    private localizationViewModel: LocalizationViewModel,
  ) {}

  ngOnInit(): void {}
}
