import { LocalizationViewModel } from './../../ViewModels/localizationViewModel';
import { ChannelType } from '@/app/core/Models/ChannelTypes';
import { EmailVerificationCodeDto } from '@/app/Data/DTO/EmailVerificationCodeDto';
import { VerificationCodeDto } from '@/app/Data/DTO/VerificationCodeDto';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { AuthViewModel } from '../../ViewModels/authViewModel';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent implements OnInit {
  codeController = new FormControl('', [Validators.required]);
  allowLeave = false;
  localization = this.localizationViewModel.localization;

  constructor(public router: Router, private localizationViewModel: LocalizationViewModel, public authViewModel: AuthViewModel) {}

  ngOnInit(): void {}

  // async resendCode(): Promise<any> {
  //   const res = await firstValueFrom(this.authViewModel.singUpState$);
  //   const { channel_value, business_name, full_name, channel_type, password } = res!.data!;
  //   const request = { channel_value, channel_type, business_name, full_name, password };
  //   this.authViewModel.requestEmailVerificationCode(request);
  // }

  // async onEmailChange(): Promise<any> {
  //   this.allowLeave = true;
  //   const res = await firstValueFrom(this.authViewModel.singUpState$);
  //   if (res?.data) {
  //     this.authViewModel.editSignupForm(res.data);
  //   }
  // }

  // async validateCode(loading = false ): Promise<any> {
  //   if(loading) {
  //     return;
  //   }
  //   const res = await firstValueFrom(this.authViewModel.singUpState$);
  //   if (res.data?.channel_value) {
  //     const request = new VerificationCodeDto(
  //       res.data?.channel_value,
  //       ChannelType.Email,
  //       this.codeController.value
  //     );
  //     this.authViewModel.validateEmailVerificationCode(request);
  //   }
  // }
}
