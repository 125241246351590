import { createSelector } from "@ngrx/store";
import { RoomState } from "./room.reducer";
import { AppState } from "@/app/State/AppState";

const roomState = (state: AppState) => state.room;

export const selectedRoom = createSelector(
  roomState,
  (roomSelector: RoomState) => roomSelector.state.roomDetails
);

export const selectedRoomPrivate = createSelector(
  roomState,
  (roomSelector: RoomState) => roomSelector.state.roomDetails?.private
);

export const selectedRoomAvatar = createSelector(
  roomState,
  (roomSelector: RoomState) => roomSelector.state.roomDetails?.avatar
);

export const roomTransacting = createSelector(
  roomState,
  (roomSelector: RoomState) => roomSelector.transacting
);

export const roomTransactionSuccess = createSelector(
  roomState,
  (roomSelector: RoomState) => roomSelector.success
);

export const roomTransactionError = createSelector(
  roomState,
  (roomSelector: RoomState) => roomSelector.error
);

export const roomTransactionType = createSelector(
  roomState,
  (roomSelector: RoomState) => roomSelector.transactionType
);


export const tenantRooms = createSelector(
  roomState,
  (roomSelector: RoomState) => roomSelector.state.tenantRooms
);

export const participantRooms = createSelector(
  roomState,
  (roomSelector: RoomState) => roomSelector.state.participantRooms
);
