import { INotesRepository } from '@/app/core/IRepositories/INotesRepository';
import { Note } from '@/app/core/Models/Note';
import { NoteBody } from '@/app/Data/DTO/NoteBodyDto';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IMediaRepository } from '../../IRepositories/IMediaRepository';
import { UseCase } from '../UseCase';

@Injectable({
  providedIn: 'root',
})
export class UpdateNoteUseCase implements UseCase<NoteBody, Note> {
  constructor(
    private notesRepository: INotesRepository,
    private mediaRepository: IMediaRepository
  ) {}

  async execute(body: NoteBody): Promise<Note> {
    const { note, noteId, contactId } = body.getBody();
    if (body.hasNewAttachments) {
      const media_urls = await firstValueFrom(
        this.mediaRepository.uploadAttachments(body.getBodyAttachment(), body.uploadProgress$)
      );
      note.attachments = [...(note.attachments ?? []), ...media_urls]
    }
    const noteResult = await firstValueFrom(
      this.notesRepository.updateNote(note, noteId!, contactId!)
    );
    this.notesRepository.saveNotes([noteResult]);
    return noteResult;
  }
}
