import { PapercupsService } from '@/app/Data/services/Papercups/papercups.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})

export class RegistrationComponent implements OnInit {
  constructor(
    private papercupsService: PapercupsService
  ) { }
  
  ngOnInit(): void {
    document.body?.classList.remove('dark')
    this.papercupsService.init();
  }
}
