<app-auth-banner class="absolute flex-1 w-full h-full"></app-auth-banner>

<div class="bg-white flex flex-col gap-6 p-10 rounded-2xl text-zinc-800 z-10 items-center mx-4 flex-1 max-w-xl">
  <p class="text-3xl font-semibold">
    {{localization.not_found.page_not_found | translate}}
  </p>
  <img
    src="assets/images/empty-taken.svg"
    class="max-h-72"
  />
  <div class="flex flex-col gap-2">
    <span class="text-lg text-center">
      {{localization.labels.visit | translate}}
      <a
        [routerLink]="'auth/login'"
        class="text-lg cursor-pointer text-primary font-semibold"
      >
        {{localization.auth.login_page | translate }}
      </a>
    </span>
    <span class="text-center text-zinc-700">
      {{localization.labels.or | translate}}
    </span>
    <span class="text-lg text-center">
      {{localization.auth.sign_in_no_account | translate}}
      <a
        routerLink="/auth/signup"
        class="text-lg cursor-pointer text-primary font-semibold"
      >
        {{localization.labels.create_account | translate }}.
      </a>
    </span>
  </div>
</div>