import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert.component';
import { MatIconModule } from '@angular/material/icon';
import { ButtonModule } from '../../components/Button/button.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [AlertComponent],
  exports: [AlertComponent],
  imports: [
    CommonModule,
    MatIconModule,
    ButtonModule,
    TranslateModule
  ]
})
export class AlertModule { }
