import { UsersState } from './reducer';
import { createSelector } from '@ngrx/store';
import { AppState } from '../AppState';
const usersState = (state: AppState) => state.users;

export const selectInvitations = createSelector(
  usersState,
  (state: UsersState) => state.invitations
);

export const selectTeamMemberOnlineRef = createSelector(
  usersState,
  (state: UsersState) => state.onlineMembers
);

export const selectCreatingUser = createSelector(
  usersState,
  (state: UsersState) => state.creatingUser
);

export const selectUpdatingUser = createSelector(
  usersState,
  (state: UsersState) => state.updatingUser
);
