import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AppState } from "../AppState";
import { Store } from "@ngrx/store";
import { CREATE_SYNTH_AGENT, CREATE_SYNTH_CONVERSATION, CREATE_SYNTH_CONVERSATION_SUCCESS, CREATE_SYNTH_INTERACTION, CreateSynthAgentActionFail, CreateSynthAgentActionSuccess, CreateSynthConversationFailction, CreateSynthConversationSuccessAction, CreateSynthInteractionFailction, CreateSynthInteractionSuccessAction, GET_SYNTH, GET_SYNTH_CONVERSATION_INTERACTIONS, GET_SYNTH_RECENT_CONVERSATIONS, GET_SYNTHS, GetSynthActionFail, GetSynthConversationInteractionsFailAction, GetSynthConversationInteractionsSuccessAction, GetSynthRecentConversationsFailction, GetSynthRecentConversationsSuccessAction, GetSynthsFailAction, GetSynthsSuccessAction, SelectSynthAction, SynthActions, UPDATE_SYNTH_AGENT, UPDATE_SYNTH_CONVERSATION, UpdateSynthAgentActionFail, UpdateSynthAgentActionSuccess, UpdateSynthConversationFailAction, UpdateSynthConversationSuccessAction, TRANSCRIPTION_SYTNTH, DELETE_SYNTH, DeleteSynthActionSuccess, DeleteSynthActionFail, GetSynthVersionControlSuccess, GetSynthVersionControlFail, GET_SYNTH_VERSION_CONTROL, } from "./synth.action";
import { catchError, firstValueFrom, map, of, switchMap, tap, withLatestFrom } from "rxjs";
import { ISynthRepository } from "@/app/core/IRepositories/ISynthREpository";
import { selectTenantProfile } from "../Tenant/tenant.selector";
import { SnackbarService } from "@/app/Utilities/snackbar/snackbar.service";
import { Router } from "@angular/router";
import { RecentSynthConversationsQueryParams, Synth, SynthConversation, SynthRecentConversation, SynthTemplateEngine, SynthVersionHistory } from "@/app/core/Models/synth";
import { ErrorApiReponse } from "@/app/Data/services/Networking/ApiResponse";
import { SynthViewModel } from "@/app/Ui/ViewModels/synth-view-model";
import { UsersViewModel } from "@/app/Ui/ViewModels/usersViewModel";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import { dynamicKeySort } from "@/app/Utilities/helpers";

@Injectable()
export class SynthEffect {
    constructor(
        private actions$: Actions<SynthActions>,
        private synthRepository: ISynthRepository,
        private store$: Store<AppState>,
        private router: Router,
        private snackBarService: SnackbarService,
        private synthViewModel: SynthViewModel,
        private usersViewModel: UsersViewModel
    ) { }

    getSynths$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_SYNTHS),
            map(action => action.payload),
            switchMap((payload) => this.synthRepository.getSynths({ page: 1, page_size: 20, search: payload.search }).pipe(
                map(synths => GetSynthsSuccessAction({ payload: synths })),
                tap((items) => {
                    const synths = items.payload.map((item) => {
                        return { ...item, synth_created_dt_timestamps: new Date(item.synth_created_dt).getTime() }
                    });
                    this.synthRepository.saveSynthsLocal(synths, { page: 1, page_size: 20 });
                }),
                catchError(err => of(GetSynthsFailAction()))
            )),
        ),
        { dispatch: true }
    )

    createSynthConversation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CREATE_SYNTH_CONVERSATION),
            map(action => action.payload),
            switchMap(({ data }) => this.synthRepository.createSynthConversation(data).pipe(
                withLatestFrom(this.store$.select(selectTenantProfile)),
                map(([data, profile]) => CreateSynthConversationSuccessAction({ payload: { data, profile } })),
                catchError(err => of(CreateSynthConversationFailction()))
            )),
        ),
        { dispatch: true }
    )

    // createSynthConversationSuccess$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(CREATE_SYNTH_CONVERSATION_SUCCESS),
    //         map(action => action.payload)
    //     ),
    //     { dispatch: false }
    // )

    getRecentInteractions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_SYNTH_RECENT_CONVERSATIONS),
            map(action => action.payload),
            switchMap(({ query }) => this.synthRepository.getSynthRecentConversations(query).pipe(
                map(conversations => GetSynthRecentConversationsSuccessAction({ payload: { conversations, query } })),
                tap(({ payload }) => this.saveRecentConversations(payload)),
                catchError(err => of(GetSynthRecentConversationsFailction()))
            )),
        ),
        { dispatch: true }
    )

    // GetSynthConversationAction$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(GET_SYNTH_CONVERSATION),
    //         map(action => action.payload),
    //         switchMap(({ conversationId }) => this.synthRepository.getSynthRecentConversations(query).pipe(
    //             map(conversations => GetSynthRecentConversationsSuccessAction({ payload: { conversations, query } })),
    //             catchError(err => of(GetSynthRecentConversationsFailction()))
    //         )),
    //     ),
    //     { dispatch: true }
    // )

    getSynthConversationInteractions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_SYNTH_CONVERSATION_INTERACTIONS),
            map(action => action.payload),
            switchMap(({ query }) => this.synthRepository.getSynthInteractionsByConversation(query).pipe(
                map(interactions => GetSynthConversationInteractionsSuccessAction({ payload: { interactions, query } })),
                catchError(err => of(GetSynthConversationInteractionsFailAction()))
            )),
        ),
        { dispatch: true }
    )

    CreateSynthInteractionAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CREATE_SYNTH_INTERACTION),
            map(action => action.payload),
            switchMap(({ data }) => this.synthRepository.createSynthInteraction(data).pipe(
                map(interaction => CreateSynthInteractionSuccessAction({ payload: { interaction } })),
                catchError(err => of(CreateSynthInteractionFailction({ payload: { data } })))
            )),
        ),
        { dispatch: true }
    )

    UpdateSynthConversationAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPDATE_SYNTH_CONVERSATION),
            map(action => action.payload),
            switchMap(({ data, synth_conversation_id }) => this.synthRepository.updateSynthConversation(data, synth_conversation_id).pipe(
                map(conversation => UpdateSynthConversationSuccessAction({ payload: { conversation } })),
                tap((data) => {
                    this.snackBarService.openAlert({
                        message: `Conversation title updated successfully`,
                        type: 'success'
                    });
                    // this.synthViewModel.fetchRecentSynthConversations(data.payload.conversation.synth_id as any, data.payload.conversation.train ? 'true' : 'false');
                }),
                catchError(err => {
                    this.snackBarService.openAlert({
                        message: `Something went wrong`,
                        type: 'failure'
                    })
                    return of(UpdateSynthConversationFailAction())
                }))
            ),
        ),
        { dispatch: true }
    )

    createSynthAgentAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CREATE_SYNTH_AGENT),
            map(action => action.payload),
            switchMap((payload) => this.synthRepository.createSynthAgent(payload).pipe(
                map(() => CreateSynthAgentActionSuccess()),
                catchError(err => of(CreateSynthAgentActionFail()))
            )),
        ),
        { dispatch: true });

    updateSynthAgentAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPDATE_SYNTH_AGENT),
            map(action => action.payload),
            switchMap(({ data, synth_id }) => this.synthRepository.updateSynthAgent(data, synth_id).pipe(
                map(() => UpdateSynthAgentActionSuccess()),
                catchError(err => of(UpdateSynthAgentActionFail()))
            )),
        ),
        { dispatch: true });

    getSynthDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_SYNTH),
            map(action => action.payload),
            switchMap((payload) => this.synthRepository.getSynthDetails(payload).pipe(
                map((item) => SelectSynthAction({ payload: { synth: item } })),
                tap((item) => {
                    const t = Object.assign(new Synth(), item.payload.synth)
                    this.synthRepository.updateLocalSythDetails(item.payload.synth.synth_id as any, t);
                }),
                catchError((err: ErrorApiReponse<any>) => {
                    const errm = err.responseError.message;
                    this.snackBarService.openAlert({ message: errm, type: 'failure' });
                    if (this.router.url !== '/main/workshop') this.router.navigate(['/main/workshop']);
                    return of(GetSynthsFailAction());
                })
            )),
        ), { dispatch: true });

    deleteSynthAgent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DELETE_SYNTH),
            map(action => action.payload),
            switchMap(({ data }) => this.synthRepository.deleteSynthAgent([data[0]]).pipe(
                map(() => {
                    // Delete from local storage after API success using synth_id
                    this.synthRepository.deleteSynthLocal([data[1]]);
                    this.synthViewModel.getSynths('');
                    return DeleteSynthActionSuccess();
                }),
                catchError(err => of(DeleteSynthActionFail()))
            ))), { dispatch: true });


    getSynthVersionControl$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_SYNTH_VERSION_CONTROL),
            map(action => action.payload),
            switchMap((payload) => this.synthRepository.getSynthVersionControl(payload.synth_agent_id, payload.page, payload.page_size).pipe(
                map((item) => GetSynthVersionControlSuccess({ payload: { items: item } })),
                tap((item) => {
                    const response: any[] = item.payload.items.feedback_data;
                    if (response.length) {
                        this.saveSynthVersionControl({ items: response, synth_id: response[0].synth_id });
                    }
                }),
                catchError((err: ErrorApiReponse<any>) => {
                    const errm = err.responseError.message;
                    this.snackBarService.openAlert({ message: errm, type: 'failure' });
                    if (this.router.url !== '/main/workshop') this.router.navigate(['/main/workshop']);
                    return of(GetSynthVersionControlFail());
                })
            )),
        ), { dispatch: true });

    async saveSynthVersionControl(payload: { items: SynthVersionHistory[], synth_id: number }) {
        let existingItems = await firstValueFrom(this.synthRepository.getLocalSynthVersionHisory(payload.synth_id as any)) ?? [];
        const users = await firstValueFrom(this.usersViewModel.usersList$);
        const version_histories = payload.items.map(item => Object.assign(new SynthVersionHistory(),
            {
                ...item,
                created_dt_timestamps: new Date(item.created_dt).getTime(),
                // new_updated_dt: dayjs(item.created_dt).format('MMM DD, YYYY h:mm A'),
                new_updated_dt: dayjs.utc(item.created_dt, 'ddd, DD MMM YYYY HH:mm:ss [GMT]').format('MMM DD, YYYY h:mm A'),
                user: users.filter(user => user.user_id === item.user_id)[0]
            }));
        const existingItemsMap = new Map(existingItems.map(item => [item.id, item]));

        version_histories.forEach(item => {
            existingItemsMap.set(item.id, item);
        });
        const updatedItems = Array.from(existingItemsMap.values());
        const sortedItems = updatedItems.sort(dynamicKeySort('created_dt_timestamps', 'desc'));
        this.synthRepository.updateLocalSythDetails(payload.synth_id as any, { 'version_history': sortedItems })
    }

    async saveRecentConversations(payload: { conversations: SynthRecentConversation[], query: RecentSynthConversationsQueryParams }) {
        const t = await firstValueFrom(this.synthRepository.getLocalSynth(payload.query.synth_id as any));
        const b = Object.assign(new SynthTemplateEngine(), { ...t, train: payload.query.train === 'false' ? false : true, recent_conversations: payload.conversations });
        this.synthRepository.updateLocalSythDetails(payload.query.synth_id as any, { 'template_engine': b.templateEngine });
    }
}