import { createReducer, on } from '@ngrx/store';
import { SignOutAction } from '@/app/State/app/action';
import {
  DisableEditForTaskCommentAction,
  EditTaskCommentSuccessAction,
  EnableEditForTaskCommentAction,
} from '@/app/State/Task/action';

export interface TaskState {
  editableCommentId: number | null;
}

export const initTaskState: TaskState = {
  editableCommentId: null,
};

export const taskReducer = createReducer(
  initTaskState,
  on(EnableEditForTaskCommentAction, (state: TaskState, { payload }) => {
    return {
      ...state,
      editableCommentId: payload,
    };
  }),
  on(EditTaskCommentSuccessAction, (state: TaskState) => {
    return {
      ...state,
      editableCommentId: 0,
    };
  }),
  on(DisableEditForTaskCommentAction, (state: TaskState) => {
    return {
      ...state,
      editableCommentId: 0,
    };
  }),
  on(SignOutAction, (state: TaskState) => {
    return {
      ...initTaskState,
    };
  })
);
