import { Injectable } from '@angular/core';

import { HttpService } from '@/app/Data/services/Networking/HttpService';
import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';

import { distinctUntilChanged, firstValueFrom, from, map, Observable, pluck, startWith, tap } from 'rxjs';

import { environment } from '@/environments/environment';
import { CreateEventDto, GetEventsByFilterDto, GetEventsByTimestampDto, Event, IEventForm, IYobiEventResponse, PatchEventDto, IYobiEvent } from '@/app/Data/DTO/eventDto';
import { SuccessApiResponse } from '@/app/Data/services/Networking/ApiResponse';
import { HttpRequestMethod } from '@/app/Data/services/Networking/HttpRequestMethod';
import { liveQuery } from 'dexie';

import dayjs from 'dayjs';

@Injectable({ providedIn: 'root' })
export class EventViewModel {
    constructor(
        private httpService: HttpService,
        private databaseService: DatabaseService,
        private snackBarService: SnackbarService
    ) { }

    readonly events$ = this.getEventsLocally().pipe(distinctUntilChanged());

    createEvent(payload: CreateEventDto) {
        return this.createEventViaAPI(payload).pipe(
            tap((res) => {
                const event: Event = {
                    id: res.yobi_event_id,
                    start: new Date(res.event_start_date * 1000),
                    end: new Date(res.event_end_date * 1000),
                    title: res.event_title,
                    color: res.extra_data,
                    meta: res
                }
                this.saveEventLocally(event);
            }));
    }

    patchEvent(eventId: number, payload: PatchEventDto) {
        return this.patchEventViaAPI(eventId, payload).pipe(
            tap((res) => {
                /// todo
            })
        )
    }

    deleteEvent(eventId: number) {
        return this.deleteEventViaAPI(eventId).pipe(
            tap((res) => {
                this.deleteEventLocally(eventId);
            }));
    }

    getEvent(yobi_event_id: any) {
        let newEvent: Event;
        return this.getEventViaAPI(yobi_event_id).pipe(
            tap((event) => {
                newEvent = this.createEventDetails(event);
                this.saveEventLocally(newEvent);
            }),
            map(() => {
                return newEvent
            })
        );
    }

    async getEvents() {
        const response = await firstValueFrom(this.getAllEventsViaAPI());
        const events: Event[] = [];
        if (response.length) {
            response.forEach((item: IYobiEventResponse) => {
                item.events.forEach((event: IYobiEvent) => {
                    events.push(this.createEventDetails(event));
                });
            });
        }
        this.saveEventsLocally(events);
        return response
    }

    private createEventDetails(event: IYobiEvent): Event {
        const defaultColor = { primary: '#3498db', secondary: '#555555' };
        return {
            id: event.yobi_event_id,
            start: new Date(event.event_start_date * 1000),
            end: new Date(event.event_end_date * 1000),
            title: event.event_title,
            color: 'primary' in event.extra_data && 'secondary' in event.extra_data ? {
                primary: event.extra_data.primary,
                secondary: event.extra_data.secondary
            } : defaultColor,
            meta: Object.assign(new IYobiEvent(), event)
        };
    }

    async getEventsByFilter(payload: GetEventsByFilterDto) {
        const response = await firstValueFrom(this.getEventsViaAPI(payload));
        return response;
    }

    private mutateCalendar(event: any) {

    }

    private createEventViaAPI(request: CreateEventDto): Observable<IYobiEvent> {
        const requestURL = `${environment.apiURL}event`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            request,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => {
                let res = item as SuccessApiResponse<IYobiEvent>;
                return res.results;
            })
        );
    }

    private patchEventViaAPI(eventId: number, payload: PatchEventDto): Observable<IYobiEvent> {
        const requestURL = `${environment.apiURL}event/${eventId}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.patch,
            this.httpService.createHeader(),
            requestURL,
            { ...payload },
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => {
                let res = item as SuccessApiResponse<IYobiEvent>;
                return res.results;
            })
        );
    }

    private deleteEventViaAPI(eventId: number) {
        const requestURL = `${environment.apiURL}event/${eventId}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.delete,
            this.httpService.createHeader(),
            requestURL,
            null,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => {
                let res = item as SuccessApiResponse<any>;
                return res.results;
            })
        );
    }

    private getAllEventsViaAPI(): Observable<IYobiEventResponse[]> {
        const requestURL = `${environment.apiURL}events`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => {
                let res = item as SuccessApiResponse<IYobiEventResponse[]>;
                return res.results;
            })
        );
    }

    private getEventViaAPI(yobi_event_id: any): Observable<IYobiEvent> {
        const requestURL = `${environment.apiURL}event/${yobi_event_id}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => {
                let res = item as SuccessApiResponse<IYobiEvent>;
                return res.results;
            })
        );
    }

    private getEventsViaAPI(params: GetEventsByFilterDto): Observable<IYobiEventResponse[]> {
        const requestURL = `${environment.apiURL}events`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => {
                let res = item as SuccessApiResponse<IYobiEventResponse[]>;
                return res.results;
            })
        );
    }

    private saveEventLocally(event: Event) {
        this.databaseService.events.put(event);
    }

    private getEventsLocally() {
        return from(
            liveQuery(() => {
                return this.databaseService.events.toArray();
            })
        )
    }

    private saveEventsLocally(events: any) {
        this.databaseService.transaction('rw!', this.databaseService.events, async () => {
            await this.databaseService.events.clear();
            await this.databaseService.events.bulkPut(events);
        }).catch((error) => {
            console.error('Transaction Failed: ', error);
        });
    }

    private deleteEventLocally(eventId: any) {
        this.databaseService.events.delete(eventId).then();
    }

    deleteEventLocally2(eventId: any) {
        return this.databaseService.events.delete(eventId)
    }

    getEventStartDate(value: IEventForm) {
        const [hours, minutes] = value.start_time.split(':').map(Number);
        const startDate = new Date(
            value.start_date.getFullYear(),
            value.start_date.getMonth(),
            value.start_date.getDate(),
            hours,
            minutes
        );
        return dayjs(startDate).unix();
    }

    getEventEndDate(value: IEventForm) {
        const [hours, minutes] = value.end_time.split(':').map(Number);
        const endDate = new Date(
            value.end_date.getFullYear(),
            value.end_date.getMonth(),
            value.end_date.getDate(),
            hours,
            minutes
        );
        return dayjs(endDate).unix();
    }

    createEventPayload(value: IEventForm): CreateEventDto {
        const _invitees: any[] = []
        value.invitees.forEach((item) => { if (item !== 0) _invitees.push({ user_id: item.user_id }) });
        const payload = {
            event_type: value.event_type as any,
            event_title: value.event_title,
            event_description: value.event_description,
            event_start_date: this.getEventStartDate(value),
            event_end_date: this.getEventEndDate(value),
            in_person_meeting: value.in_person_meeting,
            invitees: _invitees,
            event_deadline: value.event_deadline,
            event_timezone: value.event_timezone,
            event_location: value.event_location,
            event_priority: value.event_priority,
            extra_data: value.extra_data,
            tags: value.tags,
            external_email_invitees: value.external_email_invitees.map(item => { return { email: item } }),
            reminders: value.reminders
        };
        return payload;
    }

    createEditPayload(value: IEventForm): PatchEventDto {
        const _invitees: any[] = []
        value.invitees.forEach((item) => { if (item !== 0) _invitees.push({ user_id: item.user_id }) });
        const payload = {
            event_type: value.event_type as any,
            event_title: value.event_title,
            event_description: value.event_description,
            event_start_date: this.getEventStartDate(value),
            event_end_date: this.getEventEndDate(value),
            in_person_meeting: value.in_person_meeting,
            invitees: _invitees,
            event_deadline: value.event_deadline,
            event_timezone: value.event_timezone,
            event_location: value.event_location,
            event_priority: value.event_priority,
            extra_data: value.extra_data,
            tags: value.tags,
            external_email_invitees: value.external_email_invitees.map(item => { return { email: item } }),
            reminders: value.reminders
        };
        return payload;
    }

    getEventPrimaryColors() {
        return [
            { value: "#3498db", active: true, showDelete: false },
            { value: "#2ecc71", active: false, showDelete: false },
            { value: "#f39c12", active: false, showDelete: false },
            { value: "#9b59b6", active: false, showDelete: false },
            { value: "#e74c3c", active: false, showDelete: false }
        ];
    }

    getEventSecondaryColors() {
        return [
            { value: "#555555", active: true, showDelete: false },
            { value: "#333333", active: false, showDelete: false },
            { value: "#2c3e50", active: false, showDelete: false },
            { value: "#7f8c8d", active: false, showDelete: false },
            { value: "#1abc9c", active: false, showDelete: false }
        ];
    }

    showSnackbar(message: string, type: 'failure' | 'success') {
        this.snackBarService.openAlert({ message, type });
    }

    showSnackBarRaw(opt: any) {
        this.snackBarService.openAlert(opt);

    }

}