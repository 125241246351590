import { Roles } from '@/app/core/Models/Roles';

export class User {
  avatar?: string;
  avatar64: string;
  created_dt: string;
  do_not_disturb: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  roles: Roles[];
  accessibility: {
    inbox_id: number,
    send: boolean,
    access: boolean,
    visible: boolean
    notification: boolean,
  }[];
  tenant_id: number;
  updated_dt: string;
  user_id: number;
  username: string;
  metadata?: any
  room_name?: string
  room_id?: string
  private?: boolean
  given_name?: string
  family_name?: string
  user_permissions: Array<{ permission_name: string, permission_value: string }>
}

export class Room {
  active: boolean;
  avatar: string;
  room_name: string;
  room_id: string;
  private: boolean
}
export class UserInvitation {
  invitation_id: number;
  tenant_id: number;
  user_role: Roles;
  username: string
}
