import { createTagDto, tenantTagsDetailsDto } from "@/app/Data/DTO/TagsDto";
import { Observable } from "rxjs";
import { Contact } from "../Models/contact";
import { Tag } from "../Models/tag";

export abstract class ITagsRepository {
    abstract createTag(data: createTagDto): Observable<Tag>;
    abstract createContactTag(data: createTagDto, contactId: number): Observable<Tag[]>;
    abstract createUnclassifiedTag(data: createTagDto, channelId: number): Observable<Tag[]>;
    abstract getAllTenantTags(): Observable<tenantTagsDetailsDto[]>;
    abstract getContactTags(contactId: number): Observable<Tag[]>; 
    abstract getUnclassifiedChannelTags(channelId: number): Observable<Tag[]>;
    abstract searchContactsByTags(tag: string): Observable<Contact[]>;
    abstract deleteContactTag(contactId: number, tag: Tag): Observable<string>;
    abstract saveAndDeleteTags(tags: Tag[], forId: number, isContact: boolean): void;
    abstract saveTags(tags: Tag[]): void;
    abstract deleteSavedTag(tag: Tag, contactId: number): void;
    abstract getLocalTagsById(data: {id: number, isContact: boolean}): Observable<Tag[]>;
    abstract deleteChannelTag(channelId: number, tag: Tag): Observable<string>;
}