<div
  *ngIf="{
    voiceClientState: voiceClientState$ | async,
    profile: profile$ | async,
    callTimer: callTimer$ | async
  } as data"
  class="container flex flex-col bg-white rounded-3xl overflow-hidden"
  cdkDrag
>
  <!-- -------------------------- panelheader start -------------------------- -->
  <div
    class="flex py-4 px-6 justify-between items-center cursor-move bg-white border-b border-gray-200"
    cdkDragHandle
  >
    <svg-icon
      src="assets/images/Logo.svg"
      [svgStyle]="{ width: '20px', height: '20px' }"
    ></svg-icon>
    <img src="assets/icons/new-set/dialer/drag-indicator.svg" class="svg-gray-filter w-6" alt="">
  </div>
  <!-- -------------------------- panel header end --------------------------- -->
  <div class="flex flex-col w-full gap-2 bg-gray-100">
    <app-dialer-call-session-item
      *ngIf="data.voiceClientState"
      [profile]="data.profile!"
      [callTimer]="data.callTimer!"
      [callSession]="data.voiceClientState.callSession!"
      [teamMembers]="(teamMembers$ | async) ?? []"
      (accept)="accept(data.voiceClientState.callSession!.parentCallSid!)"
      (hangup)="hangup(data.voiceClientState.callSession!.parentCallSid!)"
      (putOnHold)="putOnHold(data.voiceClientState.callSession!.parentCallSid!)"
      (sendDigit)="sendDigit(data.voiceClientState.callSession!.parentCallSid!, $event)"
      (mergeCall)="mergeCall(data.voiceClientState.callSession!.parentCallSid!)"
      (toggleMute)="toggleMute(data.voiceClientState.callSession!.parentCallSid!)"
      (inviteUser)="inviteUser(data.voiceClientState.callSession!.parentCallSid!, $event)"
      (forwardCall)="forwardCall(data.voiceClientState.callSession!.parentCallSid!)"
      (selectActiveMember)="selectActiveMember(data.voiceClientState.callSession!.parentCallSid!, $event)"
    >
    </app-dialer-call-session-item>
  </div>
</div>