import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { UseCase } from '../UseCase';
import { IAuthenticationRepository } from '@/app/core/IRepositories/IAuthenticationRepository';
import { Tenant } from '@/app/core/Models/tenant';

@Injectable({
  providedIn: 'root',
})
export class GetTenantProfileUseCase implements UseCase<any, Tenant> {

  constructor(private authenticationRepository: IAuthenticationRepository) {}

  execute(): Observable<Tenant> {
    return this.authenticationRepository.getUserObject().pipe(
      tap((tenant: Tenant) => {
        this.authenticationRepository.saveTenantProfileObject(tenant);
      })
    );
  }
}
