import { Observable, of } from 'rxjs';
import { Note } from '@/app/core/Models/Note';
import { NoteDto } from '@/app/Data/DTO/NoteDto';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';

export abstract class INotesRepository {
  abstract updateNote(
    data: NoteDto,
    noteId: number,
    contactId: number
  ): Observable<Note>;
  abstract createNote(data: NoteDto, contactId: number): Observable<Note>;
  abstract getNotesByContactId(contactId: number): Observable<Note[]>;
  abstract saveNotes(notes: Note[]): void;
  abstract saveAndDeleteNotes(notes: Note[], forId: number): void;
  abstract getLocalNotesById(threadId: number, unclassified: boolean): Observable<Note[]>;
  abstract deleteSavedNote(noteId: number): void;
  abstract deleteContactNote(
    noteId: number,
    contactId: number
  ): Observable<SuccessResponse>;
  abstract deleteUnclassifiedChannelNote(
    noteId: number,
    channelId: number
  ): Observable<SuccessResponse>;
  abstract getNotesByChannelId(contactId: number): Observable<Note[]>;

  abstract createUnclassifiedChannelNote(
    data: NoteDto,
    channelId: number
  ): Observable<Note>;

  abstract updateUnclassifiedChannelNote(
    data: NoteDto,
    noteId: number,
    channelId: number
  ): Observable<Note>;
}
