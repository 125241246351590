import { Tag } from '@/app/core/Models/tag';
import { createTagDto, tenantTagsDetailsDto } from '@/app/Data/DTO/TagsDto';
import { createAction, props, union } from '@ngrx/store';

/**************************** RECENT MESSAGES *********************************/
export const FETCH_TENENT_TAGS = '[Tags] Fetch tenant tags';
export const FetchTenantTagsAction = createAction(
  FETCH_TENENT_TAGS,
);


export const FETCH_TENENT_TAGS_SUCCESS = '[Tags] Fetch tenant tags success';
export const FetchTenantTagsSuccessAction = createAction(
  FETCH_TENENT_TAGS_SUCCESS,
  props<{ payload: { tags: tenantTagsDetailsDto[] } }>()
);

export const FETCH_TAGS = '[Tags] Fetch tags success';
export const FetchTagsAction = createAction(
  FETCH_TAGS,
  props<{ payload: { contact_id: number | null, channel_id: number | null } }>()
);

export const FETCH_TAGS_BY_CONTACT_ID = '[Tags] Fetch tags by contact id';
export const FetchTagsByContactIdAction = createAction(
  FETCH_TAGS_BY_CONTACT_ID,
  props<{ payload: { contact_id:  number  }}>());

export const FETCH_TAGS_BY_UNCLASSIFIED_CHANNEL_ID = '[Tags] Fetch tags by unclassified channel id';
export const FetchTagsByUnclassifiedChannelIdAction = createAction(
  FETCH_TAGS_BY_UNCLASSIFIED_CHANNEL_ID,
  props<{ payload: { channel_id:  number  }}>()
);


export const CREATE_TAG = '[Tags] create tag';
export const CreateTagAction = createAction(
  CREATE_TAG,
  props<{ payload: { data: createTagDto } }>()
);

export const CREATE_CONTACT_TAG = '[Tags] create contact tag';
export const CreateContactTagAction = createAction(
  CREATE_CONTACT_TAG,
  props<{ payload: { data: createTagDto, contact_id: number } }>()
);

export const CREATE_CHANNEL_TAG = '[Tags] create channel tag';
export const CreateChannelTagAction = createAction(
  CREATE_CHANNEL_TAG,
  props<{ payload: { data: createTagDto, channel_id: number } }>()
);

export const CREATE_TAG_SUCCESS = '[Tags] create tag success';
export const CreateTagSuccessAction = createAction(
  CREATE_TAG_SUCCESS,
  props<{ payload: { contact_id: number | null, channel_id: number | null } }>()
);

export const CREATE_TAG_FAIL = '[Tags] create tag Fail';
export const CreateTagFailAction = createAction(
  CREATE_TAG_FAIL,
);

export const DELETE_CONTACT_TAG = '[Tags] delete contact tag';
export const DeleteContactTagAction = createAction(
  DELETE_CONTACT_TAG,
  props<{ payload: { tag: Tag } }>()
);

export const DELETE_CHANNEL_TAG = '[Tags] delete chennal tag';
export const DeleteChannelTagAction = createAction(
  DELETE_CHANNEL_TAG,
  props<{ payload: { tag: Tag } }>()
);

export const DELETE_TAG_SUCCESS = '[Tags] delete tag success';
export const DeleteTagSuccessAction = createAction(
  DELETE_TAG_SUCCESS,
  props<{ payload: { tag: Tag } }>()
);

const all = union({
  FetchTenantTagsAction,
  FetchTenantTagsSuccessAction,
  CreateContactTagAction,
  FetchTagsAction,
  CreateTagAction,
  CreateTagSuccessAction,
  FetchTagsByContactIdAction,
  FetchTagsByUnclassifiedChannelIdAction,
  DeleteContactTagAction,
  DeleteTagSuccessAction
});

export type TagsActions = typeof all;
