import { Component, Inject, OnInit } from '@angular/core';

import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
    selector: 'app-terms-privacy',
    templateUrl: './terms-privacy.component.html',
    styleUrls: ['./terms-privacy.component.scss'],
})
export class TermsPrivacyComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private ref: MatDialogRef<TermsPrivacyComponent>
    ) {

    }

    close(): void {
        this.ref.close();
    }
    ngOnInit(): void {
    }

}
