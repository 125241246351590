import { CreateDealDto, UpdateDealRequest } from "@/app/Data/DTO/dealDto";
import { Observable } from "rxjs";
import { DealsQueryParams, Deal, DealDetail } from "../Models/deal";

export abstract class IDealRepository {
    abstract getDeals(request: DealsQueryParams): Observable<{ deals: Deal[], total_count: number }>;
    abstract createDeal(request: CreateDealDto): Observable<Deal>;
    abstract updateDeal(request: CreateDealDto): Observable<Deal>;
    abstract deleteDeal(dealIds: string[]): Observable<Deal>;
    abstract getDealById(yobi_crm_deals_id: string): Observable<DealDetail>;

    abstract saveDeals(products: Deal[], params: DealsQueryParams): void;
    abstract getLocalDeals(): Observable<Deal[]>;
    abstract getLocalDeal(productId: string): Observable<Deal | undefined>;
    abstract saveDeal(product: Deal): void;
    abstract deleteLocalDeal(productId: string): void;
}