import { createSelector } from '@ngrx/store';
import { AppState } from '../AppState';
import { InboxState } from './inbox.reducer';
import { ChannelType } from '@/app/core/Models/ChannelTypes';

const inboxState = (state: AppState) => state.inbox;

export const selectInboxActiveThreadId = createSelector(
  inboxState,
  (state: InboxState) => state.threadId
);

export const selectThreadContact = createSelector(
  inboxState,
  (state: InboxState) => state.threadContact
);

export const selectIsRecentInteractionFetching = createSelector(
  inboxState,
  (state: InboxState) => state.fetching
);

export const selectRecentInteractionsFilter = createSelector(
  inboxState,
  (state: InboxState) => state.recentInteractions.filter
);
/* --------------------------------- inboxes -------------------------------- */
export const selectInboxes = createSelector(
  inboxState,
  (state: InboxState) => state.inboxes
);
export const selectFetchingInboxes = createSelector(
  inboxState,
  (state: InboxState) => state.fetchingInboxes
);
export const selectSelectedInbox = createSelector(
  inboxState,
  (state: InboxState) => state.selectedInbox
);

export const selectRecentInteractionsState = createSelector(
  inboxState,
  (state: InboxState) => state.recentInteractions
);

export const selectRecentInteractionsPage = createSelector(
  inboxState,
  (state: InboxState) => state.recentInteractions.page!
);

export const selectUnreadInteractionsCount = createSelector(
  inboxState,
  (state: InboxState): number => state.inboxes.filter(i => ![ChannelType.Email, ChannelType.Google, ChannelType.Outlook].includes(i.channel_type as any)).reduce((acc, inbox) => acc + inbox.unread_count, 0)
);

export const selectUnreadEmailsCount = createSelector(
  inboxState,
  (state: InboxState): number => state.inboxes.filter(i => i.is_channel && [ChannelType.Email, ChannelType.Google, ChannelType.Outlook].includes(i.channel_type!)).reduce((acc, inbox) => acc + inbox.unread_count, 0)
);

export const selectInboxState = createSelector(
  inboxState,
  (state: InboxState) => state
);

export const selectTypingUsers = createSelector(
  inboxState,
  (state: InboxState) => state?.usersTyping
);

export const selectShowScheduledMessages  = createSelector(
  inboxState,
  (state: InboxState) => state?.showScheduledMessages
);

export const selectInboxForceRefershing = createSelector(
  inboxState,
  (state: InboxState) => state.force_refresh
);
