import { createReducer, on } from '@ngrx/store';
import { SocialPostAttachmentUploadAction, SocialPostPublishAction, SocialPostAttachmentUploadSuccessAction, PublishFacebookPostAction, PublishFacebookPostFailAction, PublishFacebookPostSuccessAction, SocialPublishCompletedAction, PublishTwitterPostSuccessAction, PublishInstagramPostSuccessAction, SocialPostCancelEventAction, SocialPostTryAgainAction, PublishInstagramPostFailAction, PublishTwitterPostFailAction, SocialPostAttachmentUploadFailAction, PublishLinkedinPostFailAction, PublishLinkedinPostSuccessAction } from './action';
import { SocialPostChannel } from '@/app/Data/DTO/socialPublish/SocialPostDto';

export type SocialPublisherState = {
  media_uploading: {
    is_uploading: boolean;
    progress: number;
    failed: boolean
  }
  isSubmitting: boolean;
  hasAttachment: boolean;
  attachment_url?: string | null;
  content: string;
  channels: SocialPostChannel[],
  completed: boolean;
}

export const initSocialPublisherState: SocialPublisherState = {
    media_uploading: {
        is_uploading: false,
        progress: 0,
        failed: false
      },
      hasAttachment: false,
      isSubmitting: false,
      content: '',
      channels: [],
      completed: false
};


export const SocialPublisherReducer = createReducer(
    initSocialPublisherState,
    on(
      SocialPostTryAgainAction,
      (state: SocialPublisherState) => {
        return {
          ...state,
         isSubmitting: false,
         completed: false,
         channels: []
        };
      }
    ),
    on(
      SocialPostPublishAction,
      (state: SocialPublisherState,  { payload }) => {
        return {
          ...initSocialPublisherState,
         content: payload.content,
         isSubmitting: true,
         channels: payload.channels
        };
      }
    ),
  on(
    SocialPostAttachmentUploadAction,
    (state: SocialPublisherState,  { payload }) => {
      return {
        ...state,
        hasAttachment: true,
        media_uploading: {
            ...state.media_uploading,
            is_uploading: true,
            failed: false,
            progress: payload
        }
      };
    }
  ),
  on(
    SocialPostAttachmentUploadFailAction,
    (state: SocialPublisherState) => {
      return {
        ...state,
        hasAttachment: true,
        media_uploading: {
            ...state.media_uploading,
            is_uploading: false,
            failed: true
        }
      };
    }
  ),
  on(
    SocialPostAttachmentUploadSuccessAction,
    (state: SocialPublisherState,  { payload }) => {
      return {
        ...state,
        media_uploading: {
            ...state.media_uploading,
            is_uploading: false,
        },
        attachment_url: payload.attachment_url
      };
    }
  ),
  on(
    PublishInstagramPostFailAction,
    PublishTwitterPostFailAction,
    PublishLinkedinPostFailAction,
    PublishFacebookPostFailAction,
    (state: SocialPublisherState, {payload}) => {
      return {
        ...state,
        channels: state.channels.map( channel => ({...channel, failed: channel.channel_value == payload.body.page_id || channel.failed, error: payload?.error})),

      };
    }
  ),
  on(
    PublishFacebookPostSuccessAction,
    PublishLinkedinPostSuccessAction,
    PublishInstagramPostSuccessAction, 
    PublishTwitterPostSuccessAction,
    (state: SocialPublisherState, {payload}) => {
      return {
        ...state,
        channels: state.channels.map( channel => ({...channel, completed: channel.channel_value == payload.page_id || channel.completed })),
      };
    }
  ),
  on(
    SocialPublishCompletedAction,
    (state: SocialPublisherState) => {
      return {
        ...state,
        completed: true,
        isSubmitting: false,
      };
    }
  ),
  on(
    SocialPostCancelEventAction,
    (state: SocialPublisherState) => {
      return {
        ...initSocialPublisherState
      };
    }
  ),
  
);
