import { ITeamInteractionRepository } from '@/app/core/IRepositories/ITeamInteractionRepository';
import { FileModel } from '@/app/core/Models/file';
import { InteractionDirection, InteractionStatus, InteractionThread, RecentInteraction } from '@/app/core/Models/Interaction';
import { TeamMember } from '@/app/core/Models/TeamMember';
import { InputCreateRoom, TeamMessageBody } from '@/app/Data/DTO/TeamChatMessageDto';
import { AppState } from '@/app/State/AppState';
import { CloseAllTeamChatThreadsAction, CloseTeamChatThreadAction, DeleteTeamChatMessageAction, FetchTeamChatMessagesAction, FetchTeamRecentMessagesAction, OpenTeamChatThreadAction, ReactToTeamChatMessageAction, ResendTeamChatMessageAction, SendTeamChatMessageAction, SendTeamChatMessageFailAction, SendTeamChatMessageSuccessAction, UpdateTeamChatMessageStatusAction } from '@/app/State/TeamInbox/teamInbox.action';
import { selectActiveUser, selectTeamChatActiveUsers, selectTeamInboxState } from '@/app/State/TeamInbox/teamInbox.selector';
import { participantRooms, selectedRoom, tenantRooms } from "@/app/State/Room/room.selector";
import { selectTenantProfile } from '@/app/State/Tenant/tenant.selector';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Observer, catchError, combineLatest, distinctUntilChanged, filter, first, firstValueFrom, map, of, sampleTime, shareReplay, switchMap, takeUntil, tap, lastValueFrom } from 'rxjs';
import { UsersViewModel } from './usersViewModel';
import { CreateTeamMessageUseCase } from '@/app/core/usecases/team-messaging/CreateTeamMessageUseCase';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';
import { ErrorApiReponse } from '@/app/Data/services/Networking/ApiResponse';
import { IRoom } from '../team-chat-center/team-chat-room/team-chat-room.interface';

import { RightPanelIndexes, SetRightPanelActiveWindowAction } from '@/app/State/mediaObserver/media.observer.action';
import { AddRoomParticipantAction, CreateRoomAction, FetchParticipantRoomsAction, FetchTenantRoomsAction, RemoveRoomParticipantAction, SetActiveRoomAction, UpdateRoomAction } from '@/app/State/Room/room.action';
@Injectable({
  providedIn: 'root',
})
export class TeamChatViewModel {
  constructor(
    private store: Store<AppState>,
    private usersViewModel: UsersViewModel,
    private teamInteractionRepository: ITeamInteractionRepository,
    private createTeamMessageUseCase: CreateTeamMessageUseCase,
    private snackBarService: SnackbarService
  ) {

  }

  init() {
    if (!this.initialized) {
      this.fetchTenantRooms();
      this.fetchProfileRooms();
      this.initialized = true
    }
  }

  initialized = false
  teamMembers$ = this.usersViewModel.teamMembers$.pipe(
    filter(u => !!u?.length), tap(() => this.teamMembersLoaded$.next(true)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  teamMembersLoaded$ = new BehaviorSubject(false)
  teamInboxState$ = this.store.select(selectTeamInboxState).pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  recentInteractionsPage$ = new BehaviorSubject(0)
  recentInteractions$ = this.teamInteractionRepository.getLocalTeamRecentMessages().pipe(
    switchMap(interactions =>
      this.interactionType$.asObservable().pipe(
        map(type => interactions.filter(({ is_room }) =>
          type === 'all' || type === 'groups' && is_room || type === 'personal' && !is_room
        ))
      )
    ),
    switchMap(recentInteractions =>
      this.teamMembers$.pipe(
        map(users =>
          recentInteractions.map(recentInteraction => Object.assign(new RecentInteraction(), {
            ...recentInteraction,
            metadata: { online: users.find(u => u.user_id == recentInteraction.interaction.user_id)?.active }
          }))
        )
      )
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  )

  unreadMesages$ = this.recentInteractions$.pipe(
    map(interactions => interactions.filter(i => i.interaction.status == 'unread').length),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  threadInteractions$ = this.teamInboxState$.pipe(
    filter((state) => !!state.focusedUser),
    distinctUntilChanged((a, b) => {
      return true
        && a?.focusedUser == b?.focusedUser
        && a?.pendingThreadInteractions.length === b?.pendingThreadInteractions.length
        && a?.pendingThreadInteractions[0]?.interaction.status === b?.pendingThreadInteractions[0]?.interaction.status
        && a?.threadInteractions.nextPagesThreads.length == b?.threadInteractions.nextPagesThreads.length
    }),
    switchMap((state) =>
      this.teamInteractionRepository.getLocalTeamMessages({
        page: state.threadInteractions.page,
        userId: state.focusedUser
      }).pipe(
        map((messages) => [
          ...state.pendingThreadInteractions.map(p => p.interaction).filter(i => i.user_id == state.focusedUser as any),
          ...messages,
          ...state.threadInteractions.nextPagesThreads.filter(i => i.user_id == state.focusedUser as any),
        ])),
    ),
    takeUntil(this.teamInboxState$.pipe(first(s => !s.focusedUser))),
    sampleTime(100),
    map((messages) => messages.sort((a, b) => b.interaction_dt.getTime() - a.interaction_dt.getTime())),
    tap((messages) => this.updateReadStatus(messages)),
  );

  readonly profileRooms$ = this.store.select(participantRooms)
  readonly tenantRooms$ = this.store.select(tenantRooms);
  readonly selectedRoom$ = this.store.select(selectedRoom);
  tempActiveRoom$ = new BehaviorSubject<IRoom>({ room_name: '', private: false });
  editingRoom$ = new BehaviorSubject<IRoom | undefined>(undefined);
  get isRoom() {
    return !!this.tempActiveRoom$.value?.room_id
  }

  roomThreadInteractions$ = this.selectedRoom$.pipe(
    filter((room) => !!room?.room_id),
    switchMap((room) =>
      this.teamInboxState$.pipe(
        map(state => state.threadInteractions.page),
        distinctUntilChanged(),
        switchMap((page) => this.teamInteractionRepository.getLocalTeamMessages({
          page, userId: (room?.room_id) as any
        }))
      )
    ),
    tap((messages) => this.updateReadStatus(messages)),
  );

  activeUsers$: Observable<TeamMember[]> = combineLatest([
    this.store.select(selectTeamChatActiveUsers),
    this.recentInteractions$
  ]).pipe(map(([users, interactions]) => users.map(u => ({
    ...u,
    metadata: {
      unread: interactions.find(i => i.interaction.user_id as any == u.user_id)?.interaction.status == 'unread'
    }
  }))))

  activeUser$ = combineLatest([this.teamMembers$, this.store.select(selectActiveUser)]).pipe(
    map(([teamMembers, user]) => user ? ({ ...user, active: teamMembers.find(m => m.user_id == user.user_id)?.active }) : undefined)
  )

  remainigUsers$: Observable<TeamMember[]> = combineLatest([
    this.recentInteractions$,
    this.teamMembers$.pipe(first())
  ]).pipe(map(([recentInteractions, teamMembers]) => {
    const recentInteractionUsers = recentInteractions.map(i => i.interaction.user_id as any)
    return teamMembers.filter(m => !recentInteractionUsers.includes(m.user_id))
  }))

  remainingParticipantRooms$ = combineLatest([
    this.store.select(tenantRooms),
    this.store.select(participantRooms),
    this.recentInteractions$,
  ]).pipe(
    map(([tenantRooms, participantRooms, recentInteractions]) => {
      const recentInteractionsRoomsIds = recentInteractions.filter(i => !!i.room_id).map(i => i.room_id)
      const participantsRoomsIds = participantRooms.map(i => i.room_id)
      return [
        ...participantRooms
          .filter((room: any) => !recentInteractionsRoomsIds.includes(room.room_id))
          .map(room => ({ ...room, isCurrentUserParticipant: true })),
        ...tenantRooms
          .filter(room => !participantsRoomsIds.includes(room.room_id))
      ]
    })
  );

  interactionType$ = new BehaviorSubject<'all' | 'groups' | 'personal'>('all');

  async openTeamChatThread(userId: number, idle = false) {
    const users = await firstValueFrom(this.teamMembers$) ?? [];
    const user = users.find(u => u.user_id == userId)
    if (user) {
      this.store.dispatch(OpenTeamChatThreadAction({ payload: { user, idle } }))
      this.FetchTeamChatMessages({ page: 1, userId, users });
    } else {
      throw new Error("User not found");
    }
  }

  async openRoomTeamChatThread(roomId: string, idle = false) {
    const users = await firstValueFrom(this.teamMembers$) ?? [];
    this.store.dispatch(OpenTeamChatThreadAction({ payload: { user: undefined, idle } }));
    this.FetchTeamChatRoomMessages({ page: 1, userId: 0, roomId, users });
  }

  setActiveRoomStore(room: IRoom): void {
    this.store.dispatch(SetActiveRoomAction({ payload: { room_id: room.room_id, room_name: room.room_name, private: room.private, avatar: room.avatar } }));
  }

  fetchTeamRecentMessages(type: 'all' | 'groups' | 'personal', page?: number) {
    this.interactionType$.next(type);
    page = page ?? this.recentInteractionsPage$.value + 1;
    this.store.dispatch(FetchTeamRecentMessagesAction({ payload: { page, page_size: 25, type } }));
  }

  async updateReadStatus(messages: InteractionThread[]) {
    const message = messages[0];
    if (message?.direction == InteractionDirection.inbound && message.status == InteractionStatus.unread && message.interaction_id) {
      this.store.dispatch(UpdateTeamChatMessageStatusAction({
        payload: {
          interation_id: message.interaction_id, data: {
            receiver_id: this.isRoom ? this.tempActiveRoom$.value.room_id! : message.user_id,
            status: InteractionStatus.read
          }
        }
      }));
    }
  }

  async updateLocalRoomRecentInteractions(roomId: string, data: Partial<IRoom>) {
    const rooms = await firstValueFrom(this.profileRooms$)
    const room = rooms.find(({ room_id }) => room_id == roomId)
    room && this.teamInteractionRepository.updateLocalRoomRecentInteractions({
      room_id: roomId,
      room_name: data.room_name ?? room.room_name as string,
      private: data.private ?? room.private as boolean,
      avatar: data.avatar ?? room.avatar,
    })
  }

  setEditingRoom(room: IRoom) {
    this.editingRoom$.next(room)
    this.store.dispatch(SetRightPanelActiveWindowAction({ payload: RightPanelIndexes.users }))
  }

  FetchTeamChatRoomMessages(query: { page: number, userId: number, roomId: string, users?: TeamMember[] },) {
    this.store.dispatch(FetchTeamChatMessagesAction({ payload: { ...query, is_room: true } }));
  }

  FetchTeamChatMessages(query: { page: number, userId: number, users?: TeamMember[] }) {
    this.store.dispatch(FetchTeamChatMessagesAction({ payload: { ...query, is_room: false } }))
  }

  fetchProfileRooms() {
    this.store.dispatch(FetchParticipantRoomsAction({ payload: {} }));
  }

  fetchTenantRooms() {
    this.store.dispatch(FetchTenantRoomsAction({ payload: {} }));
  }

  closeTeamChatThread(userId: number) {
    this.store.dispatch(CloseTeamChatThreadAction({ payload: { userId } }))
  }

  closeAllTeamChatThreads() {
    this.store.dispatch(CloseAllTeamChatThreadsAction())
  }

  deleteInteraction(interaction_id: number) {
    this.store.dispatch(DeleteTeamChatMessageAction({ payload: { interaction_id } }));
  }

  reactToInteraction(interaction_id: number, reaction: string) {
    this.store.dispatch(ReactToTeamChatMessageAction({ payload: { reaction, interaction_id } }));
  }

  async sendMessage({ message, attachments, is_room, observer, participants_ids }: { message: string, attachments: FileModel[], is_room: boolean, observer: Observer<any>, participants_ids: string[] }) {
    let receiver_id = is_room ? this.tempActiveRoom$.getValue()['room_id'] : (await firstValueFrom(this.teamInboxState$)).focusedUser;
    let user_id = (await firstValueFrom(this.store.select(selectTenantProfile)))!.userId
    const teamMessageBody = new TeamMessageBody(
      receiver_id,
      message,
      is_room,
      'message',
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      new Date().getTime(),
      user_id,
      attachments,
      participants_ids
    )
    if (is_room) {
      const temp = this.temporarySetMessage(teamMessageBody.msg_id, is_room, user_id, receiver_id, attachments, message);
      this.store.dispatch(SendTeamChatMessageSuccessAction({ payload: { teamInteraction: [temp as any], msg_id: teamMessageBody.msg_id } }));
    }
    this.store.dispatch(SendTeamChatMessageAction({ payload: teamMessageBody }))
    this.createTeamMessageUseCase.execute({ body: teamMessageBody, observer })
      .pipe(
        map((teamInteraction) => {
          //Temporary fix for backend issue.
          if (teamInteraction.status == InteractionStatus.unread) {
            teamInteraction.status = InteractionStatus.read
          }
          return SendTeamChatMessageSuccessAction({ payload: { teamInteraction: [teamInteraction], msg_id: teamMessageBody.msg_id || 0 } })
        }),
        tap((item) => {
          this.teamInteractionRepository.deleteTeamThreadInteractions({ user_id: receiver_id, interaction_id: item.payload.msg_id })
        }),
        catchError((error: any) => {
          let errorStr = error.responseError?.error?.message || 'Operation was not possible';
          return of(SendTeamChatMessageFailAction({ payload: { errorStr, msg_id: teamMessageBody.msg_id } }));
        }),
        tap(action => this.store.dispatch(action))
      ).subscribe();
  }

  createRoom(data: InputCreateRoom) {
    this.store.dispatch(CreateRoomAction({ payload: { room_name: data.room_name, room_private: data.private, participants_ids: data.participants_ids } }));
  }

  fetchRoomParticipant(room_id: string) {
    return this.teamInteractionRepository.fetchRoomParticipant(room_id);
  }

  joinRoom(room_id: string, participants: (string | number)[]) {
    return this.teamInteractionRepository.addRoomPartcipant(room_id, participants);
  }

  addNewParticipantToRoom(room_id: string, participants: string[]) {
    this.store.dispatch(AddRoomParticipantAction({ payload: { room_id, participants_ids: participants } }));
  }

  removeParticipantToRoom(room_id: string, participant_id: string, isCurrentUser?: boolean) {
    this.store.dispatch(RemoveRoomParticipantAction({ payload: { room_id, participant_id, isCurrentUser } }))
  }

  updateRoom(room_id: string, data: InputCreateRoom) {
    this.store.dispatch(UpdateRoomAction({ payload: { room_id, room_name: data.room_name, room_private: data.private, participants_ids: data.participants_ids } }))
  }

  updateRoomImage(room_id: string, data: FormData, progress$: Observer<any>) {
    return this.teamInteractionRepository.updateRoomImage(room_id, data, progress$).pipe(
      tap(({ avatar }) => {
        this.snackBarService.openAlert({
          message: `Room logo has been updated.`,
          type: "success"
        });
        this.updateLocalRoomRecentInteractions(room_id, { avatar })
      }),
      catchError((httpError: ErrorApiReponse<any>) => {
        this.snackBarService.openAlert({
          message: httpError.message,
          type: 'failure'
        });
        throw httpError;
      }));
  }

  async resend({ msg_id, observer }: { msg_id: number, observer: Observer<any> }) {
    const state = await firstValueFrom(this.teamInboxState$)
    const { body, interaction } = state.pendingThreadInteractions.find(i => i.body.msg_id === msg_id)!
    const teamMessageBody = new TeamMessageBody(
      body.getBody().receiver_id,
      body.getBody().data,
      false,
      'message',
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      body.msg_id,
      body.getBody().user_id!,
      body.attachments,
      []
    )
    this.store.dispatch(ResendTeamChatMessageAction({ payload: { msg_id } }))
    this.createTeamMessageUseCase.execute({ body: teamMessageBody, observer })
      .pipe(
        map((teamInteraction) => SendTeamChatMessageSuccessAction({ payload: { teamInteraction: [teamInteraction], msg_id: teamMessageBody.msg_id || 0 } })),
        catchError((error: any) => {
          let errorStr = error.responseError?.error?.message || 'Operation was not possible';
          return of(SendTeamChatMessageFailAction({ payload: { errorStr, msg_id: teamMessageBody.msg_id } }));
        }),
        tap(action => this.store.dispatch(action))
      ).subscribe();
  }

  deleteTeamRecentInteraction(key: string, id: string): void {
    this.teamInteractionRepository.deleteTeamRecentInteraction(key, id);
  }

  updateRoomReadStatus(interaction_id: any, receiver_id: string) {
    return this.teamInteractionRepository.updateMessageStatus({ interation_id: interaction_id, data: { receiver_id, status: InteractionStatus.read } }).pipe(
      tap((result) => {
        this.teamInteractionRepository.updateInteractionStatus(result.interaction_id, InteractionStatus.read);
      })
    ).subscribe();
  }

  temporarySetMessage(msg_id: number, is_room: boolean, user_id: number | undefined, receiver_id: string, attachments: any[], data: string) {
    const _stash = {
      created_dt: new Date(),
      direction: 'outgoing',
      is_room: is_room,
      page: 1,
      interaction_dt: new Date().toUTCString(),
      interaction_id: msg_id,
      status: 'read',
      type: "message",
      avatar: '',
      user_id: user_id,
      sender_id: user_id,
      receiver_id: receiver_id,
      data: data,
      attachments: attachments
    }
    return _stash;
  }

}
