import { ITranslateRepository } from '@/app/core/IRepositories/ITranslateRepository';
import { LangModel, TranslationLanguage } from '@/app/core/Models/lang';
import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { map, Observable, pluck } from 'rxjs';
import { translationDto, translationResponseDto, updateTranslatePreferencesDto } from '../DTO/translationDto';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';


@Injectable({
  providedIn: 'root',
})
export class TranslateRepository implements ITranslateRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) {}

  getSupportedLnaguages(): Observable<LangModel[]> {
    const requestURL = `${environment.apiURL}languages`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<LangModel[]>;
        return res.results;
      })
    );
  }
  
  setUserTranslationPreferences(request: updateTranslatePreferencesDto): Observable<TranslationLanguage> {
    const requestURL = `${environment.apiURL}translation/preferences`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TranslationLanguage>;
        return res.results;
      })
    );
  } 

  getUserTranslationPreferences(): Observable<TranslationLanguage> {
    const requestURL = `${environment.apiURL}translation/preferences`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TranslationLanguage>;
        return res.results;
      })
    );
  }

  translate(request: translationDto): Observable<translationResponseDto> {
    const requestURL = `${environment.apiURL}translation`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<translationResponseDto>;
        return res.results;
      })
    );
  }

}