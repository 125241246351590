import { AppState } from '@/app/State/AppState';
import { selectTenantProfile } from '@/app/State/Tenant/tenant.selector';
import { Tenant } from '@/app/core/Models/tenant';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, tap } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class PapercupsService {

  profile$ = this.store.select(selectTenantProfile);

  constructor(
    private store: Store<AppState>,
  ) { }

  script: HTMLScriptElement
  configScript: HTMLScriptElement
  id = 'Chat-widget-config'

  init() {

    if (!environment.production) {
      return
    }


    const head = document.head

    const config = {
      token: environment.chatWidget.token,
      inbox: environment.chatWidget.inbox,
      title: "Welcome to Yobi support",
      subtitle: "Ask us anything in the chat window below 😊",
      primaryColor: "#6979f8",
      newMessagePlaceholder: "Start typing...",
      showAgentAvailability: false,
      iframeUrlOverride: "https://window.chat-widget.yib.io",
      agentAvailableText: "We're online right now!",
      agentUnavailableText: "We're away at the moment.",
      requireEmailUpfront: true,
      iconVariant: "outlined",
      baseUrl: "https://chat-widget.yib.io",
      customer: {}
    };

    this.configScript = document.createElement('script');
    this.configScript.id = this.id;
    this.configScript.textContent = 'window.Papercups = { config: ' + JSON.stringify(config) + ' };';

    // Add the script to the head section of the HTML document
    document.getElementById(this.id)?.remove()
    head.appendChild(this.configScript);

    this.script = document.createElement('script');

    // Set the script attributes
    this.script.type = 'text/javascript';
    this.script.async = true;
    this.script.defer = true;
    this.script.src = 'https://chat-widget.yib.io/widget.js';

    // Add the script to the head section of the HTML document

    head.appendChild(this.script);
  }

  remove() {
    this.script?.remove();
    this.configScript?.remove();
    document.getElementsByClassName('Papercups-toggleButtonContainer')[0]?.remove()
    document.getElementsByClassName('Papercups-chatWindowContainer')[0]?.remove()
  }
}
