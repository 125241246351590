import { InteractionClassification } from "@/app/core/Models/interactionClassification";
import { createReducer, on } from "@ngrx/store";
import { AddInteractionCommentAction, AddInteractionCommentFailAction, AddInteractionCommentSuccessAction, CloseInteractionCommentEditAction, GetInteractionClassification, GetInteractionClassificationFailAction, GetInteractionClassificationSuccessAction, GetInteractionCommentAction, GetInteractionCommentFailAction, GetInteractionCommentSuccessAction, OnReceivedInteractionComment, OpenInteractionCommentEditAction, OpenInteractionCommentInputAction, ResendInteractionCommentAction, ToggleInteractionCommentAction, UpdateInteractionCommentSuccessAction } from "./interaction.action";
import { InteractionComment } from "@/app/core/Models/Interaction/InteractionComment";

export type InteractionCommentState = {
  visible?: boolean;
  reply?: boolean;
  fetching?: boolean;
  commentItems: InteractionComment[],
  editable?: Set<number>,
  inputVisible?: boolean
}

export type InteractionsCommentState = {
  [key: string | number]: InteractionCommentState,
}
export interface InteractionsState {
  loadingInteractionClassification: boolean;
  interactionClassification: InteractionClassification[],
  comments: InteractionsCommentState,
}

export const initInteractionsState: InteractionsState = {
  loadingInteractionClassification: false,
  interactionClassification: [],
  comments: {}
};

export const InteractionsReducer = createReducer(
  initInteractionsState,
  on(GetInteractionClassification, (state: InteractionsState) => {
    return {
      ...state,
      loadingInteractionClassification: true,
    };
  }),
  on(GetInteractionClassificationSuccessAction, (state: InteractionsState, { classification }) => {
    return {
      ...state,
      loadingInteractionClassification: false,
      interactionClassification: [...state.interactionClassification, classification]
    };
  }),
  on(GetInteractionClassificationFailAction, (state: InteractionsState) => {
    return {
      ...state,
      loadingInteractionClassification: false,
    };
  }),
  on(ToggleInteractionCommentAction, (state: InteractionsState, { payload }) => {
    const interactionId = payload.interactionId;
    return {
      ...state,
      comments: {
        ...state.comments,
        [interactionId]: {
          ...state.comments[interactionId],
          fetching: !state.comments[interactionId]?.visible,
          visible: !state.comments[interactionId]?.visible,
          inputVisible: (!state.comments[interactionId]?.visible == false && state.comments[interactionId]?.inputVisible == true) ? false : state.comments[interactionId]?.inputVisible
        }
      }
    };
  }),
  on(GetInteractionCommentSuccessAction, (state: InteractionsState, { payload }) => {
    const interactionId = payload.interactionId;
    const comments = payload.comments?.slice().sort((a, b) => {
      return new Date(a.created_dt).getTime() - new Date(b.created_dt).getTime();
    })
    return {
      ...state,
      comments: {
        ...state.comments,
        [interactionId]: {
          ...state.comments[interactionId],
          commentItems: comments,
          fetching: false
        }
      }
    };
  }),
  on(GetInteractionCommentFailAction, (state: InteractionsState, { payload: { interactionId } }) => {
    return {
      ...state,
      comments: {
        ...state.comments,
        [interactionId]: {
          ...state.comments[interactionId],
          fetching: false
        }
      }
    };
  }),
  on(AddInteractionCommentAction, (state: InteractionsState, { payload: { data, profile ,formattedContent } }) => {
    let comments = (
      state.comments[data.interaction_id as any]?.commentItems?.slice() ?? []
    )
    comments.push(Object.assign<InteractionComment, Partial<InteractionComment>>(new InteractionComment(), {
      comment_id: new Date().getTime(),
      content: `[{"text": "${ formattedContent }", "type": "text"}]`,
      contentRAW: data.content,
      created_dt: new Date().toISOString(),
      interaction_id: data.interaction_id as any,
      user_id: profile.userId,
      avatar: profile.avatar,
      given_name: profile.firstName,
      family_name: profile.lastName,
      sending: true
    }));

    return {
      ...state,
      comments: {
        ...state.comments,
        [data.interaction_id as any]: {
          ...state.comments[data.interaction_id as any],
          commentItems: comments.sort((a, b) => new Date(a.created_dt).getTime() - new Date(b.created_dt).getTime())
        }
      }
    };
  }),
  on(OnReceivedInteractionComment, AddInteractionCommentSuccessAction, (state: InteractionsState, { payload }) => {
    let comments = (
      state.comments[payload.interaction_id]?.commentItems?.slice() ?? []
    ).filter(c => c.comment_id !== payload.comment_id && !c.sending)
    comments.push(payload);

    return {
      ...state,
      comments: {
        ...state.comments,
        [payload.interaction_id]: {
          ...state.comments[payload.interaction_id],
          commentItems: comments.sort((a, b) => new Date(a.created_dt).getTime() - new Date(b.created_dt).getTime())
        }
      }
    };
  }),
  on(AddInteractionCommentFailAction, (state: InteractionsState, { payload }) => {
    let comments = (
      state.comments[payload.interaction_id as any]?.commentItems?.slice() ?? []
    ).map(c => !c.sending ? c : Object.assign<InteractionComment, Partial<InteractionComment>>(new InteractionComment, { ...c, sending: false, failed: true }))

    return {
      ...state,
      comments: {
        ...state.comments,
        [payload.interaction_id as any]: {
          ...state.comments[payload.interaction_id as any],
          commentItems: comments.sort((a, b) => new Date(a.created_dt).getTime() - new Date(b.created_dt).getTime())
        }
      }
    };
  }),
  on(ResendInteractionCommentAction, (state: InteractionsState, { payload }) => {
    let comments = (
      state.comments[payload.comment.interaction_id as any]?.commentItems?.slice() ?? []
    ).map(c => c.comment_id !== payload.comment.comment_id 
      ? c 
      : Object.assign<InteractionComment, Partial<InteractionComment>>(new InteractionComment, { ...c, sending: true, failed: false  })
    )
    return {
      ...state,
      comments: {
        ...state.comments,
        [payload.comment.interaction_id as any]: {
          ...state.comments[payload.comment.interaction_id as any],
          commentItems: comments.sort((a, b) => new Date(a.created_dt).getTime() - new Date(b.created_dt).getTime())
        }
      }
    };
  }),
  on(OpenInteractionCommentEditAction, (state: InteractionsState, { payload }) => {
    const editableList = new Set(state.comments[payload.comment_id]?.editable || []);
    editableList.add(payload.comment_id);
    return {
      ...state,
      comments: {
        ...state.comments,
        [payload.interaction_id]: {
          ...state.comments[payload.interaction_id],
          editable: editableList
        }
      }
    };
  }),
  on(CloseInteractionCommentEditAction, (state: InteractionsState, { payload }) => {
    const editableList = new Set(state.comments[payload.comment_id]?.editable || []);
    editableList.delete(payload.comment_id)
    return {
      ...state,
      comments: {
        ...state.comments,
        [payload.interaction_id]: {
          ...state.comments[payload.interaction_id],
          editable: editableList
        }
      }
    };
  }),
  on(UpdateInteractionCommentSuccessAction, (state: InteractionsState, { payload }) => {
    const editableList = new Set(state.comments[payload.comment_id]?.editable || []);
    editableList.delete(payload.comment_id);
    return {
      ...state,
      comments: {
        ...state.comments,
        [payload.interaction_id]: {
          ...state.comments[payload.interaction_id], editable: editableList
        }
      }
    };
  }),
  on(OpenInteractionCommentInputAction, (state: InteractionsState, { payload }) => {
    return {
      ...state,
      comments: {
        ...state.comments,
        [payload.interactionId]: {
          ...state.comments[payload.interactionId], inputVisible: true
        }
      }
    };
  })
);
