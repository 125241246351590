import { IMediaRepository } from '@/app/core/IRepositories/IMediaRepository';
import { IMessagingRepository } from '@/app/core/IRepositories/IMessagingRepository';
import {
  SocketInteraction
} from '@/app/core/Models/Interaction';
import {
  ChatWidgetMessageBody,
  PhoneMessageBody,
  SocialChannelMessageBody,
  TwitterChannelMessageBody,
  WhatsappChannelMessageBody,
  YobiMessageBody
} from '@/app/core/Models/Message';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { Observable, map, pluck } from 'rxjs';
import { bulkSmsDTO } from '../DTO/smsDTO';
import { SuccessResponse } from '../DTO/successResponse';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';
@Injectable({
  providedIn: 'root',
})
export class MessagingRepository implements IMessagingRepository {
  constructor(
    private httpService: HttpService,
    private mediaRepository: IMediaRepository) { }

  sendMultipleMessages(sms: bulkSmsDTO): Observable<SuccessResponse<any>> {
    const requestURL = `${environment.apiURL}messages/Bulk_sms`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      sms,
      false,
      null
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => res as SuccessResponse<any>)
    );
  }

  sendSmsAttachmentMessage(request: FormData): Observable<SocketInteraction> {
    const from = request.get('from_number');
    const to = request?.get('to_number');
    const requestURL = `${environment.apiURL}media/send_sms/image?from_number=${from}&to_number=${to}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SocketInteraction>;
        return Object.assign(new SocketInteraction(), res.results);
      })
    );
  }
  sendFacebookAttachmentMessage(
    request: FormData
  ): Observable<SocketInteraction> {
    const requestURL = `${environment.apiURL}messages/fb/send/media`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SocketInteraction>;
        return Object.assign(new SocketInteraction(), res.results);
      })
    );
  }

  sendInstagramAttachmentMessage(
    request: FormData
  ): Observable<SocketInteraction> {
    const requestURL = `${environment.apiURL}messages/insta/send/media`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SocketInteraction>;
        return Object.assign(new SocketInteraction(), res.results);
      })
    );
  }

  sendSmsMessage(message: PhoneMessageBody): Observable<SocketInteraction> {
    const requestURL = `${environment.apiURL}messages/sms`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      message,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item: any) => {
        let res = item as SuccessApiResponse<SocketInteraction>;
        return Object.assign(new SocketInteraction(), res.results);
      })
    );
  }

  sendBulkGoogleEmails(message: FormData): Observable<SuccessApiResponse<string>> {
    const requestURL = `${environment.apiURL}email/google/bulk/send`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      message,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item: any) => item as SuccessApiResponse<string>)
    );
  }

  sendBulkOutlookEmails(message: FormData): Observable<SuccessApiResponse<string>> {
    const requestURL = `${environment.apiURL}email/outlook/bulk/send`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      message,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item: any) => item as SuccessApiResponse<string>)
    );
  }

  forwardGoogleEmails(message: FormData): Observable<SuccessApiResponse<string>> {
    const requestURL = `${environment.apiURL}email/google/forward`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      message,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item: any) => item as SuccessApiResponse<string>)
    );
  }

  forwardOutlookEmails(message: FormData): Observable<SuccessApiResponse<string>> {
    const requestURL = `${environment.apiURL}email/outlook/forward`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      message,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item: any) => item as SuccessApiResponse<string>)
    );
  }

  sendFacebookMessage(
    message: SocialChannelMessageBody
  ): Observable<SocketInteraction> {
    const requestURL = `${environment.apiURL}messages/fb/send`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      message,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SocketInteraction>;
        return Object.assign(new SocketInteraction(), res.results);
      })
    );
  }

  sendWhatsappMessage(
    message: WhatsappChannelMessageBody
  ): Observable<SocketInteraction> {
    const requestURL = `${environment.apiURL}messages/whatsapp/send`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      message,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SocketInteraction>;
        return Object.assign(new SocketInteraction(), res.results);
      })
    )
  }

  sendTwitterMessage(
    message: TwitterChannelMessageBody
  ): Observable<SocketInteraction> {
    const requestURL = `${environment.apiURL}twitter/send_message`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      message,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SocketInteraction>;
        return Object.assign(new SocketInteraction(), res.results);
      })
    );
  }

  sendInstagramMessage(
    message: SocialChannelMessageBody
  ): Observable<SocketInteraction> {
    const requestURL = `${environment.apiURL}messages/instagram/send`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      message,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SocketInteraction>;
        return Object.assign(new SocketInteraction(), res.results);
      })
    );
  }

  sendViberMessage(
    message: SocialChannelMessageBody
  ): Observable<SocketInteraction> {
    const requestURL = `${environment.apiURL}viber/send/message`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      message,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SocketInteraction>;

        return Object.assign(new SocketInteraction(), res.results);
      })
    );
  }
  //TODO: enhance it better.
  sendYobiMessage(message: YobiMessageBody): Observable<SocketInteraction> {
    const requestURL = `${environment.apiURL}messages/yobi/send/new`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      message,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        //  let res = item as SuccessApiResponse<SocketInteraction>;
        return Object.assign(new SocketInteraction(), item);
      })
    );
  }

  sendChatWidgetMessage(message: ChatWidgetMessageBody): Observable<SocketInteraction> {
    const requestURL = `${environment.apiURL}chatWidget/send`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      message,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SocketInteraction>;
        return Object.assign(new SocketInteraction(), res.results);
      })
    );
  }
}
