  /**
   * Constructs a Momento object, allowing various date utility functions.
   * 
   * @param {Date | string | number} date - The date to initialize the Momento object.
   * @param {string} inputFormat - The format of the provided date string.
   * - `yyyy`: Four-digit year
   * - `MM`: Two-digit month (01-12)
   * - `dd`: Two-digit day of the month (01-31)
   * - `HH`: Two-digit hours in 24-hour format (00-23)
   * - `mm`: Two-digit minutes (00-59)
   * - `ss`: Two-digit seconds (00-59)
   * @example
   * momento('2023-01-30 15:45:30', 'yyyy-MM-dd HH:mm:ss'); // '2023-01-30T15:45:30Z'
   * @returns {Momento} A Momento object encapsulating multiple date utility functions.
   */
  export default (date?: Date | string | number, inputFormat?: string): Momento => {
    let _date = new Date();

    const _moment = {
      get date(): Date {
        return _date;
      },
      set date(date: Date) {
        _date = date;
      },

      initDate(this: Momento) {
        let defaultDate = new Date();

        if (!date) {
          this.date = defaultDate;
        }
        if (date instanceof Date) {
          this.date = date;
        }
        if (typeof date === 'number') {
          this.date = new Date(date);
        }
        if (typeof date === 'string') {
          if (inputFormat) {
            const formatParts = inputFormat.match(/(s{1,2}|m{1,2}|H{2}|h{1}|d{1,2}|w{1,2}|M{1,2}|y{4})/g);
            const dateStr = date;
  
            if (!formatParts) {
              throw new Error('Invalid format');
            }
  
            formatParts.forEach((format: string) => {
              let value: string = '';
              const index = inputFormat.indexOf(format)
              switch (format) {
                case 'yyyy':
                  value = dateStr.substring(index, index + 4);
                  break;
                case 'MM':
                case 'dd':
                case 'HH':
                case 'mm':
                case 'ss':
                  value = dateStr.substring(index, index + 2);
                  break;
                case 'M':
                case 'd':
                case 'h':
                case 'm':
                case 's':
                  value = dateStr.substring(index, index + 1);
                  break;
                // Add other cases as needed
              }
  
              if (isNaN(parseInt(value))) {
                throw new Error(`Invalid value for format ${format}`);
              }
  
              switch (format) {
                case 'yyyy':
                  defaultDate.setFullYear(parseInt(value));
                  break;
                case 'MM':
                case 'M':
                  defaultDate.setMonth(parseInt(value) - 1); // JS months are 0-indexe
                  break;
                case 'dd':
                case 'd':
                  defaultDate.setDate(parseInt(value));
                  break;
                case 'HH':
                case 'h':
                  defaultDate.setHours(parseInt(value));
                  break;
                case 'mm':
                case 'm':
                  defaultDate.setMinutes(parseInt(value));
                  break;
                case 'ss':
                case 's':
                  defaultDate.setSeconds(parseInt(value));
                  break;
                // Add other cases as needed
              }
            });
            this.date = defaultDate;
          } else {
            this.date = new Date(date)
          }
        }
        return this;
      },

      format(this: Momento, outputFormat: string): string {
        const pad = (num: number) => num.toString().padStart(2, '0');
        const replacements: { [key: string]: string } = {
          'yyyy': this.date.getFullYear().toString(),
          'MM': pad(this.date.getMonth() + 1), // Month is 0-indexed
          'dd': pad(this.date.getDate()),
          'HH': pad(this.date.getHours()),
          'mm': pad(this.date.getMinutes()),
          'ss': pad(this.date.getSeconds()),
        };

        return outputFormat.replace(/yyyy|MM|dd|HH|mm|ss/g, (match) => replacements[match]);
      },

      isAfter(this: Momento, date: Date): boolean {
        return this.date!.getTime() > date.getTime();
      },

      isBefore(this: Momento, date: Date): boolean {
        return this.date!.getTime() < date.getTime();
      },

      add(this: Momento, value: number, unit: unitOfTime): Momento {
        const millisecondsPerUnit: { [key: string]: number } = {
          year: 365 * 24 * 60 * 60 * 1000,
          month: 30 * 24 * 60 * 60 * 1000,
          week: 7 * 24 * 60 * 60 * 1000,
          day: 24 * 60 * 60 * 1000,
          hour: 60 * 60 * 1000,
          minute: 60 * 1000,
          second: 1000,
          millisecond: 1,
        };

        const millisecondsToAdd = value * millisecondsPerUnit[unit];
        this.date!.setTime(this.date!.getTime() + millisecondsToAdd);

        return this;
      },

      set(this: Momento, value: number, unit: unitOfTime): Momento {
        switch (unit) {
          case 'year':
            this.date.setFullYear(value);
            break;
          case 'month':
            this.date.setMonth(value);
            break;
          case 'week':
            this.date.setDate(this.date.getDate() + value * 7);
            break;
          case 'day':
            this.date.setDate(value);
            break;
          case 'hour':
            this.date.setHours(value);
            break;
          case 'minute':
            this.date.setMinutes(value);
            break;
          case 'second':
            this.date.setSeconds(value);
            break;
          case 'millisecond':
            this.date.setMilliseconds(value);
            break;
        }
        return this;
      },

      setNextDay(this: Momento, day: DaysOfWeek): Momento {
        const currentDay = this.date.getDay();
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        const daysUntilDay = (days.indexOf(day) + 7 - currentDay) % 7; // Calculate days until day
        const date = new Date(this.date);
        date.setDate(this.date.getDate() + (daysUntilDay ?? 7));
        this.date = date;
        return this;
      },

      tz(this: Momento, timeZone: string): Momento {
        const options: Intl.DateTimeFormatOptions = { timeZone };
        const tzDate = new Date(this.date.toLocaleString('en-US', options));
        const diff = tzDate.getTime() - this.date.getTime()
        this.date = new Date(this.date.getTime() - diff)
        return this;
      },

      unix(this: Momento): number {
        return Math.floor(this.date.getTime() / 1000);
      }
    }

    return _moment.initDate()
  }

declare type unitOfTime = ("year" | "month" | "week" | "day" | "hour" | "minute" | "second" | "millisecond");
declare type DaysOfWeek = ('Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday')
declare type Momento = {
  date: Date;
  /**
   * Checks if the date in the Momento object is after a given date.
   * @param {Date} date - The date to compare.
   * @returns {boolean} True if the date is after the given date; otherwise, false.
   */
  isAfter: (date: Date) => boolean
  /**
   * Checks if the date in the Momento object is before a given date.
   * @param {Date} date - The date to compare.
   * @returns {boolean} True if the date is before the given date; otherwise, false.
   */
  isBefore: (date: Date) => boolean
  /**
   * Sets a specific value for a time unit in the date of the Momento object.
   * @param {number} value - The value to set.
   * @param {unitOfTime} unit - The unit of time to set.
   * @returns {Momento} The updated Momento object after setting the value.
   */
  set: (value: number, unit: unitOfTime) => Momento;
  /**
   * Adds a specific value of a time unit to the date in the Momento object.
   * @param {number} value - The value to add.
   * @param {unitOfTime} unit - The unit of time to add.
   * @returns {Momento} The updated Momento object after addition.
   */
  add: (value: number, unit: unitOfTime) => Momento;
  /**
   * Formats the date in the Momento object based on the provided output format.
   * @param {string} outputFormat - The desired format for the date.
   *                                - 'yyyy': Four-digit year
   *                                - 'MM': Two-digit month (01-12)
   *                                - 'dd': Two-digit day of the month (01-31)
   *                                - 'HH': Two-digit hours in 24-hour format (00-23)
   *                                - 'mm': Two-digit minutes (00-59)
   *                                - 'ss': Two-digit seconds (00-59)
   * @returns {string} The formatted date string.
   */
  format: (outputFormat: string) => string;
  /**
   * Sets the date in the Momento object to the next specified day of the week.
   * @param {DaysOfWeek} day - The day of the week to set.
   * @returns {Momento} The updated Momento object after setting the date.
   */
  setNextDay: (day: DaysOfWeek) => Momento;
  /**
   * Retrieves the Unix timestamp from the date in the Momento object.
   * @returns {number} The Unix timestamp.
   */
  unix: () => number;
  /**
   * Adjusts the timezone of the date in the Momento object.
   * @param {string} timeZone - The timezone to set.
   * @returns {Momento} The updated Momento object with adjusted timezone.
   */
  tz: (timeZone: string) => Momento;
}
