import { EmailFieldDto } from '@/app/Data/DTO/authDto';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthenticationRepository } from '../../IRepositories/IAuthenticationRepository';
import { UseCase } from '../UseCase';

@Injectable({
  providedIn: 'root',
})
export class CheckEmailExistingUseCase
  implements UseCase<EmailFieldDto, SuccessResponse>
{
  constructor(private authRepository: IAuthenticationRepository) {}
  execute(request: EmailFieldDto): Observable<SuccessResponse> {
    return this.authRepository.checkEmailExisting(request);
  }
}
