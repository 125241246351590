import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { InputWrapperComponent } from './input-wrapper.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [InputWrapperComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, AngularSvgIconModule, FormsModule, MatTooltipModule],
  exports: [InputWrapperComponent],
})
export class InputWrapperModule { }
