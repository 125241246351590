import { AppState } from '@/app/State/AppState';
import { createSelector } from '@ngrx/store';
import { SynthState } from './synth.reducer';

const synthState = (state: AppState) => state.synth;

export const selectSynthsList = createSelector(synthState, (state) => state.synths);
export const selectRecentSynthsConversationsState = createSelector(synthState, (state) => state.recentConversations);
export const selectSelectedSynthConversation = createSelector(synthState, (state) => state.selectedConversation);
export const selectedSynth = createSelector(synthState, (state) => state.selectedSynth);
export const selectedConversationId = createSelector(synthState, (state) => state.conversationId);
export const firstCallTranscriptions = createSelector(synthState, (state) => state.firstCallTranscriptions);
export const configurationHistory = createSelector(synthState, (state) => state.configurationHistory);
export const onboardingCallEnd = createSelector(synthState, (state) => state.onboardingCallEnd);
export const transcriptFeedback = createSelector(synthState, (state) => state.transcriptionFeedback)