import { CreateSnippetDto, EditSnippetDto, SnippetDetails } from "@/app/Data/DTO/snippetsDto";
import { Observable } from "rxjs";

export abstract class ISnippetsRepository {
    abstract createSnippets(
        data: CreateSnippetDto
      ): Observable<SnippetDetails>;
    abstract getSnippets(): Observable<SnippetDetails[]>;
    abstract editSnippet(
        request: EditSnippetDto, 
        snippetId: number
      ): Observable<SnippetDetails>;
    abstract deleteSnippet(
      snippetId: number
      ): Observable<SnippetDetails>;
      abstract saveSnippets(snippets: SnippetDetails[]): void;
      abstract getLocalSnippets(): Observable<SnippetDetails[]>;
      
}
