import { AppLang } from '@/app/core/Models/lang';
import { createAction, props, union } from '@ngrx/store';

export const SIGN_OUT = '[App] Sing Out';
export const SignOutAction = createAction(SIGN_OUT);

export const PRE_INITIALIZE_APP = '[APP] Pre Initialize application';
export const PreInitializeApplicationAction = createAction(PRE_INITIALIZE_APP);

export const PRE_INITIALIZE_APP_SUCCESS = '[APP] Application PreInitialized';
export const PreInitializeApplicationSuccessAction = createAction(PRE_INITIALIZE_APP_SUCCESS);


export const PRE_INITIALIZE_MAIN_APP = '[APP] Pre Initialize main application';
export const PreInitializeMainApplicationAction = createAction(PRE_INITIALIZE_MAIN_APP);

export const PRE_INITIALIZE_MAIN_APP_SUCCESS = '[APP] Pre Initialize main application success';
export const PreInitializeMainApplicationSuccessAction = createAction(PRE_INITIALIZE_MAIN_APP_SUCCESS);

export const PRE_INITIALIZE_MAIN_APP_FAIL = '[APP] Pre Initialize main application fail';
export const PreInitializeMainApplicationFailAction = createAction(PRE_INITIALIZE_MAIN_APP_FAIL);


export const LOAD_INTEGRATIONS_STATUS = '[APP] Load Integrations Status';
export const LoadIntegrationsStatus = createAction(
  LOAD_INTEGRATIONS_STATUS
);

export const CHANGE_APP_LANG = '[APP] change app lang';
export const ChangeAppLangAction = createAction(
  CHANGE_APP_LANG,
  props<{ payload: { lang: AppLang } }>()
);

const all = union({
  SignOutAction,
  PreInitializeApplicationAction,
  PreInitializeApplicationSuccessAction,
  LoadIntegrationsStatus,
  ChangeAppLangAction
})
export type AppActions = typeof all;
