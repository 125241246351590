import { ContactChannel } from '@/app/core/Models/Channel';
import { IOverviewTemplate } from "@/app/Ui/components/crm-overview/crm-overview.interface";
import { ChannelType } from './ChannelTypes';
import { Company } from './company';
import { Deal } from './deal';
import { Tag } from './tag';
import { User } from './User';

export class IContactAttachments {
  contact_id: string;
  contact_name: string
  attachments: Array<{ file_name: string, file_type: string, last_modified: string, raw_filename: string, size: number, url: string }>
}

export interface ContactsQueryParams {
  page: number;
  page_size: number;
  search?: string;
  contact_tags_id?: number[]
  contact_id?: string;
  users?: User[]
  all?: boolean
}
export interface ContactsLookupDto {
  keyword: string,
  channel_type: ChannelType.Phone | ChannelType.Email,
}

export interface LookupContact {
  avatar: string,
  channel_id: number,
  channel_name: string,
  channel_type: ChannelType,
  channel_value: string,
  contact_id: number,
  family_name: string,
  given_name: string,
  middle_name: string,
  preferred_name: string,
  tenant_id: number,
  email?: string
}

export interface BasicCustomFieldDetails {
  can_delete: boolean;
  can_modify: string;
  contact_cf_id: number;
  contact_id: number;
  field_name: string;
  field_type: string;
  pinned: boolean;
  tenant_cf_id: number;
  tenant_id: number;
  created_dt: Date;
}

export interface CustomFieldDetails extends BasicCustomFieldDetails {
  field_index: number;
  field_value: string;
}

export class AssociatedCompanies {
  contact_id: string
  given_name: string
  channel_value: any
  avatar: string
  companies: AssociatedCompany[]
}

export class AssociatedCompany {
  yobi_crm_company_id: any
  company_name: string
  industry: string
  company_type: string
  email_address: string
  website_url: string;
  phone_number: string;
  avatar: string
  relationship_type: string
  get isEditing(): boolean {
    return false
  }
}

export class ContactCompanyAssociationDto {
  yobi_crm_company_id: string
  relationship_type?: string
  contact_id: string
}
export class Contact {
  address: string;
  avatar: string;
  avatar64: string;
  birthday: string;
  contact_id: any;
  created_dt: string;
  department: string;
  email: string;
  family_name: string;
  gender: string;
  autoreplay_enabled?: boolean;
  given_name: string;
  is_blocked: boolean;
  middle_name: string;
  preferred_name: string;
  source: any | null;
  tenant_id: number;
  title: string;
  channels?: ContactChannel[];
  updated_dt: string;
  customFields?: CustomFieldDetails[];
  contact_tags?: Tag[];
  full_name?: string;
  associated_deals?: Deal[]
  associated_contacts?: AssociatedContacts
  associated_tasks?: ContactTasks[]
  associated_companies?: AssociatedCompanies
  social_linkedin: string;
  social_twitter: string;
  social_facebook: string;
  social_instagram: string;
  companies: Company[];
  contact_name?: string;
  phone_details: Array<{ channel_id: string, channel_name: string, channel_type: string, channel_value: string }>;
  total_count?: number;
  associated_attachments: any[]
  private _displayName?: string;
  set displayName(value: string) {
    this._displayName = value;
  }
  get displayName(): string {
    return this._displayName ?? `${this.given_name ?? ''} ${this.family_name ?? ''}`.trim()
  }

  get isContact(): boolean {
    return !!this.contact_id;
  }

  get _menus(): Array<{ name: string, icon: string, class: string }> {
    if (Boolean(this.phone_details && this.phone_details.length)) {
      return [
        { name: 'Call', icon: 'assets/icons/new-set/phone-call.svg', class: 'text-primary' },
        { name: 'Edit', icon: 'assets/icons/new-set/edit.svg', class: 'text-primary' },
        { name: 'Message', icon: 'assets/icons/new-set/message.svg', class: 'text-primary' },
        { name: 'Delete', icon: 'assets/icons/new-set/trash.svg', class: 'text-warning' }
      ];
    } else {
      return [
        { name: 'Edit', icon: 'assets/icons/new-set/edit.svg', class: 'text-primary' },
        { name: 'Delete', icon: 'assets/icons/new-set/trash.svg', class: 'text-warning' }
      ];
    }
  }

  get overviewTemplate(): IOverviewTemplate {
    return {
      name: this.displayName,
      avatar: this.avatar,
      title: 'Overview',
      menus: this._menus,
      infos: {
        contact: {
          web: '',
          phone: '',
          email: this.email,
          location: this.address,
          tags: this._getTagsId,
          links: this._socialLinks,
          created_dt: this.created_dt,
          updated_dt: this.updated_dt,
          contact_companies: this.contactCompanies,
          phones: this.phone_details,
          contact_quick_actions: [
            ...((this.phone_details && this.phone_details.length) ? [{ name: 'Call', icon: 'assets/icons/new-set/phone-call.svg', class: 'text-primary' }] : []),
            { name: 'Message', icon: 'assets/icons/new-set/message.svg', class: 'text-primary' }
          ]
        }
      }
    }
  }

  get _getTagsId() {
    if (Boolean(this.contact_tags) && this.contact_tags?.length) {
      return this.contact_tags.map((item) => {
        return { name: item.tag_name, color: item.color ?? item.color, tag_id: item.tag_id };
      });
    } else {
      return [];
    }
  }

  get _socialLinks() {
    const links = [];
    this.social_facebook && links.push({ icon: 'assets/icons/new-set/channels/brand-facebook2.svg', link: this.social_facebook });
    this.social_instagram && links.push({ icon: 'assets/icons/new-set/channels/brand-instagram2.svg', link: this.social_instagram });
    this.social_twitter && links.push({ icon: 'assets/icons/new-set/channels/brand-x2.svg', link: this.social_twitter });
    this.social_linkedin && links.push({ icon: 'assets/icons/new-set/channels/brand-linkedin2.svg', link: this.social_linkedin });
    return links;
  }

  get contactCompanies(): Array<{ company_name: string, relationship_type: string, yobi_crm_company_id: string }> {
    if (Boolean(this.companies) && this.companies.length) {
      return this.companies.map((company) => {
        return { company_name: company.company_name, yobi_crm_company_id: company.yobi_crm_company_id, relationship_type: company.relationship_type as any }
      });
    } else {
      return [];
    }
  }
}
export class UnclassifiedContact extends Contact {
  channelId: number | null;
  unclassifiedChannelId: number | null;
  channelValue: string | null;
  channelType: string | null;
}

export class ContactAssociationDto {
  contact_association_id?: string
  tenant_id?: string
  contact_id: any
  associated_contact_id: string
  association_type: string
}

export class AssociatedContact {
  contact_id: string
  associate_contact_name: string
  associated_contact_id: string
  email: string
  title: string
  avatar: string
  phone_number: string
  association_type: string
  isEditing?: boolean
}
export class AssociatedContacts {
  contact_id: string
  contact_name: string
  email: string
  title: string
  avatar: string
  associated_contacts: AssociatedContact[]
}

export class DeleteContactAssociationDto {
  contact_id: string
  associated_contact_id: string
  tenant_id?: string
}

export class ContactTasks {
  task_id: string
  contact_id: any
  title: string
  description: string
  assigned_to: string
  assigned_user: User
  status: number
  priority: number
  created_dt: string
  updated_dt: string
  attachments: Array<{ filename: string, attachment_url: string }>
}
