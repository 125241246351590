import { Observable } from 'rxjs';

export interface SocketConfig {
  token: string;
  device: string;
}
export abstract class ISocketClient {
  abstract send(event: string, data: string): void;
  abstract onReceive<T>(event: string): Observable<T>;
  abstract connectSocket(config: SocketConfig): void;
  abstract destroy(): void;
}
