<span
    class="color-circle"
    [style.backgroundColor]="content.color">
</span>
<svg-icon
    class="text-warning cursor-pointer hidden scale-125"
    src="assets/icons/new-set/close.svg"
    [svgStyle]="{ 'height.rem': 1, 'width.rem': 1 }"
    (click)="deleteTag()">
</svg-icon>
<span class="font-medium text-base text-dark">
    {{ content.tag_name }}
</span>