import { ITenantRepository } from '@/app/core/IRepositories/ITenantRepository';
import { TokenStorgeService } from '@/app/Data/services/Token/token.service';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IAuthenticationRepository } from '../../IRepositories/IAuthenticationRepository';
import { LoginCredentialsRequest } from '../../Models/LoginCredentials';
import { Tenant } from '../../Models/tenant';
import { UseCase } from '../UseCase';
@Injectable({
  providedIn: 'root',
})
export class LoginWithCredentialsUseCase
  implements UseCase<LoginCredentialsRequest, Tenant>
{
  constructor(
    private authRepository: IAuthenticationRepository,
    private tenantRepository: ITenantRepository,
    private tokenStorgeService: TokenStorgeService,
  ) {}

  async execute(request: LoginCredentialsRequest): Promise<Tenant> {
    const credential = await lastValueFrom(
      this.authRepository.LoginWithCredentials(request)
    );
    // await this.databaseService.init();
    this.tokenStorgeService.saveToken(credential.results.token)
    this.tokenStorgeService.saveRefreshToken(credential.results.refreshToken)
    const user = await lastValueFrom(
      this.authRepository.getUserObject()
    );
    this.tokenStorgeService.saveUser(user)
    this.authRepository.saveTenantProfileObject(user);
    const channels = await lastValueFrom(
      this.tenantRepository.getInternalChannels()
    );
    this.tenantRepository.saveInternalChannels(channels);
    return user;
  }
}
