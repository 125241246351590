import {
  FullTextSearch,
  fullTextSearch,
} from '@/app/core/Models/fullTextSearch';
import { SocketInteraction } from '@/app/core/Models/Interaction';
import { createReducer, on } from '@ngrx/store';
import { SignOutAction } from '../app/action';
import {
  FullTextSearchNextPage,
  GetFullTextSearchAction,
  GetFullTextSearchFail,
  GetFullTextSearchSuccess,
  GetLocalFullTextSearchSuccess,
} from './fullTextSearch.action';

export interface FullTextSearchState {
  query: string;
  result: FullTextSearch;
  currentPages: { type: fullTextSearch.type; page: number }[];
  fromHistory?: boolean;
  searching: boolean;
  fail?: string;
}

export const initFullTextSearchState: FullTextSearchState = {
  result: {},
  currentPages: [],
  query: '',
  searching: false,
};

export const fullTextSearchReducer = createReducer(
  initFullTextSearchState,
  on(GetFullTextSearchAction, (state: FullTextSearchState) => {
    return {
      ...state,
      searching: true,
    };
  }),
  on(
    FullTextSearchNextPage,
    (state: FullTextSearchState, { payload: { page, type } }) => {
      return {
        ...state,
        currentPages: [
          ...state.currentPages.filter((p) => p.type !== type),
          { type: type, page },
        ],
      };
    }
  ),
  on(
    GetFullTextSearchSuccess,
    (
      state: FullTextSearchState,
      {
        payload: {
          data,
          query: { page, type, query },
        },
      }
    ) => {
      const currentResult = page == 1 ? {} : state.result;
      const currentPages =
        page == 1
          ? []
          : [
              ...state.currentPages.filter((p) => p.type !== type[0]),
              { type: type[0], page },
            ];
      return {
        ...state,
        query,
        fromHistory: false,
        searching: false,
        currentPages,
        fail: undefined,
        result: {
          interactions: {
            count:
              data.interactions?.count ??
              currentResult.interactions?.count ??
              0,
            pages:
              !type.length || type.includes(fullTextSearch.type.Interactions)
                ? [
                    ...(currentResult.interactions?.pages ?? []).filter(
                      (p) => p.page !== page
                    ),
                    {
                      page,
                      data: (data.interactions?.data ?? []).map((interaction) =>
                        Object.assign(new SocketInteraction(), interaction)
                      ),
                    },
                  ]
                : currentResult.interactions?.pages ?? [],
          },
          contacts: {
            count: data.contacts?.count ?? currentResult.contacts?.count ?? 0,
            pages:
              !type.length || type.includes(fullTextSearch.type.Contacts)
                ? [
                    ...(currentResult.contacts?.pages ?? []).filter(
                      (p) => p.page !== page
                    ),
                    { page, data: data.contacts?.data ?? [] },
                  ]
                : currentResult.contacts?.pages ?? [],
          },
          notes: {
            count: data.notes?.count ?? currentResult.notes?.count ?? 0,
            pages:
              !type.length || type.includes(fullTextSearch.type.Notes)
                ? [
                    ...(currentResult.notes?.pages ?? []).filter(
                      (p) => p.page !== page
                    ),
                    { page, data: data.notes?.data ?? [] },
                  ]
                : currentResult.notes?.pages ?? [],
          },
          tasks: {
            count: data.tasks?.count ?? currentResult.tasks?.count ?? 0,
            pages:
              !type.length || type.includes(fullTextSearch.type.Tasks)
                ? [
                    ...(currentResult.tasks?.pages ?? []).filter(
                      (p) => p.page !== page
                    ),
                    { page, data: data.tasks?.data ?? [] },
                  ]
                : currentResult.tasks?.pages ?? [],
          },
          tags: {
            count: data.tags?.count ?? currentResult.tags?.count ?? 0,
            pages:
              !type.length || type.includes(fullTextSearch.type.Tags)
                ? [
                    ...(currentResult.tags?.pages ?? []).filter(
                      (p) => p.page !== page
                    ),
                    { page, data: data.tags?.data ?? [] },
                  ]
                : currentResult.tags?.pages ?? [],
          },
        },
      };
    }
  ),
  on(
    GetLocalFullTextSearchSuccess,
    (
      state: FullTextSearchState,
      {
        payload: {
          data: { date, result, value, type },
        },
      }
    ) => {
      return {
        ...state,
        query: value,
        fromHistory: true,
        currentPages: [],
        fail: undefined,
        result: {
          interactions: {
            count: result.interactions?.count ?? 0,
            pages: [
              {
                page: 1,
                data: result.interactions?.data.map((interaction) =>
                Object.assign(new SocketInteraction(), interaction)
              ) ?? [],

              },
            ],
          },
          contacts: {
            count: result.contacts?.count ?? 0,
            pages: [
              {
                page: 1,
                data: result.contacts?.data ?? [],
              },
            ],
          },
          notes: {
            count: result.notes?.count ?? 0,
            pages: [
              {
                page: 1,
                data: result.notes?.data ?? [],
              },
            ],
          },
          tasks: {
            count: result.tasks?.count ?? 0,
            pages: [
              {
                page: 1,
                data: result.tasks?.data ?? [],
              },
            ],
          },
          tags: {
            count: result.tags?.count ?? 0,
            pages: [
              {
                page: 1,
                data: result.tags?.data ?? [],
              },
            ],
          },
        },
      };
    }
  ),
  on(
    GetFullTextSearchFail,
    (
      state: FullTextSearchState,
      {
        payload: {
          message,
          query,
        },
      }
    ) => {
      return {
        ...initFullTextSearchState,
        query,
        fail: message,
      };
    }
  ),
  // on(GetLocalFullTextSearchSuccess),
  on(SignOutAction, () => ({
    ...initFullTextSearchState,
  }))
);
