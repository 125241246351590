import { translationDto, translationResponseDto, updateTranslatePreferencesDto } from "@/app/Data/DTO/translationDto";
import { Observable } from "rxjs";
import { LangModel, TranslationLanguage } from "../Models/lang";

export abstract class ITranslateRepository {
    constructor() { }
    abstract getSupportedLnaguages(): Observable<LangModel[]>;
    abstract setUserTranslationPreferences(request: updateTranslatePreferencesDto): Observable<TranslationLanguage>;
    abstract getUserTranslationPreferences(): Observable<TranslationLanguage>;
    abstract translate(request: translationDto): Observable<translationResponseDto>;
}