import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { AppConfig } from './Config/AppConfig';
import { ConfigurationModule } from './Config/configuration.module';
import { ErrorHandlingService } from './ErrorHanlder/ErrorHandler.service';
import { ConsoleLogger } from './Logging/consoleLogger.service';
import { LoggingService } from './Logging/LoggingService.service';
import { ConfigurationService } from '@/app/shared/Config/configuration.service';

@NgModule({
  declarations: [],
  imports: [ConfigurationModule.forRoot({ config: AppConfig })],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [ConsoleLogger, LoggingService],
    };
  }
}
