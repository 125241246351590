import { Product } from '@/app/core/Models/product';
import { createReducer, on } from '@ngrx/store';
import { FetchProductByIdAction, FetchProductByIdFailedAction, FetchProductByIdSuccessAction, GetProductsListAction, GetProductsListFailAction, GetProductsListSuccessAction, ProductCreateAction, ProductCreateFailAction, ProductCreateSuccessAction, ProductUpdateAction, ProductUpdateFailAction, ProductUpdateSuccessAction, ResetProductsStateAction, SetActiveProductId, ShowProductPageAction } from './products.action';
import { SignOutAction } from '../app/action';

export interface ProductsState {
  products: {
    isLastpage: boolean,
    fetching: boolean,
    page?: number,
    pageSize: number,
    search?: string
  }
  saving?: boolean,
  lookingUp: boolean,
  lookupProducts: Product[]
  active?: {
    productId: string;
    product?: Product,
    fetching?: boolean,
  }
}

export const initProductsState: ProductsState = {
  products: {
    fetching: false,
    pageSize: 15,
    isLastpage: false,
  },
  lookupProducts: [],
  lookingUp: false
};

export const ProductsReducer = createReducer(
  initProductsState,

  on(
    GetProductsListAction,
    (state: ProductsState, { }) => {
      return {
        ...state,
        products: {
          ...state.products,
          fetching: true
        }
      }
    }
  ),
  on(
    ProductCreateAction, ProductUpdateAction, (state: ProductsState, { }) => {
      return {
        ...state,
        saving: true
      }
    }
  ),
  on(
    ProductCreateSuccessAction, ProductCreateFailAction, ProductUpdateSuccessAction, ProductUpdateFailAction, (state: ProductsState, { }) => {
      return {
        ...state,
        saving: false
      }
    }
  ),
  on(
    ShowProductPageAction,
    (state: ProductsState, { payload }) => {
      return {
        ...state,
        active: {
          product: payload,
          productId: payload.yobi_crm_product_id + ''
        }
      }
    }
  ),
  on(
    GetProductsListSuccessAction,
    (state: ProductsState, { payload: { products, params } }) => {
      return {
        ...state,
        products: {
          ...state.products,
          page: params.page,
          search: params.search,
          fetching: false,
          isLastpage: products.length < params.page_size
        }
      }
    }
  ),
  on(
    GetProductsListFailAction,
    (state: ProductsState, { }) => {
      return {
        ...state,
        products: {
          ...state.products,
          fetching: false
        }
      }
    }
  ),
  // on(
  //   LookupProductsAction,
  //   (state: ProductsState, { }) => {
  //     return {
  //       ...state,
  //       lookingUp: true
  //     }
  //   }
  // ),

  // on(
  //   LookupProductsSuccessAction,
  //   (state: ProductsState, { payload: lookupProducts }) => {
  //     return {
  //       ...state,
  //       lookupProducts: lookupProducts,
  //       lookingUp: false
  //     }
  //   }
  // ),


  on(SetActiveProductId, (state: ProductsState, { payload: { productId } }) => {
    return {
      ...state,
      active: {
        productId,
      }
    };
  }),
  on(FetchProductByIdAction, (state: ProductsState, { payload }) => {
    return {
      ...state,
      active: {
        ...state.active,
        productId: payload + '',
        fetching: true
      }
    };
  }),
  on(FetchProductByIdSuccessAction, (state: ProductsState, { payload }) => {
    return {
      ...state,
      active: {
        ...state.active!,
        fetching: false,
        product: payload,
      }
    };
  }),
  on(FetchProductByIdFailedAction, (state: ProductsState, { }) => {
    return {
      ...state,
      active: {
        ...state.active!,
        fetching: false,
      }
    };
  }),

  on(SignOutAction, ResetProductsStateAction, () => {
    return {
      ...initProductsState,
    };
  })
);

