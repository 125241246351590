import { IImportContactsRepository } from '@/app/core/IRepositories/IImportContactsRepository';
import { analyseCsvResponseDto, ImportCsvDto } from '@/app/core/Models/csvDto';
import { getUploadEventProgress } from '@/app/Utilities/functions/getUploadEventProgress';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { last, map, Observable, Observer, pluck, tap } from 'rxjs';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';

@Injectable({
  providedIn: 'root',
})
export class ImportContactRepository implements IImportContactsRepository {
  constructor(
    private httpService: HttpService
  ) { }

  importContactsCsvFile(query: ImportCsvDto): Observable<SuccessApiResponse<string>> {
    const requestURL = `${environment.apiURL}csv-import`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      query,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessApiResponse<string>))
    );
  }

  analyseContactsCsvFile(
    data: FormData,
    progress$: Observer<any>
  ): Observable<analyseCsvResponseDto> {
    const requestURL = `${environment.apiURL}csv-analyze`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false,
      null,
      true
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      tap((event: any) => getUploadEventProgress(event, progress$)),
      last(),
      pluck('body'),
      map((item) => (item as SuccessApiResponse<analyseCsvResponseDto>).results)
    );
  }
}