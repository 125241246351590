import { ScheduledMessage } from './../Models/ScheduledMessage';
import { Observable } from 'rxjs';
import { ScheduleBulkDto, ScheduledMessageDeleteDto, ScheduleMessageUpdateDto, ScheduleSmsMessageDto  } from "@/app/Data/DTO/ScheduleMessageDto";
import { SuccessResponse } from '@/app/Data/DTO/successResponse';

export abstract class IMessagesSchedulerRepository{
    abstract scheduleSmsMessage(request: ScheduleSmsMessageDto): Observable<ScheduledMessage>
    abstract getScheduledMessages(): Observable<ScheduledMessage[]>
    abstract getLocalScheduledMessages(): Observable<ScheduledMessage[]>
    abstract saveScheduledMessages(data: ScheduledMessage[]): void
    abstract cancelScheduledMessage(id: ScheduledMessageDeleteDto): Observable<SuccessResponse>
    abstract deleteLocalScheduledMessage(id: number): void
    abstract updateScheduledMessage(request: ScheduleMessageUpdateDto): Observable<ScheduledMessage>
    abstract scheduleBulk(request: ScheduleBulkDto): Observable<any>
}
