import { Localizations } from '@/app/Utilities/localization';

import { BillingDto } from '@/app/Data/DTO/BillingDto';
import { BillingUpgradePromptDto } from '@/app/Data/DTO/BillingUpgradePromptDto';
import { AppState } from '@/app/State/AppState';
import { ApplyPromoCodeAction, DeleteCurrentPaymentMethodeAction, DeletePromoCodeAction, FetchActiveSubscriptionAction, FetchActiveSubscriptionSuccessAction, FetchBillingInvoicesAction, FetchBillingPlansAction, FetchCurrentPaymentMethodeAction, GetActivePromotionCodesAction, InteractWithBillingPromptAction, ReactiveBillingSubscriptionAction } from '@/app/State/billing/billing.actions';
import { applyingPromoCode, selectActiveBillingSubscription, selectActivePromotionCodes, selectBillingInvoices, selectBillingPlans, selectBillingPromptOpen, selectCurrentPaymentMethod, selectFetchingBillingPlans, selectIsSubscriptionFullyResricted } from '@/app/State/billing/billing.selector';
import { PlatformService } from '@/app/Utilities/platform/platform.service';
import { IBillingRepository } from '@/app/core/IRepositories/IBillingRepository';
import { BillingSubscription } from '@/app/core/Models/billing';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CreateTokenCardData } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { Subject, map, switchMap, tap, throwError } from 'rxjs';
import { AddCreditCardDialogComponent } from '../billing/modals/add-credit-card-dialog/add-credit-card-dialog.component';
import { ConfirmationDialogComponent } from '../components/confirm-dialog/confirmation-dialog.component';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class BillingViewModel {
  readonly fullyRestricted$ = this.store.select(selectIsSubscriptionFullyResricted)
  readonly activeSubscription$ = this.store.select(selectActiveBillingSubscription);
  readonly billingPromptOpen$ = this.store.select(selectBillingPromptOpen);
  readonly activePromotionCodes$ = this.store.select(selectActivePromotionCodes);
  readonly currentPaymentMethod$ = this.store.select(selectCurrentPaymentMethod);
  readonly billingInvoices$ = this.store.select(selectBillingInvoices)
    .pipe(map(items =>
      [...(items ?? [])].sort((a, b) => new Date(b?.created_dt).getTime() - new Date(a?.created_dt).getTime())
    ));
  readonly subscriptionEstimation$ = new Subject<BillingDto.Estimation>();
  readonly applyingPromoCode$ = this.store.select(applyingPromoCode);
  readonly billingPlans$ = this.store.select(selectBillingPlans)
  readonly fetchingBillingPlans$ = this.store.select(selectFetchingBillingPlans)
  invitCode: string;

  constructor(
    private store: Store<AppState>,
    private billingRepository: IBillingRepository,
    private dialog: MatDialog,
    private stripeService: StripeService,
    private router: Router,
    private platform: PlatformService
  ) { }

  fetchBillingPlans() {
    this.store.dispatch(FetchBillingPlansAction())
  }

  activePromotionCodes() {
    this.store.dispatch(GetActivePromotionCodesAction())
  }

  fetchActiveSubscription() {
    this.store.dispatch(FetchActiveSubscriptionAction())
  }

  applyPromoCode(code: string) {
    this.store.dispatch(ApplyPromoCodeAction({ payload: { code } }))
  }

  fetchCurrentPaymentMethode() {
    this.store.dispatch(FetchCurrentPaymentMethodeAction())
  }

  fetchBillingInvoices() {
    this.store.dispatch(FetchBillingInvoicesAction())
  }

  reactiveBillingSubscription() {
    this.store.dispatch(ReactiveBillingSubscriptionAction())
  }

  deletePaymentMethod() {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: Localizations.confirm.delete_payment_method,
        confirm: Localizations.labels.delete,
        warning: true,
      },
    }).afterClosed().subscribe(result =>
      result && this.store.dispatch(DeleteCurrentPaymentMethodeAction())
    );
  }

  deletePromotionCode(codeId: number) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: Localizations.confirm.delete_promo_code,
        confirm: Localizations.labels.extend,
        warning: true,
      },
    }).afterClosed().subscribe(result =>
      result && this.store.dispatch(DeletePromoCodeAction({ payload: { codeId } }))
    );
  }

  cancelSubscription() {
    this.platform.openURL("https://form.jotform.com/232763546038157")
    /*
    this.dialog.open(CancelSubscriptionDialogComponent, {
      width: '450px',
      maxWidth: '90vw',
      panelClass: 'custom-dialog-container',
      height: 'fit-content',
    }).afterClosed().subscribe((data: CancelBillingSubscriptionDTO) => {
      data && this.store.dispatch(CancelBillingSubscriptionAction({ payload: data }))
    });*/
  }

  openAddPaymentMethodDialog() {
    return new Promise<Boolean>((res, rej) => {
      this.dialog.open(AddCreditCardDialogComponent, {
        width: '550px',
        maxWidth: '90vw',
        height: 'fit-content',
        maxHeight: '90vh'
      }).afterClosed().subscribe(success => {
        if (success) {
          this.dialog.open(SuccessDialogComponent, {
            width: '400px',
            maxWidth: '90vw',
            data: {
              message: `success_messages.payment_method_updated`,
            },
          });
          res(true)
        }
        rej()
      });
    })
  }

  openAddPaymentMethodDialogOnboarding(isOnboarding: boolean) {
    return new Promise<Boolean>((res, rej) => {
      this.dialog.open(AddCreditCardDialogComponent, {
        width: '550px',
        maxWidth: '90vw',
        height: 'fit-content',
        maxHeight: '90vh'
      }).afterClosed().subscribe(success => {
        if (success) {
          this.dialog.open(SuccessDialogComponent, {
            width: '400px',
            maxWidth: '90vw',
            data: {
              isOnboarding: isOnboarding,
              message: `success_messages.payment_method_updated`,
            },
          });
          res(true)
        }
        rej()
      });
    })
  }

  savPaymentMethod(card: StripeCardComponent, data: Partial<CreateTokenCardData> & Required<Pick<CreateTokenCardData, 'name' | 'address_country'>> & { is_onboarding?: boolean }) {
    return this.stripeService
      .createToken(card.element, data)
      .pipe(
        switchMap((result) => {
          return result.token
            ? this.billingRepository.addPaymentMethod({
              card_token: result.token.id,
              cardholder: data.name,
              country: data.address_country,
              line1: data.address_line1,
              line2: data.address_line2,
              city: data.address_city,
              state: data.address_state,
              zipcode: data.address_zip,
              is_onboarding: data.is_onboarding,
            })
            : throwError(() => new Error(result.error!.message))
        })
      )
  }

  InteractWithBillingPrompt(data: BillingUpgradePromptDto) {
    this.store.dispatch(InteractWithBillingPromptAction({ payload: data }))
  }

  openBillingPage(sendEvent = true) {

  }

  getSubscriptionEstimlation(plan_id: number) {
    this.billingRepository.getEstimation(plan_id).pipe(
      tap((estimation => this.subscriptionEstimation$.next(estimation)))
    )
      .subscribe()
  }


  subscribeToCustomPlan() {
    return this.billingRepository.customPlan().pipe(tap(() => {
      // TODO open yobi support
      this.router.navigate([`main/inbox`])
    }))
  }

  updateSubscription(plan_id: number) {
    return this.billingRepository.updateBillingSubscription(plan_id).pipe(
      map(subscriptionDto => Object.assign(new BillingSubscription(), subscriptionDto)),
      tap(subscription => {
        this.store.dispatch(FetchActiveSubscriptionSuccessAction({ payload: subscription }))
      }))
  }
}
