

import {fullTextSearch as fullTextSearch_enum} from './enums/fullTextSearch'
import {integrations as integrations_enum} from './enums/integrations'
import {labels as labels_enum} from './enums/labels'
import {messages as messages_enum} from './enums/messages'
import {navigations as navigations_enum} from './enums/navigations'
import {not_found as not_found_enum} from './enums/not_found'
import {notifications as notifications_enum} from './enums/notifications'
import {onboarding as onboarding_enum} from './enums/onboarding'
import {placeholder as placeholder_enum} from './enums/placeholder'
import {subscription as subscription_enum} from './enums/subscription'
import {success_messages as success_messages_enum} from './enums/success_messages'
import {terms as terms_enum} from './enums/terms'
import {alert_dialog as alert_dialog_enum} from './enums/alert_dialog'
import {auth as auth_enum} from './enums/auth'
import {calls as calls_enum} from './enums/calls'
import {confirm as confirm_enum} from './enums/confirm'
import {connections as connections_enum} from './enums/connections'
import {errors as errors_enum} from './enums/errors'
import {sentiments as sentiments_enum} from './enums/sentiments'
import {channels as channels_enum} from './enums/channels'
export namespace Localizations {
   export import alert_dialog = alert_dialog_enum
   export import auth = auth_enum
   export import terms = terms_enum
   export import success_messages = success_messages_enum
   export import subscription = subscription_enum
   export import placeholder = placeholder_enum
   export import onboarding = onboarding_enum
   export import notifications = notifications_enum
   export import not_found = not_found_enum
   export import navigations = navigations_enum
   export import messages = messages_enum
   export import labels = labels_enum
   export import integrations = integrations_enum
   export import fullTextSearch = fullTextSearch_enum
   export import calls = calls_enum
   export import confirm = confirm_enum
   export import connections = connections_enum
   export import errors = errors_enum
   export import sentiments = sentiments_enum
   export import channels = channels_enum
   
 }

