import { Inject, Injectable } from '@angular/core';
import { lastValueFrom, Observable, of, tap } from 'rxjs';
import { UseCase } from '../UseCase';
import { ITenantRepository } from '@/app/core/IRepositories/ITenantRepository';
import { InternalChannel } from '@/app/core/Models/Channel';

@Injectable({
  providedIn: 'root',
})
export class GetInternalChannelsUseCase
  implements UseCase<any, Observable<InternalChannel[]>>
{
  constructor(private tenantRepository: ITenantRepository) {}
  execute(): Observable<InternalChannel[]> {
    return this.tenantRepository.getInternalChannels().pipe(
      tap((channels: InternalChannel[]) => {
        this.tenantRepository.saveInternalChannels(channels);
      })
    );
  }
}
