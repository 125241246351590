import { Observable } from 'rxjs';
import { IntegrationsResult } from '../Models/integrations';
import { FacebookPostDto, InstagramPostDto, LinkedinPostDto, TwitterPostDto } from '@/app/Data/DTO/socialPublish/SocialPostDto';

export abstract class ISocialPublisherRepository {
  abstract publishToFacebook(request: FacebookPostDto): Observable<any>;
  abstract publishToInstagram(request: InstagramPostDto): Observable<any>;
  abstract publishToTwitter(request: TwitterPostDto): Observable<any>;
  abstract publishToLinkedin(request: LinkedinPostDto): Observable<any>;
  
}