export const TOKEN_KEY = 'yib_tk';
export const REFRESH_TOKEN_KEY = 'yib_rt';
export const USER_KEY = 'yib_user';
export const VOICE_TOKEN = 'yib_vt';
export const FCM_TOKEN = 'fb_token';
export const PRIMARY_PHONE_CHANNEl = 'yib_pr_id' // Primary Channel Id
export const DEMO_SESSIONID = 'session_id'
export const INPUT_DEVICE = 'input_device'
export const OUTPUT_DEVICE = 'output_device'
export const APP_LANG = 'app_lang'
export const APP_USER_CHOICE = 'app_user_coice'
export const DARK_MODE = 'dark_mode'
export const COMPACT_MODE = 'compact_mode'
export const INBOXES = 'inboxes';
export const SIDE_NAV = 'toggle_side_nav';
export const RIGHT_PANEL_WINDOW = 'right_panel_window';
export const HOME_ONBOARDING_STEPS = 'home_onboarding_steps';
export const RIGHT_PANEL_VISIBLE = 'right_panel_visible';

