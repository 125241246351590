import { ILogEntry } from './ILogEntry';

export interface ILogger {
  execute(): void;
}

export abstract class Logger implements ILogger {
  hasWriter = false;
  entry: ILogEntry | null = null;

  execute(): void {
    if (this.hasWriter) {
      this.write();
    }
  }

  public abstract write(): void;
}
