import { createReducer, on } from '@ngrx/store';
import { RightPanelIndexes, SetRightPanelActiveWindowAction, ToggleDarkMode, ToggleInboxDetailsPanelAction, TogglePageLeftSidePanelAction, ToggleSideNav } from './media.observer.action';
import { SIDE_NAV, RIGHT_PANEL_WINDOW } from '@/app/Utilities/TokenKeys';
import { OpenTeamChatThreadAction } from '../TeamInbox/teamInbox.action';

export interface MediaObserverState {
  rightPanelActiveWindow: RightPanelIndexes,
  toggleLeftSidePanel: boolean;
  toggleThreadRightSidePanel: boolean;
  toggleSideNav: boolean;
  darkMode: boolean;
  compactMode: boolean;
}

export const initMediaObserverState: MediaObserverState = {
  rightPanelActiveWindow: +localStorage.getItem(RIGHT_PANEL_WINDOW)! && Object.values(RightPanelIndexes).includes(+localStorage.getItem(RIGHT_PANEL_WINDOW)!) ? +localStorage.getItem(RIGHT_PANEL_WINDOW)! : RightPanelIndexes.users,
  toggleLeftSidePanel: false,
  toggleThreadRightSidePanel: window.innerWidth > 1440,
  toggleSideNav: window.innerWidth > 1280 && (localStorage.getItem(SIDE_NAV) == null || localStorage.getItem(SIDE_NAV) === 'true'),
  darkMode: false,
  compactMode: false
};

export const MediaObserverReducer = createReducer(
  initMediaObserverState,
  on(SetRightPanelActiveWindowAction, (state: MediaObserverState, { payload }) => {
    localStorage.setItem(RIGHT_PANEL_WINDOW, payload.toString())
    return {
      ...state,
      rightPanelActiveWindow: payload,
      toggleThreadRightSidePanel: true,
      toggleLeftSidePanel: false
    };
  }),
  on(OpenTeamChatThreadAction, (state: MediaObserverState, { payload: { idle } }) => {
    localStorage.setItem(RIGHT_PANEL_WINDOW, RightPanelIndexes.teamChat.toString())
    return {
      ...state,
      rightPanelActiveWindow: RightPanelIndexes.teamChat,
      toggleThreadRightSidePanel: idle ? state.toggleThreadRightSidePanel : true,
      toggleLeftSidePanel: idle ? state.toggleLeftSidePanel : false
    };
  }),
  // on(SetActiveThreadIdAction, (state: MediaObserverState, { }) => {
  //   return {
  //     ...state,
  //     rightPanelActiveWindow: RightPanelIndexes.contact
  //   };
  // }),
  on(TogglePageLeftSidePanelAction, (state: MediaObserverState) => {
    return {
      ...state,
      toggleLeftSidePanel: !state.toggleLeftSidePanel,
      toggleThreadRightSidePanel: false
    };
  }),
  on(ToggleInboxDetailsPanelAction, (state: MediaObserverState) => {
    return {
      ...state,
      toggleLeftSidePanel: false,
      toggleThreadRightSidePanel: !state.toggleThreadRightSidePanel
    };
  }),
  on(ToggleSideNav, (state: MediaObserverState) => {
    const toggleSideNav = !state.toggleSideNav;
    localStorage.setItem(SIDE_NAV, toggleSideNav.toString())
    return {
      ...state,
      toggleSideNav
    };
  }),
  on(ToggleDarkMode, (state: MediaObserverState, { payload }) => {
    return {
      ...state,
      darkMode: payload
    };
  }),
  // on(ToggleCompactMode, (state: MediaObserverState, { payload }) => {
  //   return {
  //     ...state,
  //     compactMode: payload
  //   };
  // }),
);
