import { Injectable } from '@angular/core';
import { Contact } from '../../Models/contact';
import { UseCase } from '../UseCase';
import { Observable, tap } from 'rxjs';
import { IContactRepository } from '@/app/core/IRepositories/IContactRepository';
import { IMessagesRepository } from '../../IRepositories/IMessagesRepository';

@Injectable({
  providedIn: 'root',
})
export class CreateContactUseCase implements UseCase<Contact, Contact> {
  constructor(private contactRepository: IContactRepository) {}

  execute(request: Contact): Observable<Contact> {
    return this.contactRepository
      .createContact(request)
      .pipe()
      .pipe(
        tap((contact) => {
          const channels = [...(contact?.channels || [])];
          delete contact?.channels;
          this.contactRepository.saveContactChannels(
            contact.contact_id,
            channels
          );
          this.contactRepository.saveContact(contact);
        })
      );
  }
}
