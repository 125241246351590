import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { IAuthenticationRepository } from '@/app/core/IRepositories/IAuthenticationRepository';
import { LoginCredentialsRequest } from '@/app/core/Models/LoginCredentials';
import { Tenant } from '@/app/core/Models/tenant';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { Observable, map, pluck, tap } from 'rxjs';
import { EmailVerificationCodeDto, lightwightSignupDto } from '../DTO/EmailVerificationCodeDto';
import { ILoginWithCredentialsResponse } from '../DTO/LoginResponse';
import { PasswordChangeDto } from '../DTO/PasswordChangeDto';
import { PasswordResetDto } from '../DTO/PasswordResetDto';
import { PasswordResetRequestLinkDto } from '../DTO/PasswordResetRequestLinkDto';
import { PasswordResetTokenDto } from '../DTO/PasswordResetTokenDto';
import { RegisterTenantPasswordDto } from '../DTO/RegisterTenantPasswordDto';
import { VerificationCodeDto } from '../DTO/VerificationCodeDto';
import {
  EmailFieldDto,
  RefreshTokenDto,
  RefreshTokenResultDto
} from '../DTO/authDto';
import { SuccessResponse } from '../DTO/successResponse';
import { TenantObjectResponseDTO } from '../DTO/tenantObjectResponseDTO';
import { RequestPhoneVerificationCodeDto } from '../DTO/verifications/RequestPhoneVerificationCodeDto';
import { ValidatePhoneVerificationCodeDto } from '../DTO/verifications/ValidatePhoneVerificationCodeDto';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';
import { PasswordResetResponseDto } from './../DTO/PasswordResetResponseDto';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationRepository implements IAuthenticationRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) { }


  requestPhoneVerificationCode(
    request: RequestPhoneVerificationCodeDto
  ): Observable<any> {
    const requestURL = `${environment.apiURL}registration/v2/phone/send-verification-code`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  validatePhoneVerificationCode(
    request: ValidatePhoneVerificationCodeDto
  ): Observable<any> {
    const requestURL = `${environment.apiURL}registration/v2/phone/validate-verification-code`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  resetPassowrd(
    request: PasswordResetDto
  ): Observable<PasswordResetResponseDto> {
    const requestURL = `${environment.apiURL}users/reset-password`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item: any) => {
        let res = item as SuccessApiResponse<PasswordResetResponseDto>;
        return res.results;
      })
    );
  }
  verifyPasswordResetToken(
    request: PasswordResetTokenDto
  ): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}users/reset-password/verify`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options, false).pipe(
      pluck('results'),
      map((item: any) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }
  requestPasswordResetLink(
    request: PasswordResetRequestLinkDto
  ): Observable<SuccessResponse> {
    const URL = `${environment.apiURL}users/send-email-reset-password`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      URL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  refreshToken(request: RefreshTokenDto): Observable<RefreshTokenResultDto> {
    const requestURL = `${environment.apiURL}refresh-token`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      map((item) => {
        let res = item as SuccessApiResponse<RefreshTokenResultDto>;
        return res.results;
      })
    );
  }
  passwordChange(request: PasswordChangeDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}users/password`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  validateVerificationCode(
    request: VerificationCodeDto
  ): Observable<ILoginWithCredentialsResponse> {
    const requestURL = `${environment.apiURL}registration/v3/validate_verification_code`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options, false).pipe(
      map((item) => {
        return (item as SuccessApiResponse<ILoginWithCredentialsResponse>)
          .results!;
      })
    );
  }

  signupWithCredentials(
    request: lightwightSignupDto
  ): Observable<ILoginWithCredentialsResponse> {
    const requestURL = `${environment.apiURL}registration/v3/send-verification-code-skip-email-otp`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options, false).pipe(
      map((item) => {
        return (item as SuccessApiResponse<ILoginWithCredentialsResponse>)
          .results!;
      })
    );
  }

  requestEmailVerificationCode(
    request: EmailVerificationCodeDto
  ): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}registration/v3/send-verification-code`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  checkEmailExisting(request: EmailFieldDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}registration/v2/tenant/validation/email`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  getUserObject(): Observable<Tenant> {
    const requestURL = `${environment.apiURL}users/me`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TenantObjectResponseDTO>;
        return TenantObjectResponseDTO.mapToDomain(res.results);
      })
    );
  }

  LoginWithCredentials(
    credential: LoginCredentialsRequest
  ): Observable<ILoginWithCredentialsResponse> {
    const requestURL = `${environment.apiURL}users/login`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      credential,
      false
    );
    return this.httpService.execute(options, false).pipe(
      map((item) => {
        return (item as SuccessApiResponse<ILoginWithCredentialsResponse>)
          .results!;
      })
    );
  }

  LoginWithGoogle(): Observable<string> {
    const requestURL = `${environment.apiURL}google/sign_in`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessResponse<string>).results)
    );
  }

  LoginWithApple(): Observable<string> {
    const requestURL = `${environment.apiURL}apple/authorize`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessResponse<string>).results)
    );
  }

  LoginWithFacebook(): Observable<string> {
    const requestURL = `${environment.apiURL}fb/login/authorize`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((res) => (res as SuccessResponse<string>).results)
    );
  }

  saveTenantProfileObject(data: Tenant) {
    this.databaseService.transaction(
      'rw',
      this.databaseService.tenants,
      async () => {
        let profiles = await this.databaseService.tenants.toArray()
        // fix for tenanatId changed with users/me recent update.
        if (profiles.length > 1) {
          const oldProfiles = profiles.filter(profile => profile.tenantId != data.tenantId).map(profile => profile.tenantId as unknown as string)
          await this.databaseService.tenants.bulkDelete(oldProfiles);
        }
        await this.databaseService.tenants.put(data);
      })
  }
}
