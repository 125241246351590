export enum labels {
  cancel = 'labels.cancel',
  confirm = 'labels.confirm',
  delete = 'labels.delete',
  extend = 'labels.extend',
  no = 'labels.no',
  visit = 'labels.visit',
  yes_cancel_it = 'labels.yes_cancel_it',
  send = 'labels.send',
  send_again = 'labels.send_again',
  write_new_message = 'labels.write_new_message',
  powered_by_chatgpt = 'labels.powered_by_chatgpt',
  logout = 'labels.logout',
  make_a_call = 'labels.make_a_call',
  get_phone_number = 'labels.get_phone_number',
  copy_url = 'labels.copy_url',
  delete_image = 'labels.delete_image',
  delete_message = 'labels.delete_message',
  delete_note = 'labels.delete_note',
  delete_recording = 'labels.delete_recording',
  download = 'labels.download',
  do_not_disturb = 'labels.do_not_disturb',
  call_back = 'labels.call_back',
  show_original_text = 'labels.show_original_text',
  see_original_text = 'labels.see_original_text',
  show_translation_to = 'labels.show_translation_to',
  recreate = 'labels.recreate',
  resend = 'labels.resend',
  sending = 'labels.sending',
  only_visible_to_your_team = 'labels.only_visible_to_your_team',
  copy_to_clipboard = 'labels.copy_to_clipboard',
  to = 'labels.to',
  email = 'labels.email',
  block_contact = 'labels.block_contact',
  click_to_copy_phone_number = 'labels.click_to_copy_phone_number',
  generate_calendly_link = 'labels.generate_calendly_link',
  generate_zoom_link = 'labels.generate_zoom_link',
  hide_contact_panel = 'labels.hide_contact_panel',
  mark_as_read = 'labels.mark_as_read',
  mark_as_unread = 'labels.mark_as_unread',
  more_options = 'labels.more_options',
  move_to_archived = 'labels.move_to_archived',
  move_to_inbox = 'labels.move_to_inbox',
  unblock_contact = 'labels.unblock_contact',
  show_contact_panel = 'labels.show_contact_panel',
  are_typing = 'labels.are_typing',
  is_typing = 'labels.is_typing',
  add_note = 'labels.add_note',
  add_insight = 'labels.add_insight',
  reply = 'labels.reply',
  schedule_for_later = 'labels.schedule_for_later',
  translate_to = 'labels.translate_to',
  translating = 'labels.translating',
  upload_images = 'labels.upload_images',
  upload_images_videos = 'labels.upload_images_videos',
  upload_videos = 'labels.upload_videos',
  sms_invite = 'labels.sms_invite',
  collaps_menu = 'labels.collaps_menu',
  expand_menu = 'labels.expand_menu',
  add_apps = 'labels.add_apps',
  activet_promo_code = 'labels.activet_promo_code',
  apply_promo_code = 'labels.apply_promo_code',
  cancel_subscription = 'labels.cancel_subscription',
  change_plan = 'labels.change_plan',
  got_promo_code = 'labels.got_promo_code',
  credit_card_number = 'labels.credit_card_number',
  expires_on = 'labels.expires_on',
  update_payment_method = 'labels.update_payment_method',
  per_user = 'labels.per_user',
  plan_base_price = 'labels.plan_base_price',
  reactivate = 'labels.reactivate',
  remove = 'labels.remove',
  select = 'labels.select',
  select_plan = 'labels.select_plan',
  link_now = 'labels.link_now',
  linked = 'labels.linked',
  notify_me = 'labels.notify_me',
  open_settings = 'labels.open_settings',
  import = 'labels.import',
  import_from_google_hint = 'labels.import_from_google_hint',
  import_from_outlook = 'labels.import_from_outlook',
  import_from_outlook_hint = 'labels.import_from_outlook_hint',
  import_contacts = 'labels.import_contacts',
  import_from_google = 'labels.import_from_google',
  import_from_csv = "labels.import_from_csv",
  import_from_csv_field_not_used = "labels.import_from_csv_field_not_used",
  import_from_csv_file_format = "labels.import_from_csv_file_format",
  import_from_csv_hint = "labels.import_from_csv_hint",
  import_from_csv_learn_more = "labels.import_from_csv_learn_more",
  import_from_csv_map_columns = "labels.import_from_csv_map_columns",
  import_from_csv_map_columns_hint = "labels.import_from_csv_map_columns_hint",
  select_or_drag_and_drop = "labels.select_or_drag_and_drop",
  add_users = 'labels.add_users',
  email_invite = 'labels.email_invite',
  invite_users = 'labels.invite_users',
  ignore_this_field = 'labels.ignore_this_field',
  resend_invitation = 'labels.resend_invitation',
  application_language = 'labels.application_language',
  change_avatar = 'labels.change_avatar',
  change_password = 'labels.change_password',
  delete_account = 'labels.delete_account',
  delete_avatar = 'labels.delete_avatar',
  languages_preferences = 'labels.languages_preferences',
  messages_translation_language = 'labels.messages_translation_language',
  add_task = 'labels.add_task',
  add_to_contact = 'labels.add_to_contact',
  update_user_information = 'labels.update_user_information',
  add_a_new_user_to_your_team = 'labels.add_a_new_user_to_your_team',
  personal_information = 'labels.personal_information',
  contact_information = 'labels.contact_information',
  add_field = 'labels.add_field',
  add_custom_field = 'labels.add_custom_field',
  show_empty_fields = 'labels.show_empty_fields',
  phone_number = 'labels.phone_number',
  pinned_fields = 'labels.pinned_fields',
  archived = 'labels.archived',
  contact_tags = 'labels.contact_tags',
  copy = 'labels.copy',
  copy_phone_number = 'labels.copy_phone_number',
  edit = 'labels.edit',
  add_to_existing_contact = 'labels.add_to_existing_contact',
  create_new_contact = 'labels.create_new_contact',
  open = 'labels.open',
  create_task = 'labels.create_task',
  archive = 'labels.archive',
  edit_task = 'labels.edit_task',
  not_linked_to_conversation = 'labels.not_linked_to_conversation',
  linked_to_conversation = 'labels.linked_to_conversation',
  mark_as_done = 'labels.mark_as_done',
  mark_as_open = 'labels.mark_as_open',
  add_new_task = 'labels.add_new_task',
  move_to_open = 'labels.move_to_open',
  see_details = 'labels.see_details',
  in_progress = 'labels.in_progress',
  completed = 'labels.completed',
  archived_task = 'labels.archived_task',
  assigned_to_all = 'labels.assigned_to_all',
  assigned_to_me = 'labels.assigned_to_me',
  assign_to = 'labels.assign_to',
  archive_task = 'labels.archive_task',
  assign_to_team_member = 'labels.assign_to_team_member',
  attach_file = 'labels.attach_file',
  create = 'labels.create',
  editing_the_task = 'labels.editing_the_task',
  notify_all_users = 'labels.notify_all_users',
  done = 'labels.done',
  start_new_conversation = 'labels.start_new_conversation',
  start_conversation_to_display = 'labels.start_conversation_to_display',
  scheduled_messages = 'labels.scheduled_messages',
  assigned_to = 'labels.assigned_to',
  close = 'labels.close',
  new_messages = 'labels.new_messages',
  sentiments_analysis = 'labels.sentiments_analysis',
  contact_channel = 'labels.contact_channel',
  current_channel = 'labels.current_channel',
  help_center = 'labels.help_center',
  team_chat = 'labels.team_chat',
  all_messages = 'labels.all_messages',
  contact_support = 'labels.contact_support',
  current_plan = 'labels.current_plan',
  everything_in = 'labels.everything_in',
  free_plan = 'labels.free_plan',
  date_of_birth = 'labels.date_of_birth',
  add = 'labels.add',
  full_name = 'labels.full_name',
  schedule_message = 'labels.schedule_message',
  business_name = 'labels.business_name',
  dialer_pad = 'labels.dialer_pad',
  my_apps = 'labels.my_apps',
  first_name = 'labels.first_name',
  last_name = 'labels.last_name',
  wait_please = 'labels.wait_please',
  finish = 'labels.finish',
  send_invitation_via = 'labels.send_invitation_via',
  change = 'labels.change',
  resend_code = 'labels.resend_code',
  validating = 'labels.validating',
  verify_and_continue = 'labels.verify_and_continue',
  or = 'labels.or',
  select_role = 'labels.select_role',
  create_account = 'labels.create_account',
  from_to = 'labels.from_to',
  see_conversation = 'labels.see_conversation',
  test = 'labels.test',
  updating = 'labels.updating',
  scheduling = 'labels.scheduling',
  primary = 'labels.primary',
  filter_by = 'labels.filter_by',
  reset_filter = 'labels.reset_filter',
  task_title = 'labels.task_title',
  scheduled = 'labels.scheduled',
  updated = 'labels.updated',
  add_contact = 'labels.add_contact',
  see_all_your_scheduled_messages = 'labels.see_all_your_scheduled_messages',
  update_contact = 'labels.update_contact',
  add_phone_numbers = 'labels.add_phone_numbers',
  adding_new_channel = 'labels.adding_new_channel',
  save = 'labels.save',
  update = 'labels.update',
  upgrade_now = 'labels.upgrade_now',
  choose_country = 'labels.choose_country',
  choose_language = 'labels.choose_language',
  loading = 'labels.loading',
  upgrade = 'labels.upgrade',
  choose_timezone = 'labels.choose_timezone',
  current_password = 'labels.current_password',
  new_password = 'labels.new_password',
  confirm_new_password = 'labels.confirm_new_password',
  channel_name = 'labels.channel_name',
  business_hours = 'labels.business_hours',
  per_user_by_month = 'labels.per_user_by_month',
  add_credit_card = 'labels.add_credit_card',
  save_card = 'labels.save_card',
  discard = 'labels.discard',
  pay_amount = 'labels.pay_amount',
  checkout = 'labels.checkout',
  unlink = 'labels.unlink',
  link = 'labels.link',
  start_time = 'labels.start_time',
  start_date = 'labels.start_date',
  add_attendee = 'labels.add_attendee',
  required = 'labels.required',
  attendees = 'labels.attendees',
  end_date = 'labels.end_date',
  end_time = 'labels.end_time',
  select_channel_to_receive_notification = 'labels.select_channel_to_receive_notification',
  create_an_event = 'labels.create_an_event',
  link_all = 'labels.link_all',
  export = 'labels.export',
  search = 'labels.search',
  linking = 'labels.linking',
  unlinking = 'labels.unlinking',
  message_notifications = 'labels.message_notifications',
  tag_name = 'labels.tag_name',
  create_tag = 'labels.create_tag',
  create_this_tag = 'labels.create_this_tag',
  task_details = 'labels.task_details',
  task_attachments = 'labels.task_attachments',
  creating_the_task = 'labels.creating_the_task',
  create_snippet = 'labels.create_snippet',
  only_for_me = 'labels.only_for_me',
  for_the_team = 'labels.for_the_team',
  send_at = 'labels.send_at',
  edit_profile = 'labels.edit_profile',
  edit_message = 'labels.edit_message',
  new_message = 'labels.new_message',
  update_note = 'labels.update_note',
  cancel_message = 'labels.cancel_message',
  reschedule_message = 'labels.reschedule_message',
  add_new_line_hint = 'labels.add_new_line_hint',
  enter_to_save = 'labels.enter_to_save',
  dismiss_all = 'labels.dismiss_all',
  allow = 'labels.allow',
  shared = 'labels.shared',
  messages_preferences = 'labels.messages_preferences',
  messages_signature = 'labels.messages_signature',
  add_channels = 'labels.add_channels',
  share_now = 'labels.share_now',
  choose_an_emoji = 'labels.choose_an_emoji',
  attach_a_file = 'labels.attach_a_file',
  uploading_attachment = 'labels.uploading_attachment',
  try_again = 'labels.try_again',
  create_post = 'labels.create_post',
  publish_post = 'labels.publish_post',
  business_information = 'labels.business_information',
  your_answered_questions = 'labels.your_answered_questions',
  synth_feedback = 'labels.synth_feedback',
  none = 'labels.none',
  custom = 'labels.custom',
  in_15_minutes = 'labels.in_15_minutes',
  in_30_minutes = 'labels.in_30_minutes',
  in_one_day = 'labels.in_one_day',
  in_two_days = 'labels.in_two_days',
  in_one_week = 'labels.in_one_week',
  voicemail = 'labels.voicemail',
  hide = 'labels.hide',
  replies = 'labels.replies',
  make_note = 'labels.make_note',
  give_feedback = 'labels.give_feedback',
  application_preferences = 'labels.application_preferences',
  business_preferences = 'labels.business_preferences',
  phone_number_preferences = 'labels.phone_number_preferences',
  unpin_channel = 'labels.unpin_channel',
  pin_channel = 'labels.pin_channel',
  merge = 'labels.merge',
  merge_contact = 'labels.merge_contact',
  synth_task_assignment_reason = 'labels.synth_task_assignment_reason',
  synth_task_assignee = 'labels.synth_task_assignee',
}
