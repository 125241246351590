import {
  Directive,
  HostListener,
  OnInit,
  ElementRef,
  Input,
} from '@angular/core';
import { NgModule, ChangeDetectorRef } from '@angular/core';
import { CountryCode, formatIncompletePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

@Directive({
  selector: '[phoneInputMask]',
})
export class PhoneMaskDirective implements OnInit {
  @Input() countryCode: CountryCode = "US";
  
  constructor(
    private elementRef: ElementRef,
    private dk: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.formatPhone(this.elementRef.nativeElement.value);
  }

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent): any {
    const input = event.target as HTMLInputElement;
    const number = input.value
      .replace(/[^0-9()+\-]/g, '')
      .replace(/(\..*)\./g, '$1')
    this.formatPhone(number);
  }

  formatPhone(number: any): void {
    if (number?.length >= 10) {
      const phoneNumber = parsePhoneNumberFromString(number);
      const countryCode = phoneNumber?.country || this.countryCode;
      this.elementRef.nativeElement.value = formatIncompletePhoneNumber(number, countryCode);
    } else {
      this.elementRef.nativeElement.value = number;
    }
  }
}

@NgModule({
  declarations: [PhoneMaskDirective],
  exports: [PhoneMaskDirective],
  imports: [],
  providers: [],
})
export class PhoneMaskDirectiveModule {
  constructor() { }
}
