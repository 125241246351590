import { createReducer, on } from '@ngrx/store';
import { OnboardingQuestionsSignature } from '@/app/core/Models/Onboarding';
import { LoadOnboardingQuestionsFailAction, LoadOnboardingQuestionsSuccessAction, SubmitOnboardingQuestionsAction, SubmitOnboardingQuestionsFailAction, SubmitOnboardingQuestionsSuccessAction, VoiceSynthOnboardingStatusUpdateAction, VoiceSynthOnboardingTranscriptionAction } from './onboarding.action';
import { TranscriptionDto } from '@/app/Data/DTO/TranscriptionDto';
import { SignOutAction } from '../app/action';

export interface OnboardingState {
  voiceSynthOnboaeding: {
    callStatus?: 'established' | 'completed',
    transcriptions: TranscriptionDto[],
    recording_url?: string,
    summary?: string,
    errorMessage?: string
  },
  questionnaire: {
    loading: boolean;
    questions?: OnboardingQuestionsSignature[];
    saving: boolean
  }
}

export const initOnboardingState: OnboardingState = {
  voiceSynthOnboaeding: {
    transcriptions: [],
    // callStatus: 'completed',
    // recording_url: "https://api-dev1.yib.io/api/v1/media/CAaf75ed37578828a34262557f001addab",
    // summary: "The client asked about a software update, and the agent highlighted improved navigation and dark mode. The client expressed interest in future features, and the agent mentioned ongoing work on real-time chat support.",
    // transcriptions: [
    //   {
    //     speaker: 'You',
    //     start: 2,
    //     text: "Hi, how are you doing?",
    //     direction: "inbound"
    //   },
    //   {
    //     speaker: 'Agent',
    //     start: 4,
    //     text: "Hi, doing great, thanks for asking",
    //     direction: "outbound"
    //   },
    //   {
    //     speaker: 'You',
    //     start: 6,
    //     text: "I'm glad to hear that! I wanted to inquire about the recent software update.",
    //     direction: "inbound"
    //   },
    //   {
    //     speaker: 'Agent',
    //     start: 8,
    //     text: "Certainly! We've rolled out a few enhancements to improve user experience. One notable change is the streamlined navigation.",
    //     direction: "outbound"
    //   },
    //   {
    //     speaker: 'You',
    //     start: 10,
    //     text: "That sounds promising. Could you provide an example of how the navigation has been improved?",
    //     direction: "inbound"
    //   },
    //   {
    //     speaker: 'Agent',
    //     start: 12,
    //     text: "Absolutely! Previously, accessing your account settings required multiple clicks. Now, it's a direct one-click access from the dashboard.",
    //     direction: "outbound"
    //   },
    //   {
    //     speaker: 'You',
    //     start: 14,
    //     text: "That's convenient. I appreciate the user-centric approach. Any other noteworthy changes I should be aware of?",
    //     direction: "inbound"
    //   },
    //   {
    //     speaker: 'Agent',
    //     start: 16,
    //     text: "Certainly, we've also introduced a dark mode option for users who prefer a different visual style. It's accessible in the settings menu.",
    //     direction: "outbound"
    //   },
    //   {
    //     speaker: 'You',
    //     start: 18,
    //     text: "Great addition! Dark mode is always a popular choice. Thanks for the update. Anything else on the horizon?",
    //     direction: "inbound"
    //   },
    //   {
    //     speaker: 'Agent',
    //     start: 20,
    //     text: "We're actively working on integrating a real-time chat support feature for immediate assistance. It's in the testing phase and should be live soon.",
    //     direction: "outbound"
    //   },
    //   {
    //     speaker: 'You',
    //     start: 22,
    //     text: "That's fantastic! Real-time support can make a significant difference. Looking forward to it. Thanks for the information!",
    //     direction: "inbound"
    //   }
    // ],
  },
  questionnaire: {
    loading: false,
    saving: false,
  }
};

export const OnboardingReducer = createReducer(
  initOnboardingState,

  on(
    LoadOnboardingQuestionsFailAction,
    (state: OnboardingState, { }) => {
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          loading: true
        }
      };
    }
  ),
  on(
    LoadOnboardingQuestionsSuccessAction,
    (state: OnboardingState, { payload }) => {
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          loading: false,
          questions: payload
        }
      };
    }
  ),
  /* ------------------------------ Verify phone ------------------------------ */
  on(
    VoiceSynthOnboardingTranscriptionAction,
    (state: OnboardingState, { payload: { data } }) => {
      return {
        ...state,
        voiceSynthOnboaeding: {
          ...state.voiceSynthOnboaeding,
          transcriptions: [
            ...state.voiceSynthOnboaeding.transcriptions,
            {
              start: data.start,
              text: data.transcription,
              direction: data.direction,
              speaker: data.direction === 'inbound' ? 'You' : 'Agent'
            }
          ]
        }
      };
    }
  ),

  on(
    VoiceSynthOnboardingStatusUpdateAction,
    (state: OnboardingState, { payload }) => {
      return {
        ...state,
        voiceSynthOnboaeding: {
          ...state.voiceSynthOnboaeding,
          callStatus: payload.status,
          recording_url: payload.status === 'completed' ? state.voiceSynthOnboaeding.recording_url || payload.recording_url : state.voiceSynthOnboaeding.recording_url,
          summary: payload.status === 'completed' ? state.voiceSynthOnboaeding.summary || payload.summary : state.voiceSynthOnboaeding.summary,
        }
      };
    }
  ),

  on(
    LoadOnboardingQuestionsFailAction,
    (state: OnboardingState, { }) => {
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          loading: false
        }
      };
    }
  ),

  on(
    SubmitOnboardingQuestionsAction,
    (state: OnboardingState, { }) => {
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          saving: true
        }
      };
    }
  ),
  on(
    SubmitOnboardingQuestionsSuccessAction,
    SubmitOnboardingQuestionsFailAction,
    (state: OnboardingState, { }) => {
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          saving: false,
        }
      };
    }
  ),
  on(SignOutAction, (state: OnboardingState, { }) => {
      return {
        ...initOnboardingState
      };
    }
  ),


);

