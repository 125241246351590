/**
 * A component that will serve as task details and able to edit.
 * The component functionality is stateful.
 * Author: Royce
 * Date Created: Wednesday, Sep 13, 6:30 PM
 *
 */
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';

import { IContactsRepository } from '@/app/core/IRepositories/IContactsRepository';
import { ICompanyRepository } from '@/app/core/IRepositories/ICompanyRepository';
import { IContactRepository } from '@/app/core/IRepositories/IContactRepository';
import { MessagesViewModel } from '../../ViewModels/messagesViewModel';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';
import { CompaniesViewModel } from '../../ViewModels/companiesViewModel';
import { TagsViewModel } from '../../ViewModels/tagsViewModel';
import { TaskViewModel } from '@/app/Ui/ViewModels/taskViewModel';
import { TasksViewModel } from '../../ViewModels/tasksViewModel';
import { UsersViewModel } from '@/app/Ui/ViewModels/usersViewModel';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';

import { AttachmentsViewerComponent } from '../../components/attachments-viewer/attachments-viewer.component';
import { TaskDescriptionComponent } from '../task-description/task-description.component';
import { ConfirmationDialogComponent } from '../../components/confirm-dialog/confirmation-dialog.component';

import { Interaction, InteractionThread, RecentInteraction } from '@/app/core/Models/Interaction';
import { Task, TaskComment, TaskDetail, TaskLog, TaskStatus } from '@/app/core/Models/Task';
import { Tenant } from '@/app/core/Models/tenant';
import { TaskCommentDto } from '@/app/Data/DTO/TaskCommentDto';
import { User } from '@/app/core/Models/User';
import { Company } from '@/app/core/Models/company';
import { Contact } from '@/app/core/Models/contact';
import { Attachment } from '@/app/core/Models/file';
import { Tag } from '@/app/core/Models/tag';

import {
  BehaviorSubject, debounceTime, distinctUntilChanged, filter,
  firstValueFrom,
  map,
  Observable,
  startWith,
  switchMap,
  tap,
  combineLatest
} from 'rxjs';
import { SubSink } from 'subsink';
import dayjs from 'dayjs';
import momento from '@/app/Utilities/momento';

import { copyClipboard, fileTypeFromName } from '@/app/Utilities/helpers';
import { Store } from '@ngrx/store';
import { selectActiveThreadId } from '@/app/State/thread/thread.selector';
import { AppState } from '@/app/State/AppState';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component2.html',
  styleUrls: ['./task-details.component.scss'],
})
export class TaskDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('calendarTrigger') calendarTrigger: MatMenuTrigger;
  @ViewChild('dealsListings') dealsListings: MatMenuTrigger;
  @ViewChild('activities_container') activities_container: ElementRef<HTMLElement>;
  @ViewChild('attachmentViewer') attachmentViewer: AttachmentsViewerComponent;
  @ViewChild('taskDescription') taskDescription: TaskDescriptionComponent
  sub = new SubSink();
  TaskStatus = TaskStatus;
  taskId?: number;
  showEditOption = false;
  commentAdded = false;
  taskStatus: TaskDetail['status'] | undefined
  task$ = this.taskViewModel.task$.pipe(
    filter(item => !!item),
    tap((task) => {
      this.initTaskForm(task as Task | any);
      this.taskId = task?.task_id;
      if (this.taskStatus && this.taskStatus !== task?.status) {
        this.updatingStatus$.next(false)
      }
      this.taskStatus = task?.status
      this.showEditOption = true;
    }));
  taskAttachments$ = this.task$.pipe(
    map(task => task?.attachments ?? []),
    map(attachments => attachments.map((attachment): Attachment => ({
      type: fileTypeFromName(attachment.filename),
      url: attachment.attachment_url,
      name: attachment.filename,
    })))
  )
  todayDate: Date = new Date();
  updatingStatus$ = new BehaviorSubject(false)

  // Add property to check if we're on the conversation thread
  isOnConversationThread$ = combineLatest([
    this.task$,
    this.store.select(selectActiveThreadId)
  ]).pipe(
    map(([task, activeThreadId]) => {
      if (!task?.interaction) return false;
      const threadId = task.interaction.yobi_contact_id ?? task.interaction.channel_id;
      return threadId === activeThreadId;
    })
  );

  mentions$ = this.usersViewModel.mentions$
  profile$: Observable<Tenant | undefined> = this.taskViewModel.profile$;
  private _commentsCount$ = new BehaviorSubject(3);
  private originalDescription: string = '';
  activity$: Observable<{ comment?: TaskComment; log?: TaskLog }[]> =
    this._commentsCount$.pipe(
      switchMap((count: number) => {
        return this.taskViewModel.task$.pipe(
          map((item) => item?.comments!),
          map((items) => {
            return (items ?? [])
              .map((item) => {
                if ((item as TaskComment)['comment']) {
                  return { comment: item as TaskComment };
                } else {
                  return { log: item as TaskLog };
                }
              })
              .sort((a, b) => {
                let aa = a?.comment ?? a?.log;
                let bb = b?.comment ?? b?.log;
                let acreatedDate = new Date(aa.created_dt)
                let bcreatedDate = new Date(bb.created_dt)
                if (acreatedDate.getTime() < bcreatedDate.getTime()) {
                  return -1;
                }
                if (acreatedDate.getTime() > bcreatedDate.getTime()) {
                  return 1;
                }
                return 0;
              });
          }),
          tap(() => {
            if (this.commentAdded) {
              setTimeout(() => {
                this.activities_container.nativeElement.scrollTo({
                  top: this.activities_container.nativeElement.scrollHeight,
                  behavior: 'smooth',
                });
                this.activities_container.nativeElement.scrollTop = this.activities_container.nativeElement?.scrollHeight
              }, 250)
            }
          }),
        );
      })
    );

  selectedTab = 'comments';
  editableCommentId$ = this.taskViewModel.editableCommentId$;
  localization = this.localiztionViewModel.localization;

  taskForm: FormGroup;
  taskViewControls = {
    title: false,
    status: false,
    description: false
  }

  currentDate = new Date();

  usersList$ = this.usersViewModel.usersList$;

  companySearchControl = new FormControl();
  filtredCompanies$ = this.companySearchControl.valueChanges.pipe(
    distinctUntilChanged(),
    debounceTime(500),
    startWith(''),
    filter((search) => typeof search === 'string'),
    switchMap((val) => {
      return this.companiesViewModel.getRawCompaniesLists({ page: 1, page_size: 20, search: val });
    }));

  contactSearchControl = new FormControl();
  filtredContacts$ = this.contactSearchControl.valueChanges.pipe(
    distinctUntilChanged(),
    debounceTime(500),
    startWith(''),
    filter((search) => typeof search === 'string'),
    switchMap((val) => {
      return this.contactsRepository.getContacts({ page: 1, page_size: 20, search: val });
    }));

  tenantTags$ = this.tagsViewModel.tenantTags$;

  currentDateReminder = new Date();
  minimumTime: string
  reminderDate!: Date
  reminderTime!: string

  tab: 'Comments' | 'Activity' = 'Comments';
  task?: Task;

  constructor(
    private localiztionViewModel: LocalizationViewModel,
    private dialog: MatDialog,
    private taskViewModel: TaskViewModel,
    private tasksViewModel: TasksViewModel,
    private usersViewModel: UsersViewModel,
    private companiesViewModel: CompaniesViewModel,
    private tagsViewModel: TagsViewModel,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private messagesViewModel: MessagesViewModel,
    private contactsRepository: IContactsRepository,
    private fb: FormBuilder,
    private companyRepository: ICompanyRepository,
    private contactRepository: IContactRepository,
    private snackbarService: SnackbarService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.autoRun();
  }

  ngOnDestroy(): void {
    const url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: {} }).toString()
    this.location.go(url);
  }

  autoRun(): void {
    this.init();
    this.listen();
  }

  async init() {
    this.initDate();
    this.task = await firstValueFrom(this.task$) as Task | any;
    this.initTaskForm(this.task as Task | undefined);
  }

  listen(): void {
    this.listenOnTaskLinkCopied();
  }

  listenOnTaskLinkCopied(): void {
    this.sub.sink = this.taskViewModel.taskLinkCopied.pipe(
      filter(result => result === true),
      tap((result) => {
        copyClipboard(window.location.href);
        this.snackbarService.openAlert({
          message: 'Task link copied to clipboard.',
          type: 'success'
        });
      })
    ).subscribe();
  }

  initTaskForm(task?: Task) {
    this.taskForm = this.fb.group({
      title: new FormControl(task?.title ?? '', [Validators.required, Validators.maxLength(150)]),
      status: new FormControl(typeof task?.status === 'number' ? this.taskViewModel.mapTaskStatus(task?.status as any) : task?.status),
      description: new FormControl(''),
      priority: new FormControl(task?.task_priority ?? ''),
      tags: new FormControl(task?.tags ?? []),
      tagsModel: new FormControl([]),
      contact_id: new FormControl(task?.contact_id ?? null),
      yobi_crm_company_id: new FormControl(task?.yobi_crm_company_id ?? null),
      associatedCompanyModel: new FormControl(),
      associatedContactModel: new FormControl(),
      creator: new FormControl(task?.creator ?? null),
      deadline: new FormControl(task?.deadline !== null ? dayjs.unix(task?.deadline as number).format('MM/DD/YYYY') : null),
      task_assignees: new FormControl(task?.task_assignees ?? []),
      reminder: new FormControl(task?.reminder === null ? 'None' : dayjs.unix(task?.reminder as number).format('MM/DD/YYYY')),
      attachments: new FormControl([]),
      reminder_value: new FormControl(),
      interaction_id: new FormControl(task?.interaction_id ?? null)
    });
    this.patchAddons(task);
  }

  patchAddons(task?: Task) {
    if (task?.assigned_company !== null) this.getAssociatedCompany(task?.assigned_company as any);
    if (task?.assigned_contact !== null) this.getAssociatedContact(task?.assigned_contact as any);
    if (task?.deadline) this.currentDate = this.deadline?.value;
    if (task?.tags?.length) this.tagsModel?.patchValue(task.tags?.map(tag => tag.tag_id));
    if (task?.description) this.getTaskDescription(task?.description);
    if (task?.attachments?.length) this.getTaskAttachments(task?.attachments);
    if (task?.reminder !== null && task?.reminder_value === null) this.reminder_value?.patchValue('custom');
    if (task?.reminder !== null && task?.reminder_value !== null) this.reminder_value?.patchValue(task?.reminder_value);
    if (task?.assigned_company === null && 'yobi_crm_company_id' in task && task?.yobi_crm_company_id) this._getAssociatedCompany(task?.yobi_crm_company_id as number);
    if (task?.assigned_contact === null && 'contact_id' in task && task?.contact_id) this._getAssociatedContact(task?.contact_id as any);
    if (task?.deadline !== null) {
      this.currentDate = new Date(this.deadline?.value as any)
    }
  }

  getTaskAttachments(attachments: any[]) {
    const _t = attachments.map((attachment) => {
      return {
        type: fileTypeFromName(attachment.filename),
        url: attachment.attachment_url,
        name: attachment.filename,
        filename: attachment.filename
      }
    });
    this.attachments?.patchValue(_t);
  }

  async getTaskDescription(description: string) {
    try {
      const _desc = JSON.parse(description);
      let final = _desc.map((item: { type: string, text: string, id: string, user: string }) => {
        switch (item.type) {
          case 'text':
            return item.text
          case 'mention':
            return `<@mention_${item.id}>`
          default:
            return ''
        }
      }).join(' ');
      this.description?.patchValue(final);
      // Store the initial description value
      this.originalDescription = final;
    } catch (error) {
      // If parsing fails, it's a plain text description
      this.description?.patchValue(description);
      this.originalDescription = description;
    }
  }

  initDate() {
    const offset = 30 - (momento(this.currentDate).date.getMinutes() % 30);
    this.minimumTime = momento(this.currentDate).add(offset, "minute").format('HH:mm')
    this.reminderTime = this.minimumTime
    this.reminderDate = this.currentDate
  }

  async _getAssociatedCompany(yobi_crm_company_id: number) {
    const selectedCompany = await firstValueFrom(this.companyRepository.getCompanyById(yobi_crm_company_id as any));;
    this.associatedCompanyModel?.patchValue({
      avatar: selectedCompany.avatar,
      company_name: selectedCompany.company_name
    });
    this.yobi_crm_company_id?.patchValue(yobi_crm_company_id);
  }

  async _getAssociatedContact(contact_id: number) {
    const selectedContact = await firstValueFrom(this.contactRepository.getContactById(contact_id as any));
    this.associatedContactModel?.patchValue({
      avatar: selectedContact.avatar,
      contact_name: `${selectedContact.given_name || ''} ${selectedContact.family_name || ''}`
    });
    if (this.associatedCompanyModel?.value) this.associatedCompanyModel.patchValue(null);
  }

  getAssociatedCompany(company?: { avatar: string, company_name: string, yobi_crm_company_id: string }) {
    this.associatedCompanyModel?.patchValue(company);
    this.yobi_crm_company_id?.patchValue(company?.yobi_crm_company_id);
  }

  getAssociatedContact(contact?: { avatar: string, contact_name: string, contact_id: string }) {
    this.associatedContactModel?.patchValue(contact);
    this.contact_id?.patchValue(contact?.contact_id);
  }

  updateStatusToDone(taskId: number): void {
    this.updatingStatus$.next(true)
    this.taskViewModel.updateTaskStatus({
      taskId,
      status: TaskStatus.done,
    });
    this.taskViewModel.loadTaskDetail(taskId);
  }

  updateStatusToOpen(taskId: number): void {
    this.updatingStatus$.next(true)
    this.taskViewModel.updateTaskStatus({
      taskId,
      status: TaskStatus.open,
    });
    this.taskViewModel.loadTaskDetail(taskId);
  }

  openThread(interaction?: InteractionThread) {
    const unClassified = interaction?.yobi_contact_id;
    unClassified ? this.openUnclassifiedConversationChannel(interaction) : this.openClassifiedChannel(interaction as InteractionThread);
  }

  async openUnclassifiedConversationChannel(interaction: InteractionThread) {
    const channelId = interaction.channel_id;
    const response = await firstValueFrom(this.messagesViewModel.getRecentMessagesByChannelId(channelId));
    if (!response) {
      this.snackbarService.openAlert({
        message: 'You are not authorized to access this con`versation.',
        type: 'failure'
      });
    } else {
      this.mutateRecentInteraction(response)
      this.messagesViewModel.openConversationThread({
        threadId: (interaction.yobi_contact_id ?? interaction.channel_id)!,
        unClassified: !interaction.yobi_contact_id,
        interaction: response as any
      });
    }
  }

  mutateRecentInteraction(response: any) {
    const interaction = Object.assign(new Interaction(), response.interaction);
    response.interaction = interaction;
    response.unclassified_channel_id = response.unclassified_channel_id ?? 0;
    response.contact_id = response.contact_id ?? 0;
    response.id = response.channel_id;
    response.page = 1;
    response.interaction_dt = response.interaction.created_dt;
    let _recent = Object.assign(new RecentInteraction(), response);
    this.messagesViewModel.saveRecentMessages([_recent]);
  }

  openClassifiedChannel(interaction: InteractionThread) {
    this.messagesViewModel.openConversationThread({
      threadId: (interaction.yobi_contact_id ?? interaction.channel_id)!,
      unClassified: !interaction.yobi_contact_id
    })
    // this.close()
  }

  updateStatusToArchived(taskId: number): void {
    this.updatingStatus$.next(true)
    this.taskViewModel.updateTaskStatus({
      taskId,
      status: TaskStatus.archived,
    });
    this.taskViewModel.loadTaskDetail(taskId);
  }

  getInteraction(interaction?: InteractionThread) {
    return Object.keys(interaction!).length ? true : false;
  }

  enableEditForComment(event: { commentId: number }): void {
    this.taskViewModel.enableEditForComment(event);
  }

  async deleteComment(data: { commentId: number }, taskId: number) {
    const currentTask = await firstValueFrom(this.tasksViewModel.getLocalTaskById(taskId));
    let patchedTaskDetails = Object.assign({}, { ...currentTask, comments_count: currentTask!.comments_count - 1 });
    this.tasksViewModel.saveTask(patchedTaskDetails as Task);
    this.taskViewModel.deleteComment(data.commentId, taskId);
  }

  resendComment(activity: { comment?: TaskComment }, taskId: number): void {
    this.taskViewModel.addComment({
      taskId,
      comment: activity.comment!.comment,
      commentId: activity.comment!.comment_id
    })
  }

  async commentEdit(
    data: { comment: string; commentId: number },
    taskId: number
  ): Promise<any> {
    const request: TaskCommentDto = { ...data, taskId };
    this.taskViewModel.editComment(request);
  }

  async addTaskComment(
    data: { comment: string },
    taskId: number
  ): Promise<any> {
    const request: TaskCommentDto = { ...data, taskId };
    this.taskViewModel.addComment(request);
    const currentTask = await firstValueFrom(this.tasksViewModel.getLocalTaskById(taskId));
    let patchedTaskDetails = Object.assign({}, { ...currentTask, comments_count: currentTask!.comments_count + 1 })
    this.tasksViewModel.saveTask(patchedTaskDetails as Task)
    this.commentAdded = true;
    setTimeout(() => {
      this.taskViewModel.loadTaskDetail(taskId);
    }, 500);
  }

  onGetTagOnTask(event: { data: Tag, action: 'add' | 'delete' }): void {
    let selectedTags: Tag[] = this.tags?.value;
    let tagIds = [];
    if (event.action === 'add') {
      let selected = selectedTags.filter((tag: Tag) => tag.tag_id === event.data.tag_id);
      !selected.length && selectedTags.push(event.data);
    } else {
      selectedTags = selectedTags.filter((tag: Tag) => tag.tag_id !== event.data.tag_id);
    }
    tagIds = selectedTags.map((tag: Tag) => tag.tag_id);
    this.tags?.patchValue(selectedTags);
    this.tagsModel?.patchValue(tagIds);
    this.taskViewModel.patchTask({ task: { tags: tagIds }, taskId: this.taskId });
  }

  onChangeTitle(event: Event): void {
    const title = this.taskForm.get('title')?.value;
    this.taskViewModel.patchTask({ task: { title }, taskId: this.taskId, previousTaskDetails: this.task as any });
  }

  onChangeStatus(event: 'open' | 'in-progress' | 'done' | 'trash'): void {
    this.status?.patchValue(event);
    const value = { 'open': 0, 'in-progress': 4, 'done': 1, 'trash': 2 };
    this.taskViewModel.patchTask({ task: { status: value[event] }, taskId: this.taskId });
  }

  onChangePriority(event: number): void {
    const stash: string = event.toString();
    const values: any = { '4': 'High', '3': 'Medium', '2': 'Low', '1': 'None' };
    this.priority?.patchValue(values[stash]);
    this.taskViewModel.patchTask({ task: { task_priority: values[stash] }, taskId: this.taskId });
  }

  removeCompany(): void {
    const selectedCompany = this.associatedCompanyModel?.value as Company;
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: `Remove Company Association`,
        message: `Are you sure you want to remove ${selectedCompany.company_name}?`,
        confirm: 'Remove',
        warning: true
      }
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.taskViewModel.patchTask({ task: { yobi_crm_company_id: null as any }, taskId: this.taskId });
        this.associatedCompanyModel?.patchValue(null);
        this.yobi_crm_company_id?.patchValue(null);
      }
    });
  }

  removeContact(): void {
    const selectedContact = this.associatedContactModel?.value as Contact;
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: `Remove Contact Association`,
        message: `Are you sure you want to remove ${selectedContact.given_name} ${selectedContact.family_name}?`,
        confirm: 'Remove',
        warning: true
      }
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.taskViewModel.patchTask({ task: { contact_id: null as any }, taskId: this.taskId });
        this.associatedContactModel?.patchValue(null);
        this.contact_id?.patchValue(null);
      }
    });
  }

  async selectCompany(yobi_crm_company_id: string) {
    if (yobi_crm_company_id !== this.yobi_crm_company_id?.value) {
      this.yobi_crm_company_id?.patchValue(yobi_crm_company_id);
      let payload = { yobi_crm_company_id, contact_id: null as any };
      this.contact_id?.patchValue(null);
      this.taskViewModel.patchTask({ task: payload, taskId: this.taskId });
      const selectedCompany = await firstValueFrom(this.companyRepository.getCompanyById(yobi_crm_company_id as any));;
      this.associatedCompanyModel?.patchValue(selectedCompany);
      if (this.associatedContactModel?.value) this.associatedContactModel.patchValue(null);
    }
  }

  async selectContact(contact_id: string) {
    if (contact_id !== this.contact_id?.value) {
      this.contact_id?.patchValue(contact_id);
      let payload = { yobi_crm_company_id: null as any, contact_id: this.contact_id?.value };
      this.taskViewModel.patchTask({ task: payload, taskId: this.taskId });
      const selectedContact = await firstValueFrom(this.contactRepository.getContactById(contact_id as any));
      this.associatedContactModel?.patchValue({
        avatar: selectedContact.avatar,
        contact_name: `${selectedContact.given_name || ''} ${selectedContact.family_name || ''}`
      });
      if (this.associatedCompanyModel?.value) this.associatedCompanyModel.patchValue(null);
    }
  }

  selectAssignees(user: User): void {
    let selectedAssignees = [...this.task_assignees?.value];
    const existing = selectedAssignees.filter((selected: User) => selected.user_id === user.user_id);
    if (!existing.length) {
      selectedAssignees.unshift({ avatar: user.avatar, family_name: user.lastName, given_name: user.firstName, user_id: user.user_id });
    } else {
      selectedAssignees = selectedAssignees.filter((item: User) => item.user_id !== user.user_id);
    }

    this.task_assignees?.patchValue(selectedAssignees);
    const _payload = selectedAssignees.map(item => item.user_id);
    const _final = _payload.filter(item => item && item !== null);
    this.taskViewModel.patchTask({ task: { assignees: _final }, taskId: this.taskId });
  }

  isSelected(user_id: number): boolean {
    const selectedAssignees = this.task_assignees?.value;
    const existing = selectedAssignees.filter((selected: User) => selected.user_id === user_id);
    return existing.length ? true : false;
  }

  closeDatePicker() {
    this.calendarTrigger.closeMenu();
  }

  onSelectDate(date: Date) {
    this.currentDate = date;
    const timestamp = dayjs(date).unix();
    this.deadline?.patchValue(dayjs.unix(timestamp).format('MM/DD/YYYY'));
    this.taskViewModel.patchTask({ task: { deadline: timestamp }, taskId: this.taskId });
  }

  patchReminder(value: string) {
    const now = new Date();
    this.reminder_value?.patchValue(value);
    switch (value) {
      case 'none':
        this.reminder?.patchValue(null);
        break;
      case 'in_15_minutes':
        this.reminder?.patchValue(dayjs(momento(now).add(15, 'minute').date).format('MM/DD/YYYY hh:mm:ss:A'));
        this.taskViewModel.patchTask({ task: { reminder: dayjs(this.reminder?.value).unix(), reminder_value: this.reminder_value?.value }, taskId: this.taskId });
        break;
      case 'in_30_minutes':
        this.reminder?.patchValue(dayjs(momento(now).add(30, 'minute').date).format('MM/DD/YYYY hh:mm:ss:A'));
        this.taskViewModel.patchTask({ task: { reminder: dayjs(this.reminder?.value).unix(), reminder_value: this.reminder_value?.value }, taskId: this.taskId });
        break;
      case 'in_one_day':
        this.reminder?.patchValue(dayjs(momento(now).add(1, 'day').date).format('MM/DD/YYYY'));
        this.taskViewModel.patchTask({ task: { reminder: dayjs(this.reminder?.value).unix(), reminder_value: this.reminder_value?.value }, taskId: this.taskId });
        break;
      case 'in_two_days':
        this.reminder?.patchValue(dayjs(momento(now).add(2, 'day').date).format('MM/DD/YYYY'));
        this.taskViewModel.patchTask({ task: { reminder: dayjs(this.reminder?.value).unix(), reminder_value: this.reminder_value?.value }, taskId: this.taskId });
        break;
      case 'in_one_week':
        this.reminder?.patchValue(dayjs(momento(now).add(1, 'week').date).format('MM/DD/YYYY'));
        this.taskViewModel.patchTask({ task: { reminder: dayjs(this.reminder?.value).unix(), reminder_value: this.reminder_value?.value }, taskId: this.taskId });
        break;
      case 'custom':
        this.reminder?.patchValue('custom');
        break;
    }
  }

  onChangeReminderTime() {
    const selected = momento(this.reminderDate)
      .set(momento(this.reminderTime, 'HH:mm').date.getHours(), 'hour')
      .set(momento(this.reminderTime, 'HH:mm').date.getMinutes(), 'minute')
    const timestamp = dayjs(selected.date).unix();
    this.taskViewModel.patchTask({ task: { reminder: timestamp, reminder_value: 'custom' }, taskId: this.taskId });
  }

  onChangeReminderDate(event: string) {
    this.reminderDate = new Date(event);
    const selected = momento(this.reminderDate)
      .set(momento(this.reminderTime, 'HH:mm').date.getHours(), 'hour')
      .set(momento(this.reminderTime, 'HH:mm').date.getMinutes(), 'minute')
    const timestamp = dayjs(selected.date).unix();
    this.taskViewModel.patchTask({ task: { reminder: timestamp, reminder_value: 'custom' }, taskId: this.taskId });
  }

  processFiles(files: File[]) {
    return files.map((item) => {
      return {
        type: item.type,
        filename: item.name,
        file: item,
        file_url: ''
      }
    });
  }

  async clickFileBrowseHandler(files: any) {
    let stash: Array<{ type: string, filename: string, file: File, file_url: string }> = [];
    Object.keys(files).forEach((item: string) => {
      const file = files[item] as File;
      stash.push({
        type: file.type,
        filename: file.name,
        file: file,
        file_url: ''
      });
    });
    let attachments = this.attachments?.value;
    const uploaded = await this.uploadTaskAttachments(stash);
    if (uploaded.state) {
      if (attachments.length) {
        uploaded.result.forEach(item => attachments.push(item));
      } else {
        attachments = uploaded.result;
      }
      this.attachmentViewer.detactAttachments();
      this.attachments?.patchValue(attachments);
      this.attachmentViewer.attachments = attachments
      this.attachmentViewer.detect();
      this.taskViewModel.patchTask({ task: { attachments: attachments }, taskId: this.taskId });
    }
  }

  async handleFileDrop(files: File[]) {
    let stash: Array<{ type: string, filename: string, file: File, file_url: string }> = [];
    files.forEach((item) => {
      stash.push({
        type: item.type,
        filename: item.name,
        file: item,
        file_url: ''
      });
    });
    let attachments = this.attachments?.value;
    const uploaded = await this.uploadTaskAttachments(stash);
    if (uploaded.state) {
      if (attachments.length) {
        uploaded.result.forEach(item => attachments.push(item));
      } else {
        attachments = uploaded.result;
      }
      this.attachmentViewer.detactAttachments();
      this.attachments?.patchValue(attachments);
      this.attachmentViewer.attachments = attachments;
      this.attachmentViewer.detect();
      this.taskViewModel.patchTask({ task: { attachments: attachments }, taskId: this.taskId });
    }
  }

  uploadTaskAttachments(attachments: Array<{ type: string, file_url: string, filename: string, file?: any }>): Promise<{ state: boolean, result: Array<{ filename: string, type: string, url: string }> }> {
    let _attachments: FormData | undefined = this.taskViewModel.getBodyAttachment(attachments);
    return new Promise(async (resolve) => {
      let uploadedAttachments = await firstValueFrom(this.taskViewModel.uploadAttachments(_attachments as FormData));
      resolve({ state: true, result: uploadedAttachments as any });
    });
  }

  handleRemoveFile(event: number): void {
    const files = this.attachments?.value;
    const s = files.splice(event, 1);
    this.attachments?.patchValue(files);
    this.attachmentViewer.attachments = this.attachments?.value;
    this.attachmentViewer.detect();
    this.taskViewModel.patchTask({ task: { attachments: this.attachments?.value }, taskId: this.taskId });
  }

  resetUploadedDocuments(event: MouseEvent) {
    (event.target as HTMLInputElement).value = null as any;
  }

  async onChangeDesc(event: Event): Promise<void> {
    // Store the description when starting to edit
    if (!this.originalDescription) {
      this.originalDescription = this.description?.value || '';
    }
  }

  cancelDescriptionEdit(): void {
    // Restore the original description
    this.description?.setValue(this.originalDescription);
    this.taskViewControls.description = false;
    this.originalDescription = '';
  }

  saveDescription(): void {
    const description = this.description?.value;
    this.taskViewModel.patchTask({
      task: { description },
      taskId: this.taskId,
      previousTaskDetails: this.task as any
    });
    // Update the original description to the new saved value
    this.originalDescription = description;
    this.taskViewControls.description = false;
  }

  get title() {
    return this.taskForm.get('title');
  }

  get status() {
    return this.taskForm.get('status');
  }

  get priority() {
    return this.taskForm.get('priority');
  }

  get description() {
    return this.taskForm.get('description');
  }

  get tags() {
    return this.taskForm.get('tags');
  }

  get tagsModel() {
    return this.taskForm.get('tagsModel');
  }

  get contact_id() {
    return this.taskForm.get('contact_id');
  }

  get yobi_crm_company_id() {
    return this.taskForm.get('yobi_crm_company_id');
  }

  get associatedCompanyModel() {
    return this.taskForm.get('associatedCompanyModel');
  }

  get associatedContactModel() {
    return this.taskForm.get('associatedContactModel');
  }

  get creator() {
    return this.taskForm.get('creator');
  }

  get deadline() {
    return this.taskForm.get('deadline')
  }

  get task_assignees() {
    return this.taskForm.get('task_assignees');
  }

  get reminder() {
    return this.taskForm.get('reminder');
  }

  get attachments() {
    return this.taskForm.get('attachments');
  }

  get reminder_value() {
    return this.taskForm.get('reminder_value');
  }

  get interaction_id() {
    return this.taskForm.get('interaction_id');
  }
}