import { IUserRepository } from '@/app/core/IRepositories/IUserRepository';
import {
  CreateUserDto,
  InviteUserDto,
  InviteUserResponseDto,
} from './../../../Data/DTO/CreateUserDto';
import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom, Observable, tap } from 'rxjs';

import { UseCase } from '../UseCase';
import { IUsersRepository } from '@/app/core/IRepositories/IUsersRepository';
import { Tenant } from '../../Models/tenant';
import { TokenStorgeService } from '@/app/Data/services/Token/token.service';
import { IAuthenticationRepository } from '../../IRepositories/IAuthenticationRepository';
import { ITenantRepository } from '../../IRepositories/ITenantRepository';

@Injectable({
  providedIn: 'root',
})
export class CreateUserUseCase
  implements UseCase<CreateUserDto, Tenant>
{
  constructor(
    private usersRepository: IUsersRepository,
    private authRepository: IAuthenticationRepository,
    private tenantRepository: ITenantRepository,
    private tokenStorgeService: TokenStorgeService,

  ) {}

  async execute(request: CreateUserDto): Promise<Tenant> {
    const credential = await lastValueFrom(
      this.usersRepository.createUser(request)
    );
    // await this.databaseService.init();
    this.tokenStorgeService.saveToken(credential.results.token)
    this.tokenStorgeService.saveRefreshToken(credential.results.refreshToken)
    const user = await lastValueFrom(
      this.authRepository.getUserObject()
    );
    this.tokenStorgeService.saveUser(user)
    this.authRepository.saveTenantProfileObject(user);
    const channels = await lastValueFrom(
      this.tenantRepository.getInternalChannels()
    );
    this.tenantRepository.saveInternalChannels(channels);
    return user;
  }
}
