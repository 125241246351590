import { ContactImportDto, ImportContactsResultDto, ImportContactsUrlDto } from "@/app/Data/DTO/ImportContactsDTO";
import { Observable, Observer } from "rxjs";
import { ImportCsvDto, analyseCsvResponseDto } from "../Models/csvDto";
import { SuccessApiResponse } from "@/app/Data/services/Networking/ApiResponse";

export abstract class IImportContactsRepository {

    abstract analyseContactsCsvFile(
        data: FormData,
        progress$: Observer<any>
    ): Observable<analyseCsvResponseDto>

    abstract importContactsCsvFile(
        query: ImportCsvDto
    ): Observable<SuccessApiResponse<string>>
}