import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { UseCase } from '../UseCase';
import { IAuthenticationRepository } from '@/app/core/IRepositories/IAuthenticationRepository';
import { Tenant } from '@/app/core/Models/tenant';
import { EditProfileDto } from '@/app/Data/DTO/EditProfileDto';
import { ITenantRepository } from '../../IRepositories/ITenantRepository';

@Injectable({
  providedIn: 'root',
})
export class EditProfileUseCase
  implements UseCase<EditProfileDto, SuccessResponse>
{
  constructor(private tenantRepository: ITenantRepository) {}
  execute(request: EditProfileDto): Observable<SuccessResponse> {
    return this.tenantRepository.editProfile(request);
  }
}
