import { createReducer, on } from '@ngrx/store';
import { GetNotificationsAction, GetNotificationsFailAction, GetNotificationsSuccessAction, UpdateNotificationAsReadAction, UpdateUnreadNotificationsCountAction } from '@/app/State/Notifications/notifications.action';
import { SignOutAction } from '@/app/State/app/action';
import { Notification } from '@/app/core/Models/Notification';

export interface NotificationsState {
  unreadCount: number;
  loading: boolean;
  isLastPage: boolean;
  currentPage: number;
  notifications: Notification[]
}

export const initNotificationsState: NotificationsState = {
  unreadCount: 0,
  isLastPage: false,
  loading: false,
  currentPage: 0,
  notifications: []
};

export const notificationsReducer = createReducer(
  initNotificationsState,
  on(
    GetNotificationsAction,
    (state: NotificationsState) => {
      return {
        ...state,
        loading: true,
      };
    }
  ),
  on(
    GetNotificationsSuccessAction,
    (state: NotificationsState, { payload: { notifications, page } }) => {
      return {
        ...state,
        loading: false,
        currentPage: page,
        isLastPage: notifications.length === 0,
        notifications: page === 1 ? notifications : [...state.notifications, ...notifications],
      };
    }
  ),
  on(
    UpdateNotificationAsReadAction,
    (state: NotificationsState, { payload: { notification } }) => {
      return {
        ...state,
        notifications: [...state.notifications.map(n => n.notification_id === notification.notification_id ? { ...n, read: true } : n)],
      };
    }
  ),
  on(
    GetNotificationsFailAction,
    (state: NotificationsState) => {
      return {
        ...state,
        loading: false
      };
    }
  ),
  on(
    GetNotificationsAction,
    (state: NotificationsState) => {
      return {
        ...state,
        loading: true,
      };
    }
  ),
  on(
    UpdateUnreadNotificationsCountAction,
    (state: NotificationsState, { payload }) => {
      return {
        ...state,
        unreadCount: payload.count,
      };
    }
  ),
  on(SignOutAction, (state: NotificationsState) => {
    return {
      ...initNotificationsState,
    };
  })
);
