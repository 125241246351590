import { User } from "./User";

export interface RecentSynthConversationsQueryParams {
    page: number;
    page_size: number;
    synth_id?: number
    train?: string
}

export interface ITranscriptions {
    transcript: Array<any>,
    recording_url: string,
    synth_conversation_id: number
}

export interface ITranscriptionsFeedback {
    recording_url?: string,
    feedback_id: number
    synth_conversation_id: number
    transcript: any[]
    changes: any[]
}

export interface SynthConversationInteractionsQuery {
    conversationId: number;
    params: {
        page: number;
        page_size: number;
    }
}

export interface SynthTrainerParams {
    page: number;
    page_size: number;
    search?: string
}

export interface SynthAgent {
    synth_agent_id?: number;
    synth_id?: number;
    instructions: string;
    task_assignments: Array<ITaskAssigment>;
    always_kb_lookup: boolean;
    synth_agent_tools: Array<any>;
    synth_agent_knowledge_base: Array<any>;
    automated_text_messages: Array<{
        reason: string;
        type: 'snippet' | 'text';
        content: string;
    }>;
    synth_name: string;
    attachments: Array<{ filename: string, type: string, url: string, name?: string }>;
    company_name: string;
    greetings: string;
    personality: string;
    retell_agent_id: string;
    role: string;
    synth_voice_id: number;
    synth_agent_token?: string;
    tenant_id: string;
    other: any;
    industry?: string;
    timezone?: string;
    note?: string;
    interaction_type_instructions?: {
        discrete: string;
        live: string;
    };
    agent_attributes?: any;
    outbound_call_enabled?: boolean;
    outbound_call_config?: {
        agent_id?: string;
        calling_hours?: {
            special_closures: Record<string, any>;
            timezone: string;
            weekly_hours: {
                monday: { start_time: string; end_time: string; };
                tuesday: { start_time: string; end_time: string; };
                wednesday: { start_time: string; end_time: string; };
                thursday: { start_time: string; end_time: string; };
                friday: { start_time: string; end_time: string; };
                saturday: { start_time: string; end_time: string; };
                sunday: { start_time: string; end_time: string; };
            };
        };
        retry_config?: {
            enabled: boolean;
            max_retries: number;
            retry_time_limit_minutes: number;
        };
        synth_agent_id?: number;
    };
}

export interface ISelectedConversation {
    title?: string,
    name?: string,
    message?: string,
    avatar?: string,
    audioSrc?: string,
    transcriptions?: Array<{ speaker: string, text: string }>,
    feedbacks?: Array<{ icon: string, title: string, description: string, isCheck: boolean }>,
    openChoice?: boolean,
    isEmpty?: boolean,
    synth_conversation_id?: number,
    inlineTalking?: boolean,
    isCalling?: boolean,
    isChatting?: boolean,
    type?: 'text' | 'voice' | null,
    index?: number,
    feedback_id?: number | null,
    feedback?: Array<{ description: string, type: string }> | null,
    is_applied?: boolean | null,
    created_dt?: string,
    isLoading?: boolean
}


export class Synth {
    avatar: string;
    created_dt: string;
    do_not_disturb: boolean;
    firstName: string;
    lastName: string;
    onboarding: {
        addchannel: boolean;
        addcontacts: boolean;
        addusers: boolean;
        buyphone: boolean;
    };
    synth_id: number;
    tenant_id: number;
    updated_dt: string;
    user_id: number | string;
    username: string;
    synth_display_name: string;
    synth_name: string;
    role: string;
    company_name: string;
    greetings: string;
    personality: string;
    synth_voice_id: string;
    synth_agent: SynthAgent
    attachments?: Array<{ filename: string, type: string, url: string }>
    synth_created_dt: string;
    recent_conversations: SynthRecentConversation[];
    template_engine?: ITemplateEngine
    synth_created_dt_timestamps?: number
    allow_configure_train: boolean
    ai_workshop_edit_access: boolean
    version_history?: SynthVersionHistory[]
    task_assignments?: Array<ITaskAssigment>
    instructions?: string
    synth_agent_tools?: any[]
    knowledge_base_uuid?: Array<{
        upload?: string[],
        cancel?: string[]
    }>
    synth_agent_id?: number
    industry?: string
    timezone?: string
    is_linked_to_channel?: boolean
    personality_slider?: string
    extra_attributes?: any
    agent_attributes?: any
}

export class SynthVersionHistory {
    user_id: any
    tooltip?: boolean;
    created_dt: string
    user?: User
    transcript: string
    synth_id: number
    synthbrain_interaction_type: string
    synth_conversation_id: number
    prompt: string
    new_updated_dt: string
    message: string
    is_record_disabled: boolean
    is_applied: boolean
    id: number
    feedback: any[]
    agent_config_info: Synth
    config: {
        agent: Synth
        config: {
            discrete: string
            live: string
        }
    }
    version_name?: string
    created_dt_timestamps?: number
}


export class SelectedConversation {
    title?: string;
    name?: string;
    message?: string;
    avatar?: string;
    audioSrc?: string;
    transcriptions?: Array<{ speaker: string, text: string }>;
    feedbacks: Array<{ icon: string, title: string, description: string, isCheck: boolean }>;
    openChoice?: boolean;
    isEmpty?: boolean;
    synth_conversation_id?: number;
    inlineTalking?: boolean;
    isCalling?: boolean;
    isChatting?: boolean;
    type?: 'text' | 'voice' | null;
    index?: number;
    feedback_id?: number | null
    feedback?: Array<{ description: string, type: string }> | null
    is_applied?: boolean | null/*  */
}

export interface ITemplateEngine {
    emptyState: {
        message: string,
        talkAction: boolean,
        chatAction: boolean,
        isEmpty: boolean,
        isEmptyViewModel: boolean
    },
    firstCall: {
        isDone: boolean | null,
        isDoneViewModel: boolean | null,
        header: string,
        description: string,
        callAction: boolean,
        rightAction: string,
        isCalling: boolean,
        isSynthTalking: boolean
    },
    firstChat: boolean,
    conversations: Array<ISelectedConversation>,
    selectedConversation: ISelectedConversation | null,
    redoTraining: boolean,
    type: any,
    synth?: Synth
}

export class SynthTemplateEngine extends Synth {
    train: boolean;
    private get _synth(): Synth {
        return {
            avatar: this.avatar,
            created_dt: this.created_dt,
            do_not_disturb: this.do_not_disturb,
            firstName: this.firstName,
            lastName: this.lastName,
            onboarding: this.onboarding,
            synth_id: this.synth_id,
            tenant_id: this.tenant_id,
            updated_dt: this.updated_dt,
            user_id: this.user_id,
            username: this.username,
            synth_display_name: this.synth_display_name,
            synth_name: this.synth_name,
            role: this.role,
            company_name: this.company_name,
            greetings: this.greetings,
            personality: this.personality,
            synth_voice_id: this.synth_voice_id,
            synth_agent: this.synth_agent,
            attachments: this.attachments,
            synth_created_dt: this.synth_created_dt,
            recent_conversations: this.recent_conversations,
            allow_configure_train: this.allow_configure_train,
            ai_workshop_edit_access: this.ai_workshop_edit_access
        };
    }
    get templateEngine() {
        const isEmpty = this.recent_conversations.length ? false : true;
        return {
            emptyState: {
                message: `Start a new ${this.train ? 'training' : 'testing'} session`,
                talkAction: true,
                chatAction: !this.train, //We don't have a chat option for training at this time.
                isEmpty: isEmpty,
                isEmptyViewModel: isEmpty
            },
            firstCall: {
                isDone: isEmpty ? null : true,
                isDoneViewModel: isEmpty ? null : true,
                header: `You're ${this.train ? 'training' : 'testing'} ${this.synth_display_name}, the ${this.synth_agent?.role} for ${this.synth_agent?.company_name}`,
                description: `Ask ${this.synth_display_name} questions your customers will ask You can give feedback during the call by simply mentioning it to ${this.synth_display_name} will learn from your feedback after the call.`,
                isSynthTalking: false,
                callAction: true,
                rightAction: this.train ? 'End Training' : 'End Test Conversation',
                isCalling: false
            },
            type: this.train ? 'training' : 'testing',
            selectedConversation: this._selectedConversation,
            redoTraining: false,
            conversations: this._conversations,
            synth: this._synth,
            firstChat: false
        }
    }

    private get _selectedConversation(): ISelectedConversation | null {
        return null;
    }

    private get _conversations(): ISelectedConversation[] {
        // First deduplicate conversations by synth_conversation_id, keeping the most recent one
        const uniqueConversations = this.recent_conversations.reduce((acc, current) => {
            const existingConv = acc.find(conv => conv.synth_conversation_id === current.synth_conversation_id);
            if (!existingConv || (existingConv && new Date(current.created_dt!) > new Date(existingConv.created_dt!))) {
                // Remove any existing conversation with the same ID
                const filtered = acc.filter(conv => conv.synth_conversation_id !== current.synth_conversation_id);
                return [...filtered, current];
            }
            return acc;
        }, [] as SynthRecentConversation[]);

        // Then map the unique conversations to ISelectedConversation[]
        return uniqueConversations.map((item: SynthRecentConversation, i) => {
            let message;
            if (item.type === 'voice' && item.message !== null) {
                message = JSON.parse(item.message);
            } else {
                message = item.message;
            }
            let _f: Array<{ icon: string, title: string, description: string, isCheck: boolean }> = [];
            if (item.feedback !== null && item.feedback?.length && item.type === 'voice' && this.train) {
                const feedbackToUse = !item.is_applied ? item.feedback.slice(-1)[0] : item.feedback;
                _f = (Array.isArray(feedbackToUse) ? feedbackToUse : [feedbackToUse]).map((feedbackItem) => {
                    return { icon: 'assets/icons/new-set/icon-question.svg', title: feedbackItem.type, description: feedbackItem.description, isCheck: true };
                });
            }
            return {
                avatar: item.avatar as string,
                name: `${item.given_name} ${item.family_name}` as string,
                title: item.title as string,
                audioSrc: item.extra_content?.recording_url,
                message: item.type === 'voice' && message ? message[0]['text'] : message,
                transcriptions: message,
                isEmpty: !item.message,
                feedbacks: _f,
                synth_conversation_id: item.synth_conversation_id as any,
                inlineTalking: false,
                type: item?.type as any,
                openChoice: false,
                index: i,
                isCalling: false,
                isChatting: false,
                feedback_id: item.feedback_id,
                is_applied: item.is_applied,
                feedback: item.feedback,
                created_dt: item.created_dt
            }
        });
    }
}

export class SynthConversation {
    created_dt: string;
    initiator_id: number | string;
    is_active: boolean;
    is_record_disabled: boolean;
    synth_conversation_id: number | string;
    synth_id: number | string;
    tenant_id: number | string;
    title: string;
    updated_dt: string;
    synth_display_name: string;
    avatar?: string
    train?: boolean
    type?: 'text' | 'voice'
}

export class SynthRecentConversation {
    synth_conversation_id?: number | string;
    synth_id?: number | string;
    avatar?: string;
    family_name: string;
    given_name: string;
    message: any;
    title: string;
    username: string;
    synth_display_name?: string;
    type?: string;
    train?: boolean;
    extra_content?: any;
    feedback_id?: number | null;
    feedback?: Array<{ description: string, type: string }> | null;
    is_applied?: boolean | null;
    created_dt?: string;
}

export class SynthInteraction {
    attachment_urls: string[];
    created_dt: string;
    is_record_disabled: boolean;
    is_system?: boolean;
    is_synth: boolean;
    message: string;
    rating: number;
    synth_conversation_id: number | string;
    synth_interaction_id: number | string;
    updated_dt: string;
    user_id: string | number;
    pending?: boolean;
    latest_synth_message?: boolean;
    failed?: boolean;
}

export interface IPreset {
    name: string;
    description: string;
    icon: string;
    link: string;
    id: number;
    synth_name: string;
    roleId: string;
    role: string;
    company_name: string;
    personality: string;
    greetings: string;
    synth_voice_id: number;
    voice_url: string;
    file_name: string;
    type: string;
    isUsed: boolean;
    instructions: string;
    tasks: string[];
    templated_greeting: string;
    templated_instructions: string;
    templated_personality: string;
    agentType: 'inbound' | 'outbound';
}

export interface IdentityForm {
    synth_name: string,
    role: string;
    company_name: string;
    greetings: string;
    personality: string;
    synth_voice_id: string;
    attachments?: { filename: string, type: string, url: string };
    note: string;
    agent_attributes?: any;
}

export interface ITaskAssigment {
    reason: string,
    user_id?: string,
    userDetails?: { logo: string, name: string }
    users?: any
    userDetails2?: Array<{ logo: string, name: string, user_id: string|number }>
}

export interface ConfigurationForm {
    instructions: string;
    task_assignments: Array<ITaskAssigment>;
    always_kb_lookup: boolean;
    conversation_memory: string;
    conversation_memory_model: boolean;
    automated_text_messages: Array<{
        reason: string;
        type: 'snippet' | 'text';
        content: string;
    }>;
    interaction_type_instructions?: {
        discrete: string;
        live: string;
    };
}

export interface CreateSynthAgentDto {
    synth_name: string,
    role: string;
    company_name: string;
    greetings: string;
    personality: string;
    synth_voice_id: string;
    attachments?: Array<{
        filename: string,
        type: string,
        string: string
    }>;

    instructions: string;
    task_assignments: Array<{
        reason: string,
        user_id: string
        users?: any[]
    }>,
    synth_agent_tools?: Array<{
        synth_tool_id: number,
        other: any
        country?: string
    }>,
    synth_agent_knowledge_base?: {
        synth_tool_id: number
    },
    knowledge_base_uuid?: {
        upload: string[],
        cancel: string[]
    },
    delete_vectara_files?: string[]
    synth_id?: any
    agent_config_update_message?: string
    version_name?: string
    note?: string
    personality_slider?: string
}

export interface LinkSynthAgentDto {
    yobi_tenant_internal_channel_id: string[],
    synth_api: {
        synth_id: number,
        tenant_id: number,
        user_id: number,
        channel_value: any,
        endpoint_name: string
    }
}

export interface SynthChannel {
    channel_id: string;
    channel_name: string;
    channel_type: string;
    channel_value: string;
    linked_to_synth_id: number;
    linked_to_synth_name: string;
    status: string;
}

export interface SynthAgentScript {
    attribute_name: string,
    attribute_value: string,
    channel_id: string,
    channel_name: string,
    channel_type: string,
    channel_value: string,
    linked_to_synth_name: string
}


export interface IYobiOneMetadata {
    value: string,
    form_details: {
        [key: string]: any
        pages: {
            [key: string]: {
                [key: string]: {
                    description: string,
                    change_allowed: boolean
                }
            }
        }
    }
}

export interface SynthVersionHistoryResponse {
    feedback_data: Array<SynthVersionHistory>,
    total_count: number
}

export interface ICallTransfer {
    reason?: string,
    phone_number?: any,
    user_id?: any
    type?: 'phone' | 'user',
    country?: string
    is_valid?: boolean
}

export interface FollowUpText {
    condition: string;
    message: string;
}

export interface PersonalityConfig {
    category: string;
    description: string;
    value: number;
    parents?: string[];
    isDefault?: boolean;
    labels: {
      left: string;
      right: string;
    };
    levels: {
      1: {
        title: string;
        prompt: string;
      };
      2: {
        title: string;
        prompt: string;
      };
      3: {
        title: string;
        prompt: string;
    };
  }
}

export interface CallingHours {
    timezone: string;
    weekly_hours: {
        monday: { start_time: string; end_time: string; };
        tuesday: { start_time: string; end_time: string; };
        wednesday: { start_time: string; end_time: string; };
        thursday: { start_time: string; end_time: string; };
        friday: { start_time: string; end_time: string; };
        saturday: { start_time: string; end_time: string; };
        sunday: { start_time: string; end_time: string; };
    };
    special_closures?: Record<string, any>;
}

export interface RoleConfig {
    id: string;
    role: string;
    description: string;
    agentType: 'inbound' | 'outbound';
}