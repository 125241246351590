<div
  class="flex w-full flex-row h-full"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
>
  <app-auth-banner class="absolute flex-1 w-full h-full">
    <!-- <div class="flex flex-col gap-4 text-white">
      <div class="text-4xl font-semibold">
        <!-- {{ localization.auth.sign_up_title | translate }}
        Meet your AI Teammate
      </div>
      <div class="text-lg">
        <!-- {{ localization.auth.sign_up_slogan | translate }}
        Have a short 2 minute call with our Synthetic Agent!
      </div>
    </div> -->
  </app-auth-banner>

  <div class="wrapper">
    <div class="flex flex-col w-full max-w-xl sm:w-full gap-7 justify-center m-auto">
      <!-- <div class="flex flex-col gap-4 mb-6">
        <div class="text-3xl font-semibold text-center">
          {{ localization.labels.create_account | translate }}
        </div>
        <div class="text-md">
          {{ localization.auth.sign_up_slogan | translate }}
        </div>
      </div> -->
      <div class="text-3xl font-semibold text-center text-strong mb-6">
        Meet your New AI Teammate
      </div>

      <!-- <div class="flex flex-row justify-center gap-10">
        <div
          (click)="googleLogin()"
          class="shadow hover:shadow-md cursor-pointer p-3 rounded-md flex items-center gap-2 flex-grow justify-center max-w-xs"
        >
          <img
            class="w-5 h-5"
            src="assets/icons/social/google.svg"
          />
          <span class="text-base text-zinc-600 sm:hidden xs:hidden">Google</span>
        </div>

        <div
          (click)="appleLogin()"
          class="shadow hover:shadow-md cursor-pointer p-3 rounded-md flex items-center gap-2 flex-grow justify-center max-w-xs"
        >
          <img
            class="w-5 h-5"
            src="assets/icons/social/apple.svg"
          />
          <span class="text-base text-zinc-600 sm:hidden xs:hidden">Apple</span>
        </div>

        <div
          (click)="facebookLogin()"
          class="shadow hover:shadow-md cursor-pointer p-3 rounded-md flex items-center gap-2 flex-grow justify-center max-w-xs"
        >
          <img
            class="w-5 h-5"
            src="assets/icons/social/facebook.svg"
          />
          <span class="text-base text-zinc-600 sm:hidden xs:hidden">Facebook</span>
        </div>
      </div> -->

      <!-- <span class="text-center text-gray-500 p-4 pt-6 text-base">
        {{ localization.auth.sign_up_use_social | translate }}
      </span> -->

      <form
        id="signup_form_id"
        class="flex flex-col gap-7"
        [formGroup]="infomationForm"
        (ngSubmit)="onFormSubmit()"
      >
        <!-- ---------------------------- business name ---------------------------- -->
        <!-- <div>
          <app-text-input
            [label]="localization.labels.business_name | translate"
            (ngModelChange)="businessNameChanged($event)"
            formControlName="business_name"
            [placeholder]="localization.placeholder.type_your_business_name | translate"
            [invalid]="infomationForm.get('business_name')!.touched && infomationForm.get('business_name')!.invalid"
          ></app-text-input>
          <app-error-text
            class="mt-1"
            *ngIf="infomationForm.get('business_name')?.getError('exists')"
          >
            {{ localization.errors.business_name_already_used | translate }}
          </app-error-text>
          <app-error-text
            class="mt-1"
            *ngIf="infomationForm.get('business_name')?.getError('required') && !infomationForm.get('business_name')?.pristine"
          >
            {{ localization.errors.business_name_required | translate }}
          </app-error-text>
        </div> -->

        <!-- ------------------------------ full name ------------------------------ -->
        <!-- <div>
          <app-text-input
            [label]="localization.labels.full_name | translate"
            formControlName="full_name"
            [placeholder]="localization.placeholder.type_your_full_name | translate"
            [invalid]="infomationForm.get('full_name')!.touched && infomationForm.get('full_name')!.invalid"
          ></app-text-input>

          <app-error-text
            class="mt-1"
            *ngIf="infomationForm.get('full_name')?.getError('required') && !infomationForm.get('full_name')?.pristine"
          >
            {{ localization.errors.full_name_required | translate }}
          </app-error-text>

        </div> -->

        <!-- --------------------------- business email ---------------------------- -->
        <div *ngIf="infomationForm.get('channel_value') as channelValueControl">
          <app-text-input
            #email
            [label]="localization.terms.email | translate"
            (ngModelChange)="emailChanged($event)"
            formControlName="channel_value"
            [placeholder]="localization.placeholder.type_your_email | translate"
            [invalid]="channelValueControl.touched && channelValueControl.invalid"
          ></app-text-input>
          <app-error-text
            class="mt-1"
            *ngIf="channelValueControl.getError('exists')"
          >
            {{ localization.errors.email_already_used | translate}}
          </app-error-text>
          <app-error-text
            class="mt-1"
            *ngIf="channelValueControl.getError('required') && !channelValueControl.pristine"
          >
            {{ localization.errors.email_required | translate}}
          </app-error-text>
          <app-error-text
            class="mt-1"
            *ngIf="channelValueControl.getError('email') && channelValueControl.dirty"
          >
            {{ localization.errors.email_not_valid | translate }}
          </app-error-text>

        </div>

        <!-- ------------------------------ password ------------------------------- -->
        <div
          *ngIf="infomationForm.get('password') as passwordControl"
          class="flex flex-col gap-3 text-zinc-800"
        >
          <app-password-input
            [label]="localization.placeholder.create_your_password | translate"
            formControlName="password"
            [invalid]="passwordControl.touched && passwordControl.invalid"
          ></app-password-input>
          <app-error-text
            class="mt-1"
            *ngIf="passwordControl.invalid && passwordControl.touched"
          >
            Password must be at least 8 characters long
            <!-- {{ localization.auth.eight_character_restriction | translate }} -->
          </app-error-text>
          <!-- (blur)="passwordControl.value && passwordControl.invalid && (passwordRestrictions = true)" -->
          <!-- <div
            class="flex items-center gap-2 cursor-pointer hover:underline"
            (click)="passwordRestrictions = !passwordRestrictions"
          >
            <span
              style="max-width: 70vw;"
              class="truncate text-dark"
              [class.text-red-600]="passwordControl.dirty && passwordControl.invalid"
            >
              Must be at least 8 characters long, include at least one lowercase and one uppercase letter, one
              digit, and one special character.
            </span>
            <img
              src="assets/icons/new-set/chevron-down.svg"
              [ngClass]="{'rotate-180': passwordRestrictions}"
              class="h-6 svg-gray-filter "
              alt=""
            >
          </div> -->
          <!-- <div
            *ngIf="passwordRestrictions"
            class="criteria-container"
          >
            <p class="font-semibold text-mild">{{localization.auth.must_contain | translate}}:</p>
            <p [class.validCriteria]="!passwordControl.getError('lowercase')">
              {{localization.auth.one_lowercase_letter_restriction | translate}}
            </p>
            <p [class.validCriteria]="!passwordControl.getError('uppercase')">
              {{localization.auth.one_uppercase_letter_restriction | translate}}
            </p>
            <p [class.validCriteria]="!passwordControl.getError('digit')">
              {{localization.auth.one_digit_restriction | translate}}
            </p>
            <p [class.validCriteria]="!passwordControl.getError('specialchar')">
              {{localization.auth.one_special_character_restriction | translate}}
            </p>
            <p [class.validCriteria]="!passwordControl.getError('minLength')">
              {{localization.auth.eight_character_restriction | translate}}
            </p>
          </div> -->
        </div>

        <!-- ----------------------------- invite code ----------------------------- -->
        <div>
          <app-text-input
            label="Private Invite Code (Optional)"
            formControlName="invite_code"
            placeholder="Enter code"
          ></app-text-input>
        </div>

        <!-- ---------------------------- submit button ---------------------------- -->
        <app-button
          [color]="'primary'"
          class="w-full"
          type="submit"
        >
          <ng-container *ngIf="!(singUpState$ | async)?.loading; else loading">
            {{ localization.auth.agree_and_sign_up | translate }}
          </ng-container>
          <ng-template #loading>
            <span>
              <svg
                class="mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </span>
          </ng-template>
        </app-button>
      </form>

      <div class="text-center text-lg px-4">
        <ng-container *ngFor="let item of localization.auth.sign_up_tos  | translate | translateCut; let i = index">
          <ng-container [ngSwitch]="item.type">
            <span *ngSwitchCase="'text'">
              {{ item.content }}
            </span>
            <span
              *ngSwitchCase="'section'"
              class="text-primary font-semibold cursor-pointer"
              (click)="i == 1 ? openTermsAndConditions() : openPrivacyPolicy()"
            >
              {{ item.content }}
            </span>
          </ng-container>
        </ng-container>
      </div>
      <div class="flex gap-3 mt-6 justify-center">
        {{ localization.auth.have_account | translate }}
        <span
          class="cursor-pointer text-primary font-semibold"
          routerLink="/auth/login"
        >
          {{ localization.auth.sign_in | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
