import { Observer } from 'dexie';
import { Observable } from 'rxjs';

export abstract class IMediaRepository {
   abstract uploadImages(request: FormData, progress$?: Observer<any>)
      : Observable<string[]>
   abstract uploadAttachments(data: FormData, progress$?: Observer<any>)
      : Observable<{
         type: string,
         url: string
      }[]>
   abstract uploadAttachments2(data: FormData)
      : Observable<{
         type: string,
         url: string
      }[]>
}
