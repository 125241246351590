
<div class="flex flex-col h-full" >
    <div class="flex bg-white flex-row sticky top-0 p-2 justify-end items-center">
        <app-button (click)="close()" color="light">
            <div><b>Close</b></div>
        </app-button>
    </div>
    <div class="flex flex-1">
         <iframe src="https://form.jotform.com/241894865767074"></iframe>
    </div>

</div>

