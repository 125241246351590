import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { UseCase } from '../UseCase';
import { IUsersRepository } from '@/app/core/IRepositories/IUsersRepository';
import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { TeamMember } from '@/app/core/Models/TeamMember';

@Injectable({
  providedIn: 'root',
})
export class GetTeamMembersUseCase implements UseCase<any, TeamMember[]> {
  constructor(
    private databaseService: DatabaseService,
    private usersRepository: IUsersRepository
  ) {}

  execute(): Observable<TeamMember[]> {
    return this.usersRepository.getTeamMembers().pipe(
      tap((teamMembers) => {
        this.usersRepository.saveTeamMembers(teamMembers);
      })
    );
  }
}
