import { Injectable } from '@angular/core';
import { ILocalStorageService } from '../../Adapters/ILocalStorageService';
import { APP_LANG, APP_USER_CHOICE, COMPACT_MODE, DARK_MODE, INPUT_DEVICE, OUTPUT_DEVICE, SIDE_NAV } from '@/app/Utilities/TokenKeys';

@Injectable()
export class LocalSorageService implements ILocalStorageService {
  getItem<T>(key: string): T | null {
    let item: any = localStorage.getItem(key);
    if (item) {
      try {
        return JSON.parse(item);
      } catch {
        return item;
      }
    } else {
      return null;
    }
  }

  setItem<T>(key: string, object: T) {
    localStorage.setItem(key, JSON.stringify(object));
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    const preferencesToKeep = [APP_LANG, INPUT_DEVICE, OUTPUT_DEVICE, DARK_MODE, COMPACT_MODE, APP_USER_CHOICE, SIDE_NAV];
    const savedPreferences = preferencesToKeep.reduce((acc, key) => {
      const value = this.getItem(key);
      if (value) acc[key] = value;
      return acc;
    }, {} as Record<string, any>);
  
    localStorage.clear();
    
    Object.entries(savedPreferences).forEach(([key, value]) => this.setItem(key, value));
  }
}
