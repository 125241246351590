import { PhoneRequestState } from './phoneRequest.reducer';
import { AppState } from '@/app/State/AppState';
import { createSelector } from '@ngrx/store';

const phoneRequestState = (state: AppState) => state.phoneRequest;

export const selectPhoneRequestLoadingState = createSelector(
  phoneRequestState,
  (state: PhoneRequestState) => state.loading
);

export const selectPhoneVerificationLoadingState = createSelector(
  phoneRequestState,
  (state: PhoneRequestState) => state.phoneVerification.loading
);


export const selectPhoneVerificationRequestState = createSelector(
  phoneRequestState,
  (state: PhoneRequestState) => state.phoneVerification
);
