import { createSelector } from '@ngrx/store';
import { AppState } from '../AppState';
import { LoginState, SingupState } from './reducer';

const authState = (state: AppState) => state.login;
const singupState = (state: AppState) => state.singup;

export const selectLoginState = createSelector(
  authState,
  (state: LoginState) => state
);

export const selectSignUpState = createSelector(
  singupState,
  (state: SingupState) => state
);

export const selectEmailVerificationState = createSelector(
  singupState,
  (state: SingupState) => state.emailVerification
);

export const selectAddPasswordState = createSelector(
  singupState,
  (state: SingupState) => state.addPassword
);

export const selectIsAuthenticated = createSelector(
  authState,
  (state: LoginState) => state.authenticated
);


