import { emailRegExp } from '@/app/Utilities/regExp';
import { LookupContact } from '@/app/core/Models/contact';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import parsePhoneNumberFromString, { CountryCode, formatIncompletePhoneNumber } from 'libphonenumber-js';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';

@Component({
  selector: 'app-phone-contact-input',
  templateUrl: './phone-contact-input.component.html',
  styleUrls: ['./phone-contact-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneContactInputComponent implements OnInit {
  @Input() inputController = new FormControl('');
  @Input() invalid = false;
  @Input() filtredContacts: LookupContact[] = [];
  @Input() selectionType: 'phone' | 'email' = 'phone';
  @Input() isMac: boolean;
  @Output() addPhoneNumber = new EventEmitter<string>();
  @Output() addContactChannel = new EventEmitter<LookupContact>();
  @Output() countryCodeSelected = new EventEmitter<CountryCode>();
  localization = this.localizationViewModel.localization;
  countryCode: CountryCode = 'US';

  constructor(
    private matDialog: MatDialog,
    private localizationViewModel: LocalizationViewModel
  ) { }

  ngOnInit(): void {
  }

  get isEmail() {
    return this.selectionType === 'email'
  }

  trackContact(i: number, contact: any) {
    return contact.channel_id
  }

  _addPhoneNumber() {
    this.addPhoneNumber.emit(this.inputController.value)
  }

  _addContactChannel(i: number) {
    this.addContactChannel.emit(this.filtredContacts[i]);
  }

  get partiallyValidPhoneNumber() {
    return this.isEmail ? emailRegExp.test(this.inputController.value) : /^(\+)?\d+$/.test(this.inputController.value.replace(/[()\s-]/g, ''))
  }

  get formattedPhone() {
    let phone = this.inputController.value
    const phoneNumber = parsePhoneNumberFromString(phone);
    const cn = phoneNumber?.country || this.countryCode;
    return formatIncompletePhoneNumber(phone, cn);
  }

  async countryChange({ code }: { code: CountryCode }) {
    this.countryCode = code || this.countryCode
    this.countryCodeSelected.emit(this.countryCode)
  }

  get contacts() {
    return this.filtredContacts.map(c => ({
      channel_id: c.channel_id,
      avatar: c.avatar,
      displayName: `${c.given_name ?? ''} ${c.family_name ?? ''}`.trim(),
      channelValue: c.channel_value,
      email: c.email
    }))
  }
}
