import { ILocalStorageService } from '@/app/Data/Adapters/ILocalStorageService';
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class GuestGuard implements CanLoad, CanActivate {
  constructor(
    @Inject(ILocalStorageService) private localStorage: ILocalStorageService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const isLoggedIn =
      !!this.localStorage.getItem('yib_tk');

    if(state.url.includes('/auth/reset-password')){
      return true;
    }
    
    if (isLoggedIn) {
      this.router.navigate(['/main']);
    }
    return !isLoggedIn;
  }
  // fro lazy loading modules
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const isLoggedIn =
      !!this.localStorage.getItem('yib_tk');
    if (isLoggedIn) {
      this.router.navigate(['/main']);
    }
    return isLoggedIn;
  }
}
