import { TeamMessageBody } from '@/app/Data/DTO/TeamChatMessageDto';
import { Injectable } from '@angular/core';
import { User } from '@sentry/electron';
import { firstValueFrom, Observable, Observer, of, switchMap } from 'rxjs';
import { IMediaRepository } from '../../IRepositories/IMediaRepository';
import { ITeamInteractionRepository } from '../../IRepositories/ITeamInteractionRepository';
import { TeamInteraction } from '../../Models/interaction-team';
import { UseCase } from '../UseCase';

@Injectable({
  providedIn: 'root',
})
export class CreateTeamMessageUseCase implements UseCase<{ body: TeamMessageBody, observer: Observer<any> }, TeamInteraction<Partial<User>>> {
  constructor(
    private teamInteractionRepository: ITeamInteractionRepository,
    private mediaRepository: IMediaRepository,
  ) { }

  execute(data: { body: TeamMessageBody, observer: Observer<any> }): Observable<TeamInteraction<Partial<User>>> {
    let message = data.body.getBody();
    return (data.body.hasAttachments
      ? this.mediaRepository.uploadAttachments(data.body.getBodyAttachment(), data.observer)
      : of([])
    ).pipe(switchMap(attachments => {
      message = {
        ...message,
        attachments
      }
      return this.teamInteractionRepository.sendMessage(message)
    }))
  }
}