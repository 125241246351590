import { Localizations } from '@/app/Utilities/localization/index';
import { IBillingRepository } from '@/app/core/IRepositories/IBillingRepository';
import { BillingSubscription, PaymentMethod, PlanName } from '@/app/core/Models/billing';
import { Roles } from "@/app/core/Models/Roles";
import { CancelBillingSubscriptionDTO } from '@/app/Data/DTO/BillingDto';
import { ErrorApiReponse } from '@/app/Data/services/Networking/ApiResponse';
import { SubscriptionRestrictionComponent } from '@/app/Ui/components/subscription-restriction/subscription-restriction.component';
import { SuccessDialogComponent } from '@/app/Ui/components/success-dialog/success-dialog.component';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { AppState } from '../AppState';
import { selectTenantProfile } from "../Tenant/tenant.selector";
import { ApplyPromoCodeFailAction, ApplyPromoCodeSuccessAction, APPLY_PROMO_CODE, APPLY_PROMO_CODE_SUCCESS, BillingActions, CANCEL_BILLING_SUBSCRIPTION, DeleteCurrentPaymentMethodeSuccessAction, DeletePromoCodeSuccessAction, DELETE_CURRENT_PAYMENT_METHOD, DELETE_PROMO_CODE, DELETE_PROMO_CODE_SUCCESS, FetchActiveSubscriptionAction, FetchActiveSubscriptionSuccessAction, FetchBillingInvoicesSuccessAction, FetchBillingPlansSuccessAction, FetchCurrentPaymentMethodeSuccessAction, FETCH_ACTIVE_SUBSCRIPTION, FETCH_ACTIVE_SUBSCRIPTION_SUCCESS, FETCH_BILLING_INVOICES, FETCH_BILLING_PLANS, FETCH_CURRENT_PAYMENT_METHOD, GetActivePromotionCodesAction, GetActivePromotionCodesSuccessAction, GetBillingPromptStatusAction, GET_ACTIVE_PROMOTION_CODES, GET_BILLING_PROMPT_STATUS, INTERACT_WITH_BILLING_PROMPT, OpenBillingPromptAction, REACTIVE_BILLING_SUBSCRIPTION, FetchCurrentPaymentMethodeAction, FetchBillingPlansFailAction, } from './billing.actions';
import { PRE_INITIALIZE_APP, PRE_INITIALIZE_MAIN_APP } from '../app/action';
import { PlatformService } from '@/app/Utilities/platform/platform.service';
@Injectable()
export class BillingEffect {
  constructor(
    private actions$: Actions<BillingActions>,
    private billingRepository: IBillingRepository,
    private router: Router,
    private dialog: MatDialog,
    private platformService: PlatformService,
    private snackBarService: SnackbarService,
    private store$: Store<AppState>,
  ) { }

  onInit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PRE_INITIALIZE_APP),
        map(() =>
          FetchCurrentPaymentMethodeAction()
        )
      ),
    { dispatch: true }
  )

  onMainInit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PRE_INITIALIZE_MAIN_APP),
        map(() =>
          FetchActiveSubscriptionAction()
        )
      ),
    { dispatch: true }
  )

  onFetchActiveSubscriptionAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FETCH_ACTIVE_SUBSCRIPTION),
        switchMap(() => this.billingRepository.getActiveSubscription()),
        map(activeSubscription => Object.assign(new BillingSubscription(), activeSubscription)),
        map((activeSubscription) => {
          return FetchActiveSubscriptionSuccessAction({ payload: activeSubscription });
        })
      ),
    { dispatch: true }
  );

  onFetchBillingPlansAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FETCH_BILLING_PLANS),
        switchMap(() => this.billingRepository.getBillingPlans().pipe(
          map((plans) =>
            FetchBillingPlansSuccessAction({ payload: { plans } })
          ),
          catchError(() => of(FetchBillingPlansFailAction()))
        ))
      ),
    { dispatch: true }
  );

  onGetActivePromotionCodesAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GET_ACTIVE_PROMOTION_CODES),
        switchMap(() => this.billingRepository.getActivePromoCodes().pipe(
          map((promoCodes) =>
            GetActivePromotionCodesSuccessAction({ payload: { promoCodes } })
          ),
          catchError(() => of())
        ))
      ),
    { dispatch: true }
  );

  onApplyPromoCodeAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(APPLY_PROMO_CODE),
        map(action => action.payload),
        switchMap(({ code }) => this.billingRepository.applyPromoCode({ code }).pipe(
          map((plans) => ApplyPromoCodeSuccessAction({ payload: { plans } })),
          tap(() => {
            this.snackBarService.openAlert({
              message: Localizations.alert_dialog.promotion_code_applied,
              type: 'success'
            });
          }),
          catchError((err: ErrorApiReponse<string>) => {
            if ([409, 404].includes(err.responseError.error.status)) {
              this.snackBarService.openAlert({
                message: err.responseError.error.message,
                type: 'failure'
              });
            }
            return of(ApplyPromoCodeFailAction())
          })
        )),
      ),
    { dispatch: true }
  );

  onDeletePromoCodeAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DELETE_PROMO_CODE),
        map(action => action.payload),
        switchMap(({ codeId }) => this.billingRepository.deletePromoCode({ codeId }).pipe(
          tap(() => {
            this.snackBarService.openAlert({
              message: Localizations.alert_dialog.promotion_code_deleted,
              type: 'success'
            });
          }),
          map((plans) => DeletePromoCodeSuccessAction({ payload: { plans } })),
          catchError(() => of())
        )),
      ),
    { dispatch: true }
  );

  onFetchBillingInvoicesAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FETCH_BILLING_INVOICES),
        switchMap(() => this.billingRepository.getInvoices()),
        map(invoices => {
          return FetchBillingInvoicesSuccessAction({ payload: invoices });
        })
      ),
    { dispatch: true }
  );

  onApplyPromoCodeSuccessAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(APPLY_PROMO_CODE_SUCCESS),
        map(() => GetActivePromotionCodesAction())
      ),
    { dispatch: true }
  );

  onDeletePromoCodeSuccessAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DELETE_PROMO_CODE_SUCCESS),
        map(() => GetActivePromotionCodesAction())
      ),
    { dispatch: true }
  );

  onDeleteCurrentPaymentMethodeSuccessAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DELETE_CURRENT_PAYMENT_METHOD),
        switchMap(() => this.billingRepository.deletePaymentMethod()),
        tap(() => {
          this.dialog.open(SuccessDialogComponent, {
            width: '350px',
            panelClass: 'success-task',
            data: {
              message: Localizations.success_messages.credit_card_removed
            },
          });
        }),
        map(() => {
          return DeleteCurrentPaymentMethodeSuccessAction();
        })
      ),
    { dispatch: true }
  );


  onFetchCurrentPaymentMethodeAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FETCH_CURRENT_PAYMENT_METHOD),
        switchMap(() =>
          this.billingRepository.getCurrentPaymentMethod().pipe(
            map(paymentMethod => Object.assign(new PaymentMethod(), paymentMethod)),
            catchError(err => of(Object.assign(new PaymentMethod(), {})))
          )
        ),
        map((paymentMethod) =>
          FetchCurrentPaymentMethodeSuccessAction({ payload: paymentMethod })
        )
      ),
    { dispatch: true }
  );

  onReactiveBillingSubscriptionAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(REACTIVE_BILLING_SUBSCRIPTION),
        switchMap(() => this.billingRepository.reactivateBillingSubscription()),
        tap(() => {
          this.dialog.open(SuccessDialogComponent, {
            width: '350px',
            panelClass: 'success-task',
            data: { message: Localizations.success_messages.successful_subscription },
          });
          this.router.navigate([`main/settings/billing`])
        }),
        map(() => {
          return FetchActiveSubscriptionAction();
        })
      ),
    { dispatch: true }
  );

  onCancelBillingSubscriptionAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CANCEL_BILLING_SUBSCRIPTION),
        map((action: any) => action.payload as CancelBillingSubscriptionDTO),
        switchMap((data) => this.billingRepository.CancelBillingSubscription(data)),
        tap(() => {
          this.dialog.open(SuccessDialogComponent, {
            width: '350px',
            data: { message: Localizations.success_messages.successful_unsubscription },
          });
        }),
        map(() => {
          return FetchActiveSubscriptionAction();
        })
      ),
    { dispatch: true }
  );

  checkForSubscriptionRestriction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FETCH_ACTIVE_SUBSCRIPTION_SUCCESS),
        map(action => action.payload),
        tap((activeSubscription: BillingSubscription) => {
          if (activeSubscription?.is_fully_restricted && !this.platformService.isMacOS) {
            this.dialog.open(SubscriptionRestrictionComponent, {
              disableClose: true,
              width: '480px',
              data: {
                isTrial: [PlanName.trial, PlanName.trial_extended].includes(activeSubscription.plan_name)
              }
            })
          }
        }),
        withLatestFrom(this.store$.select(selectTenantProfile)),
        filter(([subscription, tenent]) => !!tenent?.roles.includes(Roles.admin) && ['Trial', 'Trial Extended'].includes(subscription.plan_name)),
        map(() => GetBillingPromptStatusAction()),
      ),
    { dispatch: true }
  );

  onInteractWithBillingPromptAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(INTERACT_WITH_BILLING_PROMPT),
        map(action => action.payload),
        switchMap((payload) =>
          this.billingRepository.BillingPromptEvent(payload)
        )
      ),
    { dispatch: false }
  );

  onGetBillingPromptStatusAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GET_BILLING_PROMPT_STATUS),
        switchMap(() =>
          this.billingRepository.GetBillingPromptStatus().pipe(
            filter(res => res.expired),
            map(() => OpenBillingPromptAction())
          )
        )
      ),
    { dispatch: true }
  );
}
