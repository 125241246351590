import { IAuthenticationRepository } from '@/app/core/IRepositories/IAuthenticationRepository';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordGuard implements CanActivate {
  constructor(
    private authRepository: IAuthenticationRepository,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token: string = route.params['token'];
    if (token) {
      return firstValueFrom(
        this.authRepository.verifyPasswordResetToken({
          reset_token: token,
        })
      )
        .then(() => true)
        .catch(() => {
          return this.router.parseUrl('/expired');
        });
    } else {
      return this.router.parseUrl('/expired');
    }
  }
}
