import {
  RecentInteraction, RecentMessagesQueryParams
} from '@/app/core/Models/Interaction';
import { RecentInteractionsFilter } from '@/app/core/Models/RecentInteractionsFilter';
import { User } from '@/app/core/Models/User';
import { Inbox } from '@/app/core/Models/inbox';
import { createAction, props, union } from '@ngrx/store';
import { ThreadPreferences } from './inbox.model';

/* ------------------------------- USER TYPING ------------------------------ */
export const SEND_USER_TYPING_EVENT = '[Inbox] Send user typing event';
export const SendUserTypingUserAction = createAction(
  SEND_USER_TYPING_EVENT,
  props<{ payload: { channel_id: number, status: boolean } }>()
);

export const SET_TYPING_USER = '[Inbox] Set typing user';
export const SetTypingUserAction = createAction(
  SET_TYPING_USER,
  props<{ payload: { user: User, channelId: number, status: boolean } }>()
);
/* ----------------------------- RECENT MESSAGES ---------------------------- */

export const FETCH_RECENT_MESSAGES = '[Inbox] Get Recent Messages';
export const FetchRecentMessagesAction = createAction(
  FETCH_RECENT_MESSAGES,
  props<{ payload: RecentMessagesQueryParams }>()
);

export const FETCH_RECENT_MESSAGES_SUCCESS = '[Inbox API Response] Successfully Fetched Recent Messages';
export const FetchRecentMessagesSuccessAction = createAction(
  FETCH_RECENT_MESSAGES_SUCCESS,
  props<{ payload: RecentInteraction[] }>()
);

export const FETCH_RECENT_MESSAGES_FAIL = '[Inbox API Response] Failed To Fetch Recent Messages';
export const FetchRecentMessagesFailAction = createAction(
  FETCH_RECENT_MESSAGES_FAIL,
  props<{ payload: string }>()
);

/* --------------------------------- inboxes -------------------------------- */

export const FETCH_INBOXES = '[Inbox] Fetch Inboxes';
export const FetchInboxesAction = createAction(
  FETCH_INBOXES,
);

export const FETCH_INBOXES_SUCCESS = '[Inbox] Get Inboxes Success';
export const FetchInboxesSuccessAction = createAction(
  FETCH_INBOXES_SUCCESS,
  props<{ payload: { inboxes: Inbox[] } }>()
);

export const INCREASE_INBOX_UNREAD_COUNT = '[Inbox] Increase Inbox Unread Count';
export const IncreaseInboxUnreadCountAction = createAction(
  INCREASE_INBOX_UNREAD_COUNT,
  props<{ payload: { inboxId: number } }>()
);

/* -------------------------- CONVERSATION OPENING -------------------------- */
export const OPEN_CONVERSATION_THREAD = '[InboxMessages] Open Conversation Thread';
export const OpenConversationThreadAction = createAction(
  OPEN_CONVERSATION_THREAD,
  props<{ payload: { threadId: number, unClassified: boolean, interaction?: RecentInteraction } }>()
);

export const SET_ACTIVE_THREAD_ID = '[Inbox] Set Active Thread';
export const SetActiveThreadIdAction = createAction(
  SET_ACTIVE_THREAD_ID,
  props<{ payload: { id: number; unClassified: boolean } }>()
);

export const RESET_ACTIVE_THREAD_ID = '[Inbox] Reset Active Thread';
export const ResetActiveThreadIdAction = createAction(
  RESET_ACTIVE_THREAD_ID
);

export const UPDATE_RECENT_INTERACTIONS_FILTER = '[Inbox] Update Recent Interactions Filter';
export const UpdateRecentInteractionsFilterAction = createAction(
  UPDATE_RECENT_INTERACTIONS_FILTER,
  props<{ payload: RecentInteractionsFilter }>()
);

export const INBOX_SELECTION = '[Inbox] Inbox Selected action';
export const InboxSelectionAction = createAction(
  INBOX_SELECTION,
  props<{ payload: { inbox?: Inbox } }>()
);

export const UPDATE_THREAD_PREFRENCES = '[Thread] Update Thread Preferences';
export const UpdateThreadPReferencessAction = createAction(
  UPDATE_THREAD_PREFRENCES,
  props<{ payload: { threadId: number; inbox?: Inbox, unClassified: boolean } }>()
);



export const UPDATE_PENDING_INTERACTION_TO_FAILED_STATUS = '[Thread] update pending interaction to failed status';
export const UpdatePendingInteractionToFailedStatusAction = createAction(
  UPDATE_PENDING_INTERACTION_TO_FAILED_STATUS,
  props<{ payload: { msg_id: string } }>()
);

export const UPDATE_PENDING_INLINE_NOTE_TO_FAILED_STATUS = '[Thread] update pending InlineNote to failed status';
export const UpdatePendingInlineNoteToFailedStatusAction = createAction(
  UPDATE_PENDING_INLINE_NOTE_TO_FAILED_STATUS,
  props<{ payload: { msg_id: number } }>()
);

export const SET_THREAD_SELECTED_CHANNELS = '[Inbox] SET_THREAD_SELECTED_CHANNELS';
export const SetThreadSelectedChannelsAction = createAction(
  SET_THREAD_SELECTED_CHANNELS,
  props<{ payload: ThreadPreferences }>()
);

export const INBOX_FORCE_REFRESH_REQUEST = '[Inbox] Inbox force refresh request';
export const InboxForceRefreshRequestAction = createAction(INBOX_FORCE_REFRESH_REQUEST);

export const INBOX_FORCE_REFRESH = '[Inbox] Inbox force refreshed';
export const InboxForceRefreshAction = createAction(INBOX_FORCE_REFRESH);

export const TOGGLE_SCHEDULED_MESSAGES = '[Inbox] toggle scheduled messages';
export const ToggleScheduledMessagesAction = createAction(TOGGLE_SCHEDULED_MESSAGES,
  props<{ payload: { status?: boolean } }>()
);


const all = union({
  SetTypingUserAction,
  SendUserTypingUserAction,
  FetchRecentMessagesAction,
  ToggleScheduledMessagesAction,
  SetThreadSelectedChannelsAction,
  UpdateThreadPReferencessAction,
  UpdateRecentInteractionsFilterAction,
  InboxSelectionAction,
  SetActiveThreadIdAction,
  ResetActiveThreadIdAction,
  OpenConversationThreadAction,
  FetchRecentMessagesFailAction,
  FetchRecentMessagesSuccessAction,
});

export type InboxActions = typeof all;
