import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';

@Component({
  selector: 'yobi-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  localization = this.localizationViewModel.localization

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private localizationViewModel: LocalizationViewModel,
    @Inject(MAT_DIALOG_DATA) public data: {
      message: string;
      title?: string;
      translateParams?: Object
      confirm?: string;
      cancel?: string;
      warning?: boolean
    }
  ) { }

  ngOnInit(): void { }
}
