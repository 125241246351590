<ng-container *ngIf="notificationsState$ | async as notificationsState">
  <header class="flex flex-row justify-between p-4 items-baseline">
    <span class="text-xl font-medium">
      {{localization.terms.notifications | translate }}
    </span>
    <button
      mat-icon-button
      class="text-primary font-medium text-sm"
      (click)="dismiss()"
    >
      {{localization.labels.dismiss_all | translate }}
    </button>
  </header>

  <div
    class="flex flex-col gap-1 overflow-y-auto p-2"
    *ngIf="notifications$ | async as notifications"
    (scroll)="onScroll($event)"
  >
    <p
      *ngIf="!notificationsState.loading && !notifications?.length"
      class="text-center text-base text-mild p-10"
    >
      {{localization.not_found.notifications | translate }}
    </p>

    <app-notification-item
      class="hover:bg-slate-700 rounded cursor-pointer"
      *ngFor="let notification of notifications"
      [notification]="notification"
      (click)="openNotification(notification)"
    >
    </app-notification-item>

    <div class="flex items-center justify-center h-12 shrink-0">
      <span
        class="text-sm text-mild text-center"
        *ngIf="notificationsState.isLastPage"
      >
        No more notifications
      </span>
      <mat-spinner
        class="mx-auto m-2"
        *ngIf="notificationsState.loading"
        color="primary"
        diameter="24"
      ></mat-spinner>
    </div>
  </div>
</ng-container>