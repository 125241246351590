import { StoreModule, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Dev environment
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '@/environments/environment';
import { NgModule } from '@angular/core';
import { Reducer } from './AppState';
import { AuthEffect } from './Auth/auth.effect';
import { RouterEffect } from './Router/router.effect';
import { AnalyticsEffect } from './analytics/analytics.effect';
import { ConfigEffect } from './app/app.effect';
import { TenantEffect } from './Tenant/tenant.effect';
import { BillingEffect } from './billing/billing.effect';
import { PhoneRequestEffect } from './phoneRequest/phoneRequest.effect';
import { SocketEffect } from './Socket/socket.effect';
export const metaReducers: MetaReducer<any>[] = environment.production
  ? []
  : [storeFreeze];

@NgModule({
  imports: [
    StoreModule.forRoot(Reducer, { metaReducers }),
    EffectsModule.forRoot([
      AuthEffect,
      SocketEffect,
      AnalyticsEffect,
      RouterEffect,
      ConfigEffect,
      PhoneRequestEffect,
      TenantEffect,
      BillingEffect
    ]),
    StoreDevtoolsModule.instrument({ maxAge: 30 }),
  ],
  providers: [],
})
export class StateStoreRootModule { }
