import { AnalyticsEventsNames } from '@/app/shared/analytics/analyticsEvents';
import { MixpanelService } from '@/app/shared/analytics/mixpanel-service.service';
import { Injectable } from '@angular/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { filter, map, tap } from 'rxjs';
import { OPEN_CONVERSATION_THREAD } from '../Inbox/inbox.action';
import { RouterNavigationEndAction } from '../Router/action';
import { TenantActions, UPDATE_TENANT_PROFILE } from '../Tenant/tenant.action';
import { AppActions, PRE_INITIALIZE_APP, SIGN_OUT } from '../app/action';
import { AnalyticsActions, INIT_ANALYTICS, TRACK_EVENT, TrackEventAction } from './analytics.action';

@Injectable()
export class AnalyticsEffect implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private appActions$: Actions<AppActions>,
    private tenantActions$: Actions<TenantActions>,
    private mixpanelActions$: Actions<AnalyticsActions>,
    private mixpanelService: MixpanelService,
  ) {
    window.addEventListener('beforeunload', () => {
      this.mixpanelService.trackAppClosed()
    })
  }

  ngrxOnInitEffects(): Action {
    return { type: INIT_ANALYTICS }
  }

  // APP events -----------------------------
  onInit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(INIT_ANALYTICS),
        tap(() => this.mixpanelService.init()),
        tap(() => this.mixpanelService.track({ name: AnalyticsEventsNames.APP_OPENED }))
      ),
    { dispatch: false }
  )

  // SESSION events -------------------------
  sessionStart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PRE_INITIALIZE_APP),
        map(() =>
          TrackEventAction({ payload: { event: { name: AnalyticsEventsNames.SESSION_START, properties: {} } } })
        )
      ),
    { dispatch: true }
  )

  sessionEnd$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SIGN_OUT),
        map(() =>
          TrackEventAction({ payload: { event: { name: AnalyticsEventsNames.SESSION_END, properties: {} } } })
        )
      ),
    { dispatch: true }
  )



  // ROUTER events --------------------------
  onRouterNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterNavigationEndAction),
        map((action) => action.payload),
        map(({timestamp, current_page, previous_page}) =>
          TrackEventAction({ payload: { event: { name: AnalyticsEventsNames.PAGE_VIEW, properties: {
           current_page, previous_page, timestamp 
          } } } })
        )
      ),
    { dispatch: true }
  )
  /*
    onRouterNavigation$ = createEffect(
      () =>
        this.router.events.pipe(
          filter((route) => route instanceof NavigationEnd),
          startWith(null),
          distinctUntilChanged((event1, event2) => (event1 as NavigationEnd)?.url.split('?')[0] === (event2 as NavigationEnd)!.url.split('?')[0]),
          pairwise(),
          map(([event1, event2]) => TrackEventAction({ payload: { event: { name: AnalyticsEventsNames.PAGE_VIEW, properties: { previous_page: (event1 as NavigationEnd)?.url ?? '', current_page: (event2 as NavigationEnd).url } } } })),
        ),
      { dispatch: true }
    )*/
  // AUTH events ----------------------------
  onUpdateTenantProfile$ = createEffect(
    () =>
      this.tenantActions$.pipe(
        ofType(UPDATE_TENANT_PROFILE),
        map((action) => action.payload),
        filter(tenant => !!tenant),
        tap((tenant) => this.mixpanelService.register(tenant)),
      ),
    { dispatch: false }
  );
/*
  onLoginWithCredentials$ = createEffect(
    () =>
      this.authActions$.pipe(
        ofType(LOGIN_WITH_CREDENTIAL),
        map((action) => action.payload),
        map(({ user }) => TrackEventAction({ payload: { event: { name: AnalyticsEventsNames.LOGIN_ATTEMPT, properties: { username: user, auth_type: 'native' } } } })),
      ),
    { dispatch: true }
  );
  onLoginWithGoogle$ = createEffect(
    () =>
      this.authActions$.pipe(
        ofType(LOGIN_WITH_GOOGLE),
        map(() => TrackEventAction({ payload: { event: { name: AnalyticsEventsNames.LOGIN_ATTEMPT, properties: { auth_type: 'google' } } } })),
      ),
    { dispatch: true }
  );

  onLoginWitFacebook$ = createEffect(
    () =>
      this.authActions$.pipe(
        ofType(LOGIN_WITH_FACEBOOK),
        map(() => TrackEventAction({ payload: { event: { name: AnalyticsEventsNames.LOGIN_ATTEMPT, properties: { auth_type: 'facebook' } } } })),
      ),
    { dispatch: true }
  );

  onLoginWithApple$ = createEffect(
    () =>
      this.authActions$.pipe(
        ofType(LOGIN_WITH_APPLE),
        map(() => TrackEventAction({ payload: { event: { name: AnalyticsEventsNames.LOGIN_ATTEMPT, properties: { auth_type: 'apple' } } } })),
      ),
    { dispatch: true }
  );
  */

  onLogoutAction$ = createEffect(
    () =>
      this.appActions$.pipe(
        ofType(SIGN_OUT),
        map(() => TrackEventAction({ payload: { event: { name: AnalyticsEventsNames.LOGOUT, properties: {} } } }))
      ),
    { dispatch: true }
  );
  
/*
  onGetInteractionSentimentAnalysis$ = createEffect(
    () =>
      this.appActions$.pipe(
        ofType(GET_INTERACTION_CLASSIFICATION),
        map(() => TrackEventAction({ payload: { event: { name: AnalyticsEventsNames.CALL_TO_ACTION, properties: { action_type: CallToActionEventsTypes.SENTIMENT_ANALYSIS } } } }))
      ),
    { dispatch: true }
  );
   */

  /*
  onTranslateMessage$ = createEffect(
    () =>
      this.appActions$.pipe(
        ofType(TRANSLATE_THREAD_INPUT_MESSAGE),
        map(() => 
        TrackEventAction({ payload: { 
          event: { name: AnalyticsEventsNames.CALL_TO_ACTION, 
            properties: { action_type: CallToActionEventsTypes.TRANSLATE_MESSAGE }
           } 
          } }
          ))
      ),
    { dispatch: true }
  );*/

  // other events -----------------------------
  onTrackEventAction$ = createEffect(
    () =>
      this.mixpanelActions$.pipe(
        ofType(TRACK_EVENT),
        map((action) => action.payload),
        tap(({ event }) => this.mixpanelService.track(event)),
        tap(({ event }) => event.name == AnalyticsEventsNames.LOGOUT && this.mixpanelService.reset()),
      ),
    { dispatch: false }
  );


  onOpenConversationThreadAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OPEN_CONVERSATION_THREAD),
        map((action: any) => action.payload),
        map(({ threadId, unClassified }) => {
          return TrackEventAction({
            payload: {
              event:
              {
                name: AnalyticsEventsNames.OPEN_CONVERSATION,
                properties: { conversation_id: threadId, is_contact: !unClassified }
              }
            }
          })
        })
      ),
    { dispatch: true }
  );

}
