import { LoginCredentialsRequest } from '@/app/core/Models/LoginCredentials';
import { Tenant } from '@/app/core/Models/tenant';
import { BusinessNameFieldDto, EmailFieldDto } from '@/app/Data/DTO/authDto';
import { CreateUserDto } from '@/app/Data/DTO/CreateUserDto';
import { EmailVerificationCodeDto, lightwightSignupDto } from '@/app/Data/DTO/EmailVerificationCodeDto';
import { PasswordResetDto } from '@/app/Data/DTO/PasswordResetDto';
import { VerificationCodeDto } from '@/app/Data/DTO/VerificationCodeDto';
import { createAction, props, union } from '@ngrx/store';


/* -------------------------------- granting -------------------------------- */
export const GRANTING_STARTED = '[USERS] Grant User start';
export const GrantUserStartAction = createAction(
  GRANTING_STARTED,
);
export const GRANTING_END = '[USERS] Grant User end';
export const GrantUserEndAction = createAction(
  GRANTING_END,
);

/* ------------------------------- CREATE USER ------------------------------ */
export const CREATE_USER = '[USERS] Create User';
export const CreateUserAction = createAction(
  CREATE_USER,
  props<{ payload: CreateUserDto }>()
);
export const CREATE_USER_SUCCESS = '[USERS] Create User SUCCESS';
export const CreateUserSuccessAction = createAction(
  CREATE_USER_SUCCESS,
  props<{ payload: { tenant: Tenant } }>()

);
export const CREATE_USER_FAILED = '[USERS] Create User Failed';
export const CreateUserFailedAction = createAction(
  CREATE_USER_FAILED,
  props<{ payload: { message: string } }>()
);

export const LOGIN_WITH_CREDENTIAL = '[Login] Login With Credential';
export const LoginWithCredentialsAction = createAction(
  LOGIN_WITH_CREDENTIAL,
  props<{ payload: LoginCredentialsRequest }>()
);

export const LOGIN_WITH_GOOGLE = '[Login] Login With Google';
export const LoginWithGoogleAction = createAction(LOGIN_WITH_GOOGLE);

export const LOGIN_WITH_APPLE = '[Login] Login With Apple';
export const LoginWithAppleAction = createAction(LOGIN_WITH_APPLE);

export const LOGIN_WITH_FACEBOOK = '[Login] Login With Facebook';
export const LoginWithFacebookAction = createAction(LOGIN_WITH_FACEBOOK);

export const SOCIAL_LOGIN = '[Login] Social login';
export const SocialoLoginAction = createAction(
  SOCIAL_LOGIN,
  props<{ payload: { yib_tk: string; yib_rt: string } }>()
);

export const SOCIAL_LOGIN_SUCESS = '[Login] Social login success';
export const SocialLoginSucessAction = createAction(
  SOCIAL_LOGIN_SUCESS,
  props<{ payload: { tenant: Tenant } }>()
);

export const BILLING_TOKEN_LOGIN = '[Login] Billing Token Login';
export const BillingTokenLoginAction = createAction(
  BILLING_TOKEN_LOGIN,
  props<{ payload: { yib_tk: string; yib_rt: string } }>()
);

export const SOCIAL_LOGIN_FAIL = '[Login] Social login fail';
export const SocialoLoginFailAction = createAction(
  SOCIAL_LOGIN_FAIL,
  props<{ payload: string }>()
);

export const LOGIN_WITH_CREDENTIAL_FAIL = '[Login] Login With Credential Fail';
export const LoginWithCredentialsFailAction = createAction(
  LOGIN_WITH_CREDENTIAL_FAIL,
  props<{ payload: string }>()
);

export const LOGIN_WITH_CREDENTIAL_SUCCESS =
  '[Login] Login With Credential Success';
export const LoginWithCredentialsSuccessAction = createAction(
  LOGIN_WITH_CREDENTIAL_SUCCESS,
  props<{ payload: { tenant: Tenant } }>()

);

export const CHECK_IF_BUSINESS_NAME_NOT_EXIST =
  '[Signup] Check If Business Name Exist';
export const CheckIfBusinessNameNotExistAction = createAction(
  CHECK_IF_BUSINESS_NAME_NOT_EXIST,
  props<{ payload: BusinessNameFieldDto }>()
);

export const CHECK_IF_EMAIL_NOT_EXIST = '[Signup] Check If Email Exist';
export const CheckIfEmailNotExistAction = createAction(
  CHECK_IF_EMAIL_NOT_EXIST,
  props<{ payload: EmailFieldDto }>()
);

export const CHECK_IF_BUSINESS_NAME_NOT_EXIST_SUCCESS =
  '[Signup] Check If Business Name Exist Success';
export const CheckIfBusinessNameNotExistSuccessAction = createAction(
  CHECK_IF_BUSINESS_NAME_NOT_EXIST_SUCCESS
);

export const CHECK_IF_BUSINESS_NAME_NOT_EXIST_FAIL =
  '[Signup] Check If Business Name Exist Fail';
export const CheckIfBusinessNameNotExistFailAction = createAction(
  CHECK_IF_BUSINESS_NAME_NOT_EXIST_FAIL,
  props<{ payload: string }>()
);

export const CHECK_IF_EMAIL_NOT_EXIST_SUCCESS =
  '[Signup] Check If Email Exist Success';
export const CheckIfEmailNotExistSuccessAction = createAction(
  CHECK_IF_EMAIL_NOT_EXIST_SUCCESS
);

export const CHECK_IF_EMAIL_NOT_EXIST_FAIL =
  '[Signup] Check If Email Exist Fail';
export const CheckIfEmailNotExistFailAction = createAction(
  CHECK_IF_EMAIL_NOT_EXIST_FAIL,
  props<{ payload: string }>()
);

export const REQUEST_EMAIL_VERIFICATION_CODE =
  '[Signup] Request Email Verification Code';
export const RequestEmailVerificationCodeAction = createAction(
  REQUEST_EMAIL_VERIFICATION_CODE,
  props<{ payload: EmailVerificationCodeDto }>()
);

export const REQUEST_EMAIL_VERIFICATION_CODE_FAIL =
  '[Signup] Request Email Verification Code Fail';
export const RequestEmailVerificationCodeFailAction = createAction(
  REQUEST_EMAIL_VERIFICATION_CODE_FAIL,
  props<{ payload: string }>()
);

export const REQUEST_EMAIL_VERIFICATION_CODE_SUCCESS =
  '[Signup] Request Email Verification Code Success';
export const RequestEmailVerificationCodeSuccessAction = createAction(
  REQUEST_EMAIL_VERIFICATION_CODE_SUCCESS,
  props<{ payload: string }>()
);

export const VALIDATE_EMAIL_VERIFICATION_CODE =
  '[Signup] Validate Email Verification Code';
export const ValidateEmailVerificationCodeAction = createAction(
  VALIDATE_EMAIL_VERIFICATION_CODE,
  props<{ payload: VerificationCodeDto }>()
);

export const VALIDATE_EMAIL_VERIFICATION_CODE_SUCCESS =
  '[Signup] Validate Email Verification Code Success';
export const ValidateEmailVerificationCodeSuccessAction = createAction(
  VALIDATE_EMAIL_VERIFICATION_CODE_SUCCESS
);

export const VALIDATE_EMAIL_VERIFICATION_CODE_FAIL =
  '[Signup] Validate Email Verification Code Fail';
export const ValidateEmailVerificationCodeFailAction = createAction(
  VALIDATE_EMAIL_VERIFICATION_CODE_FAIL,
  props<{ payload: string }>()
);

export const SIGNUP_WITH_CREDENTIALS = '[Signup] Signup With Credentials Action';
export const SignupWithCredentialsAction = createAction(
  SIGNUP_WITH_CREDENTIALS,
  props<{ payload: lightwightSignupDto }>()
);

export const SIGNUP_WITH_CREDENTIALS_SUCCESS = '[Signup]  Signup With Credentials Success';
export const SignupWithCredentialsSuccessAction = createAction(
  SIGNUP_WITH_CREDENTIALS_SUCCESS
);

export const SIGNUP_WITH_CREDENTIALS_FAIL = '[Signup]  Signup With Credentials Fail';
export const SignupWithCredentialsFailAction = createAction(
  SIGNUP_WITH_CREDENTIALS_FAIL,
  props<{ payload: string }>()
);

// export const HANDLE_EMAIL_VERIFICATION_DEEP_LINK =
//   '[Singup] HANDLE_EMAIL_VERIFICATION_DEEP_LINK';
// export const HandleEmailVerificationDeepLinkAction = createAction(
//   HANDLE_EMAIL_VERIFICATION_DEEP_LINK,
//   props<{ payload: { code: string; email: string } }>()
// );

export const EDIT_SINGUP_FORM = '[Singup] Edit signup form';
export const EditSingupFormAction = createAction(
  EDIT_SINGUP_FORM,
  props<{ payload: EmailVerificationCodeDto }>()
);

export const AUTHORIZE_ADD_PASSWORD = '[Singup] Authorize Add Password';
export const authorizeAddPassword = createAction(
  AUTHORIZE_ADD_PASSWORD,
  props<{ payload: boolean }>()
);

export const DESKTOP_SOCIAL_SIGNIN = '[AUTH] Desktop social sign in';
export const DesktopSocialSignInAction = createAction(DESKTOP_SOCIAL_SIGNIN);

export const PASSWORD_RESET_SEND = '[AUTH] password reset send';
export const PasswordResetSendAction = createAction(
  PASSWORD_RESET_SEND,
  props<{ payload: PasswordResetDto }>()
);

export const PASSWORD_RESET_SEND_FAIL = '[AUTH] password reset send failed';
export const PasswordResetSendFailAction = createAction(
  PASSWORD_RESET_SEND_FAIL
);


const all = union({
  CreateUserAction,
  LoginWithCredentialsAction,
  LoginWithGoogleAction,
  LoginWithAppleAction,
  LoginWithFacebookAction,
  SocialoLoginAction,
  SignupWithCredentialsAction,
  SocialLoginSucessAction,
  BillingTokenLoginAction,
  SocialoLoginFailAction,
  LoginWithCredentialsFailAction,
  LoginWithCredentialsSuccessAction,
  CheckIfBusinessNameNotExistAction,
  CheckIfEmailNotExistAction,
  CheckIfBusinessNameNotExistSuccessAction,
  CheckIfBusinessNameNotExistFailAction,
  CheckIfEmailNotExistSuccessAction,
  CheckIfEmailNotExistFailAction,
  RequestEmailVerificationCodeAction,
  RequestEmailVerificationCodeFailAction,
  RequestEmailVerificationCodeSuccessAction,
  ValidateEmailVerificationCodeAction,
  ValidateEmailVerificationCodeSuccessAction,
  ValidateEmailVerificationCodeFailAction,
  EditSingupFormAction,
  authorizeAddPassword,
  DesktopSocialSignInAction,
  PasswordResetSendAction,
  PasswordResetSendFailAction
});

export type AuthActions = typeof all;
