import { createAction, props, union } from '@ngrx/store';
import { Task, TaskFilter, TaskStatus } from '@/app/core/Models/Task';
import { TasksListByChannelIdDto, TasksListDto } from '@/app/Data/DTO/TasksListDto';


export const SET_ACTIVE_TASKS_STATUS = '[TASKS] set active tasks status';
export const SetActiveTasksStatusAction = createAction(
  SET_ACTIVE_TASKS_STATUS,
  props<{ payload: TaskStatus[] }>()
);

export const GET_ALL_TASKS = '[TASKS] Get All Tasks';
export const GetAllTasksAction = createAction(
  GET_ALL_TASKS,
  props<{ payload: TasksListDto }>()
);

export const GET_ALL_TASKS_SUCCESS = '[TASKS] Get All Tasks Success';
export const GetAllTasksSuccessAction = createAction(
  GET_ALL_TASKS_SUCCESS,
  props<{ payload: Task[] }>()
);

export const GET_ALL_TASKS_FAIL = '[TASKS] Failed To Get All Tasks';
export const GetAllTasksFailAction = createAction(
  GET_ALL_TASKS_FAIL,
  props<{ payload: string }>()
);



const all = union({
  SetActiveTasksStatusAction,
  GetAllTasksAction,
  GetAllTasksSuccessAction,
  GetAllTasksFailAction,
})

export type TasksActions = typeof all;