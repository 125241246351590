export enum placeholder {
  drag_drop_hint = 'placeholder.drag_drop_hint',
  past_screenshots_hint = 'placeholder.past_screenshots_hint',
  write_your_reply_here = 'placeholder.write_your_reply_here',
  type_your_email = 'placeholder.type_your_email',
  type_your_password = 'placeholder.type_your_password',
  create_your_password = 'placeholder.create_your_password',
  type_your_full_name = 'placeholder.type_your_full_name',
  type_your_business_name = 'placeholder.type_your_business_name',
  enter_number_or_name = 'placeholder.enter_number_or_name',
  note_seen_by_you_team = 'placeholder.note_seen_by_you_team',
  write_your_message_here = 'placeholder.write_your_message_here',
  promo_code_placeholder = 'placeholder.promo_code_placeholder',
  date_of_birth = 'placeholder.date_of_birth',
  enter_address = 'placeholder.enter_address',
  enter_date = 'placeholder.enter_date',
  enter_email = 'placeholder.enter_email',
  enter_name = 'placeholder.enter_name',
  enter_number = 'placeholder.enter_number',
  enter_phone_number = 'placeholder.enter_phone_number',
  enter_text = 'placeholder.enter_text',
  enter_url = 'placeholder.enter_url',
  phone_number = 'placeholder.phone_number',
  set_name = 'placeholder.set_name',
  type_the_contact_address = 'placeholder.type_the_contact_address',
  type_the_contact_email = 'placeholder.type_the_contact_email',
  type_the_first_name = 'placeholder.type_the_first_name',
  type_the_last_name = 'placeholder.type_the_last_name',
  task_description_placeholder = 'placeholder.task_description_placeholder',
  task_title_placeholder = 'placeholder.task_title_placeholder',
  team_search_placeholder = 'placeholder.team_search_placeholder',
  set_channel_name = 'placeholder.set_channel_name',
  attendee_name = 'placeholder.attendee_name',
  attendee_email = 'placeholder.attendee_email',
  add_title = 'placeholder.add_title',
  user_email_here = 'placeholder.user_email_here',
  add_password = 'placeholder.add_password',
  type_description = 'placeholder.type_description',
  type_tag = 'placeholder.type_tag',
  type_your_comment_here = 'placeholder.type_your_comment_here',
  add_description = 'placeholder.add_description',
  type_to_mention_teammate = 'placeholder.type_to_mention_teammate',
  type_your_message_here = 'placeholder.type_your_message_here',
  type_first_name = 'placeholder.type_first_name',
  type_address = 'placeholder.type_address',
  type_email = 'placeholder.type_email',
  type_last_name = 'placeholder.type_last_name',
  snippet_title = 'placeholder.snippet_title',
  snippet_content = 'placeholder.snippet_content',
  search_in_messages_contacts_tasks_and_more = 'placeholder.search_in_messages_contacts_tasks_and_more',
  search_in = 'placeholder.search_in',
  is_there_anything_you_like_to_talk_about = 'placeholder.is_there_anything_you_like_to_talk_about',
  create_note_in_the_thread = "Add Feedback"
}
