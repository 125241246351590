<div class="flex flex-col gap-3">
  <label *ngIf="label" [for]="label" class="font-semibold">{{ label }}</label>
  <div
    class="flex flew-row border-2 items-center focus-within:border-primary bg-gray-100 pl-3 rounded-lg h-11 border-gray-200"
    [class.border-red-500]="invalid ||!isValid"
    [class.focus-within:border-red-500]="!isValid"
  >
    <ng-content></ng-content>
    <input
      [type]="type"
      [placeholder]="placeholder"
      (focus)="onFocus()"
      [id]="label"
      [(ngModel)]="value"
      [disabled]="disabled"
      (keyup.enter)="enter.emit()"
      (input)="onInputChange($event)"
      class="ring-transparent border-none fill-transparent bg-transparent w-full focus:border-transparent focus:ring-transparent"
      [class]="classes"
    />
  </div>
</div>
