import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { Hotkey, HotkeysHelpComponent, HotkeysService as NgneatHotkeysService } from '@ngneat/hotkeys';
import { Subject } from 'rxjs';
import { PlatformService } from '../platform/platform.service';

@Injectable({
  providedIn: 'root'
})
export class HotkeysService {
  onSubmit$ = new Subject<any>()   // Ctrl + Enter
  ref: MatDialogRef<HotkeysHelpComponent, any>
  constructor(
    private hotkeysService: NgneatHotkeysService,
    private dialog: MatDialog,
    private platformService: PlatformService
  ) { 
    this.hotkeysService.registerHelpModal(() => {
      if (this.ref?.getState() == MatDialogState.OPEN) {
        this.ref.close()
      } else {
        this.ref = this.dialog.open(HotkeysHelpComponent, { width: '500px' });
        this.ref.componentInstance.dimiss.subscribe(() => this.ref.close());
      }
    });
  } 

  hotkeys = {
    submit: 'meta.enter',
    nextRecentInteraction: 'K',
    prevRecentInteraction: 'J',
    openEmoji: ';',
    switchToMessageReply: 'R',
    switchToinlIneNote: 'N',
    switchToInlineTask: 'T',
    toggleDetailsSide: this.platformService.isMac ? '0' : ')',
    toggleSideNav: this.platformService.isMac ? '9' : '('
  }

  addHotkey (options: Hotkey) {
    return this.hotkeysService.addShortcut(options)
  }
  
  removeHotkeys (hotkeys: string[]) {
    return this.hotkeysService.removeShortcuts(hotkeys)   
  }
}

