import { NgModule, Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'translateCut' })
export class TranslateCutPipe implements PipeTransform {
  transform(value: string): {
    type: 'text' | 'section',
    content: string
  }[] {
    const splitter = /(\(|\))/
    const aa =  value.split(splitter)
      .filter(content => !!content && !['(', ')'].includes(content))
      .map(content => ({
        type: value.includes(`(${ content })`) ? 'section' : 'text',
        content
      }))
      return aa as any
  }
}

@NgModule({
  declarations: [TranslateCutPipe],
  exports: [TranslateCutPipe],
  imports: [],
  providers: [],
})
export class TranslateCutModule {
  constructor() { }
}
