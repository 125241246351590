import { createReducer, on } from '@ngrx/store';
import { RouterNavigationEndAction } from './action';



export interface NavigationState { 
    current_page: string;
    previous_page?: string;
    timestamp: number;
}

export const navigationState: NavigationState = {
  current_page: '',
  timestamp:0
};


export const  navigationReducer = createReducer(
    navigationState,
  on(RouterNavigationEndAction, (state: NavigationState, { payload }) => {
    return {
      ...state,
     current_page: payload.current_page,
     previous_page: payload.previous_page,
     timestamp: payload.timestamp
    };
  }),
);
