import { ILocalStorageService } from '@/app/Data/Adapters/ILocalStorageService';
import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { AppState } from '@/app/State/AppState';
import { SocketConnectAction } from '@/app/State/Socket/action';
import { GetVoiceClientTokenAction } from '@/app/State/VoiceClient/voice-client.action';
import { PreInitializeMainApplicationAction } from '@/app/State/app/action';
import { COMPACT_MODE, DARK_MODE, TOKEN_KEY } from '@/app/Utilities/TokenKeys';
import { PlatformService } from '@/app/Utilities/platform/platform.service';
import { Injectable, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { IConfiguration } from './IConfig';
import { ConfigContext } from './config.context';
import { ToggleDarkMode } from '@/app/State/mediaObserver/media.observer.action';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private settings: Subject<IConfiguration> = new ReplaySubject<IConfiguration>(
    1
  );
  public readonly settings$: Observable<IConfiguration> =
    this.settings.asObservable();

  constructor(
    @Optional() ctx: ConfigContext,
    private localStorageService: ILocalStorageService,
    private platformService: PlatformService,
    private databaseService: DatabaseService,
    private store$: Store<AppState>
  ) {
    if (ctx) {
      this.settings.next(ctx.config);
    }
  }

  initApp(): void {
    this.connectSocket();
    this.connectVoiceClient();
    this.preInitMainApp()
  }

  connectSocket(): void {
    const token: string = this.localStorageService.getItem(TOKEN_KEY) || '';
    const device = this.platformService.device;
    this.store$.dispatch(SocketConnectAction({ payload: { token, device } }));
  }

  connectVoiceClient(): void {
    this.store$.dispatch(GetVoiceClientTokenAction());
  }

  initializeDB(): Promise<any> {
    return this.databaseService.init();
  }

  preInitMainApp(): void {
    this.store$.dispatch(PreInitializeMainApplicationAction());
    const dark = this.localStorageService.getItem(DARK_MODE)
    dark && this.store$.dispatch(ToggleDarkMode({ payload: true }))
    // const compact = this.localStorageService.getItem(COMPACT_MODE)
    // compact && this.store$.dispatch(ToggleCompactMode({ payload: true }))
  }
}
