import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';

@Pipe({
  name: 'phone',
})
export class PhoneNumberFormatterPipe implements PipeTransform {
  transform(phoneText: string | undefined, ...args: unknown[]): string {
    if (!phoneText) return '';
    const code: CountryCode = args.length > 0 ? args[0] as CountryCode : 'US';
    const stringPhone = phoneText + '';

    let phoneNumber;
    try {
      phoneNumber = parsePhoneNumberFromString(stringPhone);
      const phoneCode = phoneNumber?.country || code;
      if (phoneCode !== code) {
        if (phoneNumber?.isValid()) {
          return phoneNumber?.formatInternational();
        }
      } else {
        return phoneNumber?.formatNational() ?? phoneText;
      }
    } catch (e) {
      try {
        if (code) {
          phoneNumber = parsePhoneNumberFromString(stringPhone, code);
          if (phoneNumber?.isValid()) {
            return phoneNumber?.formatNational();
          }
        }
      } catch (e) {}
    }
    return phoneText;
  }
}

import { NgModule } from '@angular/core';
@NgModule({
  declarations: [PhoneNumberFormatterPipe],
  exports: [PhoneNumberFormatterPipe],
  imports: [],
  providers: [],
})
export class PhoneFormatterPipeModule {
  constructor() {}
}
