import { ILocalStorageService } from '@/app/Data/Adapters/ILocalStorageService';
import { CallRequestDto } from '@/app/Data/DTO/CallRequestDto';
import { VoiceClientDropMemberParticipantDto } from '@/app/Data/DTO/VoiceClient/VoiceClientDropUserDto';
import { VoiceClientHoldContactDto } from '@/app/Data/DTO/VoiceClient/VoiceClientHoldContactDto';
import { VoiceClientSetUserOnHoldDto } from '@/app/Data/DTO/VoiceClient/VoiceClientSetUserOnHoldDto';
import { PhoneState, VoiceClientService } from '@/app/Data/twilio/voice-client.service';
import { AppState } from '@/app/State/AppState';
import { selectTenantProfile } from '@/app/State/Tenant/tenant.selector';
import { VoiceClientConferenceMemberHangUpAction, VoiceClientConferenceMemberOnHoldAction, VoiceClientMakeMergeCallAction, VoiceClientMakeTransferCallAction, VoiceClientRequestCallTransferAction, VoiceClientUpdateActiveCallerAction } from '@/app/State/VoiceClient/voice-client-transfer.action';
import { selectVoiceClientState } from '@/app/State/VoiceClient/voice-client..selector';
import { MakeVoiceRequestCallAction, VoiceClientCustomerHoldAction } from '@/app/State/VoiceClient/voice-client.action';
import { PRIMARY_PHONE_CHANNEl } from '@/app/Utilities/TokenKeys';
import { Localizations } from '@/app/Utilities/localization';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';
import { ITenantRepository } from '@/app/core/IRepositories/ITenantRepository';
import { InternalChannel } from '@/app/core/Models/Channel';
import { ChannelType } from '@/app/core/Models/ChannelTypes';
import { TeamMember } from '@/app/core/Models/TeamMember';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as phoneFormatter from 'libphonenumber-js';
import { distinctUntilChanged, firstValueFrom, map, of, timer } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VoiceClientViewModel {
  profile$ = this.store.select(selectTenantProfile);
  voiceClientState$ = this.store.select(selectVoiceClientState).pipe(
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  callTimer$ = this.voiceClientState$.pipe(
    map(state => state.device.state === PhoneState.LIVE ?? state.callSession?.live ? 1 : 0),
    distinctUntilChanged(),
    switchMap((isLive) => isLive ? timer(1000, 1000) : of()),
    map((seconds) => ({
      seconds: seconds % 60,
      minutes: Math.floor(seconds / 60),
      hours: Math.floor(seconds / 3600),
    })),
    map(({ hours, minutes, seconds }) => [
      hours ? hours.toString().padStart(2, '0') : '',
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0'),
    ].filter((v) => !!v).join(':')),
    shareReplay({ refCount: true, bufferSize: 1 })
  )
  phoneAdded$ = this.tenantRepository.getLocalInternalChannels().pipe(
    distinctUntilChanged(),
    map((channels) =>
      channels.some((item) => item.channel_type == ChannelType.Phone)
    )
  );
  primaryPhoneChannel: InternalChannel | undefined

  constructor(
    private tenantRepository: ITenantRepository,
    private store: Store<AppState>,
    private storageService: ILocalStorageService,
    private snackBarService: SnackbarService,
    private voiceClientService: VoiceClientService,
    private snackbarService: SnackbarService
  ) { }

  makeCall(
    inputNumber: string,
    channel: string,
    displayName?: string,
    avatar?: string
  ): void {
    if (this.voiceClientService.device?.isBusy) {
      this.snackbarService.openAlert({ message: 'You are already on another call', duration: 4000, type: 'info' })
      return;
    }
    if (['911', '999', '112', '933'].includes(inputNumber)) {
      this.snackbarService.openAlert({ message: `For now, you cannot call emergency numbers using Yobi. Please use your personal number for this call.`, duration: 10000, type: 'info' })
      return;
    }
    if(![...this.availableInputDevices.keys()].find(x => !!x)) {
      this.snackBarService.openAlert({ message: 'Make sure you have a connected microphone and granted access.', type: 'info', duration: 6000 })
    }
    let phone;
    let parsedPhoneNumber;
    try {
      parsedPhoneNumber = phoneFormatter.parsePhoneNumber(inputNumber);
      phone = parsedPhoneNumber.number;
    } catch (e) {
      phone = phoneFormatter.formatNumber(inputNumber, 'US', 'E.164');
      if (phone) parsedPhoneNumber = phoneFormatter.parsePhoneNumber(phone);
    }
    if (!parsedPhoneNumber) {
      return;
    }
    const params: CallRequestDto['params'] = {
      To: phone,
      to: phone,
      callerId: channel,
      intent: 'dial',
    };
    const entity: CallRequestDto['entity'] = {
      channelValue: phone,
      displayName: displayName ?? '',
      avatar: avatar ?? undefined,
    };
    this.store.dispatch(
      MakeVoiceRequestCallAction({ payload: { params, entity } })
    );
  }

  addPhoneChannelAlert() {
    this.snackBarService.openAlert({
      message: Localizations.alert_dialog.add_phone_channel,
      type: 'info',
    })
  }

  /* ----------------------- active call dialer actions ----------------------- */

  accept(parentCallSid: string) {
    this.voiceClientService.accept(parentCallSid);
  }

  async holdCallParticipant(parentCallSid: string) {
    const callSession = await this.getCallSessionByParentCallSid(parentCallSid)
    const activeCaller = callSession?.conference?.participants.find(participant => !!participant.activeCaller)
    switch (activeCaller?.type) {
      case 'host':
      case 'member':
        this.store.dispatch(VoiceClientConferenceMemberOnHoldAction({
          payload: new VoiceClientSetUserOnHoldDto(
            parentCallSid!,
            activeCaller!.user_id!, // be sure userid is not null
            activeCaller.status !== 'hold'
          )
        }));
        break;
      case 'customer':
        this.store.dispatch(VoiceClientCustomerHoldAction({
          payload: new VoiceClientHoldContactDto(
            parentCallSid!,
            activeCaller.status !== 'hold'
          )
        }));
        break;
    }
  }

  async hangup(parentCallSid: string) {
    const callSession = await this.getCallSessionByParentCallSid(parentCallSid)
    const activeCaller = callSession?.conference?.participants.find(participant => participant.activeCaller)
    !callSession!.live
      ? this.voiceClientService.reject(parentCallSid)
      : activeCaller?.type === 'member'
        ? this.store.dispatch(
          VoiceClientConferenceMemberHangUpAction({
            payload: new VoiceClientDropMemberParticipantDto(parentCallSid!, activeCaller.user_id!)
          })
        )
        : this.voiceClientService.hangup(parentCallSid);
  }

  toggleMute(parentCallSid: string) {
    this.voiceClientService.toggleMute(parentCallSid);
  }

  mergeCall(parentCallSid: string) {
    this.store.dispatch(VoiceClientMakeMergeCallAction({
      payload: {
        call_sid: parentCallSid!,
      },
    }));
  }

  async transferCall(parentCallSid: string) {
    const callSession = await this.getCallSessionByParentCallSid(parentCallSid)
    this.store.dispatch(
      VoiceClientMakeTransferCallAction({
        payload: {
          call_sid: parentCallSid,
          user_id: callSession!.conference!.participants.find(participant => participant.type === 'member')!.user_id!,
          channel_value: callSession!.from!,
        },
      })
    );
  }

  sendDigit(parentCallSid: string, digit: string): void {
    this.voiceClientService.sendDigit(parentCallSid, digit);
  }

  async transferToUser(parentCallSid: string, user: TeamMember): Promise<any> {
    const callSession = await this.getCallSessionByParentCallSid(parentCallSid)
    const profile = await firstValueFrom(this.profile$);
    this.store.dispatch(VoiceClientRequestCallTransferAction({
      payload: {
        call_sid: parentCallSid,
        channel_value: callSession!.from!,
        user_id: user.user_id!.toString(),
        user_name: profile!.username,
      }
    }));
  }

  selectActiveMember(parentCallSid: string, id: string) {
    this.store.dispatch(VoiceClientUpdateActiveCallerAction(({ payload: { id, parentCallSid } })))
  }

  ignore(parentCallSid: string): void {
    this.voiceClientService.ignore(parentCallSid);
  }

  /* ----------------------- active call dialer settings ---------------------- */

  getCallSessionByParentCallSid = (parentCallSid: string) =>
    firstValueFrom(this.voiceClientState$.pipe(
      map(state => state.callSession))
    )

  setAudioInputDevice(deviceId: string) {
    this.voiceClientService.setAudioInputDevice(deviceId)
  }

  setAudioOutputDevice(deviceId: string) {
    this.voiceClientService.setAudioOutputDevice(deviceId)
  }

  testOutputSpeakerDevice(): Promise<any> {
    return this.voiceClientService.device?.audio?.ringtoneDevices
      ?.test('assets/audio/ding.mp3')
      .catch((e) => console.error(e))!;
  }

  get activeInputDevices(): MediaDeviceInfo | null | undefined {
    return this.voiceClientService.device?.audio?.inputDevice
  }

  get activeOutputDevices(): MediaDeviceInfo | null | undefined {
    return Array.from((this.voiceClientService.device?.audio?.speakerDevices as any)._activeDevices ?? [])[0] as MediaDeviceInfo | null | undefined
  }

  get availableInputDevices(): Map<string, MediaDeviceInfo> {
    return this.voiceClientService.device.audio?.availableInputDevices!;
  }

  get availableOutputDevices(): Map<string, MediaDeviceInfo> {
    return this.voiceClientService.device.audio?.availableOutputDevices!;
  }

  getPrimaryChannel = (channels: InternalChannel[]): InternalChannel | undefined => {
    const storedChannelId = this.storageService.getItem(PRIMARY_PHONE_CHANNEl)
    const channel = channels.find(channel => !!storedChannelId && channel.channel_id == storedChannelId)
    return channel || [...channels]?.shift()
  }

  setPrimaryChannel = (channel: InternalChannel): void => {
    this.storageService.setItem(PRIMARY_PHONE_CHANNEl, channel.channel_id)
    this.primaryPhoneChannel = channel;
  }
}