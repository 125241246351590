import { IsTypingEventDto } from '@/app/Data/DTO/isTypingEventDto';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { RecentInteractionsFilter } from '@/app/core/Models/RecentInteractionsFilter';
import { Observable } from 'rxjs';
import {
  RecentInteraction,
  RecentMessagesQuery
} from '../Models/Interaction';
import { RecentEmailInteraction } from '../Models/email-interaction';
export abstract class IMessagesRepository {

  abstract getRecentMessages(
    query: RecentMessagesQuery
  ): Observable<RecentInteraction[]>;

  abstract getLocalRecentMessages(data: {
    filter: RecentInteractionsFilter,
    inboxId?: number,
    page?: number,
    page_size?: number
  }): Observable<RecentInteraction[]> | Promise<RecentInteraction[]>;
  abstract saveRecentMessages(
    data: RecentInteraction[],
    query: RecentMessagesQuery
  ): void;
  abstract addRecentMessage(data: RecentInteraction): void;
  abstract findRecentMessage(
    id: number
  ): Observable<RecentInteraction | undefined>;

  abstract findRecentInteractionByContactId(
    contactId: number
  ): Observable<RecentInteraction | undefined>;
  abstract findRecentInteractionByUnclassifiedChannelId(
    unclassifiedChannelId: number
  ): Observable<RecentInteraction | undefined>;

  abstract deleteLocalRecentInteractionByContactId(
    contactId: number
  ): void;


  abstract deleteLocalRecentInteractionByUnclassifiedChannelId(
    channelId: number
  ): void;

  abstract sendIsTypingEvent(request: IsTypingEventDto): Observable<SuccessResponse>
}
