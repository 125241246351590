<div
  class="p-2 py-4 flex flex-row items-center cursor-pointer gap-1"
  matRipple
>
  <div class="flex items-center gap-3 w-full">
    <app-avatar
      class="h-10 w-10 shrink-0"
      [data]="notification.triggered_by"
      [url]="notification.triggered_by.avatar"
      [name]="displayName"
      [fontSize]="12"
    ></app-avatar>

    <div class="flex flex-col gap-1 flex-1 min-w-0">
      <div class="flex items-center justify-between  w-full">
        <p class="text-base">
          {{ displayName }}
        </p>
        <div
          *ngIf="!notification.read"
          class="h-3 w-3 bg-warning rounded-full border"
        ></div>
      </div>
      <div class="flex items-center justify-between w-full gap-4">
        <div class="flex gap-2 overflow-hidden">
          <svg-icon
            [svgStyle]="{ 'height.px': 15, 'width.px': 14 }"
            [src]="icon"
          ></svg-icon>
          <p class="text-sm truncate">
            {{ description }}
          </p>
        </div>
        <p class="text-sm whitespace-nowrap font-light">
          {{ notification.created_dt | shortDate }}
        </p>
      </div>
    </div>
  </div>
</div>