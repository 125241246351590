import { LocalizationModel } from '@/app/Utilities/localization/localization.model';
import { AppLang } from '@/app/core/Models/lang';
import { ILocalStorageService } from '@/app/Data/Adapters/ILocalStorageService';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first, map, Observable, startWith, switchMap, tap } from 'rxjs';
import { APP_LANG } from '../TokenKeys';


@Injectable({
	providedIn: 'root'
})
export class AppTranslateService {

	// grab the current lang from local storage
	default = AppLang.EN
	constructor(
		private translateService: TranslateService,
		private localSorageService: ILocalStorageService
		) { }

	init() {
		const lang = this.currentLang
		// this language will be used as a fallback when a translation isn't found in the current language
		this.translateService.setDefaultLang(this.default);
		this.useLang(lang)
	}

	changeLang(lang: AppLang) {
		if (lang !== this.currentLang) {
			this.useLang(lang)
		}
	}

	useLang(lang: AppLang) {
		this.translateService.use(lang);
		this.localSorageService.setItem(APP_LANG, lang);
	}

	getTranslation<T = any>(key: string): Observable<T> {
		return this.translateService.onLangChange.pipe(
			startWith(''),
		switchMap(() => this.translateService.get(key).pipe(first()))
		)
	}

	get currentLang(): AppLang {
		const lang = this.localSorageService.getItem(APP_LANG) as AppLang;
		return lang && Object.values(AppLang).includes(lang)
			? lang
			: this.default;
	}
}