import { Injectable } from '@angular/core';
import { Observable, of, tap, throwError } from 'rxjs';
import { UseCase } from '../UseCase';
import { Notification } from '@/app/core/Models/Notification';
import { INotificationRepository } from '@/app/core/IRepositories/INotificationRepository';
import { NotificationsSettingDto } from '@/app/Data/DTO/NotificationsSettingDto';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';

@Injectable({
  providedIn: 'root',
})
export class UpdateNotificationsSettingUseCase
  implements UseCase<NotificationsSettingDto, SuccessResponse>
{
  constructor(private notificationRepository: INotificationRepository) {}

  execute(settings: NotificationsSettingDto): Observable<SuccessResponse> {
    return this.notificationRepository.updateNotificationsSetting(settings);
  }
}
