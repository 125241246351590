export enum not_found {
  contacts = 'not_found.contacts',
  interactions = 'not_found.interactions',
  notes = 'not_found.notes',
  note = 'not_found.note',
  tags = 'not_found.tags',
  file = 'not_found.file',
  tasks = 'not_found.tasks',
  no_more_messages = 'not_found.no_more_messages',
  page_not_found = 'not_found.page_not_found',
  no_available_tasks = 'not_found.no_available_tasks',
  sentiment_not_found = 'not_found.sentiment_not_found',
  no_has_been_created = 'not_found.no_has_been_created',
  snippets = 'not_found.snippets',
  notifications = 'not_found.notifications',
  no_linked_connection = 'not_found.no_linked_connection',
}