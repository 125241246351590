import { BillingSubscription, PaymentMethod, PromoCode } from '@/app/core/Models/billing';
import { BillingInvoiceDto, CancelBillingSubscriptionDTO } from '@/app/Data/DTO/BillingDto';
import { BillingPlanDto } from '@/app/Data/DTO/BillingPlanDto';
import { BillingUpgradePromptDto } from '@/app/Data/DTO/BillingUpgradePromptDto';
import { CreditCardDto } from '@/app/Data/DTO/CriditCardDto';
import { createAction, props, union } from '@ngrx/store';
import { StripeCardComponent } from 'ngx-stripe';

export const FETCH_ACTIVE_SUBSCRIPTION = '[BILLING] Fetch active subscription';
export const FetchActiveSubscriptionAction = createAction(
  FETCH_ACTIVE_SUBSCRIPTION,
);

export const FETCH_BILLING_PLANS = '[BILLING] Fetch billing plans';
export const FetchBillingPlansAction = createAction(
  FETCH_BILLING_PLANS,
);

export const FETCH_BILLING_PLANS_SUCCESS = '[BILLING] Fetch billing plans success';
export const FetchBillingPlansSuccessAction = createAction(
  FETCH_BILLING_PLANS_SUCCESS,
  props<{ payload: { plans: BillingPlanDto[] } }>()
);

export const FETCH_BILLING_PLANS_FAIL = '[BILLING] Fetch billing plans fail';
export const FetchBillingPlansFailAction = createAction(
  FETCH_BILLING_PLANS_FAIL
);

export const GET_ACTIVE_PROMOTION_CODES = '[BILLING] Get active promotion codes';
export const GetActivePromotionCodesAction = createAction(
  GET_ACTIVE_PROMOTION_CODES,
);

export const GET_ACTIVE_PROMOTION_CODES_SUCCESS = '[BILLING] Get active promotion codes success';
export const GetActivePromotionCodesSuccessAction = createAction(
  GET_ACTIVE_PROMOTION_CODES_SUCCESS,
  props<{ payload: { promoCodes: PromoCode[] } }>()
);

export const APPLY_PROMO_CODE = '[BILLING] Apply promo code';
export const ApplyPromoCodeAction = createAction(
  APPLY_PROMO_CODE,
  props<{ payload: { code: string } }>()
);

export const APPLY_PROMO_CODE_SUCCESS = '[BILLING] Apply promo code Success';
export const ApplyPromoCodeSuccessAction = createAction(
  APPLY_PROMO_CODE_SUCCESS,
  props<{ payload: { plans: BillingPlanDto[] } }>()
);

export const APPLY_PROMO_CODE_FAIL = '[BILLING] Apply promo code fail';
export const ApplyPromoCodeFailAction = createAction(
  APPLY_PROMO_CODE_FAIL
);


export const DELETE_PROMO_CODE = '[BILLING] Delete promo code';
export const DeletePromoCodeAction = createAction(
  DELETE_PROMO_CODE,
  props<{ payload: { codeId: number } }>()
);


export const DELETE_PROMO_CODE_SUCCESS = '[BILLING] Delete promo code success';
export const DeletePromoCodeSuccessAction = createAction(
  DELETE_PROMO_CODE_SUCCESS,
  props<{ payload: { plans: BillingPlanDto[] } }>()
);

export const FETCH_BILLING_INVOICES = '[BILLING] Fetch biling invoices';
export const FetchBillingInvoicesAction = createAction(
  FETCH_BILLING_INVOICES,
);

export const GET_SUBSCRIPTION_ESTIMATION = '[BILLING] Get subscription estimation action';
export const GetSubscriptionEstimationAction = createAction(
  GET_SUBSCRIPTION_ESTIMATION,
  props<{ payload: number }>()
);

export const FETCH_CURRENT_PAYMENT_METHOD = '[BILLING] Fetch current payment methode';
export const FetchCurrentPaymentMethodeAction = createAction(
  FETCH_CURRENT_PAYMENT_METHOD,
);

export const DELETE_CURRENT_PAYMENT_METHOD = '[BILLING] Delete current payment methode';
export const DeleteCurrentPaymentMethodeAction = createAction(
  DELETE_CURRENT_PAYMENT_METHOD,
);
export const DELETE_CURRENT_PAYMENT_METHOD_SUCCESS = '[BILLING] Delete current payment methode Success';
export const DeleteCurrentPaymentMethodeSuccessAction = createAction(
  DELETE_CURRENT_PAYMENT_METHOD_SUCCESS,
);

export const FETCH_ACTIVE_SUBSCRIPTION_SUCCESS = '[BILLING] Fetch active subscription success';
export const FetchActiveSubscriptionSuccessAction = createAction(
  FETCH_ACTIVE_SUBSCRIPTION_SUCCESS,
  props<{ payload: BillingSubscription }>()
);

export const FETCH_BILLING_INVOICES_SUCESS = '[BILLING] Fetch biling invoices success';
export const FetchBillingInvoicesSuccessAction = createAction(
  FETCH_BILLING_INVOICES_SUCESS,
  props<{ payload: BillingInvoiceDto[] }>()
);

export const OPEN_BILLING_PROMPT = '[BILLING] Open Billing Prompt';
export const OpenBillingPromptAction = createAction(
  OPEN_BILLING_PROMPT,
);

export const INTERACT_WITH_BILLING_PROMPT = '[BILLING] interact with billing prompt';
export const InteractWithBillingPromptAction = createAction(
  INTERACT_WITH_BILLING_PROMPT,
  props<{ payload: BillingUpgradePromptDto }>()
);

export const GET_BILLING_PROMPT_STATUS = '[BILLING] get billing Prompt Status';
export const GetBillingPromptStatusAction = createAction(
  GET_BILLING_PROMPT_STATUS,
);

export const FETCH_CURRENT_PAYMENT_METHOD_SUCCESS = '[BILLING] Fetch current payment methode success';
export const FetchCurrentPaymentMethodeSuccessAction = createAction(
  FETCH_CURRENT_PAYMENT_METHOD_SUCCESS,
  props<{ payload: PaymentMethod }>()
);


export const REACTIVE_BILLING_SUBSCRIPTION = '[BILLING] Reactive billing subscription action';
export const ReactiveBillingSubscriptionAction = createAction(
  REACTIVE_BILLING_SUBSCRIPTION,
);

export const CANCEL_BILLING_SUBSCRIPTION = '[BILLING] Cancel billing subscription action';
export const CancelBillingSubscriptionAction = createAction(
  CANCEL_BILLING_SUBSCRIPTION,
  props<{ payload: CancelBillingSubscriptionDTO }>()
);

const all = union({
  FetchActiveSubscriptionAction,
  FetchBillingPlansAction,
  FetchBillingPlansSuccessAction,
  ApplyPromoCodeAction,
  DeletePromoCodeAction,
  FetchBillingInvoicesAction,
  GetSubscriptionEstimationAction,
  FetchCurrentPaymentMethodeAction,
  DeleteCurrentPaymentMethodeAction,
  DeleteCurrentPaymentMethodeSuccessAction,
  FetchActiveSubscriptionSuccessAction,
  FetchBillingInvoicesSuccessAction,
  FetchCurrentPaymentMethodeSuccessAction,
  ReactiveBillingSubscriptionAction,
  InteractWithBillingPromptAction,
  CancelBillingSubscriptionAction,
})

export type BillingActions = typeof all;

