import { TeamMember } from '@/app/core/Models/TeamMember';
import { InteractionThread, RecentInteraction } from './Interaction';

export class Task {
  reminder_value?: string
  created_dt_timestamp?: number
  assigned_to: number;
  attachments?: any[]
  attachments_count: number;
  comments: number;
  comments_count: number;
  created_by: number;
  created_dt: number;
  description: string;
  task_priority?: string;
  interaction_id: number;
  internal_interaction_id: number;
  status: TaskStatus;
  task_id: number;
  priority: number;
  tenant_id: number;
  title: string;
  updated_dt: string;
  user_assigned: string;
  user_create: string;
  filter: TaskFilter;
  assignedUser?: TeamMember;
  userAvatar?: string;
  assignedToName?: string;
  reminder?: number
  tags?: Array<{
    color: string,
    tag_id: number,
    tag_name: string
  }>
  contact_id?: string
  yobi_crm_company_id?: number
  creator?: {
    avatar: string
    created_dt: string
    do_not_disturb: boolean
    firstName: string
    lastName: string
    updated_dt: string
    username: string
    user_id: string
  }
  assigned_contact?: {
    contact_id: string,
    contact_name: string,
    avatar: string
  }
  assigned_company?: {
    avatar: string
    company_name: string
    yobi_crm_company_id: number
  }
  task_assignees?: Array<{
    avatar: string
    given_name: string
    family_name: string
    user_id: string
  }>
  deadline?: number
}

export interface TaskLog {
  content: string;
  created_dt: string;
  log_id: number;
  task_id: number;
}

export interface TaskComment {
  comment: string;
  comment_id: number;
  created_by: TaskCommentCreator;
  created_dt: string;
  task_id: number;
  updated_dt: string;
  creatorName?: string;
  sending?: boolean;
  failed?: boolean;
}
export interface TaskCommentCreator {
  avatar: string;
  created_dt: string;
  do_not_disturb: boolean;
  family_name: string;
  given_name: string;
  is_record_disabled: boolean;
  middle_name: string | null;
  preferred_name: string | null;
  onboarding: string;
  tenant_id: number;
  updated_dt: string;
  user_id: number;
  username: string;
}
export interface TaskDetailUser {
  avatar: string;
  created_dt: string;
  firstName: string;
  lastName: string;
  tenant_id: number;
  updated_dt: string;
  user_id: number;
  username: string;
}
export interface TaskAttachment {
  attachment_id: number;
  attachment_url: string;
  created_dt: string;
  filename: string;
}

export class TaskDetail {
  reminder_value?: string
  assigned?: TaskDetailUser;
  created_dt_timestamp?: number;
  attachments: TaskAttachment[];
  comments: (TaskComment | TaskLog)[];
  created_dt: string;
  task_priority: string;
  description: string;
  interaction_id: number;
  interaction?: InteractionThread;
  internal_interaction_id: number;
  status: TaskStatus;
  task_id: number;
  inbox_id?: number;
  tenant_id: number;
  title: string;
  updated_dt: string;
  reminder?: number;
  get body() {
    try {
      return JSON.parse(this.description) as ({ type: 'text', text: string } | { type: 'mention', user: string, id: number })[]
    } catch {
      return null
    }
  }
  get _reminder() {
    return this.reminder && (this.reminder * 1000) > new Date().getTime() ? new Date(this.reminder * 1000) : null
  }
  tags?: Array<{
    color: string,
    tag_id: number,
    tag_name: string
  }>
  contact_id?: string
  yobi_crm_company_id?: number
  creator?: {
    avatar: string
    created_dt: string
    do_not_disturb: boolean
    firstName: string
    lastName: string
    updated_dt: string
    username: string
    user_id: string
  }
  assigned_company?: {
    avatar: string
    company_name: string
    yobi_crm_company_id: number
  }
  assigned_contact?: {
    contact_id: string,
    contact_name: string,
    avatar: string
  }
  task_assignees?: Array<{
    avatar: string
    given_name: string
    family_name: string
    user_id: string
  }>
  deadline?: number
}

export enum TaskStatus {
  open = 'open',
  inProgress = 'in-progress',
  done = 'done',
  archived = 'trash',
}

export enum TaskFilter {
  All = 'all',
  Mine = 'mine',
}
