<div class="flex flex-col " >
    <div class="flex bg-white flex-row sticky top-0 p-2 justify-end items-center">
        <app-button (click)="close()" color="light">
            <div><b>Close</b></div>
        </app-button>
    </div>
    <div class="flex flex-1 flex-col p-10">
        <ng-container *ngIf="data?.isTOF">
            <h2>Terms Of Service</h2>
            <div style="width: 100%;">
                <div id="post-404" class="post-404 page type-page status-publish hentry">
                    <div class="post-content"><p>These Terms of Service of Yobi, a service provided by YiB, Inc., govern your
                        access
                        to and use of the Platform. These Terms constitute a binding agreement between Yobi and you or any
                        company
                        that you represent (“Agreement”). By accessing or using the Platform as described herein, you accept and
                        agree to these Terms. If you do not accept and agree to these Terms, you are not permitted to use the
                        Platform.</p>
                        <p>Yobi reserves the right, in its sole and absolute discretion, to amend, supplement, or otherwise
                            modify
                            these Terms from time to time. Modified Terms will take effect immediately upon posting to Yobi’s
                            website. Your continued use of the Platform after modified Terms have been so posted shall
                            constitute
                            your acceptance of such modified Terms.</p>
                        <ol>
                            <li><strong>Definitions.</strong> The following terms shall have the meanings ascribed to them here.
                            </li>
                        </ol>
                        <ul>
                            <li><strong>“Account”</strong>means the account associated with your email address.</li>
                            <li><strong>“Yobi Services”</strong>means those services provided by Yobi, including without
                                limitation:
                                (i) access to all websites branded or labeled by Yobi; (ii) telephone services; (iii) call
                                tracking
                                technology; (iv) text message capability; and (v) reporting software.
                            </li>
                            <li><strong>“User Content”</strong> means data and other information made available to and
                                aggregated by
                                Yobi through the use of Yobi Services including: (i) call records; (ii) audio recordings; (iii)
                                recording transcriptions; (iv) SMS records; and (v) SMS message content.
                            </li>
                            <li><strong>“Intellectual Property Rights”</strong>means any and all intellectual property rights
                                existing worldwide and the subject matter of such rights, including: (a) patents, copyrights,
                                trademarks, and any right to have confidential information kept confidential; and (b) any
                                application or right to apply for registration of any of the foregoing rights.
                            </li>
                            <li><strong>“Platform”</strong>means the website operated by Yobi and available at Yib.io, Yobi’s
                                mobile
                                applications for iOS and Android, together with any related Yobi service, tool, application or
                                services.
                            </li>
                            <li><strong>“Caller” </strong>means a person whose telephone call is connected to Yobi on your
                                behalf.
                            </li>
                            <li><strong>“Terms” </strong>or <strong>“Agreement”</strong> means these Terms of Use.</li>
                            <li><strong>“Yobi”</strong>,&nbsp;<strong>“we”</strong>,&nbsp;<strong>“our”</strong>,&nbsp;<strong>“company”</strong>or&nbsp;<strong>“the
                                company”</strong>&nbsp;or&nbsp;<strong>“us”</strong>&nbsp;means YiB, Inc., a California
                                corporation
                                having an address at 75 E Santa Clara St,#600 San Jose Ca 95113.
                            </li>
                            <li><strong>“User”, “you,”</strong>or&nbsp;<strong>“your”</strong>&nbsp;means an individual who
                                opens an
                                Account and uses the Platform or Yobi Services.
                            </li>
                        </ul>
                        <p><strong>&nbsp; &nbsp; 2. Scope</strong></p>
                        <p>Before using the Platform or Services, you must read and accept all of the terms in this Agreement.
                            By
                            accepting this Agreement as you access our Platform or Services, you agree that this Agreement will
                            apply whenever you use the Platform or Services.</p>
                        <ol start="3">
                            <li><h3>Eligibility</h3></li>
                        </ol>
                        <p>You must not use the Platform if you:</p>
                        <ul>
                            <li>are not able to form legally binding contracts;</li>
                            <li>a person barred from receiving services similar to Yobi under any applicable laws;</li>
                            <li>are suspended from using the Platform or Services; or</li>
                            <li>do not hold a valid email address.</li>
                        </ul>
                        <p>When you sign up on the Platform to access Yobi Services, you associate an email address with your
                            Account. You must not share Account credentials with others. The individual User associated with
                            each
                            Account shall be held responsible for all actions taken by or through the Account.</p>
                        <p>Users may provide a business or company name to be associated with the User’s Account. Users
                            acknowledge
                            and agree that where a business or company name is associated with their Account, this Agreement is
                            a
                            contract with the User as an individual, as well as the with the business or company associated with
                            the
                            User’s Account.</p>
                        <p>We may, at our absolute discretion, refuse to register any person or entity as a User.</p>
                        <p>Your Account, including any rights or obligations you have under this Agreement cannot be transferred
                            or
                            assigned to any third party without our prior written consent.</p>
                        <ol start="4">
                            <li><h3>Using Yobi</h3></li>
                        </ol>
                        <p>While using the Platform and Yobi Services, you must not:</p>
                        <ul>
                            <li>infringe any state or federal laws or regulations, third party rights, or any policies that are
                                posted on the Platform;
                            </li>
                            <li>fail to deliver payment for Services delivered to you;</li>
                            <li>circumvent or manipulate our fee structure, the billing process, or fees owed to Yobi;</li>
                            <li>transfer, sell, or rent your Yobi Account or Username to another party without our consent;</li>
                            <li>distribute viruses, harmful code, phishing scams, or any other content or technologies, or
                                otherwise
                                take any other actions, that do or are intended to harm Yobi, the Platform, or any Users or
                                third
                                parties, or their interests or property (including their Intellectual Property Rights, privacy
                                rights, and publicity rights);
                            </li>
                            <li>“frame,” “mirror,” or otherwise incorporate any part of the Platform into any other website,
                                app, or
                                system;
                            </li>
                            <li>modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software
                                programs
                                used by us in connection with the Platform;
                            </li>
                            <li>copy, modify, or distribute content from the Platform or otherwise infringe Yobi’s copyrights,
                                trademarks, or other Intellectual Property Rights; or
                            </li>
                            <li>harvest or otherwise collect information about Users, including email addresses, without their
                                consent;
                            </li>
                            <li>use the Yobi Services or Platform to access or allow access to law enforcement or other
                                emergency
                                services.
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <ol start="5">
                            <li><h3>Fees and Services</h3></li>
                        </ol>
                        <p>Prior to utilizing the Yobi Services, User must pay for the first 30 days of access to Yobi Services
                            and
                            for related items necessary to utilize Yobi Services as stated on our separate Schedule of Fees,
                            which
                            we may modify from time to time and will update by placing on our Platform. We may choose to
                            temporarily
                            change the fees for our services for promotional events (for example, temporary discounts) or new
                            services, and such changes are effective when we post a temporary promotional event or new service
                            on
                            the Platform, or as notified through promotional correspondence, in our sole discretion. Users will
                            also
                            be responsible without limitation for all costs and fees associated with Yobi’s efforts to collect
                            late
                            payments from User.</p>
                        <p>You agree to be charged on a recurring basis for subscription fees in advance of our providing Yobi
                            Services to you. All monthly fees, per minute charges, and fees for premium services shall be
                            deducted
                            from User’s available balance on a monthly basis, and User shall authorize Yobi to replenish User’s
                            account balance as defined on our Schedule of Fees when User’s available balance falls below the
                            allowable default balance. We are not responsible for any additional bank fees, interest charges,
                            finance charges, overdraft charges, or other fees resulting from charges billed by Yobi. Billing
                            disputes must be submitted within 30 days of the applicable billing date or are deemed waived.</p>
                        <ol start="6">
                            <li><h3>Taxes</h3></li>
                        </ol>
                        <p>You are responsible for paying any taxes that accrue to you by your use of the Platform, including
                            without limitation any goods and services or value-added taxes. You acknowledge that you must comply
                            with your obligations under income tax provisions in your jurisdiction, including, without
                            limitation,
                            any and all applicable state and federal taxes.</p>
                        <ol start="7">
                            <li><h3>Promotion</h3></li>
                        </ol>
                        <p>We may display your company or business name, logo, images, and other media as part of the Platform
                            and
                            our marketing materials, except where you have explicitly requested that we do not do this and we
                            have
                            agreed to such a request in writing.</p>
                        <ol start="8">
                            <li><h3>Content</h3></li>
                        </ol>
                        <p>Yobi Services and the Platform constitute Yobi’s intellectual property and will remain the exclusive
                            property of Yobi and its licensors.</p>
                        <p>You represent and warrant that the User Content:</p>
                        <ul>
                            <li>will not infringe upon or misappropriate any Intellectual Property Right or right of publicity
                                or
                                privacy of Yobi or any third party; and
                            </li>
                            <li>will not violate any applicable law or regulation.</li>
                        </ul>
                        <p>Using the Platform’s functionality for deleting, hiding, or otherwise disposing of information does
                            not
                            imply permanent deletion of content or information. Information may be retained by us for a period
                            of
                            time for any reason.</p>
                        <ol start="9">
                            <li><h3>Refunds; Chargebacks</h3></li>
                        </ol>
                        <p>In the event of a billing dispute, you may ask for a refund in writing within 30 days of the
                            applicable
                            billing period or your dispute will be deemed waived. &nbsp;You may request a refund by contacting
                            admin@yobi.app. Yobi may choose to grant your refund request, in its sole discretion, but it is not
                            obligated to do so. We may refund funds to Users irrespective of whether a User has requested funds
                            be
                            refunded if: (i) we are required by law or consider that we are required by law to do so; (ii) we
                            determine that refunding funds to the User will avoid any dispute or an increase in our costs; (iii)
                            we
                            refund funds to the User in accordance with any refund policy specified by us from time to time;
                            (iv) we
                            determine that the original payment made by the User is fraudulent; or (v) we consider, in our sole
                            opinion, that it is likely that the refund of funds is necessary to avoid a credit card
                            chargeback.</p>
                        <p>You expressly agree not to initiate any chargeback request with your card issuer. If you initiate any
                            chargeback request or other “Request for Information” or similar process, you expressly agree and
                            consent to us to share any and all information in relation to your agreement to these Terms, in
                            order to
                            defeat any such chargeback request.</p>
                        <p>If you have already initiated a chargeback request with your credit card issuer, you must not request
                            a
                            refund of funds by contacting us and must not seek double recovery. If a valid, settled transaction
                            is
                            disputed through the processing bank as a “chargeback”, and the transaction is reversed, the amount
                            of
                            that transaction and a fifty dollar processing fee will be deducted from the current available
                            balance
                            of the corresponding Account.</p>
                        <p>If we reasonably determine, having considered all the relevant circumstances, that you have made an
                            excessive or unreasonable number of requests to refund funds back to you or chargebacks, we may
                            suspend,
                            limit, or close your Account.</p>
                        <p>If you terminate your Account, the balance of unused funds will be preserved until User re-activates
                            User’s Account.&nbsp; In the event of termination by User, upon request for a refund of the unused
                            funds
                            to admin@yobi.app, Yobi will refund such balance within a reasonable timeframe provided that, at the
                            time of termination, the Account is in good standing.</p>
                        <ol start="10">
                            <li><h3>Right to Refuse Service</h3></li>
                        </ol>
                        <p>We may close, suspend, or limit your access to your Account without reason. Without limiting the
                            foregoing, we may close, suspend, or limit your access to your Account:</p>
                        <ul>
                            <li>if we determine that you have breached, or are acting in breach of, this Agreement;</li>
                            <li>if we determine that you have infringed any third party’s legal rights (resulting in actual or
                                potential claims), including infringing Intellectual Property Rights;
                            </li>
                            <li>if we determine that you have engaged, or are engaging, in fraudulent or illegal activities;
                            </li>
                            <li>to manage any risk of loss to us, or any other person; or</li>
                            <li>for other reasons we may elect.</li>
                        </ul>
                        <p>If we close your Account due to your breach of this Agreement, you may also become liable for certain
                            fees as described in this Agreement.</p>
                        <p>Without limiting our other remedies, to the extent you have breached this Agreement, you must pay us
                            all
                            fees owed to us and reimburse us for all losses and costs (including any and all of our employee
                            time)
                            and reasonable expenses (including legal fees) related to investigating such breach and collecting
                            such
                            fees.</p>
                        <p>You acknowledge and agree that: (i) the damages that we will sustain as a result of your breach of
                            this
                            Agreement will be substantial and will potentially include (without limitation) fines and other
                            related
                            expenses imposed on us by our payment processors, Callers, and other third parties and that those
                            damages may be extremely difficult and impracticable to ascertain; and (ii) if you breach this
                            Agreement, we may take legal action against you to recover losses.</p>
                        <p>If we close your Account for a reason other than as a result of your breach of this Agreement, unless
                            as
                            otherwise specified in this Agreement, you will be entitled to receive any payment due from us to
                            you.</p>
                        <p>In the event that we close your Account, you will have no claim whatsoever against us in respect of
                            any
                            such suspension or termination of your Account.</p>
                        <ol start="11">
                            <li><h3>Disputes </h3></li>
                        </ol>
                        <p>If a dispute arises between you and Yobi, our goal is to address your concerns immediately and, if we
                            are
                            unable to do so, to provide you with a means of resolving the dispute quickly. We strongly encourage
                            you
                            to first contact us directly to seek a resolution by using our customer support website or emailing
                            us
                            at admin@yobi.app.</p>
                        <p>For any claim, Yobi may elect to resolve the dispute in a cost effective manner through binding
                            non-appearance-based arbitration. If Yobi elects arbitration, such arbitration will be initiated
                            through
                            an established alternative dispute resolution (ADR) provider, which is to be selected by you from a
                            panel of ADR providers that Yobi will provide to you. The ADR provider and the parties must comply
                            with
                            the following rules: (i) the arbitration shall be conducted by telephone, online, or be solely based
                            on
                            written submissions; (ii) the arbitration shall not involve any personal appearance by the parties
                            or
                            witnesses; and (iii) any judgment on the award rendered by the arbitrator may be entered in any
                            court of
                            competent jurisdiction.</p>
                        <p>All claims you bring against Yobi must be resolved in accordance with these Terms. All claims filed
                            or
                            brought contrary to this Agreement shall be considered improperly filed and a breach of these Terms.
                            Should you file a claim contrary to these Terms, Yobi may recover its legal fees and costs
                            (including
                            but not limited to in-house lawyer and paralegal fees), provided that Yobi has notified you in
                            writing
                            of the improperly filed claim, and you have failed to promptly withdraw the claim.</p>
                        <p>You agree that you will not pursue any claims arising under this Agreement on a class action or other
                            representative basis, and will not seek to coordinate or consolidate any arbitration or action
                            hereunder
                            with any other proceeding.</p>
                        <p>If any proceeding by or against you is commenced under any provision of any bankruptcy or insolvency
                            law,
                            Yobi will be entitled to recover all reasonable costs or expenses (including reasonable legal fees
                            and
                            expenses) incurred in connection with the enforcement of this Agreement.</p>
                        <p>Yobi’s failure to act with respect to a breach by you or others does not waive our right to act with
                            respect to subsequent or similar breaches.</p>
                        <ol start="12">
                            <li><h3>Survival and Release</h3></li>
                        </ol>
                        <p>This Agreement, including any addendums hereto, supersedes any other agreement between you and the
                            Company and contains the entire understanding of the parties relating to the subject matter
                            hereof.</p>
                        <p>If any part of this document is found to be unenforceable, that part will be limited to the minimum
                            extent necessary so that this document will otherwise remain in full force and effect. The
                            invalidity or
                            unenforceability of any provision of this Agreement shall not affect the validity or enforceability
                            of
                            any other provision. Any invalid or unenforceable provision shall be deemed severed from this
                            Agreement
                            to the extent of its invalidity or unenforceability, and this Agreement shall be construed and
                            enforced
                            as if the Agreement did not contain that particular provision to the extent of its invalidity or
                            unenforceability.</p>
                        <p>Our failure to enforce any part of this document is not a waiver of any of our rights to later
                            enforce
                            that or any other part of this documents. The waiver of any breach of any term, covenant or
                            condition
                            herein contained, or our failure to seek redress for the violation of, or to insist upon the strict
                            performance of, any covenant or condition of this Agreement shall not be deemed to be a waiver of
                            such
                            term, covenant or condition or any subsequent breach of the same.</p>
                        <p>We may assign any of our rights and obligations under this document from time to time.</p>
                        <p>If there is a dispute between User and any Caller, or between User and any third party, the Yobi is
                            under
                            no obligation to become involved. In the event that you have a dispute with one or more other
                            Callers,
                            you release Yobi, its officers, employees, agents, and successors from claims, demands, and damages
                            of
                            every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising
                            out
                            of or in any way related to such disputes, the Platform, and Yobi services. If you are a California
                            resident or entity, you shall and hereby do waive California Civil Code Section 1542, which says: “A
                            general release does not extend to claims which the creditor does not know or suspect to exist in
                            his or
                            her favor at the time of executing the release, which, if known by him or her must have materially
                            affected his or her settlement with the debtor.”</p>
                        <ol start="13">
                            <li><h3>Access and Interference</h3></li>
                        </ol>
                        <p>You shall not:</p>
                        <ul>
                            <li>use any robot, spider, scraper, or other automated means to access the Platform;</li>
                            <li>take any action that imposes or may impose (in our sole discretion) an unreasonable or
                                disproportionately large load on our infrastructure;
                            </li>
                            <li>interfere with, damage, manipulate, disrupt, disable, modify, overburden, or impair any device,
                                software system, or network connected to or used (by you or us) in relation to the Platform or
                                your
                                Account;
                            </li>
                            <li>interfere or attempt to interfere with the proper working of the Platform or any activities
                                conducted on the Platform; or
                            </li>
                            <li>bypass our robot exclusion functions or other measures we may use to prevent or restrict access
                                to
                                the Platform.
                            </li>
                        </ul>
                        <h3>&nbsp; &nbsp;14. Termination; Suspension</h3>
                        <p>Either party may terminate your Account for any reason upon 180 days written notice to the other
                            party.
                            Either party may also terminate this Agreement if the other party materially breaches this Agreement
                            and
                            fails to remedy such breach within five days after written notice of such breach.</p>
                        <p>Yobi may suspend your Account immediately without notice if:</p>
                        <ul>
                            <li>Yobi reasonably believes User has violated any provisions of these terms;</li>
                            <li>your usage rates atypically increase to the extent that Yobi reasonably believes such traffic is
                                either fraudulent or unreasonably negatively impacting the operating capability of the Yobi
                                Services;
                            </li>
                            <li>you enter bankruptcy or dissolution proceedings, liquidation, or assignment for the benefit of
                                creditors;
                            </li>
                            <li>the available balance in your Account falls to below zero dollars; or</li>
                            <li>as otherwise provided in this Agreement.</li>
                        </ul>
                        <p>Account termination is subject to your paying any outstanding fees or amounts owing on the Account.
                            If
                            there are unused funds in your Account at the time of termination, you may request a refund pursuant
                            to
                            Section 9 herein. Furthermore, upon User’s termination of its Account, User shall be required to
                            confirm
                            the release of all active phone numbers associated with User’s Account, and Yobi shall immediately
                            cease
                            connecting phone calls through phone numbers associated with User’s Account.</p>
                        <p>In its sole discretion, Yobi may delete all or some portion of User Content sixty days after Account
                            termination.&nbsp; We may retain some of your personal information to satisfy regulatory
                            requirements
                            and our own external obligations. Closing your Account does not necessarily delete or remove all of
                            the
                            information we hold.</p>
                        <ol start="15">
                            <li><h3>Privacy</h3></li>
                        </ol>
                        <p>We use your information as described in the Yobi Privacy Policy. If you object to your information
                            being
                            transferred or used in this way then you must not use our Platform. For the avoidance of doubt, your
                            name and personal details shall be used for identity purposes in the normal course of conducting
                            business. This may include on invoices, on your profile pages, and any other relevant pages where
                            you
                            conduct business.</p>
                        <ol start="16">
                            <li><h3>Indemnification</h3></li>
                        </ol>
                        <p>You agree to defend, indemnify, and hold harmless us, our subsidiaries, parents, affiliates, and each
                            of
                            our and their directors, officers, managers, members, partners, agents, and employees from and
                            against
                            each and every demand, judgment, damage, loss, liability, deficiency, cost or expense, including
                            without
                            limitation reasonable attorneys’ fees, incurred in connection with any claim, suit, action, or
                            proceeding brought against us arising out of your use of Yobi Services or the Platform, or from or
                            in
                            connection with any User Content, or alleging facts or circumstances that could constitute a breach
                            by
                            you of any provision of these Terms or any of the representations and warranties set forth herein.
                            If
                            you are obligated to indemnify us, we retain the right, in our sole and unfettered discretion, to
                            control any action or proceeding and determine whether we wish to settle it, and if so, on what
                            terms.
                            In addition, we can apply any funds in your Account against any liabilities you owe to us or loss
                            suffered by us as a result of your non-performance or breach of this Agreement.</p>
                        <ol start="17">
                            <li><h3>Security</h3></li>
                        </ol>
                        <p>Yobi will strictly adhere to commercially reasonable security standards in connection with all
                            systems
                            used for storage, processing, and handling of User Content. User must immediately notify us upon
                            becoming aware of any unauthorized access or any other security breach to the Platform, Yobi
                            Services,
                            or your Account and do everything possible to mitigate the unauthorized access or security breach
                            (including preserving evidence and notifying appropriate authorities). Your User Account is yours
                            only,
                            and you must not share your password with others. You are solely responsible for securing your
                            password.
                            We will not be liable for any loss or damage arising from unauthorized access of your Account
                            resulting
                            from your failure to secure your password.</p>
                        <ol start="18">
                            <li><h3>Disclaimer of Warranties</h3></li>
                        </ol>
                        <p>Yobi Services, the Platform and all User Content are provided on an ‘as is’, ‘with all faults’ and
                            ‘as
                            available’ basis and without warranties of any kind either express or implied. Without limiting the
                            foregoing, we make no representation or warranty about:</p>
                        <ul>
                            <li>the Platform or Yobi Services;</li>
                            <li>the accuracy, reliability, availability, veracity, timeliness, or content of the Platform or any
                                Yobi Services;
                            </li>
                            <li>whether the Platform or Yobi Services will be up-to-date, uninterrupted, secure, error-free, or
                                non-misleading;
                            </li>
                            <li>whether defects in the Platform or Yobi Services will be corrected;</li>
                            <li>whether the Platform or the Yobi Services or any data, content, or material will be preserved or
                                backed up or whether business continuity arrangements are in place in respect of the Platform or
                                Yobi Services;
                            </li>
                            <li>the Platform or Yobi Services or infrastructure on which they are based, being free of errors or
                                malicious code, being secure, being confidential,
                            </li>
                            <li>performing at any particular standard, or having any particular function.</li>
                        </ul>
                        <p>We are not responsible for any problems or technical malfunction of any telephone network or lines,
                            computer online systems, servers, providers, computer equipment, software, failure of communication
                            on
                            account of technical problems, or traffic congestion on the internet or at any website or
                            combination
                            thereof, including injury or damage to User’s or to any other person’s computer related to or
                            resulting
                            from participating or downloading materials in connection with the web or in connection with Yobi
                            Services. Under no circumstances will we, or any of our affiliates, or distribution partners, be
                            responsible for any loss or damage, including personal injury or death, resulting from anyone’s use
                            of
                            the Platform or the Yobi Services. No data, information or advice obtained by you in oral or written
                            form from us or through or from our Yobi Services will create any warranty not expressly stated in
                            these
                            terms.</p>
                        <p>TO EVERY EXTENT PERMITTED BY LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
                            OR
                            IMPLIED, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR
                            A
                            PARTICULAR PURPOSE, QUALITY, SUITABILITY, AND NON-INFRINGEMENT.</p>
                        <ol start="19">
                            <li><h3>Limitation of Liability</h3></li>
                        </ol>
                        <p>In no event shall we, our related entities, our affiliates or staff be liable, whether in contract,
                            warranty, tort (including negligence), or any other form of liability, for:</p>
                        <ul>
                            <li>any indirect, special, incidental, punitive, or consequential damages that may be incurred by
                                you;
                            </li>
                            <li>any loss of income, business or profits (whether direct or indirect) that may be incurred by
                                you;
                            </li>
                            <li>any claim, damage, or loss that may be incurred by you as a result of any of your transactions
                                involving the Platform;
                            </li>
                            <li>any content provided by you or any Caller; or</li>
                            <li>any actions any Caller.</li>
                        </ul>
                        <p>The limitations on our liability to you above shall apply whether or not we, our related entities,
                            our
                            affiliates or staff have been advised of the possibility of such losses or damages arising.</p>
                        <p>Notwithstanding the above provisions, nothing in this Agreement is intended to limit or exclude any
                            liability on the part of us and our affiliates and related entities where and to the extent that
                            applicable law prohibits such exclusion or limitation. Wherever the law prohibits Yobi absolute
                            exemption from liability, Yobi’s total aggregate liability, under or in relation to any warranty or
                            condition implied by law shall be limited to the aggregate sum total of fees paid to Yobi during the
                            six
                            months preceding the incident or claim by the User in connection with User’s access to the Yobi
                            Services
                            or Platform.</p>
                        <ol start="20">
                            <li><h3>Legal Limitations</h3></li>
                        </ol>
                        <p>As some jurisdictions do not allow some of the exclusions or limitations as established above, some
                            of
                            these exclusions or limitations may not apply to you. In that event, the liability will be limited
                            as
                            far as legally possible under the applicable law. We may plead this Agreement in bar to any claim,
                            action, proceeding, or suit brought by you against us for any matter arising out of any transaction
                            or
                            otherwise in respect of this Agreement.</p>
                        <ol start="21">
                            <li><h3>Notices</h3></li>
                        </ol>
                        <p>Legal notices will be served to the email address you provide to Yobi during the registration
                            process.
                            Notice will be deemed given 24 hours after email is sent, unless the sending party is notified that
                            the
                            email address is invalid or that the email has not been delivered. Alternatively, in our sole
                            discretion, we may give you legal notice by mail to the address provided by you during the
                            registration
                            process. In such case, notice will be deemed given three days after the date of mailing.</p>
                        <p>Any notices to Yobi must be given by registered U.S. Mail.</p>
                        <ol start="22">
                            <li><h3>Governing Law </h3></li>
                        </ol>
                        <p>This Agreement will be governed in all respects by the laws of California, USA.</p>
                        <ol start="23">
                            <li><h3>Interpretation</h3></li>
                        </ol>
                        <p>Headings are for reference purposes only and in no way define, limit, construe or describe the scope
                            or
                            extent of such section.</p>
                        <ol start="24">
                            <li><h3>Communications</h3></li>
                        </ol>
                        <p>You consent to receive notices and information from us in respect of the Platform by electronic
                            communication. You may withdraw this consent at any time, but if you do so we may choose to suspend
                            or
                            close your Account.</p></div>
                </div>
            </div>
        </ng-container>
    
        <ng-container *ngIf="!data?.isTOF">
            <h2>Privacy Policy</h2>
            <div style="width: 100%;">
    
                <h3>Types of Data collected</h3>
                <p>
                    Among the types of Personal Data that yobi.app collects, by itself or through third parties, there are: Cookies; Usage Data; geographic position; Data communicated while using the service; email address; first name; last name; phone number; username; various types of Data; Contacts permission; Camera permission; SMS permission; Social media accounts permission; Call permission.
                </p>
                <p>
                Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.
                Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using yobi.app.
                Unless specified otherwise, all Data requested by yobi.app is mandatory and failure to provide this Data may make it impossible for yobi.app to provide its services. In cases where yobi.app specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.
                Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.
                Any use of Cookies – or of other tracking tools – by yobi.app or by the owners of third-party services used by yobi.app serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.
                </p>
                <p>
                Users are responsible for any third-party Personal Data obtained, published or shared through yobi.app and confirm that they have the third party's consent to provide the Data to the Owner.
                </p>
    
                <h2>Mode and place of processing the Data</h2>
                <h3>Methods of processing</h3>
                <p>
                    The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.
                    The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of yobi.app (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.
                </p>
    
                <h3>Legal basis of processing</h3>
                The Owner may process Personal Data relating to Users if one of the following applies:
    
                <ul>
                    <li>Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;</li>
                    <li>provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;</li>
                    <li>processing is necessary for compliance with a legal obligation to which the Owner is subject;</li>
                    <li>processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;</li>
                    <li>processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.</li>
                </ul>
    
                In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
    
                <h2>Place</h2>
                The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.
    
    
                Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.
    
    
                Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.
    
    
                If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.
    
    
                <h2>Retention time</h2>
                Personal Data shall be processed and stored for as long as required by the purpose they have been collected for.
    
    
                Therefore:
    
                <ul>
                    <li>Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.</li>
                    <li>Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.</li>
                </ul>
    
                The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.
    
                Once the retention period expires, Personal Data shall be deleted. Therefore, the right of access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.
    
    
                <h2>The purposes of processing</h2>
    
                The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as the following: Analytics, Access to third-party accounts, Platform services and hosting, Location-based interactions, Tag Management, Traffic optimization and distribution, Interaction with live chat platforms, Contacting the User, User database management, Heat mapping and session recording, Registration and authentication, Remarketing and behavioral targeting, Managing contacts and sending messages, Advertising, Hosting and backend infrastructure and Device permissions for Personal Data access.
    
    
                For specific information about the Personal Data used for each purpose, the User may refer to the section “Detailed information on the processing of Personal Data”.
    
    
                <h2>Facebook permissions asked by yobi.app</h2>
                Yobi.app may ask for some Facebook permissions allowing it to perform actions with the User's Facebook account and to retrieve information, including Personal Data, from it. This service allows yobi.app to connect with the User's account on the Facebook social network, provided by Facebook Inc.
    
    
                For more information about the following permissions, refer to the Facebook permissions documentation and to the Facebook privacy policy.
    
    
                The permissions asked are the following:
    
    
                <h3>Basic information</h3>
                By default, this includes certain User’s Data such as id, name, picture, gender, and their locale. Certain connections of the User, such as the Friends, are also available. If the User has made more of their Data public, more information will be available.
    
    
                <h3>Access Requests</h3>
                Provides read access to the User's friend requests.
    
    
                <h3>Page Messaging</h3>
                Send and receive messages through a Facebook Page.
    
    
                <h2>Device permissions for Personal Data access</h2>
                Depending on the User's specific device, yobi.app may request certain permissions that allow it to access the User's device Data as described below.
    
    
                By default, these permissions must be granted by the User before the respective information can be accessed. Once the permission has been given, it can be revoked by the User at any time. In order to revoke these permissions, Users may refer to the device settings or contact the Owner for support at the contact details provided in the present document.
                The exact procedure for controlling app permissions may be dependent on the User's device and software.
    
    
                Please note that the revoking of such permissions might impact the proper functioning of yobi.app.
    
    
                If User grants any of the permissions listed below, the respective Personal Data may be processed (i.e accessed to, modified or removed) by yobi.app.
    
    
                <h3>Call permission</h3>
                Used for accessing a host of typical features associated with telephony.
    
    
                <h3>Camera permission</h3>
                Used for accessing the camera or capturing images and video from the device.
    
    
                <h3>Contacts permission</h3>
                Used for accessing contacts and profiles on the User's device, including the changing of entries.
    
    
                <h3>SMS permission</h3>
                Used for accessing features related to the User's messaging including the sending, receiving and reading of SMS.
    
    
                <h3>Social media accounts permission</h3>
                Used for accessing the User's social media account profiles, such as Facebook and Twitter.
    
    
                <h2>Detailed information on the processing of Personal Data</h2>
                Personal Data is collected for the following purposes and using the following services:
    
    
                <h3>Access to third-party accounts</h3>
                This type of service allows yobi.app to access Data from your account on a third-party service and perform actions with it.
                These services are not activated automatically, but require explicit authorization by the User.
    
    
                <h3>Facebook account access (Facebook, Inc.)</h3>
                This service allows yobi.app to connect with the User's account on the Facebook social network, provided by Facebook, Inc.
    
    
                Permissions asked: Access Requests; Page Messaging.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h3>Twitter account access (Twitter, Inc.)</h3>
                This service allows yobi.app to connect with the User's account on the Twitter social network, provided by Twitter, Inc.
    
    
                Personal Data processed: username.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h3>Advertising</h3>
                This type of service allows User Data to be utilized for advertising communication purposes. These communications are displayed in the form of banners and other advertisements on yobi.app, possibly based on User interests.
                This does not mean that all Personal Data are used for this purpose. Information and conditions of use are shown below.
                Some of the services listed below may use Trackers to identify Users or they may use the behavioral retargeting technique, i.e. displaying ads tailored to the User’s interests and behavior, including those detected outside yobi.app. For more information, please check the privacy policies of the relevant services.
                Services of this kind usually offer the possibility to opt out of such tracking. In addition to any opt-out feature offered by any of the services below, Users may learn more on how to generally opt out of interest-based advertising within the dedicated section "How to opt-out of interest-based advertising" in this document.
    
    
                <h3>Google Ad Manager (Google LLC)</h3>
                Google Ad Manager is an advertising service provided by Google LLC that allows the Owner to run advertising campaigns in conjunction with external advertising networks that the Owner, unless otherwise specified in this document, has no direct relationship with.
                In order to understand Google's use of Data, consult Google's partner policy.
                This service uses the “DoubleClick” Cookie, which tracks use of yobi.app and User behavior concerning ads, products and services offered.
    
    
                Users may decide to disable all the DoubleClick Cookies by going to: Google Ad Settings.
    
    
                Personal Data processed: Tracker; Usage Data.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h2>Analytics</h2>
                The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.
    
    
                <h3>Google Analytics (Google LLC)</h3>
                Google Analytics is a web analysis service provided by Google LLC (“Google”). Google utilizes the Data collected to track and examine the use of yobi.app, to prepare reports on its activities and share them with other Google services.
                Google may use the Data collected to contextualize and personalize the ads of its own advertising network.
    
    
                Personal Data processed: Cookies; Usage Data.
    
    
                Place of processing: United States – Privacy Policy – Opt Out.
    
    
                <h3>HubSpot Analytics (HubSpot, Inc.)</h3>
                HubSpot Analytics is an analytics service provided by HubSpot, Inc.
    
    
                Personal Data processed: Tracker; Usage Data.
    
    
                Place of processing: United States – Privacy Policy – Opt Out .
    
    
                <h3>Facebook Analytics for Apps (Facebook, Inc.)</h3>
                Facebook Analytics for Apps is an analytics service provided by Facebook, Inc.
    
    
                Personal Data processed: Tracker; Usage Data; various types of Data as specified in the privacy policy of the service.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h2>Contacting the User</h2>
                <h3>Mailing list or newsletter (yobi.app)</h3>
                By registering on the mailing list or for the newsletter, the User’s email address will be added to the contact list of those who may receive email messages containing information of commercial or promotional nature concerning yobi.app. Your email address might also be added to this list as a result of signing up to yobi.app or after making a purchase.
    
    
                Personal Data processed: email address; first name; last name.
    
    
                <h3>Contact form (yobi.app)</h3>
                By filling in the contact form with their Data, the User authorizes yobi.app to use these details to reply to requests for information, quotes or any other kind of request as indicated by the form’s header.
    
    
                Personal Data processed: email address.
    
    
                <h3>Phone contact (yobi.app)</h3>
                Users that provided their phone number might be contacted for commercial or promotional purposes related to yobi.app, as well as for fulfilling support requests.
    
    
                Personal Data processed: phone number.
    
    
                <h2>Device permissions for Personal Data access</h2>
                Yobi.app requests certain permissions from Users that allow it to access the User's device Data as described below.
    
    
                <h3>Device permissions for Personal Data access (yobi.app)</h3>
                Yobi.app requests certain permissions from Users that allow it to access the User's device Data as summarized here and described within this document.
    
    
                Personal Data processed: Call permission; Camera permission; Contacts permission; SMS permission; Social media accounts permission.
    
    
                <h2>Heat mapping and session recording</h2>
                Heat mapping services are used to display the areas of yobi.app that Users interact with most frequently. This shows where the points of interest are. These services make it possible to monitor and analyze web traffic and keep track of User behavior.
                Some of these services may record sessions and make them available for later visual playback.
    
    
                <h3>Hotjar Heat Maps & Recordings (Hotjar Ltd.)</h3>
                Hotjar is a session recording and heat mapping service provided by Hotjar Ltd.
                Hotjar honors generic „Do Not Track” headers. This means the browser can tell its script not to collect any of the User's data. This is a setting that is available in all major browsers. Find Hotjar’s opt-out information here.
    
    
                Personal Data processed: Tracker; Usage Data; various types of Data as specified in the privacy policy of the service.
    
    
                Place of processing: Malta – Privacy Policy – Opt Out.
    
    
                <h2>Hosting and backend infrastructure</h2>
                This type of service has the purpose of hosting Data and files that enable yobi.app to run and be distributed as well as to provide a ready-made infrastructure to run specific features or parts of yobi.app.
    
    
                Some services among those listed below, if any, may work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data are stored.
    
    
                <h3>Firebase Realtime Database (Google LLC)</h3>
                Firebase Realtime Database is a hosting and backend service provided by Google LLC.
    
    
                Personal Data processed: Usage Data; various types of Data as specified in the privacy policy of the service.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h2>Interaction with live chat platforms</h2>
                This type of service allows Users to interact with third-party live chat platforms directly from the pages of yobi.app, in order to contact and be contacted by yobi.app‘s support service.
                If one of these services is installed, it may collect browsing and Usage Data in the pages where it is installed, even if the Users do not actively use the service. Moreover, live chat conversations may be logged.
    
    
                <h3>Facebook Messenger Customer Chat (Facebook, Inc.)</h3>
                The Facebook Messenger Customer Chat is a service for interacting with the Facebook Messenger live chat platform provided by Facebook, Inc.
    
    
                Personal Data processed: Cookies; Data communicated while using the service; Usage Data.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h2>Location-based interactions</h2>
                <h3>Geolocation (yobi.app)</h3>
                Yobi.app may collect, use, and share User location Data in order to provide location-based services.
                Most browsers and devices provide tools to opt out from this feature by default. If explicit authorization has been provided, the User’s location data may be tracked by yobi.app.
    
    
                Personal Data processed: geographic position.
    
    
                <h2>Managing contacts and sending messages</h2>
                This type of service makes it possible to manage a database of email contacts, phone contacts or any other contact information to communicate with the User.
                These services may also collect data concerning the date and time when the message was viewed by the User, as well as when the User interacted with it, such as by clicking on links included in the message.
    
    
                <h3>Twilio (Twilio, Inc.)</h3>
                Twilio is a phone numbers management and communication service provided by Twilio, Inc.
    
    
                Personal Data processed: phone number.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h3>HubSpot Email (HubSpot, Inc.)</h3>
                HubSpot Email is an email address management and message sending service provided by HubSpot, Inc.
    
    
                Personal Data processed: email address; Usage Data.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h2>Platform services and hosting</h2>
                These services have the purpose of hosting and running key components of yobi.app, therefore allowing the provision of yobi.app from within a unified platform. Such platforms provide a wide range of tools to the Owner – e.g. analytics, user registration, commenting, database management, e-commerce, payment processing – that imply the collection and handling of Personal Data.
                Some of these services work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data are stored.
    
    
                <h3>WordPress.com (Automattic Inc.)</h3>
                WordPress.com is a platform provided by Automattic Inc. that allows the Owner to build, run and host yobi.app.
    
    
                Personal Data processed: various types of Data as specified in the privacy policy of the service.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h3>Apple App Store (Apple Inc.)</h3>
                Yobi.app is distributed on Apple's App Store, a platform for the distribution of mobile apps, provided by Apple Inc.
    
    
                By virtue of being distributed via this app store, Apple collects basic analytics and provides reporting features that enables the Owner to view usage analytics data and measure the performance of yobi.app. Much of this information is processed on an opt-in basis.
    
    
                Users may opt-out of this analytics feature directly through their device settings. More information on how to manage analysis settings can be found on this page.
    
    
                Personal Data processed: Usage Data.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h3>Google Play Store (Google LLC)</h3>
                Yobi.app is distributed on the Google Play Store, a platform for the distribution of mobile apps, provided by Google LLC.
    
    
                By virtue of being distributed via this app store, Google collects usage and diagnostics data and share aggregate information with the Owner. Much of this information is processed on an opt-in basis.
    
    
                Users may opt-out of this analytics feature directly through their device settings. More information on how to manage analysis settings can be found on this page.
    
    
                Personal Data processed: Usage Data.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h2>Registration and authentication</h2>
                By registering or authenticating, Users allow yobi.app to identify them and give them access to dedicated services.
                Depending on what is described below, third parties may provide registration and authentication services. In this case, yobi.app will be able to access some Data, stored by these third-party services, for registration or identification purposes.
                Some of the services listed below may also collect Personal Data for targeting and profiling purposes; to find out more, please refer to the description of each service.
    
    
                <h3>Twitter OAuth (Twitter, Inc.)</h3>
                Twitter Oauth is a registration and authentication service provided by Twitter, Inc. and is connected to the Twitter social network.
    
    
                Personal Data processed: various types of Data as specified in the privacy policy of the service.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h3>Facebook Oauth (Facebook, Inc.)</h3>
                Facebook Oauth is a registration and authentication service provided by Facebook, Inc. and is connected to the Facebook social network.
    
    
                Personal Data processed: Tracker; various types of Data.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h3>Instagram Authentication (Facebook, Inc.)</h3>
                Instagram Authentication is a registration and authentication service provided by Facebook, Inc. and is connected to the Instagram social network.
    
    
                Personal Data processed: various types of Data as specified in the privacy policy of the service.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h3>Linkedin OAuth (LinkedIn Corporation)</h3>
                Linkedin Oauth is a registration and authentication service provided by Linkedin Corporation and is connected to the Linkedin social network.
    
    
                Personal Data processed: various types of Data as specified in the privacy policy of the service.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h3>Facebook Authentication (Facebook, Inc.)</h3>
                Facebook Authentication is a registration and authentication service provided by Facebook, Inc. and is connected to the Facebook social network.
    
    
                Personal Data processed: Tracker; various types of Data as specified in the privacy policy of the service.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h3>Google OAuth (Google LLC)</h3>
                Google OAuth is a registration and authentication service provided by Google LLC and is connected to the Google network.
    
    
                Personal Data processed: various types of Data as specified in the privacy policy of the service.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h2>Remarketing and behavioral targeting</h2>
                This type of service allows yobi.app and its partners to inform, optimize and serve advertising based on past use of yobi.app by the User.
                This activity is facilitated by tracking Usage Data and by using Trackers to collect information which is then transferred to the partners that manage the remarketing and behavioral targeting activity.
                Some services offer a remarketing option based on email address lists.
                In addition to any opt-out feature provided by any of the services below, Users may opt out by visiting the Network Advertising Initiative opt-out page.
    
                <b>
                Users may also opt-out of certain advertising features through applicable device settings, such as the device advertising settings for mobile phones or ads settings in general.
                </b>
    
                <h3>Facebook Remarketing (Facebook, Inc.)</h3>
                Facebook Remarketing is a remarketing and behavioral targeting service provided by Facebook, Inc. that connects the activity of yobi.app with the Facebook advertising network.
    
    
                Personal Data processed: Tracker; Usage Data.
    
    
                Place of processing: United States – Privacy Policy – Opt Out.
    
    
                <h3>Google Ads Remarketing (Google LLC)</h3>
                Google Ads Remarketing is a remarketing and behavioral targeting service provided by Google LLC that connects the activity of yobi.app with the Google Ads advertising network and the DoubleClick Cookie.
    
    
                In order to understand Google's use of Data, consult Google's partner policy.
    
    
                Users can opt out of Google's use of Trackers for ads personalization by visiting Google's Ads Settings.
    
    
                Personal Data processed: Tracker; Usage Data.
    
    
                Place of processing: United States – Privacy Policy – Opt Out.
    
    
                <h2>Tag Management</h2>
                This type of service helps the Owner to manage the tags or scripts needed on yobi.app in a centralized fashion.
                This results in the Users' Data flowing through these services, potentially resulting in the retention of this Data.
    
    
                <h3>Google Tag Manager (Google LLC)</h3>
                Google Tag Manager is a tag management service provided by Google LLC.
    
    
                Personal Data processed: Usage Data.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h2>Traffic optimization and distribution</h2>
                This type of service allows yobi.app to distribute their content using servers located across different countries and to optimize their performance.
                Which Personal Data are processed depends on the characteristics and the way these services are implemented. Their function is to filter communications between yobi.app and the User's browser.
                Considering the widespread distribution of this system, it is difficult to determine the locations to which the contents that may contain Personal Information of the User are transferred.
    
    
                <h3>Cloudflare (Cloudflare Inc.)</h3>
                Cloudflare is a traffic optimization and distribution service provided by Cloudflare Inc.
                The way Cloudflare is integrated means that it filters all the traffic through yobi.app, i.e., communication between yobi.app and the User's browser, while also allowing analytical data from yobi.app to be collected.
    
    
                Personal Data processed: Cookies; various types of Data as specified in the privacy policy of the service.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h2>User database management</h2>
                This type of service allows the Owner to build user profiles by starting from an email address, a personal name, or other information that the User provides to yobi.app, as well as to track User activities through analytics features. This Personal Data may also be matched with publicly available information about the User (such as social networks' profiles) and used to build private profiles that the Owner can display and use for improving yobi.app.
                Some of these services may also enable the sending of timed messages to the User, such as emails based on specific actions performed on yobi.app.
    
    
                <h3>HubSpot CRM (HubSpot, Inc.)</h3>
                HubSpot CRM is a User database management service provided by HubSpot, Inc.
    
    
                Personal Data processed: email address; phone number; various types of Data as specified in the privacy policy of the service.
    
    
                Place of processing: United States – Privacy Policy.
    
    
                <h2>Information on opting out of interest-based advertising</h2>
                In addition to any opt-out feature provided by any of the services listed in this document, Users may follow the instructions provided by YourOnlineChoices (EU), the Network Advertising Initiative (US) and the Digital Advertising Alliance (US), DAAC (Canada), DDAI (Japan) or other similar initiatives. Such initiatives allow Users to select their tracking preferences for most of the advertising tools. The Owner thus recommends that Users make use of these resources in addition to the information provided in this document.
    
    
                The Digital Advertising Alliance offers an application called AppChoices that helps Users to control interest-based advertising on mobile apps.
    
    
                Users may also opt-out of certain advertising features through applicable device settings, such as the device advertising settings for mobile phones or ads settings in general.
    
    
                <h2>Further information about the processing of Personal Data</h2>
                <h3>User identification via a universally unique identifier (UUID)</h3>
                Yobi.app may track Users by storing a so-called universally unique identifier (or short UUID) for analytics purposes or for storing Users' preferences. This identifier is generated upon installation of this Application, it persists between Application launches and updates, but it is lost when the User deletes the Application. A reinstall generates a new UUID.
    
    
                <h3>Preference Cookies</h3>
                Preference Cookies store the User preferences detected on yobi.app in the local domain such as, for example, their timezone and region.
    
    
                <h3>Push notifications</h3>
                Yobi.app may send push notifications to the User to achieve the purposes outlined in this privacy policy.
    
    
                Users may in most cases opt-out of receiving push notifications by visiting their device settings, such as the notification settings for mobile phones, and then change those settings for yobi.app, some or all of the apps on the particular device.
                Users must be aware that disabling push notifications may negatively affect the utility of yobi.app.
    
    
                <h3>Equal protection of User Data</h3>
                Yobi.app shares User Data only with third parties carefully selected to ensure that they provide the same or equal protection of User Data as stated in this privacy policy and requested by applicable data protection laws. Further information on data processing and privacy practices by third parties can be found in their respective privacy policies.
    
    
                <h3>Access the address book</h3>
                Yobi.app may request access to your address book.
    
    
                <h2>The rights of Users</h2>
                Users may exercise certain rights regarding their Data processed by the Owner.
    
    
                In particular, Users have the right to do the following:
    
    
                Withdraw their consent at any time. Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.
                Object to processing of their Data. Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.
                Access their Data. Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.
                Verify and seek rectification. Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.
                Restrict the processing of their Data. Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.
                Have their Personal Data deleted or otherwise removed. Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.
                Receive their Data and have it transferred to another controller. Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof.
                Lodge a complaint. Users have the right to bring a claim before their competent data protection authority.
                <h3>Details about the right to object to processing</h3>
                Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.
    
    
                Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.
    
    
                <h3>How to exercise these rights</h3>
                Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.
    
    
                <h2>Additional information about Data collection and processing</h2>
                <h3>Legal action</h3>
                The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of yobi.app or the related Services.
                The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.
    
    
                <h3>Additional information about User's Personal Data</h3>
                In addition to the information contained in this privacy policy, yobi.app may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.
    
    
                <h3>System logs and maintenance</h3>
                For operation and maintenance purposes, yobi.app and any third-party services may collect files that record interaction with yobi.app (System logs) use other Personal Data (such as the IP Address) for this purpose.
    
    
                <h3>Information not contained in this policy</h3>
                More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.
    
    
                <h3>How “Do Not Track” requests are handled</h3>
                Yobi.app does not support “Do Not Track” requests.
                To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.
    
    
                <h3>Changes to this privacy policy</h3>
                The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on this page and possibly within yobi.app and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom.
    
    
                Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the User, where required.
    
    
                <h2>Definitions and legal references</h2>
                <h3>Personal Data (or Data)</h3>
                Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.
    
    
                <h3>Usage Data</h3>
                Information collected automatically through yobi.app (or third-party services employed in yobi.app), which can include: the IP addresses or domain names of the computers utilized by the Users who use yobi.app, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.
    
    
                <h3>User</h3>
                The individual using yobi.app who, unless otherwise specified, coincides with the Data Subject.
    
    
                <h3>Data Subject</h3>
                The natural person to whom the Personal Data refers.
    
    
                <h3>Data Processor (or Data Supervisor)</h3>
                The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.
    
    
                <h3>Data Controller (or Owner)</h3>
                The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of yobi.app. The Data Controller, unless otherwise specified, is the Owner of yobi.app.
    
    
                <h3>yobi.app (or this Application)</h3>
                The means by which the Personal Data of the User is collected and processed.
    
    
                <h3>Service</h3>
                The service provided by yobi.app as described in the relative terms (if available) and on this site/application.
    
    
                <h3>European Union (or EU)</h3>
                Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.
    
    
                <h3>Cookie</h3>
                Cookies are Trackers consisting of small sets of data stored in the User's browser.
    
    
                <h3>Tracker</h3>
                Tracker indicates any technology - e.g Cookies, unique identifiers, web beacons, embedded scripts, e-tags and fingerprinting - that enables the tracking of Users, for example by accessing or storing information on the User’s device.
    
    
                <h3>Legal information</h3>
                This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).
    
    
                This privacy policy relates solely to yobi.app, if not stated otherwise within this document.
    
    
    
    
    
    
    
    
    
    
            </div>
        </ng-container>
    </div>

</div>

