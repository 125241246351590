export enum EnvironmentType {
  dev,
  qa,
  prod,
}

export interface IConfiguration {
  applicationName: string;
  applicationVersion: string;
  environment: EnvironmentType;
  productionModeEnabled: boolean;
}
