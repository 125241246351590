import { HttpService } from '@/app/Data/services/Networking/HttpService';
import { Injectable } from '@angular/core';
import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { BillingPlanDto } from '../DTO/BillingPlanDto';
import { map, Observable, pluck } from 'rxjs';
import { environment } from '@/environments/environment';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { PaymentMethodDto } from '../DTO/PaymentMethodDto';
import { CreditCardDto } from '../DTO/CriditCardDto';
import { BillingSubscriptionDto } from '../DTO/BillingSubscriptionDto';
import { BillingDto, BillingInvoiceDto } from '../DTO/BillingDto';
import { IBillingRepository } from '@/app/core/IRepositories/IBillingRepository';
import { BillingSubscription, PromoCode } from '@/app/core/Models/billing';
import { BillingUpgradePromptDto } from '../DTO/BillingUpgradePromptDto';
import { SuccessResponse } from '../DTO/successResponse';

@Injectable()
export class BillingRepository implements IBillingRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) {
    // super();
  }


  getBillingPlans(): Observable<BillingPlanDto[]> {
    const requestURL = `${environment.apiURL}billing/plans`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<BillingPlanDto[]>;
        return res.results;
      })
    );
  }

  applyPromoCode(data: { code: string }): Observable<BillingPlanDto[]> {
    const requestURL = `${environment.apiURL}billing/promotion`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<BillingPlanDto[]>;
        return res.results;
      })
    );
  }

  GetBillingPromptStatus(): Observable<{ expired: boolean }> {
    const requestURL = `${environment.apiURL}upgrade-prompt-event`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<{ expired: boolean }>;
        return res.results;
      })
    );
  }

  BillingPromptEvent(data: BillingUpgradePromptDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}upgrade-prompt-event`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessResponse;
        return res;
      })
    );
  }

  deletePromoCode(data: { codeId: number }): Observable<BillingPlanDto[]> {
    const requestURL = `${environment.apiURL}billing/promotion/${ data.codeId }`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<BillingPlanDto[]>;
        return res.results;
      })
    );
  }

  getActivePromoCodes(): Observable<PromoCode[]> {
    const requestURL = `${environment.apiURL}billing/promotions`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<PromoCode[]>;
        return res.results;
      })
    );
  }

  getInvoices(): Observable<BillingInvoiceDto[]> {
    const requestURL = `${environment.apiURL}billing/invoices`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<BillingInvoiceDto[]>;
        return res.results;
      })
    );
  }
  
  addPaymentMethod(card: CreditCardDto): Observable<PaymentMethodDto> {
    const requestURL = `${environment.apiURL}billing/cards`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      card,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<PaymentMethodDto>;
        return res.results;
      })
    );
  } 
  
  deletePaymentMethod(): Observable<any> {
    const requestURL = `${environment.apiURL}billing/cards`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      undefined,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }  

  getCurrentPaymentMethod(): Observable<PaymentMethodDto> {
    const requestURL = `${environment.apiURL}billing/cards`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<PaymentMethodDto>;
        return res.results;
      })
    );
  }
  
  getActiveSubscription(): Observable<BillingSubscriptionDto | null> {
    const requestURL = `${environment.apiURL}billing/subscription`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<BillingSubscriptionDto | null>;
        return res.results;
      })
    );
  }

  updateBillingSubscription(plan_id: number): Observable<BillingSubscriptionDto> {
    const requestURL = `${environment.apiURL}billing/subscription`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { plan_id },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<BillingSubscriptionDto>;
        return res.results;
      })
    );
  }
  
  customPlan(): Observable<string> {
    const requestURL = `${environment.apiURL}billing/support`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<string>;
        return res.results;
      })
    );
  }

  reactivateBillingSubscription(): Observable<BillingSubscriptionDto> {
    const requestURL = `${environment.apiURL}billing/subscription/reactivate`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      {},
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<BillingSubscriptionDto>;
        return res.results;
      })
    );
  }

  CancelBillingSubscription(data: {
    reason: string,
    improve: string,
  }): Observable<any> {
    const requestURL = `${environment.apiURL}billing/subscription/cancel`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  getCancellationReasons(): Observable<BillingDto.CancellationReasonsItem[]> {
    const requestURL = `${environment.apiURL}billing/subscription/reason`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<BillingDto.CancellationReasonsItem[]>;
        return res.results;
      })
    );
  }

  getEstimation(plan_id: number): Observable<BillingDto.Estimation> {
    const requestURL = `${environment.apiURL}billing/estimate_upcoming`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { plan_id },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<BillingDto.Estimation>;
        return res.results;
      })
    );
  }
  
}
