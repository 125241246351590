import { map } from 'rxjs/operators';
import { UsersViewModel } from './../../ViewModels/usersViewModel';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeamMember } from '@/app/core/Models/TeamMember';
import {
  Observable,
  Subject,
  combineLatest,
  BehaviorSubject,
  shareReplay,
} from 'rxjs';

@Component({
  selector: 'app-team-member-picker',
  templateUrl: './team-member-picker.component.html',
  styleUrls: ['./team-member-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamMemberPickerComponent implements OnInit, OnDestroy {
  onSearch$ = new BehaviorSubject<string>('');
  @Output() onSelect = new EventEmitter<TeamMember>();
  teamMembers$: Observable<TeamMember[]> = combineLatest([
    this.usersViewModel.teamMembers$,
    this.onSearch$,
  ]).pipe(
    map(([members, searchText]) => {
      return members.filter((member) => {
        return member?.given_name
          ?.concat(member?.family_name)
          ?.trim()
          ?.toLocaleLowerCase()
          ?.includes(searchText);
      });
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  constructor(private usersViewModel: UsersViewModel) {}

  select(member: TeamMember): void {
    this.onSelect.emit(member);
  }
  onSearch(event: any): void {
    const value: string = event.target.value;
    this.onSearch$.next(value?.trim()?.toLowerCase());
  }
  ngOnInit(): void {}
  ngOnDestroy(): void {}
}
