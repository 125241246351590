import { INotesRepository } from '@/app/core/IRepositories/INotesRepository';
import { Note } from '@/app/core/Models/Note';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { UseCase } from '../UseCase';
import { NoteBody } from '@/app/Data/DTO/NoteBodyDto';
import { IMediaRepository } from '../../IRepositories/IMediaRepository';

@Injectable({
  providedIn: 'root',
})
export class CreateNoteUseCase implements UseCase<NoteBody, Note> {
  constructor(
    private notesRepository: INotesRepository,
    private mediaRepository: IMediaRepository,
  ) { }

  async execute(body: NoteBody): Promise<Note> {
    const { note, contactId } = body.getBody();
    if (body.hasNewAttachments) {
      const media_urls = await firstValueFrom(
        this.mediaRepository.uploadAttachments(body.getBodyAttachment(), body.uploadProgress$)
      );
      note.attachments = media_urls
    }
    const noteResult = await firstValueFrom(
      this.notesRepository.createNote(note, contactId!)
    );
    this.notesRepository.saveNotes([noteResult]);
    return noteResult;
  }
}
