<!-- [matTooltipClass]="['shortcut-tooltip', 'emoji']" -->
<button
  #emojiPanelRef
  class="snippets-btn rounded-md h-9 w-9 flex items-center justify-center shrink-0 text-pale hover:text-primary focus:text-primary"
  [matTooltip]="tooltip"
  [dropDownPanel]="emojiPanel"
  [dropDownOrigin]="origin"
  [dropDownOffset]="0"
>
  <svg-icon
    src="assets/icons/new-set/smile.svg"
    [svgStyle]="{ 'height.px': size, 'width.px': size }"
  ></svg-icon>
</button>


<ng-template #emojiPanel>
  <emoji-mart
    [darkMode]="false"
    color="#9B51E0"
    size="16"
    set="facebook"
    [style]="{ background: 'transparent', border: 0 }"
    (emojiClick)="addEmoji($event)"
    [showPreview]="false"
  ></emoji-mart>
</ng-template>