import { Localizations } from '@/app/Utilities/localization';
import { IAuthenticationRepository } from '@/app/core/IRepositories/IAuthenticationRepository';
import { ErrorApiReponse } from '@/app/Data/services/Networking/ApiResponse';
import { AppState } from '@/app/State/AppState';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { SnackbarService } from './../../Utilities/snackbar/snackbar.service';
import { TrackEventAction } from '@/app/State/analytics/analytics.action';
import { AnalyticsEventsNames } from '@/app/shared/analytics/analyticsEvents';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordViewModel {
  loading$ = new BehaviorSubject(false);
  completed$ = new Subject<boolean>();
  constructor(
    private store: Store<AppState>,
    private snackBarService: SnackbarService,
    private authRepository: IAuthenticationRepository
  ) { }

  requestReset(email: string): void {
    this.store.dispatch(
      TrackEventAction({
         payload: {
            event: {
              name: AnalyticsEventsNames.PASSWORD_FORGOTTEN_STARTED,
            }
         }
      })
    )
    this.loading$.next(true);
    this.authRepository
      .requestPasswordResetLink({
        email,
      })
      .subscribe({
        next: () => {
          this.snackBarService.openAlert({
            message: Localizations.alert_dialog.check_your_email,
            type: 'success',
          });
        },
        error: (error: ErrorApiReponse<any>) => {
          this.loading$.next(false);
          this.completed$.next(true);
          if (error.statusCode == 404) {
            this.snackBarService.openAlert({
              message:  Localizations.alert_dialog.email_not_found,
              type: 'failure',
            });
          } else {
            this.snackBarService.openAlert({
              message: Localizations.alert_dialog.operation_not_possible,
              type: 'failure',
            });
          }
        },
        complete: () => {
          this.completed$.next(true);
          this.loading$.next(false);
        },
      });
  }
}
