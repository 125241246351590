import { interactionPreviewTransformer } from '@/app/Utilities/interaction/interactionPreviewTransformer';
import { RecentInteraction } from './../../core/Models/Interaction';

import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'interaction_preview',
})
export class InteractionPreviewPipe implements PipeTransform {

  transform(recentInteraction: RecentInteraction): string {
    return interactionPreviewTransformer(recentInteraction)
  }
}


@NgModule({
  declarations: [InteractionPreviewPipe],
  exports: [InteractionPreviewPipe],
  imports: [],
  providers: [InteractionPreviewPipe],
})
export class InteractionPreviewPipeModule {
  constructor() { }
}
