import { Deal } from '@/app/core/Models/deal';
import { createReducer, on } from '@ngrx/store';
import { FetchDealByIdAction, FetchDealByIdFailedAction, FetchDealByIdSuccessAction, GetDealsListAction, GetDealsListFailAction, GetDealsListSuccessAction, DealCreateAction, DealCreateFailAction, DealCreateSuccessAction, DealUpdateAction, DealUpdateFailAction, DealUpdateSuccessAction, ResetDealsStateAction, SetActiveDealId, ShowDealPageAction } from './deals.action';
import { SignOutAction } from '../app/action';

export interface DealsState {
  deals: {
    isLastpage: boolean,
    fetching: boolean,
    page?: number,
    pageSize: number,
  }
  saving?: boolean,
  lookingUp: boolean,
  lookupDeals: Deal[]
  active?: {
    dealId: string;
    deal?: Deal,
    fetching?: boolean,
  }
}

export const initDealsState: DealsState = {
  deals: {
    fetching: false,
    pageSize: 100,
    isLastpage: false,
  },
  lookupDeals: [],
  lookingUp: false
};

export const DealsReducer = createReducer(
  initDealsState,

  on(
    GetDealsListAction,
    (state: DealsState, { }) => {
      return {
        ...state,
        deals: {
          ...state.deals,
          fetching: true
        }
      }
    }
  ),
  on(
    DealCreateAction, DealUpdateAction, (state: DealsState, { }) => {
      return {
        ...state,
        saving: true
      }
    }
  ),
  on(
    DealCreateSuccessAction, DealCreateFailAction, DealUpdateSuccessAction, DealUpdateFailAction, (state: DealsState, { }) => {
      return {
        ...state,
        saving: false
      }
    }
  ),
  on(
    ShowDealPageAction,
    (state: DealsState, { payload }) => {
      return {
        ...state,
        active: {
          deal: payload,
          dealId: payload.yobi_crm_deal_id + ''
        }
      }
    }
  ),
  on(
    GetDealsListSuccessAction,
    (state: DealsState, { payload: { deals, params } }) => {
      return {
        ...state,
        deals: {
          ...state.deals,
          page: params.page,
          fetching: false,
          isLastpage: deals.length < params.page_size
        }
      }
    }
  ),
  on(
    GetDealsListFailAction,
    (state: DealsState, { }) => {
      return {
        ...state,
        deals: {
          ...state.deals,
          fetching: false
        }
      }
    }
  ),
  // on(
  //   LookupDealsAction,
  //   (state: DealsState, { }) => {
  //     return {
  //       ...state,
  //       lookingUp: true
  //     }
  //   }
  // ),

  // on(
  //   LookupDealsSuccessAction,
  //   (state: DealsState, { payload: lookupDeals }) => {
  //     return {
  //       ...state,
  //       lookupDeals: lookupDeals,
  //       lookingUp: false
  //     }
  //   }
  // ),


  on(SetActiveDealId, (state: DealsState, { payload: { dealId } }) => {
    return {
      ...state,
      active: {
        dealId,
      }
    };
  }),
  on(FetchDealByIdAction, (state: DealsState, { payload }) => {
    return {
      ...state,
      active: {
        ...state.active,
        dealId: payload + '',
        fetching: true
      }
    };
  }),
  on(FetchDealByIdSuccessAction, (state: DealsState, { payload }) => {
    return {
      ...state,
      active: {
        ...state.active!,
        fetching: false,
        deal: payload,
      }
    };
  }),
  on(FetchDealByIdFailedAction, (state: DealsState, { }) => {
    return {
      ...state,
      active: {
        ...state.active!,
        fetching: false,
      }
    };
  }),

  on(SignOutAction, ResetDealsStateAction, () => {
    return {
      ...initDealsState,
    };
  })
);

