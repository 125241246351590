import { SnippetDetails } from '@/app/Data/DTO/snippetsDto';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ISnippetsRepository } from '../../IRepositories/ISnippetsRepository';
import { UseCase } from '../UseCase';

@Injectable({
  providedIn: 'root',
})
export class GetSnippetsUseCase implements UseCase<void, SnippetDetails[]> {
  constructor(private snippetsRepository: ISnippetsRepository) { }

  async execute(): Promise<SnippetDetails[]> {
    const snippets = await firstValueFrom(
      this.snippetsRepository.getSnippets()
    );
    this.snippetsRepository.saveSnippets(snippets);
    return snippets;
  }
}
