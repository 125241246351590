import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { AppTranslateService } from './Utilities/localization/translate.service';
import { PWAService } from './Utilities/pwa.service';
import { enableDOM } from './Utilities/helpers';
import { TourService } from './Data/services/Tour/tour.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit {
  isNewUpdate$ = this.pwaService.isNewUpdate$
  showNavigationIndicator = false;

  constructor(
    private pwaService: PWAService,
    private appTranslateService: AppTranslateService,
    private tourService: TourService
  ) {
    // Make tour service available globally for the tooltip buttons
    (window as any).tourService = tourService;
  }

  ngOnInit(): void {
    this.pwaService.listenForUpdates();
    this.pwaService.getInstallPrompt();
    this.appTranslateService.init();
    enableDOM();

    // Check and start tour for first-time users with proper delay
    
  }

  ngAfterViewInit(): void {
    // Keeping your existing commented code
    // this.router.events.subscribe((event: any) => {
    //   switch (true) {
    //     case event instanceof NavigationStart: {
    //       this.showNavigationIndicator = true;
    //       break;
    //     }
    //     case event instanceof NavigationEnd:
    //     case event instanceof NavigationCancel:
    //     case event instanceof NavigationError: {
    //       this.showNavigationIndicator = false;
    //     }
    //   }
    // });
  }

  forceUpdate(): void {
    this.pwaService.forceUpdate()
  }

  // private isFirstTimeUser(): boolean {
  //   // For testing purposes
  //   const forceShowTour = false; // Set to false in production
  //   if (forceShowTour) {
  //     localStorage.removeItem('hasSeenTour');
  //     return true;
  //   }
  //   return !localStorage.getItem('hasSeenTour');
  // }
}
