export enum ChannelType {
  Email = 'email',
  Google = 'GOOGLE',
  Outlook = 'OUTLOOK',
  Phone = 'phone',
  Facebook = 'facebook',
  Instagram = 'instagram',
  Twitter = 'twitter',
  Viber = 'viber',
  Yobi = 'yobi',
  Whatsapp = 'WhatsApp',
  Zoom = 'ZOOM',
  LinkedIn = 'LinkedIn',
  ChatWidget = 'ChatWidget',
  WebCall = 'web_call',
}


export enum vendorType {
  Google = 'GOOGLE',
  Outlook = 'OUTLOOK'
}

export const emailChannels = [ChannelType.Email, ChannelType.Google, ChannelType.Outlook]
