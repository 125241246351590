import { RestrictionErrorDialogComponent } from '@/app/Ui/Modals/restriction-error-dialog/restriction-error-dialog.component';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError} from 'rxjs/operators';

@Injectable()
export class RestrictionErrorInterceptor implements HttpInterceptor {
  private restrictionDialogRef?: MatDialogRef<RestrictionErrorDialogComponent>
  constructor(
    private matDialog: MatDialog,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (
          error instanceof HttpErrorResponse &&
          (error.status === 422)
        ) {
          this.handleError(error, request, next);
        } 
        throw error
      })
    );
  }

  protected handleError(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler
  ) {
    if(this.restrictionDialogRef?.getState() != MatDialogState.OPEN) {
    this.matDialog.closeAll();
    this.restrictionDialogRef = this.matDialog.open(RestrictionErrorDialogComponent, {
      width: '80vw',
      maxWidth: '546px',
      data: {
        message: error.error.message
      }
    })
    this.restrictionDialogRef.afterClosed().subscribe(res => res && this.router.navigate(['main/settings/billing']))
  }
  }
}
