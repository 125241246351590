import { LocalizationViewModel } from '@/app/Ui/ViewModels/localizationViewModel';
import {
  Component
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { combineLatest, map, of, startWith } from 'rxjs';
import Countries from './Countries';

@Component({
  selector: 'yobi-countries-list',
  templateUrl: './countries-code-list.component.html',
  styleUrls: ['./countries-code-list.component.scss'],
})
export class CountriesCodeListComponent  {

  countries$ = of(Countries);
  localization = this.localizationViewModel.localization
  filter = new FormControl('');
  filtredCountriesList$ = combineLatest([
    this.filter.valueChanges.pipe(startWith('')),
    this.countries$
  ]).pipe(map(([value, countries]) => {
    value = (value as string).toLowerCase()
    return countries.filter(country => country.name.toLowerCase().includes(value) || country.code.toLowerCase().includes(value))
  }))
  

  constructor(
    private dialogRef: MatDialogRef<CountriesCodeListComponent>,
    private localizationViewModel: LocalizationViewModel
  ) { }

  select(country: typeof Countries[0]): void {
    this.dialogRef.close(country);
  }
}
