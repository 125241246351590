import { AddYobiPhoneDto } from '@/app/Data/DTO/AddYobiPhoneDto';
import { RequestPhoneVerificationCodeDto } from '@/app/Data/DTO/verifications/RequestPhoneVerificationCodeDto';
import { createAction, props, union } from '@ngrx/store';

/* ------------------------------ Verify phone ------------------------------ */
export const PHONE_VERIFICATION_CODE_REQUEST =
  '[Phone Request] Phone verification code request';
export const PhoneVerificationCodeRequestAction = createAction(
  PHONE_VERIFICATION_CODE_REQUEST,
  props<{ payload: RequestPhoneVerificationCodeDto }>()
);

export const PHONE_VERIFICATION_CODE_REQUEST_SUCCESS =
  '[Phone Request] Phone verification code request success';
export const PhoneVerificationCodeRequestSuccessAction = createAction(
  PHONE_VERIFICATION_CODE_REQUEST_SUCCESS
);

export const PHONE_VERIFICATION_CODE_REQUEST_FAIL =
  '[Phone Request] Phone verification code request failed';
export const PhoneVerificationCodeRequestFailAction = createAction(
  PHONE_VERIFICATION_CODE_REQUEST_FAIL,
  props<{ payload: string }>()
);

export const PHONE_VERIFICATION_CODE_VALIDATION =
  '[Phone Request] Phone verification code validation';
export const PhoneVerificationCodeValidationAction = createAction(
  PHONE_VERIFICATION_CODE_VALIDATION,
  props<{ payload: RequestPhoneVerificationCodeDto }>()
);

export const PHONE_VERIFICATION_CODE_VALIDATION_SUCCESS =
  '[Phone Request] Phone verification code validation success';
export const PhoneVerificationCodeValidationSuccessAction = createAction(
  PHONE_VERIFICATION_CODE_VALIDATION_SUCCESS
);

export const PHONE_VERIFICATION_CODE_VALIDATION_FAIL =
  '[Phone Request] Phone verification code validation failed';
export const PhoneVerificationCodeValidationFailAction = createAction(
  PHONE_VERIFICATION_CODE_VALIDATION_FAIL,
  props<{ payload: string }>()
);

export const PHONE_VERIFICATION_RESET =
  '[Phone Request] Phone verification reset';
export const PhoneVerificationResetAction = createAction(
  PHONE_VERIFICATION_RESET
);


/* -------------------------------- Buy phone ------------------------------- */
export const PHONE_CHANNEL_ADD = '[Phone Purchase] Phone channel add';
export const PhoneChannelAddAction = createAction(
  PHONE_CHANNEL_ADD,
  props<{ payload: AddYobiPhoneDto }>()
);

export const PHONE_CHANNEL_ADD_FAIL = '[Phone Purchase] Phone channel add failed';
export const PhoneChannelAddFailAction = createAction(
  PHONE_CHANNEL_ADD_FAIL
);

export const PHONE_CHANNEL_ADD_SUCCESS = '[Phone Purchase] Phone channel add success';
export const PhoneChannelAddSuccessAction = createAction(
  PHONE_CHANNEL_ADD_SUCCESS
);


const all = union({
  PhoneChannelAddFailAction,
  PhoneChannelAddSuccessAction,
  PhoneChannelAddAction
});

export type PhoneRequestActions = typeof all;