<div class="flex items-start gap-3">
  <app-avatar
    class="h-7 w-7 shrink-0"
    [fontSize]="11"
    [name]="
      comment.created_by.given_name + ' ' + comment.created_by.family_name
    "
    [url]="comment.created_by.avatar"
  ></app-avatar>
  <ng-container *ngIf="!edit; else editTemplateRef">
    <div class="flex flex-1 items-center">
      <div class="flex flex-col flex-1 gap-1">
        <div class="p-4 py-2 rounded-md secondary-bg-color">
          <p class="font-bold text-sm mb-1">
            {{ comment.created_by.given_name + ' ' + comment.created_by.family_name }}
          </p>
          <p
            *ngIf="body; else content"
            class="text-base text-dark"
          >
            <ng-container *ngFor="let section of body">
              <ng-container [ngSwitch]="section.type">
                <span
                  *ngSwitchCase="'text'"
                  [innerHtml]="$any(section).text"
                >
                </span>
                <span
                  *ngSwitchCase="'mention'"
                  class="mention"
                >
                  {{ $any(section).user }}
                </span>
              </ng-container>
            </ng-container>
          </p>
          <ng-template #content>
            <p
              class="text-base text-dark"
              [innerHTML]="detectMention(comment.comment)"
            >
            </p>
          </ng-template>
        </div>
        <div class="flex justify-between">
          <span class="text-xs ml-2 text-mild font-semibold">
            {{ comment.created_dt | shortDate }}
          </span>
          <span
            *ngIf="comment.sending"
            class="text-xs ml-2 text-mild font-semibold"
          >
            Sending ...
          </span>
          <span
            *ngIf="comment.failed"
            (click)="resendComment()"
            class="text-xs ml-2 text-warning font-semibold cursor-pointer hover:text-primary"
          >
            Sending failed
          </span>
        </div>
      </div>
      <svg-icon
        src="assets/icons/new-set/dots-vertical.svg"
        class="cursor-pointer text-mild mb-4"
        [svgStyle]="{ 'height.px': 18, 'width.px': 18}"
        [matMenuTriggerFor]="menu"
        *ngIf="userId == comment.created_by.user_id"
      ></svg-icon>
    </div>
  </ng-container>
</div>

<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    (click)="enableEdit()"
  >
    {{localization.labels.edit | translate}}
  </button>
  <button
    mat-menu-item
    (click)="deleteComment(comment.comment_id)"
  >
    {{localization.labels.delete | translate}}
  </button>
</mat-menu>

<ng-template #editTemplateRef>
  <div class="w-full flex flex-col gap-2 mr-5">
    <textarea
      rows="1"
      class="bg-transparent border-2 secondary-border-color rounded-md focus-within:border-primary ring-0 focus:ring-0"
      cdkFocusInitial
      [formControl]="commentControl"
      (keydown.control.enter)="!isMac && editComment()"
      (keydown.command.enter)="editComment()"
      [mentionConfig]="{
        triggerChar: '@',
        dropUp: true,
        items: members,
        labelKey: 'name'
      }"
      [mentionListTemplate]="mentionListTemplate"
    ></textarea>
    <div class="flex flex-row justify-end gap-6">
      <p
        class="cursor-pointer text-mild"
        (click)="cancelEdit()"
      >
        {{localization.labels.cancel | translate}}
      </p>
      <p
        class="cursor-pointer font-semibold text-primary"
        (click)="editComment()"
      >
        {{localization.labels.save | translate}}
      </p>
    </div>
  </div>
</ng-template>

<ng-template
  #mentionListTemplate
  let-item="item"
>
  <div class="flex items-center gap-4 relative h-8">
    <div
      class="flex items-center h-7 w-7 relative rounded-full"
      [ngClass]="{'bg-gray-200': !item.img}"
    >
      <img
        *ngIf="item.img"
        [src]="item.img"
        alt=""
        class="h-full w-full block rounded-full object-cover"
      />
    </div>
    <ng-container [ngSwitch]="item.name">
      <div *ngSwitchCase="'All'"> {{localization.labels.notify_all_users | translate}}</div>
      <div
        *ngSwitchDefault
        class="mention-active-user"
        [class.online]="item.active"
      >
        {{ item.name }}
      </div>
    </ng-container>
  </div>
</ng-template>