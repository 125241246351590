import { BasicCustomFieldDetails, CustomFieldDetails } from './../Models/contact';
import { Observable } from 'rxjs';
import { CustomFieldDeletedResultDto,  CustomFieldPinResultDto, renameFieldReuqestDto, UpdateCustomFieldRequestDto } from '@/app/Data/DTO/CustomFieldDto';

export abstract class ICustomFieldsRepository {
  abstract updateContactCustomField(request: UpdateCustomFieldRequestDto, contactId: number): Observable<CustomFieldDetails>;
  abstract getCustomFields(): Observable<BasicCustomFieldDetails[]> 
  abstract deleteCustomField(fieldId: number): Observable<CustomFieldDeletedResultDto>;
  abstract pinField(fieldId: number, status: boolean): Observable<CustomFieldPinResultDto>;
  abstract getChannelCustomField(channelId: number): Observable<CustomFieldDetails[] | null>;
  abstract updateChannelCustomField(request: UpdateCustomFieldRequestDto, channelId: number): Observable<CustomFieldDetails>;
  abstract renameCustomField(request: renameFieldReuqestDto): Observable<CustomFieldDetails>;
}