import { AnalyticEvent } from '@/app/shared/analytics/analyticsEvents';
import { createAction, props, union } from '@ngrx/store';

export const INIT_ANALYTICS = '[Analytics] init';
export const InitAnalyticsAction = createAction(
  INIT_ANALYTICS,
);

export const TRACK_EVENT = '[Analytics] track Event';
export const TrackEventAction = createAction(
  TRACK_EVENT,
  props<{ payload: { event: AnalyticEvent } }>()
);

const all = union({
  TrackEventAction
});

export type AnalyticsActions = typeof all;
