import { FileType } from "./file";

export class Note {
  contact_id?: number;
  unclassified_channel_id?: number;
  content: string;
  created_dt: string;
  created_by: string;
  external_id?: number;
  note_id: number;
  type: string;
  updated_dt: string;
  user_id: number;
  attachments?: {
    url: string;
    type: FileType;
  }[]
  get note_body(): ({ type: 'text', text: string } | { type: 'mention', user: string, id: number })[] {
    try {
      let content = JSON.parse(this.content)
      if (typeof content === 'string') {
        content = []
      }
      return content
    } catch {
      return [{ type: 'text', text: this.content }]
    }
  }
}
