import { TaskFilter, TaskStatus } from '@/app/core/Models/Task';
import { createReducer, on } from '@ngrx/store';
import {
  GetAllTasksAction,
  GetAllTasksFailAction,
  GetAllTasksSuccessAction,
  SetActiveTasksStatusAction,
} from './action';

export interface TasksState {
  activeTasksStatus: TaskStatus[];
  filter: TaskFilter;
  fetching: boolean;
}

export const initTasksState: TasksState = {
  activeTasksStatus: [],
  filter: TaskFilter.All,
  fetching: false,
};

export const tasksReducer = createReducer(
  initTasksState,
  on(SetActiveTasksStatusAction, (state: TasksState, { payload }) => {
    return {
      ...state,
      activeTasksStatus: payload
    };
  }),
  on(GetAllTasksAction, (state: TasksState, { payload }) => {
    return {
      ...state,
      fetching: true,
      filter: payload.filter ?? TaskFilter.All
    };
  }),
  on(GetAllTasksSuccessAction, (state: TasksState, { payload }) => {
    return {
      ...state,
      fetching: false,
    };
  }),
  on(GetAllTasksFailAction, (state: TasksState, { payload }) => {
    return {
      ...state,
      fetching: false,
    };
  })
);
