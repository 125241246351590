import { ConfigurationService } from '@/app/shared/Config/configuration.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Resolve, RouterStateSnapshot
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class OnboardingResolverService implements Resolve<any> {
  constructor(
    private configService: ConfigurationService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    this.configService.connectSocket();
    return true;
  }
}
