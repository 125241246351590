import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  player: HTMLAudioElement;
  constructor() {
    this.player = new Audio();
  }

  playIncomingInteractionNotification(): void {
    this.player.pause();
    this.player.src = 'assets/audio/ding.mp3';
    this.player.load();
    this.player.play();
  }
  
  playIncomingTeamInteractionNotification(): void {
    this.player.pause();
    this.player.src = 'assets/audio/ding-2.mp3';
    this.player.load();
    this.player.play();
  }
}
