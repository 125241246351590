export interface CreateSnippetDto {
  snip_name: string;
  snip_template: string;
  user_id: number | null;
}

export class SnippetDetails {
    created_dt: string;
    snip_name: string;
    snip_template: string;
    snippet_id: number;
    tenropant_id: number;
    updated_dt: string;
    user_id: number;
}

export interface EditSnippetDto {
  snip_name: string;
  snip_template: string;
  shared: boolean;
}