export enum confirm {
  archive_task = 'confirm.archive_task',
  chancel_scheduled_message = 'confirm.chancel_scheduled_message',
  delete_comment = 'confirm.delete_comment',
  delete_contact = 'confirm.delete_contact',
  delete_contact_field = 'confirm.delete_contact_field',
  delete_image = 'confirm.delete_image',
  delete_message = 'confirm.delete_message',
  delete_note = 'confirm.delete_note',
  delete_payment_method = 'confirm.delete_payment_method',
  delete_profile_picture = 'confirm.delete_profile_picture',
  delete_promo_code = 'confirm.delete_promo_code',
  delete_recording = 'confirm.delete_recording',
  delete_snippet = 'confirm.delete_snippet',
  delete_tag = 'confirm.delete_tag',
  delete_user = 'confirm.delete_user',
  extend_trial_period = 'confirm.extend_trial_period',
  import_contacts_in_web = 'confirm.import_contacts_in_web',
  integration_vailble_in_web = 'confirm.integration_vailble_in_web',
  pick_phone_number = 'confirm.pick_phone_number',
  remove_integration = 'confirm.remove_integration',
  delete_contact_picture = 'confirm.delete_contact_picture',
  delete_interaction_comment = 'confirm.delete_interaction_comment'
}