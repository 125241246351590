import { Observable, Observer } from 'rxjs';

import { ContactChannel, UnclassifiedChannel } from '@/app/core/Models/Channel';
import {
  AssociatedCompanies,
  Contact,
  ContactAssociationDto,
  ContactsQueryParams,
  ContactTasks,
  DeleteContactAssociationDto,
  AssociatedContact,
  AssociatedContacts,
  AssociatedCompany,
  ContactCompanyAssociationDto,
  IContactAttachments
} from '@/app/core/Models/contact';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { ContactBlockDto } from '@/app/Data/DTO/ContactBlockDto';
import { ContactImageUpdatResponseDto } from '@/app/Data/DTO/contactImageUpdatResponseDto';

import { Deal } from '../Models/deal';
import { SelectedPipeline } from '../Models/company';

export abstract class IContactRepository {
  abstract getContactById(channelId: number): Observable<Contact>;
  abstract getUnclassifiedChannelById(channelId: number): Observable<[UnclassifiedChannel]>;
  abstract getContactChannels(contactId: number): Observable<ContactChannel[]>;
  abstract saveContactChannels(contactId: number, channels: ContactChannel[]): void;
  abstract getLocalContactWithChannels(contactId: number): Observable<Contact | undefined>;
  abstract saveContact(contact: Contact): void;
  abstract getLocalContactById(channelId: number): Observable<Contact | undefined>;
  abstract getLocalContactChannelsById(contactId: number): Observable<ContactChannel[]>;
  abstract createContact(data: Contact): Observable<Contact>;
  abstract editContact(contactId: number, data: Contact): Observable<Contact>;
  abstract deleteContact(contactId: number): Observable<SuccessResponse>;
  abstract deleteLocalContact(contactId: number): void;
  abstract blockContact(request: ContactBlockDto): Observable<SuccessResponse>;
  abstract updateProfileImage(data: FormData, contactId: number, progress$: Observer<any>): Observable<ContactImageUpdatResponseDto>
  abstract deleteProfileImage(contactId: number): Observable<SuccessResponse>;
  abstract updateLocalContactDetails(contactId: string, details: any): void
  abstract getAssociatedDealsToContact(params: ContactsQueryParams): Observable<Deal[]>
  abstract getAssociatedDealsToContactLocal(contactId: string): Observable<Deal[] | undefined>


  abstract associateContactToCotact(data: ContactAssociationDto[]): Observable<ContactAssociationDto[]>
  abstract getAssociatedContactToContactLocal(contactId: string): Observable<AssociatedContact[] | undefined>
  abstract getAssociatedContactToContact(params: ContactsQueryParams): Observable<AssociatedContacts>
  abstract removeAssociatedContactToContact(data: DeleteContactAssociationDto): Observable<SuccessResponse>
  abstract updateAssociatedContactAssociationType(data: ContactAssociationDto): Observable<SuccessResponse>

  abstract getContactTasks(params: ContactsQueryParams): Observable<ContactTasks[]>
  abstract getAssociatedContactTasksLocal(contactId: string): Observable<ContactTasks[] | undefined>

  abstract getAssociatedContactToCompany(params: ContactsQueryParams): Observable<AssociatedCompanies>
  abstract getAssociatedContactToCompanyLocal(companyId: string): Observable<AssociatedCompany[] | undefined>
  abstract associateContactToCompany(input: ContactCompanyAssociationDto[]): Observable<ContactCompanyAssociationDto[]>
  abstract updateAssociatedContactToCompanyRelationshipType(input: ContactCompanyAssociationDto): Observable<ContactCompanyAssociationDto>
  abstract deleteAssociatedContactToCompany(input: ContactCompanyAssociationDto): Observable<ContactCompanyAssociationDto>

  abstract getContactAttachments(contact_id: string): Observable<IContactAttachments>;
  abstract getContactAttachmentsLocal(yobi_crm_contact_id: string): Observable<any[] | undefined>;
  abstract uploadContactAttachments(files: FormData, contact_id: string, progress$: Observer<any>): Observable<IContactAttachments>;
  abstract deleteContactAttachments(deleted_files: string[], contact_id: string): Observable<IContactAttachments>;
}
