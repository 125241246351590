import { CalendarEvent } from "angular-calendar";
import { EventAction, EventColor } from "calendar-utils";

export class CreateEventDto {
    event_type: 'task' | 'meeting' | 'nexthealth_appointment' | 'nexthealth_availability' | 'scheduled_message';
    event_title: string;
    event_id?: number;
    event_start_date: number;
    event_end_date: number;
    event_deadline: number;
    event_priority: string;
    event_location: string;
    event_timezone: string;
    in_person_meeting: boolean;
    event_description: string;
    extra_data: any;
    attachments?: IEventAttachments[] | any;
    invitees: Array<{ user_id: string }>;
    tags: number[];
    external_email_invitees: Array<{ email: string }>
}

export class PatchEventDto {
    event_type?: 'task' | 'meeting' | 'nexthealth_appointment' | 'nexthealth_availability' | 'scheduled_message';
    event_title?: string;
    event_id?: number;
    event_start_date?: number;
    event_end_date?: number;
    event_deadline?: number;
    event_priority?: string;
    event_location?: string;
    event_timezone?: string;
    in_person_meeting?: boolean;
    event_description?: string;
    extra_data?: any;
    attachments?: IEventAttachments[];
    invitees?: Array<{ user_id: string }>;
    tags?: number[];
    external_email_invitees?: Array<{ email: string }>
}

export class GetEventsByTimestampDto {
    page_size: number;
    page: number;
    timestamp: number;
}

export class GetEventsByFilterDto {
    event_start_date: string;
    event_end_date: string;
}

export interface IEventAttachments {
    filename: string;
    raw_filename: string;
    attachment_url: string;
    yobi_event_id: number;
}

export class Event implements CalendarEvent {
    id?: string | number;
    start: Date;
    end?: Date;
    title: string;
    color?: EventColor;
    actions?: EventAction[];
    allDay?: boolean;
    cssClass?: string;
    resizable?: {
        beforeStart?: boolean;
        afterEnd?: boolean;
    };
    draggable?: boolean;
    meta?: IYobiEvent
}

export interface IEventForm {
    event_title: string,
    event_type: string,
    invitees: any[],
    event_deadline: number,
    event_deadline_view: any,
    event_description: string,
    rsvp: boolean,
    event_priority: string,
    event_location: string,
    in_person_meeting: boolean,
    tags: number[],
    start_time: string,
    end_time: string,
    start_date: Date,
    end_date: Date,
    reminder: number,
    attachments: any[],
    event_timezone: string,
    extra_data: any,
    multiple_day_event?: boolean,
    reminders: Array<{ reminder_value: string, reminder: number }>,
    external_email_invitees: string[]
}

export class IYobiEvent {
    created_by: number
    event_deadline: number
    event_description: string
    event_end_date: number
    event_location: string
    event_priority: string
    event_start_date: number
    event_timezone: string
    event_title: string
    event_type: string
    in_person_meeting: boolean
    multiple_day_event: boolean
    yobi_event_id: any
    extra_data: any
}

export class IYobiEventResponse {
    event_start_date: number
    events: IYobiEvent[]
    total_events: number
}