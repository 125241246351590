import { lightwightSignupDto } from '@/app/Data/DTO/EmailVerificationCodeDto';
import { TokenStorgeService } from '@/app/Data/services/Token/token.service';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IAuthenticationRepository } from '../../IRepositories/IAuthenticationRepository';
import { ITenantRepository } from '../../IRepositories/ITenantRepository';
import { Tenant } from '../../Models/tenant';
import { UseCase } from '../UseCase';
import { ILocalStorageService } from '@/app/Data/Adapters/ILocalStorageService';

@Injectable({
  providedIn: 'root',
})
export class SignupWithCredentialsUseCase
  implements UseCase<lightwightSignupDto, Tenant> {
  constructor(
    private authRepository: IAuthenticationRepository,
    private tenantRepository: ITenantRepository,
    private tokenStorgeService: TokenStorgeService,
    @Inject(ILocalStorageService) private localStorage: ILocalStorageService
  ) { }

  async execute(request: lightwightSignupDto): Promise<Tenant> {
    const credential = await lastValueFrom(
      this.authRepository.signupWithCredentials(request)
    )
    this.localStorage.setItem('yib_p_flag', credential.show_plans_onboarding)
    // await this.databaseService.init();
    this.tokenStorgeService.setAccountAccessLink(credential.results.account_access_link);
    this.tokenStorgeService.saveToken(credential.results.token)
    this.tokenStorgeService.saveRefreshToken(credential.results.refreshToken)
    const user = await lastValueFrom(
      this.authRepository.getUserObject()
    );
    this.tokenStorgeService.saveUser(user)
    this.authRepository.saveTenantProfileObject(user);
    const channels = await lastValueFrom(
      this.tenantRepository.getInternalChannels()
    );
    this.tenantRepository.saveInternalChannels(channels);
    return user;
  }

}
