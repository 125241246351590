export enum subscription {
  active_status = 'subscription.active_status',
  canceling_status = 'subscription.canceling_status',
  incomplete_status = 'subscription.incomplete_status',
  past_due_status = 'subscription.past_due_status',
  plan_enterprise = 'subscription.plan_enterprise',
  plan_free = 'subscription.plan_free',
  plan_unpaid = 'subscription.plan_unpaid',
  plan_pro = 'subscription.plan_pro',
  plan_starter = 'subscription.plan_starter',
  plan_trial = 'subscription.plan_trial',
  plan_trial_extended = 'subscription.plan_trial_extended',
  will_expire = 'subscription.will_expire',
  you_are_on_plan = 'subscription.you_are_on_plan',
  yobi_plan = 'subscription.yobi_plan',
  almost_there = 'subscription.almost_there',
  trial_over_desktop = 'subscription.trial_over_desktop',
  subscription_over_desktop = 'subscription.subscription_over_desktop',
  trial_over_web = 'subscription.trial_over_web',
  subscription_over_web = 'subscription.subscription_over_web',
  your_payment_history = 'subscription.your_payment_history',
  empty_payment_history = 'subscription.empty_payment_history',
  cancel_subscription_hint = 'subscription.cancel_subscription_hint',
  on_free_plan = 'subscription.on_free_plan',
  manage_billing_and_plans = 'subscription.manage_billing_and_plans',
  card_information = 'subscription.card_information',
  card_holder_name = 'subscription.card_holder_name',
  billing_address = 'subscription.billing_address',
  card_address_line1 = 'subscription.address_line1',
  card_address_line2 = 'subscription.address_line2',
  card_address_state = 'subscription.address_state',
  card_address_city = 'subscription.address_city',
  card_address_zip = 'subscription.address_zip',
  we_are_sorry_to_see_you_go = 'subscription.we_are_sorry_to_see_you_go',
  tell_us_why_cancel_plan = 'subscription.tell_us_why_cancel_plan',
  order_preview = 'subscription.order_preview',
  payment_method = 'subscription.payment_method',
}