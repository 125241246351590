import { CreateSynthAgentDto, ITemplateEngine, IYobiOneMetadata, LinkSynthAgentDto, RecentSynthConversationsQueryParams, Synth, SynthAgent, SynthAgentScript, SynthChannel, SynthConversation, SynthConversationInteractionsQuery, SynthInteraction, SynthRecentConversation, SynthTemplateEngine, SynthTrainerParams } from "@/app/core/Models/synth";
import { environment } from "@/environments/environment";
import { Injectable } from "@angular/core";
import { from, last, map, Observable, Observer, pluck, tap } from "rxjs";
import { HttpService } from "../services/Networking/HttpService";
import { HttpRequestMethod } from "../services/Networking/HttpRequestMethod";
import { SuccessApiResponse } from "../services/Networking/ApiResponse";
import { CreateSynthConversationDto, CreateSynthInteraction, UpdateSynthConversationDto, UpdateSynthInteraction } from "../DTO/synthDto";
import { ISynthRepository } from "@/app/core/IRepositories/ISynthREpository";
import { getUploadEventProgress } from "@/app/Utilities/functions/getUploadEventProgress";
import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { liveQuery } from "dexie";
@Injectable({ providedIn: 'root' })
export class SynthRepository implements ISynthRepository {
    constructor(
        private httpService: HttpService,
        private databaseService: DatabaseService
    ) { }

    getSynths(params: SynthTrainerParams): Observable<Synth[]> {
        const requestURL = `${environment.apiURL}synth_trainer`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false,
            params
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<Synth[]>).results)
        );
    }

    createSynthConversation(data: CreateSynthConversationDto): Observable<SynthConversation> {
        const requestURL = `${environment.apiURL}synth_trainer/conversation`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            data,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<SynthConversation>).results)
        );
    }

    updateSynthConversation(data: UpdateSynthConversationDto, synth_conversation_id: number | string): Observable<SynthConversation> {
        const requestURL = `${environment.apiURL}synth_trainer/conversation/${synth_conversation_id}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.put,
            this.httpService.createHeader(),
            requestURL,
            data,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<SynthConversation>).results)
        );
    }

    getSynthConversation(): Observable<SynthConversation> {
        const requestURL = `${environment.apiURL}synth_trainer/recent_conversation`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<SynthConversation>).results)
        );
    }

    getSynthRecentConversations(query: RecentSynthConversationsQueryParams): Observable<SynthRecentConversation[]> {
        const requestURL = `${environment.apiURL}synth_trainer/recent_conversation`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false,
            query
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => {
                return (item as SuccessApiResponse<SynthRecentConversation[]>).results
            })
        );
    }

    getSynthInteractionsByConversation({ conversationId, params }: SynthConversationInteractionsQuery): Observable<SynthInteraction[]> {
        const requestURL = `${environment.apiURL}synth_trainer/conversation/${conversationId}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false,
            params
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<SynthInteraction[]>).results)
        );
    }

    createSynthInteraction(data: CreateSynthInteraction): Observable<SynthInteraction> {
        const requestURL = `${environment.apiURL}synth_trainer/interaction`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            data,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<SynthInteraction>).results)
        );
    }

    updateSynthInteraction(data: UpdateSynthInteraction, synth_interaction_id: number | string): Observable<SynthInteraction> {
        const requestURL = `${environment.apiURL}synth_trainer/interaction/${synth_interaction_id}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.put,
            this.httpService.createHeader(),
            requestURL,
            data,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<SynthInteraction>).results)
        );
    }

    uploadKnowledgeBaseFiles(data: FormData, progress$: Observer<any>): Observable<string[]> {
        const requestURL = `${environment.apiURL}synth/agent/knowledge-base/upload`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            data,
            false,
            null,
            true
        );
        return this.httpService.upload(options).pipe(
            tap((event: any) => getUploadEventProgress(event, progress$)),
            last(),
            pluck('body'),
            map((item) => {
                let res = item as SuccessApiResponse<any>;
                return res.results;
            })
        );
    }

    createSynthAgent(data: CreateSynthAgentDto): Observable<Synth> {
        const requestURL = `${environment.apiURL}synth/agent`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            data,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<Synth>).results)
        );
    }

    getSynthChannels(synth_id: number): Observable<SynthChannel[]> {
        const requestURL = `${environment.apiURL}synth/${synth_id}/get_channels`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<SynthChannel[]>).results)
        );
    }


    linkSynth(synth_id: number, channel_id: string): Observable<any> {
        const requestURL = `${environment.apiURL}link_synth/synth/${synth_id}/internal_channel/${channel_id}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            null,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<any>).results)
        );
    }

    unLinkSynth(synth_id: number, channel_id: string): Observable<any> {
        const requestURL = `${environment.apiURL}link_synth/synth/${synth_id}/internal_channel/${channel_id}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.delete,
            this.httpService.createHeader(),
            requestURL,
            null,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<any>).results)
        );
    }

    getSynthTrainer(): Observable<SynthAgent[]> {
        const requestURL = `${environment.apiURL}synth/get_channels`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<SynthAgent[]>).results)
        );
    }


    getSynthDetails(synth_id: number): Observable<Synth> {
        const requestURL = `${environment.apiURL}synth_trainer/${synth_id}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<Synth>).results)
        );
    }

    updateSynthAgent(data: CreateSynthAgentDto, synth_id: number): Observable<Synth> {
        const requestURL = `${environment.apiURL}synth/agent?synth_agent_id=${synth_id}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.put,
            this.httpService.createHeader(),
            requestURL,
            data,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<Synth>).results)
        );
    }

    getSynthAgentScript(synth_id: number): Observable<SynthAgentScript> {
        const requestURL = `${environment.apiURL}synth_api/${synth_id}/retell_agent_script`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<SynthAgentScript>).results)
        );
    }

    createCallSynthAgent(retell_agent_id: string, conversation_id: number): Observable<{ access_token: string }> {
        const requestURL = `${environment.apiURL}create-trainer-web-call/${retell_agent_id}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            { 'synth_conversation_id': conversation_id },
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<{ access_token: string }>).results)
        );
    }

    saveSynthsLocal(synth: Synth[], params: SynthTrainerParams): void {
        this.databaseService.transaction('rw!', this.databaseService.synth, async () => {
            params.page == 1 && await this.databaseService.synth.clear();
            await this.databaseService.synth.bulkPut(synth);
        }).catch((error) => {
            console.error('Transaction Failed: ', error);
        });
    }

    getLocalSynths(): Observable<Synth[]> {
        return from(
            liveQuery(() => {
                // this.databaseService.synth.mapToClass(Synth);
                return this.databaseService.synth.orderBy('synth_display_name').toArray();
            })
        );
    }

    getLocalSynth(synth_id: number): Observable<Synth | undefined> {
        return from(
            liveQuery(() => {
                return this.databaseService.transaction(
                    'r',
                    this.databaseService.synth,
                    async () => {
                        return this.databaseService.synth.get(synth_id as any)
                    }
                );
            })
        );
    }

    updateLocalSythDetails(synth_id: string, details: any): void {
        this.databaseService.synth.update(synth_id, details).then();
    }

    getLocalSynthTemplateEngine(synth_id: number): Observable<ITemplateEngine | undefined> {
        return from(
            liveQuery(() => {
                return this.databaseService.transaction(
                    'r',
                    this.databaseService.synth,
                    async () => {
                        const synth = await this.databaseService.synth.get({ 'synth_id': synth_id });
                        return synth?.template_engine;
                    }
                );
            }));
    }

    generateTranscriptsFeedback(synth_id: number, transcript: string, synth_conversation_id: number): Observable<any> {
        const requestURL = `${environment.apiURL}synth_trainer/change`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            { synth_id, transcript, synth_conversation_id },
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<any>).results)
        );
    }

    applyTranscriptsFeedback(synth_conversation_id: number, synth_agent_id: number, transcript: string, changes: Array<{ type: string, description: string }>, feedback_id: number): Observable<any> {
        const requestURL = `${environment.apiURL}synth_trainer/apply`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            { synth_conversation_id, synth_agent_id, transcript, changes, feedback_id },
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<any>).results)
        );
    }

    deleteSynthAgent(ids: number[]): Observable<number[]> {
        const requestURL = `${environment.apiURL}synth/agents`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.delete,
            this.httpService.createHeader(),
            requestURL,
            { synth_agent_ids: ids },
            false
        );
        return this.httpService.execute(options).pipe(
            map((item) => {
                return ids;
            })
        );
    }

    deleteSynthLocal(synth_id: number[]): void {
        this.databaseService.synth.bulkDelete(synth_id as any).then();
    }

    callYobiOne(retell_agent_id: string, metadata: IYobiOneMetadata): Observable<{ access_token: string, call_id: string }> {
        const requestURL = `${environment.apiURL}create-yobiWan-web-call/${retell_agent_id}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            { 'metadata': metadata },
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<{ access_token: string, call_id: string }>).results)
        );
    }

    callOnboardingAgent(retell_agent_id: string): Observable<{ access_token: string, call_id: string }> {
        const requestURL = `${environment.apiURL}create-onboard-web-call/${retell_agent_id}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            null,
            false
        );      
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<{ access_token: string, call_id: string }>).results)
        );
    }

    getShareLink(retellAgentId: string): Observable<{token: string}> {
        const requestURL = `${environment.apiURL}synth_agent/get_share_link/${retellAgentId}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false
        );
        return this.httpService.execute(options).pipe(
            map((item) => (item as SuccessApiResponse<{token: string}>).results)
        );
    }


}